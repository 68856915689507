import {
  Button,
  Link,
  StackProps,
  Text,
  useToast,
  VStack,
  Box,
  Heading,
  HStack,
  CloseButton,
  Stack,
  Circle,
} from '@chakra-ui/react'
import { brands, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DOC_DISPLAY_NAME } from '@gamma-app/ui'

import {
  PRIVACY_POLICY_URL,
  TERMS_OF_SERVICE_URL,
} from 'gamma_components/constants'
import { AccessLink, Doc } from 'modules/api'
import { LoginScenarios, openLoginWindow } from 'modules/auth'
import { ErrorCode, ERROR_MAP } from 'modules/errors'
import { UserRefetchFn, useUserContext } from 'modules/user'

import { clearShareToken } from './useAccessLinkCollaborator'

export type AccessLinkSignUpProps = {
  accessLink: AccessLink
  doc: Doc
  textIsBold?: boolean
  refetchFn?: UserRefetchFn
  showDivider?: boolean
} & StackProps

export const AccessLinkSignUp = ({
  accessLink,
  doc,
  refetchFn,
  ...rest
}: AccessLinkSignUpProps) => {
  const toast = useToast()
  const { refetch } = useUserContext()
  // User Context and therefore `refetch` is not available within the Chakra's toast manager
  // so when this component is used in a toast, a `refetchFn` function should be passed in
  const userRefetch = refetchFn || refetch

  const handleError = (errorCode: ErrorCode) => {
    const { title, description } = ERROR_MAP[errorCode] || {
      title: 'Unexpected Error',
      description: errorCode,
    }
    if (!toast.isActive(errorCode)) {
      toast({
        title,
        description,
        position: 'top',
        id: errorCode,
        status: 'error',
        isClosable: true,
        duration: null,
      })
    }
  }
  // Make sure we find a permission level
  const permission = accessLink.permission ?? 'view'

  const actions = {
    manage: `comment on and edit this ${DOC_DISPLAY_NAME}`,
    edit: `comment on and edit this ${DOC_DISPLAY_NAME}`,
    comment: `view and add comments on this ${DOC_DISPLAY_NAME}`,
    view: `access this ${DOC_DISPLAY_NAME} later`,
  }
  const action = actions[permission]

  const headlines = {
    manage: `You've been invited to edit`,
    edit: `You've been invited to edit`,
    comment: `You've been invited to comment`,
    view: `You've been invited to view`,
  }
  const headline = headlines[permission]

  const styling = {
    manage: {
      icon: solid('pencil'),
      colorScheme: 'cyan',
    },
    edit: {
      icon: solid('pencil'),
      colorScheme: 'cyan',
    },
    comment: {
      icon: solid('comment'),
      colorScheme: 'yellow',
    },
    view: {
      icon: solid('folder'),
      colorScheme: 'green',
    },
  }

  const docEditors =
    doc.editors?.map((editor) => {
      return editor.user.displayName
    }) ?? []

  const supportingCopy = (() => {
    switch (permission) {
      case 'manage':
      case 'edit':
        switch (docEditors.length) {
          case 0:
            return (
              <>
                Get started in seconds ⚡️. Sign in to get{' '}
                <strong>edit access</strong> to this {DOC_DISPLAY_NAME}.
              </>
            )
          case 1:
            return (
              <>
                Get started in seconds ⚡️. Sign in to collaborate with{' '}
                <strong>{docEditors[0]}</strong> on this {DOC_DISPLAY_NAME}.
              </>
            )
          case 2:
            return (
              <>
                Join the party 🎉. Sign in to collaborate with{' '}
                <strong>{docEditors[0]}</strong> and{' '}
                <strong>{docEditors[1]}</strong>.
              </>
            )
          default:
            return (
              <>
                Join the party 🎉. Sign in to collaborate with{' '}
                <strong>
                  {docEditors[0]} and {docEditors.length - 1} others
                </strong>
                .
              </>
            )
        }
      case 'comment':
        return (
          <>
            Join the conversation 💬. Sign in to add <strong>comments</strong>{' '}
            and <strong>reactions</strong>.
          </>
        )
      case 'view':
      default:
        return (
          <>
            Sign in to be able to{' '}
            <strong>access this {DOC_DISPLAY_NAME} later</strong> from your
            workspace.
          </>
        )
    }
  })()

  const handleLoginComplete = async () => {
    if (userRefetch) {
      await userRefetch()
      clearShareToken()
    }
    if (!toast.isActive('login-complete')) {
      toast({
        title: "You're logged in.",
        description: `You can now ${action}.`,
        position: 'top',
        id: 'login-complete',
        status: 'success',
        isClosable: true,
        duration: 5000,
      })
    }
  }
  return (
    <VStack align="stretch" {...rest}>
      <HStack spacing={4} mr={6}>
        <PopupIcon
          icon={<FontAwesomeIcon icon={styling[permission].icon} />}
          colorScheme={styling[permission].colorScheme}
        />
        <Heading size="sm">{headline}</Heading>
      </HStack>

      <Text fontSize="sm">{supportingCopy}</Text>

      <Stack>
        <Button
          variant="solid"
          leftIcon={<FontAwesomeIcon icon={brands('google')} />}
          size="md"
          onClick={() => {
            openLoginWindow({
              type: LoginScenarios.DOC_ACCESS_LINK,
              params: {
                docId: doc?.id,
                shareToken: accessLink.token,
              },
              errorCallback: handleError,
              doneCallback: handleLoginComplete,
            })
          }}
        >
          Continue with Google
        </Button>
        <Text fontSize="xs" color="gray.600">
          By clicking this link, you agree to Gamma's{' '}
          <Link href={TERMS_OF_SERVICE_URL} isExternal fontWeight="bold">
            Terms of Service
          </Link>{' '}
          and{' '}
          <Link href={PRIVACY_POLICY_URL} isExternal fontWeight="bold">
            Privacy Policy
          </Link>
          .
        </Text>
      </Stack>
    </VStack>
  )
}

const PopupIcon = ({ icon, colorScheme }) => {
  return (
    <Circle
      size={10}
      bg={`linear-gradient(to bottom, var(--chakra-colors-${colorScheme}-200), var(--chakra-colors-${colorScheme}-300))`}
      position="relative"
    >
      <Box
        color={`${colorScheme}.600`}
        display="block"
        mt="0.5"
        textAlign="center"
      >
        {icon}
      </Box>

      <Circle
        size={5}
        position="absolute"
        bottom={-1}
        right={-1}
        color={`${colorScheme}.800`}
        bg={`${colorScheme}.100`}
        boxShadow="base"
        fontSize="xs"
      >
        <FontAwesomeIcon icon={solid('plus')} />
      </Circle>
    </Circle>
  )
}
