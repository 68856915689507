import {
  HStack,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Text,
} from '@chakra-ui/react'
import { SmartLayoutOption, SmartLayoutOptionControlProps } from '../types'

const CellSizeOptionControl = ({
  value,
  updateValue,
}: SmartLayoutOptionControlProps<number>) => {
  return (
    <HStack spacing={2} mr={2}>
      <Text fontSize="sm" fontWeight="600">
        Size
      </Text>
      <Slider
        min={5}
        max={25}
        step={0.5}
        value={value}
        onChange={updateValue}
        w="60px"
        focusThumbOnChange={false}
        colorScheme="trueblue"
        size="sm"
      >
        <SliderTrack>
          <SliderFilledTrack />
        </SliderTrack>
        <SliderThumb background="trueblue.500" />
      </Slider>
    </HStack>
  )
}

export const CellSizeOption: SmartLayoutOption<number> = {
  key: 'cellSize',
  defaultValue: 14,
  Control: CellSizeOptionControl,
}
