import {
  Box,
  Button,
  ButtonGroup,
  Menu,
  MenuButton,
  MenuGroup,
  MenuList,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'

import { useAppSelector } from 'modules/redux'
import {
  getAccentColorPalette,
  getThemeCardColor,
  Theme,
} from 'modules/theming'
import { selectTheme } from 'modules/tiptap_editor/reducer'
import {
  BackgroundType,
  getBackgroundColor,
} from 'modules/tiptap_editor/styles/backgroundStyles'
import { filterNotVoid } from 'utils/array'
import { preventDefaultToAvoidBlur } from 'utils/handlers'

import { ToolbarButton } from '../../buttons/ToolbarButton'
import { NodeFormattingMenuProps } from '../types'
import { focusEditorOnMenuClose } from '../utils'

const DEFAULT_COLORS = [
  {
    label: 'Black',
    color: '#000000',
  },
  {
    label: 'White',
    color: '#FFFFFF',
  },
  // Todo: add our whole rainbow from text colors
]

type CardColorMenuProps = Omit<NodeFormattingMenuProps, 'decorations'>

const useCardColorMenu = ({ editor, selection }: CardColorMenuProps) => {
  const theme: Theme = useAppSelector(selectTheme)
  const currentColor = getBackgroundColor(
    selection.node.attrs.container?.background
  )
  const themeCardColor = getThemeCardColor(theme)
  const accentColors = getAccentColorPalette(theme)
  const themeColors = filterNotVoid([themeCardColor, ...accentColors])
  const hasNonThemeColor =
    currentColor &&
    !themeColors.includes(currentColor) &&
    !DEFAULT_COLORS.map((c) => c.color).includes(currentColor)
  const setCardColor = (color?: string) => {
    const { container } = selection.node.attrs
    const containerPatch = color
      ? {
          background: {
            type: BackgroundType.COLOR,
            color: { hex: color },
          },
        }
      : {
          background: undefined,
        }
    editor.commands.updateAttributesAtPos(selection.from, {
      container: {
        ...container,
        ...containerPatch,
      },
    })
  }

  return {
    accentColors,
    currentColor,
    hasNonThemeColor,
    themeColors,
    themeCardColor,
    setCardColor,
  }
}

export const CardColorMenu = ({ editor, selection }: CardColorMenuProps) => {
  const { setCardColor, currentColor, themeCardColor } = useCardColorMenu({
    editor,
    selection,
  })
  return (
    <Menu isLazy onClose={() => focusEditorOnMenuClose(editor)}>
      <GammaTooltip placement="top" label={'Change card color'}>
        <MenuButton
          as={Button}
          variant="toolbar"
          onMouseDown={preventDefaultToAvoidBlur}
          rightIcon={
            <FontAwesomeIcon
              icon={regular('chevron-down')}
              transform="shrink-6"
            />
          }
        >
          <FontAwesomeIcon icon={regular('palette')} />
        </MenuButton>
      </GammaTooltip>
      <MenuList
        maxH="min(25em, 45vh)"
        overflowY="auto"
        maxW="450px"
        minW="unset"
      >
        <MenuGroup key="none" title="Default color">
          <ToolbarButton
            onClick={() => setCardColor(undefined)}
            label={'Default'}
            isActive={currentColor === undefined}
          >
            <ColorIcon color={themeCardColor} />
          </ToolbarButton>
        </MenuGroup>
        <MenuGroup key="none" title="Custom color">
          <CardColorMenuButtonGroup editor={editor} selection={selection} />
        </MenuGroup>
      </MenuList>
    </Menu>
  )
}

export const CardColorMenuButtonGroup = ({
  editor,
  selection,
}: CardColorMenuProps) => {
  const { setCardColor, currentColor, accentColors, hasNonThemeColor } =
    useCardColorMenu({
      editor,
      selection,
    })
  return (
    <ButtonGroup size="sm" variant="ghost">
      <>
        {DEFAULT_COLORS.map(({ label, color }) => {
          return (
            <ToolbarButton
              onClick={() => setCardColor(color)}
              key={color}
              label={label}
              isActive={currentColor === color}
            >
              <ColorIcon color={color} />
            </ToolbarButton>
          )
        })}
        {accentColors.map((color: string) => {
          return (
            <ToolbarButton
              onClick={() => setCardColor(color)}
              key={color}
              label={color}
              isActive={currentColor === color}
            >
              <ColorIcon color={color} />
            </ToolbarButton>
          )
        })}
        {hasNonThemeColor && (
          <ToolbarButton
            onClick={() => setCardColor(currentColor)}
            key={'current'}
            label={'Current color'}
            isActive={true}
          >
            <ColorIcon color={currentColor} />
          </ToolbarButton>
        )}
      </>
    </ButtonGroup>
  )
}

const ColorIcon = ({ color }) => {
  return (
    <Box
      boxSize={5}
      borderRadius="md"
      border="1px solid"
      borderColor="gray.200"
      bgColor={color}
    ></Box>
  )
}
