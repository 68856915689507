import { ButtonGroup } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import { CalloutBoxOptions } from 'modules/tiptap_editor/extensions/CalloutBox/options'
import { CalloutBoxAttrs } from 'modules/tiptap_editor/extensions/CalloutBox/types'

import { DeleteNodeButton } from '../buttons/DeleteNodeButton'
import { ToolbarButton } from '../buttons/ToolbarButton'
import { FormattingMenuDivider } from './FormattingMenuDivider'
import { NodeFormattingMenuProps } from './types'

export const CalloutBoxFormattingMenu = ({
  editor,
  selection,
}: NodeFormattingMenuProps) => {
  const attrs = selection.node.attrs as CalloutBoxAttrs

  return (
    <ButtonGroup spacing={2} size="sm">
      {Object.entries(CalloutBoxOptions).map(([variant, { name, icon }]) => {
        return (
          <ToolbarButton
            key={name}
            label={name}
            icon={icon}
            isActive={attrs.icon && attrs.variant === variant}
            onClick={() => {
              editor
                .chain()
                .focus()
                .updateAttributes('calloutBox', { variant, icon: true })
                .selectNodeAtPos(selection.from)
                .run()
            }}
          />
        )
      })}
      <ToolbarButton
        key={'icon'}
        label={attrs.icon ? 'Hide icon' : 'Show icon'}
        icon={regular('ban')}
        isActive={!attrs.icon}
        onClick={() => {
          editor
            .chain()
            .focus()
            .updateAttributes('calloutBox', { icon: !attrs.icon })
            .selectNodeAtPos(selection.from)
            .run()
        }}
      />
      <FormattingMenuDivider />
      <DeleteNodeButton editor={editor} />
    </ButtonGroup>
  )
}
