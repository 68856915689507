import { ACCENT_IMAGE_SOURCE_KEY } from 'modules/theming'
import { MediaSourceType } from 'modules/tiptap_editor/extensions/media/MediaSources'
import { BackgroundType } from 'modules/tiptap_editor/styles/backgroundStyles'

export const isValidBackgroundSource = (source: MediaSourceType) =>
  !!source.backgroundType

export const isNotAccentSource = (source: MediaSourceType) =>
  source.key !== ACCENT_IMAGE_SOURCE_KEY

export const isNotBlankBackgroundSource = (source: MediaSourceType) =>
  source.backgroundType !== BackgroundType.NONE
