import { createSlice } from '@reduxjs/toolkit'

import { Messages } from './types'

export interface DesignPartnerState {
  messages: Messages[]
}

const initialState: DesignPartnerState = {
  messages: [],
}

const DesignPartnerSlice = createSlice({
  name: 'DesignPartner',
  initialState,
  reducers: {
    reset: () => initialState,
    addMessage(state, action: { payload: { message } }) {
      const { message } = action.payload
      const newMessages = [...state.messages, message]
      state.messages = newMessages
    },
  },
})

type ReduxState = {
  [DesignPartnerSlice.name]: DesignPartnerState
}

export const { addMessage, reset } = DesignPartnerSlice.actions

// Selectors
export const selectMessages = (state: ReduxState) =>
  state.DesignPartner.messages

// Reducer
export const reducer = DesignPartnerSlice.reducer
