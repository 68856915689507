import { capitalize } from 'lodash'

import { WorkspaceRole } from 'modules/api'

export const WORKSPACE_NAME_MIN_LENGTH = 1

export const WORKSPACE_ADMIN_DISPLAY_NAME = 'admin'
export const WORKSPACE_MEMBER_DISPLAY_NAME = 'member'

export const workspaceRoleMap = {
  [WorkspaceRole.Admin]: {
    title: capitalize(WORKSPACE_ADMIN_DISPLAY_NAME),
    description: `Can change workspace settings, assign roles, and invite workspace ${WORKSPACE_MEMBER_DISPLAY_NAME}s`,
  },
  [WorkspaceRole.Member]: {
    title: capitalize(WORKSPACE_MEMBER_DISPLAY_NAME),
    description: `Cannot change workspace settings or manage workspace ${WORKSPACE_MEMBER_DISPLAY_NAME}s`,
  },
}
