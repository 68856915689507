import { CSSObject } from '@emotion/react'

import {
  CARD_CONTENT_CLASS,
  CARD_WRAPPER_CLASS,
} from 'modules/tiptap_editor/extensions/Card/constants'

import { Theme } from '../types'
import { getBoxStylesheet } from './box'
import { getHeadingStylesheet } from './heading'

// Generates the CSS for the theme
// This will be applied once, at the deck level
export const getThemeStylesheet = (
  theme: Theme,
  isEditable: boolean
): CSSObject => {
  return {
    ...getHeadingStylesheet(theme),
    ...getBoxStylesheet(theme, isEditable),
    [`.${CARD_WRAPPER_CLASS} .${CARD_CONTENT_CLASS}`]:
      theme.config.contentStyles,
  }
}
