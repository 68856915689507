import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { useAppSelector } from 'modules/redux'

import { selectExpandedMediaId, setMediaNodeExpanded } from '../../../reducer'
import { ZoomTransition } from './constants'

const selectIsExpanded = (id: string) => (state) =>
  selectExpandedMediaId(state) === id

const selectZoomableId = (ids: string[]) => (state) => {
  const expandedId = selectExpandedMediaId(state)
  if (!expandedId) {
    return null
  }

  return ids.includes(expandedId) ? expandedId : null
}

export const useMediaZoom = (id: string) => {
  const dispatch = useDispatch()
  const isZoomed = useAppSelector(selectIsExpanded(id))
  const setIsZoomed = useCallback(
    (isExpanded: boolean) => {
      dispatch(setMediaNodeExpanded({ nodeId: isExpanded ? id : null }))
    },
    [dispatch, id]
  )
  const enterZoom = useCallback(() => setIsZoomed(true), [setIsZoomed])
  const exitZoom = useCallback(() => setIsZoomed(false), [setIsZoomed])
  return {
    isZoomed,
    setIsZoomed,
    enterZoom,
    exitZoom,
  }
}

export const useCarouselZoom = (ids: string[]) => {
  const dispatch = useDispatch()
  const zoomedId = useAppSelector(selectZoomableId(ids))
  const isZoomed = !!zoomedId
  const setZoomedId = useCallback(
    (id: string | null) => {
      dispatch(setMediaNodeExpanded({ nodeId: id }))
    },
    [dispatch]
  )
  const exitZoom = useCallback(() => setZoomedId(null), [setZoomedId])

  // Tracks whether the zoom animation is complete
  const [isZoomComplete, setZoomComplete] = useState(isZoomed)
  useEffect(() => {
    let timer: number
    if (isZoomed) {
      timer = window.setTimeout(() => {
        setZoomComplete(true)
      }, ZoomTransition.duration * 1000)
    } else {
      setZoomComplete(false)
    }
    return () => clearTimeout(timer)
  }, [isZoomed])

  return {
    isZoomed,
    isZoomComplete,
    zoomedId,
    setZoomedId,
    exitZoom,
  }
}
