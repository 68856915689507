import { Box, HStack, Spinner, Text } from '@chakra-ui/react'
import { cx } from '@chakra-ui/utils'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NodeViewProps } from '@tiptap/core'
import { memo } from 'react'

import { useGetCard } from 'modules/cards'
import { HEADING_CLASS } from 'modules/theming/styles/heading'

import { getFontSizeStyles } from '../Font/fontStyles'
import { CardAttributes } from './types'

interface CollapsedCardPreviewProps {
  cardId: string
  isCollapsed: boolean
  node: NodeViewProps['node']
}
const CollapsedCardTitleStyles = getFontSizeStyles('h4')

export const CollapsedCardPreview = memo(function CollapsedCardPreview({
  isCollapsed,
  cardId,
  node,
}: CollapsedCardPreviewProps) {
  const cardData = useGetCard(cardId)
  const loading = cardData === undefined
  const title = cardData?.title
  const text = cardData?.text
  const { previewContent } = node.attrs as CardAttributes
  const hidePreview = previewContent === ''

  return (
    <Box
      display={isCollapsed ? 'block' : 'none'}
      contentEditable={false}
      userSelect="none"
      data-collapsed-card-preview
    >
      <Text
        sx={CollapsedCardTitleStyles}
        noOfLines={1}
        mr={8} // Make room for card controls
        mb={2}
        className={cx(HEADING_CLASS)}
      >
        {title ? (
          title
        ) : loading ? (
          <>
            <Spinner size="sm" mr={2} /> Loading...
          </>
        ) : (
          <Text as="span" color="gray.400">
            Untitled card
          </Text>
        )}
      </Text>
      <HStack color="var(--body-color-muted)">
        <Text noOfLines={1} fontSize="0.9em">
          {hidePreview ? 'View more' : text}
        </Text>
        {hidePreview && (
          <FontAwesomeIcon icon={regular('chevron-down')} size="sm" />
        )}
      </HStack>
    </Box>
  )
})
