import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import { ToolbarButton } from 'modules/tiptap_editor/components/menus/buttons/ToolbarButton'

import { SmartLayoutOption, SmartLayoutOptionControlProps } from '../types'

type Orientation = 'horizontal' | 'vertical'

const OrientationOptionControl = ({
  value,
  updateValue,
}: SmartLayoutOptionControlProps<Orientation>) => {
  return (
    <ToolbarButton
      label="Direction"
      onClick={() =>
        updateValue(value === 'horizontal' ? 'vertical' : 'horizontal')
      }
      isActive={value === 'horizontal'}
      icon={
        value === 'horizontal' ? regular('arrow-right') : regular('arrow-down')
      }
      testId="smart-layout-orientation-option"
    />
  )
}

// Todo: support more options like solid/dashed

export const OrientationOption: SmartLayoutOption<Orientation> = {
  key: 'orientation',
  defaultValue: 'vertical',
  Control: OrientationOptionControl,
}
