import { JSONContent, NodeWithPos } from '@tiptap/core'
import { clone } from 'lodash'
import { Node } from 'prosemirror-model'

import { isListNode } from 'modules/tiptap_editor/extensions/lists/ListTypes'

import { selectionHasMatchingNodes } from '../../utils'
import { MaxHeadingLevel } from '../Heading/Heading'

type ListItemTree = {
  node: Node
  pos: number
  children: ListItemTree[]
}

// Take a flat array of list items with indents
// And turn it into an array of top-level items
// with children representing sub-bullets
export const listToTree = (listItems: NodeWithPos[]): ListItemTree[] => {
  const listItemsWithChildren: ListItemTree[] = []
  for (let i = 0; i < listItems.length; i++) {
    const item = listItems[i]
    const indent = item.node.attrs.indent
    // For each item, find all the bullets immediately after it
    // at a lower level
    const children = findNextItems(
      listItems,
      i,
      (other) => other.node.attrs.indent > indent
    )
    // Recursively turn them into a tree, since they may have
    // children themselves
    const childrenTree = listToTree(children)
    listItemsWithChildren.push({
      node: item.node,
      pos: item.pos,
      children: childrenTree,
    })
    // Skip over the children we just processed
    i += children.length
  }
  return listItemsWithChildren
}

// Iterate through the array starting from index
// Return an array of the next items that match the predicate
// Break as soon as an array doesn't match the predicate
const findNextItems = <T>(
  arr: T[],
  index: number,
  predicate: (item: T) => boolean
): T[] => {
  const items: T[] = []
  for (let i = index + 1; i < arr.length; i++) {
    const item = arr[i]
    if (predicate(item)) {
      items.push(item)
    } else {
      break
    }
  }
  return items
}

// Converts a tree of list items into a tree of cards for insertion
export const listTreeToCards = (listTree: ListItemTree[]): JSONContent[] => {
  return listTree.map((item) => {
    const childCards = listTreeToCards(item.children)
    return {
      type: 'card',
      content: [
        {
          type: 'heading',
          attrs: {
            // Parents should be H1, children should be H2, etc.
            level: Math.min(item.node.attrs.indent + 1, MaxHeadingLevel),
          },
          content: [{ type: 'text', text: item.node.textContent }],
        },
        ...childCards,
      ],
    }
  })
}

// Converts a tree of list items into a smart layout for insertion
export const listTreeToSmartLayout = (
  listTree: ListItemTree[],
  variant: string
): JSONContent => {
  return {
    type: 'smartLayout',
    attrs: {
      variantKey: variant,
    },
    content: listTree.map((item) => {
      const content = [
        {
          type: 'heading',
          attrs: {
            level: 4,
          },
          content: clone(item.node.content.toJSON()),
        },
      ]
      content.push(
        ...item.children.map((t) => {
          const json = clone(t.node.toJSON())
          json.attrs.indent -= 1
          return json
        })
      )

      return {
        type: 'smartLayoutCell',
        content,
      }
    }),
  }
}

export const areListNodesSelected = (editor) =>
  selectionHasMatchingNodes(editor, isListNode)
