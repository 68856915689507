import { SystemStyleObject } from '@chakra-ui/react'
import { CSSObject } from '@emotion/react'
import { CardLayout } from '../types'
import { CardLayoutItemIds } from './CardLayoutItem'

type CardLayoutItemSpec = {
  type: 'body' | 'accent'
  style?: CSSObject
}

export type CardLayoutPreset = {
  grid: SystemStyleObject
  numColumns: number
  items: Partial<
    {
      [id in CardLayoutItemIds]: CardLayoutItemSpec
    }
  >
  stickyAccent?: boolean
  allowResizing?: boolean
}
export const MIN_ACCENT_HEIGHT = 'minmax(20em, auto)'

const behindTemplateRows = [
  'minmax(185px, auto)',
  'minmax(225px, auto)',
  MIN_ACCENT_HEIGHT,
]
const topTemplateRows = [
  'minmax(125px, auto) auto',
  'minmax(185px, auto) auto',
  'minmax(225px, auto) auto',
]

export const LAYOUT_PRESETS: {
  [key in CardLayout]: CardLayoutPreset
} = {
  top: {
    grid: {
      gridTemplateColumns: '1fr',
      gridTemplateRows: topTemplateRows,
      gridTemplateAreas: `"accent" "body"`,
    },
    items: {
      accent: { type: 'accent' },
      body: { type: 'body' },
    },
    numColumns: 1,
    allowResizing: false,
  },
  behind: {
    grid: {
      gridTemplateColumns: '1fr',
      gridTemplateRows: behindTemplateRows,
    },
    items: {
      accent: {
        type: 'accent',
        style: {
          gridArea: '1 / 1',
        },
      },
      body: {
        type: 'body',
        style: {
          gridArea: '1 / 1',
          zIndex: 1,
        },
      },
    },
    numColumns: 1,
    stickyAccent: true,
    allowResizing: false,
  },
  blank: {
    grid: {
      gridTemplateColumns: '1fr',
      gridTemplateRows: '1fr',
      gridTemplateAreas: `"body"`,
    },
    items: {
      body: { type: 'body' },
    },
    numColumns: 1,
    allowResizing: false,
  },
  left: {
    grid: {
      gridTemplateColumns: '37.5% 62.5%',
      gridTemplateRows: MIN_ACCENT_HEIGHT,
      gridTemplateAreas: `"accent body"`,
    },
    items: {
      accent: { type: 'accent' },
      body: { type: 'body' },
    },
    numColumns: 2,
    stickyAccent: true,
    allowResizing: true,
  },
  right: {
    grid: {
      gridTemplateColumns: '62.5% 37.5%',
      gridTemplateRows: MIN_ACCENT_HEIGHT,
      gridTemplateAreas: `"body accent"`,
    },
    items: {
      accent: { type: 'accent' },
      body: { type: 'body' },
    },
    numColumns: 2,
    stickyAccent: true,
    allowResizing: true,
  },
}
