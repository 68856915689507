import { memoize } from 'lodash'
import { useCallback, useEffect, useState } from 'react'

class EditorRenderedState {
  renderedMap: { [id: string]: boolean } = {
    main: false,
  }

  listeners: { [id: string]: (() => void)[] } = {}

  subscribe(id: string, fn: () => void) {
    this.listeners[id] = this.listeners[id] || []
    this.listeners[id].push(fn)

    return () => {
      try {
        if (!this.listeners[id]) {
          return
        }

        const ind = this.listeners[id].indexOf(fn)
        if (ind > -1) {
          this.listeners[id].splice(ind, 1)
        }
      } catch (e) {
        // swallow
      }
    }
  }

  isRendered(id: string) {
    return this.renderedMap[id]
  }

  onRendered(id: string) {
    this.renderedMap[id] = true
    this.listeners[id]?.forEach((fn) => fn())
  }

  onEditorUnload(id: string) {
    this.renderedMap[id] = false
    this.listeners[id] = []
  }
}

const getEditorRenderedState = memoize(() => new EditorRenderedState())

export const useOnEditorRenderedControls = (id?: string) => {
  const setEditorRendered = useCallback(() => {
    if (!id) {
      return
    }
    getEditorRenderedState().onRendered(id)
  }, [id])
  const onEditorUnload = useCallback(() => {
    if (!id) {
      return
    }
    getEditorRenderedState().onEditorUnload(id)
  }, [id])

  return { setEditorRendered, onEditorUnload }
}

export const useOnEditorRendered = (id?: string) => {
  const [isRendered, setIsRendered] = useState(
    // assume that if an editor rendered isn't explicitly false we assume it's rendered already
    // we dont need to make optimizations for non main editors
    id === undefined ? true : getEditorRenderedState().isRendered(id) ?? true
  )

  useEffect(() => {
    if (!id) {
      return
    }
    return getEditorRenderedState().subscribe(id, () => {
      setIsRendered(true)
    })
  }, [id])

  return isRendered
}
