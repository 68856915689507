import { Circle } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome'

export const SharePanelRowIcon = ({
  icon,
  showDisabled = false,
}: {
  icon: FontAwesomeIconProps['icon']
  showDisabled?: boolean
}) => {
  return (
    <Circle
      color="gray.800"
      textAlign="center"
      bg="gray.200"
      size={8}
      opacity={showDisabled ? 0.5 : 1}
    >
      {showDisabled ? (
        <span className="fa-stack">
          <FontAwesomeIcon icon={regular('slash')} className="fa-stack-1x" />
          <FontAwesomeIcon icon={icon} className="fa-stack-1x" />
        </span>
      ) : (
        <FontAwesomeIcon size="1x" icon={icon} />
      )}
    </Circle>
  )
}
