import { SearchIcon } from '@chakra-ui/icons'
import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Circle,
  Fade,
  Flex,
  HStack,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Spinner,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
  AutoCompleteRefMethods,
  Item,
  UseAutoCompleteProps,
} from '@choc-ui/chakra-autocomplete'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DOC_DISPLAY_NAME, GammaTooltip } from '@gamma-app/ui'
import { validate as validateEmail } from 'email-validator'
import { motion } from 'framer-motion'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import {
  DocCollaborator,
  DocCollaboratorsUpdateFragmentDoc,
  DocInvitation,
  Permission,
  ShareSearchUserFragment,
  SharingSearchUserDocument,
  SharingSearchUserQuery,
  SharingSearchUserQueryVariables,
  useAddCollaboratorsMutation,
  User,
  useSharingSearchUserQuery,
} from 'modules/api'
// This separate import is needed to get tests to pass :(
import { useLazyQueryPromise } from 'modules/api/apollo/hooks'
import { REASON_FOR_DISABLED_MANAGE_PERMISSION } from 'modules/sharing/constants'
import { Deferred } from 'utils/deferred'
import { joinUserNames } from 'utils/displayNames'
import { useHandleError } from 'utils/hooks'

import {
  PermissionMapToHumanReadable,
  PermissionsMenu,
} from './PermissionsMenu'
import { SearchBarCollaboratorTag } from './SearchBarTag'
import { UnsentInvitationsModal } from './UnsentInvitationsModal'

const MotionBox = motion(Box)

type CollaboratorItem = Item & {
  originalValue: {
    email: string
    type: 'collaborator'
  }
}

type OnSelectOptionArgs = UseAutoCompleteProps['onSelectOption'] & {
  item: CollaboratorItem
}

interface InvitedUser {
  email: string
}

interface CollaboratorSearchBarProps {
  // TODO It's unclear how we'll choose organizations in the future (UX
  // pending), so this just makes it work for now.  Since this is only used
  // (so far) on the sharing panel, there shouldn't ever be a situation where
  // someone without an org can see this.
  workspaceId: string
  docId: string
  user: User
  existingDocCollaborators: DocCollaborator[]
  existingInvitations: DocInvitation[]
  registerDoneFn: React.Dispatch<React.SetStateAction<() => Promise<any>>>
  isDisabled: boolean
}

type UserOrInvitedUser = User | InvitedUser

const addCollaboratorToList = (
  current: SearchItem[],
  searchUsers: User[],
  email: string
): SearchItem[] => {
  if (!email) return current
  const next = [...current]

  const selectedIndex = current.findIndex(
    (s) => 'email' in s && s.email === email
  )
  const existingUser = searchUsers.find((u) => u.email === email)

  // If there is an existing user with this email, remove them first
  if (selectedIndex !== -1) {
    next.splice(selectedIndex, 1)
  }

  return [...next, existingUser ? existingUser : { email }]
}

type SearchItem = UserOrInvitedUser
type SearchResult = ShareSearchUserFragment

export const CollaboratorSearchBar = ({
  workspaceId,
  docId,
  user,
  existingDocCollaborators,
  existingInvitations,
  registerDoneFn,
  isDisabled,
}: CollaboratorSearchBarProps) => {
  const toast = useToast()
  const [isProcessingBatch, setProcessingBatch] = useState(false)
  const autocompleteRef = useRef<AutoCompleteRefMethods>()
  const inputRef = useRef<HTMLInputElement>(null)
  const [inputValue, setInputValue] = useState('')
  const [searchResults, setSearchResults] = useState<SearchResult[]>([])
  const [permissionValue, setPermissionValue] = useState<Permission>(
    Permission.Manage
  )
  const [doneDef, setDoneDef] = useState<Deferred<any>>(new Deferred())
  const [selectedItems, setSelectedItems] = useState<SearchItem[]>([])
  const selectedCollaborators = selectedItems.filter(
    (i): i is UserOrInvitedUser => 'email' in i
  )

  const existingCollaborators = existingDocCollaborators!.map((c) => c.user)

  const {
    isOpen: isUnsentInvitationsModalOpen,
    onOpen: onUnsentInvitationsModalOpen,
    onClose: onUnsentInvitationsModalClose,
  } = useDisclosure({ id: 'unsentInvitationsModalDisclosure' })

  const [
    addCollaborators,
    { loading: isSavingCollabs, error: addCollabError },
  ] = useAddCollaboratorsMutation()

  useHandleError('Error inviting collaborators.', addCollabError)

  const isSaving = isSavingCollabs

  // This search function returns a promise so we can use it standalone.
  const manualSearch = useLazyQueryPromise<
    SharingSearchUserQuery,
    SharingSearchUserQueryVariables
  >(SharingSearchUserDocument)
  const { data: searchData, loading } = useSharingSearchUserQuery({
    variables: {
      query: inputValue,
      workspaceId,
    },
    skip: !inputValue,
  })

  useEffect(() => {
    if (loading || !searchData) return
    const incoming = searchData.search.filter(Boolean) as SearchResult[]
    setSearchResults(incoming)
  }, [searchData, loading])

  const searchUsers = useMemo(
    () =>
      (inputValue
        ? searchResults.filter(
            (d) =>
              d.__typename === 'User' &&
              d.id !== user.id &&
              !selectedCollaborators
                .concat(existingCollaborators)
                .find((c) => c.email === d.email)
          )
        : []) as User[],
    [
      searchResults,
      existingCollaborators,
      inputValue,
      selectedCollaborators,
      user.id,
    ]
  )

  const showCreatable =
    Boolean(inputValue) && !searchUsers.find((u) => u.email === inputValue)

  const hasSelectedNonWorkspaceUsersAsOwners =
    permissionValue === Permission.Manage &&
    selectedCollaborators.some(
      (c) => !('__typename' in c && c.__typename === 'User')
    )
  const currentValidItems = selectedItems
    .concat({ email: inputValue })
    .filter((u) => ('email' in u ? validateEmail(u.email || '') : true))

  const showControls = Boolean(selectedItems.length)
  const hasSomeValidItems = Boolean(currentValidItems.length)

  useEffect(() => {
    // Let the parent sharepanel know what function to call when it's about
    // to close to ensure that we (the CollaboratorSearchBar) are OK to close.
    registerDoneFn(() => () => {
      // When the parent is about to close, show the unsent invitations
      // modal if we have unsaved collaborators.
      if (!hasSomeValidItems) {
        doneDef.resolve()
      } else {
        onUnsentInvitationsModalOpen()
      }
      return doneDef.promise
    })
  }, [registerDoneFn, doneDef, hasSomeValidItems, onUnsentInvitationsModalOpen])

  const addCollaboratorBatch = useCallback(
    /**
     * Adds a list of collaborators to the selected set and searches for each
     * one individually to see if the email matches an existing one.
     */
    async (list: string[]) => {
      setProcessingBatch(true)

      // Add the entire batch of users to the list right away
      setSelectedItems((prev) => {
        return list.reduce((acc, part) => {
          return addCollaboratorToList(acc, searchUsers, part.trim())
        }, prev)
      })

      // For each email, search for a match.
      const manualSearchResult = (
        await Promise.all(
          list.map((email: string) => {
            return manualSearch({ workspaceId, query: email }).then(
              (result) => {
                return result.data?.search.find(
                  (item) => item.__typename === 'User' && item.email === email
                )
              }
            )
          })
        )
      ).filter(Boolean) as User[]

      // Using the manual search results for each user, update the list again.
      setSelectedItems((prev) => {
        return list.reduce((acc, part) => {
          return addCollaboratorToList(acc, manualSearchResult, part.trim())
        }, prev)
      })

      setProcessingBatch(false)
    },
    [searchUsers, manualSearch, workspaceId]
  )

  const addSelectedCollaborators = useCallback(async () => {
    if (selectedCollaborators.length === 0) return

    // Grab the current input value in case the user forgot to submit the text input
    const validCollaborators = addCollaboratorToList(
      selectedCollaborators,
      searchUsers,
      inputValue.trim()
    ).filter(
      (u): u is UserOrInvitedUser =>
        'email' in u && validateEmail(u.email || '')
    )

    const optimisticCollabs = [
      ...existingDocCollaborators,
      ...validCollaborators.map((collab) => {
        if (!('id' in collab)) return
        const docCollab: DocCollaborator = {
          docId,
          user: collab,
          guest: false,
          permission: permissionValue,
          __typename: 'DocCollaborator',
        }
        return docCollab
      }),
    ].filter((i): i is DocCollaborator => Boolean(i))

    const optimisticInvitations = [
      ...existingInvitations,
      ...validCollaborators.map((collab) => {
        if ('id' in collab) return
        const docInvitation: DocInvitation = {
          id: 'temp',
          docTitle: 'temp-title',
          invitedBy: user,
          docId,
          email: collab.email,
          permission: permissionValue,
          __typename: 'DocInvitation',
        }
        return docInvitation
      }),
    ].filter((i): i is DocInvitation => Boolean(i))

    return addCollaborators({
      variables: {
        docId,
        collaborators: validCollaborators.map((c) => {
          const permission = { permission: permissionValue }
          const collaborator = 'id' in c ? { userId: c.id } : { email: c.email }
          return { ...collaborator, ...permission }
        }),
      },
      update: (cache, { data }) => {
        cache.writeFragment({
          id: `Doc:${docId}`,
          fragment: DocCollaboratorsUpdateFragmentDoc,
          data: data?.addCollaborators,
        })
      },
      optimisticResponse: {
        addCollaborators: {
          id: docId,
          collaborators: optimisticCollabs,
          invitations: optimisticInvitations,
        },
      },
    }).then(() => {
      const userNames = joinUserNames(
        validCollaborators.map((u) =>
          'displayName' in u ? u.displayName! : u.email!
        ),
        3
      )
      const plural = validCollaborators.length > 1
      toast({
        title: `Invitation${plural ? 's' : ''} sent`,
        description: `${userNames} ${plural ? 'were' : 'was'} invited to ${
          PermissionMapToHumanReadable[permissionValue].verb
        } this ${DOC_DISPLAY_NAME}.`,
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top',
      })
    })
  }, [
    addCollaborators,
    docId,
    inputValue,
    permissionValue,
    searchUsers,
    selectedCollaborators,
    existingDocCollaborators,
    existingInvitations,
    toast,
    user,
  ])

  const inviteSelected = useCallback(async () => {
    return Promise.all([addSelectedCollaborators()])
      .then(() => {
        setSelectedItems([])
        setInputValue('')
        autocompleteRef.current?.resetItems(true)
        doneDef.resolve()
      })
      .catch((e) => {
        console.warn(e)
      })
  }, [addSelectedCollaborators, doneDef])

  const shouldDisableInput = isDisabled || isSaving || isProcessingBatch

  return (
    <ButtonGroup
      w="100%"
      mb={2}
      alignItems="flex-start"
      data-testid="sharepanel-search-bar"
    >
      <Box flex={1}>
        <InputGroup w="100%" justifyContent="center" zIndex={1}>
          <Flex
            display={isProcessingBatch ? 'flex' : 'none'}
            position="absolute"
            h="100%"
            w="100%"
            alignItems="center"
            justifyContent="center"
            bg="gray.300"
            zIndex="overlay"
            opacity={0.4}
          >
            <Spinner />
          </Flex>
          <AutoComplete
            ref={autocompleteRef}
            values={selectedItems}
            maxSelections={100}
            maxSuggestions={20}
            creatable={true}
            multiple={true}
            freeSolo={true}
            suggestWhenEmpty={false}
            openOnFocus={true}
            filter={(_query: string, itemValue: string) => {
              return !selectedCollaborators.find((s) => s.email === itemValue)
            }}
            // @ts-ignore
            onSelectOption={({ item }: OnSelectOptionArgs) => {
              const { type } = item.originalValue
              if (type === 'collaborator') {
                setSelectedItems((prev) =>
                  addCollaboratorToList(prev, searchUsers, item.value)
                )
              }
              setInputValue('')
            }}
            onTagRemoved={(value) => {
              setSelectedItems((prev) =>
                [...prev].filter((c) => {
                  return 'email' in c
                    ? c.email !== value
                    : 'id' in c
                    ? c.id !== value
                    : true
                })
              )
            }}
          >
            <InputLeftElement
              pointerEvents="none"
              color="gray.300"
              h="100%"
              flexDirection="column"
            >
              {/**
               * Use a flex 1 div to slam the Search Icon to the bottom.
               * Necessary until this libary supports InputGroup inside AutoCompleteInput
               * See https://github.com/anubra266/choc-autocomplete/issues/63
               */}
              <Flex flex={1} />
              <Flex h={10} py={4} alignItems="center">
                <SearchIcon w={10} />
              </Flex>
            </InputLeftElement>
            <AutoCompleteInput
              ref={inputRef}
              data-testid="autocomplete-collaborators-input"
              cursor={shouldDisableInput ? 'not-allowed' : undefined}
              disabled={shouldDisableInput}
              w={'100%'}
              pl={5}
              wrapStyles={{
                px: 2,
              }}
              fontSize="md"
              placeholder="Add emails or people"
              transition="width 1s ease-in-out"
              value={inputValue}
              onKeyDown={({ key }) => {
                if (
                  key === 'Backspace' &&
                  inputValue.length === 0 &&
                  selectedItems.length > 0
                ) {
                  // Delete the last item
                  const lastItem = selectedItems.slice(-1).pop()
                  if (lastItem) {
                    autocompleteRef.current?.removeItem(
                      'email' in lastItem ? lastItem.email : lastItem.id
                    )
                  }
                }
              }}
              onPaste={(e: React.ClipboardEvent<HTMLInputElement>) => {
                e.preventDefault()
                e.stopPropagation()
                const value = e.clipboardData.getData('Text')
                const parts = value.trim().split(/[,|;\s]+/)
                addCollaboratorBatch(parts)
                setInputValue('')
                setSearchResults([])
              }}
              onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
                const { value } = target
                setInputValue(value)

                if (!value) {
                  setSearchResults([])
                }
              }}
            >
              {selectedItems.map((selectedItem, idx) => {
                return (
                  <SearchBarCollaboratorTag
                    key={
                      'id' in selectedItem
                        ? selectedItem.id
                        : selectedItem.email
                    }
                    idx={idx}
                    selectedCollaborator={selectedItem}
                    handleRemoveClick={() => {
                      autocompleteRef.current?.removeItem(selectedItem.email)
                    }}
                  />
                )
              })}
            </AutoCompleteInput>
            <AutoCompleteList
              w="100%"
              mt={0}
              display={inputValue.length > 0 ? 'flex' : 'none'}
            >
              {
                // Make sure all <AutoCompleteItem> children are in 1 list
                // See https://github.com/anubra266/choc-autocomplete/issues/146
                searchUsers
                  .map(({ id, email, displayName, profileImageUrl }) => {
                    return (
                      <AutoCompleteItem
                        key={id}
                        fixed={true}
                        data-testid={`autocomplete-item-user-${id}`}
                        value={{
                          email,
                          type: 'collaborator',
                        }}
                        getValue={(i) => i.email}
                        align="center"
                        _focus={{
                          bg: 'trueblue.50',
                        }}
                      >
                        <HStack>
                          <Avatar
                            size="sm"
                            name={displayName}
                            src={profileImageUrl}
                          />
                          <Box>
                            <Text textTransform="capitalize">
                              {displayName}
                            </Text>
                            <Text fontSize="xs" color="gray.500">
                              {email?.toLowerCase()}
                            </Text>
                          </Box>
                        </HStack>
                      </AutoCompleteItem>
                    )
                  })
                  .concat(
                    <AutoCompleteItem
                      key={inputValue}
                      value={{
                        email: inputValue,
                        type: 'collaborator',
                      }}
                      align="center"
                      display={showCreatable ? 'flex' : 'none'}
                      _focus={{
                        bg: 'trueblue.50',
                      }}
                    >
                      <HStack>
                        <Circle bg="trueblue.100" size={8}>
                          <FontAwesomeIcon icon={regular('envelope')} />
                        </Circle>
                        <Text size="md">{inputValue}</Text>
                      </HStack>
                    </AutoCompleteItem>
                  )
              }
            </AutoCompleteList>
          </AutoComplete>

          <Fade in={showControls} unmountOnExit={true}>
            <InputRightElement w="auto" justifyContent="flex-end" pr={1}>
              <PermissionsMenu
                isDisabled={isSaving}
                options={[
                  Permission.Manage,
                  Permission.Edit,
                  Permission.Comment,
                  Permission.View,
                ]}
                variant="ghost"
                selected={permissionValue}
                onClick={(permission) =>
                  setPermissionValue(permission as Permission)
                }
              />
            </InputRightElement>
          </Fade>
        </InputGroup>
      </Box>
      <MotionBox
        overflowX="hidden"
        mr={-2}
        transition="slow"
        animate={{
          maxWidth: showControls ? '200px' : '0px',
        }}
      >
        <GammaTooltip
          label={REASON_FOR_DISABLED_MANAGE_PERMISSION}
          aria-label={REASON_FOR_DISABLED_MANAGE_PERMISSION}
          isDisabled={!hasSelectedNonWorkspaceUsersAsOwners}
          placement="top"
        >
          <Box>
            <Button
              variant="solid"
              onClick={inviteSelected}
              isDisabled={
                !hasSomeValidItems || hasSelectedNonWorkspaceUsersAsOwners
              }
              isLoading={isSaving}
              data-testid="add-selected-items"
            >
              Add
            </Button>
          </Box>
        </GammaTooltip>
      </MotionBox>
      {isUnsentInvitationsModalOpen && (
        <UnsentInvitationsModal
          invitationCount={currentValidItems.length}
          onDiscardClick={() => {
            onUnsentInvitationsModalClose()
            doneDef.resolve()
          }}
          onSendInvitationsClick={() => {
            onUnsentInvitationsModalClose()
            inviteSelected()
          }}
          onClose={() => {
            onUnsentInvitationsModalClose()
            doneDef.reject()
            setDoneDef(new Deferred())
          }}
          isOpen={isUnsentInvitationsModalOpen}
        />
      )}
    </ButtonGroup>
  )
}
