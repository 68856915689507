import { ButtonGroup } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useCallback } from 'react'

import { DrawingAttrs } from 'modules/tiptap_editor/extensions/Drawing'

import { AlignmentCommands } from '../../../extensions/HorizontalAlign'
import { DeleteNodeButton } from '../buttons/DeleteNodeButton'
import { ToolbarButton } from '../buttons/ToolbarButton'
import { FormattingMenuDivider } from './FormattingMenuDivider'
import { NodeFormattingMenuProps } from './types'

export const DrawingFormattingMenu = ({
  editor,
  selection,
}: NodeFormattingMenuProps) => {
  const nodeName = selection.node.type.name
  const { fullWidthBlock } = editor.getAttributes(nodeName) as DrawingAttrs

  const editNode = useCallback(
    (ev) => {
      ev.stopPropagation()
      editor.commands.editSelectedDrawingBlock?.()
    },
    [editor]
  )

  return (
    <ButtonGroup spacing={0} size="sm" alignItems="center">
      {AlignmentCommands.map(({ name, icon, checkActive, apply }) => {
        if (!checkActive || !apply) return
        return (
          <ToolbarButton
            key={name}
            label={name}
            icon={icon}
            onClick={() => {
              apply(editor)
              if (fullWidthBlock) {
                editor.commands.updateAttributes(nodeName, {
                  fullWidthBlock: false,
                })
              }
            }}
            isActive={!fullWidthBlock && checkActive(editor)}
          />
        )
      })}
      <ToolbarButton
        label={'Edit'}
        icon={regular('pencil')}
        onClick={editNode}
      />
      <FormattingMenuDivider />
      {/* <FullWidthButton editor={editor} selection={selection} /> */}
      <DeleteNodeButton editor={editor} />
    </ButtonGroup>
  )
}
