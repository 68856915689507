import SweetScroll from 'sweet-scroll'

import { isMobileDevice } from 'utils/deviceDetection'
import { getOffsetFromParent, getViewportHeight } from 'utils/dom'
import { waitFor } from 'utils/wait'

// The amount of pixels to be considered within matching distance
const IN_VIEWPORT_FUZZ_PX = 5
const DEFAULT_TOP_OFFSET = 100
const NUDGE_INTO_VIEWPORT_PX = 250
const NUDGE_FACTOR = 0.2 // 20% of viewport height

const isCypress = typeof window !== 'undefined' && window['Cypress']

const SMOOTH_SCROLL_DURATION = isCypress ? 10 : 400

export type ScrollManagerTypes = 'editor' | 'toc'

interface ScrollTo {
  sync?: boolean
  behavior?: ScrollBehavior
  top?: number
}

interface ScrollIntoView {
  element?: HTMLElement | HTMLDivElement | null
  delay?: number
  attempts?: number
  offsetFromTop?: number | null
}

type InViewData = {
  px: number
  portion: 'full' | 'abovePartial' | 'belowPartial' | 'above' | 'below'
  rect: DOMRect
  bottomInView: boolean
  topInView: boolean
  yAxisInView: boolean
  xAxisInView: boolean
  inView: boolean
}

const scrollManagers = new Map<ScrollManagerTypes, ScrollManager>()

// Retrieve the scroll manager for the type requested, instantiating a new one if necessary
export const getScrollManager = (type: ScrollManagerTypes) => {
  const manager = scrollManagers.get(type)
  if (!manager) {
    scrollManagers.set(type, new ScrollManager(type))
  }
  return scrollManagers.get(type)!
}

export const useScrollManager = (type: ScrollManagerTypes) => {
  return getScrollManager(type)
}

export const isInViewport = (
  el?: HTMLElement | null,

  // The number of pixels of the element that must be in
  // the viewport for the element to be considered "in view"
  inViewThreshold = 1
): InViewData | undefined => {
  if (!el || (!el.offsetWidth && !el.offsetHeight)) {
    return
  }
  const rect = el.getBoundingClientRect()
  const heightToUse = getViewportHeight()

  const topInView = rect.top >= -IN_VIEWPORT_FUZZ_PX && rect.top <= heightToUse
  const bottomInView =
    rect.bottom >= -IN_VIEWPORT_FUZZ_PX &&
    rect.bottom - IN_VIEWPORT_FUZZ_PX <= heightToUse

  const isFullHeight = rect.top <= 0 && rect.bottom >= heightToUse

  const pixelsInView: {
    px: number
    portion: InViewData['portion']
  } =
    (topInView && bottomInView) || isFullHeight
      ? { px: rect.height, portion: 'full' } // Fully in view
      : bottomInView
      ? { px: rect.bottom, portion: 'abovePartial' } // Partially in view at top of viewport
      : topInView
      ? { px: heightToUse - rect.top, portion: 'belowPartial' } // Partially in view at bottom of viewport
      : rect.bottom < 0
      ? { px: rect.bottom, portion: 'above' } // Out of view (element above viewport)
      : { px: heightToUse - rect.top, portion: 'below' } // Out of view (element below viewport)

  const yAxisInView = pixelsInView.px >= Math.min(inViewThreshold, rect.height)
  const xAxisInView =
    rect.left >= 0 &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)

  return {
    ...pixelsInView,
    rect,
    bottomInView,
    topInView,
    yAxisInView,
    xAxisInView,
    inView: yAxisInView && xAxisInView,
  }
}

class ScrollManager {
  inProgress: boolean = false

  scrollSelector: string = 'body'

  sweetScroll: SweetScroll

  type: ScrollManagerTypes

  constructor(type: ScrollManagerTypes) {
    this.type = type
    this.sweetScroll = this.getSweetScroll()
  }

  getSweetScroll() {
    return new SweetScroll(
      {
        before: () => {
          this.inProgress = true
        },
        complete: () => {
          this.inProgress = false
        },
      },
      this.scroller || document.body
    )
  }

  setScrollSelector(selector: string) {
    this.scrollSelector = selector
    this.sweetScroll = this.getSweetScroll()
  }

  get scroller() {
    return document.querySelector<HTMLElement>(this.scrollSelector)
  }

  isAtTop(threshold = 0) {
    const scroller = document.querySelector<HTMLElement>(this.scrollSelector)
    return scroller!.scrollTop <= threshold
  }

  isAtBottom(threshold = 1) {
    const scroller = document.querySelector<HTMLElement>(this.scrollSelector)
    if (!scroller) return false

    return (
      scroller.scrollHeight - scroller.scrollTop - scroller.offsetHeight <
      threshold
    )
  }

  async scrollTo({
    top = 0,
    behavior = isMobileDevice() ? 'auto' : 'smooth',
    sync = false,
  }: ScrollTo) {
    return new Promise((res) => {
      const execute = () => {
        if (!this.scroller) return

        if (behavior === 'smooth') {
          // Only use sweetScroll for smooth transitions
          this.sweetScroll.toTop(top, {
            duration: SMOOTH_SCROLL_DURATION,
          })
        } else {
          this.scroller.scroll({ top })
        }
      }

      if (sync) {
        execute()
      } else {
        setTimeout(execute)
      }
      setTimeout(res)
    })
  }

  /**
   * Attempt to scroll an element into view and invoke the callback provided
   * once it is confirmed to be in the viewport.
   * Uses a multiple attempts strategy of scrolling then polling.
   */
  scrollElementIntoView = async ({
    element,
    delay = 0,
    attempts = 3,
    offsetFromTop = DEFAULT_TOP_OFFSET,
  }: ScrollIntoView): Promise<boolean> => {
    if (!element) return false

    const scroll = async (inViewData?: InViewData) => {
      const scroller = element.closest(this.scrollSelector)
      if (!scroller) return

      // The ideal position is the top of the target block being offsetFromTop px down the page
      const offsetFromTopOfScroller = getOffsetFromParent(
        element,
        this.scrollSelector
      )

      const defaultScrollTop =
        offsetFromTopOfScroller - (offsetFromTop || DEFAULT_TOP_OFFSET)

      inViewData = inViewData || isInViewport(element)

      // We've specically been requested to scroll the element to the top
      if (offsetFromTop !== null || !inViewData) {
        return this.scrollTo({ top: defaultScrollTop })
      }

      const nudgeToUse = Math.min(
        NUDGE_INTO_VIEWPORT_PX,
        getViewportHeight() * NUDGE_FACTOR
      )

      const aboveNudge =
        inViewData.portion === 'full' && inViewData.rect.top < nudgeToUse
      const belowNudge =
        inViewData.portion === 'full' &&
        inViewData.rect.bottom > getViewportHeight() - nudgeToUse

      // The element is above the nudge range, so nudge it down
      if (
        aboveNudge ||
        inViewData.portion === 'abovePartial' ||
        inViewData.portion === 'above'
      ) {
        const idealScroll = offsetFromTopOfScroller - nudgeToUse
        console.debug(
          '[scrollElementIntoView] Nudge DOWN from above - nudge:',
          nudgeToUse
        )
        return this.scrollTo({ top: idealScroll })
      }

      // The element is below the nudge range, so nudge it up
      if (
        belowNudge ||
        inViewData.portion === 'belowPartial' ||
        inViewData.portion === 'below'
      ) {
        const minScrollTop = offsetFromTopOfScroller - nudgeToUse
        const offset = element.getBoundingClientRect().height - inViewData.px
        const idealScrollTop = scroller.scrollTop + offset + nudgeToUse

        console.debug(
          '[scrollElementIntoView] Nudge UP from below - nudge:',
          nudgeToUse
        )
        return this.scrollTo({ top: Math.min(idealScrollTop, minScrollTop) })
      }
    }

    const predicate = () => Boolean(isInViewport(element))
    const initialViewportResult = isInViewport(element)

    if (initialViewportResult) {
      console.debug(
        `[scrollElementIntoView] Element already in view. Scrolling to center it.`,
        element
      )
      scroll(initialViewportResult)
      return true
    }

    if (delay) {
      await new Promise((r) => setTimeout(r, delay))
    }

    const begin = +new Date()

    const execute = () => {
      const scrollPromise = scroll() || Promise.resolve()
      return waitFor(
        32, // 32 Attempts
        25 // 25 ms polling = 800ms max wait time
      )(predicate).then(
        () => true,
        // If the waitFor times out, ensure the scroll
        // has finshed before resolving with false.
        () => scrollPromise.then(() => false)
      )
    }

    let times = attempts
    let found = false
    while (times > 0) {
      times--
      found = await execute()
      if (found) {
        const duration = +new Date() - begin
        console.debug(
          `[scrollElementIntoView] Element in view after ${
            attempts - times
          } attempts - ${duration}ms`
        )
        break
      }
    }
    if (!found) {
      const duration = +new Date() - begin
      console.debug(
        `[scrollElementIntoView] Element did not appear after ${attempts} attempts - ${duration}ms`,
        element
      )
    }

    return found
  }
}
