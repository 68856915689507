import { Box } from '@chakra-ui/react'
import { Editor, findChildren, JSONContent, NodeWithPos } from '@tiptap/core'
import { Node } from 'prosemirror-model'
import { Selection } from 'prosemirror-state'
import React from 'react'

import {
  isCardLayoutItemNode,
  isCardNode,
} from 'modules/tiptap_editor/extensions/Card/utils'
import { EditorModeEnum } from 'modules/tiptap_editor/types'

import { TreeItem } from './TreeItem'

/**
 * Depth first search of node
 */

const getChildCards = (node: JSONContent): JSONContent[] => {
  if (!node.content) {
    return []
  }

  // look for cards directly under cards
  const directCards = node.content.filter((a) => a.type === 'card')
  if (directCards.length > 0) {
    return directCards
  }

  // collect cards in CardLayoutItems
  let childCards: JSONContent[] = []

  node.content.forEach((n) => {
    if (n.type === 'cardLayoutItem' && n.content) {
      const cards = n.content.filter((a) => a.type === 'card')
      childCards = [...childCards, ...cards]
    }
  })
  return childCards
}

const findCardNodeById = (node: Node, id: string): NodeWithPos | null => {
  const matches = findChildren(node, (n) => isCardNode(n) && n.attrs?.id === id)
  if (!matches || matches.length === 0) {
    return null
  }
  return matches[0]
}

type TreeNavigationProps = {
  isRoot: boolean
  node: Node
  editorMode: EditorModeEnum
  editor: Editor
  docId: string
  cards: JSONContent[]
  selection: Selection
  isSelectionOriginTOC: boolean
  onClose: () => void
  scrollOffsetFromTop: number
}

export const TreeNavigation: React.FC<TreeNavigationProps> = ({
  isRoot,
  node,
  editorMode,
  editor,
  docId,
  cards,
  selection,
  isSelectionOriginTOC,
  onClose,
  scrollOffsetFromTop,
}) => {
  const testId = isRoot ? { 'data-testid': 'toc-cards' } : {}
  return (
    // Padding makes room for the expand boxes
    <Box flex={1} py={isRoot ? 3 : 0} {...testId}>
      {cards.map(function (card, index) {
        const children = getChildCards(card)
        const { id } = card.attrs!
        const cardNodeWithPos = findCardNodeById(node, id)
        if (!cardNodeWithPos) {
          return null
        }
        const { node: cardNode } = cardNodeWithPos
        return (
          <TreeItem
            key={id}
            editorMode={editorMode}
            isRoot={isRoot}
            node={cardNode}
            selection={selection}
            isSelectionOriginTOC={isSelectionOriginTOC}
            editor={editor}
            docId={docId}
            content={card}
            hasChildren={children.length > 0}
            isFirst={index === 0}
            isLast={index === cards.length - 1}
            onClose={onClose}
            scrollOffsetFromTop={scrollOffsetFromTop}
          >
            {children && (
              <TreeNavigation
                editorMode={editorMode}
                node={cardNode}
                isRoot={false}
                editor={editor}
                docId={docId}
                cards={children}
                selection={selection}
                isSelectionOriginTOC={isSelectionOriginTOC}
                onClose={onClose}
                scrollOffsetFromTop={scrollOffsetFromTop}
              />
            )}
          </TreeItem>
        )
      })}
    </Box>
  )
}
