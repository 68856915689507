import {
  Avatar,
  Flex,
  HStack,
  MenuItemProps,
  Spinner,
  Text,
} from '@chakra-ui/react'
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  ListBox,
  ListBoxItem,
  ListBoxList,
  SectionTitle,
  DOC_DISPLAY_NAME,
  capitalize,
  DOC_DISPLAY_NAME_PLURAL,
} from '@gamma-app/ui'
import DOMPurify from 'isomorphic-dompurify'
import React, {
  forwardRef,
  ForwardRefRenderFunction,
  MutableRefObject,
  useMemo,
} from 'react'

import {
  Card,
  DocResult,
  useGetCardsQuery,
  useHealthCheck,
  User,
  useSearchMentionQuery,
} from 'modules/api'
import { OfflineInfoBox } from 'modules/offline'
import { useAppSelector } from 'modules/redux'
import { DocSearchResultItem } from 'modules/search'
import { MENTION_SUGGESTION_CHARACTER } from 'modules/tiptap_editor/extensions/MentionSuggestionMenu'
import {
  useSuggestionKeyboardHandler,
  SuggestionKeyDownProps,
  SuggestionProps,
} from 'modules/tiptap_editor/extensions/Suggestion'
import { useUserContext } from 'modules/user'
import { preventDefaultToAvoidBlur } from 'utils/handlers'

import { selectDoc } from '../../../reducer'

const EMPTY_STATE_PROMPT = `Type to mention someone or a ${DOC_DISPLAY_NAME}`

type MentionListItemInnerProps = {
  result: User | DocResult | Card
}
const MentionListItemText = ({ html }: { html?: string }) => (
  <Text
    paddingInlineStart="0.2rem"
    wordBreak="break-word"
    noOfLines={1}
    maxW={300}
    lineHeight="1.5"
    dangerouslySetInnerHTML={{
      __html: html ? DOMPurify.sanitize(html) : '',
    }}
  />
)

const removeSearchFormattingTags = (str: string) =>
  str.replace(/(<em>|<\/em>)/g, '')

const MentionListItemInner = ({ result }: MentionListItemInnerProps) => {
  const { __typename } = result
  if (__typename === 'User') {
    return (
      <HStack>
        <Avatar
          size="2xs"
          minWidth={4}
          m={0}
          name={
            result.displayName && removeSearchFormattingTags(result.displayName)
          }
          src={result.profileImageUrl}
        />
        <MentionListItemText html={result.displayName} />
      </HStack>
    )
  } else if (__typename === 'Card') {
    return (
      <HStack>
        <Flex color="gray.600" minWidth={4} justifyContent="flex-end">
          <FontAwesomeIcon
            icon={solid('circle-small')}
            transform={{
              size: 6,
            }}
          />
        </Flex>
        <MentionListItemText html={result.title} />
      </HStack>
    )
  } else if (__typename === 'DocResult') {
    return <DocSearchResultItem result={result} context="mentionsList" />
  }
  console.warn(
    '[MentionList] Invalid __typename for search result:',
    result.__typename
  )
  return null
}

type MentionListItemProps = MenuItemProps & {
  result: User | DocResult | Card
  index: number
  selectedIndex: number
  selectItem: (index: number) => void
}

const MentionListItemComponent = (
  { result, index, selectedIndex, selectItem, ...rest }: MentionListItemProps,
  ref: MutableRefObject<HTMLButtonElement | null>
) => {
  return (
    <ListBoxItem
      ref={ref}
      tabIndex={index === selectedIndex ? 0 : -1}
      key={index}
      onClick={() => selectItem(index)}
      onMouseDown={preventDefaultToAvoidBlur}
      {...rest}
    >
      <MentionListItemInner result={result} />
    </ListBoxItem>
  )
}

const MentionListItem = forwardRef(MentionListItemComponent)

const CardIcon = <FontAwesomeIcon icon={regular('rectangle')} />
const MemoIcon = <FontAwesomeIcon icon={regular('rectangle-history')} />
const PeopleIcon = <FontAwesomeIcon icon={regular('circle-user')} />

/**
 * What title and icon we should use for
 * each type of mention result we display.
 * Useful for rendering a flat sorted list and inserting
 * the right heading for each group.
 */
const typeToHeadingMap = {
  Card: {
    title: `Cards in this ${DOC_DISPLAY_NAME}`,
    iconComponent: CardIcon,
  },
  DocResult: {
    title: capitalize(DOC_DISPLAY_NAME_PLURAL),
    iconComponent: MemoIcon,
  },
  User: {
    title: 'People',
    iconComponent: PeopleIcon,
  },
}

interface MentionListComponentProps {
  onKeyDown: (props: SuggestionKeyDownProps) => boolean
}

// Helper to generate a RegExp with a few options:
//   disableRegex: strip out any regular expression characters
//   caseSensitive: use the i flag for the regular expression
export const getSearchRegex = (
  term: string,
  disableRegex: boolean,
  caseSensitive: boolean
): RegExp => {
  return RegExp(
    // See https://stackoverflow.com/a/6969486
    disableRegex ? term.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') : term,
    caseSensitive ? 'gu' : 'gui'
  )
}

const MentionListComponent: ForwardRefRenderFunction<
  MentionListComponentProps,
  SuggestionProps
> = ({ editor, query, command }, ref) => {
  const { isConnected } = useHealthCheck()
  const docCollaborators = useAppSelector((state) =>
    (selectDoc(state)?.collaborators || []).map((c) => c.user)
  )
  const skipQuery = !query || !editor.gammaOrgId || !isConnected
  const {
    data: mentionData,
    previousData: previousMentionData,
    error,
    loading,
  } = useSearchMentionQuery({
    variables: { workspaceId: editor.gammaOrgId!, query },
    skip: skipQuery,
  })

  const { user } = useUserContext()

  const { searchDocs, searchUsers } = useMemo(() => {
    const searchRegex = getSearchRegex(query, true, false)
    // Grab the most up to date search results
    const userMentions = (
      mentionData
        ? mentionData.search
        : previousMentionData
        ? previousMentionData.search
        : []
    ).filter((d) => d.__typename === 'User') as User[]

    const docMentions = (
      mentionData
        ? mentionData.search
        : previousMentionData
        ? previousMentionData.search
        : []
    ).filter((d) => d.__typename === 'DocResult') as DocResult[]

    // Grab any existing doc collaborators that also match
    const docCollaboratorsToUse = docCollaborators
      // Make sure the user isn't in our search list already
      .filter((u) => !userMentions.find((f) => f.id === u.id))
      // Make sure the query matches one of the 3 fields.
      // This mimics the identity service search pattern
      // See https://github.com/gamma-app/gamma/blob/2d6bb7f3482775b7ddc5284a79a48f3149b7a485/packages/server/src/identity/identity.service.ts#L167-L186
      .filter(
        (u) =>
          u.email?.startsWith(query.toLowerCase()) ||
          u.displayName?.toLowerCase().includes(query.toLowerCase())
      )

    const users = [...userMentions, ...docCollaboratorsToUse].map((u) => ({
      ...u,
      displayName: u.displayName?.replace(searchRegex, '<em>$&</em>'),
    }))

    return {
      searchDocs: query ? [...docMentions] : [],
      searchUsers: query ? users : [],
    }
  }, [mentionData, previousMentionData, query, docCollaborators])

  const { data: cardData } = useGetCardsQuery({
    variables: {
      docId: editor.gammaDocId as string,
    },
    skip: skipQuery,
    // Use cache only as the editor context provider fetches & subscribes to all cards
    fetchPolicy: 'cache-only',
    nextFetchPolicy: 'cache-only',
    returnPartialData: true,
  })

  const thisMemoCards = useMemo(() => {
    const searchRegex = getSearchRegex(query, true, false)
    if (!cardData?.docCards || !query) {
      return []
    }
    return cardData.docCards
      .map((card) => {
        const title = card.title || ''
        if (title.match(searchRegex)) {
          const formattedTitle = title.replace(searchRegex, '<em>$&</em>')
          return { ...card, title: formattedTitle }
        }
        return null
      })
      .filter(Boolean) as Card[]
  }, [cardData, query])

  const options = [...searchUsers, ...thisMemoCards, ...searchDocs]

  const selectItem = (index: number) => {
    const item = options[index] ? { ...options[index] } : null
    if (!item) return

    if (item.__typename === 'User' && item.displayName) {
      // For User types, remove the search term formatting tags
      item.displayName = removeSearchFormattingTags(item.displayName)
    }
    command({ item, user })
  }

  const { selectedIndex, selectedItemEl } = useSuggestionKeyboardHandler({
    ref,
    selectItem,
    options,
  })

  const selection = editor.state.selection
  const shouldShowEmptyStatePrompt =
    !selection.$anchor.parent.isLeaf &&
    selection.$anchor.parent.textContent === MENTION_SUGGESTION_CHARACTER

  // Empty query
  if (query.length === 0 && shouldShowEmptyStatePrompt) {
    return (
      <Flex
        w="100%"
        p={1}
        align="flex-start"
        justify="flex-start"
        flex="1"
        mt={-9}
        ml={3}
        data-testid="mention-list-empty"
      >
        <Text fontSize="md" color="gray.400">
          {EMPTY_STATE_PROMPT}
        </Text>
      </Flex>
    )
  }

  return (
    <ListBox data-testid="mention-list">
      <ListBoxList
        data-target-name="mention-list"
        w="540px"
        maxW="90vw"
        overflowY="auto"
        maxH="65vh"
        sx={{
          em: {
            bg: 'var(--chakra-colors-trueblue-100)',
            fontStyle: 'normal',
            borderRadius: 'base',
            display: 'inline-block',
            padding: '0px 2px',
            margin: '0px -2px',
          },
        }}
      >
        <OfflineInfoBox
          isConnected={isConnected}
          label="Mentions are only available when you're online."
        />
        {error ? (
          // error
          <Flex p={1} align="center" justify="center" flex="1" minH={12}>
            <Text fontSize="sm" color="gray.500">
              Error loading search results{error ? `: ${error?.message}` : ''}.
            </Text>
          </Flex>
        ) : query.length === 0 ? (
          // empty query, but not at beginning of line
          <Flex
            p={1}
            align="center"
            justify="center"
            flex="1"
            minH={12}
            data-testid="mention-list-empty"
          >
            <Text fontSize="md" color="gray.400">
              {EMPTY_STATE_PROMPT}
            </Text>{' '}
          </Flex>
        ) : loading && options.length === 0 ? (
          // it's still loading
          <Flex p={1} align="center" justify="center" flex="1" minH={12}>
            <Spinner opacity="0.8" size="xs" />
          </Flex>
        ) : !loading && query.length > 1 && options.length === 0 ? (
          // no results were found
          <Flex p={1} align="center" justify="center" flex="1" minH={12}>
            <Text fontSize="sm" color="gray.500">
              No results for this query.
            </Text>
          </Flex>
        ) : null}

        {/* SUCCESS! Results */}
        {options.map((result, index) => {
          const firstIndexOfType =
            options.findIndex((o) => o.__typename === result.__typename) ===
            index

          const headingData =
            firstIndexOfType && result.__typename
              ? typeToHeadingMap[result.__typename]
              : null

          return (
            <React.Fragment key={result.id}>
              {headingData && (
                <HStack mt={4} mb={2} color="gray.500">
                  {headingData.iconComponent}
                  <SectionTitle>{headingData.title}</SectionTitle>
                </HStack>
              )}
              <MentionListItem
                data-testid={`mention-list-item-${result.id}`}
                ref={index === selectedIndex ? selectedItemEl : null}
                key={index}
                result={result}
                index={index}
                selectedIndex={selectedIndex}
                selectItem={selectItem}
              />
            </React.Fragment>
          )
        })}
      </ListBoxList>
    </ListBox>
  )
}

export const MentionList = forwardRef(MentionListComponent)
