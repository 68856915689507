import { HStack } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Editor, findParentNode } from '@tiptap/core'
import { NodeSelection } from 'prosemirror-state'

import {
  CellSelection,
  selectedRect,
} from 'modules/tiptap_editor/extensions/tables/prosemirror-table'
import { canAddCol } from 'modules/tiptap_editor/extensions/tables/prosemirror-table/columnUtils'
import { Table } from 'modules/tiptap_editor/extensions/tables/Table'
import { isTableNode } from 'modules/tiptap_editor/extensions/tables/utils/nodes'

import { ToolbarButton } from '../buttons/ToolbarButton'
import { FormattingMenuDivider } from './FormattingMenuDivider'
import { FilterKeyForTextFormattingCommands } from './TextFormattingCommands'
import { TextFormattingMenu } from './TextFormattingMenu'

type TableFormattingMenuProps = {
  editor: Editor
  selection: CellSelection
}

export const TableFormattingMenu = ({
  editor,
  selection,
}: TableFormattingMenuProps) => {
  // See https://github.com/ProseMirror/prosemirror-tables/tree/6b16ed3cf306886f2c169aebbe60701e1ac2deac#documentation
  const isRowSelected = selection.isRowSelection()
  const isColumnSelected = selection.isColSelection()
  const isTableSelected = isRowSelected && isColumnSelected
  const tableNode = findParentNode(isTableNode)(selection)
  const tableNodeSelection =
    tableNode && NodeSelection.create(editor.state.doc, tableNode?.pos)

  const fromCell = Math.min(selection.$anchorCell.pos, selection.$headCell.pos)
  const toCell = Math.max(selection.$anchorCell.pos, selection.$headCell.pos)

  const deleteRow = () => editor.chain().focus().deleteRow().run()
  const addRowBefore = () => {
    editor.commands.addRowBefore()
    requestAnimationFrame(() => {
      editor.commands.focus(fromCell)
    })
  }
  const addRowAfter = () => {
    editor.commands.addRowAfter()
    requestAnimationFrame(() => {
      editor.chain().focus(toCell).goToNextCell().run()
    })
  }
  const deleteColumn = () => {
    editor.commands.deleteColumn()
    requestAnimationFrame(() => {
      editor.commands.focus(fromCell)
    })
  }
  const addColumnBefore = () => {
    const { left } = selectedRect(editor.state.selection)

    editor.commands.addColumnBefore(left)
    requestAnimationFrame(() => {
      editor.commands.focus(fromCell)
    })
  }
  const addColumnAfter = () => {
    const { left } = selectedRect(editor.state.selection)

    editor.commands.addColumnAfter(left)
    requestAnimationFrame(() => {
      editor.chain().focus(fromCell).goToNextCell().run()
    })
  }
  const deleteTable = () => editor.chain().focus().deleteTable().run()

  const equalizeColumns = () => editor.commands.equalizeColumns()

  const { colMinPercent, newColSize } = editor.schema.nodes[Table.name].spec
  const node = selection.getTableNode()
  const canInsertCol = canAddCol(
    node.attrs.colWidths,
    newColSize,
    colMinPercent
  )

  return (
    <HStack spacing={1}>
      <TextFormattingMenu
        editor={editor}
        filterBy={FilterKeyForTextFormattingCommands.enabledForTables}
      />
      <FormattingMenuDivider />
      {isRowSelected && !isTableSelected && (
        <>
          <ToolbarButton
            label="Insert row before"
            onClick={addRowBefore}
            testId="add-row-before-button"
          >
            <FontAwesomeIcon icon={regular('diagram-cells')} />
          </ToolbarButton>
          <ToolbarButton
            label="Insert row after"
            onClick={addRowAfter}
            testId="add-row-after-button"
          >
            <FontAwesomeIcon icon={regular('diagram-cells')} rotation={180} />
          </ToolbarButton>
          <ToolbarButton
            label="Delete row"
            icon={regular('trash')}
            onClick={deleteRow}
            color="red.500"
            testId="delete-row-button"
          />
        </>
      )}
      {isColumnSelected && !isTableSelected && (
        <>
          <ToolbarButton
            label="Insert column before"
            onClick={addColumnBefore}
            isDisabled={!canInsertCol}
            testId="add-col-before-button"
          >
            <FontAwesomeIcon icon={regular('diagram-cells')} rotation={270} />
          </ToolbarButton>
          <ToolbarButton
            label="Insert column after"
            onClick={addColumnAfter}
            isDisabled={!canInsertCol}
            testId="add-col-after-button"
          >
            <FontAwesomeIcon icon={regular('diagram-cells')} rotation={90} />
          </ToolbarButton>
          <ToolbarButton
            label="Delete column"
            icon={regular('trash')}
            onClick={deleteColumn}
            color="red.500"
            testId="delete-col-button"
          />
        </>
      )}
      {isTableSelected && (
        <>
          {/* {tableNodeSelection && (
            <FullWidthButton editor={editor} selection={tableNodeSelection} />
          )} */}
          <ToolbarButton
            label="Equalize columns"
            icon={regular('distribute-spacing-horizontal')}
            onClick={equalizeColumns}
            testId="equalize-columns-button"
          />
          <FormattingMenuDivider />
          <ToolbarButton
            label="Delete table"
            icon={regular('trash')}
            onClick={deleteTable}
            color="red.500"
            testId="delete-table-button"
          />
        </>
      )}
    </HStack>
  )
}
