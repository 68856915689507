import {
  Editor,
  Extension,
  findChildrenInRange,
  findParentNode,
} from '@tiptap/core'
import { NodeType, Schema } from 'prosemirror-model'

import { disallowParentsFromInputRule } from 'modules/tiptap_editor/utils/inputRules'

import { isCardNode } from '../Card/utils'
import { ExtensionPriorityMap } from '../constants'
import { ListPlugin } from './ListPlugin'
import { isListNode, ListVariant } from './ListTypes'
import { listToTree, listTreeToCards, listTreeToSmartLayout } from './utils'
export const MAX_INDENT = 8

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    list: {
      listToCards: () => ReturnType
      listToSmartLayout: (variant: string) => ReturnType
      /**
       * Indent a node forward (1) or backward (-1)
       */
      indent: (delta: number) => ReturnType
      /**
       * Set nodes to a list
       */
      setListItems: (attributes: { variant: ListVariant }) => ReturnType
      /**
       * Toggle a list
       */
      toggleListItems: (attributes: { variant: ListVariant }) => ReturnType
    }
  }
}

export const List = Extension.create({
  name: 'list',
  priority: ExtensionPriorityMap.List,

  addProseMirrorPlugins() {
    return [ListPlugin(this.editor)]
  },

  addCommands() {
    return {
      listToCards:
        () =>
        ({ chain, state }) => {
          const { selection, doc } = state
          const { from, to } = selection

          const listItems = findChildrenInRange(doc, { from, to }, isListNode)
          const listParentCard = findParentNode(isCardNode)(selection)
          if (!listItems.length || !listParentCard) return false

          const insertPos = listParentCard.pos + listParentCard.node.nodeSize
          const listTree = listToTree(listItems)
          const cardTree = listTreeToCards(listTree)
          chain().insertContentAt(insertPos, cardTree).scrollIntoView().run()

          return true
        },
      listToSmartLayout:
        (variant) =>
        ({ chain, state }) => {
          const { selection, doc } = state
          const { from, to, $from, $to } = selection
          const replaceRange = $from.blockRange($to)

          const listItems = findChildrenInRange(doc, { from, to }, isListNode)
          if (!listItems.length || !replaceRange) return false

          const listTree = listToTree(listItems)
          const smartLayout = listTreeToSmartLayout(listTree, variant)
          chain()
            .insertContentAt(
              { from: replaceRange.start, to: replaceRange.end },
              smartLayout
            )
            .selectInsertedNode()
            .scrollIntoView()
            .run()

          return true
        },
      indent:
        (delta) =>
        ({ tr, dispatch, state }) => {
          // Adapted from tiptap/packages/core/src/commands/updateAttributes.ts
          // See also https://next.tiptap.dev/api/commands#dry-run-for-commands

          if (!dispatch) return true
          let nodesChanged = false

          tr.selection.ranges.forEach((range) => {
            const from = range.$from.pos
            const to = range.$to.pos

            // Find all the nodes in the selection
            state.doc.nodesBetween(from, to, (node, pos) => {
              const { indent } = node.attrs
              const listNodeTypes = Object.values(ListVariant) as string[]
              // Move any bullets that can be shifted
              if (
                listNodeTypes.includes(node.type.name) &&
                indent + delta >= 0 &&
                indent + delta <= MAX_INDENT
              ) {
                tr.setNodeMarkup(pos, undefined, {
                  ...node.attrs,
                  indent: indent + delta,
                })
                nodesChanged = true
              }
            })
          })

          return nodesChanged // Don't fire anything else if we indented
        },
      setListItems:
        ({ variant }) =>
        ({ tr, dispatch, state }) => {
          if (!dispatch) return true // Prevents tab from firing for anything else
          tr.selection.ranges.forEach((range) => {
            const from = range.$from.pos
            const to = range.$to.pos

            // Find all the nodes in the selection
            state.doc.nodesBetween(from, to, (node, pos) => {
              const listNodeTypes = Object.values(ListVariant) as string[]
              if (listNodeTypes.includes(node.type.name)) {
                // We're in a list, so just update the type
                tr.setNodeMarkup(pos, state.schema.nodes[variant], node.attrs)
              } else if (node.isTextblock) {
                // We're not in a list, but this is something like a paragraph that can become one
                tr.setNodeMarkup(pos, state.schema.nodes[variant], {
                  ...node.attrs,
                  indent: 0,
                })
              }
            })
          })

          return true // Prevents tab from firing for anything else
        },

      toggleListItems:
        ({ variant }) =>
        ({ commands, editor }) => {
          if (checkListActive(editor, variant)) {
            // In a list, turn it off
            return commands.setNode('paragraph')
          } else {
            // Put every textblock node into this list type
            return commands.setListItems({ variant })
          }
        },
    }
  },

  addKeyboardShortcuts() {
    return {
      Enter: ({ editor }) => {
        let variant, attrs
        for (const nodeName of Object.values(ListVariant)) {
          if (editor.isActive(nodeName)) {
            attrs = editor.getAttributes(nodeName)
            variant = nodeName
            break
          }
        }
        if (variant === undefined) return false // Not in a list

        const { from, $from, empty } = editor.state.selection
        if (!empty) return false // Use default erase behavior on a range

        if ($from.parent.content.size == 0) {
          // Enter on an empty bullet should outdent or switch to regular text
          if (attrs.indent > 0) {
            return editor.commands.indent(-1)
          } else {
            return editor.commands.setNode('paragraph', attrs)
          }
        } else if ($from.parentOffset === 0) {
          // Enter at the start of the line should insert a bullet above
          if (attrs.checked) attrs.checked = false // Reset todo check
          return editor
            .chain()
            .insertContentAt(from - 1, {
              type: variant,
              attrs,
            })
            .selectInsertedNode()
            .run()
        } else if ($from.parentOffset === $from.parent.content.size) {
          // Enter at the end of the line should insert a bullet below
          if (attrs.checked) attrs.checked = false // Reset todo check
          return editor
            .chain()
            .insertContentAt(from + 1, {
              type: variant,
              attrs,
            })
            .selectInsertedNode()
            .run()
        } else {
          // Enter in the middle of hte line should split the bullet
          // use default keyboard behavior with has splitBlockWithAnnotations bound to Enter
          return false
        }
      },
      Backspace: ({ editor }) => {
        let variant, attrs
        for (const nodeName of Object.values(ListVariant)) {
          if (editor.isActive(nodeName)) {
            attrs = editor.getAttributes(nodeName)
            variant = nodeName
            break
          }
        }
        if (variant === undefined) return false // Not in a list

        const { $from, empty } = editor.state.selection
        if (!empty) return false // Use default erase behavior on a range

        if ($from.parentOffset == 0) {
          // At the start of the line, delete the bullet
          return editor.commands.setNode('paragraph', attrs)
        } else {
          // Otherwise, use default behavior
          return false
        }
      },
      Tab: ({ editor }) => editor.commands.indent(1),
      'Shift-Tab': ({ editor }) => editor.commands.indent(-1),
      'Mod-]': ({ editor }) => editor.commands.indent(1),
      'Mod-[': ({ editor }) => editor.commands.indent(-1),
    }
  },
})

export const checkListActive = (editor: Editor, variant: ListVariant) => {
  const otherVariants = Object.values(ListVariant).filter((v) => v !== variant)
  return (
    editor.isActive(variant) && !otherVariants.some((v) => editor.isActive(v))
  )
}

export const listFilteredInputRule = (
  config: {
    find: RegExp
    type: NodeType
    getAttributes?: {
      [key: string]: any
    }
  },
  schema: Schema
) =>
  disallowParentsFromInputRule(config, [
    schema.nodes.heading,
    schema.nodes.title,
  ])
