import { Flex } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { NodeViewProps } from '@tiptap/react'

import { UploadStatus } from 'modules/media'

import { AnnotatableNodeViewWrapper } from '../../Annotatable/components/AnnotatableNodeViewWrapper'
import { findBlockWidthDecoration } from '../../block/BlockWidthPlugin'
import { getAlignStyles } from '../../HorizontalAlign'
import { isNodeViewInGallery } from '../Gallery'
import { ImageNodeAttrs } from '../types'
import { MediaPlaceholderAttrs } from './MediaPlaceholder'
import { MediaPlaceholderImage } from './MediaPlaceholderImage'

export const MediaPlaceholderView = (nodeViewProps: NodeViewProps) => {
  return (
    <AnnotatableNodeViewWrapper {...nodeViewProps} as="div">
      <MediaPlaceholderBlock {...nodeViewProps} />
    </AnnotatableNodeViewWrapper>
  )
}

export const MediaPlaceholderBlock = (nodeViewProps: NodeViewProps) => {
  const { node, decorations } = nodeViewProps
  const { source, horizontalAlign, uploadStatus } =
    node.attrs as MediaPlaceholderAttrs & ImageNodeAttrs
  const { isFullWidth } = findBlockWidthDecoration(decorations)
  const icon = source?.includes('embed.')
    ? regular('browser')
    : regular('photo-video')
  const inGallery = isNodeViewInGallery(nodeViewProps)

  return (
    <Flex
      w="100%"
      h="100%"
      direction="column"
      sx={getAlignStyles(horizontalAlign)}
    >
      <MediaPlaceholderImage
        hasUploadError={uploadStatus === UploadStatus.Error}
        isLoading={uploadStatus === UploadStatus.Uploading}
        data-content-reference
        icon={icon}
        width={isFullWidth || inGallery ? '100%' : '18em'}
        maxW="100%"
        h={inGallery ? '100%' : '10em'}
        maxH="100%"
        data-drag-handle // https://tiptap.dev/guide/node-views/react#dragging
        data-selection-ring
        data-selection-background
        borderRadius="var(--box-border-radius)"
      />
    </Flex>
  )
}
