import { Flex, Text } from '@chakra-ui/react'
import { NodeViewProps } from '@tiptap/core'

import { NodeViewContent, NodeViewWrapper } from 'modules/tiptap_editor/react'

import { ContainerDragHandle } from '../DragDrop/ContainerDragHandle/ContainerDragHandle'

export const LayoutCellView = (nodeViewProps: NodeViewProps) => {
  return (
    <NodeViewWrapper
      className="grid-cell"
      style={{ position: 'relative' }}
      data-selection-ring
    >
      <ContainerDragHandle
        handlePlacement="top"
        {...nodeViewProps}
        data-testid="column-drag-handle"
        label={
          <Flex direction="column" align="center">
            Select column<Text color="gray.500">Drag to rearrange</Text>
          </Flex>
        }
      />

      <NodeViewContent />
    </NodeViewWrapper>
  )
}
