import { Node as ProseMirrorNode } from 'prosemirror-model'
import { Decoration } from 'prosemirror-view'

import { featureFlags } from 'modules/featureFlags'

import { isCardCollapsed } from './Card/CardCollapse'

export type UpdateFn = (updateProps: {
  newNode: ProseMirrorNode
  newDecorations: Decoration[]
  oldNode: ProseMirrorNode
  oldDecorations: Decoration[]
  updateProps: () => any
}) => boolean

export const isFocusedCard = (
  decorations: Decoration[],
  node: ProseMirrorNode
) =>
  isCardCollapsed(node)
    ? decorations.some((decoration) => decoration.spec.focusedNode)
    : decorations.some((decoration) => decoration.spec.focusedCard)

export const getChangedDecorations = (
  oldDecos: Decoration[],
  newDecos: Decoration[]
) => {
  // Only consider decorations with spec defined
  const oldFiltered = oldDecos.filter((d) => Object.keys(d.spec).length > 0)
  const newFiltered = newDecos.filter((d) => Object.keys(d.spec).length > 0)

  const a = oldFiltered.map((d) => JSON.stringify(d.spec))
  const b = newFiltered.map((d) => JSON.stringify(d.spec))

  // find a not in b or vice versa
  const removed = a.filter((x) => !b.includes(x))
  const added = b.filter((x) => !a.includes(x))
  return { removed, added }
}

export const didDecorationsSpecChange = (a: Decoration[], b: Decoration[]) => {
  // Only consider decorations with spec defined
  const aFiltered = a.filter((d) => Object.keys(d.spec).length > 0)
  const bFiltered = b.filter((d) => Object.keys(d.spec).length > 0)

  const result =
    // check length first as a cheaper check
    aFiltered.length !== bFiltered.length ||
    JSON.stringify(aFiltered.map((d) => d.spec)) !==
      JSON.stringify(bFiltered.map((d) => d.spec))

  return result
}

export const attrsOrDecorationsChanged: UpdateFn = ({
  newNode,
  oldNode,
  updateProps,
  oldDecorations,
  newDecorations,
}) => {
  const didAttrsChange =
    JSON.stringify(newNode.attrs) !== JSON.stringify(oldNode.attrs)

  if (didAttrsChange) {
    updateProps()
    return true
  }

  const didDecosChange = didDecorationsSpecChange(
    oldDecorations,
    newDecorations
  )

  if (didDecosChange) {
    if (featureFlags.get('debugLogging')) {
      const changed = getChangedDecorations(oldDecorations, newDecorations)
      console.debug('============== decos ================')
      console.debug('decos changed', newNode.type.name)
      if (changed.removed.length > 0) {
        console.debug(
          `%c  decos removed: ${changed.removed.join(' ')} `,
          'color: red'
        )
      }
      if (changed.added.length > 0) {
        console.debug(
          `%c  decos added:   ${changed.added.join(' ')} `,
          'color: green'
        )
      }
    }
    updateProps()
    return true
  }

  return true
}

export const numChildrenOrAttrsOrDecorationsChanged: UpdateFn = (props) => {
  const { newNode, oldNode, updateProps } = props

  const didNumChildrenChange =
    newNode.content.childCount !== oldNode.content.childCount

  if (didNumChildrenChange) {
    updateProps()
    return true
  }

  return attrsOrDecorationsChanged(props)
}
