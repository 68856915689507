import { Content, Extension } from '@tiptap/core'
import { NodeSelection } from 'prosemirror-state'

import {
  findSelectionInsideNode,
  getInsertedNodePos,
} from '../../utils/selection/findSelectionInsideNode'
import { findSelectionNearOrGapCursor } from '../../utils/selection/findSelectionNearOrGapCursor'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    focusHelpers: {
      deleteSelectionAndSelectNear: (dir?: number) => ReturnType
      // This function should only be used to "refresh" the view.focus and scrollIntoView
      // we need to do this when we do things like inserting content that has to animate in (new cards)
      focusDelayed: () => ReturnType
      // focusMapped should be used when needing to select content where some content has been added
      // and others removed, as the original position of the inserted content may be incorrect after deletion
      focusMapped: (pos: number, offset?: number, assoc?: -1 | 1) => ReturnType
      insertContentAndSelect: (value: Content, bias?: number) => ReturnType
      selectNodeAtPos: (pos: number, scrollIntoView?: boolean) => ReturnType
      selectInsideNodeAtPos: (pos: number) => ReturnType
      selectInsertedNode: (bias?: number) => ReturnType
    }
  }
}

export const FocusHelpers = Extension.create({
  name: 'focusHelpers',

  addCommands() {
    return {
      /**
       * Delays the entire command until the next frame
       * While the core .focus command does delay the actual focus, it calls
       * the tr.setSelection immediately, which causes a problem with nodeviews
       * https://github.com/ueberdosis/tiptap/blob/f4fc935c6c89f7ef94aebd4b916bc4d68795bf93/packages/core/src/commands/focus.ts#L33-L51
       */
      focusDelayed:
        () =>
        ({ editor }) => {
          requestAnimationFrame(() => editor.commands.focus())
          return true
        },

      insertContentAndSelect:
        (content, bias = 1) =>
        ({ chain, state }) => {
          const { from, $from } = state.selection
          if ($from.parentOffset === 0) {
            // if we insert at beginning of line, replace the start of line, so it doesn't "split"
            // and leave an empty line at the top
            return chain()
              .insertContentAt({ from: from - 1, to: from }, content, {
                updateSelection: false,
              })
              .selectInsertedNode(bias)
              .run()
          } else {
            return chain()
              .insertContent(content, { updateSelection: false })
              .selectInsertedNode(bias)
              .run()
          }
        },

      /**
       * Maps the focus pos through the transaction, so that you can focus after inserting
       * or deleting content. Offset is applied after mapping.
       * assoc defaults to -1 since usually we're inserting after a pos
       * https://prosemirror.net/docs/ref/#transform.Mappable.map
       */
      focusMapped:
        (pos, offset = 0, assoc = -1) =>
        ({ tr, view }) => {
          const mappedPos = tr.mapping.map(pos, assoc) + offset
          try {
            const newSelection = findSelectionInsideNode(
              tr.doc.resolve(mappedPos)
            )
            if (newSelection) {
              tr.setSelection(newSelection)
            }
            view.focus()
          } catch (error) {
            console.error('[focusMapped] error selecting pos', mappedPos, error)
          }
          return true
        },
      /**
       * To be chained with other commands that insert nodes, specifically `insertContent`
       * `insertContent()` will use Selection.near which doesn't always select the proper node.
       *
       * This is to be used when a node is inserted and we explicitly want to select it,
       * versus putting the selection at the nearest text node or valid gapcursor
       */
      selectInsertedNode:
        (bias = 1) =>
        ({ tr, view }) => {
          const $pos = getInsertedNodePos(tr)
          if (!$pos) {
            return false
          }

          const sel = findSelectionInsideNode($pos, bias)
          if (sel) {
            tr.setSelection(sel)
            view.focus()
            return true
          }

          return false
        },
      // Tiptap's setNodeSelection command is buggy because it tries to be too
      // clever about finding the doc edges. This is an alternative command
      // that's more reliable, and also catches error.
      selectNodeAtPos:
        (pos, scrollIntoView = false) =>
        ({ state, tr, view }) => {
          try {
            tr.setSelection(NodeSelection.create(state.doc, pos))
            if (scrollIntoView) {
              tr.scrollIntoView()
            }
            view.focus()
          } catch (error) {
            console.warn('[selectNodeAtPos] Error selecting node', pos, error)
          }
          return true
        },

      selectInsideNodeAtPos:
        (pos) =>
        ({ tr, state }) => {
          const $pos = state.doc.resolve(pos)
          const sel = findSelectionInsideNode($pos)
          if (!sel) return false
          tr.setSelection(sel)
          return true
        },

      deleteSelectionAndSelectNear:
        (dir = 1) =>
        ({ tr, state }) => {
          const { selection } = state
          // only handle selections with content
          if (selection.empty) {
            return false
          }

          // this is default behavior from `deleteSelection` prosemirror command
          tr.deleteSelection().scrollIntoView()
          const sel = findSelectionNearOrGapCursor(
            tr.doc.resolve(selection.from),
            dir
          )
          if (sel) {
            tr.setSelection(sel)
          }

          return true
        },
    }
  },
})
