import { getCardTitle } from '@gammatech/lib/dist/prosemirror-helpers'
import { Editor, mergeAttributes, Node } from '@tiptap/core'
import { Node as ProseMirrorNode } from 'prosemirror-model'
import { NodeSelection } from 'prosemirror-state'
import { Decoration } from 'prosemirror-view'

import { getImageTextDescription } from 'modules/media/utils/image'

import { UniqueAttributeResult } from '../../plugins'
import { ReactNodeViewRenderer } from '../../react'
import { DEFAULT_DOC_BACKGROUND } from '../../styles/backgroundStyles'
import { configureJSONAttribute, isNodeEmpty } from '../../utils'
import { ExtensionPriorityMap } from '../constants'
import { isMediaNode } from '../media/utils'
import { didDecorationsSpecChange } from '../updateFns'
import { initializeCardExpanded } from './CardCollapse'
import {
  findCardAccentBackground,
  getCardLayoutItemChildren,
} from './CardLayout/cardLayoutUtils'
import { monkeyPatchGapCursorForCardLayouts } from './CardLayout/monkeyPatchGapCursorForCardLayouts'
import { CardPlugin } from './CardPlugin'
import {
  CARD_CONTENT_CLASS,
  CARD_NODE_NAME,
  CARD_WRAPPER_CLASS,
} from './constants'
import { SwitchingCardView } from './SwitchingCardView'
import { UniqueCardId } from './uniqueId'

export * from './cardNavigationUtils'
export * from './constants'
/**
 * We are monkey patching GapCursor.valid to disallow gapcursors between
 * card and cardLayoutItem.  This has to be done in a runtime check because
 * we support the schema of card > content and card > cardLayoutItem > content
 */
monkeyPatchGapCursorForCardLayouts()

// A card is empty if it contains no blocks, or one empty block
export const isCardEmpty = (node: ProseMirrorNode) => {
  if (
    isNodeEmpty(node) ||
    (node.childCount === 1 && isNodeEmpty(node.firstChild!))
  ) {
    return true
  }

  // only blank layout cards can be considered empty
  if (node.attrs.layout !== 'blank') {
    return false
  }
  const layoutItems = getCardLayoutItemChildren(node)
  if (layoutItems.length === 0) {
    return false
  }

  const bodyItem = layoutItems.find((l) => l.attrs.itemId === 'body')
  if (!bodyItem) {
    // should always have bodyItem
    return false
  }

  return bodyItem.childCount === 1 && isNodeEmpty(bodyItem.firstChild!)
}

const cardUpdateFn = ({
  newNode,
  newDecorations,
  oldNode,
  oldDecorations,
  updateProps,
}: {
  newNode: ProseMirrorNode
  newDecorations: Decoration[]
  oldNode: ProseMirrorNode
  oldDecorations: Decoration[]
  updateProps: () => any
}) => {
  const didAttrsChange =
    JSON.stringify(newNode.attrs) !== JSON.stringify(oldNode.attrs)
  const decorationsChanged = didDecorationsSpecChange(
    oldDecorations,
    newDecorations
  )

  const emptyChanged = isCardEmpty(newNode) !== isCardEmpty(oldNode)

  if (didAttrsChange || decorationsChanged || emptyChanged) {
    console.debug(
      `[cardUpdateFn] Card ${newNode.attrs.id} updateProps will be called:`,
      {
        oldDecorations,
        newDecorations,
        decorationsChanged,
      }
    )
    updateProps()
  }
  return true
}

/**
 * A custom TipTap node view to represent a Gamma Card.
 * Read about it's API here: https://www.tiptap.dev/guide/node-views/react#introduction
 */
export const Card = Node.create({
  name: CARD_NODE_NAME,
  content: '(block | cardBlock)+ | cardLayoutItemGroup{1,2}',
  group: 'cardBlock',
  defining: false, // If this is true, copying from a nested card will always paste at the same depth
  isolating: true,
  selectable: false, // If this is true, clicking between blocks selects the card instead of putting the cursor between the blocks
  draggable: true, // This seems to be needed to rearrange top level (but not nested) cards. Without it, they get duplicated.
  priority: ExtensionPriorityMap.Card,
  containerHandle: true,

  expandable: true,

  addNodeView() {
    return ReactNodeViewRenderer(SwitchingCardView, {
      update: cardUpdateFn,
      ignoreMutation: ({ mutation }) => {
        const selection = this.editor.state.selection
        const element =
          mutation.target instanceof HTMLElement
            ? mutation.target
            : mutation.target.parentElement // This happens with text nodes

        // Ignore non-selection mutations on the controls of cards, even when they're nested
        const isMutationOutsideCardContent =
          mutation.type !== 'selection' && isElementOutsideCardContent(element)

        // Protect selection when the media drawer is open
        const shouldProtectMediaSelection =
          mutation.type === 'selection' &&
          selection instanceof NodeSelection &&
          isMediaNode(selection.node)

        if (isMutationOutsideCardContent) {
          return true
        } else if (shouldProtectMediaSelection) {
          return true
        } else {
          return false
        }
      },
    })
  },

  addProseMirrorPlugins() {
    return [CardPlugin(this.editor)]
  },

  addOptions() {
    return {
      isStatic: false,
    }
  },

  addAttributes() {
    return {
      id: {},
      previewContent: {
        default: null,
      },
      background: {
        default: DEFAULT_DOC_BACKGROUND,
        ...configureJSONAttribute('background'),
      },
      container: {
        default: {},
        ...configureJSONAttribute('container'),
      },
      // Card2 attributes
      cardSize: {
        default: 'default',
      },
      layout: {
        default: 'blank',
      },
      layoutTemplateColumns: {
        default: null, // string or null
      },
    }
  },

  addExtensions() {
    const onCardCreated = (
      editor: Editor,
      results: UniqueAttributeResult[],
      doc: ProseMirrorNode
    ) => {
      if (results.length === 0) return
      results.forEach(({ val, pos }) => {
        console.debug(
          `[Card.UniqueCardId] New card id(${val}) created. Will be created in postgres through content service.`
        )
        initializeCardExpanded(val) // New cards should default to expanded
      })
    }

    return [
      // Ensure each card has a unique ID and that paste/duplicate operations
      // have existing card IDs stripped out and replaced
      UniqueCardId.configure({ callback: onCardCreated, types: [this.name] }),
    ]
  },

  addCommands() {
    // Don't add commands here! Put them in CardCommands instead,
    // because this extension may not always be loaded (e.g. in footnotes)
    return {}
  },

  parseHTML() {
    return [
      // From clipboard
      {
        tag: 'div[class=gamma-card]',
      },
      // From AI
      {
        tag: 'section',
      },
      {
        tag: 'card[bookmark]',
        getAttrs: (el: HTMLElement) => {
          const bookmark = el.getAttribute('bookmark')
          if (!bookmark) return false
          const node = this.storage.bookmarks[bookmark]
          if (!node) return false
          return node.attrs
        },
        getContent: (el: HTMLElement) => {
          const bookmark = el.getAttribute('bookmark')
          if (!bookmark) return false
          const node = this.storage.bookmarks[bookmark]
          if (!node) {
            // This is a new card we didn't send in - created by the AI
            // todo: we could create a card here from the title attr
            return false
          }
          return node.content
        },
      },
    ]
  },
  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes(HTMLAttributes, { class: 'gamma-card' }), 0]
  },

  addStorage() {
    return {
      bookmarks: {},
    }
  },

  renderHTMLforAI({ node }) {
    this.storage.bookmarks[node.attrs.id] = node
    const cardTitle = getCardTitle(node.toJSON())
    const accentBg = findCardAccentBackground(node)

    return [
      'card',
      {
        bookmark: node.attrs.id,
        title: cardTitle,
        layout: node.attrs.layout,
        accentImage: accentBg?.image
          ? getImageTextDescription(accentBg.image)
          : undefined,
      },
    ]
  },
})

// We want ignore a mutation in .card-content > .card-wrapper
// But not in .card-content > .card-wrapper > .card-content
// So this checks that the nearer of the two parents is a card-wrapper
// Note that element.closest includes the element itself, so we use element.parentElement.closest
export const isElementOutsideCardContent = (element) =>
  element?.parentElement &&
  (element.classList.contains(CARD_WRAPPER_CLASS) ||
    element.parentElement
      .closest(`.${CARD_CONTENT_CLASS}, .${CARD_WRAPPER_CLASS}`)
      ?.classList.contains(CARD_WRAPPER_CLASS))
