import { Box } from '@chakra-ui/react'
import { NodeViewProps } from '@tiptap/core'

import { useAppSelector } from 'modules/redux'
import { NodeViewContent } from 'modules/tiptap_editor/react'
import {
  selectIsStatic,
  selectToggleExpanded,
} from 'modules/tiptap_editor/reducer'

import { AnnotatableNodeViewWrapper } from '../Annotatable'
import { LEFT_PADDING } from './constants'

export const ToggleView = (nodeViewProps: NodeViewProps) => {
  const { node } = nodeViewProps

  const isStatic = useAppSelector(selectIsStatic)
  const isOpen = useAppSelector(selectToggleExpanded(node.attrs.id)) || isStatic

  return (
    <AnnotatableNodeViewWrapper
      {...nodeViewProps}
      hideComments={isOpen} // Prevent double comments showing up on the summary and the toggle
      readOnly={!isOpen} // Todo: allow commenting when the toggle is closed, but put it on the summary
    >
      <Box
        pl={LEFT_PADDING}
        position="relative"
        data-open={!!isOpen}
        className="toggle"
        borderRadius="var(--box-border-radius)"
        data-selection-ring="padded"
      >
        <NodeViewContent />
        {isOpen && (
          <Box
            position="absolute"
            left="0.375em"
            bottom="0.25em"
            top="2.5em"
            borderLeft="1px solid"
            borderColor="var(--box-border-color)"
          />
        )}
      </Box>
    </AnnotatableNodeViewWrapper>
  )
}
