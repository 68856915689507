import { createContext, useContext } from 'react'
/**
 * This editorId corresponds to the optionalId within EditorCore
 *
 * It's used to identify various editor instances, primary for the
 * useOnEditorRendered hooks to allow subscribes to only listen to
 * a signular editor (main) being rendered
 */

const EditorIdContext = createContext<string | undefined>(undefined)

export const EditorIdProvider = EditorIdContext.Provider

export const useEditorId = () => {
  return useContext(EditorIdContext)
}
