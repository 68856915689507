import { BoxProps } from '@chakra-ui/layout'

import { AnimationConfig } from 'modules/media'
import { ImageAttrs } from 'modules/media/types/Image'
import { Theme } from 'modules/theming'
import { VibeGradient } from 'modules/theming/components/MeshGradientPicker/types'
import { isColorDark } from 'utils/color'
import { resizeGammaCDNUrls } from 'utils/image'

import { ColorAttrs } from '../components/panels/ColorPanel'
import { DEFAULT_MASK } from '../extensions/Card/Card2/BackgroundMask'

export type MaskOptions = {
  effect?: 'none' | 'faded' | 'frosted'
  color?: 'black' | 'white'
}

export type BackgroundOptions = {
  type: BackgroundType // eg "image"
  source?: string // eg "image.unsplash"
  originalSource?: string // eg "image.unsplash" - the original source, to be used by the theme editor for accent images
  accentId?: string
  inside?: boolean
  // Images
  image?: ImageAttrs
  // Animations
  animation?: AnimationConfig
  // Colors
  color?: ColorAttrs
  // Gradients
  gradient?: VibeGradient
  // Legacy/custom
  css?: BoxProps
  mask?: MaskOptions
}

export enum BackgroundType {
  ANIMATION = 'animation',
  IMAGE = 'image',
  COLOR = 'color',
  GRADIENT = 'gradient',
  NONE = 'none',
}

// By default, docs should inherit from the theme's background
export const DEFAULT_DOC_BACKGROUND = {
  type: BackgroundType.NONE,
  source: undefined,
}

export const DEFAULT_THEME_BACKGROUND = {
  type: BackgroundType.COLOR,
  color: {
    hex: '#FAFAFA',
  },
}

export const DEFAULT_ACCENT_IMAGE_BACKGROUND: BackgroundOptions = {
  type: BackgroundType.GRADIENT,
  source: 'color.gradient',
  gradient: {
    vibe: 'crescent',
    primaryColor: '#75d8ff',
    css: {},
  },
}

export const getDocOrThemeBackground = (
  theme: Theme,
  docBackground?: BackgroundOptions
): BackgroundOptions =>
  !docBackground || docBackground.type === BackgroundType.NONE
    ? !theme.config.background ||
      theme.config.background.type === BackgroundType.NONE
      ? DEFAULT_THEME_BACKGROUND
      : theme.config.background
    : docBackground

// Return true for dark, false for light, null for no mask or default color
// If useDefaultMask is true, it will use the default mask if none is provided
export const isBackgroundDark = (
  background: BackgroundOptions,
  useDefaultMask?: boolean
): boolean | null => {
  // If it's a color background, check if the color is dark
  if (background.type === BackgroundType.COLOR && background.color) {
    return background.color.isDark ?? isColorDark(background.color.hex)
  } else if (background.type === BackgroundType.GRADIENT) {
    return null // Gradients auto adapt to light/dark mode
  }

  const mask = background.mask || (useDefaultMask ? DEFAULT_MASK : null)
  if (!mask) {
    // Todo: check the average_color of the image
    return null
  }
  // If there's a mask applied, check if it's black or white
  switch (mask.color) {
    case 'black':
      return true
    case 'white':
      return false
    default:
      return null
  }
}

export const getBackgroundProps = (
  background: BackgroundOptions,
  isDark: boolean,
  isPreview?: boolean
): BoxProps => {
  if (background.type === BackgroundType.GRADIENT) {
    const css = background.gradient?.css || background.css
    return {
      backgroundColor: isDark ? 'black' : 'white',
      ...css,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
    }
  }

  if (background.type === BackgroundType.IMAGE && background.image) {
    const urls = [background.image.src, background.image.tempUrl]
      .filter((url) => url)
      .map((url: string) => {
        if (isPreview) {
          url = resizeGammaCDNUrls(url, { width: 250 })
        }
        return `url(${url})`
      })

    return {
      backgroundImage: urls.join(', '),
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    }
  }

  if (background.type === BackgroundType.COLOR && background.color) {
    return {
      // Legacy backgrounds had color specified in color field directly,
      // new ones should all be in color.hex
      backgroundColor:
        typeof background.color === 'string'
          ? background.color
          : background.color.hex,
    }
  }

  return {}
}

export const getBackgroundColor = (
  background?: BackgroundOptions
): string | undefined => {
  if (
    background &&
    background.type === BackgroundType.COLOR &&
    background.color
  ) {
    return background.color.hex
  } else {
    return
  }
}
