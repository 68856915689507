import { range } from 'lodash'
import tinycolor from 'tinycolor2'

import { Theme } from 'modules/theming'
import { colorWithLightness } from 'utils/color'

export const generateGradientSpectrum = (
  color: string,
  minLightness: number,
  maxLightness: number,
  steps: number
) => {
  const lightenedColor = colorWithLightness(color, minLightness)
  const analogs = range(steps).map((i) =>
    tinycolor(lightenedColor).spin(i * (60 / steps))
  )
  const gradients = range(steps).map((i) => {
    const color1 = analogs[i].toHexString()
    const color2 = colorWithLightness(
      analogs[i].spin(10).toHexString(),
      maxLightness
    )
    return `linear-gradient(135deg, ${color1} 0%, ${color2} 100%)`
  })
  return gradients
}

export const getColorForIndex = (theme: Theme, index: number) => {
  // We generate up to 5 readable accent color CSS variables
  const modIdx = index % 5
  if (modIdx === 0) return 'var(--accent-color)'
  return `var(--accent-color-${modIdx + 1})`
}
