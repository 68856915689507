import { Editor, JSONContent } from '@tiptap/core'
import { Plugin, PluginKey } from 'prosemirror-state'

import {
  DragAnnotationData,
  DropAnnotationEvent,
} from '../Annotatable/AnnotationExtension/types'
import { decorateLayouts } from './decoration'
import { checkSmartLayoutDropTarget, SmartLayoutDropTarget } from './utils'

class SmartLayoutPluginState {
  constructor(public dragging: DragAnnotationData | null = null) {}
}
const SmartLayoutPluginKey = new PluginKey<SmartLayoutPluginState>(
  'smartLayoutPlugin'
)

export const SmartLayoutPlugin = (editor: Editor) =>
  new Plugin({
    key: SmartLayoutPluginKey,
    state: {
      init() {
        return new SmartLayoutPluginState()
      },

      apply(_transaction, pluginState) {
        return pluginState
      },
    },
    props: {
      decorations(state) {
        return decorateLayouts(state)
      },

      handleDOMEvents: {
        drop(view) {
          // Store the annotation drag data here temporarily in the plugin state
          // this because the native prosemirror drop handler clears out the dragging data
          // before it calls pluginHandlers for dropHandler
          const annotationData = (view.dragging as any)
            ?.annotations as DragAnnotationData | null
          const pluginState = SmartLayoutPluginKey.getState(view.state)
          if (!pluginState) {
            return false
          }
          pluginState.dragging = annotationData
          return
        },
      },
      handleDrop: (view, event, slice) => {
        const pluginState = SmartLayoutPluginKey.getState(view.state)
        const dragAnnotationData = pluginState?.dragging
        if (pluginState) {
          // on drop always get rid of the drag data
          pluginState.dragging = null
        }

        let smartLayoutDropTarget: SmartLayoutDropTarget | null = null

        try {
          smartLayoutDropTarget = checkSmartLayoutDropTarget(
            view,
            event as DragEvent,
            slice
          )
          if (!smartLayoutDropTarget) {
            return false
          }
        } catch (err) {
          return false
        }
        try {
          const { selection } = view.state
          const shouldDeleteOriginal = !selection.empty // Insert widget will set this empty
          const { node, pos, side } = smartLayoutDropTarget

          // Don't allow dropping inside yourself
          if (pos > selection.from && pos < selection.to) return true

          const pasteContent = slice.content.toJSON() as JSONContent[]

          const insertPos = side === 'left' ? pos : pos + node.nodeSize

          editor
            .chain()
            .insertContentAt({ from: insertPos, to: insertPos }, pasteContent, {
              updateSelection: false,
            })
            .command(({ tr }) => {
              if (shouldDeleteOriginal) tr.deleteSelection()

              if (dragAnnotationData) {
                tr.setMeta('annotationEvent', <DropAnnotationEvent>{
                  type: 'drop',
                  dragging: dragAnnotationData,
                  // add 1 to account for the shift by wrapping content in smartLayoutCell
                  droppedBlockPos: insertPos + 1,
                })
              }

              return true
            })
            .focusMapped(insertPos, 1) // Focus into the new cell
            .run()
        } catch (err) {
          console.error('(caught) [SmartLayoutPlugin] handleDrop error:', err)
        }
        // if we've determined that the drop target is not null
        // we always want to return true to prevent the default drop handler in view.js from running
        return true
      },
    },
  })
