import { config } from 'config'
import { UAParser } from 'utils/deviceDetection'

type UserMetadata = Record<string, any>

export const getUserMetadataClientSide = (): UserMetadata => {
  if (!config.IS_CLIENT_SIDE) {
    return {}
  }
  const browser = UAParser.getBrowser()
  const device = UAParser.getDevice()
  const os = UAParser.getOS()
  const DEFAULT_DATA = {
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    userAgent: navigator.userAgent,
    locale: navigator.language,
    browser: {
      name: browser.name,
      version: browser.version,
    },
  } as UserMetadata

  if (browser) {
    DEFAULT_DATA['browser'] = {
      name: browser.name,
      version: browser.version,
    }
  }
  if (device && device.type) {
    if (!('device' in DEFAULT_DATA)) {
      DEFAULT_DATA.device = {}
    }
    DEFAULT_DATA.device.type ||= device.type
    DEFAULT_DATA.device.model ||= device.model
  }

  if (os) {
    if (!('os' in DEFAULT_DATA)) DEFAULT_DATA['os'] = {}
    DEFAULT_DATA['os'].name ||= os.name
    DEFAULT_DATA['os'].version ||= os.version
  }
  return DEFAULT_DATA
}
