import { NodeViewProps } from '@tiptap/react'

import { useFeatureFlag } from 'modules/featureFlags'
import { useAppSelector } from 'modules/redux'
import { Theme } from 'modules/theming'
import { selectTheme } from 'modules/tiptap_editor/reducer'

import { ImageView } from './ImageView'
import { ImageView2 } from './ImageView2'

export const ImageViewContainer = (nodeViewProps: NodeViewProps) => {
  const theme: Theme = useAppSelector(selectTheme)
  const imageView2 = useFeatureFlag('imageView2')
  if (!theme) {
    return null
  }

  return imageView2 ? (
    <ImageView2 {...nodeViewProps} theme={theme} />
  ) : (
    <ImageView {...nodeViewProps} />
  )
}
