import { DOC_DISPLAY_NAME } from '@gamma-app/ui'

export const CARD2_LAYOUT_CLASS = 'card2-layout'
export const CARD_BACKGROUND_PADDING_RESPONSIVE = {
  base: '5rem',
  lg: '8rem',
  '3xl': '10rem',
}

export const DEFAULT_BACKGROUND_DISPLAY = {
  accentImage: `Try adding an image from this theme's accent image library or search Unsplash for inspiration. You can also upload your own image here.`,
  cardBackground: `The ${DOC_DISPLAY_NAME}'s background will show up behind
this card. You can change the ${DOC_DISPLAY_NAME}'s
background using the theme button in the toolbar, or add a
background specific to this card.`,
}
