import {
  absolutePositionToRelativePosition,
  ySyncPluginKey,
} from '@gamma-app/y-prosemirror'
import { EditorState, PluginKey, Transaction } from 'prosemirror-state'
import * as Y from 'yjs'

import { relativeToAbsolutePos } from 'modules/tiptap_editor/utils/relativePosition'

export const ColumnResizePluginKey = new PluginKey<ColumnResizeState>(
  'tableColumnResizing'
)

type DraggingState = {
  startX: number
  startWidth: number
  colWidths: number[]
  colIndex: number
  tableWidth: number
}
export type ResizeStateSetHandleEvent = {
  setHandle: number | null
}
export type ResizeStateSetDraggingEvent = {
  setDragging: DraggingState | null
}
export type ResizeStateResetEvent = {
  reset: true
}

type ResizeStateEvent = ResizeStateSetDraggingEvent | ResizeStateSetHandleEvent

export class ColumnResizeState {
  constructor(
    public activeHandle: Y.RelativePosition | null = null,
    public dragging: DraggingState | null = null
  ) {}

  getActiveHandleAbs(state: EditorState): number | null {
    if (this.activeHandle === null) {
      return null
    }
    return relativeToAbsolutePos(state, this.activeHandle)
  }

  reset(): this {
    this.activeHandle = null
    this.dragging = null
    return this
  }

  setHandle(state: EditorState, event: ResizeStateSetHandleEvent): this {
    if (event.setHandle === null) {
      this.activeHandle = null
      return this
    }
    const ystate = ySyncPluginKey.getState(state)
    if (!ystate) {
      return this
    }
    const { type, binding } = ystate
    this.activeHandle = absolutePositionToRelativePosition(
      event.setHandle,
      type,
      binding.mapping
    )
    return this
  }

  setDragging(event: ResizeStateSetDraggingEvent): this {
    this.dragging = event.setDragging
    return this
  }

  apply(tr: Transaction, state: EditorState): this {
    const action = tr.getMeta(ColumnResizePluginKey) as ResizeStateEvent | null

    if (action && 'setHandle' in action) {
      return this.setHandle(state, action)
    }
    if (action && 'setDragging' in action) {
      return this.setDragging(action)
    }
    if (action && 'reset' in action) {
      return this.reset()
    }

    // for other changes we count on Y.RelativePosition to hold it's position
    // correctly
    return this
  }
}
