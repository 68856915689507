/**
 * A selection class to represent the editor selection
 * when in present mode.
 *
 * The main feature here is a NodeSelection that supports
 * a side of -1, 0, or 1.
 */
import { Fragment, Node, ResolvedPos, Slice } from 'prosemirror-model'
import { Selection } from 'prosemirror-state'
import { Mappable } from 'prosemirror-transform'

import { findSelectionInsideNode } from './findSelectionInsideNode'
import { findSelectionNearOrGapCursor } from './findSelectionNearOrGapCursor'

type Side = -1 | 0 | 1

/// A presentation selection is a selection that points at a single node.
/// It is identical to NodeSelection with an additional property "side".
/// The side can be -1, 0, or 1, where 0 points at the node itself, and
/// -1 and 1 point at the node's beginning and end respectively.
export class PresentationSelection extends Selection {
  /// Create a presentation selection. Does not verify the validity of its
  /// argument.
  constructor($pos: ResolvedPos, side: Side = 0) {
    const node = $pos.nodeAfter!
    const $end = $pos.node(0).resolve($pos.pos + node.nodeSize)
    super($pos, $end)
    this.node = node
    this.side = side
  }

  side: Side

  /// The selected node.
  node: Node

  map(doc: Node, mapping: Mappable): Selection {
    const { deleted, pos } = mapping.mapResult(this.anchor)
    const $pos = doc.resolve(pos)
    if (deleted) {
      return findSelectionNearOrGapCursor($pos) || Selection.near($pos)
    }
    return new PresentationSelection($pos, this.side)
  }

  content() {
    return new Slice(Fragment.from(this.node), 0, 0)
  }

  eq(other: Selection): boolean {
    return (
      other instanceof PresentationSelection &&
      other.anchor === this.anchor &&
      other.side === this.side
    )
  }

  toJSON() {
    return {
      type: 'presentation',
      anchor: this.anchor,
      side: this.side,
    }
  }

  createSelectionNear() {
    const biasToUse = this.side < 1 ? 1 : -1
    const sel = findSelectionInsideNode(this.$from, biasToUse)
    return sel || Selection.near(this.$from)
  }

  /// @internal
  static fromJSON(doc: Node, json: any) {
    if (typeof json.anchor != 'number')
      throw new RangeError('Invalid input for PresentationSelection.fromJSON')
    return new PresentationSelection(doc.resolve(json.anchor))
  }

  /// Create a node selection from non-resolved positions.
  static create(doc: Node, from: number, side?: Side) {
    return new PresentationSelection(doc.resolve(from), side)
  }

  /// Determines whether the given node may be selected as a presentation
  /// selection.
  static isSelectable(node: Node) {
    return !node.isText && node.type.spec.selectable !== false
  }
}

PresentationSelection.prototype.visible = false

// @ts-ignore
if (!PresentationSelection.prototype.jsonID) {
  try {
    Selection.jsonID('presentation', PresentationSelection)
  } catch (e) {
    // swallow this error, it only occurs in hot-reloading
  }
}
