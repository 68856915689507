import { useOutsideClick } from '@chakra-ui/react'
import { NodeViewProps } from '@tiptap/core'
import uniqBy from 'lodash/uniqBy'
import { MutableRefObject, useEffect, useLayoutEffect, useState } from 'react'

import { Comment, User } from 'modules/api'
import { isMobileOrTabletDevice } from 'utils/deviceDetection'

import { MAX_POPUP_OFFSET_TOP, NEW_COMMENT_TEMP_ID } from '../../../constants'
import { DraftComment } from '../../../DraftCommentsExtension/types'
import { BlockReaction } from '../../types'
import { COMMENTS_WRAPPER_CLASSNAME } from '../components/BlockCommentsWrapper'
import { getNewComment } from '../utils'

type BlockCommentsButtonState =
  | 'fresh'
  | 'draft'
  | 'multiple-threads'
  | 'single-thread'
  | 'reactions-only'

export const REACTION_COUNT_TO_SHOW = 7

const getButtonState = ({
  comments,
  reactions,
  draftComment,
}: {
  comments: Comment[]
  reactions: BlockReaction[]
  draftComment: DraftComment | null
}): BlockCommentsButtonState => {
  if (draftComment && draftComment.text.trim().length > 0) {
    return 'draft'
  }

  if (comments.length === 0) {
    return reactions.length === 0 ? 'fresh' : 'reactions-only'
  } else if (comments.length === 1) {
    return 'single-thread'
  } else if (comments.length > 1) {
    return 'multiple-threads'
  }

  throw new Error('Invalid button state')
}
const getUsersByThread = ({
  comments,
  reactions,
}: {
  comments: Comment[]
  reactions: BlockReaction[]
}): { [commentId: string]: User[] } => {
  const usersByThread: { [commentId: string]: User[] } = {}

  comments.forEach((c) => {
    usersByThread[c.id] = [c.user!]

    c.replies!.forEach((r) => {
      usersByThread[c.id].push(r.user!)
    })
  })
  Object.entries(usersByThread).forEach(([key, users]) => {
    usersByThread[key] = uniqBy(users, 'id')
  })

  return usersByThread
}

const getReactionUsersByEmoji = ({
  reactions,
}: {
  reactions: BlockReaction[]
}): { [commentId: string]: User[] } => {
  const usersByEmoji: { [emoji: string]: User[] } = {}

  reactions.forEach((br) => {
    usersByEmoji[br.emoji] = uniqBy(
      br.reactions.flatMap((r) => (r.users! as User[])!),
      'id'
    )
  })
  return usersByEmoji
}

export const useBlockCommentsButtonData = ({
  comments,
  reactions,
  draftComment,
}: {
  comments: Comment[]
  reactions: BlockReaction[]
  draftComment: DraftComment | null
}) => {
  const state = getButtonState({ comments, reactions, draftComment })
  const allCount =
    comments.reduce((t, comment) => {
      return t + (comment.replies?.length || 0)
    }, 0) +
    reactions.reduce((total, reaction) => total + reaction.count, 0) +
    comments.length

  const overflowReactionCount = Math.max(
    0,
    reactions.length - REACTION_COUNT_TO_SHOW
  )
  return {
    state,
    allCount,
    overflowReactionCount,
  }
}

export const useAvatarGroupData = ({
  comments,
  reactions,
}: {
  comments: Comment[]
  reactions: BlockReaction[]
}) => {
  const allReactionUsers: User[] = reactions
    .flatMap((br) => br.reactions.map((r) => (r.users! as User[])!))
    .flat()

  const allThreadsUsers: User[] = comments
    .map((c) => c.user)
    .concat(comments.map((c) => c.replies!.map((r) => r.user)).flat())
    .filter(Boolean) as User[]

  const usersByThread = getUsersByThread({ comments, reactions })
  const allAvatars = uniqBy([...allThreadsUsers, ...allReactionUsers], 'id')
  const commentAvatars: User[][] = []
  const reactionAvatars = getReactionUsersByEmoji({ reactions })
  comments.forEach((comment) => {
    commentAvatars.push(usersByThread[comment.id])
  })
  return {
    allAvatars,
    reactionAvatars,
    commentAvatars,
  }
}

export const useExpandedAndHideOthers = ({
  popup,
  isMobile,
  isHovered,
  comments,
  reactions,
  draftComment,
  enableReactions,
}: {
  popup: string | null
  isMobile: boolean
  isHovered: boolean
  comments: Comment[]
  reactions: BlockReaction[]
  draftComment: DraftComment | null
  enableReactions: boolean
}) => {
  const { state: buttonState } = useBlockCommentsButtonData({
    comments,
    reactions,
    draftComment,
  })

  const isExpanded =
    isMobile ||
    // if reactions are enabled then all button states (including fresh) are considered
    // expanded when hovering
    (enableReactions && isHovered) ||
    // when reactions are disabled, then fresh isn't considered expanded
    (!enableReactions && isHovered && buttonState !== 'fresh') ||
    // TODO when ripping out enableReactions feature flag this logic is simplified to:
    // `isHovered || popup !== null`
    popup !== null

  useEffect(() => {
    if (isMobile) {
      return
    }
    if (isExpanded) {
      document.body.classList.add('is-taking-action')
    } else {
      document.body.classList.remove('is-taking-action')
    }
  }, [isExpanded, isMobile])

  return {
    isExpanded,
  }
}

export const useClickOutsideToHide = ({
  isOpen,
  popupRef,
  onClose,
}: {
  isOpen: boolean
  popupRef: MutableRefObject<HTMLDivElement | null>
  onClose: () => void
}) => {
  useOutsideClick({
    ref: popupRef,
    handler: (e) => {
      // On mobile devices, ignore outside clicks unless they come from another annotatable component
      const ignoreForMobile =
        isMobileOrTabletDevice() &&
        // Note: This check wont be necessary once the comments list becomes a singleton
        //       since only one list will ever be able to be open
        !(e?.target as HTMLElement)?.closest(
          `.${COMMENTS_WRAPPER_CLASSNAME} [data-controls-toggle-button]`
        )
      if (
        ignoreForMobile ||
        !isOpen ||
        // Ignore if it's coming from the MentionsList or EmojiPicker list, Or a menu
        (e?.target as HTMLElement)?.closest('[data-controls-toggle-button]') ||
        (e?.target as HTMLElement)?.closest(
          '[data-target-name="emoji-list"]'
        ) ||
        (e?.target as HTMLElement)?.closest(
          '[data-target-name="mention-list"]'
        ) ||
        (e?.target as HTMLElement)?.closest(
          '[data-target-name="reaction-emoji-picker"]'
        ) ||
        (e?.target as HTMLElement)?.closest('.chakra-menu__menu-list') ||
        (e?.target as HTMLElement)?.closest(
          '[data-target-name="doc-mention-popup"]'
        )
      ) {
        return
      }
      onClose()
    },
  })
}

/**
 * This hooks listen to the array of Comments being passed to a component,
 * it looks for a temp-id that is assigned via apollo optimistic create.
 *
 * The openCommentFn is then invoked with the tempId comment and the fully created
 * comment (via matching targetId).  This allows the UI to immediately open the new
 * comment via optimistic update and then set the viewing comment when the persisted
 * version comes in.
 *
 * TODO(jordan): test this
 */
export const useOpenNewlyCreatedComment = (
  comments: Comment[],
  openCommentFn: (comment: Comment) => void
) => {
  const [lastComments, setLastComments] = useState<Comment[]>(comments)
  const [tempCommentTargetId, setTempCommentTargetId] = useState<string | null>(
    null
  )

  const newComment = getNewComment(lastComments, comments)
  // this will get called first when apollo optimistically creates the comment
  if (newComment && newComment.id === NEW_COMMENT_TEMP_ID) {
    setTempCommentTargetId(newComment.targetId!)
    setLastComments(comments)
    openCommentFn(newComment)
    return
  }

  if (
    newComment &&
    tempCommentTargetId &&
    newComment.targetId === tempCommentTargetId
  ) {
    // this is a new comment created from this browser via optimistic update
    setTempCommentTargetId(null)
    setLastComments(comments)
    openCommentFn(newComment)
    return
  }
}

/**
 * gets the offset from top so we can figure out how far to translate
 * the comment popup down when on a narrow screen
 */
export const useDomNodeOffsetFromTop = ({
  editor,
  getPos,
}: {
  editor: NodeViewProps['editor']
  getPos: NodeViewProps['getPos']
}): number | null => {
  const domAtNode = editor.view.nodeDOM(getPos()) as HTMLElement
  const [offsetFromTop, setOffsetFromTop] = useState<number | null>(null)

  useLayoutEffect(() => {
    setOffsetFromTop(domAtNode?.offsetHeight || 100)
    // we dont need to update this because domAtNode we only position for the initial render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // we dont know the position, so wait until useLayoutEffect before rendering
  if (offsetFromTop === null) {
    return null
  }
  return Math.min(offsetFromTop, MAX_POPUP_OFFSET_TOP)
}
