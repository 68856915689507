import { CSSObject } from '@emotion/react'

import { getThemeBase } from '../themeBases'
import { Theme } from '../types'

export const CLICKABLE_BOX_CLASS = 'themed-clickable-box'
export const BOX_STYLE_CLASS = 'themed-box'
export const LINE_STYLE_CLASS = 'themed-line'

export const getBoxStyles = (theme: Theme) => {
  const base = getThemeBase(theme)
  return {
    ...base.boxSx,
  }
}

export const clickableTransitionSx = {
  transitionProperty: 'transform',
  transitionDuration: 'normal',
}

export const clickableHoverSx: CSSObject = {
  transform: 'scale(1.03)',
  _before: {
    content: '""',
    position: 'absolute',
    inset: 0,
    borderRadius: 'inherit',
    boxShadow: `inset 0 0 0 500px var(--expandable-hover-background)`, // Dim the background without impacting content
    pointerEvents: 'none',
  },
}

export const getClickableStyles = (theme: Theme, isEditable: boolean) => {
  const base = getThemeBase(theme)
  return {
    ...clickableTransitionSx,
    ...base.boxSx,
    ...base.clickableSx,
    _hover: isEditable ? undefined : clickableHoverSx,
  }
}

export const getBoxStylesheet = (
  theme: Theme,
  isEditable: boolean
): CSSObject => {
  return {
    [`.${BOX_STYLE_CLASS}`]: getBoxStyles(theme),
    [`.${CLICKABLE_BOX_CLASS}`]: getClickableStyles(theme, isEditable),
  }
}

// For buttons within a clickable element - e.g. expand card or open in embed in new tab
// Needs to scale with the font size in present mode
export const ClickableButtonSx = {
  _hover: {
    transform: 'scale(1.1)',
  },
  color: 'var(--link-color)',
  height: 'auto',
  padding: '0.7em',
  paddingY: '0.2em',
  minW: '2.5em',
  position: 'relative',
}
