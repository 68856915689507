import { TourProps } from '@reactour/tour'

export const baseStyles: TourProps['styles'] = {
  badge: (base) => ({
    ...base,
    background: 'var(--chakra-colors-trueblue-500)',
  }),
  // @ts-ignore
  dot: (base, { current }) => ({
    ...base,
    background: current ? 'var(--chakra-colors-trueblue-500)' : '#ccc',
  }),
  popover: (base) => ({
    ...base,
    borderRadius: 'var(--chakra-radii-lg)',
  }),
  maskArea: (base) => ({
    ...base,
    rx: 5,
  }),
  maskWrapper: (base) => ({ ...base, opacity: 0.2, pointerEvents: 'none' }),
  maskRect: (base) => ({
    ...base,
    pointerEvents: 'none',
  }),
  clickArea: (base) => ({
    ...base,
    pointerEvents: 'none',
  }),
}
