import {
  Avatar,
  Box,
  Collapse,
  Flex,
  FlexProps,
  HStack,
  Link,
  Stack,
  Text,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { JSONContent, NodeViewProps } from '@tiptap/core'
import {
  memo,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useDispatch } from 'react-redux'

import {
  Comment,
  CommentStatus,
  useCreateCommentMutation,
  useRemoveCommentMutation,
  useUpdateCommentMutation,
  useUpdateCommentStatusMutation,
} from 'modules/api'
import { useScrollManager } from 'modules/scroll'
import { CommentEditor } from 'modules/tiptap_editor/CommentEditor'
import { EventBusEvent, TiptapEventBus } from 'modules/tiptap_editor/eventBus'
import {
  MEMO_LEVEL_COMMENT_ABS_POS,
  NEW_COMMENT_TEMP_ID,
} from 'modules/tiptap_editor/extensions/Annotatable/constants'
import { openParentCards } from 'modules/tiptap_editor/extensions/Card'
import {
  BETWEEN_CARD_TRANSITION_TIME,
  EXPAND_CARD_TRANSITION_TIME,
} from 'modules/tiptap_editor/extensions/Card/constants'
import { isCardNode } from 'modules/tiptap_editor/extensions/Card/utils'
import {
  deleteDraftReply,
  setFollowingAttached,
} from 'modules/tiptap_editor/reducer'
import { findParentNodes, getDomNodeFromPos } from 'modules/tiptap_editor/utils'
// TODO: Move this and other useful shared API resources into modules/api
import { DOC_COMMENTS_FRAGMENTS } from 'sections/docs/graphql'
import { Deferred } from 'utils/deferred'
import { isMobileDevice } from 'utils/deviceDetection'
import { generateCommentUrl } from 'utils/url'

import { EditorModeEnum } from '../../../../types'
import { AnnotationPluginKey } from '../../AnnotationExtension/AnnotationPluginKey'
import { AnnotationState } from '../../AnnotationExtension/AnnotationState'
import { CommentReactions } from '../CommentReactions/CommentReactions'
import { useDraftReply } from '../hooks'
import { CommentHeader } from './CommentHeader'
import { COMMENT_STATUS_CONFIG } from './CommentStatusButton'
import { CommentTargetContent } from './CommentTargetContent'

const REPLY_PADDING = 10

export type CommentInstanceType = 'feed' | 'notification' | 'popup' | 'drawer'

type CommentProps = {
  comment: Comment
  selfUser: Comment['user']
  editor: NodeViewProps['editor']
  docId: string
  instanceType: CommentInstanceType
  commentIdToHighlight?: string | null
  editorMode?: EditorModeEnum
  isMobile?: boolean
  isRootComment?: boolean
  onClose?: () => void
  closePopup?: (closeStack?: boolean) => void
} & (CanCommentProps | CannotCommentProps) &
  FlexProps

type CanCommentProps = {
  userCanComment: true
}

type CannotCommentProps = {
  userCanComment: false
}

const CommentComponent = ({
  comment,
  editor,
  selfUser,
  docId,
  commentIdToHighlight,
  userCanComment,
  instanceType,
  editorMode,
  isRootComment = false,
  onClose,
  closePopup,
  ...flexProps
}: CommentProps) => {
  const isInPanel = instanceType === 'feed'
  const isInDrawer = instanceType === 'drawer'
  const toastPosition = isMobileDevice() ? 'bottom' : 'top'
  const {
    content,
    targetHtml,
    targetId,
    createdTime,
    updatedTime,
    id,
    replies,
    reactions,
  } = comment
  const dispatch = useDispatch()
  const { initialDraftReply, updateLocalDraftReply, hasLocalDraftReply } =
    useDraftReply(id)
  const createCommentEditorDef = useRef(new Deferred())
  const isHighlighting = commentIdToHighlight === id
  const commentUrl = generateCommentUrl({ docId, id })
  const commentRef = useRef<HTMLDivElement | null>(null)
  // eslint-disable-next-line
  const [isEditing, setIsEditing] = useState(false)
  const [isReplying, setIsReplying] = useState(isRootComment)
  // synchronize the state of isReplying since the initial
  // load of draftCommentReplies happens in a useEffect
  // and thus wont be available on the initialization of the `useState` for isReplying
  useEffect(() => {
    if (hasLocalDraftReply && !isReplying) {
      setIsReplying(true)
    }
  }, [hasLocalDraftReply, isReplying])
  const [createComment] = useCreateCommentMutation()
  const [updateComment] = useUpdateCommentMutation()
  const [updateCommentStatus] = useUpdateCommentStatusMutation()
  const [removeComment] = useRemoveCommentMutation()
  // const { email, displayName, profileImageUrl } = user as User
  const toast = useToast()
  const scrollManager = useScrollManager('editor')

  const handleEditCommentClick = useCallback(() => {
    setIsEditing(true)
  }, [])

  const handleCancelReplyClick = useCallback(() => {
    updateLocalDraftReply(null)
  }, [updateLocalDraftReply])

  const ease = [0.25, 0.1, 0.25, 1]
  // Use framer-motion's when property to ensure our transition
  // runs after the children's do, delaying it until theyre mounted
  // See https://www.framer.com/docs/transition/###when
  const lazyTransitionConfig = {
    enter: {
      when: 'afterChildren',
      height: { duration: 0.3, ease },
      opacity: { duration: 0.4, ease },
    },
    exit: {
      when: 'beforeChildren',
      height: { duration: 0.2, ease },
      opacity: { duration: 0.3, ease },
    },
  }

  useLayoutEffect(() => {
    if (!isHighlighting || !commentRef.current) return

    scrollManager.scrollElementIntoView({ element: commentRef.current })
  }, [scrollManager, isHighlighting])

  const onCommentCommentSave = (commentJSON: JSONContent) => {
    const input = {
      targetId: comment.targetId,
      commentId: id,
      docId,
      content: commentJSON,
      cardId: comment.cardId,
    }
    createComment({
      variables: { input },
      update: (cache, { data }) => {
        cache.writeFragment({
          id: `Doc:${docId}`,
          fragment: DOC_COMMENTS_FRAGMENTS,
          fragmentName: 'DocRepliesCreate',
          data: {
            comments: [
              {
                id,
                replies: [data?.createComment],
                __typename: 'Comment',
              },
            ],
          },
        })
      },
      optimisticResponse: {
        createComment: {
          id: NEW_COMMENT_TEMP_ID,
          __typename: 'Comment',
          ...input,
          user: selfUser,
          archived: false,
          reactions: [],
          createdTime: new Date().toISOString(),
          updatedTime: new Date().toISOString(),
        },
      },
    })
    setIsReplying(false)
    // Delete draft comment now that's its saved
    updateLocalDraftReply(null)
    dispatch(deleteDraftReply({ id })) // maybe move this to useDraftReply

    toast({
      title: <Text>Reply posted.</Text>,
      status: 'success',
      duration: 5000,
      isClosable: false,
      position: toastPosition,
    })
  }

  const handleDeleteCommentClick = () => {
    const isReply = Boolean(comment.commentId)
    removeComment({
      variables: {
        id,
      },
      update: (cache, { data }) => {
        cache.writeFragment({
          id: `Doc:${docId}`,
          fragment: DOC_COMMENTS_FRAGMENTS,
          fragmentName: isReply ? 'DocRepliesRemove' : 'DocCommentsRemove',
          data: {
            comments: [
              isReply
                ? {
                    id: comment.commentId,
                    replies: [data?.archiveComment],
                    __typename: 'Comment',
                  }
                : data?.archiveComment,
            ],
          },
        })
      },
      optimisticResponse: {
        archiveComment: {
          id,
          __typename: 'Comment',
          archived: true,
        },
      },
    })

    if (!isReply && comment.targetId) {
      editor.commands.deleteAnnotation(comment.targetId)
    }
    if (instanceType === 'popup' && closePopup) {
      closePopup(true)
    }

    toast({
      title: <Text>Comment deleted.</Text>,
      status: 'success',
      duration: 5000,
      isClosable: false,
      position: toastPosition,
    })
  }

  const handleUpdateCommentClick = (commentJSON: JSONContent) => {
    const isReply = Boolean(comment.commentId)
    const input = {
      id,
      docId,
      content: commentJSON,
    }
    updateComment({
      variables: { input },
      update: (cache, { data }) => {
        const writeFragmentData = {
          id: `Doc:${docId}`,
          fragment: DOC_COMMENTS_FRAGMENTS,
          fragmentName: isReply ? 'DocRepliesUpdate' : 'DocCommentsUpdate',
          data: {
            comments: [
              isReply
                ? {
                    id: comment.commentId,
                    replies: [data?.updateComment],
                    __typename: 'Comment',
                  }
                : data?.updateComment,
            ],
          },
        }
        cache.writeFragment(writeFragmentData)
      },
      optimisticResponse: {
        updateComment: {
          ...input,
          __typename: 'Comment',
          updatedTime: new Date().toISOString(),
        },
      },
    })

    toast({
      title: <Text>Comment updated.</Text>,
      status: 'success',
      duration: 5000,
      isClosable: false,
      position: toastPosition,
    })
    setIsEditing(false)
  }

  const handleCommentStatusUpdate = (oldStatus: CommentStatus) => {
    const newStatus =
      oldStatus === CommentStatus.Open
        ? CommentStatus.Closed
        : CommentStatus.Open

    const input = {
      id,
      docId,
      status: newStatus,
    }
    updateCommentStatus({
      variables: { input },
      update: (cache, { data }) => {
        const writeFragmentData = {
          id: `Doc:${docId}`,
          fragment: DOC_COMMENTS_FRAGMENTS,
          fragmentName: 'DocCommentsUpdate',
          data: {
            comments: [data?.updateCommentStatus],
          },
        }
        cache.writeFragment(writeFragmentData)
      },
      optimisticResponse: {
        updateCommentStatus: {
          ...input,
          __typename: 'Comment',
          updatedTime: new Date().toISOString(),
        },
      },
    })
      .then(() => {
        toast({
          title: (
            <Text>
              {COMMENT_STATUS_CONFIG[oldStatus].successMessage}.{' '}
              {
                <Link
                  textDecoration="underline"
                  onClick={() => handleCommentStatusUpdate(newStatus)}
                >
                  Undo
                </Link>
              }
            </Text>
          ),
          status: 'success',
          duration: 5000,
          isClosable: false,
          position: toastPosition,
        })
      })
      .catch((e) => {
        console.error('[CommentComponent]: Error changing comment status', e)
        toast({
          title: `There was an error changing the comment status: ${e.message}`,
          status: 'error',
          isClosable: true,
          position: toastPosition,
        })
      })
      .finally(() => {
        if (instanceType === 'popup' && closePopup) {
          closePopup(true)
        }
      })
  }

  const highlightedCommentBackgroundColor = useColorModeValue(
    'yellow.100',
    'yellow.800'
  )

  const annotationState: AnnotationState | undefined =
    AnnotationPluginKey.getState(editor.state)

  const match = useMemo(() => {
    return annotationState?.annotations.find((a) => a.id === targetId)
  }, [annotationState?.annotations, targetId])
  const { pos } = match || {}

  const isSourceContentAvailable = Boolean(match?.pos)
  const showCopyCommentLink =
    instanceType !== 'notification' && comment.id !== NEW_COMMENT_TEMP_ID
  const showLinkToTargetContent =
    (instanceType === 'notification' || instanceType === 'feed') &&
    // Only render go to button for block-level comments; exclude memo-level
    pos !== MEMO_LEVEL_COMMENT_ABS_POS

  const onTargetClick = useCallback(
    (e) => {
      if (
        !targetId ||
        !match ||
        !pos ||
        (e?.target as HTMLElement)?.closest(
          '[data-target-name="comment-target-expand-button"]'
        )
      )
        return
      if (instanceType === 'notification') {
        // Open the popup instance if this is a notification
        TiptapEventBus.emit(EventBusEvent.OPEN_POPUP_COMMENT, {
          commentId: id,
          highlightComment: true,
        })
      } else if (instanceType === 'feed') {
        // Scroll to the target content if were in the feed
        let scrollDelay = EXPAND_CARD_TRANSITION_TIME
        const el = getDomNodeFromPos(editor, pos)
        if (editorMode === EditorModeEnum.DOC_VIEW) {
          openParentCards({ pos, editor })
        } else if (editorMode === EditorModeEnum.SLIDE_VIEW) {
          const [parentNode] = findParentNodes(
            editor.state.doc.resolve(pos),
            isCardNode
          )
          editor.commands.spotlightCardById(parentNode.node.attrs.id)
          dispatch(setFollowingAttached({ attached: false }))
          scrollDelay = BETWEEN_CARD_TRANSITION_TIME
        }
        if (isMobileDevice() && onClose) {
          setTimeout(onClose, 300) // The timeout makes it feel less jarring
        }
        scrollManager.scrollElementIntoView({ element: el, delay: scrollDelay })
      }
    },
    [
      editor,
      scrollManager,
      targetId,
      instanceType,
      id,
      match,
      pos,
      editorMode,
      dispatch,
      onClose,
    ]
  )

  const hasCommentBeenUpdated = useMemo(() => {
    // Sometimes, createdTime and updatedTime for a new, unedited comment are off by 1 ms.
    // If the two times differ by more than 100ms, it's safe to assume an update happened.
    return (
      new Date(updatedTime).getTime() - new Date(createdTime).getTime() > 100
    )
  }, [updatedTime, createdTime])

  const isReply = !!comment.commentId
  const hasReplies = !!replies && replies.length > 0

  return (
    <Flex
      flexDir={'column'}
      ref={commentRef}
      id={`comment-${id}`}
      className="comment highlight-mask"
      data-target-id={`comment-${id}`}
      borderRadius={isHighlighting ? 'md' : '0px'}
      position="relative"
      borderBottomWidth={isRootComment ? '1px' : '0px'}
      borderBottomColor="gray.200"
      _after={
        isInPanel && hasReplies
          ? {
              content: `''`,
              position: 'absolute',
              w: '9px',
              h: '9px',
              left: '36px',
              bottom: 9,
              bg: 'gray.100',
              borderRadius: 'full',
              transitionPropery: 'common',
              transitionDuration: 'normal',
            }
          : {}
      }
      _before={
        hasReplies && isInPanel
          ? {
              content: `''`,
              position: 'absolute',
              w: '3px',
              top: 7,
              bottom: 9,
              left: '39px',
              bg: 'gray.100',
              borderRadius: 'full',
            }
          : {}
      }
      {...flexProps}
    >
      <Stack
        backgroundColor={
          isHighlighting ? highlightedCommentBackgroundColor : 'auto'
        }
        transitionProperty="border, background"
        transitionDuration="normal"
        transitionTimingFunction="ease"
        role="group"
        spacing={8}
      >
        <Box pl={isReply && isInPanel ? REPLY_PADDING : 0}>
          <CommentHeader
            isInPanel={isInPanel}
            comment={comment}
            selfUser={selfUser}
            isSourceContentAvailable={isSourceContentAvailable}
            showCopyCommentLink={showCopyCommentLink}
            showLinkToTargetContent={showLinkToTargetContent}
            hasCommentBeenUpdated={hasCommentBeenUpdated}
            onTargetClick={onTargetClick}
            commentUrl={commentUrl}
            isReply={isReply}
            userCanComment={userCanComment}
            handleCommentStatusUpdate={handleCommentStatusUpdate}
            toastPosition={toastPosition}
            isEditing={isEditing}
            handleEditCommentClick={handleEditCommentClick}
            handleDeleteCommentClick={handleDeleteCommentClick}
          />

          <Box pl={hasReplies && isInPanel ? REPLY_PADDING : 0}>
            {targetHtml && targetHtml.length && (
              <CommentTargetContent
                targetHtml={targetHtml}
                onTargetClick={onTargetClick}
                isSourceContentAvailable={isSourceContentAvailable}
                instanceType={instanceType}
              />
            )}

            {/* <Stack> */}
            <CommentEditor
              initialContent={content}
              editable={isEditing}
              onCancelEditingClick={() => setIsEditing(false)}
              saveButtonText="Save comment"
              saveButtonIcon={<FontAwesomeIcon icon={regular('check')} />}
              onCommentSave={handleUpdateCommentClick}
            />
            {!isEditing && (
              <CommentReactions
                mt={2}
                docId={docId}
                cardId={comment.cardId}
                commentId={comment.id}
                parentCommentId={comment.commentId}
                reactions={reactions}
                size="sm"
                placement={'right'}
                usePortal={true}
                userCanComment={userCanComment}
              />
            )}
          </Box>
        </Box>
        {replies &&
          replies.length > 0 &&
          replies.map((reply: Comment) => {
            return (
              <CommentBox
                instanceType={instanceType}
                userCanComment={userCanComment}
                commentIdToHighlight={commentIdToHighlight}
                key={reply?.id}
                comment={reply}
                docId={docId}
                selfUser={selfUser}
                editor={editor}
                editorMode={editorMode}
                pl={0}
                p={0}
                pb={0}
              />
            )
          })}
      </Stack>
      {userCanComment && (
        <Collapse
          in={isReplying && !isEditing}
          unmountOnExit
          transition={lazyTransitionConfig}
        >
          {isInDrawer && (
            <HStack align="center" mb={3} pt={6}>
              <Avatar
                mt={1}
                size="sm"
                name={selfUser?.displayName}
                src={selfUser?.profileImageUrl}
                ignoreFallback={true}
              />
              <Stack align="center" alignItems="flex-start" spacing={0} mb={1}>
                <Text fontWeight="600" mb={0} fontSize="sm">
                  {selfUser?.displayName} (You)
                </Text>
              </Stack>
            </HStack>
          )}
          <CommentEditor
            pl={isInPanel && hasReplies ? REPLY_PADDING - 2 : 0}
            pt={isInDrawer ? 0 : 6}
            initialContent={initialDraftReply?.json || ''}
            clearContentOnSave={true}
            editable={true}
            saveButtonText="Reply"
            saveButtonIcon={<FontAwesomeIcon icon={regular('reply')} />}
            placeholder="Reply"
            onCommentSave={onCommentCommentSave}
            onCancelEditingClick={handleCancelReplyClick}
            onUpdate={({ editor: commentEditor }) => {
              updateLocalDraftReply({
                commentId: id,
                json: commentEditor.view.state.doc.toJSON(),
                text: commentEditor.view.state.doc.textContent,
              })
            }}
            onCreate={(commentEditor) => {
              createCommentEditorDef.current.resolve(commentEditor)
            }}
            alwaysShowButtons={isInDrawer}
            shouldFocus={instanceType === 'popup' || instanceType === 'drawer'}
          />
        </Collapse>
      )}
    </Flex>
  )
}
export const CommentBox = memo(CommentComponent)
