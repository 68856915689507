export enum AppMonitoringEvents {
  DATA_SYNC_ERROR = 'dataSyncError',
  DUPLICATE_CARD_IDS = 'duplicateCardIds',
}

// NB: If you add events here, also add them to this deck:
// https://gamma.app/docs/Event-Reference-ni0cllkqyypil0r

export enum SegmentEvents {
  AI_REQUEST_SENT = 'ai.request.sent',
  AI_REQUEST_COMPLETE = 'ai.request.complete',

  CARD_VIEWED = 'card.view',
  CARD_CREATED = 'card.create',
  CARD_STYLES_UPDATED = 'card.styles.update',
  CARD_STYLES_RESET = 'card.styles.reset',
  DOC_ATTRIBUTES_UPDATED = 'doc.attrs.update',
  CARD_COLLAPSED = 'card.collapse',
  CARD_EXPANDED = 'card.expand',

  DOC_VIEWED = 'doc.view',
  DOC_CREATED = 'doc.create',
  DOC_PRINTED = 'doc.print',
  DOC_ZOOMED = 'doc.zoom',
  DOC_THEME_UPDATED = 'doc.theme.update',

  EXAMPLE_VIEWED = 'example.view',
  EXAMPLE_DUPLICATED = 'example.duplicate',
  MADE_WITH_GAMMA_CLICKED = 'madeWithGamma.click',
  EXAMPLE_SIGNUP_CLICKED = 'example.signup.click',

  HOME_TAB_VIEWED = 'homeTab.view',

  NODE_INSERTED = 'node.insert',

  // PRESENT MODE
  PRESENT_MODE_NAVIGATED = 'present.navigate',
  PRESENT_MODE_ENTERED = 'present.enter',
  PRESENT_MODE_EXITED = 'present.exit',

  // AUTH
  LOGIN = 'auth.login',
  SIGNUP = 'auth.signup',

  // ONBOARDING
  ONBOARDING_TOUR_SKIPPED = 'onboardingTour.skip',
  ONBOARDING_TOUR_STEP = 'onboardingTour.step',
  WORKSPACE_CREATED = 'workspace.create',
  GET_STARTED_CLICKED = 'getStarted.click',

  // THEMES
  THEME_CREATED = 'theme.create',
  THEME_DUPLICATED = 'theme.duplicate',
  THEME_UPDATED = 'theme.update',

  // IN APP TEMPLATES
  IN_APP_TEMPLATE_THEME_CLICKED = 'inAppTemplate.theme.click',
  IN_APP_TEMPLATE_CREATE_THEME_CLICKED = 'inAppTemplate.createTheme.click',

  // PDFS
  PDF_EXPORTED = 'pdf.export',

  // MARKETING_PAGES
  TEMPLATE_SEARCH = 'template.search',

  // GUIDERS
  DOC_EDITOR_TOUR_STARTED = 'docEditorTour.start',
  // The step at which they tried to bail
  DOC_EDITOR_TOUR_CLOSE_CLICKED = 'docEditorTour.close.click',
  // How far did they get in the tour?
  DOC_EDITOR_TOUR_COMPLETED = 'docEditorTour.completed',

  CREATE_FIRST_DOC_EXP_BUCKETED = 'createFirstDocExp.bucket',
}

export enum NodeInsertMethods {
  SLASH_MENU = 'slash_menu',
  BLOCK_PROMPT = 'block_prompt',
  INSERT_WIDGET = 'insert_widget',
  INPUT_RULE = 'input_rule',
  KEYBOARD_SHORTCUT = 'keyboard_shortcut',
  CARD_DIVIDER = 'card_divider',
  CARD_DIVIDER_DROPDOWN = 'card_divider_dropdown',
  FORMATTING_MENU = 'formatting_menu',
  SUMMARIZE = 'summarize',
}

export enum PresentNavigateMethods {
  KEYBOARD = 'keyboard',
  BUTTON_CLICK = 'button_click',
}

export enum PresentNavigateDirections {
  TELEPROMPTER_UP = 'teleprompter_up',
  TELEPROMPTER_DOWN = 'teleprompter_down',
  CARD_NEXT = 'card_next',
  CARD_PREV = 'card_prev',
}

export type DocCreatedSource =
  | 'example_deck_clone_toast'
  | 'import'
  | 'blank'
  | 'template_browser'
  | 'get_started_modal'
  | 'template_modal_griditem'
  | 'template_dashboard_griditem'
  | 'template_modal_preview'
  | 'template_modal_preview_create_theme'
  | 'slash-new'
  | 'editor_toolbar_doc_menu_duplicate'
  | 'dashboard_docs_view_duplicate'
