import { Box, Flex, FlexProps } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

type CardLayoutAccentImageSelectorProps = {
  hasImage: boolean
  onEditImage?: () => void
} & FlexProps

export const CardLayoutAccentImageSelector: React.FC<CardLayoutAccentImageSelectorProps> =
  ({ hasImage, onEditImage, ...rest }) => {
    const bg = !hasImage ? { bgColor: 'gray.200' } : {}
    const canEdit = !!onEditImage
    if (hasImage) return null

    return (
      <Flex
        align="center"
        justify="center"
        pos="absolute"
        inset={0}
        role="group"
        cursor="default"
        {...bg}
        {...rest}
      >
        {/* <Box
          pos="absolute"
          bottom={2}
          right={2}
          opacity={0}
          _groupHover={{ opacity: 1 }}
          zIndex="2" // Go over body cell in full layout
          p={6}
          m={-6}
        >
          <GammaTooltip label="Edit background">
            <IconButton
              isRound
              aria-label="Edit background"
              size="sm"
              onClick={onEditImage}
              icon={<FontAwesomeIcon icon={regular('pencil')} />}
            ></IconButton>
          </GammaTooltip>
        </Box> */}

        <Flex
          pos="absolute"
          inset={0}
          justify="center"
          align="center"
          cursor={canEdit ? 'pointer' : undefined}
          _hover={canEdit ? { filter: 'brightness(0.7)' } : undefined}
          onClick={onEditImage}
        >
          <Box color="gray.400">
            <FontAwesomeIcon icon={regular('image')} size="3x" />
          </Box>
        </Flex>
      </Flex>
    )
  }
