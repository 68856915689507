// This is a duplicate of useSessionStorage.
// They have the same API: https://developer.mozilla.org/en-US/docs/Web/API/Storage

import { useCallback, useState } from 'react'

import { localStore } from 'utils/storage'

const isWindowDefined = typeof window !== 'undefined'

export function useLocalStorage<R>(
  key: string,
  defaultValue: R
): [R, (val: R) => void] {
  const [state, setState] = useState(() => {
    try {
      const raw = isWindowDefined ? localStore.getItem(key) : null
      if (raw === null) {
        return defaultValue
      }
      return JSON.parse(raw) as R
    } catch (e) {
      console.error(
        '[useLocalStorage.getItem] Error getting localStorage item',
        e
      )
      return defaultValue
    }
  })
  const set = useCallback(
    (val: R) => {
      try {
        localStore.setItem(key, JSON.stringify(val))
        setState(val)
      } catch (e) {
        console.error(
          '[useLocalStorage.setItem] Error setting localStorage item',
          e
        )
      }
    },
    [key]
  )

  return [state, set]
}
