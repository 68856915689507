import { cx } from '@chakra-ui/utils'
import { Editor, Extension } from '@tiptap/core'
import { NodeSelection, Plugin, TextSelection } from 'prosemirror-state'
import { Decoration, DecorationSet } from 'prosemirror-view'

import { editorHasFocus, findNodeAndParents } from 'modules/tiptap_editor/utils'

import { isCardNode } from '../../Card/utils'
export const SalSelectionPlugin = (
  editor: Editor,
  storage: Extension['storage']
) =>
  new Plugin({
    props: {
      // Add a class to the editor DOM node when Sal is active to dim the rest
      attributes: () => {
        if (storage.salPanelOpen) {
          return {
            class: 'sal-active',
          }
        } else {
          return {
            class: '',
          }
        }
      },

      decorations: ({ doc, selection }) => {
        if (!storage.salPanelOpen) return
        const { from, to } = selection
        const decorations: Decoration[] = []

        // Dim inactive cards
        const parentCards = findNodeAndParents(selection.$from, isCardNode)
        parentCards.forEach((card, i) => {
          decorations.push(
            Decoration.node(card.pos, card.pos + card.node.nodeSize, {
              class: cx(i == 0 ? 'sal-active-card' : 'sal-active-card-parent'),
            })
          )
        })

        // Everything below should only run when the editor is blurred
        if (editorHasFocus(editor)) {
          return DecorationSet.create(doc, decorations)
        }

        // If the editor is out of focus, highlight the current selection
        if (selection instanceof TextSelection) {
          decorations.push(
            Decoration.inline(from, to, {
              class: cx('sal-selection-text'),
            })
          )
        } else if (selection instanceof NodeSelection) {
          decorations.push(
            Decoration.node(from, to, {
              class: cx('sal-selection-node'),
            })
          )
        }

        return DecorationSet.create(doc, decorations)
      },
    },
  })
