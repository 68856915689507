import { Box, BoxProps } from '@chakra-ui/react'
import { cx } from '@chakra-ui/utils'
import { ReactNode } from 'react'

import { isMobileDevice } from 'utils/deviceDetection'

import { CARD_CONTENT_CLASS, EXPAND_CARD_TRANSITION_TIME } from '..'
import {
  findLayoutPreset,
  getCardLayoutChildrenSx,
} from '../CardLayout/cardLayoutUtils'
import { CardAttributes, CardLayout } from '../types'
import { CARD2_LAYOUT_CLASS } from './constants'

type CardLayoutComponentProps = {
  layout: CardLayout
  attrs: CardAttributes
  isExpandableSelected: boolean
  isEditable: boolean
  isFocused: boolean
  isPresentMode: boolean
  isFullBleed: boolean
  isFirstCard: boolean
  hasCardLayoutItems: boolean
  children: ReactNode
  onDoubleClick: BoxProps['onDoubleClick']
}

export const Card2Layout = ({
  layout,
  children,
  isExpandableSelected,
  isFocused,
  isFullBleed,
  isFirstCard,
  isPresentMode,
  isEditable,
  attrs,
  hasCardLayoutItems,
  onDoubleClick,
}: CardLayoutComponentProps) => {
  // always show a blank layout in nested cards
  const layoutPreset = findLayoutPreset(layout)
  const layoutWidth = isPresentMode
    ? 'var(--editor-width)'
    : `var(--card-width)`
  const childItemsSx = getCardLayoutChildrenSx(layout)
  const layoutTemplateColumns = attrs.layoutTemplateColumns

  return (
    <Box
      className={cx(
        CARD2_LAYOUT_CLASS,
        isExpandableSelected && 'expandable-selected'
      )}
      onDoubleClick={onDoubleClick}
      w={layoutWidth}
      position="relative"
      spellCheck={isFocused}
      cursor="auto"
      color="var(--body-color)"
      {...(isPresentMode && {
        minH: '100%',
      })}
      sx={{
        // the direct descendent specificity is necessary so that grids and nested card sub grids
        // dont interact with each other
        [`> .${CARD_CONTENT_CLASS}`]: {
          '> [data-node-view-content-inner="card"]': {
            // if you are using CardView2 but the content was created without a card layout item
            ...(hasCardLayoutItems
              ? {
                  // base grid props for all layout presets
                  display: 'grid',
                  alignItems: 'stretch',
                  justifyContent: 'stretch',
                  minH: '100%',
                  transitionDuration: `${EXPAND_CARD_TRANSITION_TIME}ms`,
                  transitionProperty: 'grid-template-columns, min-height',
                  ...layoutPreset.grid,
                  ...(layoutTemplateColumns &&
                    layoutPreset.allowResizing && {
                      gridTemplateColumns: layoutTemplateColumns,
                    }),
                  ...childItemsSx,
                }
              : {
                  display: 'block',
                  padding: 'var(--card-inner-padding)',
                  // try to fill up the width, max-content-width is card width when not in
                  // present mode
                  mx: 'auto',
                  maxW: 'var(--max-content-width)',
                  width: '100%',
                  boxSizing: isMobileDevice() ? 'border-box' : 'content-box',
                }),
            '> .node-cardAccentLayoutItem': {
              ...(isPresentMode &&
                layoutPreset.stickyAccent && {
                  '> [data-node-view-wrapper]': {
                    '> .card-layout-cell-bg': {
                      position: 'sticky',
                      height: '100vh',
                      top: 0,
                    },
                  },
                }),
            },

            '> .node-cardLayoutItem, > .node-cardAccentLayoutItem': {
              '> [data-node-view-wrapper]': {
                '> .card-layout-cell-bg': {
                  '> [data-node-view-content]': {
                    '> [data-node-view-content-inner]': {
                      maxWidth: 'var(--max-content-width)',
                      margin: 'auto', // Center horizontally
                      marginTop:
                        isFullBleed && isFirstCard && isEditable
                          ? '2rem'
                          : 'auto',
                    },
                    padding: 'var(--card-inner-padding)',
                  },
                },
              },
            },
          },
        },
      }}
    >
      {children}
    </Box>
  )
}
