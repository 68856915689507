import ImageNode from '@tiptap/extension-image'
import { NodeSelection } from 'prosemirror-state'

import { getImageTextDescription } from 'modules/media/utils/image'
import { getStore } from 'modules/redux'
import { ReactNodeViewRenderer } from 'modules/tiptap_editor/react'

import { configureJSONAttribute } from '../../../utils'
import { getNodeAttrsFromBookmark } from '../../AI/Sal/utils/bookmark'
import { ExtensionPriorityMap } from '../../constants'
import { attrsOrDecorationsChanged } from '../../updateFns'
import { eventEmitter } from './eventEmitter'
import { ImageViewContainer } from './ImageViewContainer'
import { selectIsCropping } from './reducer'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    imageCommands: {
      /**
       * Sets the image clipping back to default
       */
      resetImageClip: () => ReturnType
      /**
       * Sets the image scale back to default
       */
      resetImageScale: () => ReturnType
    }
  }
}

export const DEFAULT_RESIZE_STATE = {
  clipType: null,
  clipPath: null,
  clipAspectRatio: null,
  width: null,
} as const

export const Image = ImageNode.extend({
  selectable: true,
  draggable: true,
  group: 'block media',
  priority: ExtensionPriorityMap.Image,

  expandable: true,

  addOptions() {
    return {
      ...this.parent?.(), // Extend parent options
      placeholders: {},
    }
  },

  addStorage() {
    return {
      bookmarks: {},
    }
  },

  addNodeView() {
    return ReactNodeViewRenderer(ImageViewContainer, {
      update: attrsOrDecorationsChanged,
    })
  },

  parseHTML() {
    return [
      // From clipboard
      {
        tag: 'img[src], img[tempUrl]',
      },
      // From AI
      {
        tag: 'img[bookmark]',
        getAttrs: (el: HTMLElement) =>
          getNodeAttrsFromBookmark(el, this.storage),
      },
    ]
  },

  renderHTMLforAI({ node }) {
    this.storage.bookmarks[node.attrs.id] = node
    const alt = getImageTextDescription(node.attrs)
    return ['img', { bookmark: node.attrs.id, alt }]
  },

  addAttributes() {
    return {
      src: {},
      tempUrl: {},
      uploadStatus: {},
      meta: {
        ...configureJSONAttribute('meta'),
      },
      providerMeta: {
        ...configureJSONAttribute('providerMeta'),
      },
      name: {},
      query: {},
      source: {},
      showPlaceholder: {},
      fullWidthBlock: {
        default: false,
      },
      resize: {
        ...configureJSONAttribute('resize'),
        default: DEFAULT_RESIZE_STATE,
      },
    }
  },

  addKeyboardShortcuts() {
    return {
      Enter: ({ editor }) => {
        const selection = editor.state.selection
        if (
          selection instanceof NodeSelection &&
          selection.node.type.name === ImageNode.name
        ) {
          const isCropping = selectIsCropping(getStore().getState())
          const { id } = selection.node.attrs
          if (isCropping) {
            eventEmitter.emit('endClip', { id, confirm: true })
            return true
          }
        }
        return false
      },
    }
  },

  addCommands() {
    const nodeName = this.name
    const getSelectedImage = (state) => {
      const { selection } = state
      return selection instanceof NodeSelection &&
        selection.node.type.name === nodeName
        ? selection.node
        : undefined
    }
    return {
      resetImageClip:
        () =>
        ({ chain, state }) => {
          const selectedNode = getSelectedImage(state)
          if (!selectedNode) {
            console.warn(
              '[resetImageClip] Selected node is not an image: ',
              state.selection
            )
            return true
          }

          const { clipPath, clipType, clipAspectRatio } = DEFAULT_RESIZE_STATE
          return chain()
            .updateAttributes(nodeName, {
              resize: {
                ...selectedNode.attrs.resize,
                clipPath,
                clipType,
                clipAspectRatio,
              },
            })
            .run()
        },
      resetImageScale:
        () =>
        ({ chain, state }) => {
          const selectedNode = getSelectedImage(state)
          if (!selectedNode) {
            console.warn(
              '[resetImageClip] Selected node is not an image: ',
              state.selection
            )
            return true
          }

          return chain()
            .updateAttributes(nodeName, {
              resize: {
                ...selectedNode.attrs.resize,
                width: null,
              },
            })
            .run()
        },
    }
  },
})
