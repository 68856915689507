import {
  FormControlProps,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
  VStack,
} from '@chakra-ui/react'

import { colorWithLightness, makeColorReadable } from 'utils/color'

import { ColorPalettePicker, MaybeHex } from './ColorPalettePicker'

const DEFAULT_COLOR = '#888888'
const DEFAULT_ANGLE = 135 // Will show up well in vertical lines (blockquote) and horizontal (button)

export type LinearGradient = {
  colors: MaybeHex[]
  angle?: number // degrees
  disabled?: boolean
}

type LinearGradientPickerProps = {
  value: LinearGradient | null
  updateValue: (value: LinearGradient) => void
} & FormControlProps

export const LinearGradientPicker = ({
  value,
  updateValue,
}: LinearGradientPickerProps) => {
  const updateColors = (colors: MaybeHex[]) => {
    updateValue({ ...value, colors })
  }

  const updateAngle = (angle: number) => {
    updateValue({ colors, angle })
  }

  const colors = value?.colors || [null, null]
  const displayAngle = value?.angle ?? DEFAULT_ANGLE

  return (
    <VStack spacing={2} align="flex-start">
      <ColorPalettePicker
        value={colors}
        updateValue={updateColors}
        maxColors={3}
        minColors={2}
        defaultValue={'#888888'}
      />

      <Slider
        aria-label="angle-slider"
        min={0}
        max={360}
        step={1}
        value={displayAngle}
        onChange={updateAngle}
        w="100%"
        colorScheme="trueblue"
        size="md"
        mt={'2.5rem !important'}
      >
        <SliderMark
          value={displayAngle}
          textAlign="center"
          mt={-8}
          ml={-3}
          fontSize="sm"
        >
          {displayAngle}°
        </SliderMark>
        <SliderTrack>
          <SliderFilledTrack />
        </SliderTrack>
        <SliderThumb />
      </Slider>
    </VStack>
  )
}

export const generateLinearGradientCSS = (gradient?: LinearGradient) => {
  if (!gradient || gradient.colors.length <= 1 || gradient.disabled)
    return undefined
  const colorStops = gradient.colors
    .map(
      (c, i) =>
        `${c || DEFAULT_COLOR} ${(10 + i * 80) / (gradient.colors.length - 1)}%`
    )
    .join(', ')
  return `linear-gradient(${gradient.angle || DEFAULT_ANGLE}deg, ${colorStops})`
}

export const readableGradient = (
  gradient: LinearGradient | undefined,
  contrastValue: string,
  contrastRatio?: number
): LinearGradient | undefined => {
  if (!gradient) return undefined
  const readableColors = gradient.colors.map((c) =>
    makeColorReadable(c || DEFAULT_COLOR, contrastValue, contrastRatio)
  )
  return {
    ...gradient,
    colors: readableColors,
  }
}

export const lightenedGradient = (
  gradient: LinearGradient | undefined,
  lightness: number
): LinearGradient | undefined => {
  if (!gradient) return undefined
  const lightenedColors = gradient.colors.map((c) =>
    colorWithLightness(c || DEFAULT_COLOR, lightness)
  )
  return {
    ...gradient,
    colors: lightenedColors,
  }
}
