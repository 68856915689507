import {
  Box,
  BoxProps,
  Button,
  Collapse,
  Flex,
  FlexProps,
  HStack,
  Spacer,
  useColorMode,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'
import { Content, EditorOptions } from '@tiptap/core'
import { isHotkey } from 'is-hotkey'
import React, { ReactElement, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { useEffectWhen } from 'utils/hooks'

import { EditorContent, useEditor } from '../react'
import { selectDocId, selectDocOrgId } from '../reducer'
import { hasImageNodeWithNoSrc, isTreeEmpty } from '../utils'
import { getCommentExtensions } from './extensions'

const isSubmitCommentHotkey = isHotkey('mod+Enter')
const isSelectAllHotkey = isHotkey('mod+a')
type CommentEditorProps = {
  clearContentOnSave?: boolean
  editable?: boolean
  initialContent?: Content
  placeholder?: string
  saveButtonIcon?: ReactElement
  saveButtonText?: string
  onCancelEditingClick?: () => void
  onCommentSave?: (any) => void
  onCreate?: EditorOptions['onCreate']
  onUpdate?: EditorOptions['onUpdate']
  shouldFocus?: boolean
  showBorder?: boolean
  alwaysShowButtons?: boolean
} & FlexProps

export const CommentEditor = React.memo(
  ({
    clearContentOnSave = false,
    editable = true,
    saveButtonText = 'Save',
    saveButtonIcon = <FontAwesomeIcon icon={regular('paper-plane')} />,
    initialContent = '',
    placeholder = 'Reply',
    showBorder = true,
    onCancelEditingClick,
    onCommentSave,
    onCreate = () => {},
    onUpdate = () => {},
    shouldFocus = true,
    alwaysShowButtons = false,
    ...flexProps
  }: CommentEditorProps) => {
    const extensions = useMemo(() => getCommentExtensions(), [])
    const docId = useSelector(selectDocId)
    const orgId = useSelector(selectDocOrgId)
    const commentEditor = useEditor({
      extensions,
      content: initialContent,
      onCreate: ({ editor }) => {
        onCreate({ editor })
        editor.gammaDocId = docId
        editor.gammaOrgId = orgId
      },
      onUpdate: ({ editor, transaction }) => {
        onUpdate({ editor, transaction })
      },
    })

    useEffect(() => {
      if (!commentEditor) return
      // Ensure the editor's editable state is correct
      commentEditor.setOptions({ editable })
    }, [commentEditor, editable])

    useEffectWhen(
      () => {
        if (!commentEditor || !shouldFocus || !commentEditor.isEditable) return
        // Focus on the editor input
        commentEditor.commands.focus('end')
      },
      [commentEditor, shouldFocus],
      // We don't want to focus after `shouldFocus` changes
      [commentEditor]
    )

    useEffect(() => {
      if (!commentEditor || editable) return
      // Ensure that, if an existing comment's content gets edited
      // by someone else, it gets re-rendered
      commentEditor?.commands.setContent(initialContent)
    }, [commentEditor, initialContent, editable])

    const { colorMode } = useColorMode()
    const isLight = colorMode === 'light'

    const handleKeydown = (e) => {
      if (isSelectAllHotkey(e)) {
        // Stop it from selecting the entire doc
        e.preventDefault()
        commentEditor?.commands.selectAll()
      }
      if (isSubmitCommentHotkey(e)) {
        // Stop it from going into present mode
        e.preventDefault()
        e.stopPropagation()
        handleCommentSave()
      }
    }

    const handleCommentCancel = () => {
      if (onCancelEditingClick) onCancelEditingClick()
      commentEditor?.commands.clearContent(true)
    }

    const handleCommentSave = () => {
      if (
        !onCommentSave ||
        !commentEditor ||
        // no-op if there's nothing in the editor
        isTreeEmpty(commentEditor.state.doc) ||
        // no-op if there's an image node with no src - that means an image is still being uploaded
        hasImageNodeWithNoSrc(commentEditor.state.doc)
      )
        return
      const commentJSON = commentEditor.getJSON()
      onCommentSave(commentJSON)
      if (clearContentOnSave) {
        commentEditor.commands.clearContent(true)
      }
    }

    if (!editable) {
      return (
        <Box fontSize="sm">
          <EditorContent editor={commentEditor} />
        </Box>
      )
    }
    const borderProps: Partial<BoxProps> = showBorder
      ? {
          border: '1px solid',
          borderColor: isLight ? 'gray.200' : 'gray.700',
          borderRadius: 'md',
        }
      : {}

    return (
      <Flex
        data-gamma-child-tiptap-editor="comment-editor"
        direction="column"
        {...flexProps}
      >
        <Box
          tabIndex={0}
          w="100%"
          onKeyDownCapture={handleKeydown}
          onKeyPressCapture={handleKeydown}
          className="comment-editor"
          color={isLight ? 'gray.800' : 'gray.200'}
          bg={isLight ? 'white' : 'gray.800'}
          {...borderProps}
          sx={{
            // Adapted from https://www.tiptap.dev/api/extensions/placeholder/#placeholder
            'p:first-of-type::before': commentEditor?.isEmpty
              ? {
                  content: `"${placeholder}"`,
                  float: 'left',
                  color: isLight ? 'gray.400' : 'gray.500',
                  pointerEvents: 'none',
                  height: '0',
                }
              : '',
          }}
          fontSize="sm"
        >
          <EditorContent
            editor={commentEditor}
            className="comment-editor"
            style={{
              wordBreak: 'break-word',
              width: '100%',
            }}
          />
        </Box>
        {/* TODO: collapse these in! */}
        <Collapse
          in={Boolean(
            alwaysShowButtons || (commentEditor && !commentEditor.isEmpty)
          )}
        >
          <Box h={3}>
            <></>
          </Box>
          <HStack>
            <Spacer />
            {Boolean(onCancelEditingClick) && (
              <GammaTooltip label={'Cancel writing comment'} placement="top">
                <Button
                  size="sm"
                  variant="ghost"
                  onClick={handleCommentCancel}
                  colorScheme="red"
                >
                  Cancel
                </Button>
              </GammaTooltip>
            )}

            <Button
              size="sm"
              variant="solid"
              leftIcon={saveButtonIcon}
              // hide the save button if the editor is empty, or if an image is still being uploaded
              isDisabled={
                commentEditor?.isEmpty ||
                hasImageNodeWithNoSrc(commentEditor?.state.doc)
              }
              onClick={handleCommentSave}
              data-testid="post-comment"
            >
              {saveButtonText}
            </Button>
          </HStack>
        </Collapse>
      </Flex>
    )
  }
)
