import { Button, Flex, HStack, IconButton, StackProps } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'
import { findParentNode, NodeViewProps } from '@tiptap/core'
import { memo, MutableRefObject, useCallback, useMemo } from 'react'

import { AnalyticsPanelModal } from 'modules/analytics'
import { useFeatureFlag } from 'modules/featureFlags'
import { useModalDisclosure } from 'modules/modal_state/hooks/useModalDisclosure'
import { useAppSelector } from 'modules/redux'
import { SegmentEvents, useAnalytics } from 'modules/segment'
import { SharePanelModal } from 'modules/sharing'
import { selectDoc } from 'modules/tiptap_editor/reducer'
import { useTooltipPortalRef } from 'modules/tooltips/hooks'
import { useCan } from 'modules/user'
import { isMobileDevice } from 'utils/deviceDetection'
import { preventDefaultToAvoidBlur, stopPropagation } from 'utils/handlers'
import { getCardUrl } from 'utils/url'

import { setCardCollapsed } from './CardCollapse'
import { useManageCardMenu } from './ManageCardMenu/hooks'
import { ManageCardMenuOptions } from './ManageCardMenu/ManageCardMenu'
import { CardAttributes } from './types'
import { isCardNode } from './utils'

type ManageCardControlsProps = {
  cardId: string
  docId?: string
  openStyleDrawer?: (index: number) => void
  isFirstCard: boolean
  isNested: boolean
  isCollapsed: boolean
  isPresentMode: boolean
  isEditable: boolean
  isStatic: boolean
  isDark: boolean
  hasCardBackground: boolean
  previewContent: CardAttributes['previewContent']
  top?: StackProps['top']
  hideManageCardMenuButton?: boolean
} & NodeViewProps

const iconFarCompressAlt = <FontAwesomeIcon icon={regular('compress-alt')} />
const iconFarEllipsisH = <FontAwesomeIcon icon={regular('ellipsis-h')} />

/**
 * The controls for theming, editing, deleting, etc.
 */
export const ManageCardControls = memo(function ManageCardControls({
  cardId,
  docId,
  openStyleDrawer,
  getPos,
  editor,
  isFirstCard,
  isNested,
  isCollapsed,
  isPresentMode,
  isEditable,
  isStatic,
  isDark,
  hasCardBackground,
  previewContent,
  updateAttributes,
  hideManageCardMenuButton,
  top,
}: ManageCardControlsProps) {
  const analytics = useAnalytics()
  const doc = useAppSelector(selectDoc)
  const canManage = useCan('manage', doc)
  const isAnalyticsEnabled = useFeatureFlag('analytics')
  const canViewFullAnalytics = canManage && isAnalyticsEnabled

  const {
    isOpen: isSharePanelOpen,
    onOpen: onSharePanelOpen,
    onClose: onSharePanelClose,
  } = useModalDisclosure({ id: 'sharePanelDisclosure' })

  const {
    isOpen: isAnalyticsPanelOpen,
    onOpen: onAnalyticsPanelOpen,
    onClose: onAnalyticsPanelClose,
  } = useModalDisclosure({ id: 'analyticsPanelDisclosure' })

  // Shown for nested cards in doc and present mode
  const collapseCard = useCallback(() => {
    if (isPresentMode && !isEditable) {
      editor.commands.spotlightCollapseCard(getPos())
    } else {
      setCardCollapsed(cardId, true)
      // select the card if the selection is within the card
      const { selection } = editor.state
      const parentCard = findParentNode(isCardNode)(selection)
      if (parentCard && parentCard.node.attrs.id === cardId) {
        editor.commands.selectNodeAtPos(getPos())
      }
    }
    analytics?.track(SegmentEvents.CARD_COLLAPSED, {
      method: 'collapse_button',
      is_present_mode: false,
    })
  }, [editor, getPos, analytics, cardId, isEditable, isPresentMode])

  const containerRef = useTooltipPortalRef()

  const topCollapseCardButton = useMemo(() => {
    return (
      <GammaTooltip
        label="Collapse card"
        placement="top"
        portalProps={{ containerRef }}
      >
        <IconButton
          variant="ghost"
          size="sm"
          // Make it easier to tap the card collapse button on mobile
          pl={isMobileDevice() ? 6 : 0}
          pr={isMobileDevice() ? 2 : 0}
          onClick={collapseCard}
          onMouseDown={preventDefaultToAvoidBlur}
          colorScheme="gray"
          isRound={true}
          aria-label="Collapse"
          data-collapse-card-button
          icon={iconFarCompressAlt}
        />
      </GammaTooltip>
    )
  }, [collapseCard, containerRef])

  const bottomCollapseCardButton = useMemo(() => {
    return (
      <Button
        size="sm"
        variant="ghost"
        position="absolute"
        zIndex={2}
        bottom={[1, 3]}
        right={[2, 4]}
        rightIcon={<FontAwesomeIcon icon={regular('chevron-up')} />}
        colorScheme="gray"
        data-collapse-card-button
        onClick={collapseCard}
        fontWeight="normal"
        color={isDark ? 'gray.400' : 'gray.600'}
      >
        Collapse
      </Button>
    )
  }, [collapseCard, isDark])

  // We don't show controls or get the card url when:
  // 1. In present mode and nested card (isCollapsed)
  // 2. In the version history editor, when isStatic is true
  const hideControls = (isPresentMode && isCollapsed) || isStatic
  const cardUrl = hideControls
    ? ''
    : getCardUrl({ cardId, docId, isPresentMode })

  return (
    <Flex
      contentEditable={false}
      onClick={stopPropagation} // Prevent clicks in the controls from bubbling up to open the collapsed card
      className="manage-card-controls"
      data-print-hidden
      transitionProperty="common"
      transitionDuration="normal"
      // In Card1, the controls are heavy so only visible on hover
      // But in Card2, it's just the collapse button so we want it always visible
      opacity={hideManageCardMenuButton ? 1 : 0} // Sets to 1 on card hover in doc mode via editorStyles.ts
      display="inherit"
      _hover={{
        opacity: 1,
      }}
      sx={{
        '[data-collapse-card-button]': {
          // NB: Don't change this from `visibility`. Changing it to `display` litters
          // the DOM in a weird place (the top left hand corner of the doc) when the
          // card is collapsed.
          visibility:
            !hideControls && isNested && !isCollapsed
              ? 'visible' // Visible
              : 'hidden',
        },
        '[data-manage-card-menu-button]': {
          display: !hideControls
            ? undefined // Visible
            : 'none',
        },
      }}
    >
      <HStack
        spacing={1}
        position="absolute"
        top={top || [1, 3]}
        right={[2, 4]}
        zIndex={2}
      >
        {topCollapseCardButton}
        {!isMobileDevice() && !hideManageCardMenuButton && (
          <ManageCardMenuButton
            containerRef={containerRef}
            openStyleDrawer={openStyleDrawer}
            cardUrl={cardUrl}
            getPos={getPos}
            isNested={isNested}
            isFirstCard={isFirstCard}
            isCollapsed={isCollapsed}
            hasCardBackground={hasCardBackground}
            previewContent={previewContent}
            updateAttributes={updateAttributes}
            onSharePanelOpen={onSharePanelOpen}
          />
        )}
      </HStack>
      {isNested && !isCollapsed && bottomCollapseCardButton}
      {/*Sharing and Analytics modals */}
      {doc && isSharePanelOpen && (
        <SharePanelModal
          doc={doc}
          editor={editor}
          isSharePanelOpen={isSharePanelOpen}
          onSharePanelClose={onSharePanelClose}
          onAnalyticsPanelOpen={onAnalyticsPanelOpen}
        />
      )}
      {doc && canViewFullAnalytics && isAnalyticsPanelOpen && (
        <AnalyticsPanelModal
          doc={doc}
          isAnalyticsPanelOpen={isAnalyticsPanelOpen}
          onAnalyticsPanelClose={onAnalyticsPanelClose}
          onSharePanelOpen={onSharePanelOpen}
          mode="full"
        />
      )}
    </Flex>
  )
})

type ManageCardMenuButtonProps = ManageCardMenuOptions & {
  containerRef: MutableRefObject<HTMLElement | null>
}

// Rendering Chakra Menu components is expensive, even if the children dont change
// One of these will exist for every Card in the memo, so memoize this component
const ManageCardMenuButton = memo(function ManageCardMenuButtonComponent({
  containerRef,
  ...menuProps
}: ManageCardMenuButtonProps) {
  const { openManageCardMenuClickHandler, activatorRef } =
    useManageCardMenu(menuProps)

  return (
    <GammaTooltip
      label="Card options"
      placement="top"
      portalProps={{ containerRef }}
    >
      <IconButton
        ref={activatorRef}
        data-manage-card-menu-button
        variant="ghost"
        size="sm"
        colorScheme="gray"
        isRound={true}
        icon={iconFarEllipsisH}
        aria-label="card options"
        onClick={openManageCardMenuClickHandler}
        onMouseDown={preventDefaultToAvoidBlur}
      >
        Actions
      </IconButton>
    </GammaTooltip>
  )
})
