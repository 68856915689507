import { EditorView } from 'prosemirror-view'

import {
  CardLayoutResizingPluginKey,
  LayoutItemPosAndSide,
  CardLayoutResizingResetEvent,
  CardLayoutResizingSetDraggingEvent,
  CardLayoutResizingSetHandleEvent,
} from './CardLayoutResizingState'

export const getCardLayoutResizingState = (view: EditorView) => {
  return CardLayoutResizingPluginKey.getState(view.state)!
}

export const updateHandle = (
  view: EditorView,
  value: LayoutItemPosAndSide | null
) => {
  const payload: CardLayoutResizingSetHandleEvent = {
    setHandle: value,
  }
  view.dispatch(view.state.tr.setMeta(CardLayoutResizingPluginKey, payload))
}

export const setDragging = (
  view: EditorView,
  args: CardLayoutResizingSetDraggingEvent['setDragging']
) => {
  const payload: CardLayoutResizingSetDraggingEvent = {
    setDragging: args,
  }
  view.dispatch(view.state.tr.setMeta(CardLayoutResizingPluginKey, payload))
}

export const resetResizingState = (view: EditorView) => {
  const payload: CardLayoutResizingResetEvent = {
    reset: true,
  }
  view.dispatch(view.state.tr.setMeta(CardLayoutResizingPluginKey, payload))
}
