import {
  Box,
  Collapse,
  FlexProps,
  useColorMode,
  useMergeRefs,
} from '@chakra-ui/react'
import { ScreenshotPreview } from '@gamma-app/ui'
import { Editor } from '@tiptap/core'
import { AnimatePresence } from 'framer-motion'
import React, {
  ForwardRefRenderFunction,
  memo,
  ReactNode,
  Ref,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { usePopper } from 'react-popper'

import placeholderBackground from 'gamma_components/placeholderBackground.svg'
import { useFeatureFlag } from 'modules/featureFlags'
import { usePanelLifecycle } from 'modules/panels/PanelLifecycle'
import { useScrollManager } from 'modules/scroll'
import { EditorModeEnum } from 'modules/tiptap_editor/types'
import { isMobileDevice } from 'utils/deviceDetection'

import { TreeItemInnerButton } from './TreeItemInnerButton'

export type CardTypes = 'image' | 'video' | 'embed' | 'card'

export type TreeItemInnerProps = {
  userCanEdit: boolean
  overlap: 'none' | 'self' | 'child'
  isRoot: boolean
  onSelectClick: () => void
  title: string
  hasChildren: boolean
  isExpanded: boolean
  setIsExpanded: (val: boolean) => void
  expandBox: ReactNode[]
  children: ReactNode
  showDropBorder: boolean
  previewRef: Ref<HTMLButtonElement>
  editor: Editor
  isSelectionOriginTOC: boolean
  docId: string
  cardId: string
  editorMode: EditorModeEnum
  getPos: () => number | null
  screenshotUrl?: string
  scrollOffsetFromTop: number
} & FlexProps

const TreeItemInnerRenderer: ForwardRefRenderFunction<
  HTMLDivElement,
  TreeItemInnerProps
> = (
  {
    userCanEdit,
    isRoot,
    expandBox,
    overlap,
    onSelectClick,
    title,
    hasChildren,
    isExpanded,
    setIsExpanded,
    children,
    showDropBorder,
    previewRef,
    editorMode,
    editor,
    isSelectionOriginTOC,
    docId,
    cardId,
    getPos,
    screenshotUrl,
    scrollOffsetFromTop,
    ...rest
  },
  ref
) => {
  const isSelected = overlap === 'self'
  const scrollManager = useScrollManager('toc')
  const screenshotsEnabled = useFeatureFlag('screenshotsEnabled')

  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(
    null
  )
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null)
  const { styles, update, attributes } = usePopper(
    referenceElement,
    popperElement,
    {
      placement: 'right-start',
      strategy: 'fixed',
    }
  )

  useEffect(() => {
    if (
      !isSelected ||
      !referenceElement ||
      isSelectionOriginTOC ||
      isMobileDevice()
    )
      return
    scrollManager.scrollElementIntoView({
      element: referenceElement,
      offsetFromTop: scrollOffsetFromTop,
    })
  }, [
    scrollManager,
    isSelected,
    isSelectionOriginTOC,
    referenceElement,
    scrollOffsetFromTop,
  ])

  const lifecycle = usePanelLifecycle()
  useEffect(() => {
    if (!update || !lifecycle) {
      return
    }

    return lifecycle.on('resize', () => {
      update()
    })
  }, [lifecycle, update])

  const [isHovering, setIsHovering] = useState(false)

  const onMouseEnter = useCallback(() => {
    if (update) {
      // always update the popper position on hover
      // the old position may be stale because of collapsing toc items
      update()
    }
    setIsHovering(true)
  }, [setIsHovering, update])
  const onMouseLeave = useCallback(() => setIsHovering(false), [setIsHovering])

  const { colorMode } = useColorMode()
  const isDark = colorMode === 'dark'

  return (
    <Box
      ml={isRoot ? 2 : 4}
      flex={1}
      position="relative"
      borderRadius="md"
      bg="transparent"
      ref={useMergeRefs(ref, setReferenceElement)}
      {...rest}
    >
      <TreeItemInnerButton
        showDragHandles={!isMobileDevice() && userCanEdit}
        overlap={overlap}
        onSelectClick={onSelectClick}
        title={title}
        hasChildren={hasChildren}
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
        showDropBorder={showDropBorder}
        previewRef={previewRef}
        editorMode={editorMode}
        editor={editor}
        docId={docId}
        cardId={cardId}
        getPos={getPos}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        isHovering={isHovering}
      />
      <ScreenshotPreview
        className="ss-preview"
        src={screenshotsEnabled ? screenshotUrl : placeholderBackground.src}
        fallbackSrc={placeholderBackground.src}
        shouldShow={!isMobileDevice() && Boolean(screenshotUrl) && isHovering}
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
        ml={5}
        isDark={isDark}
      />
      <Collapse in={isExpanded}>
        <AnimatePresence>{children}</AnimatePresence>
      </Collapse>
      {expandBox}
    </Box>
  )
}

export const TreeItemInner = memo(React.forwardRef(TreeItemInnerRenderer))
