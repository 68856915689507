import { Box, FormControl, Switch, Text, useToast } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { DOC_DISPLAY_NAME, GammaTooltip, LinkCopier } from '@gamma-app/ui'
import { ChangeEvent, useCallback, useMemo, useState } from 'react'

import { Doc, Permission, useUpdateDocPublicAccessMutation } from 'modules/api'
import { PUBLIC_ACCESS_TOOLTIP_LABEL } from 'modules/sharing/constants'
import { generateDocUrl, generateEmbedCode } from 'utils/url'

import { EmbedCodeCopier } from './EmbedCodeCopier'
import {
  NoAccessPermission,
  PermissionMapToHumanReadable,
} from './PermissionsMenu'
import { PermissionsSettingsRow } from './PermissionsSettingsRow'
import { PermissionsSettingsSection } from './PermissionsSettingsSection'
import { PermissionsSubtitleWithIcon } from './PermissionsSubtitleWithIcon'
import { SharePanelRowIcon } from './SharePanelRowIcon'

export const SharePublicOrEmbed = ({
  doc,
  isSharePublic,
  isConnected,
}: {
  doc: Doc
  isSharePublic: boolean
  isConnected: boolean
}) => {
  const toast = useToast()
  const [isChecked, setIsChecked] = useState(!!doc.publicAccess)
  const [updateDocPublicAccessMutation] = useUpdateDocPublicAccessMutation()

  const publicUrlOrEmbedCode = useMemo(() => {
    const publicUrl = generateDocUrl({
      docId: doc.id,
      path: 'public',
      absolute: true,
    })
    if (isSharePublic) {
      return publicUrl
    } else {
      return generateEmbedCode({
        docId: doc.id,
        docTitle: doc.title,
      })
    }
  }, [doc.id, doc.title, isSharePublic])

  const onPermissionSettingChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setIsChecked(e.target.checked)

      const variables = {
        id: doc.id,
        publicAccess: e.target.checked ? Permission.View : null,
      } as const
      updateDocPublicAccessMutation({
        // Maybe<Doc> doesnt allow null, but the API does. Need to fix codegen (#422)
        // @ts-ignore
        variables,
        optimisticResponse: {
          // @ts-ignore
          updateDoc: { ...variables, __typename: 'Doc' },
        },
      }).then(() => {
        toast({
          title: `Public access ${e.target.checked ? 'enabled' : 'removed'}`,
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top',
        })
      })
    },
    [doc.id, toast, updateDocPublicAccessMutation]
  )

  const disabledText = doc.publicAccess
    ? PermissionMapToHumanReadable[Permission.View].title
    : PermissionMapToHumanReadable[NoAccessPermission.NoAccess].title

  const description = isSharePublic ? (
    <>
      Let anyone with a link view a{' '}
      <GammaTooltip
        placement="top"
        label={`They won't be able to see other people active in the ${DOC_DISPLAY_NAME}, or follow along if you're presenting.`}
        aria-label="public access is read only"
      >
        <Text
          as="span"
          borderBottom="0.125em dashed"
          borderColor="gray.400"
          cursor="help"
        >
          read-only
        </Text>
      </GammaTooltip>{' '}
      version of your {DOC_DISPLAY_NAME}.
    </>
  ) : (
    `Enable public access to embed a copy of your ${DOC_DISPLAY_NAME} anywhere online.`
  )

  return (
    <>
      <Text fontSize="sm" color="gray.600" pt={2}>
        {description}
      </Text>

      <PermissionsSettingsSection showDivider={false}>
        <PermissionsSettingsRow
          title="Public access"
          testId="public-access"
          subtitle={
            <PermissionsSubtitleWithIcon
              subtitle="Anyone with a link can view"
              tooltipLabel={PUBLIC_ACCESS_TOOLTIP_LABEL}
              ariaLabel="Anyone with a link can view"
              icon={regular('circle-info')}
            />
          }
          img={<SharePanelRowIcon icon={regular('globe-americas')} />}
          isDisabled={!isConnected}
          disabledText={disabledText}
          permissionsControl={
            <FormControl>
              <Switch
                id="public-access"
                isChecked={isChecked}
                onChange={onPermissionSettingChange}
              />
            </FormControl>
          }
        />
      </PermissionsSettingsSection>

      <GammaTooltip
        label={`Enable public access to ${
          isSharePublic ? 'copy the public share link.' : 'get the embed code.'
        }`}
        placement="top"
        isDisabled={isChecked}
      >
        <Box>
          {isSharePublic ? (
            <LinkCopier
              isDisabled={!doc.publicAccess}
              url={publicUrlOrEmbedCode}
              customLabel="Copy public link"
              buttonWidth="10rem"
            />
          ) : (
            <EmbedCodeCopier
              embedCode={publicUrlOrEmbedCode}
              isDisabled={!isChecked}
            />
          )}
        </Box>
      </GammaTooltip>
    </>
  )
}
