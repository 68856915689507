import { callOrReturn, InputRule, InputRuleFinder } from '@tiptap/core'
import { Node, NodeType } from 'prosemirror-model'

import { findSelectionNearOrGapCursor } from './selection/findSelectionNearOrGapCursor'

// Tiptap has a wrappingInputRule, and a textBlockTypeInputRule,
// but sometimes we need both at the same time: change the type
// of the inner node, then wrap it in the outer node.
export function wrappingTransformInputRule(config: {
  find: InputRuleFinder
  innerType: NodeType
  outerType: NodeType
  getOuterAttributes?:
    | Record<string, any>
    | ((node: Node) => Record<string, any>)
    | false
    | null
  getInnerAttributes?:
    | Record<string, any>
    | ((node: Node) => Record<string, any>)
    | false
    | null
}) {
  return new InputRule({
    find: config.find,
    handler: ({ state, range, match }): void => {
      const $from = state.doc.resolve(range.from)

      // If the outer type doesn't work at this spot, bail
      if (
        !$from
          .node(-1) // The parent of the parent, e.g. see if a card can replace its child paragraph with a toggle
          .canReplaceWith(
            $from.index(-1),
            $from.indexAfter(-1),
            config.outerType
          )
      ) {
        return
      }

      const outerAttrs =
        callOrReturn(config.getOuterAttributes, undefined, $from.parent) || {}
      const innerAttrs =
        callOrReturn(config.getInnerAttributes, undefined, $from.parent) || {}

      // Remove the initial input, like "- " for a bullet
      const tr = state.tr.delete(range.from, range.to)
      const $start = tr.doc.resolve(range.from)
      const block = $start.parent
      const newBlock = config.outerType.createAndFill(outerAttrs, [
        config.innerType.create(innerAttrs, block.content),
      ])
      tr.replaceWith($start.before(), $start.after(), newBlock)
      const newSelection = findSelectionNearOrGapCursor(
        tr.doc.resolve(range.from)
      )
      if (!newSelection) return
      tr.setSelection(newSelection)
    },
  })
}
