import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Editor } from '@tiptap/core'
import { NodeSelection } from 'prosemirror-state'
import { useCallback } from 'react'

import { ToolbarButton } from './ToolbarButton'

type DuplicateNodeButtonProps = {
  editor: Editor
  selection: NodeSelection
  handleDuplicate?: () => void
}

export const DuplicateNodeButton = ({
  editor,
  selection,
  handleDuplicate,
}: DuplicateNodeButtonProps) => {
  const duplicateNode = useCallback(() => {
    editor
      .chain()
      .insertContentAt(
        selection.from + selection.node.nodeSize,
        selection.node.toJSON()
      )
      .selectInsertedNode()
      .run()
  }, [editor, selection])

  return (
    <ToolbarButton
      label="Duplicate"
      icon={regular('clone')}
      onClick={handleDuplicate || duplicateNode}
      testId="duplicate-node-button"
    />
  )
}
