import {
  Button,
  ButtonProps,
  ChakraProps,
  TooltipProps,
} from '@chakra-ui/react'
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'
import React, { MouseEventHandler } from 'react'

import { useTooltipPortalRef } from 'modules/tooltips/hooks'
import { preventDefaultToAvoidBlur } from 'utils/handlers'

export type ToolbarButtonProps = {
  label?: TooltipProps['label']
  shortcut?: string
  disabled?: boolean
  color?: ChakraProps['color']
  isActive?: boolean
  onClick: MouseEventHandler<HTMLButtonElement>
  icon?: FontAwesomeIconProps['icon']
  iconRotation?: FontAwesomeIconProps['rotation']
  testId?: string
} & ButtonProps

export const ToolbarButton = ({
  label,
  shortcut,
  disabled = false,
  color,
  isActive = false,
  onClick,
  icon,
  iconRotation,
  testId,
  children,
  ...rest
}: ToolbarButtonProps) => {
  const InnerButton = (
    <Button
      isDisabled={disabled}
      color={color}
      variant={isActive ? 'activeToolbar' : 'toolbar'}
      onClick={onClick}
      data-testid={testId}
      onMouseDown={preventDefaultToAvoidBlur}
      size="sm"
      {...rest}
    >
      {icon ? (
        <FontAwesomeIcon icon={icon} rotation={iconRotation} />
      ) : children ? (
        children
      ) : null}
    </Button>
  )
  // If no label is provided, then just render the buttons without tooltips
  if (!label) return InnerButton
  return (
    <ToolbarTooltip label={label} shortcut={shortcut}>
      {InnerButton}
    </ToolbarTooltip>
  )
}

type ToolbarTooltipProps = {
  label: TooltipProps['label']
  shortcut?: string
  children: React.ReactNode
}

export const ToolbarTooltip = ({
  label,
  shortcut,
  children,
}: ToolbarTooltipProps) => {
  return (
    <GammaTooltip
      placement="top"
      portalProps={{ containerRef: useTooltipPortalRef() }}
      label={label}
      shortcut={shortcut}
    >
      {children}
    </GammaTooltip>
  )
}
