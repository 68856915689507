import { SystemStyleObject } from '@chakra-ui/react'

import { FontSizeOptions } from './constants'
import { FontSize } from './FontSize'
import { getFontSizeOption, isHeadingStyle } from './utils'

export const HEADING_LINE_HEIGHT = 1.3
export const BODY_LINE_HEIGHT = 1.8

export const getFontSizeStyles = (
  fontSize: FontSize | undefined
): SystemStyleObject => {
  const { size, mobileSize } = getFontSizeOption(fontSize)
  return {
    fontSize: mobileSize ? [`${mobileSize}em`, `${size}em`] : `${size}em`,
    '@media print': {
      fontSize: `${size}em`, // Breakpoints don't trigger in print/PDFs, so force the larger one
    },
    lineHeight:
      fontSize && isHeadingStyle(fontSize)
        ? HEADING_LINE_HEIGHT
        : BODY_LINE_HEIGHT,
  }
}

export const fontStyles = {}
Object.keys(FontSizeOptions).forEach((key) => {
  const css = getFontSizeStyles(key)
  fontStyles[`[data-font-size="${key}"]`] = css
})
