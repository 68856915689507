import { paddedTextBackground } from '../../TextColor/highlightStyles'

const DIM_OPACITY = 0.4

// These styles will only apply when the design partner panel is open
export const SalEditorStyles = {
  // Animate card opacity when entering Sal mode
  '.block-card': {
    transitionProperty: 'opacity',
    transitionDuration: 'normal',
  },

  // Styles in here will only apply when Sal is open
  '&.sal-active': {
    // Dim inactive cards
    '.block-card:not(.sal-active-card, .sal-active-card-parent)': {
      opacity: DIM_OPACITY,
    },

    // If you're in a nested card, dim blocks that aren't the active card
    '.sal-active-card-parent': {
      '.block:not(.sal-active-card)': {
        opacity: DIM_OPACITY,
      },
      '.sal-active-card': {
        '.block': {
          opacity: 1,
        },
      },
    },

    '.sal-selection-text': {
      backgroundColor: `rgba(100, 100, 100, 0.25)`, // gray
      ...paddedTextBackground,
    },
  },
}
