// Forked from https://github.com/ueberdosis/tiptap/blob/main/packages/react/src/BubbleMenu.tsx
// to show even when non-empty (see bubble-menu-plugin)
import { Editor } from '@tiptap/core'
import React, { PropsWithChildren, useEffect, useRef } from 'react'

import { usePanelLifecycle } from 'modules/panels/PanelLifecycle'

import {
  BubbleMenuPluginKey,
  BubbleMenuPluginState,
} from './bubble-menu-plugin'

export type BubbleMenuProps = Omit<
  BubbleMenuPluginState,
  'element' | 'panelLifecycle'
> & {
  editor: Editor
  className?: string
}

export const BubbleMenu: React.FC<PropsWithChildren<BubbleMenuProps>> = (
  props
) => {
  const element = useRef<HTMLDivElement>(null)
  const panelLifecycle = usePanelLifecycle()

  const { editor, tippyOptions, onHide } = props
  useEffect(() => {
    if (!editor) return

    editor.commands.command(({ tr }) => {
      tr.setMeta(BubbleMenuPluginKey, {
        element: element.current as HTMLElement,
        tippyOptions,
        panelLifecycle,
        onHide,
      })
      return true
    })
  }, [editor, tippyOptions, panelLifecycle, onHide])

  return (
    <div
      ref={element}
      className={props.className}
      style={{ visibility: 'hidden' }}
    >
      {props.children}
    </div>
  )
}
