import { isEqual } from 'lodash'
import { EditorState, Transaction } from 'prosemirror-state'

import { AppDispatch } from 'modules/redux'
import { CardIds, CardIdMap } from 'modules/tiptap_editor'
import { setCardIds } from 'modules/tiptap_editor/reducer'

import { generateCardIdMap } from './cardIdMap'

export class CardIdsPluginState {
  public hasChanges: boolean = false

  public duplicateCardIds: string[] = []

  public value: { cardIds: CardIds; cardIdMap: CardIdMap } | null = null

  public compute(state: EditorState): this {
    const nextValue = generateCardIdMap(state)
    if (!isEqual(this.value, nextValue)) {
      this.hasChanges = true
    }

    const nextValidCardIds = nextValue.cardIds.filter(Boolean)
    const nextDuplicateCardIds = nextValidCardIds.filter((val, idx) =>
      nextValidCardIds.includes(val, idx + 1)
    )
    // The plugin state should only store newly observed duplicate cardIds
    // that have been added in the current transaction.
    this.duplicateCardIds = nextDuplicateCardIds.filter(
      (id) => !this.duplicateCardIds.includes(id)
    )

    // release old ref
    this.value = nextValue
    return this
  }

  public apply(tr: Transaction, state: EditorState): this {
    if (!tr.docChanged) {
      // early return to account for things like selection update
      return this
    }
    this.compute(state)

    return this
  }

  public processChanges(dispatch: AppDispatch): void {
    if (this.hasChanges && this.value) {
      dispatch(setCardIds(this.value))
    }

    this.hasChanges = false
  }
}
