import { EditorOptions } from '@tiptap/core'
import { DependencyList, useEffect, useMemo, useState } from 'react'

import { Editor } from './Editor'

function useForceUpdate() {
  const [, setValue] = useState(0)

  return () => setValue((value) => value + 1)
}

export const useEditor = (options: Partial<EditorOptions> = {}) => {
  const editor = useMemo<Editor>(() => {
    return new Editor(options)
  }, [])
  const forceUpdate = useForceUpdate()

  useEffect(() => {
    let isMounted = true

    editor.on('transaction', () => {
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          if (isMounted) {
            forceUpdate()
          }
        })
      })
    })

    return () => {
      editor.destroy()
      isMounted = false
    }
  }, [editor])

  return editor
}
