import {
  Box,
  Button,
  IconButton,
  InteractivityProps,
  Spacer,
  Text,
  useColorMode,
} from '@chakra-ui/react'
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Editor } from '@tiptap/core'
import React, { memo, Ref } from 'react'

import { EditorModeEnum } from 'modules/tiptap_editor/types'
import { isMobileDevice } from 'utils/deviceDetection'

import { CardMenu } from './CardMenu'

export type TreeItemInnerButtonProps = {
  showDragHandles: boolean
  overlap: 'none' | 'self' | 'child'
  onSelectClick: () => void
  title: string
  hasChildren: boolean
  isExpanded: boolean
  setIsExpanded: (val: boolean) => void
  showDropBorder: boolean
  previewRef: Ref<HTMLButtonElement>
  editorMode: EditorModeEnum
  editor: Editor
  docId: string
  cardId: string
  getPos: () => number | null
  onMouseEnter: () => void
  onMouseLeave: () => void
  isHovering: boolean
}

export const TreeItemInnerButton: React.FC<TreeItemInnerButtonProps> = memo(
  ({
    showDragHandles,
    overlap,
    onSelectClick,
    title,
    hasChildren,
    isExpanded,
    setIsExpanded,
    showDropBorder,
    previewRef,
    editorMode,
    editor,
    docId,
    cardId,
    getPos,
    onMouseEnter,
    onMouseLeave,
    isHovering,
  }) => {
    const isSelectingChild = overlap === 'child'
    const isSelected = overlap === 'self'
    const { colorMode } = useColorMode()
    const isDark = colorMode === 'dark'

    const outlineProps: Partial<InteractivityProps> = showDropBorder
      ? {
          outline: '2px solid',
          outlineColor: isDark ? 'trueblue.800' : 'trueblue.100',
        }
      : {}

    const hoverBgStyle = isSelected
      ? {}
      : { bg: isDark ? 'indigo.700' : 'indigo.50' }
    return (
      <Button
        ref={previewRef}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        className="toc-item"
        w="100%"
        _hover={{
          ...hoverBgStyle,
        }}
        p={1.5}
        pl={showDragHandles ? 5 : 1.5}
        height="auto"
        textAlign="left"
        cursor="pointer"
        borderRadius="md"
        transitionProperty="common"
        transitionDuration="normal"
        role="group"
        onClick={(event) => {
          onSelectClick()
          event.preventDefault()
          event.stopPropagation()
        }}
        variant="ghost"
        fontWeight="500"
        colorScheme={isSelected ? 'trueblue' : 'inherit'}
        data-testid="toc-item-button"
        {...outlineProps}
      >
        {showDragHandles && (
          <Box
            opacity="0"
            display="flex"
            mr="0"
            alignItems={'center'}
            color="gray.400"
            borderRadius="md"
            _groupHover={{ opacity: 1, cursor: 'grab' }}
            _hover={{
              bg: isDark ? 'whiteAlpha.100' : 'gray.100',
              color: 'gray.800',
            }}
            transitionProperty="common"
            transitionDuration="normal"
            pos="absolute"
            top={0}
            bottom={0}
            left={0}
            pl={2}
            pr={1.5}
          >
            <FontAwesomeIcon icon={solid('grip-dots-vertical')} size="sm" />
          </Box>
        )}
        {hasChildren ? (
          <IconButton
            icon={<FontAwesomeIcon icon={regular('chevron-down')} />}
            size="xs"
            variant="ghost"
            aria-label="collapse"
            isRound
            data-testid="toc-item-expand"
            transform={isExpanded ? 'rotate(0deg)' : 'rotate(-90deg)'}
            onClick={(event) => {
              event.preventDefault()
              event.stopPropagation()
              setIsExpanded(!isExpanded)
            }}
            opacity={hasChildren ? 1 : 0}
            pointerEvents={hasChildren ? 'auto' : 'none'}
            bg={
              isSelectingChild && !isExpanded
                ? isDark
                  ? 'trueblue.800'
                  : 'trueblue.50'
                : undefined
            }
            _hover={{
              bg: isSelectingChild
                ? isDark
                  ? 'trueblue.700'
                  : 'trueblue.100'
                : isDark
                ? 'whiteAlpha.100'
                : 'blackAlpha.100',
            }}
            _active={{
              bg: isSelectingChild
                ? isDark
                  ? 'trueblue.700'
                  : 'trueblue.100'
                : isDark
                ? 'whiteAlpha.100'
                : 'blackAlpha.100',
            }}
          />
        ) : (
          <IconButton
            icon={<FontAwesomeIcon icon={solid('circle')} />}
            size="xs"
            variant="ghost"
            aria-label="Collapse"
            isRound
            onClick={(event) => {
              event.preventDefault()
              event.stopPropagation()
            }}
            transform={isSelected ? 'scale(.6)' : 'scale(.4)'}
            pointerEvents={hasChildren ? 'auto' : 'none'}
            colorScheme={isSelected ? 'trueblue' : 'gray'}
          />
        )}
        <Text
          ml={1}
          whiteSpace="normal"
          noOfLines={1}
          wordBreak="break-all"
          data-testid="toc-item-title"
        >
          {title || 'Untitled'}
        </Text>
        <Spacer />
        {!isMobileDevice() && editorMode === EditorModeEnum.DOC_VIEW && (
          <CardMenu
            shouldShowButton={overlap === 'self' || isHovering}
            cardId={cardId}
            editor={editor}
            docId={docId}
            getPos={getPos}
            editorMode={editorMode}
          />
        )}
      </Button>
    )
  }
)
