import TiptapHighlight from '@tiptap/extension-highlight'
import { mergeAttributes } from '@tiptap/react'

import { ExtensionPriorityMap } from '../constants'
import { ValidTextColors } from './textColorStyles'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    setHighlightVariant: {
      setHighlightVariant: (attributes?: { variant: string }) => ReturnType
    }
  }
}

export const Highlight = TiptapHighlight.extend({
  name: 'highlight',
  priority: ExtensionPriorityMap.Highlight,
  excludes: 'textColor highlight',
  addAttributes() {
    return {
      variant: {
        default: 'yellow',
      },
    }
  },

  addCommands() {
    return {
      ...this.parent?.(),
      setHighlightVariant:
        (attributes) =>
        ({ commands }) => {
          return commands.setMark(this.name, attributes)
        },
    }
  },

  parseHTML() {
    return [
      {
        tag: 'mark[class=highlight]',
      },
      {
        tag: '*[highlight]',
        getAttrs(elt: HTMLElement) {
          const color = elt.getAttribute('highlight')
          if (!color || !ValidTextColors.includes(color)) {
            // Future todo: match arbitrary hex colors
            return false
          }
          return {
            variant: elt.getAttribute('highlight'),
          }
        },
        // This lets us match a tag like <h1 color="red">. It will run before the H1 rule due to the higher
        // priority, and consuming: false means the H1 rule can still match it.
        consuming: false,
        priority: 60,
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'mark',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
        class: 'highlight',
      }),
    ]
  },

  renderHTMLforAI({ mark }) {
    return [
      'span',
      {
        color: mark.attrs.variant,
      },
    ]
  },
}).configure({ multicolor: true })
