import { NodeViewProps } from '@tiptap/core'
import React from 'react'

import { useFeatureFlag } from 'modules/featureFlags'
import { useAppSelector } from 'modules/redux'
import { selectCardCollapsed } from 'modules/tiptap_editor/reducer'

import { useDocFlag } from '../DocFlags/hooks'
import { Card } from './Card'
import { CardView2 } from './Card2/CardView2'
import { CollapsedCard } from './Card2/CollapsedCard'

export const SwitchingCardView: React.FC<NodeViewProps> = (nodeViewProps) => {
  const { node } = nodeViewProps
  const { id: cardId } = node.attrs
  const docHasCardLayouts = useDocFlag(
    nodeViewProps.editor,
    'cardLayoutsEnabled'
  )
  const card1InCard2 = useFeatureFlag('card1InCard2')
  const isCollapsed = useAppSelector(selectCardCollapsed(cardId))

  if (docHasCardLayouts || card1InCard2) {
    return isCollapsed ? (
      <CollapsedCard {...nodeViewProps} />
    ) : (
      <CardView2 {...nodeViewProps} />
    )
  }

  return <Card {...nodeViewProps} />
}
