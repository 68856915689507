import { Box, Flex, Image, useUpdateEffect } from '@chakra-ui/react'
import { NodeViewProps } from '@tiptap/react'
import { useCallback, useEffect, useRef, useState } from 'react'

import { UploadStatus } from 'modules/media'
import { useAppDispatch, useAppSelector } from 'modules/redux'
import { getThemeBase } from 'modules/theming/themeBases'
import { selectTheme } from 'modules/tiptap_editor/reducer'
import { isMobileDevice } from 'utils/deviceDetection'
import { useEffectWhen, useWindowResizing } from 'utils/hooks'

import { MOVEABLE_WRAPPER_CLASSNAME } from '..'
import { AnnotatableNodeViewWrapper } from '../../Annotatable'
import { findBlockWidthDecoration } from '../../block/BlockWidthPlugin'
import { useCardColorMode } from '../../Card/hooks/useCardColorMode'
import { isFootnoteEditor } from '../../Footnote/utils'
import { getAlignStyles } from '../../HorizontalAlign'
import { isFocusedAndEditable } from '../../selection/FocusedNodes'
import { isNodeViewInGallery } from '../Gallery'
import {
  MediaPlaceholderBlock,
  MediaPlaceholderErrorUploadingTag,
  MediaPlaceholderSpinner,
} from '../Placeholder'
import { ResizableControls, useResizeable } from '../Resizeable'
import { ImageNodeAttrs } from '../types'
import { isSVG } from '../utils'
import {
  getZoomLayoutId,
  useMediaZoom,
  ZoomableOverlay,
  ZoomClickCapture,
  ZoomTransition,
} from '../Zoomable'
import { ClippableControls } from './Clippable'
import { CroppedImage } from './CroppedImage'
import { endCropping, selectIsCropping } from './reducer'

export const ImageView = (nodeViewProps: NodeViewProps) => {
  const {
    node,
    editor,
    updateAttributes,
    selected: isSelected,
    decorations,
  } = nodeViewProps
  const containerWrapperRef = useRef<HTMLDivElement>(null)
  const imageRef = useRef<HTMLImageElement>(null)
  const [intrinsicAspectRatio, setIntrisicAspectRatio] = useState<
    number | null
  >(null)
  const { isDark } = useCardColorMode(decorations)
  const backgroundColor = isDark ? 'black' : 'white'
  const isFocused = isFocusedAndEditable(decorations)
  const inFootnote = isFootnoteEditor(editor)
  const isWindowResizing = useWindowResizing()
  const [imgHasLoaded, setImgLoaded] = useState<string | undefined>()
  const [currentWidth, setCurrentWidth] = useState(0)
  const [currentHeight, setCurrentHeight] = useState(0)
  const isCropping = useAppSelector(selectIsCropping) // If crop mode is enabled
  const dispatch = useAppDispatch()
  const inGallery = isNodeViewInGallery(nodeViewProps)
  const { isFullWidth } = findBlockWidthDecoration(decorations)

  const {
    src,
    tempUrl,
    uploadStatus,
    meta,
    showPlaceholder,
    horizontalAlign,
    resize,
    id,
  } = node.attrs as ImageNodeAttrs

  const isCroppingThisImage = isSelected && isCropping

  const setSize = useCallback(() => {
    const containerRect = containerWrapperRef.current?.getBoundingClientRect()
    const imageRect = imageRef.current?.getBoundingClientRect()
    if (containerRect && imageRect) {
      const ar = imageRect.width / imageRect.height
      setCurrentWidth(containerRect.width)
      setCurrentHeight(containerRect.width / ar)
      setIntrisicAspectRatio(ar)
    }
  }, [])

  const {
    ref: imageWrapperRef,
    isResizing,
    setIsResizing,
    resizeableSx,
    isAnimating,
    onLayoutAnimationStart,
    onLayoutAnimationComplete,
  } = useResizeable<HTMLDivElement>(editor)

  const updateResizeAttrs = useCallback(
    (
      resizeAttrs: Partial<ImageNodeAttrs['resize']>,
      setFullWidth?: boolean
    ) => {
      updateAttributes({
        ...node.attrs,
        fullWidthBlock:
          setFullWidth === undefined ? node.attrs.fullWidthBlock : setFullWidth,
        resize: {
          ...node.attrs.resize,
          ...resizeAttrs,
        },
      })
    },
    [node.attrs, updateAttributes]
  )

  useEffect(() => {
    if (isWindowResizing) {
      // For some unknown reason, headless chrome computes the size of the image
      // as 0x0 if we run setSize() upon window resize start :shrug: :frown:
      // Return early since we really only care about recomputing on resize end
      return
    }

    setSize() // (Re)-compute the image size
  }, [setSize, isCroppingThisImage, imgHasLoaded, isWindowResizing])

  useEffectWhen(
    () => {
      if (!isSelected) {
        dispatch(endCropping())
        setIsResizing(false)
        editor.commands.forceHideBubbleMenu?.(false)
      }
    },
    [editor, dispatch, setSize, isSelected, setIsResizing],
    [isSelected]
  )

  useUpdateEffect(() => {
    // Reposition the bubble menu when toggling crop mode
    editor.commands.refreshBubbleMenu?.()
  }, [editor, isCropping, imgHasLoaded])

  const editingEnabled =
    imageWrapperRef.current &&
    Boolean(currentWidth) &&
    isFocused &&
    !inGallery &&
    !inFootnote
  const widthPx = resize?.width || meta?.width
  const isSvg = isSVG(src || tempUrl)

  // Zoom
  const zoomingEnabled = !isCropping && !isResizing && !inGallery
  const { isZoomed, enterZoom, exitZoom } = useMediaZoom(id)
  const alignStyles = getAlignStyles(isFullWidth ? 'center' : horizontalAlign)

  // Theme
  const theme = useAppSelector(selectTheme)
  const base = getThemeBase(theme)

  return (
    <AnnotatableNodeViewWrapper
      {...nodeViewProps}
      as="div"
      style={{ height: inGallery ? '100%' : undefined }}
    >
      {showPlaceholder && <MediaPlaceholderBlock {...nodeViewProps} />}
      {!showPlaceholder && (src || tempUrl) && (
        <Flex
          data-testid="image-node-wrapper"
          ref={containerWrapperRef}
          h="100%"
          w="100%"
          direction="column"
          className={MOVEABLE_WRAPPER_CLASSNAME}
          sx={resizeableSx}
          css={alignStyles}
        >
          {editingEnabled && isCroppingThisImage && (
            <ClippableControls
              imageWrapperRef={imageWrapperRef}
              updateResizeAttrs={updateResizeAttrs}
              refreshDeps={[isWindowResizing, isAnimating]}
              currentWidth={currentWidth}
              currentHeight={currentHeight}
              clipPath={resize?.clipPath}
              clipAspectRatio={resize?.clipAspectRatio}
              onFinishCrop={() => {}}
            />
          )}
          {editingEnabled && !isCropping && isSelected && !isFullWidth && (
            <ResizableControls
              imageWrapperRef={imageWrapperRef}
              setIsResizing={setIsResizing}
              updateResizeAttrs={updateResizeAttrs}
              refreshDeps={[
                node.attrs,
                imgHasLoaded,
                isAnimating,
                isWindowResizing,
              ]}
            />
          )}
          <Box
            // This div will get masked while you're cropping
            ref={imageWrapperRef}
            css={{
              mask: isCroppingThisImage ? '' : 'none !important',
            }}
            sx={
              meta?.has_transparency
                ? undefined
                : isSelected && editingEnabled
                ? { ...base.imageSx, borderRadius: '0px' }
                : base.imageSx
            }
            data-drag-handle // https://tiptap.dev/guide/node-views/react#dragging
            data-image-node-element
            data-selection-ring="inside"
            data-selection-background
            data-node-image-testid={id}
            w={
              isFullWidth || inGallery
                ? '100%'
                : widthPx
                ? `calc(${widthPx} * var(--font-size) / 16)`
                : 'fit-content'
            }
            maxW="100%"
            position="relative"
            overflow="hidden" // Apply rounded corners from theme
          >
            {/* Use an img tag to capture clicks so you can right click for copy image and copy image address */}
            <ZoomClickCapture
              enterZoom={enterZoom}
              as={Image}
              src={isMobileDevice() ? undefined : src}
              loading="lazy"
            />
            {/* Thumbnail loaded directly in the memo body */}
            <CroppedImage
              containerWidth={currentWidth}
              intrinsicAspectRatio={intrinsicAspectRatio || undefined}
              isCroppingThisImage={isCroppingThisImage}
              imageAttrs={node.attrs}
              onLoad={() => {
                setImgLoaded(src || tempUrl || '')
              }}
              ref={imageRef}
              // A specified width is needed to get SVGs to render. The max-width style applied in
              // contentStyles will keep this to max 100% of the container. We don't specify height
              // because that will be automatically inferred from the image's aspect ratio, and we
              // don't have a max-height to contain it.
              width={
                inGallery
                  ? 'auto'
                  : widthPx || isResizing || isFullWidth || isSvg
                  ? `100%`
                  : meta?.width
              }
              // On Safari, setting width: 100% messes up the gallery flex layout, but min-width: 100% works in both Safari and Chrome
              minW={inGallery ? '100%' : undefined}
              motionProps={{
                // This needs to be a string for the equality check
                layoutDependency: isZoomed,
                layoutId: getZoomLayoutId(id),
                transition: ZoomTransition,
                onLayoutAnimationStart,
                onLayoutAnimationComplete,
              }}
              objectFit={inGallery ? 'cover' : 'contain'}
            />
            {/* Click to zoom into full size version */}
            {zoomingEnabled && (
              <ZoomableOverlay
                isZoomed={isZoomed}
                exitZoom={exitZoom}
                editor={editor}
              >
                <CroppedImage
                  imageAttrs={node.attrs}
                  backgroundColor={backgroundColor}
                  w={isSvg ? '80vw' : undefined}
                  motionProps={{
                    layoutDependency: isZoomed,
                    layoutId: getZoomLayoutId(id),
                    transition: ZoomTransition,
                  }}
                  isZoomed
                />
              </ZoomableOverlay>
            )}
            {/* Upload status indicators */}
            {uploadStatus == UploadStatus.Uploading && (
              <MediaPlaceholderSpinner />
            )}
            {uploadStatus == UploadStatus.Error && (
              <MediaPlaceholderErrorUploadingTag />
            )}
          </Box>
        </Flex>
      )}
    </AnnotatableNodeViewWrapper>
  )
}
