import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Center,
  Fade,
  Flex,
  GridItem,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Link,
  SimpleGrid,
  Skeleton,
  Text,
  VStack,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'
import { motion } from 'framer-motion'
import { range, sample } from 'lodash'
import React, { memo, useCallback, useEffect, useState } from 'react'

import { config } from 'config'
import { preventDefaultToAvoidBlur } from 'utils/handlers'
import { useDebounced } from 'utils/hooks'

import { ImageSearchResult } from '../apis/imageSearch'
import { ImageAttrs } from '../types/Image'

const RESULTS_PER_PAGE = 18

type ImageSearchGridItemProps = {
  image: ImageSearchResult
  isSelected: boolean
  onImageClick: (image: ImageSearchResult) => void
  provider: string
}

const ImageSearchGridItem = memo(
  ({ image, isSelected, onImageClick }: ImageSearchGridItemProps) => {
    return (
      <GridItem key={image.id}>
        <Button
          borderRadius="base"
          display="block"
          variant="ghost"
          width="100%"
          opacity={1}
          height="100px"
          textAlign="left"
          pos="relative"
          p={0}
          _hover={{ shadow: 'outline' }}
          _active={{ shadow: 'outline' }}
          transition="box-shadow .2s ease"
          cursor="pointer"
          onClick={() => onImageClick(image)}
          backgroundImage={`url(${image.thumbnailUrl})`}
          backgroundRepeat="no-repeat"
          backgroundSize="cover"
          backgroundPosition="center"
          sx={{
            _focusVisible: {
              '.attribution-container': {
                opacity: 1,
              },
            },
            _hover: {
              '.attribution-container': {
                opacity: 1,
              },
            },
          }}
          data-testid="image-search-grid-item"
        >
          <Fade in={isSelected}>
            <Box
              pointerEvents="none"
              color="white"
              pos="absolute"
              inset={0}
              bg="rgba(0,0,0,.5)"
              borderRadius="base"
            >
              <Center h="100%" w="100%">
                <FontAwesomeIcon icon={regular('check')} />
              </Center>
            </Box>
          </Fade>

          {/* We need a fallback */}
          {!image.thumbnailUrl && (
            <GridItem width="100%">
              <Skeleton height="80px" borderRadius="base" />
            </GridItem>
          )}
          <Box
            left={0}
            right={0}
            bottom={0}
            p={2}
            pos="absolute"
            color="white"
            fontSize="xs"
            textOverflow="ellipsis"
            overflow="hidden"
            bg="linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%)"
            borderBottomRadius="base"
            className="attribution-container"
            opacity={0}
            transitionProperty="common"
            transitionDuration="normal"
          >
            {image.attributionUrl && (
              <GammaTooltip
                label={`${image.credit}'s profile`}
                placement="left"
              >
                <Link
                  mr={1}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={image.attributionUrl}
                  tabIndex={-1}
                >
                  <FontAwesomeIcon
                    transform="shrink-4"
                    icon={regular('external-link-alt')}
                  />
                </Link>
              </GammaTooltip>
            )}
            {image.credit}
          </Box>
        </Button>
      </GridItem>
    )
  }
)

ImageSearchGridItem.displayName = 'ImageSearchGridItem'

export const imageResultToImageAttrs = (
  imageResult: ImageSearchResult,
  searchQuery: string
) => {
  return {
    src: imageResult.imageUrl,
    tempUrl: imageResult.thumbnailUrl,
    meta: {
      height: imageResult.height,
      width: imageResult.width,
    },
    query: searchQuery,
  }
}

type ImageSearchGridProps = {
  searchQuery: string
  currentImageUrl?: string
  updateAttributes: (attrs: Partial<ImageAttrs>) => void
  provider: string
  maxResults?: number
}

export const ImageSearchGrid = ({
  searchQuery,
  currentImageUrl,
  updateAttributes,
  provider,
  maxResults = 1000,
}: ImageSearchGridProps) => {
  const [imageResults, setImageResults] = useState([])
  const [isLoading, setLoading] = useState(true)
  const [hasError, setHasError] = useState(false)
  const [page, setPage] = useState(1)

  const trackImageClick = useCallback(
    (image: ImageSearchResult) => {
      const imageTrackEndpoint = `${config.API_HOST || ''}/media/images/track`
      fetch(imageTrackEndpoint, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          provider,
          id: image.id,
        }),
      })
    },
    [provider]
  )

  const fetchResults = useCallback(
    async (forPage: number) => {
      const imageSearchEndpoint = `${
        config.API_HOST || ''
      }/media/images/search?count=${RESULTS_PER_PAGE}&query=${searchQuery}&provider=${provider}&page=${forPage}`
      setLoading(true)
      setHasError(false)
      try {
        const req = await fetch(imageSearchEndpoint, { credentials: 'include' })
        const res = await req.json()
        setLoading(false)
        if (!(res?.length >= 0)) {
          throw new Error(res)
        }
        setImageResults((currentRes) => currentRes.concat(res))
      } catch (error) {
        setHasError(true)
        setLoading(false)
        console.error('(caught) [ImageSearchGrid] fetchResults:', error)
      }
    },
    [searchQuery, provider]
  )

  // Whenever the query changes, reset to the first page of results
  useEffect(() => {
    setImageResults([])
    setPage(1)
    fetchResults(1)
  }, [searchQuery, fetchResults])

  // Load more by incrementing the page and refetching
  const canLoadMore =
    imageResults.length < maxResults &&
    imageResults.length >= page * RESULTS_PER_PAGE
  const loadMore = useCallback(() => {
    fetchResults(page + 1)
    setPage(page + 1)
  }, [fetchResults, page])
  const onImageClick = useCallback(
    (imageResult: ImageSearchResult) => {
      trackImageClick(imageResult)
      updateAttributes(imageResultToImageAttrs(imageResult, searchQuery))
    },
    [searchQuery, trackImageClick, updateAttributes]
  )

  const gridItems: React.ReactNode[] = imageResults.map(
    (image: ImageSearchResult, index) => {
      const imageBaseUrl = image.imageUrl.split('?')[0]
      const currentImageBaseUrl =
        currentImageUrl && currentImageUrl.split('?')[0]
      return (
        <ImageSearchGridItem
          provider={provider}
          image={image}
          isSelected={imageBaseUrl === currentImageBaseUrl}
          onImageClick={onImageClick}
          key={index}
        />
      )
    }
  )
  return (
    <Flex direction="column" align="stretch">
      <SimpleGrid gap={2} columns={{ base: 2, '2xl': 3 }}>
        {gridItems}
        {isLoading && <GridSkeleton />}
      </SimpleGrid>
      {canLoadMore && (
        <Button variant="plain" onClick={loadMore} mt={4}>
          Load more
        </Button>
      )}
      {imageResults.length === 0 && !isLoading && (
        <Box width="100%" textAlign="center" padding="10px">
          <Text style={{ color: 'gray' }}>
            No images found for "{searchQuery}"
          </Text>
        </Box>
      )}
      {hasError && (
        <Alert status="error">
          <AlertIcon />
          Error searching for images.
        </Alert>
      )}
    </Flex>
  )
}

const GridSkeleton = () => {
  return (
    <>
      {range(RESULTS_PER_PAGE).map((_, index) => (
        <GridItem key={index}>
          <Skeleton height="80px" borderRadius="base" />
        </GridItem>
      ))}
    </>
  )
}

type ImageSearchProps = {
  currentImageUrl?: string
  updateAttributes: (attrs: Partial<ImageAttrs>) => void
  defaultQuery?: string
  randomQueries?: string[]
  provider: string
}

const MotionIconButton = motion(IconButton)

export const ImageSearch = ({
  currentImageUrl,
  updateAttributes,
  defaultQuery,
  randomQueries,
  provider,
}: ImageSearchProps) => {
  const [searchQuery, setSearchQuery] = useState<string | null>(null)
  const [inputValue, setInputValue] = useState('')

  // When we change providers, reset the search
  useEffect(() => {
    setInputValue(defaultQuery || '')
    setSearchQuery(defaultQuery || sample(randomQueries) || '')
  }, [defaultQuery, randomQueries])

  const randomizeQuery = useCallback(() => {
    const query = sample(randomQueries)
    if (!query) return
    setSearchQuery(query)
    setInputValue('')
  }, [randomQueries])

  const setSearchQueryDebounced = useDebounced(setSearchQuery, 500)

  return (
    <VStack
      spacing={4}
      align="stretch"
      translate="no" // https://linear.app/gamma-app/issue/G-3050/reactdom-insertbefore-error-when-using-translation-plugin
    >
      <InputGroup size="md">
        <InputLeftElement pointerEvents="none">
          <Box color="gray.400">
            <FontAwesomeIcon icon={regular('search')} />
          </Box>
        </InputLeftElement>
        <Input
          placeholder={searchQuery || 'Find an image'}
          value={inputValue}
          onChange={(event) => {
            setInputValue(event.target.value)
            setSearchQueryDebounced(event.target.value)
          }}
          data-testid="image-search-input"
        />
        {randomQueries && (
          <InputRightElement>
            <GammaTooltip placement="top" label="Surprise me">
              <MotionIconButton
                icon={<FontAwesomeIcon icon={regular('dice')} />}
                aria-label="Surprise me"
                variant="ghost"
                onClick={randomizeQuery}
                size="sm"
                whileTap={{ y: '-5px' }}
                onMouseDown={preventDefaultToAvoidBlur}
              />
            </GammaTooltip>
          </InputRightElement>
        )}
      </InputGroup>
      {searchQuery !== null && (
        <ImageSearchGrid
          updateAttributes={updateAttributes}
          currentImageUrl={currentImageUrl}
          searchQuery={searchQuery}
          provider={provider}
        />
      )}
    </VStack>
  )
}
