import { Divider, Stack, useOutsideClick } from '@chakra-ui/react'
import { Editor } from '@tiptap/core'
import React, { useRef } from 'react'

import { useAllFeatureFlags } from 'modules/featureFlags'

import { InsertWidgetButtons } from './InsertWidgetButtons'
import { INSERTABLE_CATEGORIES_LIST } from './items'

export const InsertWidgetInner = ({ editor }: { editor: Editor }) => {
  const allFlags = useAllFeatureFlags()
  const [openButton, setOpenButton] = React.useState<string | null>(null)
  const widgetRef = useRef(null)

  useOutsideClick({
    ref: widgetRef,
    handler: (e) => {
      if ((e.target as HTMLElement).closest('[data-insert-widget-popover]')) {
        return // no op
      }
      setOpenButton(null)
    },
  })
  return (
    <Stack
      bg="#F9FAFBDD"
      p={1}
      borderWidth="1px"
      borderColor="whiteAlpha.600"
      borderRadius="md"
      shadow="xl"
      ref={widgetRef}
      overflow="hidden"
      spacing={1}
    >
      {INSERTABLE_CATEGORIES_LIST.map((outer, index) => {
        return (
          <React.Fragment key={index}>
            {index > 0 && <Divider borderColor="blackAlpha.200" />}
            {outer.map(({ name, itemGroups, icon, featureFlag }) => {
              if (featureFlag && !allFlags[featureFlag]) return null

              return (
                <InsertWidgetButtons
                  key={name}
                  itemGroups={itemGroups}
                  icon={icon}
                  name={name}
                  editor={editor}
                  setOpenButton={setOpenButton}
                  isOpen={openButton === name}
                />
              )
            })}
          </React.Fragment>
        )
      })}
    </Stack>
  )
}
