import { AvatarGroup, Flex, Text } from '@chakra-ui/react'
import { AvatarOverflowList, TooltipAvatar } from '@gamma-app/ui'
import { NodeViewProps } from '@tiptap/react'
import { formatDistance, parseISO } from 'date-fns'

import { config } from 'config'
import { useAppSelector } from 'modules/redux'
import { selectDoc, selectDocEditors } from 'modules/tiptap_editor/reducer'
import { useGammaBreakpointValue } from 'utils/breakpoints/useGammaBreakpointValue'

import { AnnotatableNodeViewWrapper } from '../Annotatable'
import { useCurrentTime } from './useCurrentTime'

export const ContributorsView = (nodeViewProps: NodeViewProps) => {
  const MAX_EDITORS =
    useGammaBreakpointValue({
      base: 4,
      md: 8,
    }) || 8

  const contributors = useAppSelector(selectDocEditors) || []
  const doc = useAppSelector(selectDoc)
  const lastEditedTimeToDisplay = doc?.editedTime
  const currTime = useCurrentTime(lastEditedTimeToDisplay)
  const overflowCount = contributors.length - MAX_EDITORS
  const horizontalAlign = nodeViewProps.node.attrs.horizontalAlign ?? 'left'
  const flexDirection = {
    left: 'row',
    center: 'column',
    right: 'row-reverse',
  }[horizontalAlign]

  return (
    <>
      {contributors.length > 0 && (
        <AnnotatableNodeViewWrapper {...nodeViewProps} as="div">
          <Flex
            userSelect="none"
            contentEditable={false}
            justify="flex-start"
            align="center"
            direction={flexDirection}
            borderRadius="var(--box-border-radius)"
            data-selection-ring="padded"
            data-selection-background
          >
            <AvatarGroup>
              {contributors.slice(0, MAX_EDITORS).map((contributor) => (
                <TooltipAvatar
                  // width/height/fontSize props needed for zooming in the editor to work
                  width="1.6em"
                  height="1.6em"
                  fontSize="inherit"
                  key={contributor.user.id}
                  shadow="base"
                  borderWidth="1px"
                  name={`${contributor.user.displayName} edited
              ${formatDistance(
                currTime,
                parseISO(contributor.lastEdited)
              )} ago`}
                  src={contributor.user.profileImageUrl}
                />
              ))}
              {overflowCount > 0 && (
                <AvatarOverflowList
                  count={overflowCount}
                  // Map over contributors to match the data to avatar's expected data shape
                  avatars={contributors.map((c) => ({
                    name: c.user.displayName || '',
                    id: c.user.id,
                    profileImageUrl: c.user.profileImageUrl || '',
                    color: 'white',
                  }))}
                  disabled={true}
                />
              )}
            </AvatarGroup>
            <Flex
              direction={'column'}
              ml={horizontalAlign === 'left' ? '0.5em' : 0}
              mr={horizontalAlign === 'right' ? '0.5em' : 0}
              lineHeight="1.4"
              textAlign={horizontalAlign}
            >
              <Text className="contributors-list">
                {contributors.length === 1
                  ? `by ${contributors[0].user.displayName}`
                  : `${contributors.length} Contributors`}
              </Text>
              {!config.GAMMA_PUPPETEER_SERVICE && lastEditedTimeToDisplay && (
                <Text fontSize="0.75em">{`Last edited ${formatDistance(
                  currTime,
                  parseISO(lastEditedTimeToDisplay)
                )} ago`}</Text>
              )}
            </Flex>
          </Flex>
        </AnnotatableNodeViewWrapper>
      )}
    </>
  )
}
