import { Badge, Flex, Portal, Stack } from '@chakra-ui/react'
import { capitalize } from '@gamma-app/ui'
import { Editor } from '@tiptap/core'
import { NodeSelection, Selection } from 'prosemirror-state'
import { useEffect, useState } from 'react'

import { PresentationSelection } from './PresentationSelection'

export const SelectionDebugger = ({ editor }: { editor?: Editor }) => {
  const [selection, setSelection] = useState<Selection | null>(null)

  useEffect(() => {
    if (!editor) return
    const cb = () => {
      setSelection(editor.state.selection)
    }
    cb()
    editor.on('selectionUpdate', cb)
    return () => {
      editor.off('selectionUpdate', cb)
    }
  }, [editor])

  const isNodeSelection = selection instanceof NodeSelection
  const isPresentationSelection = selection instanceof PresentationSelection
  const label = capitalize(selection?.toJSON().type || 'none')
  const nodeType = capitalize(
    (isNodeSelection && selection?.node.type.name) || ''
  )
  const nodeSpecificColorScheme = isPresentationSelection
    ? undefined
    : isNodeSelection
    ? 'blue'
    : 'green'

  return (
    <Portal>
      <Flex
        className="selection-debugger"
        position="absolute"
        top="50px"
        left="10px"
        zIndex="999999"
      >
        <Stack direction="column">
          <Badge colorScheme={nodeSpecificColorScheme}>
            {label}
            {nodeType && <i> ({nodeType})</i>}
          </Badge>

          {selection && (
            <Badge colorScheme="gray">
              f:{selection.from} - t:{selection.to}
              {isPresentationSelection && ` | side:${selection.side}`}
            </Badge>
          )}
        </Stack>
      </Flex>
    </Portal>
  )
}
