import { Extension } from '@tiptap/core'

export const getNodeAttrsFromBookmark = (
  el: HTMLElement,
  storage: Extension['storage']
) => {
  const bookmark = el.getAttribute('bookmark')
  if (!bookmark) return false
  // Future idea: put all the bookmarks in the Sal extension
  // Not possible now because parseHTML doesn't have access to editor, only to storage.
  // const node = editor.storage[SalExtension.name].bookmarks[bookmark]
  const node = storage.bookmarks[bookmark]
  if (!node) {
    // This node had a bookmark attr, but we don't see a match in storage
    return false
  }
  return node.attrs
}
