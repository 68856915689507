import { Decoration } from 'prosemirror-view'

import { useAppSelector } from 'modules/redux'
import { isThemeDark, Theme } from 'modules/theming'
import { selectTheme } from 'modules/tiptap_editor/reducer'

import { findCardPluginDecoration } from '../CardPlugin'
// Hook for detecting whether the card you're inside of is light or dark
// Only works for node types listed in CARD_DECORATION_NODES in CardPlugin.ts
export const useCardColorMode = (decorations: Decoration[]) => {
  const theme: Theme = useAppSelector(selectTheme)
  const { isCardDark } = findCardPluginDecoration(decorations)
  return {
    theme,
    isDarkOverride: isCardDark,
    isDark: isCardDark ?? isThemeDark(theme),
  }
}
