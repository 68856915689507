import { Divider, StackProps, VStack } from '@chakra-ui/react'
import { ReactNode } from 'react'

interface PermissionsSettingsSectionProps extends StackProps {
  children: ReactNode
  showDivider?: boolean
}

export const PermissionsSettingsSection = ({
  children,
  showDivider = true,
  ...stackProps
}: PermissionsSettingsSectionProps) => {
  return (
    <>
      <VStack spacing={1} align="stretch" py={4} {...stackProps}>
        {children}
      </VStack>
      {showDivider && <Divider />}
    </>
  )
}
