import debounce from 'lodash/debounce'
import * as Y from 'yjs'

import { IndexeddbPersistence } from './y-indexeddb'

export const initIndexedDB = (
  docId: string,
  document: Y.Doc,
  onSync?: () => void
) => {
  const indexeddbPersistence = new IndexeddbPersistence(docId, document)
  const handleUpdateDebounced = debounce(() => {
    const schemaVersion = document
      .getMap('SCHEMA_VERSION')
      .get('REQUIRED_VERSION') as number | undefined
    indexeddbPersistence.set('lastUpdated', new Date().toISOString())
    indexeddbPersistence.set('schemaVersion', schemaVersion || '')
  }, 1000)
  document.on('update', handleUpdateDebounced)
  indexeddbPersistence.on('synced', () => {
    onSync?.()
  })
  return indexeddbPersistence
}
