import {
  absolutePositionToRelativePosition,
  relativePositionToAbsolutePosition,
  ySyncPluginKey,
  yUndoPluginKey,
} from '@gamma-app/y-prosemirror'
import { cloneDeep, flatMap, uniqBy } from 'lodash'
import { Node, Slice } from 'prosemirror-model'
import { EditorState, Transaction } from 'prosemirror-state'
import { Decoration, DecorationSet } from 'prosemirror-view'
import * as Y from 'yjs'

import { featureFlags } from 'modules/featureFlags'
import { findParentNodes } from 'modules/tiptap_editor'
import {
  absoluteToRelativePos,
  relativeToAbsolutePos,
} from 'modules/tiptap_editor/utils/relativePosition'

import { isCardNode } from '../../Card/utils'
import { isAnnotatableParent } from '../utils'
import { AnnotationPluginKey } from './AnnotationPluginKey'
import {
  AddAnnotationAction,
  AnnotationActions,
  AnnotationData,
  AnnotationEvent,
  AnnotationPluginParams,
  AnnotationStateEntry,
  AnnotationStateYMap,
  DropAnnotationEvent,
  JoinBackwardAnnotationEvent,
  JoinForwardAnnotationEvent,
  MergeCardsAnnotationEvent,
  MoveAnnotationEvent,
  MoveInstruction,
  ReplaceAroundStep,
  ReplaceStep,
  RestoreAnnotationsAction,
  RestoreAnnotationYKeyValue,
  SplitBlockAnnotationEvent,
  SplitCardAnnotationEvent,
  UnwrapNodeAnnotationEvent,
  UpdateNodeAttrsAnnotationEvent,
  WrapNodesAnnotationEvent,
} from './types'
import { UndoableYMapWrapper, UndoOperation } from './UndoableYMap'

export class AnnotationState {
  public decorations = DecorationSet.empty

  /**
   * purely for debug purposes, the `pos` in AnnotationData isn't guaranteed to be updated on local changes
   */
  public annotations: AnnotationData[] = []

  public map: AnnotationStateYMap

  public restoreMap: RestoreAnnotationYKeyValue

  public refreshDecorations: boolean = false

  public hasChanges: boolean = false

  protected undoableMap: UndoableYMapWrapper<AnnotationStateEntry>

  protected cutData: {
    slice: Slice
    toMove: {
      id: string
      offset: number
    }[]
  } | null = null

  protected moveInstructionMap: Record<string, MoveInstruction> = {}

  constructor(protected options: AnnotationPluginParams) {
    this.map = options.map
    this.restoreMap = options.restoreMap
    this.undoableMap = new UndoableYMapWrapper<AnnotationStateEntry>(this.map)
  }

  public persistRestoreMap(state: EditorState, force = false) {
    const perf: {
      deletion: number
      relToAbs: number
      setting: number
      total: number
    } = {
      deletion: 0,
      relToAbs: 0,
      setting: 0,
      total: 0,
    }
    const start = performance.now()
    if (!force && !this.hasChanges) {
      return
    }

    this.transactYDoc(() => {
      const deleteStart = performance.now()
      for (const { key } of this.restoreMap) {
        this.restoreMap.delete(key)
      }
      perf.deletion = performance.now() - deleteStart

      this.map.forEach(({ id, pos, data }) => {
        try {
          const relStart = performance.now()
          const absPos = this.relToAbs(state, pos)!
          perf.relToAbs += performance.now() - relStart

          const setStart = performance.now()
          this.restoreMap.set(id, {
            id,
            data,
            absPos,
          })
          perf.setting += performance.now() - setStart
        } catch (e) {
          console.error('[Annotations] persistRestoreMap error: ', e.message)
        }
      })
    })
    perf.total = performance.now() - start
    this.log(`[Annotations] persistRestoreMap in ${perf.total}ms`, {
      values: this.restoreMap.yarray.toArray(),
      performance: perf,
    })
    this.hasChanges = false
  }

  public flushMoveInstructionQueue(): MoveInstruction[] {
    const { moveInstructionMap: moveInstructionQueue } = this
    this.moveInstructionMap = {}
    return Object.values(moveInstructionQueue)
  }

  public clearCutData() {
    this.cutData = null
  }

  public getAnnotationsBetween(
    state: EditorState,
    start: number,
    end: number
  ): AnnotationData[] {
    const res: AnnotationData[] = []
    for (const entry of this.map.values()) {
      try {
        const absPos = this.relToAbs(state, entry.pos)
        // this.log(
        //   `%c[Annotations] getAnnotationBetween ${start} - ${end} ${absPos} #${entry.id}`,
        //   'color:grey'
        // )
        if (absPos === null) {
          continue
        }

        if (start <= absPos && absPos < end) {
          res.push({
            id: entry.id,
            data: entry.data,
            pos: absPos,
            relativePos: entry.pos,
          })
        }
      } catch (e) {
        console.warn('[Annotations] getAnnotationsBetween error: ', e.message)
      }
    }
    return res
  }

  public apply(transaction: Transaction, state: EditorState): this {
    // Handle remote changes
    const ystate = ySyncPluginKey.getState(state)

    // always re-render decorations for remote changes
    if (ystate.isChangeOrigin) {
      // createDecoration may fail in the case of a remote update from
      // a special case like <enter>, <backspace> or <delete>
      // swallow and expect that a correction to the annotation ymap is incoming
      try {
        this.createDecorations(state)
      } catch (e) {
        console.warn(
          `[Annotations] handle remote change, not create decorations: ${e.message}`
        )
        // swallow
      }
      return this
    }

    // Add/Remove annotations
    const annotationAction = transaction.getMeta(
      AnnotationPluginKey
    ) as AnnotationActions

    if (annotationAction) {
      switch (annotationAction.type) {
        case 'addAnnotation':
          this.addAnnotation(annotationAction, state)
          break

        case 'restoreAnnotations':
          this.restoreAnnotations(annotationAction)
          break

        case 'clearAnnotations':
          this.clearAnnotations()
          break

        case 'deleteAnnotation':
          this.deleteAnnotation(annotationAction.id)
          break

        case 'refreshAnnotationDecorations':
          // since we can't do batch updates to a Y.Map swallow errors
          // with the hope that things are "eventually right"
          try {
            this.createDecorations(state)
          } catch (e) {
            console.warn(`could not create decorations: ${e.message}`)
            // swallow
          }
          break

        case 'moveAnnotations':
          this.moveAnnotations(state, annotationAction.toMove)
          break
      }
      this.hasChanges = true
      return this
    }

    // capture the last drop transaction to keep reference to the transaction.mapping
    const uiAction = transaction.getMeta('uiEvent')
    switch (uiAction) {
      case 'cut':
        this.handleCutTransaction(state, transaction)
        break

      case 'paste':
        this.handlePasteTransaction(state, transaction)
        break
    }

    const annotationEvent = transaction.getMeta('annotationEvent') as
      | AnnotationEvent
      | undefined

    switch (annotationEvent?.type) {
      case 'split-card':
        this.handleSplitCard(state, transaction, annotationEvent)
        break

      case 'split-block':
        this.handleSplitBlock(state, transaction, annotationEvent)
        break

      case 'join-forward':
        this.handleJoinForward(state, transaction, annotationEvent)
        break

      case 'join-backward':
        this.handleJoinBackward(state, transaction, annotationEvent)
        break

      case 'update-node-attrs':
        this.handleUpdateNodeAttrs(state, transaction, annotationEvent)
        break

      case 'move':
        this.handleMove(state, transaction, annotationEvent)
        break

      case 'merge-cards':
        this.handleMergeCards(state, transaction, annotationEvent)
        break

      case 'drop':
        this.handleDropAnnotations(state, transaction, annotationEvent)
        break

      case 'unwrap-node':
        this.handleUnwrapNode(state, transaction, annotationEvent)
        break

      case 'wrap-nodes':
        this.handleWrapNodes(state, transaction, annotationEvent)
        break

      default:
        // Currently this plugin does not support transactions added via `appendTransaction` in a prosemirror plugin
        // this is because there is an intermediate state of `transaction` -> `transaction` -> `afterAllTransactions`
        // that this plugin doesnt track, thus throwing off mappings of where annotations actually live
        //
        // We get by on the assertion that appendedTransactions aren't actually updating the mapping or pushing annotations
        // around, but instead doing thigs like updating selections and setting attributes
        if (!transaction.getMeta('appendedTransaction')) {
          // check if a replace transaction happened - delete comments from emptied out blocks
          this.handleReplaceTransaction(state, transaction)
          this.handleReplaceAroundTransaction(state, transaction)
        }
    }

    // LOCAL CHANGE
    if (transaction.docChanged) {
      this.hasChanges = true
    }

    return this.handleLocalChange(transaction)
  }

  public restore(
    state: EditorState,
    queue: UndoOperation<AnnotationStateEntry>[]
  ) {
    queue.forEach((op) => {
      const { next, key } = op
      if (next === undefined) {
        this.map.delete(key)
      } else {
        this.map.set(key, {
          ...next,
          pos: this.refreshRelativePos(state, next.pos),
        })
      }
    })
    this.persistRestoreMap(state, true)
  }

  protected handleUnwrapNode(
    state: EditorState,
    tr: Transaction,
    { pos }: UnwrapNodeAnnotationEvent
  ) {
    const $pos = tr.before.resolve(pos)
    const start = $pos.start($pos.depth + 1)
    const end = tr.before.resolve(start).end()

    const toMove: MoveInstruction[] = this.getAnnotationsBetween(
      state,
      start,
      end
    )
      .map(({ id, relativePos }) => {
        const annotationPos = this.relToAbs(state, relativePos)
        if (annotationPos === null) {
          return null
        }

        const offset = annotationPos - start

        return {
          id,
          newPos: pos + offset,
        }
      })
      .filter((a): a is MoveInstruction => !!a)

    this.log('%c[Annotations] unwrap node', 'color: green', {
      tr,
      pos,
      start,
      end,
      toMove,
    })
    if (toMove.length > 0) {
      this.enqueueMoveInstructions(toMove)
    }
  }

  protected handleWrapNodes(
    state: EditorState,
    tr: Transaction,
    { start, end, level }: WrapNodesAnnotationEvent
  ) {
    const toMove: MoveInstruction[] = this.getAnnotationsBetween(
      state,
      start,
      end
    )
      .map(({ id, pos }) => {
        return {
          id,
          newPos: pos + level,
        }
      })
      .filter((a): a is MoveInstruction => !!a)

    this.log('%c[Annotations] wrap nodes', 'color: green', {
      tr,
      start,
      end,
      toMove,
    })
    if (toMove.length > 0) {
      this.enqueueMoveInstructions(toMove)
    }
  }

  protected handleMergeCards(
    state: EditorState,
    tr: Transaction,
    { insertPos, contentPos }: MergeCardsAnnotationEvent
  ) {
    // use before here to get the two cards before they've been merged
    const contentWrapper = tr.before.nodeAt(contentPos)
    if (!contentWrapper) {
      console.error(
        `[AnnotationState] trying to merge cards, but cannot resolve content wrapper`
      )
      return
    }
    const toMove = this.getAnnotationsBetween(
      state,
      contentPos,
      contentPos + contentWrapper.nodeSize
    ).map<MoveInstruction>(({ id, pos }) => {
      const offset = pos - contentPos - 1
      // -1 since we are mapping over a wrapper and "unwrapping" the content
      // in the insertPos, eg depth--
      return {
        id,
        newPos: insertPos + offset,
      }
    })

    this.log('%c[Annotations] merge cards', 'color: green', {
      transaction: tr,
      insertPos,
      contentPos,
      toMove,
    })
    if (toMove.length > 0) {
      this.enqueueMoveInstructions(toMove)
    }
  }

  protected handleMove(
    state: EditorState,
    transaction: Transaction,
    { insertPos, insertPosRaw, pos, end }: MoveAnnotationEvent
  ) {
    const annotationsInCard = this.getAnnotationsBetween(
      state,
      pos,
      end
    ).map<MoveInstruction>(({ id, pos: p }) => {
      // If the annotation is at the block level (where pos - $next.pos === 0) then use 0
      // otherwise use the textContent offset since the block will be joined
      return {
        id,
        newPos: insertPos + (p - pos),
      }
    })
    // we need to handle mapping.map over which cards the moved card is "jumping over"
    // ex 1: moving up
    // original
    //   - card1
    //   - card2
    //   - card3
    // OPERATION: card3 moves above card2
    // new
    //   - card1
    //   - card3
    //   - card2
    //
    // ex 2: moving down
    // original
    //   - card1
    //   - card2
    //   - card3
    // OPERATION: card1 moves below card3
    // new
    //   - card2
    //   - card3
    //   - card1
    const jumpedAnnotations = this.getAnnotationsBetween(
      state,
      Math.min(insertPosRaw, end),
      Math.max(insertPosRaw, pos)
    ).map<MoveInstruction>(({ id, pos: p }) => {
      return {
        id,
        newPos: transaction.mapping.map(p),
      }
    })

    this.log('%c[Annotations] move', 'color: green', {
      transaction,
      insertPos,
      insertPosRaw,
      pos,
      end,
      annotationsInCard,
      jumpedAnnotations,
    })
    const toMove = [...annotationsInCard, ...jumpedAnnotations]
    if (toMove.length > 0) {
      this.enqueueMoveInstructions(toMove)
    }
  }

  protected handleUpdateNodeAttrs(
    state: EditorState,
    transaction: Transaction,
    { pos }: UpdateNodeAttrsAnnotationEvent
  ) {
    const node = transaction.doc.nodeAt(pos)
    if (!node) {
      return
    }
    const toMove = this.getAnnotationsBetween(
      state,
      pos,
      pos + node.nodeSize
    ).map<MoveInstruction>(({ id, pos: newPos }) => {
      return {
        id,
        newPos,
      }
    })
    this.log('%c[Annotations] update node attrs', 'color: green', {
      transaction,
      pos,
      toMove,
    })
    if (toMove.length > 0) {
      this.enqueueMoveInstructions(toMove)
    }
  }

  protected handleJoinBackward(
    state: EditorState,
    transaction: Transaction,
    { atBeginning, joinPos }: JoinBackwardAnnotationEvent
  ) {
    if (!atBeginning) {
      return
    }

    const $joinPos = transaction.before.resolve(joinPos)
    const $next = transaction.before.resolve($joinPos.before())
    // <p>block 1</p>       prevBlock
    // <p>|block 2</p>      nextBlock
    //    ^
    //    delete pressed here
    //
    // RESULT:
    // <p>block 1block 2</p> joinedBlock
    const prevBlock = $next.nodeBefore
    const nextBlock = $next.nodeAfter
    if (!prevBlock || !nextBlock) {
      return
    }

    const nextBlockEnd = $next.pos + nextBlock.nodeSize
    const prevBlockPos = $next.pos - prevBlock.nodeSize

    // selection is now updated to last text content position of the prev block
    const joinPoint = state.selection.from

    this.log('%c[Annotations] join backward', 'color: green', {
      transaction,
      prevBlock,
      nextBlock,
      nextBlockPos: $next.pos,
      nextBlockEnd,
      prevBlockPos,
      joinPoint,
    })

    const nextBlockAnnotations = this.getAnnotationsBetween(
      state,
      $next.pos,
      nextBlockEnd
    )
    if (nextBlockAnnotations.length === 0) {
      this.enqueueRefreshDecorations()
      return
    }

    if (nextBlock.content.size === 0) {
      // joinBackward will simply delete the block
      nextBlockAnnotations.forEach(({ id }) => this.undoableMap.delete(id))
      // wait for the transaction to settle and write to undo stack
      requestAnimationFrame(() => {
        this.writeUndoStack(state)
      })
    } else {
      this.enqueueMoveInstructions(
        nextBlockAnnotations.map<MoveInstruction>(({ id, pos }) => {
          // If the annotation is at the block level (where pos - $next.pos === 0) then use 0
          // otherwise use the textContent offset since the block will be joined
          const offset = Math.max(pos - $next.pos - 1, 0)
          // if prevBlock has no content then join content at the block level
          const startingPoint =
            prevBlock.content.size === 0 ? prevBlockPos : joinPoint
          return {
            id,
            newPos: startingPoint + offset,
          }
        })
      )
    }
  }

  protected handleJoinForward(
    state: EditorState,
    tr: Transaction,
    { atEnd, joinPos }: JoinForwardAnnotationEvent
  ) {
    if (!atEnd) {
      return
    }
    //   delete pressed here
    //           |
    //           v
    // <p>block 1|</p>       prevBlock
    // <p>block 2</p>        nextBlock
    //
    // RESULT:
    // <p>block 1block 2</p> joinedBlock
    const $joinPos = tr.before.resolve(joinPos)
    const { pos: prevPos, node: prevBlock } = findParentNodes(
      $joinPos,
      (a) => a.isBlock
    )[0]!
    const $next = tr.before.resolve($joinPos.after())
    const nextBlock = $next.nodeAfter
    if (!nextBlock) {
      return
    }
    const nextBlockEnd = $next.pos + nextBlock.nodeSize

    this.log('%c[Annotations] join forward', 'color: green', {
      sel: state.selection,
      prevBlock,
      transaction: tr,
      deleteAnnotations: !!nextBlock.isAtom,
      joinPos: $joinPos.pos,
      prevBlockPos: prevPos,
      nextBlockPos: $next.pos,
      nextBlock,
    })

    const prevBlockAnnotations = this.getAnnotationsBetween(
      state,
      prevPos,
      prevPos + prevBlock.nodeSize
    )

    const nextBlockAnnotations = this.getAnnotationsBetween(
      state,
      $next.pos,
      nextBlockEnd
    )

    // nothing to move, enqueue refresh decorations to allow RelativePosition to update
    // decoration positions
    if (
      nextBlockAnnotations.length === 0 &&
      prevBlockAnnotations.length === 0
    ) {
      this.enqueueRefreshDecorations()
      return
    }

    // helper function to delete annotations and write to undo stack
    const deleteAnnotations = (toDelete: AnnotationData[]) => {
      toDelete.forEach(({ id }) => this.undoableMap.delete(id))
      // wait for the transaction to settle and write to undo stack
      requestAnimationFrame(() => {
        this.writeUndoStack(state)
      })
    }

    if (nextBlock.isAtom && prevBlock.content.size > 0) {
      // if the next block is an atom, and prevBlock is non-empty, then joinforward will delete it,
      // thus we should also delete the annotations
      // see: https://github.com/ProseMirror/prosemirror-commands/blob/master/src/commands.js#L56-L59
      deleteAnnotations(nextBlockAnnotations)
    } else if (prevBlock.content.size === 0) {
      // if the previous block is empty and a joinForward already happened then it will delete the previous block
      deleteAnnotations(prevBlockAnnotations)
    } else {
      this.enqueueMoveInstructions(
        nextBlockAnnotations.map<MoveInstruction>(({ id, pos }) => {
          // If the annotation is at the block level (where pos - $next.pos === 0) then use 0
          // otherwise use the textContent offset since the block will be joined
          const offset = Math.max(pos - $next.pos - 1, 0)
          // if prevBlock has no content then join content at the block level
          const startingPoint = prevBlock.content.size === 0 ? prevPos : joinPos
          return {
            id,
            newPos: startingPoint + offset,
          }
        })
      )
    }
  }

  protected handleSplitBlock(
    state: EditorState,
    transaction: Transaction,
    { splitPos, atBeginning }: SplitBlockAnnotationEvent
  ) {
    if (atBeginning) {
      this.enqueueRefreshDecorations()
      return
    }

    const $before = transaction.before.resolve(splitPos)
    const { pos: beforeParentPos, node: beforeParentNode } = findParentNodes(
      $before,
      (a) => a.isBlock
    )[0]!
    const { $from } = state.selection
    const nextBlock = findParentNodes($from, (a) => a.isBlock)[0]!
    const toMove = this.getAnnotationsBetween(
      state,
      splitPos,
      beforeParentPos + beforeParentNode.nodeSize
    ).map<MoveInstruction>(({ id, pos }) => {
      const offset = pos - splitPos
      return {
        id,
        newPos: nextBlock.pos + (offset === 0 ? offset : offset + 1),
      }
    })

    if (toMove.length > 0) {
      this.enqueueMoveInstructions(toMove)
    } else {
      this.enqueueRefreshDecorations()
    }

    this.log('%c[Annotations] split block', 'color: green', {
      sel: state.selection,
      transaction,
      splitPos,
      toMove,
      $before,
    })
  }

  protected handleSplitCard(
    state: EditorState,
    transaction: Transaction,
    event: SplitCardAnnotationEvent
  ) {
    const { splitPos } = event
    // use before here to get content of card before any removal
    const $splitPos = transaction.before.resolve(splitPos)
    const parentCard = findParentNodes($splitPos, isCardNode)[0]
    if (!parentCard) {
      return
    }
    const parentPos = parentCard.pos
    const parentEnd = parentCard.pos + parentCard.node.nodeSize
    const toMove = this.getAnnotationsBetween(
      state,
      parentPos,
      parentEnd
    ).map<MoveInstruction>(({ id, pos }) => {
      return {
        id,
        newPos:
          pos === splitPos
            ? // for items right on the split position, they actually will end up in the next card, but the mapping
              // only starts at +1, so (+1 then -1) to get the right position
              transaction.mapping.map(pos + 1) - 1
            : transaction.mapping.map(pos),
      }
    })

    this.log('%c[Annotations] split card', 'color: green', {
      transaction,
      splitPos,
      parentEnd,
      toMove,
    })

    this.enqueueMoveInstructions(toMove)
  }

  protected handlePasteTransaction(
    state: EditorState,
    transaction: Transaction
  ) {
    if (!this.cutData) {
      return
    }
    const replaceStep = transaction.steps.find(
      (step: any): step is ReplaceStep => step.jsonID === 'replace'
    )
    if (!replaceStep) {
      return
    }
    const {
      from: insertFrom,
      to: insertTo,
      slice: { openStart },
    } = replaceStep
    const resolved = state.doc.resolve(replaceStep.from)
    const insertPos = insertTo === insertFrom ? insertFrom : insertFrom + 1
    // if pasting at the beginning of a block
    // used to determine if should be pasted at the parent block's pos
    const isPasteBeginning =
      resolved.nodeBefore == null && resolved.parent.isBlock
    const respectNegativeOffset = !openStart || isPasteBeginning

    this.log('%c[Annotations] paste transaction', 'color: green', {
      transaction,
      insertPos: insertFrom,
      resolved,
      isPasteBeginning,
    })

    const moveInstructions = this.cutData?.toMove.map<MoveInstruction>(
      ({ id, offset }) => {
        return {
          id,
          newPos:
            insertPos + (respectNegativeOffset ? offset : Math.max(0, offset)),
        }
      }
    )
    if (moveInstructions && moveInstructions.length > 0) {
      this.log('[Annotations] paste final move', moveInstructions)
      this.enqueueMoveInstructions(moveInstructions)
    }
  }

  /**
   * When a cut happens find any annotations within the range being replaced
   * Store their ids and offset positions (from the beginning of the cut) so when
   * they are pasted back new relative positions can be created
   */
  protected handleCutTransaction(state: EditorState, transaction: Transaction) {
    const replaceStep = transaction.steps.find(
      (step: any): step is ReplaceStep => step.jsonID === 'replace'
    )
    if (!replaceStep) {
      return
    }
    const { from, to } = replaceStep as any
    const resolved = state.doc.resolve(from)
    const isWholeBlock =
      resolved.nodeAfter == null &&
      resolved.nodeBefore == null &&
      resolved.parent.isBlock

    const annotations = this.getAnnotationsBetween(
      state,
      from - (isWholeBlock ? 1 : 0),
      to
    )
    const moveData = annotations.map<{ id: string; offset: number }>((a) => {
      const absPos = this.relToAbs(state, a.relativePos)!
      return {
        id: a.id,
        offset: absPos - from,
      }
    })

    this.cutData = {
      slice: replaceStep.slice,
      toMove: moveData,
    }
    this.log('%c[Annotations] cut transaction', 'color:green', {
      isWholeBlock,
      resolved,
      transaction,
      annotations,
      moveData,
    })
  }

  /**
   * Handles the case where a `ReplaceStep` is used to replace a block start by
   * { from: "pos of block", to: "first pos in block"}
   *
   * We use this when inserting content via the slash menu
   */
  protected handlePrependContentToBlock(
    state: EditorState,
    tr: Transaction
  ): void {
    const replaceStep = tr.steps.find(
      (step: any): step is ReplaceStep => step.jsonID === 'replace'
    )
    if (!replaceStep) {
      return
    }
    const { from } = replaceStep
    const $from = tr.before.resolve(from)
    if (!$from.nodeAfter) {
      return
    }

    // find any annotations in the block that is getting prepended to
    const annotationsToMove = this.getAnnotationsBetween(
      state,
      from,
      from + $from.nodeAfter.nodeSize
    ).map<MoveInstruction>(({ id, relativePos }) => {
      const pos = this.relToAbs(state, relativePos)!
      const newPos =
        pos === from
          ? // use map(pos + 1), because map(pos) is the newly inserted node, not the
            // node that is being pushed down by the new content
            tr.mapping.map(pos + 1) - 1
          : tr.mapping.map(pos)

      return {
        id,
        newPos,
      }
    })

    if (annotationsToMove.length > 0) {
      this.log(
        '%c[Annotations] replace transaction - prepend content',
        'color:green',
        {
          tr,
          annotationsToMove,
        }
      )
      this.enqueueMoveInstructions(annotationsToMove)
    }
  }

  /**
   * When does replace transaction happen
   * 1. typing content (no selection)
   * 2. deleting content (no selection)
   * 3. pasting content (no selection)
   * 4. typing content (selection)
   * 5. deleting content (selection)
   * 6. pasting content (selection)
   *
   * @param state When
   * @param tr
   * @returns
   */
  protected handleReplaceTransaction(
    state: EditorState,
    tr: Transaction
  ): void {
    const replaceStep = tr.steps.find(
      (step: any): step is ReplaceStep => step.jsonID === 'replace'
    )
    if (!replaceStep) {
      return
    }
    const { from, to } = replaceStep as any
    const $from = tr.before.resolve(from)
    const $to = tr.before.resolve(to)

    const isMultiline = $from.parent !== $to.parent
    const replacingWithNothing = replaceStep.slice.content.size == 0
    const replacingEntireFromBlock =
      // use - 2 here to account for parent open and close tags
      // childContentSize = $from.parent.nodeSize - 2
      $from.parentOffset === 0 && to >= from + $from.parent.nodeSize - 2
    const isWholeBlock = replacingEntireFromBlock && replacingWithNothing

    // this occurs when we try to insert content to replace { from: "pos of block", to: "first pos in block"}
    // this happens for any slash command or `insertContentAndSelect` where the $from.parentOffset === 0
    // we do this to not have an empty line above the newly inserted content
    const isPrependingToBlock =
      $to.parentOffset === 0 &&
      $to.depth === $from.depth + 1 &&
      $to.pos - $from.pos === 1

    if (isPrependingToBlock) {
      return this.handlePrependContentToBlock(state, tr)
    }

    // use state.doc.resolve to look at the current doc model
    // if the from position resolves to an empty text selection with no sibling text
    // then we know the replacement was a delete over the entire block
    // const $nowFrom = state.doc.resolve(from)
    // const isWholeBlock =
    //   $nowFrom.nodeAfter == null &&
    //   $nowFrom.nodeBefore == null &&
    //   $nowFrom.parent.isBlock

    //  $from.parent
    //  |           replace range from
    //  |                |
    //  v                v
    // <p>content before | between</p>
    // <p>middle content</p>
    // <p>between| after</p>
    //  ^        ^ ️
    //  |        | replace range to
    //  |
    //  $to.parent
    //
    // Heres the plan:
    // take annotations in [from - $from.parentOffset - 1, from] and create new relative positions
    // take annotations in [to, $to.nodeAfter?.nodeSize] and create new relative positions
    // take annotations in deleted selection and delete their relative positions

    let didOperation = false
    if (isMultiline) {
      // isWholeBlock means that the from block is fully selected
      // we dont want to recreate annotations on that block's pos
      const beforeToMove = isWholeBlock
        ? []
        : this.getAnnotationsBetween(
            state,
            // - 1 to get the parent blocks pos (not the first index)
            from - $from.parentOffset - 1,
            from
          ).map<MoveInstruction>(({ id, relativePos }) => {
            return {
              id,
              newPos: this.relToAbs(state, relativePos)!,
            }
          })

      const afterToMove = this.getAnnotationsBetween(
        state,
        // TODO: should this be to - 1?
        to,
        // not really sure why we need to add one here but to+nodeSize comes up short 1
        to + $to.nodeAfter?.nodeSize! + 1
      ).map<MoveInstruction>(({ id, relativePos }) => {
        return {
          id,
          newPos: tr.mapping.map(this.relToAbs(state, relativePos)!),
        }
      })

      const multilineToMove = [...beforeToMove, ...afterToMove]
      this.log('[Annotations] multiline to move', multilineToMove)
      if (multilineToMove.length > 0) {
        didOperation = true
        this.enqueueMoveInstructions(multilineToMove)
      }
    }

    // anotations annotations that we're in selection that will be deleted
    const annotationsToDelete = this.getAnnotationsBetween(
      state,
      // if selecting all content in entire block delete annotation attached to that block's pos
      // delete the annotation attached to the from position if the selection is entire block and from.parentOffset is 0
      from -
        (isWholeBlock || (isMultiline && $from.parentOffset === 0) ? 1 : 0),
      to
    )

    if (annotationsToDelete.length > 0) {
      didOperation = true
      this.log('[Annotations] to delete', annotationsToDelete)
      annotationsToDelete.forEach((a) => {
        this.undoableMap.delete(a.id)
      })
    }

    if (didOperation) {
      this.log('%c[Annotations] replace transaction', 'color:green', {
        tr,
        annotationsToDelete,
        isWholeBlock,
        isMultiline,
        replacingEntireFromBlock,
        replacingWithNothing,
      })
      // wait for the transaction to settle and write to undo stack
      requestAnimationFrame(() => {
        this.writeUndoStack(state)
      })
    }
  }

  /**
   * When tiptap calls commands.setNode() or commands.setNodeMarkup a prosemirror transform of `setNodeMarkup` or `setBlockType`
   * is created, containing a ReplaceAroundStep
   *
   * This step takes replaces a `from` `to` selection with a new slice and thus breaks relative positions
   *
   * We can hook into that transaction and ReplaceAroundStep and find any annotations within the selection
   * and remake their relative positions with transaction.mapping.map(annotation.oldPos)
   *
   * @returns {boolean} true if any annotations were moved
   */
  protected handleReplaceAroundTransaction(
    state: EditorState,
    tr: Transaction
  ): boolean {
    const filteredSteps = flatMap(
      tr.steps.filter(
        // use any here because the types for ReplaceAroundStep are not accurate with to and from included
        // see source: https://github.com/ProseMirror/prosemirror-transform/blob/master/src/replace_step.js#L80
        (step: any): step is ReplaceAroundStep =>
          step.jsonID === 'replaceAround'
      ),
      ({ from, to }) => this.getAnnotationsBetween(state, from, to)
    )
    const moveInstructions = uniqBy(
      filteredSteps,
      (annotationData) => annotationData.id
    ).map<MoveInstruction>(({ id, pos }) => ({
      id,
      newPos: tr.mapping.map(pos),
    }))

    if (moveInstructions.length > 0) {
      this.log('%c[Annotations] replaceAround transaction', 'color:green', {
        tr,
        moveInsturctions: moveInstructions,
      })
    }
    if (moveInstructions.length === 0) {
      return false
    }

    // don't move immediately becuase the ySync binding.mapping hasn't been updated for this
    // transaction yet, instead queue up and process as soon as possible
    this.enqueueMoveInstructions(moveInstructions)
    return true
  }

  protected clearAnnotations() {
    this.transactYDoc(() => {
      this.map.forEach((_val, key) => {
        this.map.delete(key)
      })
    })
  }

  protected restoreAnnotations(action: RestoreAnnotationsAction) {
    this.log(`%c[Annotations] restoreAnnotations`, `color: green`, action)
    const { toRestore } = action

    this.clearAnnotations()
    this.enqueueMoveInstructions(
      toRestore
        .filter(({ absPos }) => absPos != null)
        .map(({ absPos, id }) => ({
          id,
          newPos: absPos,
        }))
    )
  }

  protected addAnnotation(action: AddAnnotationAction, state: EditorState) {
    this.log(`%c[Annotations] addAnnotation`, `color: green`, action)
    const { pos, id, data } = action
    const relativePos = this.absToRel(state, pos)
    this.map.set(id, {
      id,
      pos: relativePos,
      data,
    })
  }

  protected deleteAnnotation(id: string) {
    this.log(`%c[Annotations] deleteAnnotation`, `color: green`, id)
    this.map.delete(id)
  }

  /**
   * Moves a set of annotations, this should only be called once per editor transaction since
   * it mutates the last item on the undoStack, if called more than once it will override undoStack
   */
  protected moveAnnotations(state: EditorState, toMove: MoveInstruction[]) {
    const { map } = this

    this.log(`%c[Annotations] moveAnnotations`, `color: green`, toMove)
    this.transactYDoc(() => {
      toMove.forEach(({ id, newPos }) => {
        // update decoration position
        const existing = map.get(id) || { id }
        this.undoableMap.set(id, {
          ...existing,
          pos: this.absToRel(state, newPos),
        })
      })
    })
    this.writeUndoStack(state)
  }

  protected writeUndoStack(state: EditorState) {
    // NOTE(jordan): this is kind of hacky for this plugin state to reach into
    // another plugin, but it seems to be the simplest way

    const undoManager: Y.UndoManager =
      yUndoPluginKey.getState(state).undoManager

    if (undoManager.undoStack.length === 0) {
      return
    }

    const serialized = this.undoableMap.flushUndoQueue()
    if (serialized.length === 0) {
      // nothing to write
      return
    }

    const stackItem = undoManager.undoStack[undoManager.undoStack.length - 1]
    const key = 'annotations'
    const existing = stackItem.meta.get(key) || []
    if (existing.length > 0) {
      this.log('[Annotations] existing undo stack', {
        existing,
        toWrite: serialized,
        stack: undoManager.undoStack,
      })
    }
    const toWrite = [...serialized, ...existing]
    stackItem.meta.set(key, toWrite)
    this.log('[Annotations] writing to undo stack', toWrite)
  }

  protected createDecorations(state: EditorState) {
    const { map } = this.options
    this.refreshDecorations = false
    const decorations: Decoration[] = []
    const annotations: AnnotationData[] = []

    map.forEach((annotation, key) => {
      const pos = relativeToAbsolutePos(state, annotation.pos)

      if (pos == null) {
        return
      }

      const node = state.doc.nodeAt(pos)
      if (node) {
        if (node?.isInline) {
          decorations.push(
            Decoration.inline(pos, pos + 1, {
              class: 'debug-comment-cursor',
            })
          )
        } else {
          decorations.push(
            Decoration.node(pos, pos + node.nodeSize, {
              class: 'debug-comment-block',
            })
          )
        }
      }

      annotations.push({
        id: key,
        data: annotation.data,
        relativePos: annotation.pos,
        pos,
      })
    })
    state.doc.descendants((node: Node, pos: number, parent: Node) => {
      // Prevent recursing down into blocks that aren't annotatable for performance,
      if (
        pos > 0 && // Don't skip the top level document node, or we'll never reach anything
        !isAnnotatableParent(parent) &&
        !isAnnotatableParent(node)
      ) {
        return false
      }

      // Find annotations inside
      const annotationsInBlock = annotations.filter(
        (annotation) =>
          annotation.pos >= pos && annotation.pos < pos + node.nodeSize
      )
      annotationsInBlock.forEach(({ id, data }) => {
        decorations.push(
          Decoration.node(
            pos,
            pos + node.nodeSize,
            {}, // classes
            {
              isAnnotation: true,
              id,
              data,
            }
          )
        )
      })
      return true
    })

    this.decorations = DecorationSet.create(state.doc, decorations)
    this.annotations = annotations
  }

  protected handleDropAnnotations(
    state: EditorState,
    tr: Transaction,
    event: DropAnnotationEvent
  ): void {
    const {
      dragging: { origNodePos, inBlock, inCard },
      droppedBlockPos,
    } = event

    const toMove: MoveInstruction[] = []
    inBlock.forEach((a) => {
      // check the annotation offset position
      // with respect to the node being moved
      const offset = a.pos - origNodePos
      const newPos = droppedBlockPos + offset
      toMove.push({
        id: a.id,
        newPos,
      })
    })
    // map any annotation at the droppedBlockPos
    // this case matters when dragging something from out of card
    this.getAnnotationsBetween(state, droppedBlockPos, droppedBlockPos + 1)
      // filter annotations that have already been moved
      .filter((a) => !toMove.find((b) => b.id === a.id))
      .forEach((a) => {
        toMove.push({
          id: a.id,
          newPos: tr.mapping.map(a.pos),
        })
      })

    inCard
      // filter annotations that have already been moved
      .filter((a) => !toMove.find((b) => b.id === a.id))
      .forEach((a) => {
        // use pos (absolute position) here because we can't
        // trust RelativePositions once a drag and drop move
        // has occurred
        const mappedPos = tr.mapping.map(a.pos)
        // always move the annotation even if newPos === mappedPos
        // this is because we can have a uiEvent drop where the Transaction
        // contains a ReplaceStep that would delete the annotation on a Gallery node
        // when moving an image out.
        toMove.push({
          newPos: mappedPos,
          id: a.id,
        })
      })

    this.log(`%c[Annotations] handleDropAnnotations`, `color: green`, {
      action: event,
      tr,
      toMove,
    })

    if (toMove.length > 0) {
      this.enqueueMoveInstructions(toMove)
    }
  }

  protected handleLocalChange(transaction: Transaction): this {
    const movedDecorations = [
      ...this.decorations.find(
        undefined,
        undefined,
        (spec) => spec.isAnnotation && this.moveInstructionMap[spec.id]
      ),
    ]
    const mappedDecorations = this.decorations
      .remove(
        cloneDeep(movedDecorations) // This prevents the original decorations from being mutated by the remove call
      )
      .map(transaction.mapping, transaction.doc)
      .add(transaction.doc, movedDecorations)

    this.decorations = mappedDecorations
    return this
  }

  // helpers for relative position
  protected absToRel(state: EditorState, abs: number): Y.RelativePosition {
    const relative = absoluteToRelativePos(state, abs)
    if (!relative) {
      throw new Error('Y.State non initialized')
    }
    return relative
  }

  public relToAbs(state: EditorState, pos: Y.RelativePosition): number | null {
    return relativeToAbsolutePos(state, pos)
  }

  /**
   * This is a hacky function to create a relative position at the most up to date ID of the element
   * This works because we convert it to a abs pos (number) and then map it back to the current ID
   * in absoluteToRelativePosition
   */
  protected refreshRelativePos(
    state: EditorState,
    pos: Y.RelativePosition
  ): Y.RelativePosition {
    const ystate = ySyncPluginKey.getState(state)
    const { doc, type, binding } = ystate
    const abs = relativePositionToAbsolutePosition(
      doc,
      type,
      pos,
      binding.mapping
    )!
    return absolutePositionToRelativePosition(abs, type, binding.mapping)
  }

  public enqueueMoveInstructions(toMove: MoveInstruction[]) {
    toMove.forEach((instruction) => {
      const existing = this.moveInstructionMap[instruction.id]
      if (existing) {
        console.error(
          `[AnnotationState] trying to enqueue two move instructions for annotation with targetId=${instruction.id}`
        )
      }
      this.moveInstructionMap[instruction.id] = instruction
    })
  }

  protected enqueueRefreshDecorations() {
    this.refreshDecorations = true
  }

  protected transactYDoc(fn: (tr: Y.Transaction) => void) {
    this.options.document.transact(fn)
  }

  protected log(...args: any) {
    featureFlags.get('debugComments')
      ? console.log(...args)
      : console.debug(...args)
  }
}
