import { CheckIcon } from '@chakra-ui/icons'
import {
  Button,
  ButtonGroup,
  IconButton,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Text,
  VStack,
} from '@chakra-ui/react'
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'
import { Editor } from '@tiptap/core'
import { useCallback } from 'react'

import { useFeatureFlag } from 'modules/featureFlags'
import { hasOneUniqueAttributeForMark } from 'modules/tiptap_editor/utils/attributes'
import { preventDefaultToAvoidBlur } from 'utils/handlers'

import { ToolbarButtonProps } from '../../components/menus/buttons/ToolbarButton'
import { focusEditorOnMenuClose } from '../../components/menus/FormattingMenus/utils'
import { HighlightColorOptions, highlightColors } from './highlightStyles'
import { TextColorOptions, textColors } from './textColorStyles'

type TextColorMenuProps = Omit<ToolbarButtonProps, 'onClick'> & {
  editor: Editor
}

const useTextColorMenu = ({ editor }: { editor: Editor }) => {
  const isDark = false // Always use the light version in menu
  const textColorOptions = isDark ? textColors.dark : textColors.light
  const highlightColorOptions = isDark
    ? highlightColors.dark
    : highlightColors.light

  // Values should be false if no highlight is applied, and null if there's a mix of styles
  const { selection, doc, schema } = editor.state
  const { from, to } = selection
  const textColorValue = doc.rangeHasMark(from, to, schema.marks.highlight)
    ? null
    : !doc.rangeHasMark(from, to, schema.marks.textColor)
    ? false
    : hasOneUniqueAttributeForMark<keyof TextColorOptions>(
        editor.state,
        'textColor',
        'variant'
      ) || null
  const highlightValue = doc.rangeHasMark(from, to, schema.marks.textColor)
    ? null
    : !doc.rangeHasMark(from, to, schema.marks.highlight)
    ? false
    : hasOneUniqueAttributeForMark<keyof HighlightColorOptions>(
        editor.state,
        'highlight',
        'variant'
      ) || null

  const textColorEnabled = useFeatureFlag('textColor')
  const canSetTextColor = textColorValue || textColorEnabled

  const setHighlightColor = useCallback(
    (variant: string) =>
      editor
        .chain()
        .focus()
        .setHighlightVariant({
          variant,
        })
        .run(),
    [editor]
  )
  const setTextColor = useCallback(
    (variant: string) =>
      editor
        .chain()
        .focus()
        .setTextColorVariant({
          variant,
        })
        .run(),
    [editor]
  )

  const resetColorAndHighlight = useCallback(() => {
    editor.chain().focus().unsetMark('textColor').unsetMark('highlight').run()
  }, [editor])

  return {
    canSetTextColor,
    highlightColorOptions,
    highlightValue: highlightValue as typeof highlightValue,
    resetColorAndHighlight,
    setHighlightColor,
    setTextColor,
    textColorEnabled,
    textColorValue: textColorValue as typeof textColorValue,
    textColorOptions,
  }
}

export const TextColorMenu = ({
  editor,
  testId,
  disabled,
}: TextColorMenuProps) => {
  const {
    textColorOptions,
    highlightColorOptions,
    textColorValue,
    highlightValue,
    textColorEnabled,
    canSetTextColor,
    setHighlightColor,
    setTextColor,
    resetColorAndHighlight,
  } = useTextColorMenu({ editor })

  return (
    <Menu isLazy onClose={() => focusEditorOnMenuClose(editor)}>
      <GammaTooltip
        placement="top"
        label={textColorEnabled ? 'Text color...' : 'Highlight color...'}
      >
        <MenuButton
          isDisabled={disabled}
          as={Button}
          variant="toolbar"
          rightIcon={
            <FontAwesomeIcon
              icon={regular('chevron-down')}
              transform="shrink-6"
            />
          }
          data-testid={testId}
          onMouseDown={preventDefaultToAvoidBlur}
        >
          <FontColorIcon
            highlightColor={
              highlightValue
                ? highlightColorOptions[highlightValue].hex
                : undefined
            }
            color={
              textColorValue ? textColorOptions[textColorValue]?.hex : undefined
            }
          />
        </MenuButton>
      </GammaTooltip>
      <MenuList
        maxH="min(25em, 45vh)"
        overflowY="auto"
        sx={{
          '[role=menuitem]': {
            py: '0.5',
          },
        }}
      >
        <MenuGroup key="none" title="No color">
          <MenuItem
            key={'default'}
            icon={
              <FontColorIcon
                fixedWidth
                isChecked={textColorValue === false && highlightValue === false}
              />
            }
            onClick={resetColorAndHighlight}
          >
            Default
          </MenuItem>
        </MenuGroup>
        {canSetTextColor && (
          <MenuGroup key="text-color" title="Text color">
            {Object.entries(textColorOptions).map(([key, { hex, name }]) => {
              return (
                <MenuItem
                  key={key}
                  icon={
                    <FontColorIcon
                      color={hex}
                      fixedWidth
                      isChecked={key === textColorValue}
                    />
                  }
                  onClick={() => setTextColor(key)}
                >
                  {name}
                </MenuItem>
              )
            })}
          </MenuGroup>
        )}
        <MenuGroup key="highlight" title="Highlight color">
          {Object.entries(highlightColorOptions).map(([key, { hex, name }]) => {
            if (!name) return
            return (
              <MenuItem
                key={key}
                value={key}
                icon={
                  <FontColorIcon
                    highlightColor={hex}
                    fixedWidth
                    isChecked={key === highlightValue}
                  />
                }
                onClick={() => setHighlightColor(key)}
              >
                {name} highlight
              </MenuItem>
            )
          })}
        </MenuGroup>
      </MenuList>
    </Menu>
  )
}

export const AITextColorPicker = ({ editor }: { editor: Editor }) => {
  const {
    textColorOptions,
    highlightColorOptions,
    textColorValue,
    highlightValue,
    textColorEnabled,
    canSetTextColor,
    setHighlightColor,
    setTextColor,
    resetColorAndHighlight,
  } = useTextColorMenu({ editor })

  return (
    <VStack>
      <Text>Text colors</Text>
      <ButtonGroup>
        {Object.entries(textColorOptions).map(([key, { hex, name }]) => {
          return (
            <IconButton
              aria-label={key}
              key={key}
              size="sm"
              icon={
                <FontColorIcon
                  color={hex}
                  fixedWidth
                  isChecked={key === textColorValue}
                />
              }
              onClick={() => setTextColor(key)}
            />
          )
        })}
      </ButtonGroup>
      <Text>Highlight colors</Text>
      <ButtonGroup>
        {Object.entries(highlightColorOptions).map(([key, { hex, name }]) => {
          if (!name) return
          return (
            <IconButton
              key={key}
              aria-label={key}
              value={key}
              size="sm"
              icon={
                <FontColorIcon
                  highlightColor={hex}
                  fixedWidth
                  isChecked={key === highlightValue}
                />
              }
              onClick={() => setHighlightColor(key)}
            />
          )
        })}
      </ButtonGroup>
    </VStack>
  )
}

type FontColorIconProps = {
  highlightColor?: string
  color?: string
  fixedWidth?: boolean
  isChecked?: boolean
}

const FontColorIcon = ({
  highlightColor,
  color,
  fixedWidth,
  isChecked,
}: FontColorIconProps) => {
  return (
    <Text
      bg={highlightColor}
      color={color}
      py="4px"
      mt="-3px"
      mb="-4px"
      px="6px"
      mx="-6px"
      borderRadius="md"
    >
      {isChecked ? (
        <CheckIcon />
      ) : (
        <FontAwesomeIcon icon={solid('font')} fixedWidth={fixedWidth} />
      )}
    </Text>
  )
}
