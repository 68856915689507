import { HocuspocusProvider } from '@hocuspocus/provider'
import { Editor } from '@tiptap/core'
import debounce from 'lodash/debounce'
import { customAlphabet } from 'nanoid'
import { useMemo, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'

import { observeSelector } from 'modules/redux'
import { useUserContext } from 'modules/user'
import { sessionStore } from 'utils/storage'
import { USER_SETTINGS_CONSTANTS } from 'utils/userSettingsConstants'

import { awarenessStatesToArray } from '../extensions/CollaborationCursor'
import {
  IDLE_EXPIRATION_TIME_IN_MS,
  selectMemoStateToSync,
  setCollaborators,
  setLocalCollaboratorId,
  Collaborator,
} from '../reducer'

type AwarenessUpdateFunction = (
  {
    added,
    updated,
    removed,
  }: { added: number[]; updated: number[]; removed: number[] },
  origin: string | HocuspocusProvider
) => void

const sessionIdNanoid = customAlphabet('1234567890', 10)

const ensureSessionId = () => {
  const existingSessionId = sessionStore.getItem(
    USER_SETTINGS_CONSTANTS.sessionId
  )
  if (existingSessionId) return existingSessionId

  // Prefix the sessionId with the date so it can be sorted
  const newSessionId = `${+new Date()}__${sessionIdNanoid()}`
  sessionStore.setItem(USER_SETTINGS_CONSTANTS.sessionId, newSessionId)

  return newSessionId
}

/**
 * This hook has 3 jobs:
 * - Ensure the visitor has a sessionId set in SessionStorage
 * - When the editor is ready, call the user command to set the
 *   value of the current user (which could be unknown/anonymous)
 * - Observe changes to the awareness data and sync it into redux
 */
export const useAwarenessSync = (
  editor: Editor | undefined,
  yProvider: HocuspocusProvider | undefined
) => {
  const { user, isUserLoading, anonymousUser, color } = useUserContext()
  const hasInitialized = useRef(false)
  const reduxDispatch = useDispatch()
  const sessionId = useMemo(() => ensureSessionId(), [])

  const userObject = useMemo<
    Omit<Collaborator, 'clientId' | 'attached' | 'following' | 'memoState'>
  >(() => {
    const id = user?.id || anonymousUser.id

    return {
      id,
      color: color.value,
      sessionId,
      idleSince: null,
      name: user?.displayName || anonymousUser.displayName || '',
      profileImageUrl: user?.profileImageUrl || '',
      isReady: !isUserLoading,
      spotlight: {
        pos: null,
        cardId: null,
      },
    }
  }, [user, color, isUserLoading, anonymousUser, sessionId])

  useEffect(() => {
    if (!editor || editor.isDestroyed || !yProvider) return

    editor.commands.user(userObject)

    if (!hasInitialized.current && userObject.isReady) {
      // Hack to propagate our cursor info to other clients
      // This only fires once when the user loads for the first time
      // See https://github.com/ueberdosis/tiptap/issues/2457
      editor.chain().blur().focusDelayed().run()

      hasInitialized.current = true
    }

    reduxDispatch(
      setLocalCollaboratorId({
        sessionId: userObject.sessionId,
      })
    )

    const cb: AwarenessUpdateFunction = (
      { added: _added, updated: _updated, removed: _removed },
      _origin
    ) => {
      /**
       * Listen to awareness bus changes and sync them to our store
       */

      requestIdleCallback(
        () => {
          const viewers = awarenessStatesToArray(yProvider.awareness.states)
          reduxDispatch(
            setCollaborators({ collaborators: viewers as Collaborator[] })
          )
        },
        { timeout: 500 }
      )
    }

    // Always respond to the "change" event, which is fired when
    // a client is added/removed or updated based on deepEquality:
    //
    // https://github.com/yjs/y-protocols/blob/399cfd0d8b8e7e32bb0d57f4bbaa109cea312266/awareness.js#L132
    // https://github.com/yjs/y-protocols/blob/399cfd0d8b8e7e32bb0d57f4bbaa109cea312266/awareness.js#L286-L288
    yProvider.awareness.on('change', cb)

    // Lazily respond to the update event, which fires on every single local change,
    // but also fires every 30s as a heartbeat (even without changes). This ensures
    // we remove users who are idle for a long time (e.g. left a tab open)
    const cbDebounced = debounce(cb, IDLE_EXPIRATION_TIME_IN_MS)
    yProvider.awareness.on('update', cbDebounced)

    return () => {
      yProvider.awareness.off('change', cb)
      yProvider.awareness.off('update', cbDebounced)
    }
  }, [userObject, editor, yProvider, reduxDispatch])

  /**
   * Emit certain state we want to sync out to the awareness bus
   */
  useEffect(() => {
    if (!editor) return

    const callback = debounce(
      (stateToSync: ReturnType<typeof selectMemoStateToSync>) => {
        editor.commands.user(stateToSync)
      },
      10
    )

    return observeSelector(selectMemoStateToSync, callback)
  }, [editor])
}
