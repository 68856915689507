import { Flex, Box } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import NextImage from 'next/legacy/image'

import { ImageSearch } from '../components/ImageSearch'
import {
  MediaProviderType,
  MediaProviderPanelProps,
} from '../types/MediaProvider'
import GiphyIcon from './icons/giphy.svg'

const GIPHY_RANDOM_QUERIES = [
  'funny',
  'surprise',
  'hooray',
  'welcome',
  'excited',
  'nope',
  'fail',
  'sad',
  'cry',
  'angry',
  'teamwork',
  'cats',
  'dogs',
  'high five',
]

const GiphyPanel = ({
  updateAttributes,
  currentAttributes,
}: MediaProviderPanelProps) => {
  return (
    <>
      <ImageSearch
        updateAttributes={updateAttributes}
        currentImageUrl={currentAttributes.src}
        defaultQuery={currentAttributes.query}
        randomQueries={GIPHY_RANDOM_QUERIES}
        provider="giphy"
      />
      <Flex width="100%" justify="center" padding="10px" paddingTop="30px">
        <Box maxW="150px">
          <NextImage
            src="/images/powered-by-giphy.png"
            width="150"
            height="17"
            alt="Powered by GIPHY"
          />
        </Box>
      </Flex>
    </>
  )
}

export const GiphyProvider: MediaProviderType = {
  key: 'image.giphy',
  Panel: GiphyPanel,
  label: 'GIFs from GIPHY',
  description: 'Search for fun animated GIFs',
  icon: regular('film'),
  image: GiphyIcon,
  keywords: ['gif', 'animation', 'animated'],
}
