import {
  Button,
  IconButton,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useCallback } from 'react'

import { ColorPickerInput } from 'modules/tiptap_editor/components/panels/ColorPanel'

export type MaybeHex = string | null

type ColorPalettePickerProps = {
  value: MaybeHex[]
  updateValue: (value: MaybeHex[]) => void
  buttonLabel?: string
  maxColors?: number
  minColors?: number
  defaultValue?: string
}

export const ColorPalettePicker = ({
  value,
  updateValue,
  buttonLabel,
  maxColors = 10,
  minColors = 0,
  defaultValue,
}: ColorPalettePickerProps) => {
  const updateColor = useCallback(
    (index: number, newColor: string) => {
      const newColors = [...value]
      newColors[index] = newColor
      updateValue(newColors)
    },
    [value, updateValue]
  )

  const addValue = useCallback(() => {
    updateValue([...value, null])
  }, [value, updateValue])

  const removeValue = useCallback(
    (index: number) => {
      const newColors = [...value]
      newColors.splice(index, 1)
      updateValue(newColors)
    },
    [value, updateValue]
  )

  return (
    <>
      {value.map((color, index) => (
        <InputGroup key={index}>
          <ColorPickerInput
            value={color}
            updateValue={(newColor) => updateColor(index, newColor!)}
            defaultValue={defaultValue}
          />
          {value.length > minColors && (
            <InputRightElement>
              <IconButton
                aria-label="Delete"
                variant="ghost"
                onClick={() => removeValue(index)}
                icon={<FontAwesomeIcon icon={regular('trash')} />}
              />
            </InputRightElement>
          )}
        </InputGroup>
      ))}
      {value.length < maxColors && (
        <Button
          onClick={addValue}
          variant="plain"
          size="sm"
          leftIcon={<FontAwesomeIcon icon={regular('plus')} />}
        >
          {buttonLabel || `Add color`}
        </Button>
      )}
    </>
  )
}
