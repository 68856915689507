import {
  Box,
  Flex,
  Grid,
  GridItem,
  IconButton,
  Portal,
  useOutsideClick,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NodeViewProps } from '@tiptap/core'
import { useCallback, useState } from 'react'

import { useAppSelector } from 'modules/redux'
import { BackgroundType } from 'modules/tiptap_editor/styles/backgroundStyles'
import { preventDefaultAndStopPropagation } from 'utils/handlers'
import { useLightPopover } from 'utils/hooks'

import { selectSuggestionCardSelectedVariant } from './reducer'

const NUBBIN_WIDTH = 10

export const AIImagePicker = ({
  cardId,
  getPos,
  editor,
}: {
  cardId: string
  getPos: NodeViewProps['getPos']
  editor: NodeViewProps['editor']
}) => {
  const [isOpen, setOpen] = useState(false)

  const handleSelectImage = useCallback(
    (imageUrl: string) => {
      const pos = getPos()
      const cardLayoutItemNode = editor.state.doc.nodeAt(pos)

      if (cardLayoutItemNode) {
        editor.commands.updateAttributesAtPos(pos, {
          background: {
            type: BackgroundType.IMAGE,
            image: { src: imageUrl, tempUrl: imageUrl },
          },
        })
      }

      setOpen(false)
    },
    [editor, getPos]
  )

  const selectedVariant = useAppSelector(
    selectSuggestionCardSelectedVariant(cardId)
  )
  const imageUrls = selectedVariant?.imageUrls || []

  const { popperRef, referenceRef } = useLightPopover({
    placement: 'top',
    gutter: NUBBIN_WIDTH - 10,
  })

  useOutsideClick({
    // @ts-ignore
    ref: popperRef,
    handler(e: Event) {
      if ((e.target as HTMLElement)?.closest('[data-ai-image-picker]')) {
        return
      }
      setOpen(false)
    },
  })

  const showPicker = Boolean(isOpen && imageUrls.length)

  if (!imageUrls.length) return null

  return (
    <Flex
      className="ai-image-picker"
      borderRadius="var(--card-border-radius)"
      pos="absolute"
      justifyContent="center"
      alignItems="center"
      inset={0}
      role="group"
      overflow="hidden"
      transitionProperty="common"
      transitionDuration=".5s"
      onClick={() => {
        setOpen((p) => !p)
      }}
      cursor="pointer"
      zIndex={1}
    >
      <Box
        ref={referenceRef}
        pos="absolute"
        width="100%"
        height="100%"
        opacity={showPicker ? 0.3 : 0}
        _groupHover={{ opacity: 0.3 }}
        bg="trueblue.400"
        onMouseDown={preventDefaultAndStopPropagation}
      ></Box>
      <Flex
        boxSize={12}
        pos="relative"
        align="center"
        justify="center"
        borderRadius="md"
        opacity={showPicker ? 1 : 0}
        _groupHover={{ opacity: 1 }}
        transitionProperty="common"
        transitionDuration="fast"
        zIndex={1}
      >
        <IconButton
          data-ai-image-picker-button
          aria-label="change image"
          icon={<FontAwesomeIcon icon={regular('magic-wand-sparkles')} />}
          variant="unstyled"
          color="white"
        />
      </Flex>
      {showPicker && (
        <Portal>
          <Flex ref={popperRef} zIndex="modal" data-ai-image-picker>
            <Grid
              templateColumns="repeat(5, 1fr)"
              gap={4}
              bg="white"
              p={3}
              m={2}
              borderRadius="md"
              position="relative"
              shadow="md"
            >
              {imageUrls.map((url) => {
                return (
                  <GridItem
                    key={url}
                    borderRadius="md"
                    w="90px"
                    h="120px"
                    cursor="pointer"
                    _hover={{
                      filter: 'brightness(0.9)',
                    }}
                    onClick={() => {
                      handleSelectImage(url)
                    }}
                    backgroundImage={url}
                    backgroundPosition="center"
                    backgroundSize="cover"
                    zIndex={1}
                  />
                )
              })}
            </Grid>
          </Flex>
        </Portal>
      )}
    </Flex>
  )
}
