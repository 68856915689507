import { colorWithOpacity, isColorDark } from 'utils/color'

import { ThemeBase } from '../types'

export const Soft3DThemeBase: ThemeBase = {
  key: 'soft3d',
  name: 'Soft 3D',
  description: 'Smooth rounded edges with a soft 3D effect',
  getCSSVars(cardColor, accentColor) {
    const isDark = isColorDark(cardColor)
    return {
      '--outer-shadow-small': isDark
        ? `-2px -2px 4px rgba(255, 255, 255, 0.1), 2px 2px 4px rgba(0, 0, 0, 0.25)`
        : `-2px -2px 4px rgba(255, 255, 255, 0.7), 2px 2px 4px rgba(189, 200, 223, 0.7)`,
      '--outer-shadow-big': isDark
        ? `-6px -6px 12px rgba(255, 255, 255, 0.1), 6px 6px 12px rgba(0, 0, 0, 0.25)`
        : `-6px -6px 12px rgba(255, 255, 255, 0.7), 6px 6px 12px rgba(189, 200, 223, 0.7)`,
      '--inner-shadow-small': isDark
        ? `inset -2px -2px 4px rgba(255, 255, 255, 0.1), inset 2px 2px 4px rgba(0, 0, 0, 0.25)`
        : `inset -2px -2px 4px rgba(255, 255, 255, 0.7), inset 2px 2px 4px rgba(189, 200, 223, 0.7)`,
      '--line-thickness': `0.45em`,
      '--table-stripe': colorWithOpacity(accentColor, 0.05),
      '--box-border-radius': '1em',
    }
  },
  cardSx: {
    borderRadius: '1.5em',
    backgroundColor: 'var(--card-color)',
    boxShadow: 'var(--outer-shadow-big)',
  },
  boxSx: {
    border: '0px',
    boxShadow: 'var(--inner-shadow-small)',
    borderRadius: 'var(--box-border-radius)',
  },
  clickableSx: {
    boxShadow: 'var(--outer-shadow-big)',
    backgroundColor: 'var(--card-color)',
    border: '0px !important', // Override default button borders
  },
  imageSx: {
    borderRadius: 'var(--box-border-radius)',
    // Temporarily disabling since it doesn't work with transparent images. G-4008
    // boxShadow: 'var(--outer-shadow-small)',
    // border: '1px solid var(--card-color)',
  },
  smartLayoutBoxSx: {
    boxShadow: 'var(--inner-shadow-small)',
    borderRadius: '0.5em',
    backgroundColor: 'var(--card-color)',
  },
  smartLayoutLineSx: {
    boxShadow: 'var(--inner-shadow-small)',
    backgroundColor: 'var(--card-color)',
  },
  tableSx: {
    boxShadow: 'var(--inner-shadow-small)',
    borderRadius: 'var(--box-border-radius)',
    'tbody tr': {
      _even: {
        backgroundColor: `var(--table-stripe)`,
      },
      _last: {
        borderBottomRadius: 'var(--box-border-radius)',
      },
    },
  },
}
