import { Box, Flex, FlexProps, keyframes } from '@chakra-ui/react'
import { cx } from '@chakra-ui/utils'

import { getThemeCSSVars } from 'modules/theming/styles/variables'
import { getThemeBase } from 'modules/theming/themeBases'
import { Theme } from 'modules/theming/types'
import { getContainerBackgroundColor } from 'modules/tiptap_editor/styles/containerStyles'
import { isMobileDevice } from 'utils/deviceDetection'

import { CARD_BODY_CLASS, EXPAND_CARD_TRANSITION_TIME } from '../constants'
import {
  CARD_OUTER_PADDING_Y,
  useCardSizeCSSVars,
} from '../hooks/useCardSizeCSSVars'
import { CardAttributes } from '../types'
import { CARD_BACKGROUND_PADDING_RESPONSIVE } from './constants'

const nestedCardTransition = keyframes`
  0% {
    margin-inline: 0;
  }
  100% {
    margin-inline: var(--nested-card-margin);
  }
`

// Make room for the card menu and the top edge of the card
const FIRST_CARD_PADDING = ['0.5rem', '4.5rem']

type Card2BodyProps = {
  attrs: CardAttributes
  isFullBleed: boolean
  isFirstCard: boolean
  isFocused: boolean
  isNested: boolean
  isPresentMode: boolean
  isDarkOverride?: boolean
  isEditable: boolean
  theme: Theme
  nestedDepth: number
  children: React.ReactNode
}

export const Card2Body: React.FC<Card2BodyProps> = ({
  attrs,
  isFullBleed,
  isNested,
  isPresentMode,
  isEditable,
  isFirstCard,
  isFocused,
  isDarkOverride,
  nestedDepth,
  theme,
  children,
}) => {
  const { id: cardId, container } = attrs
  const baseTheme = getThemeBase(theme)
  const cardColor = getContainerBackgroundColor(theme, container)
  const { borderRadius: themeBorderRadius, ...themeCardStyles } = {
    // Make expanded nested cards stand out against their parent by using the same border/shadow they have when collapsed
    ...(isNested ? baseTheme.clickableSx : undefined),
    ...baseTheme.cardSx,
  }
  const isPresentModeTopLevel = isPresentMode && !isNested

  const borderRadius =
    (isFullBleed || isPresentMode) && !isNested ? 'none' : themeBorderRadius

  const themeCssVars = getThemeCSSVars(
    theme,
    isDarkOverride,
    container.background ? cardColor : undefined
  )
  const cardSizeCSSVars = useCardSizeCSSVars({
    nestedDepth,
    isNested,
    isPresentMode,
    isEditable,
    isFullBleed,
    theme,
    attrs,
  })

  const hasCardBackground = attrs.background.type !== 'none' && !isNested

  const positionProps: FlexProps = isFullBleed
    ? {
        width: isNested ? 'var(--card-width)' : '100%',
      }
    : {
        my:
          isFullBleed || isNested
            ? undefined
            : hasCardBackground && !isPresentMode && !isMobileDevice()
            ? CARD_BACKGROUND_PADDING_RESPONSIVE
            : ['.5rem', `${CARD_OUTER_PADDING_Y}rem`],
        mt: isFirstCard && !isMobileDevice() ? FIRST_CARD_PADDING : undefined,
        // See nestedCardTransition for the application of mx
        zIndex: isNested ? 1 : undefined, // Go over adjacent backgrounds
        width: `var(--card-width)`,
      }
  const presentModeProps: FlexProps = isPresentModeTopLevel
    ? {
        boxShadow: 'none',
        border: 'none',
        minW: '100%', // Use minW to support transitioning between DOC/PRESENT modes
        minH: '100vh', // Use minH to support transitioning between DOC/PRESENT modes
        maxH: '100vh',
        my: 0,
        mt: 0,
        overflow: 'hidden auto',
        position: 'static', // Card color be positioned relative to the parent, card-wrapper, to avoid scrolling away when position: fixed in present mode
      }
    : {
        minW: '0%', // Use minW to support transitioning between DOC/PRESENT modes
        minH: '0vh', // Use minH to support transitioning between DOC/PRESENT modes
        position: 'relative',
      }

  return (
    <Flex
      className={cx(CARD_BODY_CLASS)}
      data-card-id={cardId}
      data-card-body={cardId}
      data-card-scroll-element // The element that scrolls in present mode (used for PDF export)
      data-selection-ring
      data-guider-highlight="card-body"
      data-content-reference // Used for drag previews
      transitionProperty="min-height, min-width, font-size"
      transitionDuration={`${EXPAND_CARD_TRANSITION_TIME}ms`}
      fontSize="var(--font-size)"
      animation={
        isNested
          ? `${nestedCardTransition} ${EXPAND_CARD_TRANSITION_TIME}ms ease-out forwards`
          : undefined
      }
      {...positionProps}
      {...presentModeProps}
      css={{
        '--card-border-radius': borderRadius,
        ...themeCssVars,
        ...cardSizeCSSVars,
      }}
      justify="center" // On wide screens, keep the layout centered horizontally
      borderRadius="var(--card-border-radius)"
    >
      {/* Card color and glassiness. This needs to be its own div for the glassy effect with backdrop-filter: blur to work on nested cards. */}
      <Box
        position="absolute"
        inset="0"
        sx={themeCardStyles}
        borderRadius="inherit"
        contentEditable={false}
        zIndex={isPresentMode ? -1 : undefined} // Under the scrollbars
        outline={isFocused ? `2px solid var(--chakra-ring-color)` : undefined}
        outlineOffset={isFullBleed ? '-3px' : undefined}
      />
      {children}
    </Flex>
  )
}
