import {
  absolutePositionToRelativePosition,
  relativePositionToAbsolutePosition,
  ySyncPluginKey,
} from '@gamma-app/y-prosemirror'
import { Range } from '@tiptap/core'
import { EditorState } from 'prosemirror-state'
import * as Y from 'yjs'

export const absoluteToRelativePos = (
  state: EditorState,
  pos: number
): Y.RelativePosition | null => {
  const ystate = ySyncPluginKey.getState(state)
  if (!ystate) {
    return null
  }
  const { type, binding } = ystate
  if (!ystate.binding) {
    return null
  }
  return absolutePositionToRelativePosition(pos, type, binding.mapping)
}

export const relativeToAbsolutePos = (
  state: EditorState,
  relativePos: Y.RelativePosition
): number | null => {
  const ystate = ySyncPluginKey.getState(state)
  if (!ystate) {
    return null
  }
  const { doc, type, binding } = ystate
  if (!ystate.binding) {
    return null
  }
  return relativePositionToAbsolutePosition(
    doc,
    type,
    relativePos,
    binding.mapping
  )
}

export type RelativeRange = {
  from: Y.RelativePosition | null
  to: Y.RelativePosition | null
}

export const absoluteToRelativeRange = (
  state: EditorState,
  range: Range
): RelativeRange => {
  return {
    from: absoluteToRelativePos(state, range.from),
    to: absoluteToRelativePos(state, range.to),
  }
}

export const relativeToAbsoluteRange = (
  state: EditorState,
  relativeRange: RelativeRange
): Range | null => {
  if (!relativeRange.from || !relativeRange.to) return null
  const from = relativeToAbsolutePos(state, relativeRange.from)
  const to = relativeToAbsolutePos(state, relativeRange.to)
  if (from == null || to == null) return null
  return {
    from,
    to,
  }
}
