import { Flex, useMediaQuery } from '@chakra-ui/react'
import { Editor } from '@tiptap/core'
import { memo } from 'react'

import { useAppSelector } from 'modules/redux'

import { selectEditable, selectIsAnyCommentOpen } from '../../../reducer'
import { InsertWidgetInner } from './InsertWidgetInner'

const InsertWidgetComponent = ({ editor }: { editor: Editor }) => {
  const editable = useAppSelector(selectEditable)

  const [isShort] = useMediaQuery(['(max-height: 400px)', '(min-height: 401px'])

  const isAnyCommentOpen = useAppSelector(selectIsAnyCommentOpen)

  return (
    <Flex
      position="fixed"
      w="0%"
      h="0%"
      right={6}
      top="50%"
      display={
        editable && !isShort && !isAnyCommentOpen
          ? { base: 'none', lg: 'flex' }
          : 'none'
      }
      alignItems="center"
      // Over the memo contents and drag handle, but under other popovers like the comments list
      // Note that the flyout InsertWidgetButtons are at a higher zIndex via a portal
      zIndex="docked"
      opacity={isShort ? 0 : { base: 0, lg: 1 }}
      data-testid="insert-widget-wrapper"
    >
      <Flex
        position="absolute"
        right={0}
        data-guider-highlight="insert-widget"
        direction="column"
        className="insert-widget"
      >
        <InsertWidgetInner editor={editor} />
      </Flex>
    </Flex>
  )
}

export const InsertWidget = memo(InsertWidgetComponent)
