import { Box, Flex, FlexProps, Grid } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import { getThemeBase } from 'modules/theming/themeBases'
import { BLOCK_MARGIN } from 'modules/tiptap_editor/styles/constants'

import { getAlignStyles } from '../../HorizontalAlign'
import { EditableLabel } from '../components/EditableLabel'
import { CELL_PADDING, EmptyCellContent } from '../constants'
import { OrientationOption } from '../options/OrientationOption'
import {
  SmartLayoutCellComponent,
  SmartLayoutVariant,
  SmartLayoutWrapperComponent,
} from '../types'

const SEQUENCE_GAP = '1em'
const CHEVRON_WIDTH = '1.5em'

export const SequenceWrapper: SmartLayoutWrapperComponent = ({
  children,
  options,
}) => {
  const isHorizontal = options.orientation === 'horizontal'

  const gridProps = isHorizontal
    ? {
        // Header and content rows each auto scale to fit their content
        templateRows: 'auto auto',
        // Columns all have matching sizes
        autoColumns: '1fr',
        columnGap: SEQUENCE_GAP,
      }
    : {
        templateColumns: 'auto 1fr', // Ensure the second column fills remaining width
        // Rows scale to fit their content
        autoRows: 'auto',
        rowGap: SEQUENCE_GAP,
      }

  return (
    <Grid {...gridProps} data-selection-ring>
      {children}
    </Grid>
  )
}

export const SequenceCell: SmartLayoutCellComponent = (cellProps) => {
  const { layoutOptions, children, theme } = cellProps
  const base = getThemeBase(theme)
  const isHorizontal: boolean = layoutOptions.orientation === 'horizontal'

  const contentProps = isHorizontal
    ? {
        gridRow: 2,
        gridColumn: 'span 1',
        minW: 0, // Allows columns to shrink if the container is too small
        transformOrigin: 'center top',
        ...getAlignStyles('center'),
      }
    : {
        gridColumn: 2,
        gridRow: 'span 1',
        transformOrigin: 'left center',
      }
  return (
    <>
      <SequenceLabel {...cellProps} />
      <Box
        flex="1"
        {...contentProps}
        px={CELL_PADDING}
        data-selection-ring
        data-content-reference
        sx={base.smartLayoutContentSx}
      >
        {children}
      </Box>
    </>
  )
}

const SequenceLabel: SmartLayoutCellComponent = ({
  layoutOptions,
  label,
  index,
  updateAttributes,
  theme,
}) => {
  const base = getThemeBase(theme)
  const isHorizontal = layoutOptions.orientation === 'horizontal'
  const labelProps: FlexProps = isHorizontal
    ? {
        gridRow: 1,
        gridColumn: 'span 1',
        align: 'center',
        justify: 'flex-start',
        ...getAlignStyles('center'),
      }
    : {
        gridColumn: 1,
        gridRow: 'span 1',
        align: 'center',
        justify: 'center',
        width: '6em',
      }
  return (
    <Flex
      fontSize="1.5em"
      fontFamily="var(--heading-font)"
      fontWeight="var(--heading-font-weight)"
      px={BLOCK_MARGIN}
      lineHeight="1"
      contentEditable={false}
      sx={base.smartLayoutBoxSx || base.boxSx}
      {...labelProps}
      position="relative"
    >
      <EditableLabel
        value={label || ''}
        my={BLOCK_MARGIN}
        updateAttributes={updateAttributes}
        placeholder={`${index + 1}`}
        flex={isHorizontal ? 1 : undefined}
      />
    </Flex>
  )
}

export const Sequence: SmartLayoutVariant = {
  key: 'sequence',
  name: 'Sequence',
  icon: regular('forward'),
  options: [OrientationOption],
  Wrapper: SequenceWrapper,
  Cell: SequenceCell,
  defaultContent: EmptyCellContent,
  addDirection(options) {
    return options.orientation === 'horizontal' ? 'right' : 'bottom'
  },
  isFullWidth(options) {
    return options.orientation === 'horizontal'
  },
  disabled: true,
}
