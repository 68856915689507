import { gammaTheme } from '@gamma-app/ui'

import { Theme } from './types'

export const DEFAULT_ACCENT_COLOR = gammaTheme.colors.trueblue[500] as string
export const DEFAULT_HEADING_COLOR = '#000000' // black
export const DEFAULT_HEADING_COLOR_DARK = '#FFFFFF' // white
export const DEFAULT_BODY_COLOR = '#272525' // dark gray
export const DEFAULT_BODY_COLOR_DARK = gammaTheme.colors.gray[200] as string

export const DEFAULT_FONTS = {
  bodyFont: 'Inter',
  headingFont: 'Inter',
  fontSize: 1,
}

export const DEFAULT_FONT_WEIGHTS = {
  heading: 700,
  body: 400,
}

export const DARK_TEXT_LIGHTNESS = 0.4
export const LIGHT_TEXT_LIGHTNESS = 0.8

export const DEFAULT_LIGHT_CARD_BACKGROUND = 'white'
export const DEFAULT_DARK_CARD_BACKGROUND = 'rgba(12, 12, 12)'

export const ACCENT_IMAGE_SOURCE_KEY = 'accent.theme'

export const EMPTY_THEME_NAME = 'Untitled theme'

export const EMPTY_THEME: Theme = {
  id: 'new',
  name: EMPTY_THEME_NAME,
  archived: false,
  priority: 0,
  config: {},
  fonts: [],
}
