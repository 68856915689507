import { sample } from 'lodash'
import { useCallback } from 'react'

import { useAppSelector } from 'modules/redux'
import { Theme } from 'modules/theming'
import { selectTheme } from 'modules/tiptap_editor/reducer'

export const useGetRandomAccentBackground = () => {
  const theme: Theme = useAppSelector(selectTheme)

  const getRandomAccentBackground = useCallback(() => {
    return sample(theme.config.accentBackgrounds)
  }, [theme])

  return getRandomAccentBackground
}
