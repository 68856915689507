import { HStack } from '@chakra-ui/react'
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Editor } from '@tiptap/core'
import { Selection } from 'prosemirror-state'
import { useCallback } from 'react'

import { AlignmentCommands } from '../../../extensions/HorizontalAlign'
import { DeleteNodeButton } from '../buttons/DeleteNodeButton'
import { ToolbarButton } from '../buttons/ToolbarButton'
import { FormattingMenuDivider } from './FormattingMenuDivider'
import { LinkInput } from './LinkFormattingMenu'

export const ButtonVariants = [
  {
    name: 'Solid button',
    key: 'solid',
    icon: solid('rectangle-wide'),
  },
  {
    name: 'Outlined button',
    key: 'outline',
    icon: regular('rectangle-wide'),
  },
]

type ButtonFormattingMenuProps = {
  editor: Editor
  selection: Selection
}
export const ButtonFormattingMenu = ({ editor }: ButtonFormattingMenuProps) => {
  const href = editor.getAttributes('button').href || ''
  const deleteButton = useCallback(
    () => editor.commands.handleButtonDelete(false),
    [editor]
  )

  return (
    <HStack px={2}>
      {ButtonVariants.map(({ name, icon, key }) => {
        return (
          <ToolbarButton
            key={name}
            label={name}
            icon={icon}
            onClick={() => {
              editor.commands.updateAttributes('button', { variant: key })
            }}
            isActive={editor.isActive('button', { variant: key })}
          />
        )
      })}
      <FormattingMenuDivider />
      {AlignmentCommands.map(({ name, icon, checkActive, apply }) => {
        if (!checkActive || !apply) return
        return (
          <ToolbarButton
            key={name}
            label={name}
            icon={icon}
            onClick={() => {
              apply(editor)
            }}
            isActive={checkActive(editor)}
          />
        )
      })}
      <LinkInput
        href={href}
        updateHref={(url) =>
          editor.commands.updateAttributes('button', { href: url })
        }
        editor={editor}
        allowEditMetadata={false}
      />
      <FormattingMenuDivider />
      <DeleteNodeButton editor={editor} handleDelete={deleteButton} />
    </HStack>
  )
}
