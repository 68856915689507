import { findParentNodeClosestToPos, NodeWithPos } from '@tiptap/core'
import { Node, ResolvedPos, Schema, Slice } from 'prosemirror-model'
import { EditorView } from 'prosemirror-view'

import { isNodeEmpty, rectAtPos } from '../../utils'

const PREVENT_COLUMN_DROPS_ON_NODES = [
  'card',
  'document',
  'gridLayout',
  'gallery',
]

const isColumnDroppable = (node: Node) => {
  return (
    node.isBlock &&
    !PREVENT_COLUMN_DROPS_ON_NODES.includes(node.type.name) &&
    !(node.isTextblock && isNodeEmpty(node))
  )
}

export const isLayoutNode = (node: Node) => node.type.name === 'gridLayout'
export const isLayoutCellNode = (node: Node) => node.type.name === 'gridCell'

export type ColumnDropTarget = {
  pos: number
  node: Node
  side: 'right' | 'left'
  rect: DOMRect
}

export const checkColumnDropTarget = (
  view: EditorView,
  event: DragEvent,
  slice?: Slice,
  needsUpload?: boolean
): ColumnDropTarget | null => {
  const betweenTarget =
    slice && checkBetweenColumnsDropTarget(view, slice, event)
  if (betweenTarget) {
    return betweenTarget
  }

  if (slice && !canColumnContainSlice(slice, view.state.schema)) return null
  // Column drops are only valid when dragging within Gamma, not from outside
  // because the upload plugin takes precedence and doesn't play nice with this
  if (needsUpload) return null
  const target =
    checkColumnDropTargetOnSide(view, event, 'right') ||
    checkColumnDropTargetOnSide(view, event, 'left')
  if (!target) return null

  // Prevent dropping on yourself
  if (slice && slice.content.firstChild === target.node) return null
  return target
}

const checkBetweenColumnsDropTarget = (
  view: EditorView,
  slice: Slice,
  event: DragEvent
): ColumnDropTarget | null => {
  if (!canLayoutContainSlice(slice, view.state.schema)) {
    return null
  }
  const pos = view.posAtCoords({
    left: event.clientX,
    top: event.clientY,
  })?.inside
  if (!pos || pos == -1) return null
  const { doc } = view.state
  const $pos = doc.resolve(pos)
  const node = doc.nodeAt(pos)
  const target =
    node && isLayoutCellNode(node)
      ? { node, pos }
      : findParentNodeClosestToPos($pos, isLayoutCellNode)
  const targetRect = target?.pos && rectAtPos(target.pos, view)

  if (!target || !targetRect) {
    return null
  }

  const side =
    event.clientX > targetRect.left + targetRect.width / 2 ? 'right' : 'left'
  return {
    pos: target.pos,
    node: target.node,
    rect: targetRect,
    side,
  }
}

const OFFSET_MARGIN = 60 // pixels outside the block to check for a drop
const SIDE_DROP_PERCENT = 15 // % from the right edge internally to assume it's a column drop

export const checkColumnDropTargetOnSide = (
  view: EditorView,
  event: DragEvent,
  side: ColumnDropTarget['side']
): ColumnDropTarget | null => {
  const pos = view.posAtCoords({
    left: event.clientX + (side == 'left' ? OFFSET_MARGIN : -OFFSET_MARGIN),
    top: event.clientY,
  })?.inside
  if (!pos || pos == -1) return null
  const { doc, schema } = view.state
  const $pos = doc.resolve(pos)
  const node = doc.nodeAt(pos)
  const target =
    node && isColumnDroppable(node)
      ? { node, pos }
      : findParentNodeClosestToPos($pos, isColumnDroppable)
  if (!target) return null

  // Check whether we're dropping on the sides of a block
  const targetRect = rectAtPos(pos, view)
  if (!targetRect) return null
  const sideDistance =
    side == 'left'
      ? event.clientX - targetRect.left
      : targetRect.right - event.clientX
  const isOutside = sideDistance < 0
  const isColumnDrop =
    // It's a drop if you're off the block, within OFFSET_MARGIN
    (isOutside && sideDistance > -OFFSET_MARGIN) ||
    // or on the block, within SIDE_DROP_PERCENT of the width from the right edge
    (side == 'right' &&
      sideDistance <
        (targetRect.right - targetRect.left) * (SIDE_DROP_PERCENT / 100))
  if (!isColumnDrop) return null

  // Check that a layout is allowed where we're dropping
  const $target = doc.resolve(target.pos)
  const canMakeColumn =
    (node?.type.name === 'gridCell' && canAddColumnAtPos($target, schema)) ||
    canWrapLayoutAtPos($target, schema)

  // If we're in the margin, or we can't make a column directly on this block, check if we can add to a parent layout
  if (isOutside || !canMakeColumn) {
    const parentCell = findParentNodeClosestToPos(
      $pos,
      (n) => n.type.name === 'gridCell'
    )
    if (parentCell && canAddColumnAtPos(doc.resolve(parentCell.pos), schema)) {
      const rect = rectAtPos(parentCell.pos, view)
      if (!rect) return null
      return {
        pos: parentCell.pos,
        node: parentCell.node,
        side,
        rect,
      }
    }
  }
  if (!canMakeColumn) return null

  return {
    pos: target.pos,
    rect: targetRect,
    node: target.node,
    side,
  }
}

const canAddColumnAtPos = ($pos: ResolvedPos, schema: Schema) =>
  $pos.parent.canReplaceWith($pos.index(), $pos.index(), schema.nodes.gridCell)

const canWrapLayoutAtPos = ($pos: ResolvedPos, schema: Schema) =>
  $pos.parent.canReplaceWith(
    $pos.index(),
    $pos.indexAfter(),
    schema.nodes.gridLayout
  )

const canColumnContainSlice = (slice: Slice, schema: Schema) =>
  schema.nodes.gridCell.validContent(slice.content)

const canLayoutContainSlice = (slice: Slice, schema: Schema) =>
  schema.nodes.gridLayout.validContent(slice.content)

export const getParentLayout = ($pos: ResolvedPos): ResolvedPos | null => {
  return getParent($pos, 'gridLayout')
}

export const getLayoutChildren = ($pos: ResolvedPos): NodeWithPos[] => {
  const $layout = getParent($pos, 'gridLayout')

  if (!$layout || !$layout.nodeAfter) {
    throw new Error()
  }

  const layout = $layout.nodeAfter
  const result: NodeWithPos[] = []

  let start = $layout.start($layout.depth + 1)
  for (let i = 0; i < layout.childCount; i++) {
    const cell = layout.child(i)
    start += i === 0 ? 0 : layout.child(i - 1).nodeSize
    result.push({
      node: cell,
      pos: start,
    })
  }
  return result
}

const getParent = ($pos: ResolvedPos, nodeName): ResolvedPos | null => {
  if ($pos.nodeAfter?.type.name === nodeName) {
    return $pos
  }

  const doc = $pos.doc
  for (let d = $pos.depth; d > 0; d--) {
    if ($pos.node(d).type.name === nodeName) {
      return doc.resolve($pos.before(d))
    }
  }
  return null
}

export const getLayoutCellResolvedPos = (
  $layout: ResolvedPos,
  colInd: number
): ResolvedPos | null => {
  const layoutChildren = getLayoutChildren($layout)
  const entry =
    layoutChildren[colInd === -1 ? layoutChildren.length - 1 : colInd]

  if (!entry) {
    return null
  }

  return $layout.doc.resolve(entry.pos)
}

export const getColIndex = ($cell: ResolvedPos): number => {
  const children = getLayoutChildren($cell)
  return children.findIndex((c) => c.pos === $cell.pos)
}
