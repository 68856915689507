import { Center, CenterProps, TooltipProps } from '@chakra-ui/react'
import { GammaTooltip } from '@gamma-app/ui'
import React from 'react'

export type BaseDragHandleProps = {
  icon?: string | JSX.Element
  isVisible?: boolean
  isSelected?: boolean
  label?: TooltipProps['label']
} & CenterProps

export const BaseDragHandle: React.FC<BaseDragHandleProps> = ({
  icon = '⋮',
  isVisible,
  isSelected,
  label,
  onClick,
  onDragStart,
  ...centerProps
}) => {
  return (
    <GammaTooltip placement="top" label={isSelected ? undefined : label}>
      <Center
        position="absolute"
        // default width+height
        width="16px"
        height="22px"
        // Styles should match GlobalDragHandle styles in globals.scss
        fontWeight="900"
        fontFamily="Inter, sans-serif"
        backgroundColor={isSelected ? 'trueblue.50' : 'whiteAlpha.900'}
        backdropFilter="blur(8px)"
        border="1px solid"
        borderColor="blackAlpha.100"
        color={isSelected ? 'trueblue.300' : 'gray.500'}
        borderRadius="sm"
        boxShadow="sm"
        fontSize="16px"
        cursor="pointer"
        // Hidden unless `isSelected` is true, you hover directly, or you hover a `drag-handle-hover` parent. See editorStyles.ts
        opacity={isSelected || isVisible ? 1 : 0}
        _hover={{
          color: isSelected ? 'trueblue.600' : 'gray.800',
          backgroundColor: isSelected ? 'trueblue.100' : 'gray.100',
          opacity: 1,
        }}
        {...centerProps}
        className="drag-handle"
        // Handle events
        onClick={onClick}
        onDragStart={onDragStart}
        draggable={true}
        contentEditable={false}
        // Larger hover target
        _after={{
          position: 'absolute',
          content: '""',
          inset: -2,
        }}
      >
        {icon}
      </Center>
    </GammaTooltip>
  )
}
