import { BorderProps, Flex, FlexProps } from '@chakra-ui/react'
import { NodeViewProps } from '@tiptap/react'
import { useCallback } from 'react'

import { useAppSelector } from 'modules/redux'
import { NodeViewContent, NodeViewWrapper } from 'modules/tiptap_editor/react'
import { selectEditable } from 'modules/tiptap_editor/reducer'
import { getBackgroundProps } from 'modules/tiptap_editor/styles/backgroundStyles'
import { findParentNodes } from 'modules/tiptap_editor/utils'

import { isNodeViewEmpty } from '../../EmptyNodes'
import { isFocusedAndEditable } from '../../selection/FocusedNodes'
import { CardOrnaments } from '../CardOrnaments/CardOrnaments'
import { findCardPluginDecoration } from '../CardPlugin'
import { useGetRandomAccentBackground } from '../hooks/useGetRandomAccentBackground'
import { usePresentVariant } from '../hooks/usePresentVariant'
import { CardLayout } from '../types'
import { isCardNode } from '../utils'
import { CardLayoutItemAttrs, CardLayoutItemIds } from './CardLayoutItem'
import { findCardLayoutDecorationSpec } from './CardLayoutPlugin'
import { findLayoutPreset } from './cardLayoutUtils'
import { ChooseLayoutPlaceholder } from './ChooseLayoutPlaceholder'

export const getBgBorderRadius = (
  layout: CardLayout,
  itemId: CardLayoutItemIds,
  isFullBleed: boolean
): Partial<BorderProps> => {
  if (isFullBleed) {
    return {}
  }

  if (layout === 'behind' || layout === 'blank') {
    return {
      borderRadius: 'var(--card-border-radius)',
    }
  }

  if (layout === 'left') {
    if (itemId === 'accent') {
      return {
        borderLeftRadius: 'var(--card-border-radius)',
      }
    }
    if (itemId === 'body') {
      return {
        borderRightRadius: 'var(--card-border-radius)',
      }
    } else {
      return {}
    }
  }

  if (layout === 'right') {
    if (itemId === 'body') {
      return {
        borderLeftRadius: 'var(--card-border-radius)',
      }
    }
    if (itemId === 'accent') {
      return {
        borderRightRadius: 'var(--card-border-radius)',
      }
    } else {
      return {}
    }
  }

  if (layout === 'top') {
    if (itemId === 'body') {
      return {
        borderBottomRadius: 'var(--card-border-radius)',
      }
    }
    if (itemId === 'accent') {
      return {
        borderTopRadius: 'var(--card-border-radius)',
      }
    } else {
      return {}
    }
  }
  return {}
}

const DEFAULT_VERTICAL_ALIGN = 'center'

export const CardBodyLayoutItemView = (nodeViewProps: NodeViewProps) => {
  const { node, getPos, decorations, editor } = nodeViewProps
  const attrs = node.attrs as CardLayoutItemAttrs

  const { background } = attrs

  const cardLayoutDecoSpec = findCardLayoutDecorationSpec(decorations)
  const { isNested } = findCardPluginDecoration(decorations)
  const isBlankLayout = cardLayoutDecoSpec?.layout === 'blank'
  const bgProps = isBlankLayout ? {} : getBackgroundProps(background, false)

  const borderProps = getBgBorderRadius(
    cardLayoutDecoSpec!.layout,
    attrs.itemId,
    cardLayoutDecoSpec!.cardSize === 'full'
  )
  const alignProps: Partial<FlexProps> = {
    flexDirection: 'column',
    justifyContent: attrs.verticalAlign || DEFAULT_VERTICAL_ALIGN,
  }
  const preset = findLayoutPreset(cardLayoutDecoSpec?.layout)
  const shouldHide = preset && !preset.items[attrs.itemId]
  const [presentVariant] = usePresentVariant(cardLayoutDecoSpec!.cardId)
  // TODO maybe find a better way to get this information here
  const isPresentingCurrent = presentVariant === 'presentCurrent'
  const isEmpty = isNodeViewEmpty(decorations)
  const isEditable = useAppSelector(selectEditable)
  const isFocused = isFocusedAndEditable(decorations)
  const getRandomAccentBackground = useGetRandomAccentBackground()

  const setLayout = useCallback(
    (l: CardLayout) => {
      const parentCards = findParentNodes(
        editor.state.doc.resolve(getPos()),
        isCardNode
      )
      const parentCardPos = parentCards.length > 0 ? parentCards[0].pos : null
      if (!parentCardPos) {
        return
      }

      editor
        .chain()
        .setCardLayout(parentCardPos, l, getRandomAccentBackground)
        // make sure to select text inside body
        .selectInsideCardBody(parentCardPos)
        .focus()
        .run()
    },
    [editor, getPos, getRandomAccentBackground]
  )

  return (
    <NodeViewWrapper
      as="div"
      style={{
        ...(shouldHide ? { display: 'none' } : {}),
      }}
    >
      <Flex
        data-content-reference
        className="card-layout-cell-bg"
        w="100%"
        h="100%"
        position="relative"
        data-selection-ring
        data-selection-background
        {...bgProps}
        {...(!isPresentingCurrent && borderProps)}
        {...alignProps}
      >
        {isBlankLayout && <CardOrnaments />}
        <NodeViewContent
          as="div"
          style={{
            width: '100%',
          }}
        />

        {isEditable && isEmpty && !isNested && (
          <ChooseLayoutPlaceholder
            isVisible={isBlankLayout && isFocused}
            setLayout={setLayout}
            editor={editor}
          />
        )}
      </Flex>
    </NodeViewWrapper>
  )
}
