import {
  Box,
  Button,
  ButtonGroup,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
} from '@chakra-ui/react'
import { focus, getAllFocusable } from '@chakra-ui/utils'
import { Editor } from '@tiptap/core'
import { NodeSelection } from 'prosemirror-state'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { useHealthCheck } from 'modules/api'
import { CustomImageProvider } from 'modules/media'
import { useUploadOnUnmount } from 'modules/media/components/useUploadOnUnmount'
import { OfflineInfoBox } from 'modules/offline'
import { useAppSelector } from 'modules/redux'
import { useForwardUndo } from 'modules/tiptap_editor/hooks/useForwardUndo'

import {
  MediaSourcesMap,
  MediaSourceType,
} from '../../../extensions/media/MediaSources'
import { useEditorUpdateDuringSelection } from '../../../hooks'
import { selectEditable, selectIsEditingMedia } from '../../../reducer'
import { useDrawerSize } from '../hooks'
import { MediaDrawerMenu } from './MediaDrawerMenu'
import {
  canEditInMediaDrawer,
  getSelectedMedia,
  updateSelectedMedia,
  useToggleMediaDrawer,
} from './utils'

type MediaDrawerProps = {
  editor: Editor
}

const DEFAULT_SOURCE = CustomImageProvider.key

export const MediaDrawer = React.memo(({ editor }: MediaDrawerProps) => {
  useEditorUpdateDuringSelection(editor)
  const { isConnected } = useHealthCheck()
  const toggleMediaDrawer = useToggleMediaDrawer()
  const isEditable = useAppSelector(selectEditable)
  const selection = editor.state.selection
  const selectedMedia = useMemo(
    () => getSelectedMedia(editor, selection),
    [editor, selection]
  )
  const isEditingMedia = useAppSelector(selectIsEditingMedia)
  const isMediaSelected =
    selectedMedia && canEditInMediaDrawer(selectedMedia.type)
  const isOpen = Boolean(isEditingMedia && isMediaSelected && isEditable)
  const panelRef = useRef<HTMLDivElement>(null)

  const onClose = useCallback(() => {
    toggleMediaDrawer(false)
    if (selection instanceof NodeSelection) {
      editor.chain().setNodeSelection(selection.from).focus().run() // Keep selection from jumping when you close
    }
  }, [editor, selection, toggleMediaDrawer])

  const forwardUndo = useForwardUndo(editor)

  useEffect(() => {
    // Close the media drawer if selection changes, e.g. the node you have selected is deleted or something
    if (!isMediaSelected) {
      toggleMediaDrawer(false)
    }
  }, [isMediaSelected, toggleMediaDrawer])

  useEffect(() => {
    if (!isOpen) return
    editor.commands.scrollIntoView()

    // Auto focus on the first interactive element in the panel, if there is one
    // Chakra's autoFocus prop doesn't work in combination with trapFocus=false, see below
    // Based on https://github.com/chakra-ui/chakra-ui/blob/726105acb38f34290fff53d2dc520b5fbd299061/packages/focus-lock/src/index.tsx#L72
    setTimeout(() => {
      if (!panelRef.current) return
      const focusables = getAllFocusable(panelRef.current)
      if (!focusables || focusables.length < 1) return
      focus(focusables[0], { nextTick: true })
    }, 100)
  }, [isOpen, editor])

  useEffect(() => {
    // Reset the source picker if selection changes
    if (selectedMedia && canEditInMediaDrawer(selectedMedia.type)) {
      setCurrentSourceKey(selectedMedia.attrs.source || DEFAULT_SOURCE)
    }
  }, [selectedMedia])

  const [currentSourceKey, setCurrentSourceKey] = useState(DEFAULT_SOURCE)
  const currentSource = MediaSourcesMap[currentSourceKey]

  useEffect(() => {
    if (!currentSource) setCurrentSourceKey(DEFAULT_SOURCE) // Handle obsolete or missing source values
  }, [currentSource])

  const updateAttributes = useCallback(
    (attrs: Record<string, any>) => {
      updateSelectedMedia(editor, selection, currentSourceKey, attrs)
    },
    [editor, currentSourceKey, selection]
  )
  const currentAttributes = selectedMedia?.attrs || {}

  useUploadOnUnmount({
    currentAttributes,
    updateAttributes,
    editor,
    isMounted: isOpen,
  })

  const resetToPlaceholder = useCallback(() => {
    updateSelectedMedia(
      editor,
      selection,
      currentSourceKey,
      { source: currentSourceKey },
      'mediaPlaceholder',
      true
    )
  }, [currentSourceKey, selection, editor])

  const drawerSize = useDrawerSize()

  const isConnectedOrAvailableOffline = Boolean(
    isConnected || currentSource?.availableOffline
  )

  const editType = selectedMedia?.editType || 'node'
  const isValidSource = useCallback(
    (source: MediaSourceType) => {
      if (selectedMedia?.type.name === 'link') {
        // Don't allow images for links
        return source.nodeName === 'embed' || source.nodeName === 'video'
      } else if (selectedMedia?.type.name === 'smartLayoutCell') {
        // Only allow image sources for layouts
        return source.nodeName === 'image'
      }
      // Only return true if the source is valid as a node (not just a background)
      return !!source.nodeName
    },
    [selectedMedia]
  )

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      size={drawerSize}
      // trapFocus was breaking Loom's record flow, see G-448
      trapFocus={false}
    >
      <DrawerOverlay background="none" />
      <DrawerContent
        onKeyDown={forwardUndo}
        data-in-editor-focus
        data-testid="media-drawer"
        data-test-media-source={currentSourceKey}
      >
        <DrawerHeader>Media</DrawerHeader>
        <DrawerBody>
          <OfflineInfoBox
            isConnected={isConnectedOrAvailableOffline}
            label="Media options will be available when you reconnect."
            mb={6}
          />
          <MediaDrawerMenu
            currentSource={currentSource}
            onChange={setCurrentSourceKey}
            isValidSource={isValidSource}
          />
          {currentSource && (
            <Box
              ref={panelRef}
              opacity={isConnectedOrAvailableOffline ? 1 : 0.4}
              pointerEvents={isConnectedOrAvailableOffline ? 'initial' : 'none'}
            >
              <currentSource.Panel
                editor={editor}
                updateAttributes={updateAttributes}
                currentAttributes={currentAttributes}
                resetToPlaceholder={resetToPlaceholder}
                editType={editType}
              />
            </Box>
          )}
        </DrawerBody>
        <DrawerFooter>
          <ButtonGroup w="100%">
            <Button
              w="100%"
              onClick={onClose}
              variant="solid"
              data-testid="media-drawer-done"
            >
              Done
            </Button>
          </ButtonGroup>
        </DrawerFooter>
        <DrawerCloseButton />
      </DrawerContent>
    </Drawer>
  )
})

MediaDrawer.displayName = 'MediaDrawer'
