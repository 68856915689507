import { Box, Flex } from '@chakra-ui/react'
import { NodeViewProps } from '@tiptap/core'

import { useFeatureFlag } from 'modules/featureFlags'
import { CardViewedDebugger } from 'modules/tiptap_editor/TimeOnCard/CardViewedDebugger'

export const CardDebugPanel = (nodeViewProps: NodeViewProps) => {
  const debugCardViewed = useFeatureFlag('debugCardViewed')
  const { getPos, node } = nodeViewProps
  const { id: cardId } = node.attrs

  if (!debugCardViewed) return null

  return (
    <Flex
      left={8} // Make room for the drag handle
      top={2}
      zIndex="overlay"
      position="absolute"
      pointerEvents="none"
      opacity={0.5}
      contentEditable={false}
    >
      <Box bg="yellow" px={2} mr={2} borderRadius="md">
        {cardId}
      </Box>
      <Box bg="trueblue.200" px={2} mr={2} borderRadius="md">
        f:{getPos()} t:{getPos() + node.nodeSize}
      </Box>
      <CardViewedDebugger cardId={cardId} px={2} borderRadius="md" />
    </Flex>
  )
}
