import { Box } from '@chakra-ui/react'
import { Content, Editor, Extension } from '@tiptap/core'
import { Gapcursor } from '@tiptap/extension-gapcursor'
import { Strike } from '@tiptap/extension-strike'
import { Text } from '@tiptap/extension-text'
import {
  Dispatch,
  memo,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useDispatch } from 'react-redux'

import { config } from 'config'
import { Doc } from 'modules/api'
import { useFeatureFlag } from 'modules/featureFlags/hooks/useFeatureFlag'
import { useAppSelector } from 'modules/redux'
import { useScrollManager } from 'modules/scroll'
import { Theme } from 'modules/theming'
import { InsertWidget } from 'modules/tiptap_editor/components/menus/InsertWidget/InsertWidget'
import { findSelectionInsideNode } from 'modules/tiptap_editor/utils/selection/findSelectionInsideNode'
import { useCan } from 'modules/user'
import { useEffectWhen } from 'utils/hooks'

import { EditorModeEnum } from '..'
import { MediaDrawer } from '../components/drawers/MediaDrawer/MediaDrawer'
import { FormattingMenu } from '../components/menus/FormattingMenus/FormattingMenu'
import { BlockClass } from '../extensions/BlockClass'
import { Blockquote } from '../extensions/Blockquote/Blockquote'
import { Bold } from '../extensions/Bold'
import { Button } from '../extensions/buttons/Button'
import { ButtonGroup } from '../extensions/buttons/ButtonGroup'
import { CalloutBox } from '../extensions/CalloutBox/CalloutBox'
import { Card, getCardSelector } from '../extensions/Card'
import { CardCollapse } from '../extensions/Card/CardCollapse'
import { CardCommands } from '../extensions/Card/CardCommands'
import {
  CardIdsExtension,
  CardIdsPluginKey,
} from '../extensions/Card/CardIdMap/CardIdsExtension'
import { CardAccentLayoutItem } from '../extensions/Card/CardLayout/CardAccent/CardAccentLayoutItem'
import { CardLayoutItem } from '../extensions/Card/CardLayout/CardLayoutItem'
import { CardTableOfContents } from '../extensions/CardTableOfContents/CardTableOfContents'
import { Clipboard } from '../extensions/Clipboard'
import { CodeBlock } from '../extensions/code/CodeBlock'
import { CodeMark } from '../extensions/code/CodeMark'
import { Contributors } from '../extensions/Contributors/Contributors'
import { DocRoot, Document } from '../extensions/Document'
import {
  DocumentAttrsExtension,
  DocumentAttrsPluginKey,
} from '../extensions/Document/DocumentAttrs/DocumentAttrsExtension'
import { DropCursor } from '../extensions/DragDrop/DropCursor'
import { Drawing } from '../extensions/Drawing'
import { DynamicNodes } from '../extensions/DynamicNodes/DynamicNodesExtension'
import { EmojiNode } from '../extensions/Emoji'
import { EmptyNodes } from '../extensions/EmptyNodes'
import { FixRequiredAttrs } from '../extensions/FixRequiredAttrs'
import { FontSize } from '../extensions/Font/FontSize'
import { Footnote, FootnoteLabel } from '../extensions/Footnote'
import { HardBreak } from '../extensions/HardBreak'
import { Heading } from '../extensions/Heading/Heading'
import { HorizontalAlign } from '../extensions/HorizontalAlign'
import { Italic } from '../extensions/Italic'
import { KeyBoardCatchall, KeyMapOverride } from '../extensions/keyboard'
import { Layout } from '../extensions/Layout'
import { LayoutCell } from '../extensions/Layout/LayoutCell'
import { Link } from '../extensions/Link'
import { Bullet } from '../extensions/lists/Bullet'
import { List } from '../extensions/lists/List'
import { Numbered } from '../extensions/lists/Numbered'
import { Todo } from '../extensions/lists/Todo'
import { Math } from '../extensions/Math'
import { Embed } from '../extensions/media/Embed'
import { usePreventIframeScrollTop } from '../extensions/media/Embed/usePreventIframeScrollTop'
import { Gallery } from '../extensions/media/Gallery/Gallery'
import { Image } from '../extensions/media/Image'
import { Media } from '../extensions/media/MediaExtension'
import { MediaPlaceholder } from '../extensions/media/Placeholder'
import { Video } from '../extensions/media/Video'
import { CardMention, DocMention, UserMention } from '../extensions/Mention'
import { Migrate } from '../extensions/Migrate'
import { Paragraph } from '../extensions/Paragraph/Paragraph'
import { FocusHelpers } from '../extensions/selection/FocusHelpers'
import { SmartLayout, SmartLayoutCell } from '../extensions/SmartLayout'
import { Table } from '../extensions/tables/Table'
import { TableCommands } from '../extensions/tables/Table/TableCommands'
import { TableCell } from '../extensions/tables/TableCell'
import { TableRow } from '../extensions/tables/TableRow'
import { Highlight, TextColor } from '../extensions/TextColor'
import { Title } from '../extensions/Title/Title'
import { Toggle } from '../extensions/Toggle/Toggle'
import { Underline } from '../extensions/Underline'
import { UpdateAttributes } from '../extensions/UpdateAttributes'
import {
  useFixReactNodeViewGapCursors,
  useGlobalForwardUndo,
  useHandleCopyPasteNodeSelection,
  useHandlePasteCard,
} from '../hooks'
import { OnEditorRendered } from '../hooks/OnEditorRendered'
import { EditorIdProvider } from '../hooks/useEditorId'
import { useOnEditorRenderedControls } from '../hooks/useOnEditorRendered'
import { EditorContent, useEditor } from '../react'
import {
  reset,
  selectContentEditable,
  selectMode,
  selectPresentingCardId,
  setAnimationsEnabled,
  setCommentsEnabled,
  setDoc,
  setIsAllowedToEdit,
  setIsStatic,
  setTheme,
} from '../reducer'
import { editorStyles } from '../styles/editorStyles'
import { initializeState } from './utils'

declare module '@tiptap/core' {
  interface Editor {
    gammaOrgId?: string
    gammaDocId?: string
  }
}

/**
 * This is the core list of extensions that should be used for anything
 * that impacts the underlying prosemirror schema or global configuration.
 *
 * By default, this component is readOnly.
 *
 * Any extensions that need to be tested should run here.
 *
 * ANY TIME YOU CHANGE THIS LIST, MAKE SURE TO UPDATE ../schema.ts VERSION
 * if your change impacts the schema. Unsure? Check the tiptap schema generator:
 *
 * Nodes: https://github.com/ueberdosis/tiptap/blob/6c08057bb229135abc6f5244d2010569ef5d3561/packages/core/src/helpers/getSchemaByResolvedExtensions.ts#L47-L62
 * Marks: https://github.com/ueberdosis/tiptap/blob/6c08057bb229135abc6f5244d2010569ef5d3561/packages/core/src/helpers/getSchemaByResolvedExtensions.ts#L103-L112
 */

export const getBaseExtensions = ({
  isFootnoteEditor = false,
}: {
  isFootnoteEditor?: boolean
} = {}) => [
  // Doc + card structure
  DocRoot,
  Document,
  DocumentAttrsExtension,
  Card,
  CardIdsExtension.configure({
    // don't use the default card id redux generator when in a puppeteer
    // context, this data will be manually injected via a window variable
    enabled: !config.GAMMA_PUPPETEER_SERVICE,
  }),
  CardCollapse,
  CardCommands,
  CardLayoutItem,
  CardAccentLayoutItem,
  UpdateAttributes,

  // Text
  Text,
  HardBreak,
  Link.configure({
    openOnClick: false, // In the future, we might restore this for read-only
  }),
  CodeMark,
  Math,
  Bold,
  Italic,
  Underline,
  Strike,
  List,
  Bullet,
  Numbered,
  Todo,
  Title,
  Heading,
  Paragraph,
  Blockquote,
  CalloutBox,
  CodeBlock,
  HorizontalAlign,
  Highlight,
  Button,
  ButtonGroup,
  TextColor,
  FontSize,

  // Tables
  Table.extend({
    resizable: true,
    allowTableNodeSelection: true,
  }),
  TableRow,
  TableCell,
  TableCommands,

  // Media
  Media,
  MediaPlaceholder,
  Image,
  Video,
  Embed,
  Drawing,
  Gallery,

  // Other nodes
  UserMention,
  CardMention,
  DocMention,
  EmojiNode,
  Layout, // todo: rename this to GridLayout to distinguish
  LayoutCell,
  SmartLayout,
  SmartLayoutCell,
  Footnote,
  FootnoteLabel,
  Contributors,
  CardTableOfContents,
  Toggle,
  DynamicNodes,

  // Decorations
  EmptyNodes,
  BlockClass,

  // Non-node extensions
  FixRequiredAttrs,
  Clipboard,
  Migrate,
  DropCursor,
  Gapcursor,
  FocusHelpers,
  KeyMapOverride.configure({ addSelectionKeyMaps: !isFootnoteEditor }),
  KeyBoardCatchall,
]

const focusDocStart = (editorInstance: Editor) => {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      // Let the new state kick in
      requestAnimationFrame(() => {
        const $node = editorInstance.state.doc.resolve(1)
        const sel = findSelectionInsideNode($node)
        if (sel) {
          editorInstance.commands.command(({ tr }) => {
            tr.setSelection(sel)
            return true
          })
        }
        resolve()
      })
    })
  })
}

const useConfigureScrollManager = (scrollingSelector?: string) => {
  const scrollManager = useScrollManager('editor')
  const currentScrollingSelector = useAppSelector((state) => {
    // In doc mode the scrolling selector is identified by the parent provided scrollingParentSelector.
    // In slide mode, the currently presenting card is what scrolls.
    const mode = selectMode(state)
    const presentingCardId = selectPresentingCardId(state)
    return mode === EditorModeEnum.SLIDE_VIEW && presentingCardId
      ? getCardSelector(presentingCardId)
      : scrollingSelector
  })
  useEffect(() => {
    if (!currentScrollingSelector) return
    scrollManager.setScrollSelector(currentScrollingSelector)
  }, [scrollManager, currentScrollingSelector])
}

export interface OnCreateArgs {
  editor: Editor
}
export interface EditorCoreProps {
  extensions?: Extension[]
  onCreate?: ({ editor }: OnCreateArgs) => void
  doc?: Doc
  docId?: string
  readOnly: boolean
  /**
   * Whether this editor instance should support comments at all
   */
  shouldSupportComments?: boolean
  /**
   * Whether this editor instance should show menus (e.g. BubbleMenu, FloatingMenu, MediaDrawer).
   * This is always true for the CollaborativeEditor, and false for Snapshots, Filmstrip Previews, etc.
   */
  shouldSupportMenus?: boolean
  initialContent?: Content
  isStatic?: boolean // Prevent interactions (eg expanding/collapse cards)
  animationsEnabled?: boolean
  scrollingParentSelector?: string
  theme?: Theme
  editorId?: string
}

export const EditorCore = ({
  onCreate = () => {},
  extensions = [],
  readOnly = true,
  shouldSupportComments = false,
  shouldSupportMenus = false,
  initialContent = undefined,
  doc = undefined,
  docId = undefined,
  isStatic = false,
  animationsEnabled = true,
  scrollingParentSelector = undefined,
  theme,
  editorId,
}: EditorCoreProps): JSX.Element => {
  // Use the initial value of shouldSupportMenus and dont respond to it changing.
  const userCanEditDoc = useCan('edit', doc)
  const orgId = doc?.organization && doc.organization.id
  const [menusSupported] = useState(shouldSupportMenus)
  const [menusReady, setMenusReady] = useState(!shouldSupportMenus)
  const [tiptapReady, setTiptapReady] = useState(false)
  const dispatch = useDispatch()
  const editable = useAppSelector(selectContentEditable)
  const baseExtensions = useMemo(() => getBaseExtensions(), [])
  const enableDebugLogging = useFeatureFlag('debugLogging')
  const { setEditorRendered, onEditorUnload } =
    useOnEditorRenderedControls(editorId)

  usePreventIframeScrollTop(scrollingParentSelector)
  useConfigureScrollManager(scrollingParentSelector)

  const editor = useEditor(
    {
      async onCreate({ editor: editorInstance }: OnCreateArgs) {
        console.debug('[EditorCore][onCreate] TipTap editor is now ready')
        // Note that `onUpdate` fires before this for some reason
        // See https://github.com/ueberdosis/tiptap/issues/2583
        initializeState(editorInstance, dispatch)
        setTiptapReady(true)
      },
      onUpdate({ editor: editorInstance }: OnCreateArgs) {
        DocumentAttrsPluginKey.getState(editorInstance.state)?.processChanges(
          dispatch
        )

        CardIdsPluginKey.getState(editorInstance.state)?.processChanges(
          dispatch
        )
      },
      onSelectionUpdate({ editor: editorInstance }) {
        console.debug(
          '[EditorCore][onSelectionUpdate] selection updated',
          editorInstance.state.selection.from,
          editorInstance.state.selection.to,
          editorInstance.state.selection
        )
      },
      onDestroy() {
        if (enableDebugLogging) {
          console.warn(
            '[EditorCore][onDestroy] This should only happen on page navigation.'
          )
        }
        onEditorUnload()
        dispatch(reset())
      },
      extensions: baseExtensions.concat(extensions),
      content: initialContent,

      /**
       * Initialize the editor in readOnly mode. We'll set it to editable if
       * needed below once the editor has fully loaded.
       */
      editable: false,
    } /*[ editor dependencies ]*/
  )

  // Expected order is:
  //   1. Tiptap onCreate fires                     - tiptapReady=true
  //   2. Editor and Menus components load
  //   3. BubbleMenu prosemirror plugin(s) register - menusReady=true
  //   4. Focus is set on doc start pos=3           - focusDocStart()
  //   5. Parent onCreate is called                 - onCreate(editor)
  const fullyReady = Boolean(editor && tiptapReady)

  useEffect(() => {
    if (!theme) return
    dispatch(setTheme({ theme }))
  }, [theme, dispatch])

  useEffectWhen(
    () => {
      if (!editor || !fullyReady) return

      focusDocStart(editor).then(() => {
        console.debug(
          '[EditorCore][fullyReady] Menus initialized & focused at start - fully ready'
        )
        onCreate({ editor })
        requestAnimationFrame(() => {
          requestIdleCallback(() => {
            setEditorRendered()
          })
        })
      })
    },
    [editor, fullyReady, onCreate, setEditorRendered],
    [fullyReady]
  )

  useEffect(() => {
    // When the doc in the DocEditor reducer changes,
    // update the value in the Tiptap reducer to be in sync.
    if (doc) dispatch(setDoc({ doc }))
  }, [doc, dispatch])

  useEffect(() => {
    dispatch(setIsStatic({ isStatic }))
  }, [isStatic, dispatch])

  useEffect(() => {
    dispatch(setAnimationsEnabled({ animationsEnabled }))
  }, [animationsEnabled, dispatch])

  useEffect(() => {
    dispatch(setCommentsEnabled({ commentsEnabled: shouldSupportComments }))
  }, [shouldSupportComments, dispatch])

  useEffect(() => {
    if (!editor) return

    if (
      config.DEBUG_ENABLED &&
      typeof window['gammaEditorCore'] === 'undefined'
    ) {
      // @ts-ignore - For debugging purposes only
      window.gammaEditorCore = editor
    }

    editor.gammaOrgId = orgId
    editor.gammaDocId = docId

    return () => {
      // @ts-ignore - For debugging purposes only
      delete window.gammaEditorCore
    }
  }, [editor, docId, orgId])

  useEffect(() => {
    if (!editor) return

    // Monitor the readOnly prop and CASL permissions
    // These values are not expected to change often, if at all
    dispatch(
      setIsAllowedToEdit({
        isAllowedToEdit:
          userCanEditDoc && // Has CASL permission to edit doc
          !readOnly, // Parent has allowed editing
      })
    )
  }, [editor, dispatch, readOnly, userCanEditDoc])

  useEffect(() => {
    // Wait until fullyReady is true before setting editable
    // NB: This is specifically necessary for some odd states in Safari where
    // mutation events can fire recursively on load and cause the editor to crash.
    // See https://linear.app/gamma-app/issue/G-1534/mobile-editing-wiped-out-niks-ufo-gammaramma
    if (!editor || !fullyReady) return
    // Monitor the permission to edit and toggle the editor state accordingly
    requestAnimationFrame(() => {
      editor.setOptions({ editable })
    })
  }, [editor, dispatch, editable, fullyReady, setEditorRendered])

  useHandleCopyPasteNodeSelection(editor)
  useHandlePasteCard(editor)
  useGlobalForwardUndo(editor, editable)
  useFixReactNodeViewGapCursors(editor)

  if (!editor || !tiptapReady) return <></>

  return (
    <EditorIdProvider value={editorId}>
      <Box
        id="editor-core-root"
        className="editor-core-root"
        data-testid="editor-core-root"
        width="100%"
        position="relative"
        sx={editorStyles}
      >
        <EditorContent
          editor={editor}
          style={{ width: '100%', height: '100%' }}
          data-testid="tiptap-react-root-wrapper"
          className="highlight-mask"
        />
        {menusSupported && (
          <OnEditorRendered editorId={editorId}>
            <EditorMenus
              editor={editor}
              setReady={setMenusReady}
              scrollingParentSelector={scrollingParentSelector}
            />
          </OnEditorRendered>
        )}
      </Box>
    </EditorIdProvider>
  )
}

const EditorMenusComponent = ({
  editor,
  setReady,
  scrollingParentSelector,
}: {
  editor: Editor
  setReady: Dispatch<SetStateAction<boolean>>
  scrollingParentSelector?: string
}) => {
  // The FormattingMenu registers a Prosemirror plugin on mount,
  // so allow that to happen before calling the ready callback
  useEffect(() => {
    setReady(true)
  }, [setReady])

  return (
    <>
      <FormattingMenu
        editor={editor}
        scrollingParentSelector={scrollingParentSelector}
      />
      <InsertWidget editor={editor} />
      <MediaDrawer editor={editor} />
    </>
  )
}

const EditorMenus = memo(EditorMenusComponent)
