import { MAX_INDENT } from 'modules/tiptap_editor/extensions/lists/List'

import { BLOCK_MARGIN } from './constants'

const indents = [...Array(MAX_INDENT + 1).keys()]
const INDENT_MARGIN = '1.6em'

export const BULLET_STYLES = ['disc', 'circle', 'square']

export const createUnorderedListStyle = (i: number) => ({
  listStyle: BULLET_STYLES[i % BULLET_STYLES.length],
  paddingLeft: '0.4em',
  marginLeft: `calc(${i + 1} * ${INDENT_MARGIN} - 0.4em)`,
})

const listClasses = '.block-bullet, .block-numbered, .block-todo'
export const listStyles = {
  [listClasses]: {
    margin: '0',
    marginBottom: '0.5em',
    textAlign: 'left',

    '&:first-of-type': {
      marginTop: BLOCK_MARGIN,
    },
  },

  'ol.block-numbered': {
    listStyle: 'none',
  },

  '.block-todo': {
    listStyle: 'none',
    '.chakra-checkbox__control': {
      width: '1em',
      height: '1em',
      top: '0.3em',
      position: 'relative',
      borderRadius: 'var(--box-border-radius)',
      borderColor: 'var(--link-color)',
      borderWidth: '0.125em',
      '&[data-checked]': {
        backgroundColor: 'var(--link-color)',
        color: 'var(--body-color-inverted)',
      },
      svg: {
        fontSize: '0.5em',
      },
    },
  },

  '.block-bullet, .block-todo': {
    li: {
      position: 'relative',
    },
  },
}
indents.forEach((i) => {
  const selector = `ol.block-numbered[indent="${i}"]`
  listStyles[selector] = {
    'li[data-node-view-content]': {
      marginLeft: `calc(${i + 1} * ${INDENT_MARGIN})`,
      position: 'relative',
      _before: {
        position: 'absolute',
        left: `-${INDENT_MARGIN}`,
        content: `attr(data-list-counter) "."`,
      },
    },
  }
})

indents.forEach((i) => {
  listStyles[`ul.block-bullet[indent="${i}"]`] = {
    'li[data-node-view-content]': createUnorderedListStyle(i),
  }

  listStyles[`ul.block-todo[indent="${i}"]`] = {
    'li.todo': {
      marginLeft: `calc(${i + 1} * ${INDENT_MARGIN})`,
      listStyle: 'none',
    },
    '.chakra-checkbox__control': {
      left: `-${INDENT_MARGIN}`,
    },
  }
})
