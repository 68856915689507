import {
  Button,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'
import { Editor } from '@tiptap/core'

import { preventDefaultToAvoidBlur } from 'utils/handlers'

import { ToolbarButtonProps } from '../buttons/ToolbarButton'
import {
  ConvertCommandGroups,
  TextConvertCommands,
} from './TextConvertCommands'
import { checkCommandDisabled } from './TextFormattingCommands'
import { focusEditorOnMenuClose } from './utils'

type TextCollapseMenuProps = Omit<ToolbarButtonProps, 'onClick'> & {
  editor: Editor
}
export const TextConvertMenu = ({
  editor,
  testId,
  disabled,
  icon: toolbarIcon,
  label,
}: TextCollapseMenuProps) => {
  const enabledCommands = TextConvertCommands.filter(
    (command) => command.apply && !checkCommandDisabled(editor, command)
  )
  if (enabledCommands.length === 0) return null

  return (
    <Menu isLazy onClose={() => focusEditorOnMenuClose(editor)}>
      <GammaTooltip placement="top" label={label}>
        <MenuButton
          isDisabled={disabled}
          as={Button}
          variant="toolbar"
          data-testid={testId}
          onMouseDown={preventDefaultToAvoidBlur}
          rightIcon={
            <FontAwesomeIcon
              icon={regular('chevron-down')}
              transform="shrink-6"
            />
          }
        >
          {toolbarIcon && <FontAwesomeIcon icon={toolbarIcon} />}
        </MenuButton>
      </GammaTooltip>
      <MenuList maxH="min(25em, 45vh)" overflowY="auto" maxW="400px">
        {Object.entries(ConvertCommandGroups).map(
          ([group, { label: groupLabel }]) => {
            const commands = enabledCommands.filter(
              (command) => command.group === group
            )
            if (commands.length === 0) return null
            return (
              <MenuGroup key={group} title={groupLabel}>
                {commands.map(({ key, icon, name, description, apply }) => {
                  return (
                    <MenuItem
                      key={key}
                      value={key}
                      icon={<FontAwesomeIcon icon={icon} fixedWidth />}
                      onClick={() => apply!(editor)}
                      data-testid={`text-convert-menu-${key}`}
                    >
                      <Text>{name}</Text>
                      {description && (
                        <Text color="gray.400" fontSize="sm">
                          {description}
                        </Text>
                      )}
                    </MenuItem>
                  )
                })}
              </MenuGroup>
            )
          }
        )}
      </MenuList>
    </Menu>
  )
}
