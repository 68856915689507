// @ts-nocheck
// See `update` and `mount` below for ----- FORKED CHANGES -----
import {
  NodeView,
  NodeViewProps,
  NodeViewRenderer,
  NodeViewRendererOptions,
  NodeViewRendererProps,
} from '@tiptap/core'
import {
  Node as ProseMirrorNode,
  Mark as ProseMirrorMark,
} from 'prosemirror-model'
import { Decoration, NodeView as ProseMirrorNodeView } from 'prosemirror-view'
import React from 'react'

import { isiOS } from 'utils/deviceDetection'

import { computeDragAnnotationData } from '../extensions/Annotatable/utils'
import { UpdateFn } from '../extensions/updateFns'
import { Editor } from './Editor'
import { ReactRenderer } from './ReactRenderer'
import {
  ReactNodeViewContext,
  ReactNodeViewContextProps,
} from './useReactNodeView'

export interface ReactNodeViewRendererOptions extends NodeViewRendererOptions {
  update: UpdateFn | null
  updateWrapperEl?: (el: Element, props: NodeViewProps) => void
  as?: string
}

class ReactNodeView extends NodeView<
  React.FunctionComponent,
  Editor,
  ReactNodeViewRendererOptions
> {
  // ----- FORKED CHANGES -----
  // renderer!: ReactRenderer

  // contentDOMElement!: HTMLElement | null

  updateAttributes(attributes: {}) {
    // this replaces the extended NodeView.updateAttributes to support annotation moving
    this.editor.commands.command(({ tr }) => {
      const pos = this.getPos()

      // If the node unmounts before this is called (e.g. via settimeout), setNodeMarkup
      // will error. This will bail instead.
      if (pos === undefined) return

      const payload: MoveAnnotationEvent = {
        type: 'move',
        insertPos: pos,
        insertPosRaw: pos,
        pos,
        end: pos + this.node.content.size + 1,
      }

      tr.setNodeMarkup(pos, undefined, {
        ...this.node.attrs,
        ...attributes,
      }).setMeta('annotationEvent', payload)

      return true
    })
  }
  // ----- END FORKED CHANGES -----

  mount() {
    const props: NodeViewProps = {
      editor: this.editor,
      node: this.node,
      decorations: this.decorations,
      selected: false,
      extension: this.extension,
      getPos: () => this.getPos(),
      updateAttributes: (attributes = {}) => this.updateAttributes(attributes),
      deleteNode: () => this.deleteNode(),
    }

    if (!(this.component as any).displayName) {
      const capitalizeFirstChar = (string: string): string => {
        return string.charAt(0).toUpperCase() + string.substring(1)
      }

      this.component.displayName = capitalizeFirstChar(this.extension.name)
    }

    const ReactNodeViewProvider: React.FunctionComponent = (componentProps) => {
      const Component = this.component
      const onDragStart = this.onDragStart.bind(this)
      const nodeViewContentRef: ReactNodeViewContextProps['nodeViewContentRef'] =
        (element) => {
          if (
            element &&
            this.contentDOMElement &&
            element.firstChild !== this.contentDOMElement
          ) {
            element.appendChild(this.contentDOMElement)
          }
        }

      return (
        <ReactNodeViewContext.Provider
          value={{
            onDragStart,
            nodeViewContentRef,
            node: this.node,
            editor: this.editor,
            getPos: this.getPos,
          }}
        >
          <Component {...componentProps} />
        </ReactNodeViewContext.Provider>
      )
    }

    ReactNodeViewProvider.displayName = 'ReactNodeView'

    this.contentDOMElement = this.node.isLeaf
      ? null
      : document.createElement(
          // ----- FORKED CHANGES -----
          this.node.isInline || this.node instanceof ProseMirrorMark
            ? // ----- END FORKED CHANGES -----
              'span'
            : 'div'
        )

    if (this.contentDOMElement) {
      // For some reason the whiteSpace prop is not inherited properly in Chrome and Safari
      // With this fix it seems to work fine
      // See: https://github.com/ueberdosis/tiptap/issues/1197
      this.contentDOMElement.style.whiteSpace = 'inherit'
      // ----- FORKED CHANGES -----
      // This helps us target inner divs via sx
      this.contentDOMElement.setAttribute(
        'data-node-view-content-inner',
        this.node.type.name
      )
      // ----- END FORKED CHANGES -----
    }

    let as = this.node.isInline ? 'span' : 'div'

    if (this.options.as) {
      as = this.options.as
    }

    const { className = '' } = this.options

    this.renderer = new ReactRenderer(ReactNodeViewProvider, {
      editor: this.editor,
      props,
      as,
      className: `node-${this.node.type.name} ${className}`.trim(),
      // ----- FORKED CHANGES -----
      updateWrapperEl: this.options.updateWrapperEl,
      // ----- END FORKED CHANGES -----
    })
  }

  get dom() {
    if (
      this.renderer.element.firstElementChild &&
      !this.renderer.element.firstElementChild?.hasAttribute(
        'data-node-view-wrapper'
      )
    ) {
      throw Error(
        'Please use the NodeViewWrapper component for your node view.'
      )
    }

    return this.renderer.element as HTMLElement
  }

  get contentDOM() {
    if (this.node.isLeaf) {
      return null
    }

    return this.contentDOMElement
  }

  update(node: ProseMirrorNode, decorations: Decoration[]) {
    const updateProps = (props?: Record<string, any>) => {
      this.renderer.updateProps(props)
    }

    if (node.type !== this.node.type) {
      return false
    }

    if (typeof this.options.update === 'function') {
      const oldNode = this.node
      const oldDecorations = this.decorations

      this.node = node
      this.decorations = decorations

      return this.options.update({
        oldNode,
        oldDecorations,
        newNode: node,
        newDecorations: decorations,
        updateProps: () => updateProps({ node, decorations }),
      })
    }

    if (node === this.node && this.decorations === decorations) {
      return true
    }

    this.node = node
    this.decorations = decorations

    updateProps({ node, decorations })

    return true
  }

  selectNode() {
    this.renderer.updateProps({
      selected: true,
    })
  }

  deselectNode() {
    this.renderer.updateProps({
      selected: false,
    })
  }

  destroy() {
    this.renderer.destroy()
    this.contentDOMElement = null
  }
  // ----- FORKED CHANGES -----
  //  Implement ignoreMutation from base @tiptap-core/NodeView.ts to consider it with our own
  ignoreMutation(
    mutation: MutationRecord | { type: 'selection'; target: Element }
  ) {
    if (!this.dom || !this.contentDOM) {
      return true
    }

    if (typeof this.options.ignoreMutation === 'function') {
      // If the consumer has provided an ignoreMutation function, check that first and if
      // it's false, fallback to whatever the original function would return.
      return (
        this.options.ignoreMutation({ mutation }) ||
        this.defaultIgnoreMutation(mutation)
      )
    }

    return this.defaultIgnoreMutation(mutation)
  }

  // Forked from https://github.com/ueberdosis/tiptap/blob/53e39d0c470368987580e18dbea02864edd60ec6/packages/core/src/NodeView.ts#L186
  defaultIgnoreMutation(
    mutation: MutationRecord | { type: 'selection'; target: Element }
  ) {
    // a leaf/atom node is like a black box for ProseMirror
    // and should be fully handled by the node view
    if (this.node.isLeaf || this.node.isAtom) {
      return true
    }

    // Allows selecting inside comments without bubbling up to the parent editor
    // We have to put this here because every annotatable block would need it.
    if (
      mutation.type === 'selection' &&
      mutation.target.closest('[data-comments-wrapper]')
    ) {
      return true
    }

    // ProseMirror should handle any other selections
    if (mutation.type === 'selection') {
      return false
    }

    // try to prevent a bug on iOS that will break node views on enter
    // this is because ProseMirror can’t preventDispatch on enter
    // this will lead to a re-render of the node view on enter
    // see: https://github.com/ueberdosis/tiptap/issues/1214
    if (
      this.dom.contains(mutation.target) &&
      mutation.type === 'childList' &&
      isiOS() &&
      this.editor.isFocused
    ) {
      const changedNodes = [
        ...Array.from(mutation.addedNodes),
        ...Array.from(mutation.removedNodes),
      ] as HTMLElement[]

      // we’ll check if every changed node is contentEditable
      // to make sure it’s probably mutated by ProseMirror
      if (changedNodes.every((node) => node.isContentEditable)) {
        return false
      }
    }

    // we will allow mutation contentDOM with attributes
    // so we can for example adding classes within our node view
    if (this.contentDOM === mutation.target && mutation.type === 'attributes') {
      return true
    }

    // ProseMirror should handle any changes within contentDOM
    if (this.contentDOM.contains(mutation.target)) {
      return false
    }

    return true
  }

  /**
   * A custom (simplified) version of onDragStart for NodeViews that looks
   * for the drag handle inside the nodeview and sets the preview image accordingly.
   */
  onDragStart(event: DragEvent) {
    const target = event.target as HTMLElement

    // Get the drag handle element, which will also be used for the drag preview
    // Target here will be the NodeViewWrapper
    let dragHandle = target.querySelector('[data-drag-handle]')
    if (!dragHandle && target.hasAttribute('data-drag-handle')) {
      dragHandle = target
    }

    if (!this.dom || !dragHandle) {
      // NOTE: this prevents things like dragging images / text from outside of editor content
      // if a drag start event makes it to this handler, then we only want to allow
      // drag start to happen if there is a [data-drag-handle]
      // GlobalDragHandle dom node does not live in the same tree as the Prosemirror Doc
      // and the ContainerDragHandle intentionally calls stopPropagation on the event
      event.preventDefault()
      return false
    }

    try {
      // compute annotation data to move comments / reactions
      const domPos = this.editor.view.posAtDOM(dragHandle)
      if (!domPos || domPos == -1) {
        return
      }
      const node = this.editor.state.doc.nodeAt(domPos)
      const sel = this.editor.state.selection
      if (!sel.empty && domPos > sel.from && domPos < sel.to) {
        this.editor.commands.setNodeSelection(domPos)
      }
      if (!node) {
        return
      }

      const dragData = computeDragAnnotationData({
        from: domPos,
        to: domPos + node.nodeSize,
        editor: this.editor,
        pos: domPos,
      })

      if (dragData) {
        // do in RAF because view.dragstart hasn't been called yet and that
        // sets editor.view.dragging to be an object
        requestAnimationFrame(() => {
          if (this.editor.view.dragging?.annotations) {
            // dragStart gets called twice for some reason, if view.dragging.annotations
            // is already set then no-op
            return
          }

          if (
            this.editor.view.dragging != null &&
            typeof this.editor.view.dragging == 'object'
          ) {
            this.editor.view.dragging.annotations = dragData
          }
        })
      }
    } catch (err) {
      console.warn(
        '(caught) [ReactNodeViewRenderer] onDragStart error moving annotations',
        err
      )
    }

    let x = 0
    let y = 0

    // Remove any existing cloned drag preview elements, as
    // there should only ever be 1 of them in the DOM
    // For some reason, this function sometimes fires twice per drag
    document
      .querySelectorAll('[data-nodeview-drag-preview]')
      .forEach((el) => el.parentNode.removeChild(el))

    // Clone the el to use and append it to the dom
    // This allows us to resize it before using it for the preview
    const dragPreviewEl = dragHandle.cloneNode(true)
    document.body.appendChild(dragPreviewEl)
    dragPreviewEl.dataset.nodeviewDragPreview = true
    dragPreviewEl.style.maxWidth = '250px'
    dragPreviewEl.style.width = getComputedStyle(dragHandle).width

    const removePreview = () =>
      dragPreviewEl.parentElement?.removeChild(dragPreviewEl)

    // Make sure to remove the cloned preview element when the drag completes
    document.addEventListener('dragend', removePreview, { once: true })
    document.addEventListener('drop', removePreview, { once: true })
    document.addEventListener('mouseup', removePreview, { once: true })

    // calculate offset for drag element if we use a different drag handle element
    if (this.dom !== dragHandle) {
      // In React, we have to go through nativeEvent to reach offsetX/offsetY.
      const offsetX = event.offsetX ?? (event as any).nativeEvent?.offsetX
      const offsetY = event.offsetY ?? (event as any).nativeEvent?.offsetY

      x = offsetX
      y = offsetY
    }

    event.dataTransfer?.setDragImage(dragPreviewEl, x, y)
  }
  // ----- END FORKED CHANGES -----
}

export function ReactNodeViewRenderer(
  component: any,
  options?: Partial<ReactNodeViewRendererOptions>
): NodeViewRenderer {
  return (props: NodeViewRendererProps) => {
    // try to get the parent component
    // this is important for vue devtools to show the component hierarchy correctly
    // maybe it’s `undefined` because <editor-content> isn’t rendered yet
    if (!(props.editor as Editor).contentComponent) {
      return {}
    }

    return new ReactNodeView(
      component,
      props,
      options
    ) as unknown as ProseMirrorNodeView
  }
}

export { ReactNodeView }
