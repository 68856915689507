import {
  Flex,
  Box,
  Text,
  Checkbox,
  FormControl,
  Switch,
} from '@chakra-ui/react'
import React, { memo } from 'react'

import { featureFlags, FlagExplanation } from '../FeatureFlagProvider'

type FeatureFlagListItemProps = {
  currentValue: any
  flagKey: string
} & Pick<FlagExplanation, 'overrideValue' | 'source'>

export const FeatureFlagListItem: React.FC<FeatureFlagListItemProps> = memo(
  ({ flagKey, source, currentValue, overrideValue }) => {
    return (
      <Flex justify="space-between" align="center">
        <Box>
          <Text>
            <strong>{flagKey}</strong>
          </Text>
          <Text fontSize="sm" color="gray.700">
            <Checkbox
              isChecked={overrideValue !== undefined}
              onChange={(e) => {
                const newValue = e.target.checked
                if (newValue) {
                  featureFlags.overrideFlag(flagKey as any, currentValue as any)
                } else {
                  featureFlags.removeOverride(flagKey as any)
                }
              }}
            >
              override
            </Checkbox>
          </Text>
        </Box>

        <Flex direction="column" align="stretch">
          <FormControl
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
          >
            <Flex direction="column" alignItems="flex-end">
              <Text fontSize={10} fontStyle="italic">
                {source}
              </Text>
              <Switch
                id={`${flagKey}-switch`}
                isDisabled={overrideValue === undefined}
                isChecked={Boolean(currentValue)}
                onChange={(e) => {
                  featureFlags.overrideFlag(flagKey as any, e.target.checked)
                }}
              />
            </Flex>
          </FormControl>
        </Flex>
      </Flex>
    )
  }
)

FeatureFlagListItem.displayName = 'FeatureFlagListItem'
