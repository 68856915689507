import { GapCursor } from 'prosemirror-gapcursor'

import { getDocFlags } from '../../DocFlags/docFlags'
import { isCardLayoutItemNode } from '../utils'

let patched = false

export const monkeyPatchGapCursorForCardLayouts = () => {
  if (patched) return

  // @ts-ignore we're monkey patching this
  const originalGapCursorValid = GapCursor.valid
  // @ts-ignore we're monkey patching this
  GapCursor.valid = ($pos: ResolvedPos) => {
    const docFlags = getDocFlags($pos.doc)
    // if card layouts aren't enabled we can trust the original
    if (!docFlags.cardLayoutsEnabled) {
      // @ts-ignore
      return originalGapCursorValid($pos)
    }
    const isValid = originalGapCursorValid($pos)
    if (
      isValid &&
      (($pos.nodeAfter && isCardLayoutItemNode($pos.nodeAfter)) ||
        ($pos.nodeBefore && isCardLayoutItemNode($pos.nodeBefore)))
    ) {
      // if card layouts are enabled, then don't allow gap cursor AT cardLayoutItem
      // which is between card and cardLayoutItem
      return false
    }

    return isValid
  }
  patched = true
}
