import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import { FeatureFlags } from 'modules/featureFlags'
import { NodeInsertMethods } from 'modules/segment'
import { areListNodesSelected } from 'modules/tiptap_editor/extensions/lists/utils'
import { ListToSmartLayoutCommands } from 'modules/tiptap_editor/extensions/SmartLayout/commands'

import { COMMANDS_MAP, trackItemInserted } from '../../../commands'
import { canWrapSelection } from '../../../utils'
import { TextConvertCommand } from './types'

type ConvertGroup = {
  label: string
  featureFlag?: keyof FeatureFlags
}

export const ConvertCommandGroups: Record<
  TextConvertCommand['group'],
  ConvertGroup
> = {
  list: { label: 'Transform list' },
  wrap: { label: 'Wrap inside' },
}

export const TextConvertCommands: TextConvertCommand[] = [
  {
    key: 'toggle',
    name: 'Toggle',
    icon: regular('play'),
    node: 'toggle',
    description:
      'Turn this into a collapsible section. Highlight key points and hide details',
    featureFlag: 'toggle',
    apply: (editor) => {
      trackItemInserted(COMMANDS_MAP.toggle, NodeInsertMethods.FORMATTING_MENU)
      editor.chain().wrapWithToggle().run()
    },
    group: 'wrap',
  },
  {
    key: 'convertToNestedCard',
    name: 'Nested card',
    node: 'card',
    description:
      'Put this content into its own card. Good for bigger, self-contained ideas',
    icon: regular('diagram-subtask'),
    apply: (editor) => {
      trackItemInserted(
        COMMANDS_MAP.insertCardInside,
        NodeInsertMethods.FORMATTING_MENU
      )
      editor.chain().convertToNestedCard().run()
    },
    checkDisabled: (editor) =>
      !editor.schema.nodes.card || !canWrapSelection(editor, 'card'),
    group: 'wrap',
  },
  {
    key: 'blockquote',
    name: 'Blockquote',
    node: 'blockquote',
    description: 'Pull out quotes or text for emphasis',
    icon: regular('block-quote'),
    shortcut: '> Quote',
    apply: (editor) => editor.chain().wrapWithAnnotations('blockquote').run(),
    checkDisabled: (editor) => !canWrapSelection(editor, 'blockquote'),
    group: 'wrap',
  },
  {
    key: 'calloutBox',
    name: 'Info box',
    node: 'calloutBox',
    description: 'Call out important info with a box and icon',
    icon: regular('info-circle'),
    apply: (editor) =>
      editor
        .chain()
        .wrapWithAnnotations('calloutBox', { variant: 'info' })
        .run(),
    checkDisabled: (editor) => !canWrapSelection(editor, 'calloutBox'),
    group: 'wrap',
  },
  {
    key: 'listToCards',
    name: 'Cards from outline',
    icon: regular('rectangle-history'),
    description: 'Turn bullet points into cards',
    group: 'list',
    apply: (editor) => editor.commands.listToCards(),
    checkDisabled: (editor) => !areListNodesSelected(editor),
  },
  ...ListToSmartLayoutCommands,
]
