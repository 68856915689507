import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Editor, JSONContent } from '@tiptap/core'

import { fetchTextCompletion } from 'modules/ai/openai'
import { FeatureFlags } from 'modules/featureFlags'
import { NodeInsertMethods } from 'modules/segment'
import { TextFormattingCommand } from 'modules/tiptap_editor/components/menus/FormattingMenus/TextFormattingCommands'
import {
  absoluteToRelativeRange,
  relativeToAbsoluteRange,
} from 'modules/tiptap_editor/utils/relativePosition'

import { COMMANDS_MAP, trackItemInserted } from '../../../commands'
import { parseMarkdownToSlice, rangeToMarkdown } from '../../Clipboard/markdown'
import {
  summarizeBulletsPrompt,
  summarizeToCards,
  summarizeToToggles,
} from './summarize'

export interface RewriteCommand extends TextFormattingCommand {
  description?: string
  type: 'summarize' | 'collapse' | 'expand'
  apply?: (editor: Editor) => Promise<void> | void
  featureFlag?: keyof FeatureFlags
}

type RewriteType = {
  label: string
  featureFlag?: keyof FeatureFlags
}

export const RewriteTypes: Record<string, RewriteType> = {
  summarize: { label: 'Summarize' },
  expand: { label: 'Expand' },
}

export const TextRewriteCommands: RewriteCommand[] = [
  {
    key: 'summarize.toggles',
    type: 'summarize',
    name: 'Summarize as toggles',
    icon: regular('chevrons-down'),
    featureFlag: 'toggle',
    node: 'toggle',
    description: 'Use AI to generate headings and collapse details under them',
    apply: (editor) => {
      const { selection, schema, doc } = editor.state
      const { from, to } = selection
      const relativeRange = absoluteToRelativeRange(editor.state, { from, to })
      const selectedText = rangeToMarkdown(doc, from, to)
      return summarizeToToggles(selectedText, schema).then((toggles) => {
        trackItemInserted(COMMANDS_MAP.toggle, NodeInsertMethods.SUMMARIZE)
        const range = relativeToAbsoluteRange(editor.state, relativeRange)
        if (!range) {
          throw Error('Error finding replaced content')
        }
        editor
          .chain()
          .insertContentAt(range, toggles, { updateSelection: false })
          .run()
      })
    },
  },
  {
    key: 'summarize.smartLayout',
    type: 'summarize',
    name: 'Visualize key points',
    description:
      'Automatically highlight key points, keeping the details below',
    icon: regular('list-timeline'),
    featureFlag: 'smartLayout',
    node: 'smartLayout',
    apply: (editor) => {
      const { selection, doc } = editor.state
      const { from, to } = selection
      const relativeRange = absoluteToRelativeRange(editor.state, { from, to })
      const selectedText = rangeToMarkdown(doc, from, to)
      const prompt = summarizeBulletsPrompt(selectedText)
      const layoutVariant = 'textBoxes'
      return fetchTextCompletion(prompt, 'raw', { temperature: 0.7 }).then(
        (result) => {
          const range = relativeToAbsoluteRange(editor.state, relativeRange)
          if (!range) {
            throw Error('Error finding replaced content')
          }
          const bullets = result
            .split('\n')
            .map((line) => line.trim())
            .map((line) => {
              if (line.startsWith('-')) {
                return line.slice(1).trim()
              }
              return line
            })

          const layoutContent: JSONContent[] = bullets.map((text) => {
            return {
              type: 'smartLayoutCell',
              content: [
                {
                  type: 'heading',
                  attrs: {
                    level: 3,
                  },
                  content: [
                    {
                      type: 'text',
                      text,
                    },
                  ],
                },
              ],
            }
          })

          trackItemInserted(
            COMMANDS_MAP[`smartLayout-${layoutVariant}`],
            NodeInsertMethods.SUMMARIZE
          )
          editor
            .chain()
            .setTextSelection(range)
            .wrapWithToggle('Details', false)
            .insertContentAt(range.from - 1, {
              type: 'smartLayout',
              attrs: {
                variantKey: layoutVariant,
              },
              content: layoutContent,
            })
            .selectInsertedNode()
            .scrollIntoView()
            .run()
        }
      )
    },
  },
  {
    key: 'summarize.cards',
    type: 'summarize',
    name: 'Split into cards',
    description: 'Split your text into multiple cards with AI-generated titles',
    icon: regular('rectangle-history'),
    node: 'card',
    apply: (editor) => {
      const { selection, schema, doc } = editor.state
      const { from, to } = selection
      const selectedText = rangeToMarkdown(doc, from, to)
      const relativeRange = absoluteToRelativeRange(editor.state, { from, to })
      return summarizeToCards(selectedText, schema).then((slice) => {
        const range = relativeToAbsoluteRange(editor.state, relativeRange)
        if (!range) {
          throw Error('Error finding replaced content')
        }
        const cards = slice.content.toJSON()
        trackItemInserted(
          COMMANDS_MAP.insertCardInside,
          NodeInsertMethods.SUMMARIZE
        )
        editor
          .chain()
          .insertContentAt(range, cards, { updateSelection: false })
          .run()
      })
    },
  },
  {
    key: 'expand.paragraphs',
    type: 'expand',
    name: 'Expand text',
    icon: regular('up-down'),
    description:
      'Take a short summary or bullets and make it longer automatically',
    apply: (editor) => {
      const { selection, doc, schema } = editor.state
      const { from, to } = selection
      const selectedText = rangeToMarkdown(doc, from, to)
      const relativeRange = absoluteToRelativeRange(editor.state, { from, to })
      return fetchTextCompletion(selectedText, 'expand', {
        temperature: 0.7,
      }).then((result) => {
        const range = relativeToAbsoluteRange(editor.state, relativeRange)
        if (!range) {
          throw Error('Error finding replaced content')
        }
        const slice = parseMarkdownToSlice(result, schema)
        editor
          .chain()
          .insertContentAt(range, slice.content.toJSON(), {
            updateSelection: false,
          })
          .run()
      })
    },
    featureFlag: 'aiRewrite',
  },
]
