import { SmartLayoutVariant } from '../types'
import { Bullets } from './Bullets'
import { ImagesWithText } from './ImagesWithText'
import { Sequence } from './Sequence'
import { TextBoxes } from './TextBoxes'
import { Timeline } from './Timeline'

const DefaultVariant = Bullets
export const SmartLayoutVariants: SmartLayoutVariant[] = [
  Bullets,
  TextBoxes,
  ImagesWithText,
  Timeline,
  Sequence,
]

const SmartLayoutVariantsMap: Record<string, SmartLayoutVariant> =
  SmartLayoutVariants.reduce((acc, variant) => {
    acc[variant.key] = variant
    return acc
  }, {})

export const getSmartLayoutVariant = (key?: string): SmartLayoutVariant => {
  return (key && SmartLayoutVariantsMap[key]) || DefaultVariant
}
