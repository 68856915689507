import { ReactNodeViewRendererOptions } from '../react'

export const ignoreDataMutation: ReactNodeViewRendererOptions['ignoreMutation'] =
  ({ mutation }) => {
    // Ignore data attributes added to the layout
    // Specifically, in GlobalDragHandle this prevents an infinite loop
    const isProgrammaticAttrChange =
      mutation.type === 'attributes' &&
      mutation.attributeName?.startsWith('data-')

    if (isProgrammaticAttrChange) {
      return true
    } else {
      return false
    }
  }
