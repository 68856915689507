// See https://github.com/missive/emoji-mart/issues/716
// We need to augment the Data.natives object with our own map because it seems
// that the emoji data in @emoji-mart/data uses different characters from the ones
// that the macOS keyboard inputs (at least in Chrome?). Their unicode is different.
// The dataset is here: https://github.com/missive/emoji-mart/blob/052badf03695a16390188efcc4b115ccb3346b13/packages/emoji-mart-data/sets/14/native.json

import { NativeEmojiToIdDict } from './types'

// Resources:
// 1) Codesandbox for validating that emoji native lookup doesn't work: https://zopvw4.csb.app
// 2) Full Emoji List, v14.0: https://www.unicode.org/emoji/charts-14.0/full-emoji-list.html
// 3) Emoji Default Style Values, v14.0: https://unicode.org/emoji/charts-14.0/emoji-style.html
//    A lot of the emoji in this list didn't work in our app so I migrated every single one

export const extendEmojiMartNativeDict: NativeEmojiToIdDict = {
  // Broken emoji that we found on our own
  '⭐️': 'star',
  '☕️': 'coffee',
  '❤': 'heart',
  '☺': 'relaxed',

  // From https://chrome.google.com/webstore/detail/emoji-keyboard-emojis-for/fbcgkphadgmbalmlklhbdagcicajenei?hl=en-US
  '❤️️': 'heart',
  '🗨': 'left_speech_bubble',
  '🏌': 'golfer',
  '️🀄️': 'mahjong',
  '⚡️': 'lightning',
  '1⃣': 'one',
  '2⃣': 'two',
  '3⃣': 'three',
  '4⃣': 'four',
  '5⃣': 'five',
  '6⃣': 'six',
  '7⃣': 'seven',
  '8⃣': 'eight',
  '9⃣': 'nine',
  '0⃣': 'zero',
  '◽️': 'white_medium_small_square',

  // Found by copy pasting into deck from here: https://copy.r74n.com/emojis, pasting the emoji in
  // our app and finding broken question marks.
  // Also validated that lookups didn't work at https://zopvw4.csb.app/.
  '☔️': 'umbrella_with_rain_drops',
  '⛄️': 'snowman_without_snow',
  '⚽️': 'soccer',
  '⛵️': 'boat',
  '⛲️': 'fountain',
  '⛺️': 'tent',
  '⌚️': 'watch',
  '⌛️': 'hourglass',
  '⚒': 'hammer_and_pick',
  '♈︎': 'aries',
  '♈️': 'aries',
  '♉️': 'taurus',
  '♉︎': 'taurus',
  '♊️': 'gemini',
  '♊︎': 'gemini',
  '♋️': 'cancer',
  '♋︎': 'cancer',
  '♌️': 'leo',
  '♌︎': 'leo',
  '♍️': 'virgo',
  '♍︎': 'virgo',
  '♎️': 'libra',
  '♎︎': 'libra',
  '♏️': 'scorpius',
  '♏︎': 'scorpius',
  '♐️': 'sagittarius',
  '♐︎': 'sagittarius',
  '♑️': 'capricorn',
  '♑︎': 'capricorn',
  '♒️': 'aquarius',
  '♒︎': 'aquarius',
  '♓️': 'pisces',
  '♓︎': 'pisces',
  '⭕️': 'o',
  '❗️': 'exclamation',
  '♿️': 'wheelchair',
  '⚪️': 'white_circle',
  '◾️': 'black_medium_small_square',
  '⬛️': 'black_large_square',
  '⬜️': 'white_large_square',
  '👁‍🗨': 'eye-in-speech-bubble',
  '🀄️': 'mahjong',
  '🧑🏻‍🤝‍🧑🏻': 'people_holding_hands',
  '🧑🏻‍🤝‍🧑🏼': 'people_holding_hands',
  '🧑🏻‍🤝‍🧑🏽': 'people_holding_hands',
  '🧑🏻‍🤝‍🧑🏾': 'people_holding_hands',
  '🧑🏻‍🤝‍🧑🏿': 'people_holding_hands',
  '🧑🏼‍🤝‍🧑🏻': 'people_holding_hands',
  '🧑🏼‍🤝‍🧑🏼': 'people_holding_hands',
  '🧑🏼‍🤝‍🧑🏽': 'people_holding_hands',
  '🧑🏼‍🤝‍🧑🏾': 'people_holding_hands',
  '🧑🏼‍🤝‍🧑🏿': 'people_holding_hands',
  '🧑🏽‍🤝‍🧑🏻': 'people_holding_hands',
  '🧑🏽‍🤝‍🧑🏼': 'people_holding_hands',
  '🧑🏽‍🤝‍🧑🏽': 'people_holding_hands',
  '🧑🏽‍🤝‍🧑🏾': 'people_holding_hands',
  '🧑🏽‍🤝‍🧑🏿': 'people_holding_hands',
  '🧑🏾‍🤝‍🧑🏻': 'people_holding_hands',
  '🧑🏾‍🤝‍🧑🏼': 'people_holding_hands',
  '🧑🏾‍🤝‍🧑🏽': 'people_holding_hands',
  '🧑🏾‍🤝‍🧑🏾': 'people_holding_hands',
  '🧑🏾‍🤝‍🧑🏿': 'people_holding_hands',
  '🧑🏿‍🤝‍🧑🏻': 'people_holding_hands',
  '🧑🏿‍🤝‍🧑🏼': 'people_holding_hands',
  '🧑🏿‍🤝‍🧑🏽': 'people_holding_hands',
  '🧑🏿‍🤝‍🧑🏾': 'people_holding_hands',
  '🧑🏿‍🤝‍🧑🏿': 'people_holding_hands',
  '👭🏻': 'two_women_holding_hands',
  '👩🏻‍🤝‍👩🏼': 'two_women_holding_hands',
  '👩🏻‍🤝‍👩🏽': 'two_women_holding_hands',
  '👩🏻‍🤝‍👩🏾': 'two_women_holding_hands',
  '👩🏻‍🤝‍👩🏿': 'two_women_holding_hands',
  '👩🏼‍🤝‍👩🏻': 'two_women_holding_hands',
  '👭🏼': 'two_women_holding_hands',
  '👩🏼‍🤝‍👩🏽': 'two_women_holding_hands',
  '👩🏼‍🤝‍👩🏾': 'two_women_holding_hands',
  '👩🏼‍🤝‍👩🏿': 'two_women_holding_hands',
  '👩🏽‍🤝‍👩🏻': 'two_women_holding_hands',
  '👩🏽‍🤝‍👩🏼': 'two_women_holding_hands',
  '👭🏽': 'two_women_holding_hands',
  '👩🏽‍🤝‍👩🏾': 'two_women_holding_hands',
  '👩🏽‍🤝‍👩🏿': 'two_women_holding_hands',
  '👩🏾‍🤝‍👩🏻': 'two_women_holding_hands',
  '👩🏾‍🤝‍👩🏼': 'two_women_holding_hands',
  '👩🏾‍🤝‍👩🏽': 'two_women_holding_hands',
  '👭🏾': 'two_women_holding_hands',
  '👩🏾‍🤝‍👩🏿': 'two_women_holding_hands',
  '👩🏿‍🤝‍👩🏻': 'two_women_holding_hands',
  '👩🏿‍🤝‍👩🏼': 'two_women_holding_hands',
  '👩🏿‍🤝‍👩🏽': 'two_women_holding_hands',
  '👩🏿‍🤝‍👩🏾': 'two_women_holding_hands',
  '👭🏿': 'two_women_holding_hands',
  '👫🏻': 'man_and_woman_holding_hands',
  '👩🏻‍🤝‍👨🏼': 'man_and_woman_holding_hands',
  '👩🏻‍🤝‍👨🏽': 'man_and_woman_holding_hands',
  '👩🏻‍🤝‍👨🏾': 'man_and_woman_holding_hands',
  '👩🏻‍🤝‍👨🏿': 'man_and_woman_holding_hands',
  '👩🏼‍🤝‍👨🏻': 'man_and_woman_holding_hands',
  '👫🏼': 'man_and_woman_holding_hands',
  '👩🏼‍🤝‍👨🏽': 'man_and_woman_holding_hands',
  '👩🏼‍🤝‍👨🏾': 'man_and_woman_holding_hands',
  '👩🏼‍🤝‍👨🏿': 'man_and_woman_holding_hands',
  '👩🏽‍🤝‍👨🏻': 'man_and_woman_holding_hands',
  '👩🏽‍🤝‍👨🏼': 'man_and_woman_holding_hands',
  '👫🏽': 'man_and_woman_holding_hands',
  '👩🏽‍🤝‍👨🏾': 'man_and_woman_holding_hands',
  '👩🏽‍🤝‍👨🏿': 'man_and_woman_holding_hands',
  '👩🏾‍🤝‍👨🏻': 'man_and_woman_holding_hands',
  '👩🏾‍🤝‍👨🏼': 'man_and_woman_holding_hands',
  '👩🏾‍🤝‍👨🏽': 'man_and_woman_holding_hands',
  '👫🏾': 'man_and_woman_holding_hands',
  '👩🏾‍🤝‍👨🏿': 'man_and_woman_holding_hands',
  '👩🏿‍🤝‍👨🏻': 'man_and_woman_holding_hands',
  '👩🏿‍🤝‍👨🏼': 'man_and_woman_holding_hands',
  '👩🏿‍🤝‍👨🏽': 'man_and_woman_holding_hands',
  '👩🏿‍🤝‍👨🏾': 'man_and_woman_holding_hands',
  '👫🏿': 'man_and_woman_holding_hands',
  '👬🏻': 'two_men_holding_hands',
  '👨🏻‍🤝‍👨🏼': 'two_men_holding_hands',
  '👨🏻‍🤝‍👨🏽': 'two_men_holding_hands',
  '👨🏻‍🤝‍👨🏾': 'two_men_holding_hands',
  '👨🏻‍🤝‍👨🏿': 'two_men_holding_hands',
  '👨🏼‍🤝‍👨🏻': 'two_men_holding_hands',
  '👬🏼': 'two_men_holding_hands',
  '👨🏼‍🤝‍👨🏽': 'two_men_holding_hands',
  '👨🏼‍🤝‍👨🏾': 'two_men_holding_hands',
  '👨🏼‍🤝‍👨🏿': 'two_men_holding_hands',
  '👨🏽‍🤝‍👨🏻': 'two_men_holding_hands',
  '👨🏽‍🤝‍👨🏼': 'two_men_holding_hands',
  '👬🏽': 'two_men_holding_hands',
  '👨🏽‍🤝‍👨🏾': 'two_men_holding_hands',
  '👨🏽‍🤝‍👨🏿': 'two_men_holding_hands',
  '👨🏾‍🤝‍👨🏻': 'two_men_holding_hands',
  '👨🏾‍🤝‍👨🏼': 'two_men_holding_hands',
  '👨🏾‍🤝‍👨🏽': 'two_men_holding_hands',
  '👬🏾': 'two_men_holding_hands',
  '👨🏾‍🤝‍👨🏿': 'two_men_holding_hands',
  '👨🏿‍🤝‍👨🏻': 'two_men_holding_hands',
  '👨🏿‍🤝‍👨🏼': 'two_men_holding_hands',
  '👨🏿‍🤝‍👨🏽': 'two_men_holding_hands',
  '👨🏿‍🤝‍👨🏾': 'two_men_holding_hands',
  '👬🏿': 'two_men_holding_hands',

  // From the Emoji Style list: https://unicode.org/emoji/charts-14.0/emoji-style.html
  '☹': 'white_frowning_face',
  '☠': 'skull_and_crossbones',
  '❣': 'heavy_heart_exclamation_mark_ornament',
  '🕳': 'hole',
  '🗯': 'right_anger_bubble',
  '🖐': 'raised_hand_with_fingers_splayed',
  '✌': 'v',
  '☝': 'point_up',
  '✍': 'writing_hand',
  '👁': 'eye',
  '🕵': 'sleuth_or_spy',
  '🕴': 'man_in_business_suit_levitating',
  '⛷': 'skier',
  '⛳️': 'golf',
  '⛹': 'person_with_ball',
  '🏋': 'weight_lifter',
  '⚾️': 'baseball',
  '🗣': 'speaking_head_in_silhouette',
  '☘': 'shamrock',
  '🐿': 'chipmunk',
  '🕊': 'dove_of_peace',
  '🕷': 'spider',
  '🕸': 'spider_web',
  '🏵': 'rosette',
  '🌶': 'hot_pepper',
  '🍽': 'knife_fork_plate',
  '🗺': 'world_map',
  '🏔': 'snow_capped_mountain',
  '⛰': 'mountain',
  '🏕': 'camping',
  '🏖': 'beach_with_umbrella',
  '🏜': 'desert',
  '🏝': 'desert_island',
  '🏞': 'national_park',
  '🏟': 'stadium',
  '🏛': 'classical_building',
  '🏗': 'building_construction',
  '🏘': 'house_buildings',
  '🏚': 'derelict_house_building',
  '⛩': 'shinto_shrine',
  '🏙': 'cityscape',
  '♨️': 'hotsprings',
  '🏎': 'racing_car',
  '🏍': 'racing_motorcycle',
  '🛣': 'motorway',
  '🛤': 'railway_track',
  '🛢': 'oil_drum',
  '🛳': 'passenger_ship',
  '⛴': 'ferry',
  '🛥': 'motor_boat',
  '✈': 'airplane',
  '🛩': 'small_airplane',
  '🛰': 'satellite',
  '🛎': 'bellhop_bell',
  '⏱': 'stopwatch',
  '⏲': 'timer_clock',
  '🕰': 'mantelpiece_clock',
  '🌡': 'thermometer',
  '☀': 'sunny',
  '☁': 'cloud',
  '⛈': 'thunder_cloud_and_rain',
  '🌤': 'mostly_sunny',
  '🌥': 'barely_sunny',
  '🌦': 'partly_sunny_rain',
  '🌧': 'rain_cloud',
  '🌨': 'snow_cloud',
  '🌩': 'lightning',
  '🌪': 'tornado',
  '🌫': 'fog',
  '🌬': 'wind_blowing_face',
  '☂': 'umbrella',
  '⛱': 'umbrella_on_ground',
  '❄️': 'snowflake',
  '☃': 'snowman',
  '☄': 'comet',
  '🎗': 'reminder_ribbon',
  '🎟': 'admission_tickets',
  '🎖': 'medal',
  '⛸': 'ice_skate',
  '🕹': 'joystick',
  '♠️': 'spades',
  '♥️': 'hearts',
  '♦️': 'diamonds',
  '♣️': 'clubs',
  '♟': 'chess_pawn',
  '🖼': 'frame_with_picture',
  '🕶': 'dark_sunglasses',
  '🛍': 'shopping_bags',
  '⛑': 'helmet_with_white_cross',
  '🎙': 'studio_microphone',
  '🎚': 'level_slider',
  '🎛': 'control_knobs',
  '☎': 'phone',
  '🖥': 'desktop_computer',
  '🖨': 'printer',
  '⌨': 'keyboard',
  '🖱': 'three_button_mouse',
  '🖲': 'trackball',
  '🎞': 'film_frames',
  '📽': 'film_projector',
  '🕯': 'candle',
  '🗞': 'rolled_up_newspaper',
  '🏷': 'label',
  '✉': 'email',
  '🗳': 'ballot_box_with_ballot',
  '✏': 'pencil2',
  '✒': 'black_nib',
  '🖋': 'lower_left_fountain_pen',
  '🖊': 'lower_left_ballpoint_pen',
  '🖌': 'lower_left_paintbrush',
  '🖍': 'lower_left_crayon',
  '🗂': 'card_index_dividers',
  '🗒': 'spiral_note_pad',
  '🗓': 'spiral_calendar_pad',
  '🖇': 'linked_paperclips',
  '✂': 'scissors',
  '🗃': 'card_file_box',
  '🗄': 'file_cabinet',
  '🗑': 'wastebasket',
  '🗝': 'old_key',
  '⛏': 'pick',
  '⚒️': 'hammer_and_pick',
  '🛠': 'hammer_and_wrench',
  '🗡': 'dagger_knife',
  '⚔️': 'crossed_swords',
  '🛡': 'shield',
  '⚙': 'gear',
  '🗜': 'compression',
  '⚖': 'scales',
  '⛓': 'chains',
  '⚗': 'alembic',
  '🛏': 'bed',
  '🛋': 'couch_and_lamp',
  '⚰': 'coffin',
  '⚱': 'funeral_urn',
  '⚠': 'warning',
  '☢': 'radioactive_sign',
  '☣': 'biohazard_sign',
  '⬆': 'arrow_up',
  '↗': 'arrow_upper_right',
  '➡': 'arrow_right',
  '↘': 'arrow_lower_right',
  '⬇': 'arrow_down',
  '↙': 'arrow_lower_left',
  '⬅': 'arrow_left',
  '↖': 'arrow_upper_left',
  '↕': 'arrow_up_down',
  '↔': 'left_right_arrow',
  '↩': 'leftwards_arrow_with_hook',
  '↪': 'arrow_right_hook',
  '⤴': 'arrow_heading_up',
  '⤵': 'arrow_heading_down',
  '⚛': 'atom_symbol',
  '🕉': 'om_symbol',
  '✡': 'star_of_david',
  '☸': 'wheel_of_dharma',
  '☯': 'yin_yang',
  '✝': 'latin_cross',
  '☦': 'orthodox_cross',
  '☪': 'star_and_crescent',
  '☮': 'peace_symbol',
  '▶': 'arrow_forward',
  '⏭': 'black_right_pointing_double_triangle_with_vertical_bar',
  '⏯': 'black_right_pointing_triangle_with_double_vertical_bar',
  '◀': 'arrow_backward',
  '⏮': 'black_left_pointing_double_triangle_with_vertical_bar',
  '⏸': 'double_vertical_bar',
  '⏹': 'black_square_for_stop',
  '⏺': 'black_circle_for_record',
  '⏏': 'eject',
  '♀': 'female_sign',
  '♂': 'male_sign',
  '⚧': 'transgender_symbol',
  '✖': 'heavy_multiplication_x',
  '♾': 'infinity',
  '‼': 'bangbang',
  '⁉': 'interrobang',
  '〰': 'wavy_dash',
  '⚕': 'medical_symbol',
  '♻': 'recycle',
  '⚜': 'fleur_de_lis',
  '☑': 'ballot_box_with_check',
  '✔': 'heavy_check_mark',
  '〽': 'part_alternation_mark',
  '✳': 'eight_spoked_asterisk',
  '✴': 'eight_pointed_black_star',
  '❇': 'sparkle',
  '©': 'copyright',
  '®': 'registered',
  '™': 'tm',
  '🅰': 'a',
  '🅱': 'b',
  ℹ: 'information_source',
  'Ⓜ': 'm',
  '🅾': 'o2',
  '🅿': 'parking',
  '🈂': 'sa',
  '🈷': 'u6708',
  '㊗': 'congratulations',
  '㊙': 'secret',
  '◼': 'black_medium_square',
  '◻': 'white_medium_square',
  '▪': 'black_small_square',
  '▫': 'white_small_square',
  '🏳': 'waving_white_flag',

  '♥': 'hearts',
  '♠': 'spades',
  '♣': 'clubs',
  '♦': 'diamonds',
  '🫱🏻‍🫲🏼': 'handshake',
  '🫱🏻‍🫲🏽': 'handshake',
  '🫱🏻‍🫲🏾': 'handshake',
  '🫱🏻‍🫲🏿': 'handshake',
  '🫱🏼‍🫲🏻': 'handshake',
  '🫱🏼‍🫲🏽': 'handshake',
  '🫱🏼‍🫲🏾': 'handshake',
  '🫱🏼‍🫲🏿': 'handshake',
  '🫱🏽‍🫲🏻': 'handshake',
  '🫱🏽‍🫲🏼': 'handshake',
  '🫱🏽‍🫲🏾': 'handshake',
  '🫱🏽‍🫲🏿': 'handshake',
  '🫱🏾‍🫲🏻': 'handshake',
  '🫱🏾‍🫲🏼': 'handshake',
  '🫱🏾‍🫲🏽': 'handshake',
  '🫱🏾‍🫲🏿': 'handshake',
  '🫱🏿‍🫲🏻': 'handshake',
  '🫱🏿‍🫲🏼': 'handshake',
  '🫱🏿‍🫲🏽': 'handshake',
  '🫱🏿‍🫲🏾': 'handshake',
  '👨🏻‍❤️‍👨🏼': 'man-heart-man',
  '👨🏻‍❤️‍👨🏽': 'man-heart-man',
  '👨🏻‍❤️‍👨🏾': 'man-heart-man',
  '👨🏻‍❤️‍👨🏿': 'man-heart-man',
  '👨🏼‍❤️‍👨🏻': 'man-heart-man',
  '👨🏼‍❤️‍👨🏽': 'man-heart-man',
  '👨🏼‍❤️‍👨🏾': 'man-heart-man',
  '👨🏼‍❤️‍👨🏿': 'man-heart-man',
  '👨🏽‍❤️‍👨🏻': 'man-heart-man',
  '👨🏽‍❤️‍👨🏼': 'man-heart-man',
  '👨🏽‍❤️‍👨🏾': 'man-heart-man',
  '👨🏽‍❤️‍👨🏿': 'man-heart-man',
  '👨🏾‍❤️‍👨🏻': 'man-heart-man',
  '👨🏾‍❤️‍👨🏼': 'man-heart-man',
  '👨🏾‍❤️‍👨🏽': 'man-heart-man',
  '👨🏾‍❤️‍👨🏿': 'man-heart-man',
  '👨🏿‍❤️‍👨🏻': 'man-heart-man',
  '👨🏿‍❤️‍👨🏼': 'man-heart-man',
  '👨🏿‍❤️‍👨🏽': 'man-heart-man',
  '👨🏿‍❤️‍👨🏾': 'man-heart-man',
  '👩🏻‍❤️‍👨🏼': 'woman-heart-man',
  '👩🏻‍❤️‍👨🏽': 'woman-heart-man',
  '👩🏻‍❤️‍👨🏾': 'woman-heart-man',
  '👩🏻‍❤️‍👨🏿': 'woman-heart-man',
  '👩🏻‍❤️‍👩🏼': 'woman-heart-woman',
  '👩🏻‍❤️‍👩🏽': 'woman-heart-woman',
  '👩🏻‍❤️‍👩🏾': 'woman-heart-woman',
  '👩🏻‍❤️‍👩🏿': 'woman-heart-woman',
  '👩🏼‍❤️‍👨🏻': 'woman-heart-man',
  '👩🏼‍❤️‍👨🏽': 'woman-heart-man',
  '👩🏼‍❤️‍👨🏾': 'woman-heart-man',
  '👩🏼‍❤️‍👨🏿': 'woman-heart-man',
  '👩🏼‍❤️‍👩🏻': 'woman-heart-woman',
  '👩🏼‍❤️‍👩🏽': 'woman-heart-woman',
  '👩🏼‍❤️‍👩🏾': 'woman-heart-woman',
  '👩🏼‍❤️‍👩🏿': 'woman-heart-woman',
  '👩🏽‍❤️‍👨🏻': 'woman-heart-man',
  '👩🏽‍❤️‍👨🏼': 'woman-heart-man',
  '👩🏽‍❤️‍👨🏾': 'woman-heart-man',
  '👩🏽‍❤️‍👨🏿': 'woman-heart-man',
  '👩🏽‍❤️‍👩🏻': 'woman-heart-woman',
  '👩🏽‍❤️‍👩🏼': 'woman-heart-woman',
  '👩🏽‍❤️‍👩🏾': 'woman-heart-woman',
  '👩🏽‍❤️‍👩🏿': 'woman-heart-woman',
  '👩🏾‍❤️‍👨🏻': 'woman-heart-man',
  '👩🏾‍❤️‍👨🏼': 'woman-heart-man',
  '👩🏾‍❤️‍👨🏽': 'woman-heart-man',
  '👩🏾‍❤️‍👨🏿': 'woman-heart-man',
  '👩🏾‍❤️‍👩🏻': 'woman-heart-woman',
  '👩🏾‍❤️‍👩🏼': 'woman-heart-woman',
  '👩🏾‍❤️‍👩🏽': 'woman-heart-woman',
  '👩🏾‍❤️‍👩🏿': 'woman-heart-woman',
  '👩🏿‍❤️‍👨🏻': 'woman-heart-man',
  '👩🏿‍❤️‍👨🏼': 'woman-heart-man',
  '👩🏿‍❤️‍👨🏽': 'woman-heart-man',
  '👩🏿‍❤️‍👨🏾': 'woman-heart-man',
  '👩🏿‍❤️‍👩🏻': 'woman-heart-woman',
  '👩🏿‍❤️‍👩🏼': 'woman-heart-woman',
  '👩🏿‍❤️‍👩🏽': 'woman-heart-woman',
  '👩🏿‍❤️‍👩🏾': 'woman-heart-woman',
  '🧑🏻‍❤️‍🧑🏼': 'couple_with_heart',
  '🧑🏻‍❤️‍🧑🏽': 'couple_with_heart',
  '🧑🏻‍❤️‍🧑🏾': 'couple_with_heart',
  '🧑🏻‍❤️‍🧑🏿': 'couple_with_heart',
  '🧑🏼‍❤️‍🧑🏻': 'couple_with_heart',
  '🧑🏼‍❤️‍🧑🏽': 'couple_with_heart',
  '🧑🏼‍❤️‍🧑🏾': 'couple_with_heart',
  '🧑🏼‍❤️‍🧑🏿': 'couple_with_heart',
  '🧑🏽‍❤️‍🧑🏻': 'couple_with_heart',
  '🧑🏽‍❤️‍🧑🏼': 'couple_with_heart',
  '🧑🏽‍❤️‍🧑🏾': 'couple_with_heart',
  '🧑🏽‍❤️‍🧑🏿': 'couple_with_heart',
  '🧑🏾‍❤️‍🧑🏻': 'couple_with_heart',
  '🧑🏾‍❤️‍🧑🏼': 'couple_with_heart',
  '🧑🏾‍❤️‍🧑🏽': 'couple_with_heart',
  '🧑🏾‍❤️‍🧑🏿': 'couple_with_heart',
  '🧑🏿‍❤️‍🧑🏻': 'couple_with_heart',
  '🧑🏿‍❤️‍🧑🏼': 'couple_with_heart',
  '🧑🏿‍❤️‍🧑🏽': 'couple_with_heart',
  '🧑🏿‍❤️‍🧑🏾': 'couple_with_heart',
  '👨🏻‍❤️‍💋‍👨🏼': 'man-kiss-man',
  '👨🏻‍❤️‍💋‍👨🏽': 'man-kiss-man',
  '👨🏻‍❤️‍💋‍👨🏾': 'man-kiss-man',
  '👨🏻‍❤️‍💋‍👨🏿': 'man-kiss-man',
  '👨🏼‍❤️‍💋‍👨🏻': 'man-kiss-man',
  '👨🏼‍❤️‍💋‍👨🏽': 'man-kiss-man',
  '👨🏼‍❤️‍💋‍👨🏾': 'man-kiss-man',
  '👨🏼‍❤️‍💋‍👨🏿': 'man-kiss-man',
  '👨🏽‍❤️‍💋‍👨🏻': 'man-kiss-man',
  '👨🏽‍❤️‍💋‍👨🏼': 'man-kiss-man',
  '👨🏽‍❤️‍💋‍👨🏾': 'man-kiss-man',
  '👨🏽‍❤️‍💋‍👨🏿': 'man-kiss-man',
  '👨🏾‍❤️‍💋‍👨🏻': 'man-kiss-man',
  '👨🏾‍❤️‍💋‍👨🏼': 'man-kiss-man',
  '👨🏾‍❤️‍💋‍👨🏽': 'man-kiss-man',
  '👨🏾‍❤️‍💋‍👨🏿': 'man-kiss-man',
  '👨🏿‍❤️‍💋‍👨🏻': 'man-kiss-man',
  '👨🏿‍❤️‍💋‍👨🏼': 'man-kiss-man',
  '👨🏿‍❤️‍💋‍👨🏽': 'man-kiss-man',
  '👨🏿‍❤️‍💋‍👨🏾': 'man-kiss-man',
  '👩🏻‍❤️‍💋‍👨🏼': 'woman-kiss-man',
  '👩🏻‍❤️‍💋‍👨🏽': 'woman-kiss-man',
  '👩🏻‍❤️‍💋‍👨🏾': 'woman-kiss-man',
  '👩🏻‍❤️‍💋‍👨🏿': 'woman-kiss-man',
  '👩🏻‍❤️‍💋‍👩🏼': 'woman-kiss-woman',
  '👩🏻‍❤️‍💋‍👩🏽': 'woman-kiss-woman',
  '👩🏻‍❤️‍💋‍👩🏾': 'woman-kiss-woman',
  '👩🏻‍❤️‍💋‍👩🏿': 'woman-kiss-woman',
  '👩🏼‍❤️‍💋‍👨🏻': 'woman-kiss-man',
  '👩🏼‍❤️‍💋‍👨🏽': 'woman-kiss-man',
  '👩🏼‍❤️‍💋‍👨🏾': 'woman-kiss-man',
  '👩🏼‍❤️‍💋‍👨🏿': 'woman-kiss-man',
  '👩🏼‍❤️‍💋‍👩🏻': 'woman-kiss-woman',
  '👩🏼‍❤️‍💋‍👩🏽': 'woman-kiss-woman',
  '👩🏼‍❤️‍💋‍👩🏾': 'woman-kiss-woman',
  '👩🏼‍❤️‍💋‍👩🏿': 'woman-kiss-woman',
  '👩🏽‍❤️‍💋‍👨🏻': 'woman-kiss-man',
  '👩🏽‍❤️‍💋‍👨🏼': 'woman-kiss-man',
  '👩🏽‍❤️‍💋‍👨🏾': 'woman-kiss-man',
  '👩🏽‍❤️‍💋‍👨🏿': 'woman-kiss-man',
  '👩🏽‍❤️‍💋‍👩🏻': 'woman-kiss-woman',
  '👩🏽‍❤️‍💋‍👩🏼': 'woman-kiss-woman',
  '👩🏽‍❤️‍💋‍👩🏾': 'woman-kiss-woman',
  '👩🏽‍❤️‍💋‍👩🏿': 'woman-kiss-woman',
  '👩🏾‍❤️‍💋‍👨🏻': 'woman-kiss-man',
  '👩🏾‍❤️‍💋‍👨🏼': 'woman-kiss-man',
  '👩🏾‍❤️‍💋‍👨🏽': 'woman-kiss-man',
  '👩🏾‍❤️‍💋‍👨🏿': 'woman-kiss-man',
  '👩🏾‍❤️‍💋‍👩🏻': 'woman-kiss-woman',
  '👩🏾‍❤️‍💋‍👩🏼': 'woman-kiss-woman',
  '👩🏾‍❤️‍💋‍👩🏽': 'woman-kiss-woman',
  '👩🏾‍❤️‍💋‍👩🏿': 'woman-kiss-woman',
  '👩🏿‍❤️‍💋‍👨🏻': 'woman-kiss-man',
  '👩🏿‍❤️‍💋‍👨🏼': 'woman-kiss-man',
  '👩🏿‍❤️‍💋‍👨🏽': 'woman-kiss-man',
  '👩🏿‍❤️‍💋‍👨🏾': 'woman-kiss-man',
  '👩🏿‍❤️‍💋‍👩🏻': 'woman-kiss-woman',
  '👩🏿‍❤️‍💋‍👩🏼': 'woman-kiss-woman',
  '👩🏿‍❤️‍💋‍👩🏽': 'woman-kiss-woman',
  '👩🏿‍❤️‍💋‍👩🏾': 'woman-kiss-woman',
  '🧑🏻‍❤️‍💋‍🧑🏼': 'couplekiss',
  '🧑🏻‍❤️‍💋‍🧑🏽': 'couplekiss',
  '🧑🏻‍❤️‍💋‍🧑🏾': 'couplekiss',
  '🧑🏻‍❤️‍💋‍🧑🏿': 'couplekiss',
  '🧑🏼‍❤️‍💋‍🧑🏻': 'couplekiss',
  '🧑🏼‍❤️‍💋‍🧑🏽': 'couplekiss',
  '🧑🏼‍❤️‍💋‍🧑🏾': 'couplekiss',
  '🧑🏼‍❤️‍💋‍🧑🏿': 'couplekiss',
  '🧑🏽‍❤️‍💋‍🧑🏻': 'couplekiss',
  '🧑🏽‍❤️‍💋‍🧑🏼': 'couplekiss',
  '🧑🏽‍❤️‍💋‍🧑🏾': 'couplekiss',
  '🧑🏽‍❤️‍💋‍🧑🏿': 'couplekiss',
  '🧑🏾‍❤️‍💋‍🧑🏻': 'couplekiss',
  '🧑🏾‍❤️‍💋‍🧑🏼': 'couplekiss',
  '🧑🏾‍❤️‍💋‍🧑🏽': 'couplekiss',
  '🧑🏾‍❤️‍💋‍🧑🏿': 'couplekiss',
  '🧑🏿‍❤️‍💋‍🧑🏻': 'couplekiss',
  '🧑🏿‍❤️‍💋‍🧑🏼': 'couplekiss',
  '🧑🏿‍❤️‍💋‍🧑🏽': 'couplekiss',
  '🧑🏿‍❤️‍💋‍🧑🏾': 'couplekiss',
}
