import { Editor } from '@tiptap/core'
import TiptapLink from '@tiptap/extension-link'
import { NodeSelection } from 'prosemirror-state'

import { featureFlags } from 'modules/featureFlags'
import { replaceState } from 'modules/history'
import {
  configureJSONAttribute,
  getTopCenterPosPct,
} from 'modules/tiptap_editor/utils'
import { updateCardHash } from 'modules/tiptap_editor/utils/url'
import { isMobileOrTabletDevice } from 'utils/deviceDetection'

import { addMarkViewPlugin } from '../../react/addMarkViewPlugin'
import { ExtensionPriorityMap } from '../constants'
import { MediaSourcesMap } from '../media/MediaSources'
import { LinkAttrs, MediaEmbedAttrs } from '../media/types'
import { isMediaEmbedNode } from '../media/utils'
import { linkClickPlugin } from './linkClickPlugin'
import { LinkView } from './LinkView'
import {
  fetchUrlThenUpdateMarks,
  isOtherSupportedProtocol,
  isValidAutoLink,
} from './utils'

export const SUPPORTED_PROTOCOLS = ['tel', 'mailto']

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    linkCommands: {
      /**
       * Converts the current Embed or Video NodeSelection to a link
       */
      convertMediaToLink: () => ReturnType
      /**
       * Converts a link to a Video or Embed node
       */
      convertLinkToMedia: (
        displayStyle: MediaEmbedAttrs['displayStyle']
      ) => ReturnType
      /**
       * Change the href of the link that contains this selection
       */
      updateSurroundingLink: (url: string) => ReturnType
      /**
       * Remove the link that contains this selection
       */
      removeSurroundingLink: () => ReturnType
    }
  }
}

export const navigateToCardLink = (
  editor: Editor,
  event: MouseEvent | React.MouseEvent,
  cardId?: string | null
) => {
  if (!cardId) return

  const topPosPct = getTopCenterPosPct(editor, 100)
  if (topPosPct) {
    replaceState({
      data: { fromPos: topPosPct.pos, fromPct: topPosPct.pct },
      emitChange: false,
    })
  }

  updateCardHash({
    cardId,
    method: 'push',
    data: { fromPos: null, fromPct: null },
  })
}

export const Link = TiptapLink.extend({
  priority: ExtensionPriorityMap.Link,
  inclusive: false,
  excludes: 'underline footnoteLabel link',
  addAttributes() {
    return {
      href: {
        default: '',
      },
      source: {},
      thumbnail: {
        ...configureJSONAttribute('thumbnail'),
      },
      embed: {
        ...configureJSONAttribute('embed'),
      },
      meta: {
        ...configureJSONAttribute('meta'),
      },
    }
  },
  addCommands() {
    return {
      ...this.parent?.(),
      convertLinkToMedia:
        (displayStyle) =>
        ({ chain, editor }) => {
          const { href, ...attrs } = editor.getAttributes('link') as LinkAttrs
          if (!href || isOtherSupportedProtocol(href)) {
            return false
          }
          if (!attrs.source) {
            // We need to fetch metadata first
            chain()
              .extendMarkRange('link')
              .insertEmbedAndFetchMetadata(href, displayStyle, false)
              .run()
            return true
          }
          const newSource = MediaSourcesMap[attrs.source]
          const newNodeName = newSource?.nodeName || 'embed'
          chain()
            .extendMarkRange('link')
            .insertContent({
              type: newNodeName,
              attrs: {
                ...attrs,
                sourceUrl: href,
                displayStyle,
              },
            })
            .selectInsertedNode()
            .run()
          return true
        },
      convertMediaToLink:
        () =>
        ({ state, chain }) => {
          if (!(state.selection instanceof NodeSelection)) return false
          const { from, to, node } = state.selection
          if (!isMediaEmbedNode(node)) return false
          const { sourceUrl, meta } = node.attrs
          const href = sourceUrl
          const text = meta?.title || href || 'link'
          // The new selection will be just inside the new paragraph node we create
          const newRange = { from: from + 1, to: from + 1 + text.length }
          chain()
            .insertContentAt(
              { from, to },
              [
                {
                  type: 'paragraph',
                  attrs: {},
                  content: [{ type: 'text', text }],
                },
              ],
              { updateSelection: true }
            )
            .setTextSelection(newRange)
            .setMark(Link.name, { ...node.attrs, href })
            .run()

          return true
        },
      updateSurroundingLink:
        (url) =>
        ({ state, chain, editor }) => {
          const { from, to } = state.selection

          fetchUrlThenUpdateMarks(url, editor)
          chain()
            .extendMarkRange('link') // Expand selection to cover the whole link
            .updateAttributes('link', { href: url }) // Update it
            // prevent auto link, per original link extension:
            // https://github.com/ueberdosis/tiptap/commit/ea10ffbc6a0f447c33680dcc6f6dc2fa6337c4c2
            .setMeta('preventAutolink', true)
            .setTextSelection({ from, to }) // Then put selection back
          return true
        },
      removeSurroundingLink:
        () =>
        ({ state, chain }) => {
          const { from, to } = state.selection
          chain()
            .extendMarkRange('link') // Expand selection to cover the whole link
            .unsetMark('link') // Remove it
            // prevent auto link, per original link extension:
            // https://github.com/ueberdosis/tiptap/commit/ea10ffbc6a0f447c33680dcc6f6dc2fa6337c4c2
            .setMeta('preventAutolink', true)
            .setTextSelection({ from, to }) // Then put selection back
            .focus()
          return true
        },
    }
  },

  addKeyboardShortcuts() {
    return {
      'Mod-k': ({ editor }) => {
        const { selection } = editor.state
        // If you don't have a selection, fall back to the search modal
        if (selection.empty) {
          return false
        }
        return editor.commands.toggleMark(this.name)
      },
    }
  },

  addProseMirrorPlugins() {
    const plugins = this.parent?.() || []
    if (!isMobileOrTabletDevice() && featureFlags.get('linkMetadata')) {
      plugins.push(addMarkViewPlugin(this, LinkView))
    }
    plugins.push(linkClickPlugin(this.editor))

    return plugins
  },
}).configure({
  HTMLAttributes: {
    target: '_blank',
    rel: 'noopener noreferrer nofollow',
    class: 'link',
  },
  validate: isValidAutoLink,
  protocols: SUPPORTED_PROTOCOLS,
  linkOnPaste: false, // We use our own handleLinkPaste, not Tiptap's
})
