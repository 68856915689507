import { Editor, JSONContent } from '@tiptap/core'
import { Node } from 'prosemirror-model'
import {
  NodeSelection,
  Plugin,
  PluginKey,
  TextSelection,
} from 'prosemirror-state'
import { Decoration, DecorationSet } from 'prosemirror-view'

import {
  DragAnnotationData,
  DropAnnotationEvent,
} from '../../Annotatable/AnnotationExtension/types'
import { computeMediaOnMediaGalleryCreationMoves } from '../../Annotatable/utils'
import { ImageNodeAttrs } from '../types'
import { isMediaNode } from '../utils'
import {
  checkGalleryDropTarget,
  clipboardContainsGalleryContent,
  GalleryDropTarget,
} from './utils'

import { isGalleryNode } from '.'

export const PREVENT_FLEX_CLASSNAME = 'gallery-prevent-flex'
class GalleryPluginState {
  constructor(public dragging: DragAnnotationData | null = null) {}
}

const GalleryPluginKey = new PluginKey<GalleryPluginState>('galleryPlugin')

export const GalleryPlugin = (editor: Editor) =>
  new Plugin({
    key: GalleryPluginKey,
    state: {
      init() {
        return new GalleryPluginState()
      },

      apply(_transaction, pluginState) {
        return pluginState
      },
    },

    props: {
      handleDOMEvents: {
        drop(view) {
          // Store the annotation drag data here temporarily in the plugin state
          // this because the native prosemirror drop handler clears out the dragging data
          // before it calls pluginHandlers for dropHandler
          const annotationData = (view.dragging as any)
            ?.annotations as DragAnnotationData | null
          const pluginState = GalleryPluginKey.getState(view.state)
          if (!pluginState) {
            return false
          }
          pluginState.dragging = annotationData
          return
        },
      },
      decorations: ({ doc }) => {
        const decorations: Decoration[] = []
        const decorate = (node: Node, pos: number, parent: Node) => {
          if (isGalleryNode(node)) {
            decorations.push(
              Decoration.node(
                pos,
                pos + node.nodeSize,
                {},
                {
                  children: node.content,
                }
              )
            )
          } else if (isGalleryNode(parent)) {
            const preventFlex =
              node.type.name === 'image' &&
              (node.attrs as ImageNodeAttrs).resize?.clipType === 'circle'
            decorations.push(
              Decoration.node(
                pos,
                pos + node.nodeSize,
                {
                  class: preventFlex ? PREVENT_FLEX_CLASSNAME : '',
                },
                {
                  inGallery: true,
                }
              )
            )
          }
        }
        doc.descendants(decorate)
        return DecorationSet.create(doc, decorations)
      },
      handlePaste: (view, event, slice) => {
        const { selection, schema } = view.state

        const isSelectingGalleryNode =
          selection instanceof NodeSelection && isGalleryNode(selection.node)
        // if we're not selecting the gallery node, the plugin does nothing
        if (!isSelectingGalleryNode) {
          return false
        }

        // if what we're pasting can live inside the gallery
        // put the selection.from at the end and let the uploadPlugin / clipboardPlugin
        // do the actual upload and insert
        if (clipboardContainsGalleryContent(schema, slice, event)) {
          // NOTE: we must use the prosemirror low level tr.setTextSelection because
          // tiptap does too much to coerce the selection positions to be in inlineContent
          // nodes.  Selecting non text should be fine because the paste handlers in uploadPlugin
          // and clipboardPlugin will select the inserted node
          const tr = view.state.tr.setSelection(
            TextSelection.create(view.state.doc, selection.to - 1)
          )
          view.dispatch(tr)
        }

        return false
      },
      handleDrop: (view, event, slice) => {
        const pluginState = GalleryPluginKey.getState(view.state)
        const dragAnnotationData = pluginState?.dragging
        if (pluginState) {
          // on drop always get rid of the drag data
          pluginState.dragging = null
        }

        let galleryDropTarget: GalleryDropTarget | null

        try {
          galleryDropTarget = checkGalleryDropTarget(
            view,
            event as DragEvent,
            slice,
            false // We know upload isn't needed because image upload already would have handled drop
          )
          if (!galleryDropTarget) {
            return false
          }
        } catch (err) {
          console.error(
            '(caught) [GalleryPlugin] handleDrop checkGalleryDropTarget error:',
            err
          )
          // return false here so that the other dropHandlers can run
          return false
        }

        try {
          const { selection } = view.state
          const shouldDeleteOriginal = !selection.empty // Insert widget will set this empty

          const pasteContent = slice.content.toJSON() as JSONContent[]
          const { pos, side, node } = galleryDropTarget

          if (node && isGalleryNode(node)) {
            // If it's already a gallery, drop inside
            const insertPos = side === 'left' ? pos : pos + 1 // Media is (currently) always of size 1
            editor
              .chain()
              .insertContentAt(
                { from: insertPos, to: insertPos },
                pasteContent,
                {
                  updateSelection: false,
                }
              )
              .command(({ tr }) => {
                if (shouldDeleteOriginal) tr.deleteSelection()

                if (dragAnnotationData) {
                  // Mapping of the insert position without the actual insert.
                  // this case happens when i'm dragging something from the left to the right.
                  // the insert position after everything is settled would take into account for the
                  // content on the left being deleted
                  const deleteTr = editor.state.tr
                  deleteTr.deleteSelection()

                  tr.setMeta('annotationEvent', <DropAnnotationEvent>{
                    type: 'drop',
                    dragging: dragAnnotationData,
                    droppedBlockPos: deleteTr.mapping.map(insertPos),
                  })
                }

                return true
              })
              .focusMapped(insertPos) // Focus onto the newly added thing
              .run()
          } else if (node && isMediaNode(node)) {
            // Wrap it in a gallery
            const content =
              side === 'left'
                ? [...pasteContent, node.toJSON()]
                : [node.toJSON(), ...pasteContent]
            editor
              .chain()
              .insertContentAt(
                { from: pos, to: pos + node.nodeSize },
                {
                  type: 'gallery',
                  content,
                },
                { updateSelection: false }
              )
              .command(({ tr }) => {
                if (shouldDeleteOriginal) tr.deleteSelection()

                if (dragAnnotationData) {
                  const moveInstructions =
                    computeMediaOnMediaGalleryCreationMoves({
                      side,
                      view,
                      tr,
                      dragging: dragAnnotationData!,
                      dropPos: pos,
                      dropNode: node,
                    })
                  requestAnimationFrame(() => {
                    editor.commands.moveAnnotations?.(moveInstructions)
                  })
                }
                return true
              })
              .run()
          }
        } catch (err) {
          console.error('(caught) [GalleryPlugin] handleDrop error:', err)
        }

        // if we've determined that checkGallryDropTarget is not null
        // we always want to return true to prevent the default drop handler in view.js from running
        return true
      },
    },
  })
