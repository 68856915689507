/**
 * This component is for creating top-level, non-reply comments.
 */

import { Avatar, Flex, FlexProps, HStack, Stack, Text } from '@chakra-ui/react'
import { EditorOptions, JSONContent } from '@tiptap/core'

import { CommentEditor } from 'modules/tiptap_editor/CommentEditor'
import { GraphqlUser } from 'modules/user'
import { isMobileOrTabletDevice } from 'utils/deviceDetection'

import { DraftComment } from '../DraftCommentsExtension/types'
import { CommentTargetContent } from './Comments/CommentTargetContent'

type CreateTopLevelCommentProps = {
  draftComment?: DraftComment | null
  showUser?: boolean
  showBorder?: boolean

  user: GraphqlUser
  onCommentDraftUpdate?: EditorOptions['onUpdate']
  onCommentSave: (commentJSON: JSONContent) => void
  commentIdToHighlight: string | null
  alwaysShowButtons?: boolean
} & FlexProps
export const CreateTopLevelComment = ({
  draftComment,
  showUser = true,
  showBorder = true,
  user,
  onCommentDraftUpdate,
  onCommentSave,
  commentIdToHighlight,
  alwaysShowButtons = false,
  ...flexProps
}: CreateTopLevelCommentProps) => {
  return (
    <Flex direction="column" {...flexProps}>
      {showUser && (
        <HStack mb={3} pt={3}>
          <Avatar src={user?.profileImageUrl} size="sm"></Avatar>
          <Stack>
            <Text fontWeight="bold" fontSize="sm">
              {user?.displayName} (You)
            </Text>
          </Stack>
        </HStack>
      )}
      {draftComment && draftComment?.targetHtml && (
        <CommentTargetContent targetHtml={draftComment.targetHtml} />
      )}
      <CommentEditor
        onUpdate={onCommentDraftUpdate}
        initialContent={draftComment?.json || null}
        clearContentOnSave={true}
        editable={true}
        saveButtonText="Post comment"
        placeholder="Say something"
        onCommentSave={onCommentSave}
        showBorder={showBorder}
        // Avoid focusing when open
        shouldFocus={Boolean(
          !isMobileOrTabletDevice() && !commentIdToHighlight
        )}
        alwaysShowButtons={alwaysShowButtons}
      />
    </Flex>
  )
}
