import { AirtableProvider } from './Airtable'
import { AmplitudeProvider } from './Amplitude'
import { CustomImageProvider } from './CustomImage'
import { CustomVideoProvider } from './CustomVideo'
import { FigmaProvider } from './Figma'
import { GiphyProvider } from './Giphy'
import { GoogleDriveProvider } from './GoogleDrive'
import { GoogleImageProvider } from './GoogleImage'
import { GradientProvider } from './Gradient'
import { IconProvider } from './Icon'
import { LoomProvider } from './Loom'
import { MicrosoftOfficeProvider } from './MicrosoftOffice'
import { MiroProvider } from './Miro'
import { PDFProvider } from './PDF'
import { PowerBIProvider } from './PowerBI'
import { SpotifyProvider } from './Spotify'
import { TikTokProvider } from './TikTok'
import { TwitterProvider } from './Twitter'
import { UnsplashProvider } from './Unsplash'
import { VimeoProvider } from './Vimeo'
import { WebpageProvider } from './Webpage'
import { WistiaProvider } from './Wistia'
import { YoutubeProvider } from './Youtube'

export const MediaProviders = [
  GiphyProvider,
  GoogleImageProvider,
  UnsplashProvider,
  YoutubeProvider,
  LoomProvider,
  FigmaProvider,
  GoogleDriveProvider,
  WebpageProvider,
  CustomImageProvider,
  MicrosoftOfficeProvider,
  PowerBIProvider,
  TwitterProvider,
  VimeoProvider,
  CustomVideoProvider,
  PDFProvider,
  MiroProvider,
  AirtableProvider,
  AmplitudeProvider,
  WistiaProvider,
  SpotifyProvider,
  TikTokProvider,
  GradientProvider,
  IconProvider,
]

export const MediaProvidersMap = Object.fromEntries(
  Object.values(MediaProviders)
    .flat()
    .map((provider) => [provider.key, provider])
)

export {
  GiphyProvider,
  GoogleImageProvider,
  UnsplashProvider,
  YoutubeProvider,
  LoomProvider,
  FigmaProvider,
  GoogleDriveProvider,
  WebpageProvider,
  CustomImageProvider,
  MicrosoftOfficeProvider,
  PowerBIProvider,
  TwitterProvider,
  VimeoProvider,
  CustomVideoProvider,
  PDFProvider,
  MiroProvider,
  AirtableProvider,
  AmplitudeProvider,
  WistiaProvider,
  SpotifyProvider,
  TikTokProvider,
  GradientProvider,
  IconProvider,
}
