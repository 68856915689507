import { NodeViewProps } from '@tiptap/core'

export const isFocusedInside = (decorations: NodeViewProps['decorations']) =>
  decorations.some((decoration) => decoration.spec.isFocusedInside)

export const isSelectingNodeOrInside = (
  decorations: NodeViewProps['decorations']
) =>
  decorations.some(
    (decoration) =>
      decoration.spec.isSelectingNode || decoration.spec.isFocusedInside
  )
