import { findParentNodeClosestToPos, NodeViewProps } from '@tiptap/core'
import { Node, ResolvedPos, Schema, Slice } from 'prosemirror-model'
import { NodeSelection } from 'prosemirror-state'
import { EditorView } from 'prosemirror-view'
import React from 'react'

import { featureFlags } from 'modules/featureFlags'
import { EventBusEvent, TiptapEventBus } from 'modules/tiptap_editor/eventBus'
import { generateHtmlFromNode } from 'modules/tiptap_editor/utils/contentHelpers'

import { fragmentToArray, rectAtPos } from '../../../utils'
import { findNearestAnnotatableParent } from '../../Annotatable/utils'
import { isCardNode } from '../../Card/utils'
import { sliceLooksLikeLink } from '../../Clipboard/handleLinkPaste'
import { getUploadsFromClipboardEvent } from '../Upload/uploadPlugin'
import { isMediaNode } from '../utils'

export const isGalleryNode = (node: Node) => node.type.name === 'gallery'

export const findParentGallery = ($pos: ResolvedPos) =>
  findParentNodeClosestToPos($pos, isGalleryNode)

export const isNodeInGallery = ($pos: ResolvedPos) => {
  return !!findParentGallery($pos)
}

export const isNodeViewInGallery = ({ decorations }: NodeViewProps) => {
  return decorations.some((d) => d.spec.inGallery)
}

export const getGalleryChildren = ({ decorations }: NodeViewProps): Node[] => {
  const fragment = decorations.find((d) => d.spec.children)?.spec.children
  return fragment ? fragmentToArray(fragment) : []
}

export const addImageComment = (selection: NodeSelection) => {
  // If the image is in a block like a gallery, treat that as the parent
  // but don't use a Card because Card's BlockCommentsStack won't render when open
  const parentAnnotatable = findNearestAnnotatableParent(selection.$from)
  if (parentAnnotatable && !isCardNode(parentAnnotatable.node)) {
    TiptapEventBus.emit(EventBusEvent.CREATE_COMMENT_FROM_SELECTION, {
      selectionPos: selection.from,
      parentPos: parentAnnotatable.pos,
      text: generateHtmlFromNode(selection.node),
    })
  } else {
    TiptapEventBus.emit(EventBusEvent.CREATE_COMMENT_FROM_SELECTION, {
      selectionPos: selection.from,
      parentPos: selection.from,
      text: generateHtmlFromNode(selection.node),
    })
  }
}

export type GalleryDropTarget = {
  pos: number
  node: Node | null
  side: 'right' | 'left'
  rect: DOMRect
}

export const checkGalleryDropTarget = (
  view: EditorView,
  event: DragEvent,
  slice?: Slice,
  needsUpload?: boolean
): GalleryDropTarget | null => {
  if (!featureFlags.get('imageGallery')) return null
  if (slice && !canGalleryContainSlice(slice, view.state.schema)) return null
  const coordPos = view.posAtCoords({
    left: event.clientX,
    top: event.clientY,
  })
  if (!coordPos) return null
  const { doc } = view.state
  let { pos } = coordPos
  const $pos = doc.resolve(pos)
  const gallery = findParentGallery($pos)
  let rect: DOMRect | undefined, node: Node | null
  if (gallery && gallery.node.childCount > 0) {
    pos = coordPos.pos
    rect = rectAtPos(pos, view)
    // If we're at the end, we want a right selection on the node before
    if (!rect && pos == gallery.pos + gallery.node.nodeSize - 1) {
      pos = pos - 1
      rect = rectAtPos(pos, view)
    }
    node = gallery.node
  } else {
    // Use the inside pos
    pos = coordPos.inside
    try {
      node = doc.resolve(pos).nodeAfter
    } catch (err) {
      return null
    }
    if (
      !node ||
      !isMediaNode(node) ||
      node == slice?.content.firstChild ||
      needsUpload // Right now the image upload plugin trumps this, they don't work together
    )
      return null
    // We're dropping onto a media node to create a gallery
    rect = rectAtPos(pos, view)
  }
  if (!rect) return null
  const leftDistance = event.clientX - rect.left
  const rightDistance = rect.right - event.clientX
  const side = leftDistance > rightDistance ? 'right' : 'left'
  const isOutside = side == 'left' ? leftDistance < 0 : rightDistance < 0
  // If you're dropping an image onto an image, you have to actually land on it. Drops on the edges around it should become columns
  if (isOutside && !gallery) return null
  return {
    pos,
    side,
    rect,
    node,
  }
}

export const canGalleryContainSlice = (slice: Slice, schema: Schema) =>
  slice.content.size > 0 && schema.nodes.gallery.validContent(slice.content)

export const canDropInGallery = (view: EditorView, ev: React.DragEvent) => {
  const slice = view.dragging?.slice
  if (!slice) {
    // This is an external drag, not from ProseMirror, so check the mime types
    const items = [...ev.dataTransfer.items]
    return (
      items.length > 0 && items.every((item) => item.type.startsWith('image/'))
    )
  }
  return canGalleryContainSlice(slice, view.state.schema)
}

export const clipboardContainsGalleryContent = (
  schema: Schema,
  slice: Slice,
  event: ClipboardEvent
) => {
  const uploads = getUploadsFromClipboardEvent(event, slice)

  return (
    sliceLooksLikeLink(slice) ||
    canGalleryContainSlice(slice, schema) ||
    (uploads && uploads.length > 0)
  )
}
