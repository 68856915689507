import { ButtonGroup } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import { CardTOCAttributes } from 'modules/tiptap_editor/extensions/CardTableOfContents/CardTableOfContentsView'

import { ToolbarButton } from '../buttons/ToolbarButton'
import { NodeFormattingMenuProps } from './types'

export const CardTOCFormattingMenu = ({
  editor,
  selection,
}: NodeFormattingMenuProps) => {
  const { nested } = selection.node.attrs as CardTOCAttributes
  return (
    <ButtonGroup spacing={0} size="sm">
      <ToolbarButton
        testId="toc-top-level-cards"
        label={'Show top-level cards'}
        icon={regular('list')}
        onClick={() => {
          editor.commands.updateAttributes('tableOfContents', {
            nested: false,
          })
        }}
        isActive={!nested}
      />
      <ToolbarButton
        testId="toc-nested-cards"
        label={'Show all nested cards'}
        icon={regular('list-tree')}
        onClick={() => {
          editor.commands.updateAttributes('tableOfContents', {
            nested: true,
          })
        }}
        isActive={nested}
      />
    </ButtonGroup>
  )
}
