import { CSSObject } from '@emotion/react'

import { isGradientEnabled } from '../components/ColorOrGradientPicker'
import { Theme } from '../types'

export const HEADING_CLASS = 'themed-heading'
export const HEADING_NO_GRADIENT_CLASS = 'themed-heading-no-gradient'

export const HeadingStyles = {
  fontWeight: 'var(--heading-font-weight)',
  fontFamily: 'var(--heading-font)',
  color: 'var(--heading-color)',
  background: 'unset',
  backgroundClip: 'unset',
  width: 'fit-content', // Keeps gradient limited to just the text width
  maxWidth: '100%', // Allows for long headings with no spaces to wrap
}

export const GradientHeadingStyles = {
  ...HeadingStyles,
  background: 'var(--heading-gradient)',
  backgroundClip: 'text',
  caretColor: 'var(--heading-color)',
  color: 'transparent',
}

export const getHeadingStylesheet = (theme: Theme): CSSObject => {
  const useGradient = theme && isGradientEnabled(theme.config.headingGradient)

  if (useGradient) {
    return {
      // Use a less specific version of the selector to allow overriding it https://developer.mozilla.org/en-US/docs/Web/CSS/:where
      [`*:where(.${HEADING_CLASS})`]: GradientHeadingStyles,
      [`.${HEADING_NO_GRADIENT_CLASS}`]: {
        [`.${HEADING_CLASS}`]: HeadingStyles,
      },
    }
  } else {
    return {
      [`.${HEADING_CLASS}`]: HeadingStyles,
    }
  }
}

export const resetHeadingStylesheet = (newColor?: string) => {
  return {
    [`.${HEADING_CLASS}`]: HeadingStyles,
    '--heading-color': newColor,
  }
}
