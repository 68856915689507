import { ThemeFont } from 'modules/api'
import EudoxusFontLinks from 'scss/fonts/Eudoxus-Sans'

import { Theme } from '../types'

interface FontLoaderFont extends ThemeFont {}

type FontLoaderProps = {
  fonts?: FontLoaderFont[]
}

export const FontLoader = ({ fonts }: FontLoaderProps) => {
  const hasEudoxus = fonts?.find((f) => f.id === 'Eudoxus Sans')
  return (
    <>
      {hasEudoxus ? <EudoxusFontLinks /> : null}
      {fonts &&
        fonts.map(
          (font) =>
            font.url && <link href={font.url} rel="stylesheet" key={font.id} />
        )}
    </>
  )
}

type ThemeFontLoaderProps = {
  theme: Theme
}

export const ThemeFontLoader = ({ theme }: ThemeFontLoaderProps) => {
  return <FontLoader fonts={theme?.fonts} />
}
