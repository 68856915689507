import {
  ApolloClient,
  createHttpLink,
  DefaultOptions,
  InMemoryCache,
  NormalizedCacheObject,
  from,
  split,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { RetryLink } from '@apollo/client/link/retry'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'

import { config } from 'config'

import { ApolloClientPromiseRejectionEvent } from '../errors'
import { getIsConnected } from '../healthCheck'
import { cacheConfig } from './cache'

export * from './hooks'

let client: ApolloClient<NormalizedCacheObject>
let wsLink: WebSocketLink

export const getWsLink = () => wsLink

const RETRY_ATTEMPTS = 2

// On the server, we overwrite the regular GraphQLError with our own formatting:
// https://github.com/gamma-app/gamma/blob/2ca7a4994e6384c49e83d7a0805c7b40cd86cd10/packages/server/src/app.module.ts#L107-L115
export type FormattedGraphQLError = {
  code: string
  message: string
}
// Export for testing
export const defaultOptions: DefaultOptions = {
  watchQuery: {
    // See https://www.apollographql.com/docs/react/data/queries/#setting-a-fetch-policy
    fetchPolicy: 'cache-and-network',

    // Details on how this is expected to behave here:
    //   https://github.com/apollographql/apollo-client/issues/6760#issuecomment-668188727
    nextFetchPolicy: 'cache-first',

    returnPartialData: true,

    errorPolicy: 'all',
  },
  query: {
    fetchPolicy: 'cache-first',

    returnPartialData: true,

    errorPolicy: 'all',
  },
}

export const getApolloClient = () => client

export const initApolloClient = () => {
  const cache = new InMemoryCache(cacheConfig)
  client = new ApolloClient({
    link: getSplitLink(),
    defaultOptions,
    cache,
    connectToDevTools: config.APPLICATION_ENVIRONMENT !== 'production',
  })
  return client
}

type ShareTokenHeader = {
  'share-token'?: string
}

const getShareTokenHeader = (): ShareTokenHeader => {
  const additionalHeaders: ShareTokenHeader = {}
  if (config.SHARE_TOKEN) {
    additionalHeaders['share-token'] = config.SHARE_TOKEN
  }
  return additionalHeaders
}

const getSplitLink = () => {
  const httpLink = createHttpLink({
    uri: `${config.API_HOST}/graphql`,
    credentials: 'include',
  })

  const contentfulHttpLink = createHttpLink({
    uri: `${config.CONTENTFUL_GRAPHQL_ENDPOINT}`,
    headers: {
      Authorization: `Bearer ${config.CONTENTFUL_API_TOKEN}`,
    },
  })

  if (!process.browser) return httpLink

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        ...getShareTokenHeader(),
      },
    }
  })

  wsLink = new WebSocketLink({
    uri: `${config.API_HOST.replace('https', 'wss')}/graphql`,
    options: {
      lazy: true,
      reconnect: true,
      connectionParams: async () => {
        // We cant pass the share-token in the headers with WebSocketLink,
        // so set it as a connectionParam and handle that on the server
        // See more here: https://github.com/apollographql/apollo-client/issues/6782
        return {
          ...getShareTokenHeader(),
        }
      },
    },
  })

  // Retry when network errors happen, not GraphQL errors
  // https://www.apollographql.com/docs/react/api/link/apollo-link-retry/
  const retryLink = new RetryLink({
    attempts: (count, _operation, error) => {
      if (!getIsConnected()) {
        // Dont retry if our connection isn't healthy
        return false
      }
      if (count > RETRY_ATTEMPTS) {
        throw new ApolloClientPromiseRejectionEvent('ApolloRetryLink', {
          message: error,
        })
      }
      return true
    },
  })

  // Log any GraphQL errors or network error that occurred
  const errorLink = onError(({ graphQLErrors }) => {
    if (graphQLErrors) {
      for (const err of graphQLErrors as unknown as FormattedGraphQLError[]) {
        console.log(
          `[GraphQL error]: Code: ${err.code}. Message: ${err.message}}`
        )
        if (err.code === 'UNAUTHENTICATED') {
          // Redirect to logout
          window.location.href = `${config.API_HOST}/logout`
        }
      }
    }
  })

  return split(
    ({ query }) => {
      const definition = getMainDefinition(query)
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      )
    },
    // Based on the split above, use the websocket link for subscriptions
    wsLink,

    split(
      (operation) => operation.getContext().clientName === 'contentfulGraphql',
      contentfulHttpLink,
      // For normal GraphQL requests, use a link chain.
      // The order here matters (httpLink should be last)
      // See https://www.apollographql.com/docs/react/api/link/introduction/
      from([retryLink, errorLink, authLink, httpLink])
    )
  )
}
