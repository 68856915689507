/**
 * Resets any routing-related session storage values
 */
import { useRouter } from 'next/router'
import { useEffect, useRef } from 'react'

import { useSessionStorage } from 'utils/hooks/useSessionStorage'
import { USER_SETTINGS_CONSTANTS } from 'utils/userSettingsConstants'

export const useInitRoutingSessionStorage = () => {
  const { pathname, isReady } = useRouter()
  const hasRun = useRef<boolean>(false)
  const [_, setLastHomeUrl] = useSessionStorage<string | null>(
    USER_SETTINGS_CONSTANTS.lastHomeUrl,
    '/'
  )

  useEffect(() => {
    if (hasRun.current || typeof window === 'undefined' || !isReady) return
    const lastHomeUrl =
      pathname === '/inspiration' || pathname === '/templates' ? pathname : '/'
    // Reset session storage
    setLastHomeUrl(lastHomeUrl)
    hasRun.current = true
  }, [setLastHomeUrl, isReady, pathname])
}
