import { Button, Flex, IconButton, Text, VStack } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'
import { NodeViewProps } from '@tiptap/core'
import { motion } from 'framer-motion'
import React, { useCallback, useRef } from 'react'

import { useAppSelector } from 'modules/redux'
import { isMobileDevice } from 'utils/deviceDetection'
import { useDroppable } from 'utils/hooks/useDroppable'

import { openMediaDrawer } from '../../../components/drawers/MediaDrawer/utils'
import { NodeViewContent } from '../../../react'
import { selectEditable } from '../../../reducer'
import { AnnotatableNodeViewWrapper } from '../../Annotatable'
import { ContainerDragHandle } from '../../DragDrop/ContainerDragHandle/ContainerDragHandle'
import { isSelectingNodeOrInside } from '../../selection/utils'
import { EmbedPlayer, MiniEmbedPreview } from '../Embed'
import { CroppedImage } from '../Image'
import { VideoPlayer } from '../Video'
import {
  getZoomLayoutId,
  useCarouselZoom,
  ZoomableCarousel,
  ZoomTransition,
} from '../Zoomable'
import {
  DEFAULT_THUMB_HEIGHT_EM,
  GALLERY_PADDING_REM,
  MOBILE_THUMB_HEIGHT_EM,
} from './constants'
import { PREVENT_FLEX_CLASSNAME } from './GalleryPlugin'
import { canDropInGallery, getGalleryChildren } from './utils'

const MotionFlex = motion(Flex)

export const GalleryView = (nodeViewProps: NodeViewProps) => {
  const { node, editor, decorations, getPos, updateAttributes } = nodeViewProps
  const children = getGalleryChildren(nodeViewProps)
  const isEmpty = children.length === 0
  const thumbSize = isEmpty
    ? DEFAULT_THUMB_HEIGHT_EM
    : isMobileDevice()
    ? MOBILE_THUMB_HEIGHT_EM
    : node.attrs.thumbHeight || DEFAULT_THUMB_HEIGHT_EM
  const { isZoomed, isZoomComplete, exitZoom, zoomedId, setZoomedId } =
    useCarouselZoom(children.map((n) => n.attrs.id))

  const isEditable = useAppSelector(selectEditable)
  const ref = useRef<HTMLDivElement>(null)
  const dragHandlers = useDroppable(ref, (ev) =>
    canDropInGallery(editor.view, ev)
  )
  const showMenu = isSelectingNodeOrInside(decorations)

  const addImage = useCallback(
    (event: React.MouseEvent) => {
      try {
        event.stopPropagation() // Prevents selectGalleryFromGaps from handling as well
        event.preventDefault()
        const pos = getPos()
        const gallery = editor.state.doc.nodeAt(pos)
        if (!gallery) return
        const lastChildSource = gallery.lastChild?.attrs.source
        const insertPos = pos + gallery?.nodeSize - 1
        editor
          .chain()
          .insertContentAt(insertPos, {
            type: 'mediaPlaceholder',
            attrs: {
              source: lastChildSource || 'image.custom',
            },
          })
          .run()
        setTimeout(() => {
          editor.commands.setNodeSelection(insertPos)
          openMediaDrawer()
        })
      } catch (err) {
        console.error('(caught) [GalleryView] addImage', err)
      }
    },
    [getPos, editor]
  )

  const selectGalleryFromGaps = useCallback(
    (ev: React.MouseEvent) => {
      if (!editor.isEditable) return
      if (
        (ev.target as HTMLElement)
          .closest('.gallery, .block')
          ?.classList.contains('gallery')
      ) {
        // The click was on the gallery, not any of the nodes inside
        editor.commands.selectNodeAtPos(getPos())
      }
    },
    [editor, getPos]
  )

  return (
    <AnnotatableNodeViewWrapper {...nodeViewProps}>
      <Flex
        className="gallery"
        cursor="default"
        onClick={selectGalleryFromGaps}
        ref={ref}
        align="center"
        direction="column"
        position="relative"
        data-selection-ring
        data-selection-background
        // Handle drag over
        {...(isEmpty ? dragHandlers : {})}
        // Pass sizing/layout props down to child blocks
        css={{
          '--media-maxH': `${thumbSize}em`,
          '--media-maxW': `100%`,
        }}
        sx={{
          // The added div inside the node-view-content, which is the direct
          // parent of the images
          '[data-node-view-content-inner=gallery]': {
            // Flex layout, stacking horizontally
            // https://codepen.io/chriscoyier/pen/PXGNom
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'center',
            '@media print': {
              justifyContent: 'center',
            },
            gap: `${GALLERY_PADDING_REM}rem`,
            '.block': {
              h: `${thumbSize}em`,
              flex: '1 1 auto', // Expand or shrink based on the image's width
              '@media print': {
                flex: 'unset !important',
              },
              [`&.${PREVENT_FLEX_CLASSNAME}`]: {
                flex: '0 0 auto',
              },
              my: 0, // Override the default block padding
              transitionProperty: 'filter',
              transitionDuration: 'normal',
              _hover: {
                filter: 'brightness(0.9)',
              },
              '[data-node-view-wrapper]': {
                h: '100%',
              },
            },
            // Add a spacer if there aren't enough to fill
            _after:
              children.length < 3
                ? {
                    flex: '10 10 auto',
                    backgroundColor: 'gray.100',
                    width: '1px',
                    content: '""',
                  }
                : undefined,
          },
        }}
      >
        {isEmpty ? (
          <VStack
            spacing={3}
            h={`${thumbSize}em`}
            justify="center"
            contentEditable={false}
            color={'gray.400'}
            className="gallery-placeholder"
            data-testid="gallery-placeholder"
            userSelect="none"
          >
            <FontAwesomeIcon size="2x" icon={regular('images')} />
            {!isEditable ? null : (
              <Text fontSize="sm" textAlign="center">
                <Button size="xs" onClick={addImage} mr={1}>
                  Click here
                </Button>{' '}
                or drag to add images, videos, or embeds
              </Text>
            )}
          </VStack>
        ) : (
          <NodeViewContent />
        )}
        {isEditable && !isEmpty && (
          <Flex
            position="absolute"
            bottom="0"
            h={`${thumbSize}em`}
            align="center"
            w={6}
            right={-3}
            className="add-image"
            opacity="0"
            transitionProperty="opacity"
            transitionDuration="normal"
          >
            <GammaTooltip label="Add image" placement="top">
              <IconButton
                icon={<FontAwesomeIcon icon={regular('plus')} />}
                aria-label="Add image"
                position="absolute"
                size="xs"
                variant="plain"
                isRound
                onClick={addImage}
              />
            </GammaTooltip>
          </Flex>
        )}
        <ContainerDragHandle {...nodeViewProps} label="Select gallery" />
      </Flex>
      <ZoomableCarousel
        isZoomed={isZoomed}
        exitZoom={exitZoom}
        zoomedId={zoomedId}
        setZoomedId={setZoomedId}
        editor={editor}
      >
        {children
          .filter((child) => child.attrs.id)
          .map((child) => {
            const { id } = child.attrs
            const nodeName = child.type.name
            const motionProps = {
              layoutId: getZoomLayoutId(id),
              // We only want the layout transition when entering/exit zoom,
              // not while animating between children
              transition:
                isZoomComplete && isZoomed ? { duration: 0 } : ZoomTransition,
              layoutDependency: isZoomed,
            }
            return {
              id,
              thumbnail:
                nodeName === 'image' ? (
                  <CroppedImage imageAttrs={child.attrs} objectFit="cover" />
                ) : (
                  <MiniEmbedPreview node={child} />
                ),
              full: (
                <Flex justify="center" align="center" h="100%" w="100%">
                  {nodeName === 'image' ? (
                    <CroppedImage
                      isZoomed={true}
                      imageAttrs={child.attrs}
                      motionProps={motionProps}
                    />
                  ) : nodeName === 'video' ? (
                    <MotionFlex
                      {...motionProps}
                      // Fill the viewport, center inside
                      width="var(--media-maxW)"
                      height="var(--media-maxH)"
                      direction="column"
                      align="center"
                      justify="center"
                    >
                      <VideoPlayer isZoomed node={child} decorations={[]} />
                    </MotionFlex>
                  ) : (
                    <MotionFlex
                      {...motionProps}
                      h="100%"
                      w="100%"
                      direction="column"
                      justify="center"
                      align="center"
                    >
                      <EmbedPlayer isZoomed={true} node={child} />
                    </MotionFlex>
                  )}
                </Flex>
              ),
            }
          })}
      </ZoomableCarousel>
    </AnnotatableNodeViewWrapper>
  )
}
