import { SearchIcon } from '@chakra-ui/icons'
import {
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
} from '@chakra-ui/react'
import { getShortcutTextForOS } from '@gamma-app/ui'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { isMobileDevice } from 'utils/deviceDetection'

import { setIsGlobalSearchOpen } from '../../reducer'
export const FauxSearchInput = () => {
  const dispatch = useDispatch()

  const onOpen = useCallback(() => {
    dispatch(setIsGlobalSearchOpen({ isOpen: true }))
  }, [dispatch])

  const onChange = useCallback(
    (e) => {
      dispatch(
        setIsGlobalSearchOpen({ isOpen: true, initialQuery: e.target.value })
      )
    },
    [dispatch]
  )

  return (
    <InputGroup mt={4}>
      <InputLeftElement pointerEvents="none">
        <SearchIcon color="gray.300" />
      </InputLeftElement>
      <Input
        placeholder="Jump to"
        _placeholder={{ opacity: 1 }}
        onChange={onChange}
        onClick={onOpen}
        value={''}
      />
      {!isMobileDevice() && (
        <InputRightElement pointerEvents="none">
          <Text
            mr={6}
            fontWeight="600"
            color="gray.400"
            fontSize="sm"
            bg="gray.50"
            px={1}
            borderRadius="md"
          >
            {getShortcutTextForOS('Mod+K')}
          </Text>
        </InputRightElement>
      )}
    </InputGroup>
  )
}
