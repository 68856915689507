import {
  Button,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Editor } from '@tiptap/core'

import { useFeatureFlag } from 'modules/featureFlags'
import { useAppSelector } from 'modules/redux'
import {
  BodySizeOptions,
  HeadingSizeOptions,
  TitleSizeOptions,
} from 'modules/tiptap_editor/extensions/Font/constants'
import {
  allowedFontSizes,
  FontSize,
  getSelectedFontSizes,
} from 'modules/tiptap_editor/extensions/Font/FontSize'
import { getFontSizeOption } from 'modules/tiptap_editor/extensions/Font/utils'
import { selectTheme } from 'modules/tiptap_editor/reducer'
import { canChangeSelectedNodeType } from 'modules/tiptap_editor/utils'
type TextDropdownProps = {
  editor: Editor
}

export const TextSizeMenu = ({ editor }: TextDropdownProps) => {
  const setFontSize = (value: FontSize) => {
    editor.chain().focus().setFontSize(value).run()
  }
  const theme = useAppSelector(selectTheme)

  const canChangeFontSize = useFeatureFlag('fontSize')

  const { $from } = editor.state.selection
  const allowHeading =
    canChangeSelectedNodeType(editor, 'heading') ||
    allowedFontSizes($from.parent).includes('heading')
  const allowTitle =
    canChangeSelectedNodeType(editor, 'title') ||
    allowedFontSizes($from.parent).includes('title')
  const allowBody =
    canChangeSelectedNodeType(editor, 'paragraph') ||
    allowedFontSizes($from.parent).includes('body')

  if (!allowBody && !allowHeading && !allowTitle) return null

  const currentSizes = getSelectedFontSizes(editor)
  const currentSize = editor.isActive('title')
    ? 'title'
    : editor.isActive('heading')
    ? `h${editor.getAttributes('heading').level}`
    : currentSizes.length === 1
    ? currentSizes[0] || 'default'
    : 'default'
  const currentOption = getFontSizeOption(currentSize)

  return (
    <Menu closeOnSelect={true} isLazy>
      <MenuButton
        size="sm"
        as={Button}
        rightIcon={
          <FontAwesomeIcon
            icon={regular('chevron-down')}
            transform="shrink-6"
          />
        }
        variant="toolbar"
        pl={2}
      >
        {currentOption.label}
      </MenuButton>
      <MenuList maxH="min(25em, 45vh)" overflowY="auto">
        {allowBody && (
          <MenuOptionGroup type="radio" value={currentSize} title="Body">
            {BodySizeOptions.map(([key, { label, size, flagged }]) => {
              if (flagged && !canChangeFontSize && key !== currentSize) return
              return (
                <MenuItemOption
                  value={key}
                  key={key}
                  onClick={() => setFontSize(key)}
                >
                  <Text fontSize={`${size}em`} fontFamily={theme.bodyFont}>
                    {label}
                  </Text>
                </MenuItemOption>
              )
            })}
          </MenuOptionGroup>
        )}
        {allowHeading && (
          <MenuOptionGroup type="radio" value={currentSize} title="Heading">
            {HeadingSizeOptions.map(([key, { label, size, flagged }]) => {
              if (flagged && !canChangeFontSize && key !== currentSize) return
              return (
                <MenuItemOption
                  value={key}
                  key={key}
                  onClick={() => setFontSize(key)}
                >
                  <Text
                    fontWeight="bold"
                    fontSize={`${size}em`}
                    fontFamily={theme.headingFont}
                  >
                    {label}
                  </Text>
                </MenuItemOption>
              )
            })}
          </MenuOptionGroup>
        )}
        {allowTitle && (
          <MenuOptionGroup type="radio" value={currentSize} title="Title">
            {TitleSizeOptions.map(([key, { label, size, flagged }]) => {
              if (flagged && !canChangeFontSize && key !== currentSize) return
              return (
                <MenuItemOption
                  value={key}
                  key={key}
                  onClick={() => setFontSize(key)}
                >
                  <Text
                    fontWeight="bold"
                    fontSize={`${size}em`}
                    fontFamily={theme.headingFont}
                  >
                    {label}
                  </Text>
                </MenuItemOption>
              )
            })}
          </MenuOptionGroup>
        )}
      </MenuList>
    </Menu>
  )
}
