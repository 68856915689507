import {
  Alert,
  AlertIcon,
  Box,
  Divider,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  Text,
} from '@chakra-ui/react'
import { DOC_DISPLAY_NAME } from '@gamma-app/ui'
import { useMemo } from 'react'

import { Doc, useGetDocCardsAnalyticsQuery } from 'modules/api'
import { useAppStore } from 'modules/redux'
import { MANAGE_PERMISSION_LABEL } from 'modules/sharing/constants'
import { selectCardIdMap } from 'modules/tiptap_editor/reducer'
import { useUserContext } from 'modules/user'

import { createCardLookupTable } from '../utils'
import { AnalyticsError } from './AnalyticsError'
import {
  HorizontalBarCardTreeDescription,
  HorizontalBarCardTreeSkeleton,
  ViewTimePercentTooltip,
} from './CardEngagement'
import { HorizontalBarCardTree } from './HorizontalBarCardTree'
import { CardDrilldownUserInfo } from './UserCardDrilldown'

export const PersonalAnalyticsView = ({
  doc,
  onAnalyticsPanelClose,
}: {
  doc: Doc
  onAnalyticsPanelClose: () => void
}) => {
  const reduxStore = useAppStore()
  const state = reduxStore.getState()
  const cardIdMap = selectCardIdMap(state)
  const { user } = useUserContext()
  const {
    data: docCardsData,
    error: docCardsError,
    loading: docCardsLoading,
    refetch,
  } = useGetDocCardsAnalyticsQuery({
    variables: { docId: doc.id, userId: user?.id },
    skip: !user?.id,
    // re-get analytics every 15 minutes
    pollInterval: 1000 * 60 * 15,
    // This ensures that the value of isAnalyticsLoading updates on refetch
    notifyOnNetworkStatusChange: true,
  })

  const cardsViewed = docCardsData?.docCardsStats?.everyone?.length ?? 0
  const totalCards = docCardsData?.docCardsStats?.cardCount
  const cardStats = useMemo(
    () => docCardsData?.docCardsStats?.everyone || [],
    [docCardsData?.docCardsStats]
  )
  const cardLookupTable = useMemo(
    () => createCardLookupTable(cardStats, 'viewTimePercent'),
    [cardStats]
  )

  if (!user) return null
  const isCreator = user.id === doc.createdBy?.id
  const isContributor = doc.editors?.some((c) => c.user.id === user.id)
  const displayUser = {
    name: user.displayName,
    id: user.id,
    profileImg: user.profileImageUrl,
    email: user.email,
    isContributor,
    isCreator,
  }
  return (
    <>
      <ModalHeader>
        Analytics
        <Text
          fontSize="sm"
          fontWeight="normal"
          letterSpacing="normal"
          color="gray.500"
          noOfLines={1}
        >
          {doc.title || 'Untitled'}
        </Text>
      </ModalHeader>
      <ModalCloseButton />
      <Divider />
      <ModalBody>
        <Box>
          <CardDrilldownUserInfo user={displayUser} />
          {totalCards !== undefined && (
            <HorizontalBarCardTreeDescription
              description={`Viewed ${cardsViewed}/${totalCards} cards across all sessions.`}
            />
          )}
          {docCardsLoading && !docCardsError && (
            <HorizontalBarCardTreeSkeleton includeDescription />
          )}
          {docCardsError && (
            <AnalyticsError error={docCardsError} refetch={refetch} px={0} />
          )}
          {!docCardsError && !docCardsLoading && (
            <HorizontalBarCardTree
              cards={cardIdMap.tree}
              scaleToMax
              includeChildren
              leftLabel="Less time spent"
              rightLabel="More time spent"
              cardLookupTable={cardLookupTable}
              tooltipLabel={<ViewTimePercentTooltip />}
              onAnalyticsPanelClose={onAnalyticsPanelClose}
            />
          )}
          <Alert>
            <AlertIcon />
            To help authors understand how people engage with their work, Gamma
            shares your relative (not actual) time spent across cards. Request "
            {MANAGE_PERMISSION_LABEL}" from this {DOC_DISPLAY_NAME}'s creator to
            view aggregate analytics for this {DOC_DISPLAY_NAME}.
          </Alert>
        </Box>
      </ModalBody>
    </>
  )
}
