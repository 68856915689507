import { JSONContent } from '@tiptap/core'

import { User } from 'modules/api'
import { TitleLevel } from 'modules/tiptap_editor/extensions/Title/Title'

import { DEFAULT_DOC_BACKGROUND } from '../styles/backgroundStyles'
import { DEFAULT_THEME } from '../styles/themes'

type CreateEmptyDocOptions = {
  docFlags: { [key: string]: any }
}
export const emptyDoc = (
  _user?: User,
  options: CreateEmptyDocOptions = { docFlags: {} }
): JSONContent => {
  const { docFlags } = options
  return {
    type: 'doc',
    content: [
      {
        type: 'document',
        attrs: {
          theme: DEFAULT_THEME,
          background: DEFAULT_DOC_BACKGROUND,
          docFlags,
        },
        content: [
          {
            type: 'card',
            content: [
              {
                type: 'title',
                attrs: { level: TitleLevel.DocTitle },
              },
              {
                type: 'contributors',
              },
            ],
          },
          {
            type: 'card',
            content: [
              {
                type: 'heading',
              },
            ],
          },
        ],
      },
    ],
  }
}

export const initialDocWithCards = (
  options: CreateEmptyDocOptions = { docFlags: {} },
  cardContent: JSONContent[] = []
): JSONContent => {
  const { docFlags } = options
  return {
    type: 'doc',
    content: [
      {
        type: 'document',
        attrs: {
          theme: DEFAULT_THEME,
          background: DEFAULT_DOC_BACKGROUND,
          docFlags,
        },
        content: cardContent,
      },
    ],
  }
}

export const emptyDocWithQuickstart = (
  _user?: User,
  options: CreateEmptyDocOptions = { docFlags: {} }
): JSONContent => {
  const { docFlags } = options
  return {
    type: 'doc',
    content: [
      {
        type: 'document',
        attrs: {
          theme: DEFAULT_THEME,
          background: DEFAULT_DOC_BACKGROUND,
          docFlags,
        },
        content: [
          {
            type: 'card',
            content: [
              {
                type: 'title',
                attrs: { level: TitleLevel.DocTitle },
              },
              {
                type: 'contributors',
              },
            ],
          },
          {
            type: 'card',
            attrs: {
              previewContent: null,
              background: {
                type: 'none',
              },
              container: {},
              cardSize: 'default',
              layout: 'blank',
            },
            content: [
              {
                type: 'heading',
                attrs: {
                  horizontalAlign: null,
                  level: 2,
                },
                content: [
                  {
                    type: 'text',
                    text: 'Quick Start Checklist',
                  },
                ],
              },
              {
                type: 'todo',
                attrs: {
                  indent: 0,
                  checked: false,
                  fontSize: null,
                },
                content: [
                  {
                    type: 'emoji',
                    attrs: {
                      id: 'zap',
                      native: '⚡',
                    },
                  },
                  {
                    type: 'text',
                    text: '️ ',
                  },
                  {
                    type: 'text',
                    marks: [
                      {
                        type: 'bold',
                      },
                    ],
                    text: 'Type ',
                  },
                  {
                    type: 'text',
                    marks: [
                      {
                        type: 'code',
                      },
                    ],
                    text: '/',
                  },
                  {
                    type: 'text',
                    marks: [
                      {
                        type: 'bold',
                      },
                    ],
                    text: ' in a card to open the slash menu',
                  },
                ],
              },
              {
                type: 'bullet',
                attrs: {
                  indent: 1,
                  fontSize: null,
                },
                content: [
                  {
                    type: 'text',
                    text: 'It lets you browse and add content blocks quickly',
                  },
                ],
              },
              {
                type: 'bullet',
                attrs: {
                  indent: 1,
                  fontSize: null,
                },
                content: [
                  {
                    type: 'text',
                    text: 'Content blocks can be added from the ',
                  },
                  {
                    type: 'text',
                    marks: [
                      {
                        type: 'footnoteLabel',
                        attrs: {
                          noteId: '7jZEg',
                        },
                      },
                    ],
                    text: 'insert menu',
                  },
                  {
                    type: 'footnote',
                    attrs: {
                      noteId: '7jZEg',
                    },
                    content: [
                      {
                        type: 'image',
                        attrs: {
                          id: 'Y3FmX',
                          horizontalAlign: null,
                          src: 'https://cdn.gamma.app/zc87vhr30n8uf3n/b98e59519a994c7abd900f44683bbb5d/optimized/insert-menu-explainer.gif',
                          tempUrl:
                            'blob:https://gamma.app/983566b4-f21e-4d28-bc69-d448c1976833',
                          uploadStatus: 0,
                          meta: {
                            width: 700,
                            height: 459,
                            date_file_modified: '2023/01/20 23:08:42 GMT',
                            duration: 4.42,
                            aspect_ratio: 1.525,
                            has_clipping_path: false,
                            frame_count: 36,
                            colorspace: 'RGB',
                            average_color: '#f7f5f5',
                          },
                          providerMeta: null,
                          name: 'insert-menu-explainer.gif',
                          query: null,
                          source: 'image.custom',
                          showPlaceholder: false,
                          fullWidthBlock: false,
                          resize: {
                            clipType: null,
                            clipPath: null,
                            clipAspectRatio: null,
                            width: null,
                          },
                        },
                      },
                    ],
                  },
                  {
                    type: 'text',
                    text: ' if you prefer to drag',
                  },
                ],
              },
              {
                type: 'todo',
                attrs: {
                  indent: 0,
                  checked: false,
                  fontSize: null,
                },
                content: [
                  {
                    type: 'emoji',
                    attrs: {
                      id: 'heavy_plus_sign',
                      native: '➕',
                    },
                  },
                  {
                    type: 'text',
                    text: ' ',
                  },
                  {
                    type: 'text',
                    marks: [
                      {
                        type: 'bold',
                      },
                    ],
                    text: 'Add a new card',
                  },
                  {
                    type: 'text',
                    text: ' (type ',
                  },
                  {
                    type: 'text',
                    marks: [
                      {
                        type: 'code',
                      },
                    ],
                    text: '/card',
                  },
                  {
                    type: 'text',
                    text: ') or select a template via the ',
                  },
                  {
                    type: 'text',
                    marks: [
                      {
                        type: 'footnoteLabel',
                        attrs: {
                          noteId: 'xLt8J',
                        },
                      },
                    ],
                    text: 'plus button',
                  },
                  {
                    type: 'footnote',
                    attrs: {
                      noteId: 'xLt8J',
                    },
                    content: [
                      {
                        type: 'image',
                        attrs: {
                          id: '3CSXR',
                          horizontalAlign: null,
                          src: 'https://cdn.gamma.app/zc87vhr30n8uf3n/a8d6b7149f75401ebd54e6b022670a3e/optimized/image.png',
                          tempUrl:
                            'blob:https://gamma.app/5368f25a-cf30-4410-bf62-bd26080b01f5',
                          uploadStatus: 0,
                          meta: {
                            width: 844,
                            height: 608,
                            date_file_modified: '2023/01/20 21:38:09 GMT',
                            aspect_ratio: 1.388,
                            has_clipping_path: false,
                            frame_count: 1,
                            colorspace: 'RGB',
                            average_color: '#f7f5f5',
                          },
                          providerMeta: null,
                          name: 'image.png',
                          query: null,
                          source: 'image.custom',
                          showPlaceholder: false,
                          fullWidthBlock: false,
                          resize: {
                            clipType: null,
                            clipPath: null,
                            clipAspectRatio: null,
                            width: null,
                          },
                        },
                      },
                    ],
                  },
                ],
              },
              {
                type: 'todo',
                attrs: {
                  indent: 0,
                  checked: false,
                  fontSize: null,
                },
                content: [
                  {
                    type: 'emoji',
                    attrs: {
                      id: 'newspaper',
                      native: '📰',
                    },
                  },
                  {
                    type: 'text',
                    text: ' ',
                  },
                  {
                    type: 'text',
                    marks: [
                      {
                        type: 'bold',
                      },
                    ],
                    text: 'Create multi-column layouts',
                  },
                  {
                    type: 'text',
                    text: ' (type ',
                  },
                  {
                    type: 'text',
                    marks: [
                      {
                        type: 'code',
                      },
                    ],
                    text: '/columns',
                  },
                  {
                    type: 'text',
                    text: ') or ',
                  },
                  {
                    type: 'text',
                    marks: [
                      {
                        type: 'footnoteLabel',
                        attrs: {
                          noteId: 'JyryA',
                        },
                      },
                    ],
                    text: 'drag blocks',
                  },
                  {
                    type: 'footnote',
                    attrs: {
                      noteId: 'JyryA',
                    },
                    content: [
                      {
                        type: 'image',
                        attrs: {
                          id: 'iJXTh',
                          horizontalAlign: null,
                          src: 'https://cdn.gamma.app/zc87vhr30n8uf3n/3e47c137ba5d4d13bdafda584b3a8f94/optimized/drag-columns-optimized.gif',
                          tempUrl:
                            'blob:https://gamma.app/654cd2e0-6972-4a24-a7c0-25eff0cf9050',
                          uploadStatus: 0,
                          meta: {
                            width: 500,
                            height: 313,
                            date_file_modified: '2023/01/20 21:35:04 GMT',
                            duration: 9.83,
                            aspect_ratio: 1.597,
                            has_clipping_path: false,
                            frame_count: 62,
                            colorspace: 'RGB',
                            average_color: '#e8cac5',
                          },
                          providerMeta: null,
                          name: 'drag-columns-optimized.gif',
                          query: null,
                          source: 'image.custom',
                          showPlaceholder: false,
                          fullWidthBlock: false,
                          resize: {
                            clipType: null,
                            clipPath: null,
                            clipAspectRatio: null,
                            width: null,
                          },
                        },
                      },
                    ],
                  },
                  {
                    type: 'text',
                    text: ' side by side',
                  },
                ],
              },
              {
                type: 'todo',
                attrs: {
                  indent: 0,
                  checked: false,
                  fontSize: null,
                },
                content: [
                  {
                    type: 'emoji',
                    attrs: {
                      id: 'bridge_at_night',
                      native: '🌉',
                    },
                  },
                  {
                    type: 'text',
                    text: ' ',
                  },
                  {
                    type: 'text',
                    marks: [
                      {
                        type: 'bold',
                      },
                    ],
                    text: 'Add an image ',
                  },
                  {
                    type: 'text',
                    text: '(type ',
                  },
                  {
                    type: 'text',
                    marks: [
                      {
                        type: 'code',
                      },
                    ],
                    text: '/image',
                  },
                  {
                    type: 'text',
                    text: '). You can drag in or paste image files too!',
                  },
                ],
              },
              {
                type: 'todo',
                attrs: {
                  indent: 0,
                  checked: false,
                  fontSize: null,
                },
                content: [
                  {
                    type: 'emoji',
                    attrs: {
                      id: 'art',
                      native: '🎨',
                    },
                  },
                  {
                    type: 'text',
                    text: ' ',
                  },
                  {
                    type: 'text',
                    marks: [
                      {
                        type: 'bold',
                      },
                    ],
                    text: 'Change the deck ',
                  },
                  {
                    type: 'text',
                    marks: [
                      {
                        type: 'bold',
                      },
                      {
                        type: 'footnoteLabel',
                        attrs: {
                          noteId: 'mTOBc',
                        },
                      },
                    ],
                    text: 'theme',
                  },
                  {
                    type: 'footnote',
                    attrs: {
                      noteId: 'mTOBc',
                    },
                    content: [
                      {
                        type: 'paragraph',
                        attrs: {
                          horizontalAlign: null,
                          fontSize: null,
                        },
                        content: [
                          {
                            type: 'text',
                            text: 'Click the ',
                          },
                          {
                            type: 'text',
                            marks: [
                              {
                                type: 'code',
                              },
                            ],
                            text: 'Theme',
                          },
                          {
                            type: 'text',
                            text: ' button in the top toolbar, then try clicking some of the different options',
                          },
                        ],
                      },
                      {
                        type: 'image',
                        attrs: {
                          id: 'GHNdu',
                          horizontalAlign: 'center',
                          src: 'https://cdn.gamma.app/zc87vhr30n8uf3n/db839cee72b7497b9ff1424987641ed5/optimized/image.png',
                          tempUrl: null,
                          uploadStatus: 0,
                          meta: {
                            width: 806,
                            height: 1678,
                            date_file_modified: '2022/08/01 23:13:09 GMT',
                            aspect_ratio: 0.48,
                            has_clipping_path: false,
                            frame_count: 1,
                            colorspace: 'RGB',
                            average_color: '#ccc7ca',
                          },
                          providerMeta: null,
                          name: 'image.png',
                          query: null,
                          source: 'image.custom',
                          showPlaceholder: false,
                          fullWidthBlock: false,
                          resize: {
                            clipType: 'inset',
                            clipPath: [
                              '17.31441903150651%',
                              '0%',
                              '41.5572854690296%',
                              '0%',
                            ],
                            clipAspectRatio: 1.1678975712005124,
                            width: 209.77777777777777,
                          },
                        },
                      },
                    ],
                  },
                  {
                    type: 'text',
                    text: ' to give it a different look and feel',
                  },
                ],
              },
              {
                type: 'todo',
                attrs: {
                  indent: 0,
                  checked: false,
                  fontSize: null,
                },
                content: [
                  {
                    type: 'emoji',
                    attrs: {
                      id: 'arrow_forward',
                      native: '▶️',
                    },
                  },
                  {
                    type: 'text',
                    text: ' ',
                  },
                  {
                    type: 'text',
                    marks: [
                      {
                        type: 'bold',
                      },
                    ],
                    text: 'Try present mode',
                  },
                  {
                    type: 'text',
                    text: ' and use your arrow keys and ',
                  },
                  {
                    type: 'text',
                    marks: [
                      {
                        type: 'footnoteLabel',
                        attrs: {
                          noteId: 'UGnAU',
                        },
                      },
                    ],
                    text: 'spotlight',
                  },
                  {
                    type: 'footnote',
                    attrs: {
                      noteId: 'UGnAU',
                    },
                    content: [
                      {
                        type: 'paragraph',
                        attrs: {
                          horizontalAlign: null,
                          fontSize: null,
                        },
                        content: [
                          {
                            type: 'text',
                            text: 'Spotlight lets you',
                          },
                          {
                            type: 'text',
                            marks: [
                              {
                                type: 'bold',
                              },
                            ],
                            text: ' focus ',
                          },
                          {
                            type: 'text',
                            text: 'on',
                          },
                          {
                            type: 'text',
                            marks: [
                              {
                                type: 'bold',
                              },
                            ],
                            text: ' one piece of content at a time. ',
                          },
                          {
                            type: 'text',
                            text: 'While presenting, press ',
                          },
                          {
                            type: 'text',
                            marks: [
                              {
                                type: 'code',
                              },
                            ],
                            text: 's',
                          },
                          {
                            type: 'text',
                            text: ' to enter spotlight mode.',
                          },
                        ],
                      },
                      {
                        type: 'image',
                        attrs: {
                          id: 'YOFhU',
                          horizontalAlign: null,
                          src: 'https://cdn.gamma.app/zc87vhr30n8uf3n/ed0b362f6c2f4a438cd3188ba7697a02/optimized/spotlight-compressed.gif',
                          tempUrl:
                            'blob:https://gamma.app/d2eaae7f-515e-4e06-b396-4b0551a5a999',
                          uploadStatus: 0,
                          meta: {
                            width: 1200,
                            height: 750,
                            date_file_modified: '2023/01/13 21:48:00 GMT',
                            duration: 6.66,
                            aspect_ratio: 1.6,
                            has_clipping_path: false,
                            frame_count: 34,
                            colorspace: 'RGB',
                            average_color: '#323030',
                          },
                          providerMeta: null,
                          name: 'spotlight-compressed.gif',
                          query: null,
                          source: 'image.custom',
                          showPlaceholder: false,
                          fullWidthBlock: false,
                          resize: {
                            clipType: null,
                            clipPath: null,
                            clipAspectRatio: null,
                            width: null,
                          },
                        },
                      },
                    ],
                  },
                  {
                    type: 'text',
                    text: ' to move around',
                  },
                ],
              },
              {
                type: 'todo',
                attrs: {
                  indent: 0,
                  checked: false,
                  fontSize: null,
                },
                content: [
                  {
                    type: 'emoji',
                    attrs: {
                      id: 'tada',
                      native: '🎉',
                    },
                  },
                  {
                    type: 'text',
                    text: ' ',
                  },
                  {
                    type: 'text',
                    marks: [
                      {
                        type: 'bold',
                      },
                    ],
                    text: 'Share your deck',
                  },
                  {
                    type: 'text',
                    text: '.',
                  },
                  {
                    type: 'text',
                    marks: [
                      {
                        type: 'bold',
                      },
                    ],
                    text: ' ',
                  },
                  {
                    type: 'text',
                    text: 'Publish it to the world, invite others, or export a PDF',
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  }
}
