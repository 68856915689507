import { Box, Flex } from '@chakra-ui/react'
import { cx } from '@chakra-ui/utils'
import { NodeViewProps } from '@tiptap/core'
import { motion } from 'framer-motion'

import { config } from 'config'
import { CLICKABLE_BOX_CLASS } from 'modules/theming/styles/box'

import { AnnotatableNodeViewWrapper } from '../../Annotatable/components/AnnotatableNodeViewWrapper'
import { EmbedPreview, MiniEmbedPreview } from '../Embed'
import { isNodeViewInGallery } from '../Gallery'
import { VideoEmbedAttrs } from '../types'
import {
  getZoomLayoutId,
  useMediaZoom,
  ZoomableOverlay,
  ZoomClickCapture,
  ZoomTransition,
} from '../Zoomable'
import { VideoPlayer } from './VideoPlayer'

const MotionBox = motion(Box)
const MotionFlex = motion(Flex)

export const VideoView = (nodeViewProps: NodeViewProps) => {
  const { node, editor } = nodeViewProps
  const { displayStyle, id } = node.attrs as VideoEmbedAttrs
  const { isZoomed, enterZoom, exitZoom } = useMediaZoom(id)
  const inGallery = isNodeViewInGallery(nodeViewProps)

  const showInline =
    // Never show inline iframe within the context of puppeteer, because there's
    // no guarantee what will load in the iframe!
    !config.GAMMA_PUPPETEER_SERVICE && displayStyle === 'inline'

  return (
    <AnnotatableNodeViewWrapper {...nodeViewProps} as="div">
      <MotionBox
        layoutDependency={isZoomed}
        layoutId={getZoomLayoutId(id)}
        transition={ZoomTransition}
        h="100%"
        className={cx('embed-preview', !showInline && CLICKABLE_BOX_CLASS)}
        position="relative"
      >
        {inGallery ? (
          <>
            <ZoomClickCapture enterZoom={enterZoom} />
            <MiniEmbedPreview {...nodeViewProps} />
          </>
        ) : showInline ? (
          <VideoPlayer {...nodeViewProps} />
        ) : (
          <>
            <ZoomClickCapture enterZoom={enterZoom} />
            <EmbedPreview {...nodeViewProps} />
          </>
        )}
      </MotionBox>
      {!inGallery && (
        <ZoomableOverlay
          isZoomed={isZoomed}
          exitZoom={exitZoom}
          editor={editor}
        >
          <MotionFlex
            layoutDependency={isZoomed}
            layoutId={getZoomLayoutId(id)}
            transition={ZoomTransition}
            // Fill the viewport, center inside
            width="var(--media-maxW)"
            height="var(--media-maxH)"
            direction="column"
            align="center"
            justify="center"
          >
            <VideoPlayer {...nodeViewProps} isZoomed />
          </MotionFlex>
        </ZoomableOverlay>
      )}
    </AnnotatableNodeViewWrapper>
  )
}
