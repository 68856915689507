import {
  BackgroundType,
  DEFAULT_THEME_BACKGROUND,
  getBackgroundColor,
} from 'modules/tiptap_editor/styles/backgroundStyles'
import { filterNotVoid } from 'utils/array'
import { isColorDark } from 'utils/color'

import { DEFAULT_ACCENT_COLOR } from '../constants'
import { Theme } from '../types'

export const themeSortFunction = (a: Theme, b: Theme) => {
  const regex = new RegExp(/\p{Emoji_Presentation}/, 'gu')
  const nameA = a.name.replace(regex, '').trim()
  const nameB = b.name.replace(regex, '').trim()

  return (
    // sort by priority first, then sort by name (ignoring punctuation/emoji)
    b.priority - a.priority ||
    nameA.localeCompare(nameB, 'en-US', { ignorePunctuation: true })
  )
}

export const getThemeBackgroundOrDefault = (theme: Theme) =>
  theme.config?.background &&
  theme.config.background.type !== BackgroundType.NONE
    ? theme.config.background
    : DEFAULT_THEME_BACKGROUND

export const isThemeDark = (theme: Theme) => {
  const cardColor = getThemeCardColor(theme)
  return cardColor
    ? isColorDark(cardColor)
    : theme.config.container?.isDark ?? false
}

export const getAccentColor = (theme: Theme) =>
  theme.accentColor ?? DEFAULT_ACCENT_COLOR

export const getAccentColorPalette = (theme: Theme) => {
  const accentColor = getAccentColor(theme)
  const secondaryColors = filterNotVoid(
    theme.config.secondaryAccentColors || []
  )
  const palette = [accentColor].concat(secondaryColors)
  return palette
}

export const getThemeCardColor = (theme: Theme): string | undefined => {
  const { cardBackground } = theme.config
  return getBackgroundColor(cardBackground)
}
