import { Box } from '@chakra-ui/react'
import { Editor } from '@tiptap/core'
import React, { memo, useCallback, useEffect } from 'react'

import { useHealthCheck } from 'modules/api'
import { OfflineInfoBox } from 'modules/offline'
import { ACCENT_IMAGE_SOURCE_KEY } from 'modules/theming'
import {
  MediaSourcesMap,
  MediaSourceType,
  NO_BACKGROUND_SOURCE_KEY,
} from 'modules/tiptap_editor/extensions/media/MediaSources'

import { BackgroundOptions } from '../../styles/backgroundStyles'
import { MediaDrawerMenu } from '../drawers/MediaDrawer/MediaDrawerMenu'

type BackgroundPanelProps = {
  editor?: Editor
  defaultMessage: React.ReactNode
  isDark?: boolean
  currentBackground: BackgroundOptions
  setCurrentBackground: (b: BackgroundOptions) => void
  setCurrentSourceKey: (s: string) => void
  currentSourceKey: string
  currentSource: MediaSourceType
  isValidSource: (s: MediaSourceType) => boolean
}

export const BackgroundPanel = memo(
  ({
    editor,
    defaultMessage,
    isDark,
    setCurrentSourceKey,
    currentSourceKey,
    currentSource,
    setCurrentBackground,
    currentBackground,
    isValidSource,
  }: BackgroundPanelProps) => {
    const { isConnected } = useHealthCheck()

    const updateBackgroundAttributes = (attrs: Record<string, any>) => {
      if (!currentSource.backgroundType) {
        return
      }
      if (currentSource.key === ACCENT_IMAGE_SOURCE_KEY) {
        // For accent images, attrs will be the entire background
        setCurrentBackground(attrs as BackgroundOptions)
      } else {
        setCurrentBackground({
          ...currentBackground,
          type: currentSource.backgroundType,
          [currentSource.backgroundType]: {
            ...attrs,
          },
          source: currentSourceKey,
        })
      }
    }

    const getBackgroundAttributes = (
      bg: BackgroundOptions
    ): BackgroundOptions | Record<string, any> => {
      if (!currentSource.backgroundType) {
        return {}
      }
      if (currentSource.key === ACCENT_IMAGE_SOURCE_KEY) {
        return bg
      }
      return bg[currentSource.backgroundType] || {}
    }

    const resetBackground = useCallback(
      (sourceKey: string) => {
        const source = MediaSourcesMap[sourceKey]
        if (!source.backgroundType) {
          return
        }
        setCurrentBackground({
          type: source.backgroundType,
          source: sourceKey,
        })
      },
      [setCurrentBackground]
    )

    const resetToPlaceholder = useCallback(
      () => resetBackground(currentSourceKey),
      [currentSourceKey, resetBackground]
    )

    const onChange = useCallback(
      (value: string) => {
        if (value === NO_BACKGROUND_SOURCE_KEY) {
          resetBackground(NO_BACKGROUND_SOURCE_KEY)
        }
        setCurrentSourceKey(value)
      },
      [setCurrentSourceKey, resetBackground]
    )

    useEffect(() => {
      // Set source picker value on first load, and any time it changes (collaboratively)
      if (
        currentBackground.source &&
        MediaSourcesMap[currentBackground.source]
      ) {
        setCurrentSourceKey(currentBackground.source)
      }
    }, [currentBackground.source, setCurrentSourceKey])

    const isConnectedOrAvailableOffline = Boolean(
      isConnected || currentSource?.availableOffline
    )

    return (
      <>
        <OfflineInfoBox
          isConnected={isConnectedOrAvailableOffline}
          label="Some background options will not be available until you reconnect."
          mb={3}
          my={6}
        />
        <MediaDrawerMenu
          isValidSource={isValidSource}
          onChange={onChange}
          currentSource={currentSource}
        />
        {currentSource && (
          <Box
            opacity={isConnectedOrAvailableOffline ? 1 : 0.4}
            pointerEvents={isConnectedOrAvailableOffline ? 'initial' : 'none'}
          >
            <currentSource.Panel
              editor={editor}
              updateAttributes={updateBackgroundAttributes}
              currentAttributes={getBackgroundAttributes(currentBackground)}
              resetToPlaceholder={resetToPlaceholder}
              editType="background"
              isDark={isDark}
            />
          </Box>
        )}
        {currentSourceKey === NO_BACKGROUND_SOURCE_KEY && defaultMessage}
      </>
    )
  }
)

BackgroundPanel.displayName = 'BackgroundPanel'
