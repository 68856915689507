import { Editor, JSONContent } from '@tiptap/core'
import React, {
  memo,
  PropsWithChildren,
  useContext,
  useMemo,
  useRef,
} from 'react'

export const CardSuggestionEditorContext = React.createContext({
  enabled: false,

  /**
   * Function to register an editor instance
   */
  registerEditor: (id: string, editor: Editor) => {},

  /**
   * A function to get all the cards from each editor instance
   */
  getAllCards: () => [] as JSONContent[],
})

export function useCardSuggestionEditorContext() {
  const ctx = useContext(CardSuggestionEditorContext)
  return ctx
}

const CardSuggestionEditorContextProvider = ({
  children,
}: PropsWithChildren<{}>) => {
  const editors = useMemo(() => new Map<string, Editor>(), [])
  const registerEditor = useRef((id: string, editor: Editor) => {
    editors.set(id, editor)
  })
  const getAllCards = useRef(() => {
    return Array.from(editors.values())
      .map((editor) => {
        return editor.state.doc.firstChild?.content.toJSON()?.[0] as JSONContent
      })
      .filter(Boolean)
  })

  return (
    <CardSuggestionEditorContext.Provider
      value={{
        enabled: true,
        registerEditor: registerEditor.current,
        getAllCards: getAllCards.current,
      }}
    >
      {children}
    </CardSuggestionEditorContext.Provider>
  )
}

export function withCardSuggestionProvider<T>(Component: React.FC<T>) {
  const WithSuggestionProvider = (props: T) => (
    <CardSuggestionEditorContextProvider>
      <Component {...props} />
    </CardSuggestionEditorContextProvider>
  )

  return memo(WithSuggestionProvider)
}
