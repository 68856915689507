import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro'
import { CommandInfo } from '../types'
import {
  SingleHipsumParagraph,
  SingleHipsumH1,
  SingleHipsumH3,
  ShortHipsumList,
  RandomHipsumCard,
} from './hipsumTemplates'

export const HipsumCommands: CommandInfo[] = [
  {
    key: 'hipsum-paragraph',
    name: 'Hipsum',
    nodeName: 'paragraph',
    icon: duotone('cup-togo'),
    execute: (editor) => {
      editor.commands.insertContent(SingleHipsumParagraph())
    },
    keywords: ['hipster', 'ipsum', 'hipsum', 'paragraph'],
    featureFlag: 'loremIpsum',
  },
  {
    key: 'hipsum-title',
    name: 'HipTitle',
    nodeName: 'heading',
    icon: duotone('coffee-beans'),
    execute: (editor) => {
      editor.commands.insertContent(SingleHipsumH1())
    },
    keywords: ['hipster', 'ipsum', 'hipTitle', 'title', 'h1', 'heading'],
    featureFlag: 'loremIpsum',
  },
  {
    key: 'hipsum-heading',
    name: 'HipHeading',
    nodeName: 'heading',
    icon: duotone('coffee-bean'),
    execute: (editor) => {
      editor.commands.insertContent(SingleHipsumH3())
    },
    keywords: ['hipster', 'ipsum', 'h3', 'h2', 'heading'],
    featureFlag: 'loremIpsum',
  },
  {
    key: 'hipsum-list',
    name: 'HipList',
    nodeName: 'bullet',
    icon: duotone('mug-saucer'),
    execute: (editor) => {
      editor.commands.insertContent(ShortHipsumList())
    },
    keywords: ['hipster', 'ipsum', 'list'],
    featureFlag: 'loremIpsum',
  },
  {
    key: 'hipsum-card',
    name: 'HipCard',
    nodeName: 'gridLayout',
    icon: duotone('coffee-pot'),
    execute: (editor) => {
      editor.commands.insertContent(RandomHipsumCard())
    },
    keywords: ['hipster', 'ipsum', 'hipcard', 'card'],
    featureFlag: 'loremIpsum',
  },
]
