import {
  Flex,
  FlexProps,
  HStack,
  ImageProps,
  Spinner,
  Tag,
  TagLabel,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome'
import React from 'react'

interface MediaPlaceholderImageProps extends FlexProps {
  icon?: FontAwesomeIconProps['icon']
  isLoading?: boolean
  hasUploadError?: boolean
}

export const MediaPlaceholderSpinner = () => (
  <Tag
    size="sm"
    variant="subtle"
    colorScheme="gray"
    pos="absolute"
    top={4}
    left={4}
    p={1}
  >
    <Spinner color="gray.600" size="xs" />
  </Tag>
)

export const MediaPlaceholderErrorUploadingTag = () => (
  <Tag
    size="sm"
    variant="subtle"
    colorScheme="red"
    pos="absolute"
    top={4}
    left={4}
  >
    <TagLabel>
      <FontAwesomeIcon icon={regular('exclamation-circle')} /> Error uploading
      image.
    </TagLabel>
  </Tag>
)

export const MediaPlaceholderImage: React.FC<MediaPlaceholderImageProps> = ({
  children,
  icon = regular('photo-video'),
  isLoading = false,
  hasUploadError = false,
  ...flexProps
}) => {
  return (
    <Flex
      color="gray.400"
      align="center"
      justify="center"
      p={6}
      bg="gray.200"
      className="placeholder"
      data-drag-handle
      position="relative"
      {...flexProps}
    >
      <HStack spacing={4}>
        <FontAwesomeIcon size="2x" icon={icon} />
      </HStack>
      {isLoading && <MediaPlaceholderSpinner />}
      {hasUploadError && <MediaPlaceholderErrorUploadingTag />}
      {children}
    </Flex>
  )
}

export const mediaPlaceholderStyleProps: ImageProps = {
  minW: 16,
  minH: 16,
  bg: 'gray.200',
}
