import { Variants } from 'framer-motion'
import { useEffect, useRef } from 'react'

import { featureFlags } from 'modules/featureFlags'
import { useAppSelector, useAppStore } from 'modules/redux'

import {
  selectCardIdMap,
  selectCardIds,
  selectFollowingAttached,
  selectMode,
  selectPresentingCardId,
} from '../../../reducer'
import { EditorModeEnum } from '../../../types'
import { setCardCollapsed } from '../CardCollapse'
import { BETWEEN_CARDS_FRAMER_TRANSITION } from '../constants'

type CustomArgs = [mode: EditorModeEnum, cardId: string]

/**
 * These are the 4 possible framer-motion variants to pass to our MotionBox that wraps each card.
 * See https://www.framer.com/docs/component/###variants
 */
export const PresentVariants: Variants = {
  /** This variant is essentially DOC_MODE */
  doc: () => {
    return {
      position: 'relative',
      overflowY: 'visible',
      zIndex: 'auto',
      transitionEnd: {
        // DO NOT REMOVE.
        // Temp workaround to fix trailing styles.
        // https://github.com/framer/motion/issues/823
        y: 0,
      },
    }
  },

  presentCollapsed: (_, current) => {
    const { y } = current
    const wasNextPrev = y && y !== 0

    return {
      y: 0,
      position: 'relative',
      zIndex: 'auto',
      transition: wasNextPrev
        ? {
            y: { duration: 0 },
          }
        : undefined,
    }
  },

  /** Present mode off screen to the top (negative y offset) */
  presentPrev: ([mode, cardId]: CustomArgs, current) => {
    const wasRelative = current.position == 'relative'
    const y = '-100%'

    const props = {
      y,
      // Wait til transition is complete to set position: fixed to avoid a flicker over the current card
      transitionEnd: {
        position: 'fixed',
        zIndex: 2, // Over the present mode background (1)
        overflowY: 'hidden',
      },
      transition: wasRelative
        ? { duration: 0 }
        : BETWEEN_CARDS_FRAMER_TRANSITION,
    } as const
    return props
  },

  /** Present mode off screen to the bottom (positive y offset) */
  presentNext: ([mode, cardId]: CustomArgs, current) => {
    const wasRelative = current.position == 'relative'
    const y = '100%'
    const props = {
      y,
      // Wait til transition is complete to set position: fixed to avoid a flicker over the current card
      transitionEnd: {
        overflowY: 'hidden',
        position: 'fixed',
        zIndex: 2, // Over the present mode background (1)
      },
      transition: wasRelative
        ? { duration: 0 }
        : BETWEEN_CARDS_FRAMER_TRANSITION,
    } as const
    return props
  },

  /** Present mode center screen (card is being presented) */
  presentCurrent: ([mode, cardId]: CustomArgs) => {
    const props = {
      y: 0,
      overflowY: 'auto',
      position: 'fixed',
      zIndex: 2, // Over the present mode background (1)
      transition: BETWEEN_CARDS_FRAMER_TRANSITION,
    } as const
    console.debug(
      `%c[PresentVariants] Card id ${cardId} is presentCurrent`,
      'background-color: deeppink',
      {
        mode,
        props,
      }
    )
    return props
  },

  /** Present mode center screen , underneath an expanded child */
  presentParent: ([mode, cardId]: CustomArgs) => {
    const props = {
      y: 0,
      position: 'fixed',
      zIndex: 2, // This sets the stacking context for nested cards, so it needs to be above the background (1) even though it's hidden
    } as const
    return props
  },
}

// The framer variant to use for a given card
export type PresentVariant =
  | 'doc'
  | 'presentCollapsed'
  | 'presentPrev'
  | 'presentCurrent'
  | 'presentNext'
  | 'presentParent'

/**
 * Given a card ID, return the current PresentVariant to indicate how this
 * card should currently be displayed.
 *
 * This hook only changes once we've fully entered or fully left present mode
 * by keeping track of the most recent value in a ref. This is necessary because
 * 2 separate variables change between DOC<>SLIDE mode (the mode and the presentingCardId)
 *
 * If the hook determines were in the middle of a state change, it returns the last known value
 *
 * Note that the mode always changes first, which allows us to derive the direction were going.
 * See similar logic for the doc where in Doc.tsx
 *
 * |------     Mode      ------|------ presentingCardId ------|--------- status ---------|
 * |-------------------------------------------------------------------------------------|
 * |------    SLIDE      ------|------      string      ------|--------- PRESENT --------|  <-- isPresentModeReady
 * |------    SLIDE      ------|------      empty       ------|-- SWITCHING TO PRESENT --|  <-- isSwitchingToPresentMode
 * |------     DOC       ------|------      string      ------|--- SWITCHING TO DOC  ----|  <-- isSwitchingToDocMode
 * |------     DOC       ------|------      empty       ------|---------   DOC   --------|  <-- isDocModeReady
 * |-------------------------------------------------------------------------------------|
 */
export const usePresentVariant = (cardId: string) => {
  const prevVariant = useRef<PresentVariant>('doc')
  const store = useAppStore()

  const value = useAppSelector<PresentVariant>((state) => {
    const presentingCardId = selectPresentingCardId(state) || '' // Currently presenting card ID
    const cardIdMap = selectCardIdMap(state) // The tree of card IDs with children
    const cardIds = selectCardIds(state) // A flat list of all card IDs
    const mode = selectMode(state)
    const presentingCardsParents = cardIdMap.parents[presentingCardId] || []
    // The portion of the card tree anchored at the parent of the presenting card (it + its siblings)
    const presentingCardSiblingTree = presentingCardsParents.reduce(
      (acc, curr) => acc[curr],
      cardIdMap.tree
    )

    const hasPresentingCardId = cardIds.includes(presentingCardId)
    const isPresentMode = mode === EditorModeEnum.SLIDE_VIEW

    const _isPresentModeReady = isPresentMode && hasPresentingCardId
    const isSwitchingToPresentMode = isPresentMode && !hasPresentingCardId
    const isSwitchingToDocMode = !isPresentMode && hasPresentingCardId
    const isDocModeReady = !isPresentMode && !hasPresentingCardId

    if (isSwitchingToPresentMode || isSwitchingToDocMode) {
      // If we are switching states, return the last known one
      return prevVariant.current
    } else if (isDocModeReady) {
      return 'doc'
    }

    // Measure sibling distance
    const siblingIds = cardIds.filter((id) => presentingCardSiblingTree[id])
    const getSiblingDistance = (id: string) => {
      // Return Infinity if not a sibling so it's never considered close
      if (!presentingCardSiblingTree[id]) return Infinity
      return siblingIds.indexOf(id) - siblingIds.indexOf(presentingCardId)
    }
    const siblingDistance = getSiblingDistance(cardId)
    const isParentOfPresenting = presentingCardsParents.indexOf(cardId) > -1
    const isDirectChildOfPresentingCardOrItsSiblings = siblingIds.some(
      (siblingId) =>
        presentingCardSiblingTree[siblingId][cardId] &&
        Math.abs(getSiblingDistance(siblingId)) <= 1
    )
    const isPresentingNow = presentingCardId === cardId
    const isPresentingPrev = siblingDistance === -1
    const isPresentingNext = siblingDistance === 1

    const isPresentModeFlat = featureFlags.get('presentModeFlat')
    const parents = cardIdMap.parents[cardId]
    // parents can be undefined if a card is created while in present mode
    if (!parents) {
      return prevVariant.current
    }
    const isDescendantOfPresentingCard = parents.includes(presentingCardId)

    const newVariant =
      isDirectChildOfPresentingCardOrItsSiblings ||
      (isPresentModeFlat && isDescendantOfPresentingCard)
        ? 'presentCollapsed'
        : isParentOfPresenting
        ? 'presentParent'
        : isPresentingNow
        ? 'presentCurrent'
        : isPresentingPrev
        ? 'presentPrev'
        : isPresentingNext
        ? 'presentNext'
        : 'doc'
    return newVariant
  })

  useEffect(() => {
    prevVariant.current = value

    const state = store.getState()

    // TODO - Remove this side effect by computing usePresentVariant elsewhere
    // https://linear.app/gamma-app/issue/G-1256/consider-computing-usepresentvariant-in-one-place

    // Dont update cardCollapse if we're following
    // It will be handled by the overal memoState sync
    if (selectFollowingAttached(state)) return

    const isPresentModeFlat = featureFlags.get('presentModeFlat')

    if (value === 'presentCollapsed' && !isPresentModeFlat) {
      setCardCollapsed(cardId, true)
    } else if (
      [
        'presentParent',
        'presentCurrent',
        'presentPrev',
        'presentNext',
      ].includes(value)
    ) {
      setCardCollapsed(cardId, false)
    }
  }, [store, value, cardId])

  return [value, prevVariant.current] as [PresentVariant, PresentVariant]
}
