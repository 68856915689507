import {
  Box,
  Button,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

import { useAllFeatureFlags } from 'modules/featureFlags'

import {
  MediaSources,
  MediaSourceType,
} from '../../../extensions/media/MediaSources'

export type MediaDrawerMenuProps = {
  currentSource?: MediaSourceType
  onChange: (val: string) => void
  isValidSource: (source: MediaSourceType) => boolean
}

export const MediaDrawerMenu = React.memo(
  ({ currentSource, onChange, isValidSource }: MediaDrawerMenuProps) => {
    const imageSrc = currentSource?.image?.src
    const allFlags = useAllFeatureFlags()
    return (
      <Menu>
        <MenuButton
          as={Button}
          variant="plain"
          w="100%"
          textAlign="left"
          rightIcon={<FontAwesomeIcon icon={regular('chevron-down')} />}
          leftIcon={
            imageSrc ? (
              <Image h="1em" w="1em" objectFit="cover" src={imageSrc} />
            ) : currentSource?.icon ? (
              <FontAwesomeIcon
                icon={currentSource?.icon}
                color="var(--chakra-colors-trueblue-600)"
                fixedWidth
              />
            ) : undefined
          }
          mb={6}
        >
          {currentSource?.label || 'Source'}
        </MenuButton>
        <MenuList zIndex="popover" maxH="60vh" overflowY="auto">
          {Object.entries(MediaSources).map(([sourceGroup, sources]) => {
            const validSources = sources.filter((source) => {
              if (
                source.disabled ||
                (source.featureFlag && !allFlags[source.featureFlag])
              ) {
                return false
              }
              return isValidSource(source)
            })
            if (validSources.length === 0) return null
            return (
              <MenuOptionGroup
                title={sourceGroup}
                key={sourceGroup}
                type="radio"
                onChange={onChange}
                value={currentSource?.key}
              >
                {validSources.map(({ label, key, icon, image }) => {
                  return (
                    <MenuItemOption value={key} key={key}>
                      <Flex direction="row" align="center">
                        {image ? (
                          <Image
                            display="inline"
                            mr={2}
                            h="1em"
                            w="1em"
                            objectFit="contain"
                            src={image.src}
                          />
                        ) : icon ? (
                          <Box display="inline" mr={2} color="trueblue.600">
                            <FontAwesomeIcon icon={icon} />
                          </Box>
                        ) : (
                          <></>
                        )}
                        {label}
                      </Flex>
                    </MenuItemOption>
                  )
                })}
              </MenuOptionGroup>
            )
          })}
        </MenuList>
      </Menu>
    )
  }
)
MediaDrawerMenu.displayName = 'MediaDrawerMenu'
