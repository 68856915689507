import { Extension } from '@tiptap/core'

import { findAnnotatableBlock } from '../utils'
import { createMobileAnnotationPlugin } from './MobileAnnotationPlugin'
import {
  MobileAnnotationPluginKey,
  SetMobileAnnotationBlockCommentEvent,
} from './MobileAnnotationState'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    mobileAnnotations: {
      setMobileAddBlockComment: (ev: MouseEvent | null) => ReturnType
      resetMobileAddBlockComment: () => ReturnType
    }
  }
}

export const MobileAnnotationExtension = Extension.create({
  name: 'mobileAnnotation',

  addCommands() {
    return {
      setMobileAddBlockComment:
        (ev: MouseEvent | null) =>
        ({ dispatch, tr, view }) => {
          if (!dispatch) {
            return false
          }
          if (ev === null) {
            tr.setMeta(MobileAnnotationPluginKey, <
              SetMobileAnnotationBlockCommentEvent
            >{
              setPos: null,
            })
            dispatch(tr)
            return true
          }
          const { clientX, clientY } = ev
          const pos = view.posAtCoords({
            left: clientX,
            top: clientY,
          })
          // could not find an element here, no-op
          if (pos === null) {
            return true
          }

          const found = findAnnotatableBlock(pos.inside, view)
          if (!found) {
            return false
          }
          // compute the offset x, y (x, y distance from the container element)
          const coords = view.coordsAtPos(found.pos)
          const offsetX = clientX - coords.left
          const offsetY = clientY - coords.top
          tr.setMeta(MobileAnnotationPluginKey, <
            SetMobileAnnotationBlockCommentEvent
          >{
            setPos: {
              pos: found.pos,
              end: found.end,
              offsetX,
              offsetY,
            },
          })
          dispatch(tr)
          return true
        },
    }
  },

  addProseMirrorPlugins() {
    return [createMobileAnnotationPlugin(this.editor)]
  },
})
