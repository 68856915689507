// ----- UNCHANGED -----

import { Node } from 'prosemirror-model'
import { createContext, useContext } from 'react'

import { Editor } from './Editor'

export interface ReactNodeViewContextProps {
  onDragStart: (event: DragEvent) => void
  nodeViewContentRef: (element: HTMLElement | null) => void
  node: Node
  editor: Editor
  getPos: () => number
}

export const ReactNodeViewContext = createContext<
  Partial<ReactNodeViewContextProps>
>({
  onDragStart: undefined,
})

export const useReactNodeView = () => useContext(ReactNodeViewContext)
