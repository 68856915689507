/**
 * NB: This is a pared-down version of @tiptap/extension-mention.
 * Original source here: https://github.com/ueberdosis/tiptap/blob/ab4a0e2507b4b92c46d293a0bb06bb00a04af6e0/packages/extension-mention/src/mention.ts
 *
 * The only difference is that this version is totally decoupled from @tiptap/extension-suggestion.
 */

import { DOC_DISPLAY_NAME } from '@gamma-app/ui'
import { mergeAttributes, Node, Range } from '@tiptap/core'

import { ReactNodeViewRenderer } from 'modules/tiptap_editor/react'
import { generateDocUrl } from 'utils/url'

import { ExtensionPriorityMap } from '../constants'
import { MENTION_SUGGESTION_CHARACTER } from '../MentionSuggestionMenu'
import { attrsOrDecorationsChanged } from '../updateFns'
import { DOC_MENTION_NODE_NAME } from './constants'
import { DocMentionNodeView } from './DocMentionView'
import { getMentionPasteRule } from './helpers'

export const DOC_TITLE_FALLBACK = `Unknown ${DOC_DISPLAY_NAME}`
export type DocMentionOptions = {
  HTMLAttributes: Record<string, any>
  suggestion: { char: string }
}
declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    docMention: {
      addDocMention: (
        { id, mentionedById }: DocMentionArgs,
        range: Range
      ) => ReturnType
    }
  }
}

type DocMentionArgs = {
  id: string
  mentionedById?: string
}

export const DocMention = Node.create<DocMentionOptions>({
  name: DOC_MENTION_NODE_NAME,

  addStorage() {
    // We'll use this to build an object where the keys are docIds, values are docTitles.
    // We need these to construct HTML and text strings for the clipboard.
    return {}
  },

  addOptions() {
    return {
      HTMLAttributes: {},
      suggestion: {
        char: MENTION_SUGGESTION_CHARACTER,
      },
    }
  },

  priority: ExtensionPriorityMap.DocMention,
  group: 'inline',
  inline: true,
  selectable: true,
  atom: true,

  addAttributes() {
    return {
      id: {
        default: null,
      },

      mentionedById: {
        default: null,
      },
    }
  },

  addPasteRules() {
    return [
      getMentionPasteRule({
        // Only capture mentions that are for docs other than this one,
        // as that is the definition of what a DocMention is (another doc)
        // However if the url is to this doc with no cardId, also capture it.
        // Mentions to cards within this memo are handled by CardMention,
        // which uses the inverse of this condition.
        filterFn: (docId: string | null, cardId: string | null) =>
          docId !== this.editor.gammaDocId || !cardId,
        getAttributesFn: ({ docId }) => ({ id: docId }),
      })(this.type),
    ]
  },

  addCommands() {
    return {
      addDocMention:
        ({ id, mentionedById }: DocMentionArgs, range) =>
        ({ state, chain }) => {
          const nodeAfter = state.selection.$to.nodeAfter
          const overrideSpace = nodeAfter?.text?.startsWith(' ')

          if (overrideSpace) {
            range.to += 1
          }
          chain()
            .focus()
            .insertContentAt(range, [
              {
                type: this.name,
                attrs: { id, mentionedById },
              },
              {
                type: 'text',
                text: ' ',
              },
            ])
            .run()

          return true
        },
    }
  },

  parseHTML() {
    return [
      {
        tag: `a[data-type="${this.name}"]`,
        // Rules without a priority are counted as having a priority of 50. Rules with a higher
        // priority come first! This makes it so that any content with `data-type="docMention"`
        // will be handled here first before they are handled by, say, the Link extension.
        // See https://prosemirror.net/docs/ref/#model.ParseRule.priority
        // and https://github.com/ProseMirror/prosemirror-model/blob/b8c5166e9ac5c5cf87da3f13012b0044fd8a4bd9/src/from_dom.js#L240
        priority: 51,
      },
    ]
  },

  renderHTML({ HTMLAttributes, node }) {
    // We get the docTitle from this.storage, which is asynchronously updated as the
    // title updates. Fallback to DOC_TITLE_FALLBACK' if we don't have the title yet,
    // or if the user doesn't have access to the doc
    const titleOrHole = this.storage[node.attrs.id] || DOC_TITLE_FALLBACK
    return [
      'a',
      mergeAttributes(
        {
          'data-type': this.name,
          href: generateDocUrl({ docId: node.attrs.id, absolute: true }),
        },
        this.options.HTMLAttributes,
        HTMLAttributes
      ),
      // Based on https://github.com/ueberdosis/tiptap/blob/ab4a0e2507b4b92c46d293a0bb06bb00a04af6e0/packages/extension-mention/src/mention.ts#L117-L120
      titleOrHole,
    ]
  },

  renderText({ node }) {
    // Fallback to DOC_TITLE_FALLBACK' if we don't have the title yet,
    // or if the user doesn't have access to the doc
    const docTitle = this.storage[node.attrs.id] || DOC_TITLE_FALLBACK
    const docUrl = generateDocUrl({ docId: node.attrs.id, absolute: true })
    return `[${docTitle}](${docUrl})`
  },

  addNodeView() {
    return ReactNodeViewRenderer(DocMentionNodeView, {
      update: attrsOrDecorationsChanged,
    })
  },
})
