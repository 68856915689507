import { config } from 'config'
import { ExistingWorkspace } from 'modules/api'
import { slugify } from 'utils/slugify'

// See packages/server/src/common/utils.ts
export const DOC_NANOID_REGEX = /[0-9a-z]{15}$/

export const CARD_HASH_PREFIX = 'card-'

export const generateInviteUrl = (workspace: ExistingWorkspace) => {
  return `${config.FRONTEND_URL}/${workspace.id}/join?code=${workspace.inviteCode}`
}

export const generateDocUrl = ({
  docId,
  path = 'docs',
  docTitle = '',
  token = undefined,
  absolute = false,
}: {
  docId?: string
  path?: 'docs' | 'example' | 'public' | 'embed'
  docTitle?: string
  token?: string
  absolute?: boolean
}) => {
  return `${!absolute ? '' : config.FRONTEND_URL}/${path}/${
    docTitle ? `${slugify(docTitle)}-` : ''
  }${docId}${token ? `?token=${token}` : ''}`
}

export const generateChannelUrl = (
  { id, slug }: { id: string; slug?: string },
  relative = true
) => {
  return `${relative ? '' : config.FRONTEND_URL}/channels/${slug}-${id}`
}

export const generateExampleUrl = ({ docId }, relative = true) => {
  return `${relative ? '' : config.FRONTEND_URL}/example/${docId}`
}

export const generateFontsUrl = () => {
  return `${config.FRONTEND_URL}/#fonts`
}

export const generateEmbedCode = ({
  docId,
  docTitle,
}: {
  docId: string
  docTitle?: string
}) => {
  const embedUrl = generateDocUrl({ docId, path: 'embed', absolute: true })
  return `<iframe src="${embedUrl}" style="width: 700px; max-width: 100%; height: 450px" allow="fullscreen" title="${
    docTitle ?? 'Gamma embed'
  }"></iframe>`
}

export const getCardUrl = ({
  cardId,
  docId,
  isPresentMode = false,
}: {
  cardId: string
  docId?: string
  isPresentMode?: boolean
}) => {
  if (!docId) {
    console.error('[getCardUrl] docId is undefined. Returning empty string')
    return ''
  }
  const currentPath =
    typeof window !== 'undefined' && window.location.pathname.split('/')[1]
  const shouldUseCurrentPath =
    currentPath && ['docs', 'public', 'example'].includes(currentPath)
  const isEmbedUrl = currentPath === 'embed'
  const url = new URL(
    `${config.FRONTEND_URL}/${
      shouldUseCurrentPath ? currentPath : isEmbedUrl ? 'public' : 'docs'
    }/${docId}`
  )
  if (isPresentMode) {
    url.searchParams.set('mode', 'present')
  }
  if (config.SHARE_TOKEN) {
    url.searchParams.set('token', config.SHARE_TOKEN)
  }
  url.hash = `${CARD_HASH_PREFIX}${cardId}`
  return url.toString()
}

export const generateCommentUrl = ({ docId, id }) => {
  return `${config.FRONTEND_URL}/docs/${docId}#comment-${id}`
}

/**
 * Next router doesn't provide a first class way to access the browser hash,
 * so this is an SSR defensive utility to get it from window.location.hash
 */
export const getUrlHash = (url?: string): string | undefined => {
  if (typeof window === 'undefined') return

  const hash = url
    ? new URL(url.startsWith('https') ? url : `${config.FRONTEND_URL}${url}`)
        .hash
    : window.location.hash

  return hash.split('#').pop() || undefined
}

/**
 * Grabs the cardId and commentId from the URL hash if present
 */
export const parseUrlHash = (
  url?: string
): {
  cardId: string | null
  commentId: string | null
} => {
  const hash = getUrlHash(url)
  const cardId = hash?.split(CARD_HASH_PREFIX)?.[1] || null
  const commentId = hash?.split('comment-')?.[1] || null
  return {
    cardId,
    commentId,
  }
}

export const getExistingQueryParams = () => {
  if (typeof window === 'undefined') return {}

  const url = new URL(window.location.href)
  const rest: { [key: string]: string } = {}
  for (const [key, value] of url.searchParams.entries()) {
    rest[key] = value
  }
  return rest
}

const stripTrailingSlash = (str: string) => {
  return str.endsWith('/') ? str.slice(0, -1) : str
}

export const getPathnameParts = (url?: string): [string, string] => {
  if (typeof window === 'undefined') return ['', '']
  const urlObj = new URL(url || window.location.href)
  urlObj.pathname = stripTrailingSlash(urlObj.pathname)

  // Strip trailing slashes to ensure lastPart is correct
  const prefix = urlObj.pathname
    .split('/')
    .slice(0, -1)
    .filter(Boolean) // Filter out any empty strings
    .join('/')
  const lastPart = urlObj.pathname.split('/').slice(-1)[0]

  return [prefix, lastPart]
}

export const isValidHomeUrl = (url) => {
  return (
    !url.startsWith('/docs/') &&
    // We may not need this
    (url.startsWith('/channels/') ||
      url.startsWith('/#examples') ||
      url.startsWith('/#inspiration') ||
      url.startsWith('/#all') ||
      url.startsWith('/#trash'))
  )
}
