import { useCallback, useEffect, useRef, useState } from 'react'

import { config } from 'config'
import { useAppDispatch, useAppSelector, useAppStore } from 'modules/redux'
import { isDocPrivate, isOtherOrgDoc } from 'modules/sharing/utils'
import { selectDoc } from 'modules/tiptap_editor/reducer'
import { useAbility, useUserContext } from 'modules/user'

import { ManageCardMenuOptions } from './ManageCardMenu'
import { showManageCardMenu, updateManageCardMenuOptions } from './reducer'

export const useManageCardMenu = (options: ManageCardMenuOptions) => {
  const dispatch = useAppDispatch()
  const store = useAppStore()
  const activatorRef = useRef<HTMLButtonElement | null>(null)
  const { getPos, cardUrl } = options

  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const onClose = useCallback(() => {
    setIsMenuOpen(false)
  }, [])

  useEffect(() => {
    if (isMenuOpen) {
      dispatch(updateManageCardMenuOptions({ getPos, cardUrl }))
    }
  }, [getPos, dispatch, isMenuOpen, cardUrl])

  const openManageCardMenu = useCallback(() => {
    setIsMenuOpen(true)
    dispatch(
      showManageCardMenu({ ...options, onClose, ref: activatorRef.current! })
    )
  }, [dispatch, options, onClose])

  const openManageCardMenuClickHandler = useCallback(() => {
    const lastRef = store.getState().ManageCardMenu.lastRef
    // since the clickOutside handler will occur synchronously before
    // this click handler.  If the last activator ref was this button
    // don't open the menu, instead let it "toggle" by allowing the
    // clickOutside handler to close the menu
    if (lastRef && lastRef === activatorRef.current) {
      return
    }
    openManageCardMenu()
  }, [openManageCardMenu, store])

  return {
    isMenuOpen,
    openManageCardMenuClickHandler,
    activatorRef,
  }
}

export const useCardLinkPermissionSummary = (): {
  permissionLabel: string | null
  canManageDoc: boolean
} => {
  const shareToken = config.SHARE_TOKEN
  const doc = useAppSelector(selectDoc)
  const { user, currentWorkspace } = useUserContext()
  const ability = useAbility()
  const canManage = doc && ability.can('manage', doc)
  const canComment = doc && ability.can('comment', doc)
  if (!doc) {
    return {
      permissionLabel: null,
      canManageDoc: false,
    }
  }
  const isPrivate = isDocPrivate(doc, user)
  const hasCollaborators = doc.collaborators && doc.collaborators?.length > 1
  const hasInvitations = doc.invitations && doc.invitations?.length > 0
  const isPublic = doc.publicAccess !== null
  const isFromOtherOrg = isOtherOrgDoc(doc, currentWorkspace)
  const hasWorkspaceAccess = doc.orgAccess !== null && !isFromOtherOrg

  let message: string | null = null
  if (isPublic || shareToken) {
    message = null
  } else if (hasWorkspaceAccess) {
    message = 'Only those invited or in your workspace can open this link'
  } else if (hasCollaborators || hasInvitations || !canComment) {
    // checking cannotComment permission because these users will not see collaborators
    message = 'Only those invited can open this link'
  } else if (isPrivate) {
    message = 'Only you can open this link'
  }

  return { permissionLabel: message, canManageDoc: !!canManage }
}
