import { mergeAttributes, Node } from '@tiptap/core'

import { ignoreDataMutation } from 'modules/tiptap_editor/utils/ignoreMutation'

import { ReactNodeViewRenderer } from '../../../react'
import { ExtensionPriorityMap } from '../../constants'
import { attrsOrDecorationsChanged } from '../../updateFns'
import { GalleryPlugin } from './GalleryPlugin'
import { GalleryView } from './GalleryView'

export const Gallery = Node.create({
  name: 'gallery',
  group: 'cardBlock layoutBlock',
  content: 'media*',
  isolating: true,
  draggable: true,
  selectable: true,
  priority: ExtensionPriorityMap.Gallery,
  containerHandle: true,

  expandable: true,

  addNodeView() {
    return ReactNodeViewRenderer(GalleryView, {
      // Decorations handle detecting when the content changes
      update: attrsOrDecorationsChanged,
      ignoreMutation: ignoreDataMutation,
    })
  },

  addAttributes() {
    return {
      thumbHeight: {},
      fullWidthBlock: {
        default: false,
      },
    }
  },

  addProseMirrorPlugins() {
    return [GalleryPlugin(this.editor)]
  },

  parseHTML() {
    return [
      {
        tag: 'div[class=gallery]',
      },
      {
        tag: 'gallery',
      },
    ]
  },

  renderHTMLforAI({ node }) {
    return ['gallery', { thumbHeight: node.attrs.thumbHeight }, 0]
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes(HTMLAttributes, { class: 'gallery' }), 0]
  },
})
