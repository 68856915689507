import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro'
import cloneDeep from 'lodash/cloneDeep'

import { CommandInfo, setDraggingContent } from '../../../commands'
import FlywheelImage from '../thumbnails/Flywheel.svg'
import PyramidImage from '../thumbnails/Pyramid.svg'
import QuadrantImage from '../thumbnails/Quadrants.svg'
import StepsStairImage from '../thumbnails/Staircase.svg'
import VennImage from '../thumbnails/Venn.svg'
import { generateDrawingId, setDrawingExpanded } from '../utils'
import { DrawingTemplates } from './templates'

export const DrawingCommands: CommandInfo[] = [
  {
    key: 'diagram',
    name: 'Blank diagram',
    keywords: ['sketch', 'drawing', 'shapes'],
    shortcut: '/diagram',
  },
  {
    key: 'staircase',
    name: 'Staircase diagram',
    keywords: ['sequence', 'steps', 'stepper', 'stages'],
    previewImage: StepsStairImage,
  },
  {
    key: 'flywheel',
    name: 'Flywheel diagram',
    keywords: ['cycle', 'circle'],
    previewImage: FlywheelImage,
  },
  {
    key: 'quadrant',
    name: '2x2 matrix diagram',
    keywords: ['matrix', 'quadrant', 'diagram'],
    previewImage: QuadrantImage,
  },
  {
    key: 'venn',
    name: 'Venn diagram',
    previewImage: VennImage,
  },
  {
    key: 'pyramid',
    name: 'Pyramid diagram',
    keywords: ['pyramid', 'diagram', 'hierarchy', 'priorities'],
    previewImage: PyramidImage,
  },
].map((item): CommandInfo => {
  const node = DrawingTemplates[item.key] || {
    type: 'drawing',
    attrs: {},
  }

  return {
    ...item,
    nodeName: 'drawing',
    icon: duotone('shapes'),
    featureFlag: 'drawingBlock',
    execute: (editor) => {
      const drawingId = generateDrawingId()
      const newNode = cloneDeep(node)
      newNode.attrs.id = drawingId
      setDrawingExpanded(drawingId, true)
      editor.commands.insertContent(newNode)
    },
    dragStartFn: (editor) => {
      const drawingId = generateDrawingId()
      const newNode = cloneDeep(node)
      newNode.attrs.id = drawingId
      setDrawingExpanded(drawingId, true)
      setDraggingContent(editor, newNode)
    },
  }
})
