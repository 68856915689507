import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Editor } from '@tiptap/core'
import { useCallback } from 'react'

import { ToolbarButton } from './ToolbarButton'

type DeleteNodeButtonProps = {
  editor: Editor
  handleDelete?: () => void
}

export const DeleteNodeButton = ({
  editor,
  handleDelete,
}: DeleteNodeButtonProps) => {
  const deleteNode = useCallback(() => {
    editor.chain().deleteSelectionAndSelectNear().run()
  }, [editor])

  return (
    <ToolbarButton
      label="Delete"
      icon={regular('trash')}
      onClick={handleDelete || deleteNode}
      testId="delete-node-button"
      color="red.500"
    />
  )
}
