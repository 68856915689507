import { callOrReturn, Extension, getExtensionField } from '@tiptap/core'

declare module '@tiptap/core' {
  interface NodeConfig<Options, Storage> {
    dynamic?: boolean
  }
}

export const DynamicNodes = Extension.create({
  name: 'dynamicNodes',

  extendNodeSchema(extension) {
    return {
      dynamic:
        callOrReturn(getExtensionField(extension, 'dynamic', extension)) ??
        false,
    }
  },
})
