import { JSONContent } from '@tiptap/core'
import { random, sample, sampleSize } from 'lodash'

import {
  HipsumBullets,
  HipsumHeadings,
  HipsumParagraphs,
  HipsumTitles,
} from './hipsumContent'

const randomH1 = () => sample(HipsumTitles)
const randomH3 = () => sample(HipsumHeadings)
const randomParagraph = () => sample(HipsumParagraphs)
const randomParagraphs = (count = 1) => sampleSize(HipsumParagraphs, count)
const randomBullets = (count = 1) => sampleSize(HipsumBullets, count)

const headingNode = (heading = '', headingLevel = 1): JSONContent[] => {
  return [
    {
      type: 'heading',
      attrs: {
        horizontalAlign: null,
        level: headingLevel,
      },
      content: [
        {
          type: 'text',
          text: heading,
        },
      ],
    },
  ]
}

const paragraphNodes = (paragraphs = ['']): JSONContent[] => {
  return paragraphs.map((str) => {
    return {
      type: 'paragraph',
      content: [
        {
          type: 'text',
          text: str,
        },
      ],
    }
  })
}

const bulletNodes = (bullets = ['']): JSONContent[] => {
  return bullets.map((str) => {
    return {
      type: 'bullet',
      attrs: {
        fontSize: null,
        indent: 0,
      },
      content: [
        {
          type: 'text',
          text: str,
        },
      ],
    }
  })
}

const twoColumnText = (paragraphStrings = ['', '']): JSONContent[] => {
  return [
    {
      type: 'gridLayout',
      attrs: {
        colWidths: [50, 50],
      },
      content: [
        {
          type: 'gridCell',
          content: [
            {
              type: 'paragraph',
              attrs: {
                horizontalAlign: null,
                fontSize: null,
              },
              content: [
                {
                  type: 'text',
                  text: paragraphStrings[0],
                },
              ],
            },
          ],
        },
        {
          type: 'gridCell',
          content: [
            {
              type: 'paragraph',
              attrs: {
                horizontalAlign: null,
                fontSize: null,
              },
              content: [
                {
                  type: 'text',
                  text: paragraphStrings[1],
                },
              ],
            },
          ],
        },
      ],
    },
  ]
}

const imageRight = (str = ''): JSONContent[] => {
  return [
    {
      type: 'gridLayout',
      attrs: {
        colWidths: [50, 50],
      },
      content: [
        {
          type: 'gridCell',
          content: [
            {
              type: 'paragraph',
              attrs: {
                horizontalAlign: null,
                fontSize: null,
              },
              content: [
                {
                  type: 'text',
                  text: str,
                },
              ],
            },
          ],
        },
        {
          type: 'gridCell',
          content: [
            {
              type: 'mediaPlaceholder',
              attrs: {
                horizontalAlign: null,
                source: 'image.custom',
              },
            },
          ],
        },
      ],
    },
  ]
}

const imageLeft = (str = ''): JSONContent[] => {
  return [
    {
      type: 'gridLayout',
      attrs: {
        colWidths: [50, 50],
      },
      content: [
        {
          type: 'gridCell',
          content: [
            {
              type: 'mediaPlaceholder',
              attrs: {
                horizontalAlign: null,
                source: 'image.custom',
              },
            },
          ],
        },
        {
          type: 'gridCell',
          content: [
            {
              type: 'paragraph',
              attrs: {
                horizontalAlign: null,
                fontSize: null,
              },
              content: [
                {
                  type: 'text',
                  text: str,
                },
              ],
            },
          ],
        },
      ],
    },
  ]
}

// Text nodes
export const SingleHipsumParagraph = () => paragraphNodes(randomParagraphs(1))
export const SingleHipsumH1 = () => headingNode(randomH1(), 1)
export const SingleHipsumH3 = () => headingNode(randomH3(), 3)

// Short list of bullets
export const ShortHipsumList = () => {
  return bulletNodes(randomBullets(random(5, 7)))
}

// Title with pararaphs
export const BasicHipsumCard = () => {
  const heading = headingNode(randomH1())
  const paragraphs = paragraphNodes(randomParagraphs(random(1, 3)))
  return [...heading, ...paragraphs]
}

// Title with bullets and up to one pararaph
export const BulletsHipsumCard = () => {
  const heading = headingNode(randomH1())
  const paragraphs = paragraphNodes(randomParagraphs(random(0, 1)))
  const bullets = bulletNodes(randomBullets(random(5, 7)))
  return [...heading, ...paragraphs, ...bullets]
}

// Randomly chosen layout
export const RandomHipsumCard = () => {
  const num = random(1, 5)
  switch (num) {
    case 1:
      return [...headingNode(randomH1()), ...twoColumnText(randomParagraphs(2))]
    case 2:
      return [...headingNode(randomH1()), ...imageLeft(randomParagraph())]
    case 3:
      return [...headingNode(randomH1()), ...imageRight(randomParagraph())]
    case 4:
      return BulletsHipsumCard()
    default:
      return BasicHipsumCard()
  }
}
