import { Font, ThemeFont } from 'modules/api'
import { CARD_CONTENT_CLASS } from 'modules/tiptap_editor/extensions/Card'

import { DEFAULT_FONTS, DEFAULT_FONT_WEIGHTS } from '../constants'
import { FontMap, Theme } from '../types'

export const getFontWeight = (theme: Theme, type: 'body' | 'heading') => {
  const weightKey = type === 'body' ? 'bodyFontWeight' : 'headingFontWeight'
  return theme[weightKey] ?? DEFAULT_FONT_WEIGHTS[type]
}

export const isAdonisOrMackinac = (fontId?: string) =>
  fontId && ['adonis-web', 'p22-mackinac-pro'].includes(fontId)

export const getFontName = (
  fonts?: { id: string; name: string }[],
  fontId?: string,
  isNameForCss: boolean = true
) => {
  // Special case for adonis-web and p22-mackinac-pro since that is what they are called in the CSS file
  if (isNameForCss && isAdonisOrMackinac(fontId)) {
    return fontId
  }

  return fonts?.find((font) => font && font.id === fontId)?.name
}

export const getFontOverrideCSS = (
  orderedFonts: string,
  theme: Theme
): string => {
  const headingFontName =
    getFontName(theme.fonts, theme.headingFont) || DEFAULT_FONTS.headingFont
  const bodyFontName =
    getFontName(theme.fonts, theme.bodyFont) || DEFAULT_FONTS.bodyFont
  return `.${CARD_CONTENT_CLASS} {
    --heading-font: ${`"${headingFontName}", `}${orderedFonts}, sans-serif;
    --body-font: ${`"${bodyFontName}", `}${orderedFonts}, sans-serif;
  }`
}

export const getFontWeightOptions = (font?: Font) => {
  if (!font) {
    return []
  }
  const hasNoFontFiles =
    font.workspaceId === null && font.fontFiles?.length === 0
  if (hasNoFontFiles) {
    return [400, 600, 700]
  } else {
    const fontWeights = (font.fontFiles || []).map((f) => f.weight)
    // We'll include all unique font weights, even for italics
    return Array.from(new Set(fontWeights)).sort((a, b) => a - b)
  }
}

export const getUpdatedThemeFonts = ({
  fontsMap,
  bodyFontId,
  headingFontId,
}: {
  fontsMap: FontMap
  bodyFontId?: string
  headingFontId?: string
}): ThemeFont[] => {
  const defaultHeadingFont = fontsMap[DEFAULT_FONTS.headingFont]
  const defaultBodyFont = fontsMap[DEFAULT_FONTS.bodyFont]
  const nextBodyFont = fontsMap[bodyFontId!] || defaultBodyFont
  const nextHeadingFont = fontsMap[headingFontId!] || defaultHeadingFont

  // make sure theme fonts are in the expected ThemeFont shape
  const nextBodyThemeFont = {
    id: nextBodyFont.id,
    name: nextBodyFont.name,
    url: nextBodyFont.cssUrl,
  }
  const nextHeadingThemeFont = {
    id: nextHeadingFont.id,
    name: nextHeadingFont.name,
    url: nextHeadingFont.cssUrl,
  }
  return [nextBodyThemeFont, nextHeadingThemeFont]
}
