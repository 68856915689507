import { brightenColor, isColorDark, saturateColor } from 'utils/color'
import { isMobileDevice } from 'utils/deviceDetection'

import { ThemeBase } from '../types'
import { DefaultThemeBase } from './default'

export const FinesseThemeBase: ThemeBase = {
  ...DefaultThemeBase,
  key: 'finesse',
  name: 'Finesse',
  description: 'Minimalist, flat design with playful details',
  getCSSVars(cardColor, accentColor) {
    const isDark = isColorDark(cardColor)
    return {
      ...DefaultThemeBase.getCSSVars(cardColor, accentColor),
      '--box-background-color': saturateColor(
        brightenColor(cardColor, isDark ? 5 : -5),
        isDark ? 5 : 20
      ),
      '--line-thickness': '2px',
      '--box-border-radius': '0.25em',
    }
  },
  cardSx: {
    borderRadius: 'var(--box-border-radius)',
    backgroundColor: 'var(--card-color)',
  },
  boxSx: {
    border: '0px',
    borderRadius: 'var(--box-border-radius)',
  },
  clickableSx: {
    border: '1px solid var(--accent-color-border)',
    backgroundColor: 'var(--box-background-color)',
  },
  smartLayoutBoxSx: {
    borderRadius: 'var(--box-border-radius)',
    backgroundColor: 'var(--box-background-color)',
  },
  smartLayoutLineSx: {
    opacity: 0.8,
    backgroundColor: 'var(--accent-color)',
  },
  smartLayoutContentSx: {},
  tableSx: {
    'tbody tr': {
      _even: {
        backgroundColor: 'var(--box-background-color)',
      },
      td: {
        borderX: '0px',
      },
      position: 'relative',
      _after: {
        content: '""',
        position: 'absolute',
        right: isMobileDevice() ? 0 : '0.25em',
        top: isMobileDevice() ? 0 : '0.25em',
        width: '100%',
        height: '100%',
        border: '2px solid var(--accent-color)',
        borderTopWidth: '0px',
        zIndex: 1,
        borderTopRadius: 'inherit',
        borderBottomRadius: 'inherit',
        opacity: 0.8,
        pointerEvents: 'none',
      },
      _first: {
        _after: {
          borderTopWidth: '2px',
        },
        borderTopRadius: '4px',
      },
      _last: {
        borderBottomRadius: '4px',
      },
    },
  },
}
