import { EditorState, PluginKey, Transaction } from 'prosemirror-state'
import * as Y from 'yjs'

import {
  absoluteToRelativePos,
  relativeToAbsolutePos,
} from 'modules/tiptap_editor/utils/relativePosition'

export const BlockHoverPluginKey = new PluginKey<BlockHoverState>(
  'BlockHoverKey'
)

export type SetBlockHoverPosEvent = {
  setHoverPos: number | null
  setAnnotatablePos: number | null
}

type BlockHoverEvent = SetBlockHoverPosEvent

export class BlockHoverState {
  public hoverPos: Y.RelativePosition | null = null

  public annotatablePos: Y.RelativePosition | null = null

  constructor() {}

  getHoverAbsPos(state: EditorState): number | null {
    if (!this.hoverPos) {
      return null
    }
    return relativeToAbsolutePos(state, this.hoverPos)
  }

  getAnnotableAbsPos(state: EditorState): number | null {
    if (!this.annotatablePos) {
      return null
    }
    return relativeToAbsolutePos(state, this.annotatablePos)
  }

  apply(tr: Transaction, state: EditorState): this {
    const action = tr.getMeta(BlockHoverPluginKey) as BlockHoverEvent | null

    if (action && 'setHoverPos' in action) {
      this.handleSetPos(state, action)
    }

    return this
  }

  private handleSetPos(state: EditorState, event: SetBlockHoverPosEvent): this {
    this.hoverPos =
      event.setHoverPos === null
        ? null
        : absoluteToRelativePos(state, event.setHoverPos)

    this.annotatablePos =
      event.setAnnotatablePos === null
        ? null
        : absoluteToRelativePos(state, event.setAnnotatablePos)

    return this
  }
}
