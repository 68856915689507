import { Center, CenterProps, Flex, FlexProps, Text } from '@chakra-ui/react'
import { cx } from '@chakra-ui/utils'
import { useCallback } from 'react'

import { HEADING_CLASS } from 'modules/theming/styles/heading'
import { getThemeBase } from 'modules/theming/themeBases'
import { BLOCK_MARGIN } from 'modules/tiptap_editor/styles/constants'

import { FontSizeOptions } from '../../Font/constants'
import { getFontSizeStyles } from '../../Font/fontStyles'
import { SmartLayoutCellProps } from '../types'
import { selectParentLayout } from '../utils'

const BULLET_FONT_SIZE = 'h3'
const BULLET_PADDING = 0.375
const markerFontStyles = getFontSizeStyles(BULLET_FONT_SIZE)

type BulletMarkerProps = SmartLayoutCellProps & {
  alignText: boolean
  positionProps?: FlexProps
  markerProps?: CenterProps
}

export const BulletMarker = ({
  index,
  theme,
  firstChildSize,
  layoutOptions,
  children,
  positionProps,
  markerProps,
  alignText,
  getPos,
  editor,
}: BulletMarkerProps) => {
  const sizerFontStyles = getFontSizeStyles(firstChildSize)
  const isNumbered = layoutOptions.numbered ?? true
  const bulletFontSize = FontSizeOptions[BULLET_FONT_SIZE].size
  const bulletSize = `${
    (isNumbered ? bulletFontSize : 1) + BULLET_PADDING * 2
  }em`
  const base = getThemeBase(theme)
  const selectLayout = useCallback(() => {
    if (!editor.isEditable) return
    const $pos = editor.state.doc.resolve(getPos())
    selectParentLayout(editor, $pos)
  }, [getPos, editor])

  return (
    <Flex
      my={alignText ? BLOCK_MARGIN : 0}
      {...positionProps}
      contentEditable={false}
      userSelect="none"
      onMouseDown={selectLayout}
      cursor="default"
    >
      {/* Ensure that the bullet is centered on the first line of text, by giving it height equal to the font size of the first child, and putting it inside a margin that matches our normal block margin  */}
      <Center sx={sizerFontStyles} h={`${sizerFontStyles.lineHeight}em`}>
        {/* Within that block, reset the font size inside the bullet to be relative to the base font size */}
        <Center
          fontSize="var(--font-size)"
          h={bulletSize}
          w={bulletSize}
          transitionProperty="width, height"
          transitionDuration="normal"
          sx={base.smartLayoutBoxSx || base.boxSx}
          {...markerProps}
        >
          <Text sx={markerFontStyles} className={cx(HEADING_CLASS)}>
            {isNumbered ? index + 1 : ''}
          </Text>
        </Center>
      </Center>
      {/* Add-ons like a line */}
      {children}
    </Flex>
  )
}
