import { CheckIcon } from '@chakra-ui/icons'
import { Box, Button, ButtonProps, Flex, Stack, Text } from '@chakra-ui/react'

import {
  BackgroundOptions,
  getBackgroundProps,
} from 'modules/tiptap_editor/styles/backgroundStyles'

import { BackgroundMask } from '../BackgroundMask'

export type BackgroundMaskPreviewProps = {
  label: string
  onClick: () => void
  isChecked: boolean
  cardBackground: BackgroundOptions
  cardColor: string
  isDark?: boolean
} & ButtonProps

export const BackgroundMaskPreview = ({
  label,
  onClick,
  isChecked,
  cardBackground,
  isDark = false,
  ...buttonProps
}: BackgroundMaskPreviewProps) => {
  const backgroundSx = getBackgroundProps(cardBackground, isDark, true)

  return (
    <Button
      borderRadius="md"
      display="block"
      height="auto"
      variant="ghost"
      color="black"
      onClick={onClick}
      p={1}
      {...buttonProps}
    >
      <Flex
        borderWidth="1px"
        borderColor="gray.200"
        sx={backgroundSx}
        p={2}
        backgroundAttachment="unset"
        _focus={{
          boxShadow: 'outline',
        }}
        overflow="hidden"
        position="relative"
        direction="column"
        align="center"
        justify="center"
        height="80px"
      >
        <BackgroundMask background={cardBackground} />
        <Box
          position="relative"
          p={2}
          zIndex={0}
          transitionProperty="inset"
          transitionDuration="normal"
          width="100%"
          overflow="hidden"
          color={'var(--body-color)'}
        >
          <ContentSkeleton />
        </Box>
      </Flex>
      <Flex align="center" mt={1}>
        {isChecked && <CheckIcon w={3} mr={1} />}
        <Text fontSize="sm" fontWeight="medium">
          {label}
        </Text>
      </Flex>
    </Button>
  )
}

export const ContentSkeleton = () => {
  const baseElementColor = 'currentColor'
  const baseElementBorderRadius = 'sm'
  const baseElementLgHeight = '6px'
  const baseElementSmHeight = '3px'
  const baseCardStackHeight = '28px'
  return (
    <Stack spacing={1} h={baseCardStackHeight} position="relative" zIndex={1}>
      <Box
        h={baseElementLgHeight}
        w="50%"
        bg={baseElementColor}
        borderRadius={baseElementBorderRadius}
      ></Box>

      <Box
        h={baseElementSmHeight}
        w="100%"
        bg={baseElementColor}
        borderRadius={baseElementBorderRadius}
      ></Box>
      <Box
        h={baseElementSmHeight}
        w="100%"
        bg={baseElementColor}
        borderRadius={baseElementBorderRadius}
      ></Box>
      <Box
        h={baseElementSmHeight}
        w="30%"
        bg={baseElementColor}
        borderRadius={baseElementBorderRadius}
      ></Box>
    </Stack>
  )
}
