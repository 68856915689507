import isHotkey from 'is-hotkey'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { keyboardHandler } from 'modules/keyboard'
import { useUserContext } from 'modules/user'
import { isMobileDevice } from 'utils/deviceDetection'

import { setIsGlobalSearchOpen } from './reducer'

const isModK = isHotkey('mod+K')

export const useGlobalSearchKeyboardShortcuts = ({
  isEnabledFn = () => true,
  isOpen = false,
}: {
  isEnabledFn?: () => boolean
  isOpen: boolean
}) => {
  const { user } = useUserContext()
  const dispatch = useDispatch()

  useEffect(() => {
    if (isMobileDevice() || !user) return
    const handleKeyDown = (e) => {
      if (!isModK(e) || !isEnabledFn()) {
        return false
      }
      e.preventDefault()
      dispatch(setIsGlobalSearchOpen({ isOpen: !isOpen }))
      return true
    }
    return keyboardHandler.on('keydown', 'GLOBAL_SEARCH', handleKeyDown)
  }, [dispatch, isEnabledFn, user, isOpen])
}
