import { ButtonGroup } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useCallback } from 'react'

import { getSmartLayoutVariant } from 'modules/tiptap_editor/extensions/SmartLayout/variants'

import {
  getSmartLayoutDirection,
  isSmartLayoutNode,
} from '../../../extensions/SmartLayout/utils'
import { DeleteNodeButton } from '../buttons/DeleteNodeButton'
import { DuplicateNodeButton } from '../buttons/DuplicateNodeButton'
import { SelectParentButton } from '../buttons/SelectParentButton'
import { ToolbarButton } from '../buttons/ToolbarButton'
import { FormattingMenuDivider } from './FormattingMenuDivider'
import { NodeFormattingMenuProps } from './types'

export const SmartLayoutCellFormattingMenu = ({
  editor,
  selection,
}: NodeFormattingMenuProps) => {
  const parentLayout = selection.$from.parent

  const addColumnBefore = useCallback(() => {
    editor.chain().insertSmartLayoutCell(selection.from).run()
  }, [editor, selection.from])
  const addColumnAfter = useCallback(() => {
    editor
      .chain()
      .insertSmartLayoutCell(selection.from + selection.node.nodeSize)
      .run()
  }, [editor, selection.from, selection.node])

  if (!parentLayout || !isSmartLayoutNode(parentLayout)) {
    console.error('SmartLayoutCellFormattingMenu: parent is not a smartLayout')
    return null
  }
  const addDirection = getSmartLayoutDirection(parentLayout.attrs)
  const parentVariant = getSmartLayoutVariant(parentLayout.attrs.variantKey)

  return (
    <ButtonGroup spacing={1} size="sm" alignItems="center">
      <SelectParentButton
        editor={editor}
        label="Edit layout..."
        icon={parentVariant.icon}
      />
      <FormattingMenuDivider />
      <ToolbarButton
        label="Add item before"
        iconRotation={addDirection === 'bottom' ? undefined : 270}
        icon={regular('diagram-cells')}
        onClick={addColumnBefore}
        testId="add-col-before-button"
      />
      <ToolbarButton
        label="Add item after"
        icon={regular('diagram-cells')}
        iconRotation={addDirection === 'bottom' ? 180 : 90}
        onClick={addColumnAfter}
        testId="add-col-after-button"
      />
      <DuplicateNodeButton editor={editor} selection={selection} />
      <FormattingMenuDivider />
      <DeleteNodeButton editor={editor} />
    </ButtonGroup>
  )
}
