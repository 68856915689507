import { useClipboard, useToast } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import React, { MouseEvent, useCallback } from 'react'

import { useAppSelector } from 'modules/redux'
import { useCardLinkPermissionSummary } from 'modules/tiptap_editor/extensions/Card/ManageCardMenu/hooks'
import { CardAttributes } from 'modules/tiptap_editor/extensions/Card/types'
import { selectDoc, selectMode } from 'modules/tiptap_editor/reducer'
import { EditorModeEnum } from 'modules/tiptap_editor/types'
import { getCardUrl } from 'utils/url'

import { ToolbarButton } from '../../buttons/ToolbarButton'

type CopyLinkButtonProps = {
  attrs: CardAttributes
}

export const CopyLinkButton: React.FC<CopyLinkButtonProps> = ({ attrs }) => {
  const editorMode = useAppSelector(selectMode)
  const doc = useAppSelector(selectDoc)
  const cardId = attrs.id
  const cardUrl = getCardUrl({
    cardId,
    docId: doc?.id,
    isPresentMode: editorMode === EditorModeEnum.SLIDE_VIEW,
  })
  const toast = useToast()
  const { onCopy: onCopyLink } = useClipboard(cardUrl)
  const onCopyLinkClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      if (
        e.target &&
        (e.target as HTMLButtonElement)?.closest('[data-testid="open-share"]')
      ) {
        e.preventDefault()
        return
      }
      onCopyLink()
      toast({
        title: 'URL copied.',
        status: 'success',
        duration: 3000,
        position: 'top',
      })
    },
    [onCopyLink, toast]
  )

  const { permissionLabel } = useCardLinkPermissionSummary()
  const tooltip = permissionLabel
    ? `Copy card link. ${permissionLabel}`
    : 'Copy card link'

  return (
    <ToolbarButton
      onClick={onCopyLinkClick}
      icon={regular('link')}
      label={tooltip}
    />
  )
}
