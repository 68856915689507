import { ListVariant } from '../lists/ListTypes'

// Starting from a list element, find all the list elements of the same type
// that are immediately after it and wrap them in a UL/OL element.
// If there are children listed further down, recursively wrap them too.
export const wrapListItems = (elt: Element): Element => {
  // Already wrapped, just return the existing wrapper
  if (elt?.parentElement?.matches('ol, ul')) {
    return elt.parentElement
  }

  // Loop through siblings starting from here until the list ends
  const siblings: Element[] = [elt]
  let sibling: Element | null = elt
  while ((sibling = sibling.nextElementSibling)) {
    if (sibling.tagName !== 'LI') {
      break
    }

    const indentDifference =
      parseInt(sibling.getAttribute('indent') || '0') -
      parseInt(elt.getAttribute('indent') || '0')

    if (indentDifference < 0) {
      break
    } else if (indentDifference == 0) {
      if (sibling.getAttribute('variant') != elt.getAttribute('variant')) {
        break
      }
      siblings.push(sibling)
    } else {
      sibling = wrapListItems(sibling)
      siblings.push(sibling)
    }
  }

  // Wrap it in the proper list type, then replace this with the wrapper
  const wrapper =
    elt.getAttribute('variant') == ListVariant.Numbered
      ? document.createElement('ol')
      : document.createElement('ul')
  elt.replaceWith(wrapper)

  siblings.forEach((sib) => {
    // Remove our attributes since the info is already captured by the HTML structure
    sib.removeAttribute('variant')
    sib.removeAttribute('indent')
  })

  wrapper.append(...siblings)

  return wrapper
}

// For each list item, recognize its variant, indent level and checked status
// and flatten it from a tree into a list of list items
export const flattenListTree = (elt: HTMLElement) => {
  let parentElt: HTMLElement | null | undefined = elt
  let level = 0
  let variant: ListVariant | undefined
  let checked: string = 'false'

  // Notion checkboxes
  if (elt.innerHTML.startsWith('[ ]')) {
    variant = ListVariant.Todo
    checked = 'false'
    if (elt.innerHTML.startsWith('[ ]  ')) {
      elt.innerHTML = elt.innerHTML.slice(4)
    } else {
      // handle checkboxes with no text
      elt.innerHTML = elt.innerHTML.slice(3)
    }
  } else if (elt.innerHTML.startsWith('[x]')) {
    variant = ListVariant.Todo
    checked = 'true'
    if (elt.innerHTML.startsWith('[x]  ')) {
      elt.innerHTML = elt.innerHTML.slice(4)
    } else {
      // handle checkboxes with no text
      elt.innerHTML = elt.innerHTML.slice(3)
    }
  }

  // Google checkboxes
  if (elt.getAttribute('role') === 'checkbox') {
    variant = ListVariant.Todo
    checked = elt.getAttribute('aria-checked') || 'false'
  }

  // Check for our own checkboxes
  if (elt.getAttribute('variant') === 'todo' || elt.getAttribute('checked')) {
    variant = ListVariant.Todo
    checked = elt.getAttribute('checked') || 'false'
  }

  // Loop over their parents to get indent level
  while ((parentElt = elt.parentElement?.closest('li, ul, ol'))) {
    if (parentElt.matches('ul, ol')) {
      level++
      // Google Docs import will only have bullet indent level in a class name
      const googleIndentMatch = parentElt.className.match(/lst-kix_\w+-(\d+)/)
      if (googleIndentMatch) {
        level = parseInt(googleIndentMatch[1]) + 1
      }

      if (!variant && parentElt.tagName == 'OL') {
        variant = ListVariant.Numbered
      } else if (!variant && parentElt.tagName == 'UL') {
        variant = ListVariant.Bullet
      }
    }
    // Flatten out the list by moving our LI outside its parent
    // This can happen with UL > LI, OL > LI, or LI > LI
    parentElt.after(elt)
  }
  elt.setAttribute('indent', Math.max(level - 1, 0).toString())
  elt.setAttribute('variant', variant || ListVariant.Bullet)
  if (variant == ListVariant.Todo) {
    elt.setAttribute('checked', checked)
  }
}
