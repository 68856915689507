import { Box, LinkBox, LinkOverlay, Portal, Text } from '@chakra-ui/react'
import { AnimatePresence, motion } from 'framer-motion'
import React, { useCallback } from 'react'

import { useAppSelector } from 'modules/redux'
import { NodeViewContent, NodeViewWrapper } from 'modules/tiptap_editor/react'
import { selectEditable } from 'modules/tiptap_editor/reducer'
import { isChrome } from 'utils/deviceDetection'
import { LightPopoverMotionProps, useLightPopover } from 'utils/hooks'

import { MarkViewProps } from '../../react/addMarkViewPlugin'
import { EmbedPreview } from '../media/Embed'
import { LinkAttrs } from '../media/types'

const MotionBox = motion(Box)

export const LinkView = ({ node }: MarkViewProps) => {
  const { href, meta } = node.attrs as LinkAttrs
  const editable = useAppSelector(selectEditable)
  const handleLinkClick = useCallback(
    (ev: React.MouseEvent) => {
      if (!editable) return // Use default handler
      ev.preventDefault() // Prevent the default link click behavior
    },
    [editable]
  )

  const {
    popperRef,
    referenceRef,
    isHovering,
    onMouseOver,
    onMouseOut,
    getPopperProps,
  } = useLightPopover()

  return (
    <NodeViewWrapper as="span">
      <Text
        as="a"
        className="link"
        href={href || ''}
        onClick={handleLinkClick}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        ref={referenceRef}
      >
        {/* On Chrome, this fixes an issue where typing on the edge would not register as part of the node. On Firefox and Safari, it's not needed and causes a different issue where you can't arrow past it. */}
        {isChrome() && <span contentEditable={false}>&#8203;</span>}
        <NodeViewContent as="span" />
        {isChrome() && <span contentEditable={false}>&#8203;</span>}
      </Text>

      {isHovering && href && meta ? (
        <Portal>
          <AnimatePresence>
            <MotionBox
              position="relative"
              zIndex="popover"
              ref={popperRef}
              onMouseOver={onMouseOver}
              onMouseOut={onMouseOut}
              w="min(500px, 80vw)"
              className="link-preview-hover"
              {...getPopperProps()}
              {...LightPopoverMotionProps}
            >
              <LinkBox
                backgroundColor="white"
                border="1px solid"
                borderColor="gray.200"
                shadow="lg"
                borderRadius="lg"
                _hover={{ backgroundColor: 'trueblue.50' }}
              >
                <EmbedPreview node={node} />
                <LinkOverlay href={href} target="_blank" />
              </LinkBox>
            </MotionBox>
          </AnimatePresence>
        </Portal>
      ) : null}
    </NodeViewWrapper>
  )
}
