import { Flex, FlexProps, useDisclosure } from '@chakra-ui/react'
import { NodeViewProps } from '@tiptap/react'
import { useCallback, useEffect, useState } from 'react'

import { useCardSuggestionEditorContext } from 'modules/ai/components/CardSuggestionEditor/context'
import { AIImagePicker } from 'modules/ai/components/CardSuggestionEditor/ImagePicker'
import { useAppSelector } from 'modules/redux'
import { EditBackgroundDrawer } from 'modules/tiptap_editor/extensions/Card/Card2/EditBackgroundDrawer/EditBackgroundDrawer'
import { NodeViewContent, NodeViewWrapper } from 'modules/tiptap_editor/react'
import { selectEditable } from 'modules/tiptap_editor/reducer'
import {
  BackgroundType,
  getBackgroundProps,
} from 'modules/tiptap_editor/styles/backgroundStyles'

import { BackgroundMask } from '../../Card2/BackgroundMask'
import { usePresentVariant } from '../../hooks/usePresentVariant'
import { getBgBorderRadius } from '../CardBodyLayoutItemView'
import { CardLayoutItemAttrs } from '../CardLayoutItem'
import { findCardLayoutDecorationSpec } from '../CardLayoutPlugin'
import {
  findLayoutPreset,
  replaceCardLayoutWithCardAccentLayoutItem,
} from '../cardLayoutUtils'
import { CardLayoutAccentImageSelector } from './CardLayoutAccentImageSelector'

const useConvertToCardAccentNodeType = ({
  editor,
  node,
  getPos,
}: NodeViewProps) => {
  useEffect(() => {
    if (node.type.name === 'cardLayoutItem' && node.attrs.itemId === 'accent') {
      setTimeout(() => {
        editor.commands.command(({ tr }) => {
          replaceCardLayoutWithCardAccentLayoutItem(
            tr,
            getPos(),
            node,
            editor.schema
          )
          return true
        })
      })
    }
  }, [editor, getPos, node])
}

export const CardAccentLayoutItemView = (nodeViewProps: NodeViewProps) => {
  const { node, decorations, editor, getPos } = nodeViewProps
  const attrs = node.attrs as CardLayoutItemAttrs
  useConvertToCardAccentNodeType(nodeViewProps)

  const { isOpen, onOpen, onClose } = useDisclosure()
  const [styleDrawerTabIndex, setStyleDrawerTabIndex] = useState<number>(0)
  const { enabled: cardSuggestionEditorEnabled } =
    useCardSuggestionEditorContext()

  const { background } = attrs

  const selectCardAccent = useCallback(() => {
    editor.commands.selectNodeAtPos(getPos())
  }, [editor, getPos])

  const onEditImage = useCallback(() => {
    selectCardAccent()
    onOpen()
  }, [onOpen, selectCardAccent])

  const cardLayoutDecoSpec = findCardLayoutDecorationSpec(decorations)
  const cardId = cardLayoutDecoSpec?.cardId
  const isBehindLayout = cardLayoutDecoSpec?.layout === 'behind'
  const bgProps = getBackgroundProps(background, false)
  const layoutPreset = findLayoutPreset(cardLayoutDecoSpec!.layout)
  const shouldShow = !!layoutPreset.items[attrs.itemId]

  const borderProps = getBgBorderRadius(
    cardLayoutDecoSpec!.layout,
    attrs.itemId,
    cardLayoutDecoSpec!.cardSize === 'full'
  )
  const alignProps: Partial<FlexProps> = {
    flexDirection: 'column',
    justifyContent: 'center',
  }
  const [presentVariant] = usePresentVariant(cardLayoutDecoSpec!.cardId)
  // TODO maybe find a better way to get this information here
  const isPresentingCurrent = presentVariant === 'presentCurrent'
  const noImage =
    background.type === 'none' ||
    (background.type === BackgroundType.IMAGE && !background.image)
  const isEditable = useAppSelector(selectEditable)

  const updateCardLayoutItemAttributes = useCallback(
    (a: Record<string, any>) => {
      editor.commands.updateAttributesAtPos(getPos(), a)
    },
    [editor, getPos]
  )

  if (!shouldShow) {
    return null
  }

  return (
    <NodeViewWrapper as="div">
      {cardSuggestionEditorEnabled && cardId && (
        <AIImagePicker cardId={cardId} getPos={getPos} editor={editor} />
      )}
      <Flex
        data-content-reference
        className="card-layout-cell-bg"
        w="100%"
        h="100%"
        position="relative"
        data-selection-ring
        data-selection-background
        data-card-layout={cardLayoutDecoSpec?.layout}
        {...bgProps}
        {...(!isPresentingCurrent && borderProps)}
        {...alignProps}
        onClick={selectCardAccent}
      >
        {isBehindLayout && <BackgroundMask background={background} />}
        <NodeViewContent
          as="div"
          style={{
            width: '100%',
          }}
        />

        {isEditable && (
          <EditBackgroundDrawer
            editor={editor}
            isOpen={isOpen}
            onClose={onClose}
            tabIndex={styleDrawerTabIndex}
            setTabIndex={setStyleDrawerTabIndex}
            updateAttributes={updateCardLayoutItemAttributes}
            background={attrs?.background}
            enableMask={isBehindLayout}
            isAccentImageDrawer
          />
        )}

        {isEditable && (
          <CardLayoutAccentImageSelector
            hasImage={!noImage}
            onEditImage={isEditable ? onEditImage : undefined}
            {...borderProps}
          />
        )}
      </Flex>
    </NodeViewWrapper>
  )
}
