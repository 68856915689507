import { Node } from 'prosemirror-model'

import { FontSizeOptions } from './constants'
import { FontSize } from './FontSize'

export const getFontSizeOption = (size: FontSize | undefined) => {
  return size == null || size == undefined
    ? FontSizeOptions['default'] // Treat null as "default"
    : FontSizeOptions[size] || FontSizeOptions['default'] // If the size isn't found (deprecated), fall back to default
}

const fontSizeFromHeading = (level: number): FontSize | undefined => {
  return Object.entries(FontSizeOptions).find(
    ([_, option]) => option.type === 'heading' && option.level == level
  )?.[0]
}

export const fontSizeFromNode = (node: Node): FontSize | undefined => {
  if (node.type.name === 'heading') {
    return fontSizeFromHeading(node.attrs.level)
  } else if (node.type.name === 'title') {
    return 'title'
  } else {
    return node.attrs.fontSize
  }
}

export const isHeadingStyle = (fontSize: FontSize) => {
  const { type } = getFontSizeOption(fontSize)
  return type === 'heading' || type === 'title'
}
