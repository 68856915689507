import { Box } from '@chakra-ui/react'
import SVG from 'react-inlinesvg'

import { useAppSelector } from 'modules/redux'
import { getThemeBase } from 'modules/theming/themeBases'
import { selectTheme } from 'modules/tiptap_editor/reducer'

import { svgStyles } from './styles'

export const CardOrnaments = () => {
  const theme = useAppSelector(selectTheme)
  const themeBase = getThemeBase(theme)
  const ornaments = themeBase.ornaments
  if (!ornaments) return null

  // Todo: decide which ornament to use for each card
  const ornament = ornaments[0]

  // Todo: position the ornament so it doesn't clash with content
  const { image } = ornament
  return (
    <Box
      position="absolute"
      right="0"
      top="0"
      width={image.width}
      height={image.height}
      maxH="100%"
      overflow="hidden"
      sx={svgStyles}
      key={ornament.key}
    >
      <SVG src={image.src} />
    </Box>
  )
}
