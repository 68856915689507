import { isTextSelection } from '@tiptap/core'
import { useCallback } from 'react'

import { isMobileOrTabletDevice } from 'utils/deviceDetection'
import { useEffectWhen, useIsHovering } from 'utils/hooks'

import { setFootnoteExpandedDebounced } from './FootnoteState'

export const useToggleFootnoteOnHover = (editor, noteId) => {
  const { isHovering, ...onHoverHandlers } = useIsHovering({
    enterDelay: 20,
    leaveDelay: 20,
  })

  const toggleExpanded = useCallback(
    (expand: boolean | null) => {
      const selection = editor.state.selection
      const isFormatMenuOpen = isTextSelection(selection) && !selection.empty
      const isFocusedInsideFootnote =
        document.activeElement?.closest('.footnote-popover') ||
        Boolean(document.activeElement?.closest('[data-in-editor-focus]')) // Prevent closing if you're in a drawer or menu

      // Don't collapse if you're actively editing and mouse out, need to use X
      if (isFocusedInsideFootnote) return

      // Don't expand if you have a formatting menu open
      if (expand && !isFormatMenuOpen) {
        setFootnoteExpandedDebounced(noteId, true)
      } else if (expand === false) {
        setFootnoteExpandedDebounced(noteId, false)
      }
      // If null, do nothing - the node was just created and hasn't been hovered yet
    },
    [noteId, editor]
  )

  const onTouchEnd = useCallback(() => {
    toggleExpanded(true)
  }, [toggleExpanded])

  useEffectWhen(
    () => {
      toggleExpanded(isHovering)
    },
    [toggleExpanded, isHovering],
    [isHovering]
  )

  return isMobileOrTabletDevice() ? { onTouchEnd } : { ...onHoverHandlers }
}
