import { Editor } from '@tiptap/core'
import { MarkType, NodeType } from 'prosemirror-model'
import { NodeSelection, Selection, TextSelection } from 'prosemirror-state'
import { useCallback } from 'react'

import { ImageType } from 'modules/media'
import { getStore, useAppStore } from 'modules/redux'
import { UpdateNodeAttrsAnnotationEvent } from 'modules/tiptap_editor/extensions/Annotatable/AnnotationExtension/types'
import { isMediaNodeType } from 'modules/tiptap_editor/extensions/media/utils'
import { isSmartLayoutCellNode } from 'modules/tiptap_editor/extensions/SmartLayout/utils'

import { MediaSourcesMap } from '../../../extensions/media/MediaSources'
import { setIsEditingMedia } from '../../../reducer'

type SelectedMedia = {
  attrs: Record<string, any>
  type: NodeType | MarkType
  editType?: ImageType // Only used for images, to determine how CustomImage replaces content, and how Transloadit resizes
}

export const getSelectedMedia = (
  editor: Editor,
  selection: Selection
): SelectedMedia | null => {
  if (
    selection instanceof NodeSelection &&
    isSmartLayoutCellNode(selection.node)
  ) {
    return {
      attrs: selection.node.attrs.image || {}, // If there's no image set, this will be null
      type: selection.node.type,
      editType: 'accessory',
    }
  } else if (selection instanceof NodeSelection) {
    return { editType: 'node', ...selection.node }
  } else if (
    selection instanceof TextSelection &&
    selection.to - selection.from === 1 &&
    selection.$from.nodeAfter
  ) {
    // Sometimes ProseMirror breaks a NodeSelection and it turns into a TextSelection over the same range. We should still detect those as selected media nodes
    return { editType: 'node', ...selection.$from.nodeAfter }
  } else if (selection instanceof TextSelection && editor.isActive('link')) {
    return {
      attrs: editor.getAttributes('link'),
      type: editor.schema.marks.link,
    }
  }
  return null
}

// Updates the attributes of the node or mark
// and changes the node type (eg Embed vs. Video vs. Image) if specified or required by the attrs
export const updateSelectedMedia = (
  editor: Editor,
  selection: Selection,
  sourceKey: string,
  attrs: Record<string, any>,
  nodeName?: string,
  resetAttrs?: boolean
) => {
  const { from: pos } = selection
  const selectedMedia = getSelectedMedia(editor, selection)

  const baseAttrs = resetAttrs ? {} : selectedMedia ? selectedMedia.attrs : {}
  const newAttrs: Record<string, any> = {
    ...baseAttrs, // Start from the old attributes
    source: sourceKey, // Fill in the source if it's not provided
    ...attrs, // Provided attrs take precedence
  }

  if (selectedMedia?.type.name === 'link') {
    // Links use href, but iframely and media providers assume everything is sourceUrl,
    // so this maps it only for links.
    newAttrs.href = newAttrs.href || newAttrs.sourceUrl
    const { from, to } = selection
    editor
      .chain()
      .extendMarkRange('link')
      .updateAttributes('link', newAttrs)
      .setTextSelection({ from, to })
      .run()
    return
  } else if (selectedMedia?.type.name === 'smartLayoutCell') {
    editor.chain().updateAttributesAtPos(pos, { image: newAttrs }).run()
    return
  }

  // Update the node type to match these attrs, if a node name hasn't been specified
  const newSource = MediaSourcesMap[newAttrs.source]
  const newNodeName = nodeName || newSource.nodeName
  if (!newNodeName) return

  editor.commands.command(({ tr, state }) => {
    const newNode = state.schema.nodes[newNodeName]
    if (!newNode) return false
    // since we are replacing all markup here move any annotation
    // on the media to it's new RelativePosition
    const payload: UpdateNodeAttrsAnnotationEvent = {
      type: 'update-node-attrs',
      pos: pos,
    }
    tr.setNodeMarkup(pos, newNode, newAttrs)
      .setSelection(NodeSelection.create(tr.doc, pos))
      .setMeta('annotationEvent', payload)

    return true
  })
}

export const canEditInMediaDrawer = (type: NodeType | MarkType) => {
  if (type instanceof NodeType) {
    return isMediaNodeType(type) || type.name === 'smartLayoutCell'
  } else if (type instanceof MarkType) {
    return type.name === 'link'
  } else {
    return false
  }
}

export const openMediaDrawer = () => {
  const store = getStore()
  if (!store) {
    console.error(
      `[MediaDrawer].openMediaDrawer: can't open because getStore() returned ${store}`
    )
    return
  }
  store.dispatch(setIsEditingMedia({ isEditingMedia: true }))
}

export const useToggleMediaDrawer = () => {
  const store = useAppStore()
  return useCallback(
    (isEditingMedia: boolean) => {
      store.dispatch(setIsEditingMedia({ isEditingMedia }))
    },
    [store]
  )
}
