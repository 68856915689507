import { useUpdateEffect } from '@chakra-ui/hooks'
import isHotkey from 'is-hotkey'
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import {
  Clippable,
  ClippableProps,
  InitialMoveable,
  makeMoveable,
} from 'react-moveable'

import { keyboardHandler } from 'modules/keyboard'
import { useAppDispatch, useAppSelector } from 'modules/redux'

import { MIN_WIDTH_OR_HEIGHT_PIXELS } from '../constants'
import { ResizeAttrs } from '../types'
import { eventEmitter } from './eventEmitter'

import { selectClipType } from '.'

const DEFAULT_CLIP_PATH_INSET = ['0%', '0%', '0%', '0%']

// TODO: Figure out how to enforce min size on clipping
const wrapperClassName = 'clippable-control-wrapper'

type ClipData = Pick<ResizeAttrs, 'clipPath' | 'clipAspectRatio'>

const radiusPixelsToPct = (radiusPixels: number, w: number, h: number) => {
  // Somehow this converts the radius in pixels to the % that the circle clipPath expects
  // See https://github.com/daybrush/moveable/blob/b3986de338b2d38e42288c9d2cafe2a2a7da7705/packages/react-moveable/src/react-moveable/ables/Clippable.tsx#L188-L193
  return (radiusPixels / Math.sqrt((w * w + h * h) / 2)) * 100
}
const radiusPctToPixels = (radius: number, w: number, h: number) => {
  // Somehow this converts the clipPath radius percent to radius in pixels
  // See https://github.com/daybrush/moveable/blob/b3986de338b2d38e42288c9d2cafe2a2a7da7705/packages/react-moveable/src/react-moveable/ables/Clippable.tsx#L188-L193
  return Math.sqrt((w * w + h * h) / 2) * (radius / 100)
}

const getDefaultClipPath = (
  type: 'inset' | 'circle' | null | string,
  w: number,
  h: number
) => {
  if (type === 'circle') {
    const radiusInPixels = Math.min(w, h) / 2
    return [`${radiusPixelsToPct(radiusInPixels, w, h)}%`, 'at', '50%', '50%']
  }
  if (type === 'inset') return DEFAULT_CLIP_PATH_INSET

  return DEFAULT_CLIP_PATH_INSET
}

const getDefaultClipAspectRatio = (
  type: 'inset' | 'circle' | null | string,
  w: number,
  h: number
) => {
  if (type === 'circle') return 1
  if (type === 'inset') return w / h
  return null
}

// Using the array of 8 poses (corners & sides starting top left),
// compute the aspect ratio using top/left and bottom/right
const getAspectRatioFromPoses = (
  poses: number[][],
  clipType: ResizeAttrs['clipType']
) => {
  if (clipType === 'inset') {
    const [x1, y1] = poses[0] // Top Left
    const [x2, y2] = poses[4] // Bottom Right

    const clipWidth = x2 - x1
    const clipHeight = y2 - y1
    return clipWidth / clipHeight
  } else if (clipType === 'circle') {
    return 1
  }
  return 1
}

// Mutates clipStyles to ensure it fits our minimum size
const enforceMinimumClipSize = (
  clipStyles: string[],
  clipType: string,
  w: number,
  h: number
): void => {
  const [s1, s2, s3, s4] = clipStyles
  if (clipType === 'inset') {
    const top = parseFloat(s1)
    const right = parseFloat(s2)
    const bottom = parseFloat(s3)
    const left = parseFloat(s4)
    const width = 100 - left - right
    const height = 100 - top - bottom
    const minWidth = (MIN_WIDTH_OR_HEIGHT_PIXELS / w) * 100
    const minHeight = (MIN_WIDTH_OR_HEIGHT_PIXELS / h) * 100
    if (width < minWidth) {
      const newRight = 100 - left - minWidth
      if (newRight >= 0) {
        clipStyles[1] = `${newRight}%`
      } else {
        clipStyles[1] = '0%' // Lock to right edge
        clipStyles[3] = `${100 - minWidth}%` // As left as possible
      }
    }
    if (height < minHeight) {
      const newBottom = 100 - top - minHeight
      if (newBottom >= 0) {
        clipStyles[2] = `${newBottom}%`
      } else {
        clipStyles[2] = '0%' // Lock to bottom
        clipStyles[0] = `${100 - minHeight}%` // As top as possible
      }
    }
  } else if (clipType === 'circle') {
    const radius = parseFloat(s1)
    const radiusInPixels = Math.max(
      radiusPctToPixels(radius, w, h),
      MIN_WIDTH_OR_HEIGHT_PIXELS
    )
    clipStyles[0] = `${radiusPixelsToPct(radiusInPixels, w, h)}%`
  }
}

/**
 * Given a clip type and clip style, compute CSS mask needed to show that piece.
 * Moveable stores its state and emits events for the clipping using CSS clip-path
 * https://developer.mozilla.org/en-US/docs/Web/CSS/clip-path
 *
 * Converting clip-path to mask takes a little bit of work here, and for circles,
 * its necessary to know the original elements current height/width.
 */
const getMaskFromClipStyle = ({
  clipType,
  clipStyles,
  w,
  h,
}: {
  clipType: ResizeAttrs['clipType']
  clipStyles: string[]
  w: number
  h: number
}) => {
  const [s1, s2, s3, s4] = clipStyles

  if (clipType === 'inset') {
    const top = parseFloat(s1)
    const right = parseFloat(s2)
    const bottom = parseFloat(s3)
    const left = parseFloat(s4)
    const leftPct = (left / (right + left)) * 100
    const topPct = (top / (top + bottom)) * 100

    const finalLeftPct = isNaN(leftPct) ? 50 : leftPct
    const finalTopPct = isNaN(topPct) ? 50 : topPct

    const width = 100 - left - right
    const height = 100 - top - bottom

    return {
      // Use a linear gradient mask for inset (rectangle) clipping.
      // This applies a 0.4 opacity gradient to the part of the image
      // that will be cropped out.
      mask: `
        linear-gradient(#000 0 0) ${finalLeftPct}% ${finalTopPct}% / ${width}% ${height}%,
        linear-gradient(rgba(0,0,0,0.4) 0 0)`,
      width,
      height,
      top,
      right,
      bottom,
      left,
    }
  }

  if (clipType === 'circle') {
    const radius = parseFloat(s1)
    const xPct = parseFloat(s3)
    const yPct = parseFloat(s4)

    const radiusInPixels = radiusPctToPixels(radius, w, h)
    const topToCenterPixels = h * (yPct / 100) - radiusInPixels
    const leftToCenterPixels = w * (xPct / 100) - radiusInPixels

    const width = ((radiusInPixels * 2) / w) * 100
    const height = ((radiusInPixels * 2) / h) * 100
    const top = (topToCenterPixels / h) * 100
    const left = (leftToCenterPixels / w) * 100

    // Use a radial gradient mask for circle clipping.
    // This applies a 0.4 opacity gradient to the part of the image
    // that will be cropped out.
    const mask = `radial-gradient(${width}% ${height}% at ${xPct}% ${yPct}%, black 50%, rgba(0, 0, 0, 0.4) 50%) no-repeat`

    return {
      mask,
      width,
      height,
      top: Math.max(top, 0),
      left: Math.max(left, 0),
      bottom: 0,
      right: 0,
    }
  }

  return {
    mask: '',
    width: 0,
    height: 0,
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  }
}

/**
 * Given a clip type and a list of its styles, compute the transformation
 * data to crop the original image correctly (scale, translate, and CSS mask)
 */
export const getCustomClipData = (
  resize: ResizeAttrs | undefined,
  w: number,
  h: number,
  intrinsicAspectRatio: number | null
) => {
  const defaultData = {
    clipType: 'inset',
    scaleCrop: 1,
    scaleX: 1,
    scaleY: 1,
    aspectRatio: intrinsicAspectRatio || undefined,
    referenceXOffset: 1,
    referenceYOffset: 1,
    clipPathCSSString: '',
    translateX: 0,
    translateY: 0,
  }
  if (!resize || !resize.clipType || !intrinsicAspectRatio) return defaultData

  const clipStyles: string[] | null =
    resize.clipPath || getDefaultClipPath(resize.clipType, w, h)

  const { clipType } = resize
  const { width, height, top, left, right, bottom } = getMaskFromClipStyle({
    w,
    h,
    clipType,
    clipStyles,
  })

  const scaleLeft = 100 / (100 - left + right)
  const scaleTop = 100 / (100 - top + bottom)

  const scaleX = 100 / width
  const scaleY = 100 / height

  const clippedAspectRatio = resize?.clipAspectRatio || 1

  const aspectRatioRatio = intrinsicAspectRatio / clippedAspectRatio
  const aspectRatio = resize?.clipAspectRatio || intrinsicAspectRatio

  const scaleCrop =
    aspectRatioRatio < 1 ? Math.min(scaleY, scaleX) : Math.max(scaleY, scaleX)

  return {
    // To zoom in on the orignal image
    scaleCrop,
    aspectRatio,
    scaleX,
    scaleY,

    // To adjust the offset of the original image
    translateX: left,
    translateY: top,

    // To position the drag preview image
    // TODO - These aren't quite right :(
    referenceXOffset: w * (scaleLeft - 1),
    referenceYOffset: h * (scaleTop - 1),

    // To clip the original image
    clipPathCSSString: `${clipType}(${clipStyles.join(' ')})`,
    clipType,
  }
}

const Moveable = makeMoveable<ClippableProps>([Clippable])

const INSET_OFFSET = 2
const INSET_WIDTH_AND_HEIGHT = 16
/**
 * Styles for the controls added by the moveable library.
 * See https://github.com/daybrush/moveable/blob/master/handbook/handbook.md#toc-custom-css
 *
 * Some of these are just overrides for the default values the library uses, like z-index
 * See those defaults here: https://github.com/daybrush/moveable/blob/6a6bc858afc7edc90212fba8b46b7bdf1c572afd/packages/react-moveable/src/react-moveable/consts.ts#L35-L152
 */
export const ClippableStyles = {
  [`.${wrapperClassName}`]: {
    zIndex: 2,
    '.moveable-control': {
      zIndex: 2,
      _hover: { opacity: 1 },
      transitionProperty: 'opacity',
      transitionDuration: 'normal',
    },
    '&.data-clip-type-inset': {
      '.moveable-control': {
        zIndex: 2,
        bg: '0 none !important',
        borderRadius: '0px',
        width: `${INSET_WIDTH_AND_HEIGHT}px`,
        height: `${INSET_WIDTH_AND_HEIGHT}px`,
        border: '6px solid var(--chakra-colors-trueblue-300)',
        // Style the
        // https://github.com/daybrush/moveable/blob/21622f2f25d912f69b70ba5193b909bf7244db80/packages/react-moveable/src/ables/Clippable.tsx#L52-L54
        '&[data-clip-index="0"]': {
          borderRight: '0',
          borderBottom: '0',
          marginTop: `-${INSET_OFFSET}px`,
          marginLeft: `-${INSET_OFFSET}px`,
          cursor: 'nw-resize',
        },
        '&[data-clip-index="1"]': {
          borderRight: '0',
          borderBottom: '0',
          borderLeft: '0',
          marginTop: `-${INSET_OFFSET}px`,
          cursor: 'n-resize',
        },
        '&[data-clip-index="2"]': {
          borderLeft: '0',
          borderBottom: '0',
          marginTop: `-${INSET_OFFSET}px`,
          marginLeft: `-${INSET_WIDTH_AND_HEIGHT - INSET_OFFSET}px`,
          cursor: 'ne-resize',
        },
        '&[data-clip-index="3"]': {
          borderBottom: '0',
          borderTop: '0',
          borderLeft: '0',
          marginLeft: `-${INSET_WIDTH_AND_HEIGHT - INSET_OFFSET}px`,
          cursor: 'e-resize',
        },
        '&[data-clip-index="4"]': {
          borderTop: '0',
          borderLeft: '0',
          marginLeft: `-${INSET_WIDTH_AND_HEIGHT - INSET_OFFSET}px`,
          marginTop: `-${INSET_WIDTH_AND_HEIGHT - INSET_OFFSET}px`,
          cursor: 'se-resize',
        },
        '&[data-clip-index="5"]': {
          borderTop: '0',
          borderLeft: '0',
          borderRight: '0',
          marginTop: `-${INSET_WIDTH_AND_HEIGHT - INSET_OFFSET}px`,
          cursor: 's-resize',
        },
        '&[data-clip-index="6"]': {
          borderTop: '0',
          borderRight: '0',
          marginTop: `-${INSET_WIDTH_AND_HEIGHT - INSET_OFFSET}px`,
          marginLeft: `-${INSET_OFFSET}px`,
          cursor: 'sw-resize',
        },
        '&[data-clip-index="7"]': {
          borderTop: '0',
          borderBottom: '0',
          borderRight: '0',
          marginLeft: `-${INSET_OFFSET}px`,
          cursor: 'w-resize',
        },
      },
    },
    '&.data-clip-type-circle': {
      '.moveable-control': {
        bg: 'trueblue.300',
      },
    },
    '.moveable-clip-ellipse': {
      borderWidth: '2px',
      borderColor: 'var(--chakra-colors-trueblue-300) !important',
    },
    '.moveable-line': {
      display: 'none',
    },
  },
}

type ClippableControlsProps = {
  currentWidth: number
  currentHeight: number
  clipPath?: ResizeAttrs['clipPath']
  clipAspectRatio?: ResizeAttrs['clipAspectRatio']
  updateResizeAttrs: (resizeAttrs: Partial<ResizeAttrs>) => void
  imageWrapperRef: MutableRefObject<HTMLImageElement | HTMLDivElement | null>

  onFinishCrop?: () => void
  // A dependency array to watch for refreshing the target controls
  refreshDeps: any[]
}

export const ClippableControls = ({
  clipPath,
  clipAspectRatio,
  updateResizeAttrs,
  imageWrapperRef,
  refreshDeps,
  currentWidth,
  currentHeight,
  onFinishCrop,
}: ClippableControlsProps) => {
  const dispatch = useAppDispatch()
  const currentClipType = useAppSelector(selectClipType)
  const moveableInstance = useRef<InitialMoveable | null>(null)
  const [currentClipData, setCurrentClipData] = useState<ClipData>({
    clipPath:
      clipPath ||
      getDefaultClipPath(currentClipType, currentWidth, currentHeight),
    clipAspectRatio:
      clipAspectRatio ||
      getDefaultClipAspectRatio(currentClipType, currentWidth, currentHeight),
  })

  const applyMask = useCallback(
    (clipStyles) => {
      if (!currentWidth || !currentHeight || !imageWrapperRef.current) return

      const { mask } = getMaskFromClipStyle({
        w: currentWidth,
        h: currentHeight,
        clipType: currentClipType,
        clipStyles,
      })
      imageWrapperRef.current.style['-webkit-mask'] = mask
      imageWrapperRef.current.style['-webkit-mask-repeat'] = 'no-repeat'
    },
    [imageWrapperRef, currentClipType, currentWidth, currentHeight]
  )

  const clipStylesHash = JSON.stringify(currentClipData.clipPath)
  useEffect(() => {
    applyMask(currentClipData.clipPath)
  }, [applyMask, clipStylesHash, currentClipData.clipPath])

  useUpdateEffect(() => {
    // When the clip type changes, reset the clipPath data
    setCurrentClipData((prev) => ({
      ...prev,
      clipPath: getDefaultClipPath(
        currentClipType,
        currentWidth,
        currentHeight
      ),
      clipAspectRatio: getDefaultClipAspectRatio(
        currentClipType,
        currentWidth,
        currentHeight
      ),
    }))
  }, [currentClipType])

  useEffect(() => {
    moveableInstance.current?.updateTarget()
  }, [refreshDeps])

  // The function to save the current clipping
  const confirmClip = useCallback(() => {
    const dataToSave = { clipType: currentClipType, ...currentClipData }
    if (!dataToSave.clipAspectRatio || !dataToSave.clipPath) return
    updateResizeAttrs(dataToSave)
  }, [updateResizeAttrs, currentClipData, currentClipType])

  // Store a ref to the confirm function and whether or not we should call it on unmount
  const confirmClipData = useRef({ fn: confirmClip, confirmOnUnmount: true })
  confirmClipData.current.fn = confirmClip

  useEffect(() => {
    // If we get an endClip message, set the confirm value and turn off cropping
    return eventEmitter.on('endClip', ({ confirm }) => {
      confirmClipData.current.confirmOnUnmount = confirm
      if (onFinishCrop) {
        onFinishCrop()
      }
    })
  }, [dispatch, onFinishCrop])

  useEffect(() => {
    // When we unmount, confirm the clip if necessary
    return () => {
      if (confirmClipData.current.confirmOnUnmount) {
        confirmClipData.current.fn()
      }
    }
  }, [])

  useEffect(() => {
    const keydownListener = (e: KeyboardEvent) => {
      // Intercept attempts to cut the image while cropping, because it breaks otherwise
      if (isHotkey('mod+x')(e)) {
        e.preventDefault()
        return true
      }
      return false
    }
    return keyboardHandler.on('keydown', 'CLIPPABLE', keydownListener)
  }, [])

  const customClipPath = `${currentClipType}(${(
    currentClipData.clipPath ||
    getDefaultClipPath(currentClipType, currentWidth, currentHeight)
  ).join(' ')})`

  return (
    <Moveable
      ref={(instance) => {
        moveableInstance.current = instance
      }}
      className={`${wrapperClassName} data-clip-type-${currentClipType}`}
      target={imageWrapperRef.current}
      renderDirections={['ne', 'nw', 'se', 'sw']}
      origin={false}
      draggable={false}
      clippable={true}
      clipTargetBounds={true}
      clipRelative={true}
      clipArea={true}
      defaultClipPath={currentClipType}
      customClipPath={customClipPath}
      dragWithClip={true}
      keepRatio={true}
      onClip={({ clipStyles, clipType }) => {
        enforceMinimumClipSize(
          clipStyles,
          clipType,
          currentWidth,
          currentHeight
        )
        applyMask(clipStyles)
      }}
      onClipEnd={({ lastEvent }) => {
        if (!lastEvent?.clipStyles) return
        const { clipStyles, clipType } = lastEvent
        enforceMinimumClipSize(
          clipStyles,
          clipType,
          currentWidth,
          currentHeight
        )
        setCurrentClipData({
          clipPath: clipStyles,
          clipAspectRatio: getAspectRatioFromPoses(
            lastEvent.poses,
            currentClipType
          ),
        })
      }}
    />
  )
}
