interface ItemWithId {
  id: string
}
const sortItemWithIdToEnd =
  (id?: string) =>
  <T extends ItemWithId>(a: T, b: T) =>
    a.id === id ? 1 : b.id === id ? -1 : 0

export const moveItemWithMatchingIdToEnd = <T extends ItemWithId>(
  list: Array<T>,
  id?: string
) => {
  return [...list].sort(sortItemWithIdToEnd(id))
}

const sortItemWithIdToBeginning =
  (id?: string) =>
  <T extends ItemWithId>(a: T, b: T) =>
    a.id === id ? -1 : b.id === id ? 1 : 0

export const moveItemWithMatchingIdToBeginning = <T extends ItemWithId>(
  list: Array<T>,
  id?: string
) => {
  return list.sort(sortItemWithIdToBeginning(id))
}

export const filterNotVoid = <T>(list: Array<T | null | undefined>): T[] => {
  return list.filter((item): item is T => item !== null && item !== undefined)
}
