import round from 'lodash/round'
import { useCallback, useState } from 'react'

import { collapseMathCmd } from '../extensions/Math/prosemirror-math/commands/collapse-math-cmd'

type LogEntry = {
  msg: string
  timestamp: number
  duration?: number
}

export const useDebugPerfLogging = (enabled: boolean) => {
  const [log, setLog] = useState<LogEntry[]>([])

  const logPerfMessage = useCallback(
    (msg: string) => {
      if (!enabled) {
        return
      }

      const timestamp = performance.now()

      const entry: LogEntry = {
        msg,
        timestamp,
      }
      setLog((a) => {
        if (a.length === 0) {
          return [...a, entry]
        }

        const existing = a.find((e) => e.msg === msg)
        if (existing) {
          return a
        }

        const last = a[a.length - 1]

        if (last.msg === msg) {
          // no-op
          return a
        }

        entry.duration = round(timestamp - a[a.length - 1].timestamp, 2)

        return [...a, entry]
      })
    },
    [enabled]
  )

  const dumpLog = useCallback(() => {
    if (!enabled) {
      return
    }

    setLog((a) => {
      try {
        console.table(a)
      } catch (e) {
        // swallow
      }
      return []
    })
  }, [enabled])

  return { logMessages: log, logPerfMessage, dumpLog }
}
