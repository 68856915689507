import { JSONContent, Editor } from '@tiptap/core'
import { Slice } from 'prosemirror-model'

import { NodeInsertMethods, analytics, SegmentEvents } from 'modules/segment'

import { CommandInfo } from './types'

/**
 * When editor content is being dragged, `editor.view.dragging` contains information about
 * the dragged slice and whether it is being copied or moved. At any other time, it is null.
 * From: https://prosemirror.net/docs/ref/#view.EditorView.dragging
 * This is read by the ProseMirror drop handler:
 * https://github.com/ProseMirror/prosemirror-view/blob/8f246f320801f8e3cac92c97f71ac91e3e327f2f/src/input.js#L626-L677
 */
export const setDraggingContent = (editor: Editor, content: JSONContent) => {
  const sliceContent = Array.isArray(content) ? content : [content]
  const slice = Slice.fromJSON(editor.schema, {
    content: sliceContent,
  })

  // This will get picked up by ProseMirror's onDrop handler and insert the content
  // Setting move: true ensures that unique attribute will honor any IDs we set,
  // which is needed for opening an editor after dragging (e.g. footnote, drawing)
  editor.view.dragging = { slice, move: true }

  // ProseMirror will try to delete the selection you dragged from. This *hack* creates an empty selection such that nothing is deleted on drop.
  // https://github.com/ProseMirror/prosemirror-view/blob/8f246f320801f8e3cac92c97f71ac91e3e327f2f/src/input.js#L655
  editor.commands.setTextSelection(editor.state.selection.from)
}

export const trackItemInserted = (
  item: CommandInfo,
  method: NodeInsertMethods
) => {
  analytics.track(SegmentEvents.NODE_INSERTED, {
    node_name: item.nodeName,
    command: item.key,
    method,
  })
  if (item.nodeName === 'card') {
    analytics.track(SegmentEvents.CARD_CREATED, {
      method,
      command: item.key,
    })
  }
}
