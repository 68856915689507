import { ButtonGroup } from '@chakra-ui/react'

import { AlignmentCommands } from '../../../extensions/HorizontalAlign'
import { DeleteNodeButton } from '../buttons/DeleteNodeButton'
import { ToolbarButton } from '../buttons/ToolbarButton'
import { FormattingMenuDivider } from './FormattingMenuDivider'
import { NodeFormattingMenuProps } from './types'

export const ContributorsFormattingMenu = ({
  editor,
}: NodeFormattingMenuProps) => {
  return (
    <ButtonGroup spacing={0} size="sm" alignItems="center">
      {AlignmentCommands.map(({ name, icon, checkActive, apply }) => {
        if (!checkActive || !apply) return
        return (
          <ToolbarButton
            key={name}
            label={name}
            icon={icon}
            onClick={() => {
              apply(editor)
            }}
            isActive={checkActive(editor)}
          />
        )
      })}
      <FormattingMenuDivider />
      <DeleteNodeButton editor={editor} />
    </ButtonGroup>
  )
}
