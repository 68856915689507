import { Editor } from '@tiptap/core'
import { Mark as ProseMirrorMark, MarkType } from 'prosemirror-model'
import { Plugin, PluginKey } from 'prosemirror-state'

import { ReactNodeViewRenderer } from './ReactNodeViewRenderer'

export type MarkViewProps = {
  node: ProseMirrorMark // Confusingly, ProseMirror calls this node but its of type Mark
  editor: Editor
}

export const addMarkViewPlugin = (
  mark: {
    editor: Editor
    name: string
    type: MarkType
    options: any
    storage: any
  },
  component
) => {
  const markView = (node, view, getPos, decorations) => {
    return ReactNodeViewRenderer(component, {
      as: 'span',
    })({
      editor: mark.editor,
      node,
      getPos,
      decorations,
      // @ts-ignore
      extension: {
        name: mark.name,
        type: mark.type.name,
        options: mark.options,
        storage: mark.storage,
      },
      HTMLAttributes: {},
    })
  }

  const plugin = new Plugin({
    key: new PluginKey(`${mark.name}_markview`),
    props: {
      // @ts-ignore - Tiptap's NodeView definition includes a {} that we don't expect to see here
      nodeViews: {
        [mark.name]: markView,
      },
    },
  })

  return plugin
}
