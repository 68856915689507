import { Box, ButtonGroup, HStack, Text } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import isEqual from 'lodash/isEqual'
import { useCallback } from 'react'

import { useAppDispatch, useAppSelector } from 'modules/redux'
import { isFootnoteEditor } from 'modules/tiptap_editor/extensions/Footnote/utils'
import {
  DEFAULT_RESIZE_STATE,
  Image as ImageNode,
  selectClipType,
  selectIsIdCropping,
} from 'modules/tiptap_editor/extensions/media/Image'
import { useCroppingControls } from 'modules/tiptap_editor/extensions/media/Image/croppingHooks'
import {
  ImageNodeAttrs,
  ResizeAttrs,
} from 'modules/tiptap_editor/extensions/media/types'
import { preventDefaultToAvoidBlur } from 'utils/handlers'

import { AlignmentCommands } from '../../../extensions/HorizontalAlign'
import {
  addImageComment,
  isNodeInGallery,
} from '../../../extensions/media/Gallery/utils'
import { useMediaZoom } from '../../../extensions/media/Zoomable'
import { useToggleMediaDrawer } from '../../drawers/MediaDrawer/utils'
import { DeleteNodeButton } from '../buttons/DeleteNodeButton'
import { SelectParentButton } from '../buttons/SelectParentButton'
import { ToolbarButton } from '../buttons/ToolbarButton'
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownList,
} from '../ToolbarDropdown'
import { FormattingMenuDivider } from './FormattingMenuDivider'
import { NodeFormattingMenuProps } from './types'

export const ImageFormattingMenu = ({
  editor,
  selection,
}: NodeFormattingMenuProps) => {
  const nodeName = selection.node.type.name // Could be image or mediaPlaceholder
  const isImageNode = nodeName === ImageNode.name
  const { resize, fullWidthBlock, id } = selection.node.attrs as ImageNodeAttrs
  const currentClipType = useAppSelector(selectClipType)
  const isCropping = useAppSelector(selectIsIdCropping(id))
  const dispatch = useAppDispatch()
  const hasFormattingApplied = !isEqual(
    {
      resize,
    },
    {
      resize: DEFAULT_RESIZE_STATE,
    }
  )

  const hasCroppingApplied = Boolean(resize?.clipPath && resize?.clipType)

  const { setCropClipType, confirmCrop, cancelCrop, startCrop, resetCrop } =
    useCroppingControls(editor)

  const toggleMediaDrawer = useToggleMediaDrawer()
  const editNode = useCallback(
    (ev) => {
      toggleMediaDrawer(true)
      ev.stopPropagation()
    },
    [toggleMediaDrawer]
  )
  const { enterZoom } = useMediaZoom(selection.node.attrs.id)

  const addComment = useCallback(() => {
    addImageComment(selection)
  }, [selection])

  const inFootnote = isFootnoteEditor(editor)
  const inGallery = isNodeInGallery(selection.$from)
  const cropAndResizeEnabled = !inGallery && !inFootnote

  return (
    <HStack spacing={0}>
      {cropAndResizeEnabled && (
        <>
          {isCropping ? (
            <CropImageDropdown
              clipType={currentClipType}
              onChange={(clipType: ResizeAttrs['clipType']) => {
                setCropClipType({ clipType })
              }}
            />
          ) : (
            <>
              {AlignmentCommands.map(({ name, icon, checkActive, apply }) => {
                if (!checkActive || !apply) return
                return (
                  <ToolbarButton
                    key={name}
                    label={name}
                    icon={icon}
                    onClick={() => {
                      apply(editor)
                      if (fullWidthBlock) {
                        editor.commands.updateAttributes(nodeName, {
                          fullWidthBlock: false,
                          resize: {
                            ...resize,
                            width: null,
                          },
                        })
                      }
                    }}
                    isActive={!fullWidthBlock && checkActive(editor)}
                  />
                )
              })}
            </>
          )}
          <FormattingMenuDivider />
        </>
      )}
      <ButtonGroup spacing={0} size="sm" alignItems="center">
        {isCropping ? (
          <>
            <ToolbarButton
              label={'Reset crop'}
              icon={regular('rotate-left')}
              isDisabled={!hasCroppingApplied}
              onClick={() => {
                resetCrop({ id })
              }}
            />
            <ToolbarButton
              color="red.500"
              label={'Cancel crop'}
              icon={regular('xmark')}
              onClick={() => {
                cancelCrop({ id })
              }}
            />
            <ToolbarButton
              color="green.500"
              label={'Save crop'}
              icon={regular('check')}
              onClick={() => {
                confirmCrop({ id })
              }}
            />
          </>
        ) : (
          <>
            {isImageNode && (
              <ToolbarButton
                label={'Zoom'}
                icon={regular('magnifying-glass-plus')}
                onClick={enterZoom}
              />
            )}
            {isImageNode && !inFootnote && (
              <ToolbarButton
                label={'Add comment'}
                icon={regular('comment')}
                onClick={addComment}
              />
            )}
            {isImageNode && cropAndResizeEnabled && (
              <ToolbarButton
                label={'Crop'}
                icon={regular('crop')}
                isActive={isCropping}
                onClick={() => {
                  startCrop({
                    id,
                    clipType: resize?.clipType,
                  })
                }}
              />
            )}
            {isImageNode && cropAndResizeEnabled && (
              <ToolbarButton
                label={'Reset formatting'}
                icon={regular('rotate-left')}
                isDisabled={!hasFormattingApplied}
                onClick={() => {
                  editor
                    .chain()
                    .resetImageClip()
                    .resetImageScale()
                    .command(({ commands }) => !!commands.refreshBubbleMenu?.())
                    .run()
                }}
              />
            )}
            {inGallery && (
              <>
                <SelectParentButton
                  editor={editor}
                  label="Edit gallery..."
                  icon={regular('grid-2')}
                />
                <FormattingMenuDivider />{' '}
              </>
            )}
            <ToolbarButton
              label={'Edit'}
              icon={regular('pencil')}
              onClick={editNode}
              testId="edit-button"
            />
          </>
        )}
        {!isCropping && (
          <>
            <FormattingMenuDivider />
            <DeleteNodeButton editor={editor} />
          </>
        )}
      </ButtonGroup>
    </HStack>
  )
}

const OPTIONS = {
  inset: { title: 'Freeform' },
  circle: { title: 'Circle' },
} as const

const CropImageDropdown = ({
  clipType,
  onChange,
}: {
  clipType: ResizeAttrs['clipType']
  onChange: (clipType: ResizeAttrs['clipType']) => void
}) => {
  if (!clipType) return null

  return (
    <Dropdown>
      <DropdownButton
        size="sm"
        borderRadius="full"
        // Maintain the same width regardless of the option
        minWidth="105px"
        rightIcon={
          <FontAwesomeIcon
            icon={regular('chevron-down')}
            transform="shrink-6"
          />
        }
        variant="toolbar"
        pl={2}
        onMouseDown={preventDefaultToAvoidBlur}
      >
        {OPTIONS[clipType].title}
      </DropdownButton>
      <DropdownList>
        {Object.entries(OPTIONS).map(([key, { title }]) => {
          return (
            <DropdownItem
              key={key}
              value={key}
              fontSize="sm"
              fontWeight={600}
              icon={
                <Box visibility={key === clipType ? 'visible' : 'hidden'}>
                  <FontAwesomeIcon icon={regular('check')} />
                </Box>
              }
              onMouseDown={preventDefaultToAvoidBlur}
              onClick={() => {
                onChange(key as ResizeAttrs['clipType'])
              }}
            >
              <Text>{title}</Text>
            </DropdownItem>
          )
        })}
      </DropdownList>
    </Dropdown>
  )
}
