import { Slice } from 'prosemirror-model'
import { EditorView } from 'prosemirror-view'

import { createSelectionNearLastTo } from 'modules/tiptap_editor/utils/selection/findSelectionNearOrGapCursor'

export function sliceSingleNode(slice: Slice) {
  return slice.openStart == 0 &&
    slice.openEnd == 0 &&
    slice.content.childCount == 1
    ? slice.content.firstChild
    : null
}

/**
 * this is our end of the line handler for pasting content.  This is meant to always return true
 * to prevent the default prosemirror-view handler to run.  We do this to control the selection after paste
 *
 * This is forked from https://github.com/ProseMirror/prosemirror-view/blob/1.26.2/src/input.ts#L582-L587
 */
export const defaultHandlePaste = (
  view: EditorView,
  event: ClipboardEvent,
  slice: Slice
) => {
  if (!slice) return true

  const singleNode = sliceSingleNode(slice)
  const tr = singleNode
    ? // @ts-ignore view.input is defined
      view.state.tr.replaceSelectionWith(singleNode, view.input.shiftKey)
    : view.state.tr.replaceSelection(slice)

  // this is where our logic diverges.  try to nudge the selection around and potentially create a gapcursor
  // second arg = true, makes it use the `to` instead of `from`.  because we are pasting and want to move "forward"
  const sel = createSelectionNearLastTo(tr)
  if (sel) {
    tr.setSelection(sel)
  }

  view.dispatch(
    tr.scrollIntoView().setMeta('paste', true).setMeta('uiEvent', 'paste')
  )
  return true
}
