import { Editor, findChildrenInRange, NodeWithPos } from '@tiptap/core'
import { Plugin, PluginKey } from 'prosemirror-state'
import { Decoration, DecorationSet } from 'prosemirror-view'

type AutocompletePluginState = {
  loading: boolean
  insertedRange: { from: number; to: number } | null
}

export type AutocompleteLoadEvent = {
  type: 'load'
}
export type AutocompleteResetEvent = {
  type: 'reset'
}
export type AutocompleteInsertEvent = {
  type: 'insert'
  range: { from: number; to: number }
}

export type AutocompleteEvent =
  | AutocompleteLoadEvent
  | AutocompleteResetEvent
  | AutocompleteInsertEvent

const AutocompletePluginKey = new PluginKey<AutocompletePluginState>(
  'autocomplete'
)

export const AutocompletePlugin = (_editor: Editor) => {
  return new Plugin<AutocompletePluginState>({
    key: AutocompletePluginKey,
    // Store the loading status in state, so that when it updates,
    // the editor will redraw the indicator
    state: {
      init() {
        return {
          loading: false,
          insertedRange: null,
        }
      },

      apply(tr, pluginState) {
        const event = tr.getMeta('autocompleteEvent') as
          | AutocompleteEvent
          | undefined
        if (event?.type === 'reset') {
          return {
            loading: false,
            insertedRange: null,
          }
        } else if (event?.type === 'load') {
          return {
            loading: true,
            insertedRange: null,
          }
        } else if (event?.type === 'insert') {
          return {
            loading: false,
            insertedRange: event.range,
          }
        }

        return pluginState
      },
    },

    props: {
      decorations(state) {
        const decos: Decoration[] = []
        const pluginState = AutocompletePluginKey.getState(state)
        const { selection, doc } = state
        const { loading, insertedRange } = pluginState || {}
        if (loading) {
          const deco = Decoration.widget(selection.from, () => {
            const span = document.createElement('span')
            span.className = 'autocomplete-loading'
            return span
          })
          decos.push(deco)
        }

        if (insertedRange) {
          let children: NodeWithPos[]
          try {
            children = findChildrenInRange(
              doc,
              insertedRange,
              (n) => n.isTextblock
            )
          } catch (err) {
            // If the inserted range is invalid, just return an empty array
            children = []
          }
          children.forEach(({ node, pos }) => {
            const start = Math.max(pos + 1, insertedRange.from)
            const end = Math.min(pos + node.nodeSize - 1, insertedRange.to)
            const deco = Decoration.inline(start, end, {
              class: 'autocomplete-inserted',
            })
            decos.push(deco)
          })
        }

        return decos.length
          ? DecorationSet.create(doc, decos)
          : DecorationSet.empty
      },
    },
  })
}
