import { cx } from '@chakra-ui/utils'
import { NodeViewProps, getRenderedAttributes, Editor } from '@tiptap/core'
import {
  Node as ProseMirrorNode,
  Mark as ProseMirrorMark,
} from 'prosemirror-model'
import { DecorationAttrs } from 'prosemirror-view'
import React, { PropsWithChildren, ReactElement } from 'react'

import { ReactRenderer } from '../react/ReactRenderer'
import { useReactNodeView } from '../react/useReactNodeView'
import { getDecorationsForNode } from '../utils/nodeHelpers'

export interface NodeViewContentProps {
  [key: string]: any
  as?: React.ElementType
}

const getAttributeSet = (el: HTMLElement) => {
  const attributes: Record<string, string | undefined> = {}
  for (const attr of el.attributes) {
    const name = attr.name === 'class' ? 'className' : attr.name
    attributes[name] = attr.value
  }
  return attributes
}

export const getRendererForNode = (
  node: NodeViewProps['node'],
  editor: NodeViewProps['editor']
) => {
  const Renderer = Array.from(
    // @ts-ignore
    editor.contentComponent.renderers.values()
  ).find((r: ReactRenderer): r is ReactRenderer => r.props.node === node)

  if (Renderer) {
    const Tag = Renderer.element.nodeName.toLowerCase() || 'div'

    return (
      // @ts-ignore
      <Tag
        key={Renderer.id}
        {...getAttributeSet(Renderer.element as HTMLElement)}
        style={{
          whiteSpace: 'inherit',
        }}
      >
        <Renderer.component {...Renderer.props} />
      </Tag>
    )
  }
  return null
}

/**
 * For a given instance of a node or mark, get the rendered HTML
 * for that node or mark so we can create a React component from it.
 */
const getRenderedHTML = (
  editor: Editor,
  nodeOrMark: ProseMirrorNode | ProseMirrorMark
) => {
  const extension = editor.extensionManager.extensions.find(
    (e) => e.name === nodeOrMark.type.name
  )

  if (!extension) return

  const extensionAttributes = editor.extensionManager.attributes.filter(
    (attribute) => attribute.type === nodeOrMark.type.name
  )
  const HTMLAttributes = getRenderedAttributes(nodeOrMark, extensionAttributes)
  let extensionToUse = extension
  while (!extensionToUse.config.renderHTML && extensionToUse.parent) {
    extensionToUse = extensionToUse.parent
  }
  if (!extensionToUse.config.renderHTML) return

  const renderHTML = extensionToUse.config.renderHTML.bind(extensionToUse)

  return renderHTML({
    HTMLAttributes,
    node: nodeOrMark,
    mark: nodeOrMark,
  })
}

export const NodeViewContent = React.memo(
  (props: PropsWithChildren<NodeViewContentProps>) => {
    const { node, editor, getPos } = useReactNodeView()

    if (!node || !editor) {
      return null
    }

    const Tag = props.as || 'div'
    const TagInner =
      node.isInline || node instanceof ProseMirrorMark ? 'span' : 'div'

    const nodeList: ReactElement<any, any>[] = []

    const basePos = getPos?.() || null

    const getReactElementForNode = (
      n: ProseMirrorNode,
      pos: number,
      key: string
    ) => {
      const renderer = getRendererForNode(n, editor)

      if (renderer) {
        return renderer
      }

      const wrapNodeInMarks = (comp: JSX.Element) => {
        return Array.from(n.marks)
          .reverse() // Reverse to that the last mark in the array is the deepest child
          .reduce((acc, mark) => {
            const [type, nProps, _children] =
              getRenderedHTML(editor, mark) || []
            if (type) {
              return React.createElement(type, { key, ...nProps }, acc)
            }
            return acc
          }, comp)
      }

      const decorations = getDecorationsForNode(
        editor,
        (basePos as number) + pos + 1
      )
      const [type, nProps, children] = getRenderedHTML(editor, n) || []

      if (type) {
        let childrenToUse: React.ReactNode = null
        // See https://prosemirror.net/docs/ref/#model.DOMOutputSpec
        if (children === 0 && n.firstChild) {
          childrenToUse = getReactElementForNode(n.firstChild, pos, `${key}_0`)
          console.debug('[SSR NodeViewContent] HOLE', n.firstChild)
        } else if (Array.isArray(children)) {
          console.debug('[SSR NodeViewContent] children array', children)
          childrenToUse = [...children]
        } else if (children) {
          console.debug('[SSR NodeViewContent] children plain', children)
          childrenToUse = children
        }

        const { nodeName: _nodeName, ...decorationAttrs } = decorations
          // @ts-ignore
          .map((d) => d.type?.attrs as DecorationAttrs)
          .reduce((acc, attrs) => {
            return { ...acc, ...attrs }
          }, {})

        const propsToUse = {
          ...nProps,
          ...decorationAttrs,
          class: cx(decorationAttrs.class, nProps.class),
        }

        console.log('[SSR NodeViewContent] non-nodeview:', propsToUse, children)
        const reactEl = React.createElement(
          type,
          { key, ...propsToUse },
          childrenToUse
        )
        return wrapNodeInMarks(reactEl)
      }

      if (n.isText) {
        return wrapNodeInMarks(<>{n.textContent}</>)
      }

      return null
    }

    node.forEach((n, pos) => {
      const idx = nodeList.length + 1
      const reactElement = getReactElementForNode(n, pos, `${idx}`)
      if (reactElement) {
        nodeList.push(reactElement)
      } else {
        console.warn(
          '%c [Simple NodeViewContent] UNKNOWN NODE $$$$$$$$$$$$$$$$$$$$$$$$$',
          'background-color: aqua; font-weight: bold',
          { unknownNode: n, parentNode: node }
        )
      }
    })

    return (
      <Tag
        {...props}
        data-node-view-content=""
        style={{
          whiteSpace: 'pre-wrap',
          ...props.style,
        }}
      >
        <TagInner
          data-node-view-content-inner={node.type.name}
          style={{
            whiteSpace: 'inherit',
          }}
        >
          {nodeList}
        </TagInner>
      </Tag>
    )
  }
)

NodeViewContent.displayName = 'NodeViewContent'
