import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Editor, Extension } from '@tiptap/core'
import { TextSelection } from 'prosemirror-state'

import { TextFormattingCommand } from '../components/menus/FormattingMenus/TextFormattingCommands'
import { selectionAllowsAttr } from '../utils'
import { UpdateNodeAttrsAnnotationEvent } from './Annotatable/AnnotationExtension/types'
import { ExtensionPriorityMap } from './constants'

const ALIGNABLE_NODES = [
  'paragraph',
  'heading',
  'title',
  'drawing',
  'image',
  'contributors',
  'mediaPlaceholder',
  'buttonGroup',
]

export type HorizontalAlignment = 'left' | 'right' | 'center' | null
const ALIGNMENTS: HorizontalAlignment[] = ['left', 'right', 'center']

export const getAlignStyles = (align: HorizontalAlignment) => {
  const flexAlign = getFlexAlign(align)
  return {
    textAlign: align || undefined,
    alignItems: flexAlign || 'var(--flex-align)',
    '--flex-align': flexAlign,
  }
}

export const getFlexAlign = (align: HorizontalAlignment) => {
  if (!align) {
    // Future: default to flex-end for RTL languages
    return undefined
  }
  return {
    left: 'flex-start',
    center: 'center',
    right: 'flex-end',
  }[align]
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    horizontalAlign: {
      toggleHorizontalAlign: (alignment: HorizontalAlignment) => ReturnType
    }
  }
}

export interface HorizontalAlignOptions {
  types: string[]
  alignments: HorizontalAlignment[]
  defaultAlignment: HorizontalAlignment
}

// Modeled off of Tiptap's text-align, but extended to support other types
export const HorizontalAlign = Extension.create({
  name: 'horizontalAlign',
  priority: ExtensionPriorityMap.HorizontalAlign,

  addOptions() {
    return {
      types: ALIGNABLE_NODES,
      alignments: ALIGNMENTS,
      defaultAlignment: null,
    }
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          horizontalAlign: {
            default: this.options.defaultAlignment,
            keepOnSplit: true,
            parseHTML: (element) =>
              element.style.textAlign || this.options.defaultAlignment,
            renderHTML: (attributes) => {
              if (!attributes.horizontalAlign) {
                return {}
              }

              return { style: `text-align: ${attributes.horizontalAlign}` }
            },
          },
        },
      },
    ]
  },

  // Modeled off of commands in List.ts
  addCommands() {
    return {
      toggleHorizontalAlign:
        (alignment: HorizontalAlignment) =>
        ({ tr, dispatch, state, editor }) => {
          if (!dispatch) return true

          const isActive = checkAlignActive(editor, alignment)
          const newAlign = isActive ? null : alignment

          tr.selection.ranges.forEach((range) => {
            const from = range.$from.pos
            const to = range.$to.pos

            // Find all the nodes in the selection
            state.doc.nodesBetween(from, to, (node, pos) => {
              if (!ALIGNABLE_NODES.includes(node.type.name)) return

              tr.setNodeMarkup(pos, undefined, {
                ...node.attrs,
                horizontalAlign: newAlign,
              }).setMeta('annotationEvent', <UpdateNodeAttrsAnnotationEvent>{
                type: 'update-node-attrs',
                pos,
              })
            })
          })

          return true
        },
    }
  },

  addKeyboardShortcuts() {
    return {
      // Note: onepassword eats this shortcut, but it works otherwise
      'Mod-Shift-l': () => this.editor.commands.toggleHorizontalAlign('left'),
      'Mod-Shift-e': () => this.editor.commands.toggleHorizontalAlign('center'),
      'Mod-Shift-r': () => this.editor.commands.toggleHorizontalAlign('right'),
      // 'Mod-Shift-j': () => this.editor.commands.toggleHorizontalAlign('justify'),

      // If you backspace on an empty line, remove the horizontal alignment. This matches Google Docs behavior
      Backspace: () => {
        const { selection } = this.editor.state
        if (!(selection instanceof TextSelection) || !selection.empty)
          return false

        const node = selection.$from.parent
        if (
          !ALIGNABLE_NODES.includes(node.type.name) ||
          !node.isTextblock ||
          node.nodeSize > 2 // https://prosemirror.net/docs/ref/#model.Node.nodeSize
        ) {
          return false
        }

        if (node.attrs.horizontalAlign == this.options.defaultAlignment) {
          return false
        }

        return this.editor.commands.toggleHorizontalAlign(
          this.options.defaultAlignment
        )
      },
    }
  },
})

export const checkAlignActive = (
  editor: Editor,
  alignment: HorizontalAlignment
) => {
  const otherAlignments = ALIGNMENTS.filter((a) => a !== alignment)

  return (
    editor.isActive({ horizontalAlign: alignment }) &&
    !otherAlignments.some((a) => editor.isActive({ horizontalAlign: a }))
  )
}

const checkAlignDisabled = (editor: Editor) =>
  !selectionAllowsAttr(editor, 'horizontalAlign')

export const AlignmentCommands: TextFormattingCommand[] = [
  {
    key: 'alignLeft',
    name: 'Left align',
    icon: regular('align-left'),
    checkActive: (editor: Editor) => checkAlignActive(editor, 'left'),
    checkDisabled: checkAlignDisabled,
    apply: (editor: Editor) =>
      editor.chain().toggleHorizontalAlign('left').run(),
    shortcut: 'Mod+Shift+L',
    enabledForTables: true,
  },
  {
    key: 'alignCenter',
    name: 'Center align',
    icon: regular('align-center'),
    checkActive: (editor: Editor) => checkAlignActive(editor, 'center'),
    checkDisabled: checkAlignDisabled,
    apply: (editor: Editor) =>
      editor.chain().toggleHorizontalAlign('center').run(),
    shortcut: 'Mod+Shift+E',
    enabledForTables: true,
  },
  {
    key: 'alignRight',
    name: 'Right align',
    icon: regular('align-right'),
    checkActive: (editor: Editor) => checkAlignActive(editor, 'right'),
    checkDisabled: checkAlignDisabled,
    apply: (editor: Editor) =>
      editor.chain().toggleHorizontalAlign('right').run(),
    shortcut: 'Mod+Shift+R',
    enabledForTables: true,
  },
]
