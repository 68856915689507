import { Theme } from 'modules/theming'

import { BackgroundType } from '../backgroundStyles'
import { ContainerEffect } from '../containerStyles'

export const DefaultLightTheme: Theme = {
  accentColor: '#4950bc',
  archived: false,
  config: {
    accentBackgrounds: [
      {
        accentId: 'b11faf30',
        image: {
          meta: { height: 4096, width: 4096 },
          name: 'defaultlight-1_b11faf30.jpg',
          source: 'image.custom',
          src: 'https://cdn.gamma.app/theme_images/default-light/defaultlight-1_b11faf30.jpg',
        },
        originalSource: 'image.custom',
        source: 'accent.theme',
        type: BackgroundType.IMAGE,
      },
      {
        accentId: 'bd6251c2',
        image: {
          meta: { height: 4096, width: 4096 },
          name: 'defaultlight-2_bd6251c2.jpg',
          source: 'image.custom',
          src: 'https://cdn.gamma.app/theme_images/default-light/defaultlight-2_bd6251c2.jpg',
        },
        originalSource: 'image.custom',
        source: 'accent.theme',
        type: BackgroundType.IMAGE,
      },
      {
        accentId: 'fc5293f8',
        image: {
          meta: { height: 4096, width: 4096 },
          name: 'defaultlight-3_fc5293f8.jpg',
          source: 'image.custom',
          src: 'https://cdn.gamma.app/theme_images/default-light/defaultlight-3_fc5293f8.jpg',
        },
        originalSource: 'image.custom',
        source: 'accent.theme',
        type: BackgroundType.IMAGE,
      },
      {
        accentId: 'a9cd724c',
        image: {
          meta: { height: 4096, width: 4096 },
          name: 'defaultlight-4_a9cd724c.jpg',
          source: 'image.custom',
          src: 'https://cdn.gamma.app/theme_images/default-light/defaultlight-4_a9cd724c.jpg',
        },
        originalSource: 'image.custom',
        source: 'accent.theme',
        type: BackgroundType.IMAGE,
      },
      {
        accentId: 'a0c27514',
        image: {
          meta: { height: 4096, width: 4096 },
          name: 'defaultlight-6_a0c27514.jpg',
          source: 'image.custom',
          src: 'https://cdn.gamma.app/theme_images/default-light/defaultlight-6_a0c27514.jpg',
        },
        originalSource: 'image.custom',
        source: 'accent.theme',
        type: BackgroundType.IMAGE,
      },
      {
        accentId: 'b5d93885',
        image: {
          meta: { height: 4096, width: 4096 },
          name: 'defaultlight-8_b5d93885.jpg',
          source: 'image.custom',
          src: 'https://cdn.gamma.app/theme_images/default-light/defaultlight-8_b5d93885.jpg',
        },
        originalSource: 'image.custom',
        source: 'accent.theme',
        type: BackgroundType.IMAGE,
      },
      {
        accentId: '2b633020',
        image: {
          meta: { height: 4096, width: 4096 },
          name: 'defaultlight-10_2b633020.jpg',
          source: 'image.custom',
          src: 'https://cdn.gamma.app/theme_images/default-light/defaultlight-10_2b633020.jpg',
        },
        originalSource: 'image.custom',
        source: 'accent.theme',
        type: BackgroundType.IMAGE,
      },
      {
        accentId: '622798c1',
        image: {
          meta: { height: 4096, width: 4096 },
          name: 'defaultlight-11_622798c1.jpg',
          source: 'image.custom',
          src: 'https://cdn.gamma.app/theme_images/default-light/defaultlight-11_622798c1.jpg',
        },
        originalSource: 'image.custom',
        source: 'accent.theme',
        type: BackgroundType.IMAGE,
      },
      {
        accentId: '99644b26',
        image: {
          meta: { height: 4096, width: 4096 },
          name: 'defaultlight-12_99644b26.jpg',
          source: 'image.custom',
          src: 'https://cdn.gamma.app/theme_images/default-light/defaultlight-12_99644b26.jpg',
        },
        originalSource: 'image.custom',
        source: 'accent.theme',
        type: BackgroundType.IMAGE,
      },
      {
        accentId: 'ff65bca8',
        image: {
          meta: { height: 4096, width: 4096 },
          name: 'defaultlight-13_ff65bca8.jpg',
          source: 'image.custom',
          src: 'https://cdn.gamma.app/theme_images/default-light/defaultlight-13_ff65bca8.jpg',
        },
        originalSource: 'image.custom',
        source: 'accent.theme',
        type: BackgroundType.IMAGE,
      },
      {
        accentId: '9cbf8fa2',
        image: {
          meta: { height: 4096, width: 4096 },
          name: 'defaultlight-16_9cbf8fa2.jpg',
          source: 'image.custom',
          src: 'https://cdn.gamma.app/theme_images/default-light/defaultlight-16_9cbf8fa2.jpg',
        },
        originalSource: 'image.custom',
        source: 'accent.theme',
        type: BackgroundType.IMAGE,
      },
      {
        accentId: '50147d7c',
        image: {
          meta: { height: 4096, width: 4096 },
          name: 'defaultlight-17_50147d7c.jpg',
          source: 'image.custom',
          src: 'https://cdn.gamma.app/theme_images/default-light/defaultlight-17_50147d7c.jpg',
        },
        originalSource: 'image.custom',
        source: 'accent.theme',
        type: BackgroundType.IMAGE,
      },
      {
        accentId: '3f7406eb',
        image: {
          meta: { height: 4096, width: 4096 },
          name: 'defaultlight-18_3f7406eb.jpg',
          source: 'image.custom',
          src: 'https://cdn.gamma.app/theme_images/default-light/defaultlight-18_3f7406eb.jpg',
        },
        originalSource: 'image.custom',
        source: 'accent.theme',
        type: BackgroundType.IMAGE,
      },
    ],
    background: {
      color: {
        hex: '#f6f4f4',
      },
      type: BackgroundType.COLOR,
    },
    colors: [
      {
        name: 'Cool Gray',
        hex: '#EDF2F7',
        isDark: false,
      },
      {
        name: 'Neutral Gray',
        hex: '#F2F2F2',
        isDark: false,
      },
      {
        name: 'Warm Gray',
        hex: '#F7F3F0',
        isDark: false,
      },
      {
        name: 'Beige',
        hex: '#F7F1EB',
        isDark: false,
      },
      {
        name: 'Powder Blue',
        hex: '#bdebe8',
        isDark: false,
      },
      {
        name: 'Light Cyan',
        hex: '#d5eff6',
        isDark: false,
      },
      {
        name: 'Papaya Whip',
        hex: '#f8ecd3',
        isDark: false,
      },
      {
        name: 'Pale Pink',
        hex: '#f8d3d7',
        isDark: false,
      },
      {
        name: 'Lavender',
        hex: '#e2def2',
        isDark: false,
      },
      {
        name: 'Periwinkle',
        hex: '#cbdbf1',
        isDark: false,
      },
      {
        name: 'Linen',
        hex: '#eee2dd',
        isDark: false,
      },
    ],
    container: {
      effect: ContainerEffect.SOLID,
      isDark: false,
    },
    contentStyles: {
      '.block-heading [level], .block-title .title': {
        letterSpacing: '-.03em',
      },
      letterSpacing: '-.02em',
      lineHeight: 1.8,
    },
  },
  createdTime: '2022-01-01T23:49:27.389Z',
  id: 'default-light',
  name: 'Default (light)',
  priority: 1000,
  updatedTime: '2022-06-09T10:38:15.943Z',
}
