import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import { ToolbarButton } from 'modules/tiptap_editor/components/menus/buttons/ToolbarButton'

import { SmartLayoutOption, SmartLayoutOptionControlProps } from '../types'

const TwoSidedOptionControl = ({
  value,
  updateValue,
}: SmartLayoutOptionControlProps<boolean>) => {
  return (
    <ToolbarButton
      label="Two sided"
      onClick={() => updateValue(!value)}
      isActive={value}
      icon={regular('timeline')}
      testId="smart-layout-two-sided-option"
    />
  )
}

export const TwoSidedOption: SmartLayoutOption<boolean> = {
  key: 'twoSided',
  defaultValue: true,
  Control: TwoSidedOptionControl,
}
