import { Extension } from '@tiptap/core'

import { createBlockHoverPlugin } from './BlockHoverPlugin'

export const BlockHoverExtension = Extension.create({
  name: 'BlockHover',

  addProseMirrorPlugins() {
    return [createBlockHoverPlugin(this.editor)]
  },
})
