import { Node } from '@tiptap/core'

import { ReactNodeViewRenderer } from 'modules/tiptap_editor/react'

import { attrsOrDecorationsChanged } from '../updateFns'
import { ToggleSummaryView } from './ToggleSummaryView'

export const ToggleSummary = Node.create({
  name: 'toggleSummary',
  content: 'inline*',
  selectable: false,

  marks: 'bold italic underline code footnoteLabel strike textColor highlight',

  allowFontSizes: 'heading body',
  addAttributes() {
    return {
      fontSize: {
        default: null,
      },
    }
  },

  addNodeView() {
    return ReactNodeViewRenderer(ToggleSummaryView, {
      update: attrsOrDecorationsChanged,
    })
  },

  renderHTML({ HTMLAttributes }) {
    return ['summary', HTMLAttributes, 0]
  },

  parseHTML() {
    return [
      {
        tag: 'summary',
      },
    ]
  },
})
