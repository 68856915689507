import memoize from 'fast-memoize'
import { nanoid } from 'nanoid'

/**
 * This class queues up multiple operations and executes them in the next animation frame.
 * If an operation with the same id is already queued, it will be replaced with the new one.
 */
class NextAnimationFrame {
  private _queue: { id: string; fn: () => void }[] = []
  private _handle: number = 0

  constructor() {
    this._handle = requestAnimationFrame(this._flush.bind(this))
  }

  private _flush() {
    this._handle = 0
    const queue = this._queue
    this._queue = []
    queue.forEach(({ fn }) => {
      fn()
    })
  }

  public push(fn: () => void, id?: string) {
    if (!id) {
      this._queue.push({ fn, id: nanoid(5) })
    } else {
      const ind = this._queue.findIndex((q) => q.id === id)
      if (ind > -1) {
        this._queue.splice(ind, 1, { fn, id })
      } else {
        this._queue.push({ fn, id })
      }
    }
    if (!this._handle) {
      this._handle = requestAnimationFrame(this._flush.bind(this))
    }
  }
}

export const getNextAnimationFrame = memoize(() => new NextAnimationFrame())
