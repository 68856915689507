import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome'
import { Editor } from '@tiptap/core'

import { ToolbarButton } from './ToolbarButton'

type SelectParentButtonProps = {
  editor: Editor
  label: string
  icon?: FontAwesomeIconProps['icon']
}

export const SelectParentButton = ({
  editor,
  label,
  icon,
}: SelectParentButtonProps) => {
  const selectParent = () => {
    editor.chain().selectParentNode().run()
  }
  return (
    <ToolbarButton label={label} onClick={selectParent}>
      {icon ? (
        <FontAwesomeIcon icon={icon} />
      ) : (
        <FontAwesomeIcon icon={regular('arrow-turn-up')} flip="horizontal" />
      )}
    </ToolbarButton>
  )
}
