import { mergeAttributes, Node } from '@tiptap/core'

import { ReactNodeViewRenderer } from '../../react'
import { numChildrenOrAttrsOrDecorationsChanged } from '../updateFns'
import { ButtonGroupView } from './ButtonGroupView'
import { MAX_BUTTONS } from './constants'

export const ButtonGroup = Node.create({
  name: 'buttonGroup',
  content: `button{1, ${MAX_BUTTONS}}`, // https://prosemirror.net/docs/guide/#schema.content_expressions
  group: 'block',

  addNodeView() {
    return ReactNodeViewRenderer(ButtonGroupView, {
      update: numChildrenOrAttrsOrDecorationsChanged,
    })
  },

  parseHTML() {
    return [
      {
        tag: 'div[class=button-group]',
      },
      {
        tag: 'buttons',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      mergeAttributes(HTMLAttributes, { class: 'button-group' }),
      0,
    ]
  },

  renderHTMLforAI({ node }) {
    return ['buttons', {}, 0]
  },
})
