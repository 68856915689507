import { Box, BoxProps, Flex, useColorMode } from '@chakra-ui/react'
import { cx } from '@chakra-ui/utils'
import { Node } from 'prosemirror-model'
import { useRef } from 'react'

import { Browser } from 'modules/browser'
import { stopPropagation } from 'utils/handlers'

import { WebEmbedAttrs } from '../types'
import { NavMenu } from './NavMenu'
import { getEmbedProvider, getProxiedUrl } from './utils'

type EmbedPlayerProps = {
  isZoomed?: boolean
  node: Node
}

export const EmbedPlayer = ({
  isZoomed,
  ...nodeViewProps
}: EmbedPlayerProps) => {
  const { node } = nodeViewProps
  const frameRef = useRef<HTMLIFrameElement>(null)
  const { colorMode } = useColorMode()
  const isDark = colorMode === 'dark'

  // Get the URL to embed
  const { url, proxy, source, embed } = node.attrs as WebEmbedAttrs
  const provider = getEmbedProvider(source)

  const useHtmlEmbed = provider.preferHtml && embed?.html
  if (useHtmlEmbed) {
    return (
      <HtmlEmbed
        html={embed.html!}
        w={isZoomed ? '100%' : undefined}
        onClick={isZoomed ? stopPropagation : undefined}
      />
    )
  }

  const embedUrl =
    provider.rewriteEmbedUrl && url ? provider.rewriteEmbedUrl(url) : url
  const loadableUrl = proxy
    ? getProxiedUrl(embedUrl)
    : embedUrl || 'about:blank'
  const embedHeight = embed?.height || 400

  return (
    <Flex
      direction="column"
      h="100%"
      w="100%"
      maxW="var(--media-maxW)"
      maxH="var(--media-maxH)"
      borderRadius={isZoomed ? 'lg' : 'var(--box-border-radius)'}
      border="1px solid"
      borderColor={isDark ? 'gray.600' : 'gray.200'}
      overflow="hidden"
      data-selection-ring="inside"
      data-selection-background
      className={cx('embed-viewer')}
      // Prevents clicks on the embed from bubbling up to close the overlay
      onClick={isZoomed ? stopPropagation : undefined}
    >
      <NavMenu {...nodeViewProps} frameRef={frameRef} isDark={isDark} />
      <Browser
        initialUrl={loadableUrl}
        frameRef={frameRef}
        iframeOptions={provider.iframeOptions}
        minH={`${embedHeight}px`}
        flex="1"
      />
    </Flex>
  )
}

type HtmlEmbedProps = {
  html: string
} & BoxProps
const HtmlEmbed = ({ html, ...props }: HtmlEmbedProps) => {
  return (
    <Box
      position="relative"
      role="group"
      className="embed-viewer"
      data-selection-ring
      data-selection-background
      borderRadius="var(--box-border-radius)"
      contentEditable={false}
      dangerouslySetInnerHTML={{ __html: html }}
      {...props}
    />
  )
}
