import context from 'launchdarkly-react-client-sdk/lib/context'
import React, { ReactElement } from 'react'

import { useFeatureFlag } from 'modules/featureFlags'

import { useEditorId } from './useEditorId'
import { useOnEditorRendered } from './useOnEditorRendered'

type OnEditorRenderedProps = {
  children: ReactElement
  editorId?: string
}
export const OnEditorRendered: React.FC<OnEditorRenderedProps> = ({
  editorId,
  children,
}) => {
  const enabled = useFeatureFlag('perfOnEditorRendered')
  const contextEditorId = useEditorId()
  const editorIdToUse = editorId || contextEditorId
  const isRendered = useOnEditorRendered(editorIdToUse)

  if (!enabled) {
    return children
  }

  return isRendered ? children : null
}
