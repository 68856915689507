import { findParentNodeClosestToPos, NodeWithPos } from '@tiptap/core'
import { Node, ResolvedPos, Schema, Slice } from 'prosemirror-model'
import { EditorView } from 'prosemirror-view'

import { SmartLayoutAttrs, SmartLayoutNode } from './types'
import { getSmartLayoutVariant } from './variants'

export const getSmartLayoutOptions = (attrs: SmartLayoutAttrs) => {
  const variant = getSmartLayoutVariant(attrs.variantKey)
  const options: Record<string, any> = {}
  variant.options.forEach(({ key, defaultValue }) => {
    options[key] = attrs.options[key] ?? defaultValue
  })
  return options
}

export const getSmartLayoutDirection = (attrs: SmartLayoutAttrs) => {
  const variant = getSmartLayoutVariant(attrs.variantKey)
  const options = getSmartLayoutOptions(attrs)
  return variant.addDirection?.(options) || 'right'
}

export const isSmartLayoutCellNode = (node: Node) =>
  node.type.name === 'smartLayoutCell'

export const isSmartLayoutNode = (node: Node): node is SmartLayoutNode =>
  node.type.name === 'smartLayout'

export const canSmartLayoutContainSlice = (slice: Slice, schema: Schema) =>
  schema.nodes.smartLayout.validContent(slice.content)

export type SmartLayoutDropTarget = {
  pos: number
  node: Node
  side: 'right' | 'left' | 'top' | 'bottom'
  rect: DOMRect
}

export const checkSmartLayoutDropTarget = (
  view: EditorView,
  event: DragEvent,
  slice?: Slice
): SmartLayoutDropTarget | null => {
  if (!slice || !canSmartLayoutContainSlice(slice, view.state.schema)) {
    return null
  }
  const pos = view.posAtCoords({
    left: event.clientX,
    top: event.clientY,
  })
  if (!pos || pos.inside == -1) return null

  const { doc } = view.state
  let target: NodeWithPos | undefined
  const posNode = doc.nodeAt(pos.pos)
  const insideNode = doc.nodeAt(pos.inside)
  if (insideNode && isSmartLayoutCellNode(insideNode)) {
    // Hover directly on a cell
    target = { node: insideNode, pos: pos.inside }
  } else if (posNode && isSmartLayoutCellNode(posNode)) {
    // Hover between two cells
    target = { node: posNode, pos: pos.pos }
  } else {
    // Hover within the children of a cell
    const $pos = doc.resolve(pos.inside)
    target = findParentNodeClosestToPos($pos, isSmartLayoutCellNode)
  }

  const dom = target && (view.nodeDOM(target.pos) as HTMLElement | null)
  const box = dom?.querySelector('[data-content-reference]')
  const targetRect = box?.getBoundingClientRect()

  if (!target || !targetRect) {
    return null
  }

  const $cellPos = doc.resolve(target.pos)
  const layout = $cellPos.parent
  if (!layout || !isSmartLayoutNode(layout)) {
    return null
  }
  const direction = getSmartLayoutDirection(layout.attrs)
  const side =
    direction === 'right'
      ? event.clientX > targetRect.left + targetRect.width / 2
        ? 'right'
        : 'left'
      : event.clientY > targetRect.top + targetRect.height / 2
      ? 'bottom'
      : 'top'
  return {
    pos: target.pos,
    node: target.node,
    rect: targetRect,
    side,
  }
}

export const selectParentLayout = (editor, $pos: ResolvedPos) => {
  const parentLayout = findParentNodeClosestToPos($pos, isSmartLayoutNode)
  if (!parentLayout) return
  editor.commands.selectNodeAtPos(parentLayout.pos)
}
