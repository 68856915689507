import { TDAssets, TDPage, TldrawApp } from '@gamma-app/tldraw'
import { ySyncPluginKey } from '@gamma-app/y-prosemirror'
import { mergeAttributes, Node } from '@tiptap/core'
import { EditorState, NodeSelection, PluginKey } from 'prosemirror-state'

import { getStore } from 'modules/redux'
import { UniqueAttribute } from 'modules/tiptap_editor/plugins'
import { ReactNodeViewRenderer } from 'modules/tiptap_editor/react'
import { setExpandedDrawingId } from 'modules/tiptap_editor/reducer'
import { configureJSONAttribute } from 'modules/tiptap_editor/utils'

import { getNodeAttrsFromBookmark } from '../AI/Sal/utils/bookmark'
import { HorizontalAlignment } from '../HorizontalAlign'
import { attrsOrDecorationsChanged } from '../updateFns'
import { DrawingView } from './DrawingView'
import { generateDrawingId } from './utils'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    drawing: {
      editSelectedDrawingBlock?: () => ReturnType
      insertDrawingBlock?: () => ReturnType
    }
  }
}

export type DrawingAttrs = {
  id: string
  page: TDPage | null
  assets: TDAssets
  horizontalAlign: HorizontalAlignment
  width: number | null
  fullWidthBlock: boolean // 100% of the viewport width, spilling out of the main content column
  meta: {
    width: number | null
    height: number | null
  }
  svg: string | null
}

const pluginKey = new PluginKey('UniqueMediaId')

/**
 * Ensure each drawing node instance has a unique id attribute
 */
const UniqueDrawingId = UniqueAttribute.extend({
  name: 'UniqueDrawingId',
}).configure({
  attributeName: 'id',
  types: ['drawing'],
  initialValue: generateDrawingId,
  filterTransaction: (transaction) => {
    const ySyncMeta = transaction.getMeta(ySyncPluginKey)
    // Ensure that the transaction didnt originate from
    // another user (the name is confusing, but see the code here)
    // https://github.com/yjs/y-prosemirror/blob/a2135a683cb8bdb170c4fb909c8f72a88447951c/src/plugins/sync-plugin.js#L356
    return ySyncMeta?.isChangeOrigin !== true
  },
  pluginKey,
})

export const Drawing = Node.create({
  name: 'drawing',
  group: 'block',
  atom: true,
  selectable: true,
  draggable: true,

  addAttributes() {
    return {
      page: {
        ...configureJSONAttribute('page'),
      },
      assets: {
        ...configureJSONAttribute('assets'),
        default: {},
      },
      meta: {
        ...configureJSONAttribute('meta'),
        default: {},
      },
      fullWidthBlock: { default: false },
      width: {},
      svg: {},
    }
  },

  parseHTML() {
    return [
      {
        tag: 'div[class=drawing]',
      },
      {
        tag: 'tldraw',
        getAttrs: (dom: HTMLElement) => {
          try {
            // Instantiate a temporary tldraw app to handle the paste
            const app = new TldrawApp()
            const content = JSON.parse(dom.innerHTML)
            app.insertContent(content, { select: false })
            const attrs = {
              page: app.document.pages[app.currentPageId],
              assets: app.assets,
            }
            return attrs
          } catch (err) {
            console.error(
              '(caught) Error parsing Tldraw paste',
              err,
              dom.innerHTML
            )
            return false // Prevents parse rule from matching, so others can handle
          }
        },
      },
      {
        tag: 'drawing[bookmark]',
        getAttrs: (el: HTMLElement) =>
          getNodeAttrsFromBookmark(el, this.storage),
      },
    ]
  },

  addStorage() {
    return {
      bookmarks: {},
    }
  },

  renderHTMLforAI({ node }) {
    this.storage.bookmarks[node.attrs.id] = node
    return ['drawing', { bookmark: node.attrs.id }]
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes(HTMLAttributes, { class: 'drawing' })]
  },

  addCommands() {
    const nodeName = this.name
    const getSelectedDrawing = (state: EditorState) => {
      const { selection } = state
      return selection instanceof NodeSelection &&
        selection.node.type.name === nodeName
        ? selection.node
        : undefined
    }
    return {
      editSelectedDrawingBlock:
        () =>
        ({ state }) => {
          const selectedNode = getSelectedDrawing(state)
          if (selectedNode) {
            const store = getStore()
            store.dispatch(
              setExpandedDrawingId({ drawingId: selectedNode.attrs.id })
            )
          }
          return true
        },
      insertDrawingBlock:
        () =>
        ({ commands }) => {
          return commands.insertContent({
            type: 'drawing',
          })
        },
    }
  },

  addExtensions() {
    return [UniqueDrawingId]
  },

  addNodeView() {
    return ReactNodeViewRenderer(DrawingView, {
      update: attrsOrDecorationsChanged,
    })
  },
})
