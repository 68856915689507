import { Box } from '@chakra-ui/react'
import { NodeViewProps } from '@tiptap/core'

import { NodeViewContent } from '../../react'
import { AnnotatableNodeViewWrapper } from '../Annotatable'
export const BlockquoteView = (nodeViewProps: NodeViewProps) => {
  return (
    <AnnotatableNodeViewWrapper {...nodeViewProps}>
      <Box position="relative" data-selection-ring="padded">
        <NodeViewContent as="blockquote"></NodeViewContent>
        <Box
          position="absolute"
          left="0"
          top="0"
          height="100%"
          w="var(--line-thickness)"
          backgroundColor="var(--accent-color)"
          backgroundImage="var(--accent-gradient)"
        />
      </Box>
    </AnnotatableNodeViewWrapper>
  )
}
