import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Editor } from '@tiptap/core'
import { groupBy } from 'lodash'

import { featureFlags } from 'modules/featureFlags'
import { CommandInfo } from 'modules/tiptap_editor/commands'
import { EMPTY_NODES } from 'modules/tiptap_editor/commands/emptyNodes'
import AccentImageLeft from 'publicImages/card-templates/Accent-left.svg'
import AccentImageRight from 'publicImages/card-templates/Accent-right.svg'
import BlankCard from 'publicImages/card-templates/Blank-card.svg'
import TitleCard from 'publicImages/card-templates/Title-card.svg'
import TitleWith2ColumnContent from 'publicImages/card-templates/Title-with-2-Column-Content.svg'
import TitleWith2ColumnImageLeft from 'publicImages/card-templates/Title-with-2-Column-Image-Left.svg'
import TitleWith2ColumnImageRight from 'publicImages/card-templates/Title-with-2-Column-Image-Right.svg'
import TitleWith2Images from 'publicImages/card-templates/Title-with-2-images.svg'
import TitleWith3Boxes from 'publicImages/card-templates/Title-with-3-boxes.svg'
import TitleWith3ColumnContent from 'publicImages/card-templates/Title-with-3-Column-Content.svg'
import TitleWith3Images from 'publicImages/card-templates/Title-with-3-images.svg'
import TitleWith3NestedCards from 'publicImages/card-templates/Title-with-3-nested-cards.svg'
import TitleWith3Toggles from 'publicImages/card-templates/Title-with-3-toggles.svg'
import TitleWithContentWithButton from 'publicImages/card-templates/Title-with-Content-with-Button.svg'
import TitleWithLargeBullets from 'publicImages/card-templates/Title-with-large-bullets.svg'
import TitleWithTeamPhotos from 'publicImages/card-templates/Title-with-team-photos.svg'
import TitleWithText from 'publicImages/card-templates/Title-with-text.svg'
import TitleWithTimeline from 'publicImages/card-templates/Title-with-timeline.svg'

import { getDocFlag } from '../../DocFlags/hooks'
import { assignCardIds } from '../uniqueId'

const CardTemplateGroups = {
  basic: 'One section',
  twoCol: 'Two section',
  specialty: 'Multi section',
}

type CardTemplateCommand = CommandInfo & {
  group: keyof typeof CardTemplateGroups
}

export const checkCardLayoutsDisabled = (editor: Editor) => {
  const docHasCardLayouts = getDocFlag(editor, 'cardLayoutsEnabled')
  const card1InCard2 = featureFlags.get('card1InCard2')
  return !docHasCardLayouts && !card1InCard2
}

export const CardTemplateCommands: CardTemplateCommand[] = [
  {
    type: 'cardTemplate',
    key: 'blankCard',
    name: 'Blank card',
    nodeName: 'card',
    shortcut: '/new',
    icon: duotone('grid-2'),
    previewImage: BlankCard,
    execute: (editor) =>
      editor.commands.insertContentAndSelect(EMPTY_NODES.blankCard),
    group: 'basic',
  },
  {
    type: 'cardTemplate',
    key: 'titleCard',
    name: 'Title card',
    nodeName: 'card',
    icon: duotone('grid-2'),
    previewImage: TitleCard,
    execute: (editor) =>
      editor.commands.insertContentAndSelect(EMPTY_NODES.titleCard),
    group: 'basic',
  },
  {
    type: 'cardTemplate',
    key: 'titleWithText',
    name: 'Title with text',
    nodeName: 'card',
    icon: duotone('grid-2'),
    previewImage: TitleWithText,

    execute: (editor) =>
      editor.commands.insertContentAndSelect(EMPTY_NODES.titleWithText),
    group: 'basic',
  },
  {
    type: 'cardTemplate',
    key: 'accentLeft',
    name: 'Accent left',
    nodeName: 'card',
    icon: duotone('grid-2'),
    previewImage: AccentImageLeft,
    execute: (editor) =>
      editor.commands.insertContentAndSelect(EMPTY_NODES.accentLeft),

    group: 'twoCol',
    checkDisabled: checkCardLayoutsDisabled,
  },
  {
    type: 'cardTemplate',
    key: 'accentRight',
    name: 'Accent right',
    nodeName: 'card',
    icon: duotone('grid-2'),
    previewImage: AccentImageRight,
    execute: (editor) =>
      editor.commands.insertContentAndSelect(EMPTY_NODES.accentRight),
    group: 'twoCol',
    checkDisabled: checkCardLayoutsDisabled,
  },
  {
    type: 'cardTemplate',
    key: 'titleWithContentAndButton',
    name: 'Content with button',
    nodeName: 'card',
    icon: duotone('grid-2'),
    previewImage: TitleWithContentWithButton,
    execute: (editor) =>
      editor.commands.insertContentAndSelect(
        EMPTY_NODES.titleWithContentAndButton
      ),
    group: 'basic',
  },
  {
    type: 'cardTemplate',
    key: 'titleWith2ColumnContent',
    name: '2 column content',
    nodeName: 'card',
    icon: duotone('grid-2'),
    previewImage: TitleWith2ColumnContent,
    execute: (editor) =>
      editor.commands.insertContentAndSelect(
        EMPTY_NODES.titleWith2ColumnContent
      ),
    group: 'twoCol',
  },
  {
    type: 'cardTemplate',
    key: 'titleWith2ColumnImageLeft',
    name: '2 column (image left)',
    nodeName: 'card',
    icon: duotone('grid-2'),
    previewImage: TitleWith2ColumnImageLeft,
    execute: (editor) =>
      editor.commands.insertContentAndSelect(
        EMPTY_NODES.titleWith2ColumnImageLeft
      ),
    group: 'twoCol',
  },
  {
    type: 'cardTemplate',
    key: 'titleWith2ColumnImageRight',
    name: '2 column (image right)',
    nodeName: 'card',
    icon: duotone('grid-2'),
    previewImage: TitleWith2ColumnImageRight,
    group: 'twoCol',

    execute: (editor) =>
      editor.commands.insertContentAndSelect(
        EMPTY_NODES.titleWith2ColumnImageRight
      ),
  },
  {
    type: 'cardTemplate',
    key: 'titleWith2ImageColumns',
    name: '2 image columns',
    nodeName: 'card',
    icon: duotone('grid-2'),
    previewImage: TitleWith2Images,

    execute: (editor) =>
      editor.commands.insertContentAndSelect(
        EMPTY_NODES.titleWith2ImageColumns
      ),
    group: 'twoCol',
  },
  {
    type: 'cardTemplate',
    key: 'titleWith3ColumnContent',
    name: '3 column content',
    nodeName: 'card',
    icon: duotone('grid-2'),
    previewImage: TitleWith3ColumnContent,

    execute: (editor) =>
      editor.commands.insertContentAndSelect(
        EMPTY_NODES.titleWith3ColumnContent
      ),
    group: 'specialty',
  },
  {
    type: 'cardTemplate',
    key: 'titleWith3ImageColumns',
    name: '3 image columns',
    nodeName: 'card',
    icon: duotone('grid-2'),
    previewImage: TitleWith3Images,

    execute: (editor) =>
      editor.commands.insertContentAndSelect(
        EMPTY_NODES.titleWith3ImageColumns
      ),
    group: 'specialty',
  },
  {
    type: 'cardTemplate',
    key: 'titleWith3NestedCards',
    name: 'Title with 3 nested cards',
    nodeName: 'card',
    icon: duotone('grid-2'),
    previewImage: TitleWith3NestedCards,
    execute: (editor) => {
      editor
        .chain()
        .insertContentAndSelect(
          assignCardIds(EMPTY_NODES.titleWith3NestedCards)
        )
        .run()
    },
    group: 'specialty',
  },
  {
    type: 'cardTemplate',
    key: 'titleWith3Toggles',
    name: 'Title with 3 toggles',
    nodeName: 'card',
    icon: duotone('grid-2'),
    previewImage: TitleWith3Toggles,

    execute: (editor) =>
      editor.commands.insertContentAndSelect(EMPTY_NODES.titleWith3Toggles),
    group: 'specialty',
    featureFlag: 'toggle',
  },
  {
    type: 'cardTemplate',
    key: 'titleWithTimeline',
    name: 'Title with timeline',
    nodeName: 'card',
    icon: duotone('grid-2'),
    previewImage: TitleWithTimeline,
    execute: (editor) =>
      editor.commands.insertContentAndSelect(EMPTY_NODES.titleWithTimeline),
    group: 'specialty',
    featureFlag: 'smartLayout',
  },
  {
    type: 'cardTemplate',
    key: 'titleWithLargeBulletList',
    name: 'Title with large bullet list',
    nodeName: 'card',
    icon: duotone('grid-2'),
    previewImage: TitleWithLargeBullets,
    execute: (editor) =>
      editor.commands.insertContentAndSelect(
        EMPTY_NODES.titleWithLargeBulletList
      ),
    group: 'specialty',
    featureFlag: 'smartLayout',
  },
  {
    type: 'cardTemplate',
    key: 'titleWith3Boxes',
    name: 'Title with 3 boxes',
    nodeName: 'card',
    icon: duotone('grid-2'),
    previewImage: TitleWith3Boxes,
    execute: (editor) =>
      editor.commands.insertContentAndSelect(EMPTY_NODES.titleWith3Boxes),
    group: 'specialty',
    featureFlag: 'smartLayout',
  },
  {
    type: 'cardTemplate',
    key: 'titleWithTeamPhotos',
    name: 'Title with team photos',
    nodeName: 'card',
    icon: duotone('grid-2'),
    previewImage: TitleWithTeamPhotos,
    execute: (editor) =>
      editor.commands.insertContentAndSelect(EMPTY_NODES.titleWithTeamPhotos),
    group: 'specialty',
    featureFlag: 'smartLayout',
  },
]

export const GroupedCardTemplates = Object.entries(
  groupBy(CardTemplateCommands, 'group')
).map(([group, items]) => ({
  subcategory: CardTemplateGroups[group],
  items: items,
}))
