import { useTheme } from '@chakra-ui/react'
import { arrayToObjectNotation } from '@chakra-ui/utils'
import { useEffect, useState } from 'react'

import { getBreakpointEmitter } from 'utils/breakpoint'
import { isMobileDevice } from 'utils/deviceDetection'

export function useGammaBreakpointValue<T = any>(
  values: Partial<Record<string, T>> | T[]
): T | undefined {
  const breakpoint = useGammaBreakpoint()
  const theme = useTheme()

  if (!breakpoint) return

  /**
   * Get the sorted breakpoint keys from the provided breakpoints
   */
  const breakpoints = Array.from(theme.__breakpoints?.keys || [])

  const obj = Array.isArray(values)
    ? Object.fromEntries<any>(
        Object.entries(arrayToObjectNotation(values, breakpoints)).map(
          ([key, value]) => [key, value]
        )
      )
    : values

  const value = getClosestValue(obj, breakpoint, breakpoints)

  return value
}

function getClosestValue<T = any>(
  values: Record<string, T>,
  breakpoint: string,
  breakpoints: string[]
) {
  let index = Object.keys(values).indexOf(breakpoint)

  if (index !== -1) {
    return values[breakpoint]
  }

  let stopIndex = breakpoints.indexOf(breakpoint)

  while (stopIndex >= 0) {
    const key = breakpoints[stopIndex]

    // eslint-disable-next-line no-prototype-builtins
    if (values.hasOwnProperty(key)) {
      index = stopIndex
      break
    }
    stopIndex -= 1
  }

  if (index !== -1) {
    const key = breakpoints[index]
    return values[key]
  }

  return undefined
}

/**
 * React hook used to get the current responsive media breakpoint.
 *
 * For SSR, you can use a package like [is-mobile](https://github.com/kaimallea/isMobile)
 * to get the default breakpoint value from the user-agent.
 */
export function useGammaBreakpoint() {
  if (typeof window === 'undefined') {
    return isMobileDevice() ? 'sm' : 'xl'
  }
  // TODO handle SSR in here
  const [breakpoint, setBreakpoint] = useState<string>(
    getBreakpointEmitter().getBreakpointValue('base')
  )

  useEffect(() => {
    return getBreakpointEmitter().on('breakpoint', ({ val }) => {
      setBreakpoint(val)
    })
  }, [])

  return breakpoint
}
