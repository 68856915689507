import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { DOC_DISPLAY_NAME } from '@gamma-app/ui'

import { WORKSPACE_MEMBER_DISPLAY_NAME } from 'modules/workspaces/constants'

export const MANAGE_PERMISSION_LABEL = 'Full Access'

export const CHANNELS_ICON = regular('hashtag')
export const CHANNELS_ICON_ACTIVE = solid('hashtag')

export const PUBLIC_ACCESS_TOOLTIP_LABEL = `They don't need to have a Gamma account to view this ${DOC_DISPLAY_NAME} 👍`

export const ACCESS_LINK_DISPLAY_NAME = 'invite link'

export const REASON_FOR_DISABLED_MANAGE_PERMISSION = `Only workspace ${WORKSPACE_MEMBER_DISPLAY_NAME}s can have ${MANAGE_PERMISSION_LABEL} permission.`
