import { Flex, HStack, Link, Text } from '@chakra-ui/react'
import { Mark, Node } from 'prosemirror-model'

import { config } from 'config'
import { getLinkDomain } from 'utils/link'

import { MediaEmbedAttrs } from '../types'
import { EmbedDescription, EmbedIcon } from './EmbedDescription'
import { EmbedThumbnail } from './EmbedThumbnail'
import { getEmbedProvider } from './utils'

const goldenRatio = 1.618

// Tiny thumbnail's like Twitter's look bad stretched up in our UI
const MIN_THUMBNAIL_SIZE = 50
const isThumbnailBigEnough = (thumbnail: MediaEmbedAttrs['thumbnail']) => {
  return (
    thumbnail &&
    (!thumbnail.height || thumbnail.height >= MIN_THUMBNAIL_SIZE) &&
    (!thumbnail.width || thumbnail.width >= MIN_THUMBNAIL_SIZE)
  )
}

type EmbedPreviewProps = {
  node: Node | Mark
}

export const EmbedPreview = ({ node }: EmbedPreviewProps) => {
  const { thumbnail, sourceUrl } = node.attrs as MediaEmbedAttrs

  return (
    <Flex
      overflow="hidden"
      direction="row"
      align="stretch"
      wrap="wrap"
      contentEditable={false}
      transitionProperty="common"
      transitionDuration="normal"
      position="relative"
      borderRadius="inherit"
      data-selection-ring="inside"
      data-selection-background
      fontFamily="Inter, sans-serif"
      data-drag-handle // https://tiptap.dev/guide/node-views/react#dragging
      h="100%"
      maxW="var(--media-maxW)"
      maxH="var(--media-maxH)"
    >
      {thumbnail && isThumbnailBigEnough(thumbnail) && (
        <Flex
          flex={1}
          minH="10em"
          minW="min(10em, 100%)" // Ensures wrapping when thumb gets too small
          maxW="100%"
          maxH="var(--media-maxH)"
        >
          <EmbedThumbnail node={node} />
        </Flex>
      )}
      <Flex
        flex={goldenRatio}
        w="15em"
        maxW="100%"
        p={'1em'}
        position="relative"
      >
        <EmbedDescription node={node} />
      </Flex>
      {config.GAMMA_PUPPETEER_SERVICE && sourceUrl && (
        <Link
          href={sourceUrl}
          h="100%"
          w="100%"
          position="absolute"
          zIndex="-10"
          data-pdf-link
        />
      )}
    </Flex>
  )
}

export const MiniEmbedPreview = ({ node }: EmbedPreviewProps) => {
  const { thumbnail, meta, sourceUrl, source } = node.attrs as MediaEmbedAttrs
  const provider = getEmbedProvider(source)

  return (
    <Flex
      minW="var(--media-maxH)" // Without a minW, Safari will render these as 0px wide in galleries!
      w="100%"
      css={{
        aspectRatio: '16 / 9',
      }}
      maxW="100%"
      maxH="var(--media-maxH)"
      h="100%"
      align="stretch"
      boxSizing="content-box"
      overflow="hidden"
      data-selection-ring="inside"
      position="relative"
    >
      {thumbnail && isThumbnailBigEnough(thumbnail) ? (
        <>
          <EmbedThumbnail node={node} />
          <HStack
            backgroundColor="whiteAlpha.700"
            backdropFilter="blur(8px)"
            py={1}
            px={2}
            position="absolute"
            bottom="0"
          >
            <EmbedIcon fontSize="sm" icon={meta?.icon || provider.image?.src} />
            {sourceUrl && (
              <Text fontSize="sm" color="black" noOfLines={1}>
                {meta?.title || meta?.site || getLinkDomain(sourceUrl)}
              </Text>
            )}
          </HStack>
        </>
      ) : (
        <Flex align="flex-start" flex="1" p={4}>
          <EmbedDescription node={node} disableOpenButton />
        </Flex>
      )}
      {config.GAMMA_PUPPETEER_SERVICE && sourceUrl && (
        <Link
          href={sourceUrl}
          h="100%"
          w="100%"
          position="absolute"
          zIndex="-10"
          data-pdf-link
        />
      )}
    </Flex>
  )
}
