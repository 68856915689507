import { Editor } from '@tiptap/core'

import { AppDispatch } from 'modules/redux'

import { setDefaultCardCollapse } from '../extensions/Card/CardCollapse'
import { CardIdsPluginKey } from '../extensions/Card/CardIdMap/CardIdsExtension'
import { DocumentAttrsPluginKey } from '../extensions/Document/DocumentAttrs/DocumentAttrsExtension'

export const initializeState = (editor: Editor, dispatch: AppDispatch) => {
  DocumentAttrsPluginKey.getState(editor.state)
    // force compute initial value on create, since a transaction isn't gauranteed to happen
    ?.compute(editor.state.doc)
    .processChanges(dispatch)

  CardIdsPluginKey.getState(editor.state)
    ?.compute(editor.state)
    .processChanges(dispatch)

  // Run in a transaction so that this happens after card IDs have been applied
  editor.commands.command(() => {
    setDefaultCardCollapse(editor.state.doc, dispatch)
    return true
  })
}
