import { Editor } from '@tiptap/core'
import { Node } from 'prosemirror-model'

import { getStore } from 'modules/redux'
import {
  selectToggleExpanded,
  setTogglesExpanded,
} from 'modules/tiptap_editor/reducer'

export const isToggleOpen = (id: string) => {
  const store = getStore()
  return selectToggleExpanded(id)(store.getState()) || false
}

export const setToggleOpen = (id: string, isOpen: boolean) => {
  const store = getStore()
  store.dispatch(setTogglesExpanded({ toggleIds: [id], isExpanded: isOpen }))
}

export const setAllTogglesOpen = (editor: Editor, isOpen: boolean) => {
  const ids: string[] = []
  const store = getStore()
  editor.state.doc.descendants((node) => {
    if (!isToggleNode(node)) return
    const { id } = node.attrs
    ids.push(id)
  })
  store.dispatch(setTogglesExpanded({ toggleIds: ids, isExpanded: isOpen }))
}

export const isToggleNode = (node: Node) => {
  return node.type.name === 'toggle'
}

export const isToggleSummaryNode = (node: Node) => {
  return node.type.name === 'toggleSummary'
}
