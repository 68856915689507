import { keyframes } from '@chakra-ui/react'

import { INSERT_FADE_TIME } from './Autocomplete'

// Spinner
// Based on https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/spinner/src/spinner.tsx
const thickness = '2px'
const emptyColor = 'transparent'
const speed = '0.45s'
const spin = keyframes({
  '0%': {
    transform: 'rotate(0deg)',
  },
  '100%': {
    transform: 'rotate(360deg)',
  },
})

const colorAnimation = keyframes({
  '0%': {
    // opacity: 0.1,
    color: 'var(--chakra-colors-trueblue-500)',
  },
  '100%': {
    // opacity: 1,
    color: 'inherit',
  },
})

const spinnerStyles = {
  display: 'inline-block',
  borderColor: 'currentColor',
  borderStyle: 'solid',
  borderRadius: '99999px',
  borderWidth: thickness,
  borderBottomColor: emptyColor,
  borderLeftColor: emptyColor,
  animation: `${spin} ${speed} linear infinite`,
  width: 'var(--spinner-size)',
  height: 'var(--spinner-size)',
  '--spinner-size': '1em',
  marginLeft: '0.25em',
  color: 'var(--chakra-colors-trueblue-500)',
}

export const AutocompleteStyles = {
  '.autocomplete-loading': spinnerStyles,
  '.autocomplete-inserted': {
    animation: `${colorAnimation} ${INSERT_FADE_TIME}ms ease-out`,
  },
}
