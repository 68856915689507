import {
  Button,
  Textarea,
  useClipboard,
  useToast,
  VStack,
} from '@chakra-ui/react'
import { useCallback, useEffect, useRef, useState } from 'react'

const EMBED_COPIED_TOAST_ID = 'embed-copied-toast'

export const EmbedCodeCopier = ({
  embedCode,
  isDisabled,
}: {
  embedCode: string
  isDisabled: boolean
}) => {
  const toast = useToast()
  const [isSelected, setIsSelected] = useState(false)
  const { hasCopied, onCopy } = useClipboard(embedCode)
  const textareaRef = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    if (!textareaRef.current) {
      return
    }
    if (isSelected) {
      textareaRef.current.focus()
      textareaRef.current.select()
    }
  }, [isSelected])

  const onTextareaClick = useCallback(() => setIsSelected(true), [])
  const onTextareaBlur = useCallback(() => setIsSelected(false), [])

  const onCopyClick = useCallback(() => {
    onCopy()
    if (!toast.isActive(EMBED_COPIED_TOAST_ID)) {
      toast({
        id: EMBED_COPIED_TOAST_ID,
        title: 'Embed code copied to clipboard',
        status: 'success',
        duration: 5000,
      })
    }
  }, [onCopy, toast])

  return (
    <VStack spacing={4} align="stretch">
      <Textarea
        ref={textareaRef}
        rows={5}
        variant="filled"
        fontFamily="mono"
        colorScheme="gray"
        disabled={isDisabled}
        onClick={onTextareaClick}
        onBlur={onTextareaBlur}
        isReadOnly
        resize="none"
        pointerEvents={isDisabled ? 'none' : undefined}
      >
        {embedCode}
      </Textarea>
      <Button
        alignSelf="flex-end"
        onClick={onCopyClick}
        isDisabled={isDisabled}
        size="sm"
      >
        {hasCopied ? 'Copied' : 'Copy embed code'}
      </Button>
    </VStack>
  )
}
