import { createSlice } from '@reduxjs/toolkit'

import { RootState } from 'modules/redux'

type ImageEditorSliceState = Pick<RootState, 'ImageEditor'>

type ClipType = 'inset' | 'circle'
export interface ImageEditorState {
  clipType: ClipType
  isCropping: boolean
  croppingImageId: string | null
}

const initialState: ImageEditorState = {
  clipType: 'inset',
  isCropping: false,
  croppingImageId: null,
}

const ImageEditorSlice = createSlice({
  name: 'ImageEditor',
  initialState,
  reducers: {
    reset: () => initialState,
    setClipType(
      state,
      action: {
        payload: { clipType: ClipType }
      }
    ) {
      const { clipType } = action.payload
      state.clipType = clipType
    },
    startCropping(
      state,
      action: {
        payload: {
          id: string
          clipType?: ClipType | null
        }
      }
    ) {
      const { id, clipType } = action.payload
      state.croppingImageId = id
      state.clipType = clipType || 'inset'
    },
    endCropping(state) {
      state.croppingImageId = null
    },
  },
})

export const { setClipType, endCropping, startCropping } =
  ImageEditorSlice.actions

// Selectors
export const selectClipType = (state: ImageEditorSliceState) =>
  state.ImageEditor.clipType

export const selectIsCropping = (state: ImageEditorSliceState) =>
  !!state.ImageEditor.croppingImageId

export const selectIsIdCropping = (id) => (state: ImageEditorSliceState) =>
  state.ImageEditor.croppingImageId === id

// Reducer
export const reducer = ImageEditorSlice.reducer
