import { IconButton, useColorMode } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'
import { Editor, NodeViewProps } from '@tiptap/core'
import { memo } from 'react'

import { AnalyticsPanelModal } from 'modules/analytics'
import { useFeatureFlag } from 'modules/featureFlags'
import { useModalDisclosure } from 'modules/modal_state/hooks/useModalDisclosure'
import { useAppSelector } from 'modules/redux'
import { SharePanelModal } from 'modules/sharing'
import { EditorModeEnum } from 'modules/tiptap_editor'
import { useManageCardMenu } from 'modules/tiptap_editor/extensions/Card/ManageCardMenu/hooks'
import { selectDoc } from 'modules/tiptap_editor/reducer'
import { useCan } from 'modules/user'
import { getCardUrl } from 'utils/url'

type CardMenuProps = {
  cardId: string
  docId?: string
  editor?: Editor
  shouldShowButton: boolean
  getPos: () => number | null
  editorMode: EditorModeEnum
} & Pick<NodeViewProps, 'editor'>

export const CardMenu = memo(function CardMenuMemo({
  cardId,
  docId,
  editor,
  getPos,
  shouldShowButton,
  editorMode,
}: CardMenuProps) {
  const cardUrl = getCardUrl({
    cardId,
    docId,
    isPresentMode: editorMode === EditorModeEnum.SLIDE_VIEW,
  })
  const { colorMode } = useColorMode()
  const isDark = colorMode === 'dark'
  const doc = useAppSelector(selectDoc)
  const canManage = useCan('manage', doc)
  const isAnalyticsEnabled = useFeatureFlag('analytics')
  const canViewFullAnalytics = canManage && isAnalyticsEnabled

  const {
    isOpen: isSharePanelOpen,
    onOpen: onSharePanelOpen,
    onClose: onSharePanelClose,
  } = useModalDisclosure({ id: 'sharePanelDisclosure' })

  const {
    isOpen: isAnalyticsPanelOpen,
    onOpen: onAnalyticsPanelOpen,
    onClose: onAnalyticsPanelClose,
  } = useModalDisclosure({ id: 'analyticsPanelDisclosure' })

  const { openManageCardMenuClickHandler, isMenuOpen, activatorRef } =
    useManageCardMenu({
      id: 'toc',
      cardUrl,
      getPos,
      onSharePanelOpen,
    })

  if (!shouldShowButton && !isMenuOpen) {
    return null
  }
  return (
    <>
      <GammaTooltip label={'Card options'} placement="top">
        <IconButton
          ref={activatorRef}
          aria-label="card options"
          onClick={(e) => {
            e.stopPropagation()
            openManageCardMenuClickHandler()
          }}
          isRound={true}
          icon={<FontAwesomeIcon icon={regular('ellipsis-h')} />}
          size="xs"
          variant="ghost"
          _hover={{ bg: isDark ? 'whiteAlpha.100' : 'blackAlpha.100' }}
        >
          Actions
        </IconButton>
      </GammaTooltip>
      {/*Sharing and Analytics modals */}
      {doc && isSharePanelOpen && (
        <SharePanelModal
          doc={doc}
          editor={editor}
          isSharePanelOpen={isSharePanelOpen}
          onSharePanelClose={onSharePanelClose}
          onAnalyticsPanelOpen={onAnalyticsPanelOpen}
        />
      )}
      {doc && canViewFullAnalytics && isAnalyticsPanelOpen && (
        <AnalyticsPanelModal
          doc={doc}
          isAnalyticsPanelOpen={isAnalyticsPanelOpen}
          onAnalyticsPanelClose={onAnalyticsPanelClose}
          onSharePanelOpen={onSharePanelOpen}
          mode="full"
        />
      )}
    </>
  )
})
