import { VStack } from '@chakra-ui/react'
import { brands } from '@fortawesome/fontawesome-svg-core/import.macro'

import { VideoEmbedAttrs } from 'modules/tiptap_editor/extensions/media/types'
import { getMediaSourceUrl } from 'modules/tiptap_editor/extensions/media/utils'

import { EmbedTip } from '../components/EmbedTip'
import { URLFetcher } from '../components/URLFetcher'
import {
  MediaProviderPanelProps,
  MediaProviderType,
} from '../types/MediaProvider'
import { EmbedMetadata } from './EmbedMetadata'
import Icon from './icons/tiktok.svg'

const Panel = ({
  editor,
  updateAttributes,
  currentAttributes,
}: MediaProviderPanelProps<VideoEmbedAttrs>) => {
  const url = getMediaSourceUrl(currentAttributes)

  return (
    <VStack align="flex-start" spacing={4}>
      <URLFetcher
        currentUrl={url}
        updateAttributes={updateAttributes}
        placeholder="https://www.tiktok.com/..."
      />
      <EmbedTip
        guideLink="https://developers.tiktok.com/doc/embed-videos/"
        providerName="TikTok"
      />
      {url && (
        <EmbedMetadata
          editor={editor}
          updateAttributes={updateAttributes}
          currentAttributes={currentAttributes}
        />
      )}
    </VStack>
  )
}

/* Example:
https://www.tiktok.com/@mariitv__/video/7124106368214273326?is_from_webapp=1&sender_device=pc&web_id=7127511579529561646
https://www.tiktok.com/share/video/6718335390845095173
https://regexr.com/73fjl
*/
const VideoIdRegex = /\/video\/(\d+)/

export const TikTokProvider: MediaProviderType = {
  label: 'TikTok',
  key: 'video.tiktok',
  icon: brands('tiktok'),
  image: Icon,
  urlRegex: /^https?:\/\/www.tiktok.com/,
  Panel,
  iframeOptions: {
    allowPopups: true,
  },
  rewriteEmbedUrl(url) {
    const videoId = url.match(VideoIdRegex)?.[1]
    if (!videoId) return url
    return `https://www.tiktok.com/embed/v2/${videoId}`
  },
}
