import {
  callOrReturn,
  getExtensionField,
  mergeAttributes,
  Node,
  ParentConfig,
} from '@tiptap/core'
import { NodeView } from 'prosemirror-view'

import { ReactNodeViewRenderer } from 'modules/tiptap_editor/react'
import { configureJSONAttribute } from 'modules/tiptap_editor/utils'

import { ExtensionPriorityMap } from '../../constants'
import { attrsOrDecorationsChanged } from '../../updateFns'
import {
  addColumn,
  addRowAfter,
  addRowBefore,
  CellSelection,
  columnResizing,
  deleteColumnTr,
  deleteColWhenEmpty,
  deleteRowTr,
  deleteRowWhenEmpty,
  deleteTableTr,
  deleteTableWhenEmpty,
  fixTables,
  goToNextCell,
  isInTable,
  mergeCells,
  selectedRect,
  setCellAttr,
  splitCell,
  tableEditing,
  toggleHeaderCell,
  toggleHeaderColumn,
  toggleHeaderRow,
} from '../prosemirror-table'
import { createColumnWidths } from '../prosemirror-table/columnUtils'
import { createTable } from '../utils/createTable'
import { TableView } from './Table'

export interface TableOptions {
  HTMLAttributes: Record<string, any>
  resizable: boolean
  handleWidth: number
  colMinPercent: number
  newColSize: number
  View: NodeView
  lastColumnResizable: boolean
  allowTableNodeSelection: boolean
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    table: {
      insertTable: (options?: {
        rows?: number
        cols?: number
        withHeaderRow?: boolean
      }) => ReturnType
      addColumnBefore: (col: number) => ReturnType
      addColumnAfter: (col: number) => ReturnType
      equalizeColumns: () => ReturnType
      deleteColumn: () => ReturnType
      addRowBefore: () => ReturnType
      addRowAfter: () => ReturnType
      deleteRow: () => ReturnType
      deleteTable: () => ReturnType
      deleteTableWhenEmpty: () => ReturnType
      deleteRowWhenEmpty: () => ReturnType
      deleteColWhenEmpty: () => ReturnType
      mergeCells: () => ReturnType
      splitCell: () => ReturnType
      toggleHeaderColumn: () => ReturnType
      toggleHeaderRow: () => ReturnType
      toggleHeaderCell: () => ReturnType
      mergeOrSplit: () => ReturnType
      setCellAttribute: (name: string, value: any) => ReturnType
      goToNextCell: () => ReturnType
      goToPreviousCell: () => ReturnType
      fixTables: () => ReturnType
      setCellSelection: (position: {
        anchorCell: number
        headCell?: number
      }) => ReturnType
    }
  }

  interface NodeConfig<Options, Storage> {
    /**
     * Table Role
     */
    tableRole?:
      | string
      | ((this: {
          name: string
          options: Options
          storage: Storage
          parent: ParentConfig<NodeConfig<Options>>['tableRole']
        }) => string)
  }
}

export type TableAttrs = {
  colWidths: string[]
  fullWidthBlock: boolean
}

export const Table = Node.create<TableOptions>({
  name: 'table',

  priority: ExtensionPriorityMap.Table,
  containerHandle: true,

  addNodeView() {
    return ReactNodeViewRenderer(TableView, {
      update: attrsOrDecorationsChanged,
    })
  },

  // @ts-ignore
  addOptions() {
    return {
      resizable: true,
      handleWidth: 10,
      colMinPercent: 10,
      newColSize: 20,
      lastColumnResizable: false,
      // allow table node selection to make dragging (move) able to delete the dragged table node
      allowTableNodeSelection: true,
    }
  },

  content: 'tableRow+',

  tableRole: 'table',

  isolating: true,
  // explicitely don't allow gapcursors
  allowGapCursor: false,

  group: 'cardBlock layoutBlock footnoteBlock calloutBlock',

  parseHTML() {
    return [{ tag: 'table' }]
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'table',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      ['tbody', 0],
    ]
  },

  addAttributes() {
    return {
      colWidths: {
        ...configureJSONAttribute('colWidths'),
        default: [],
      },
      fullWidthBlock: {
        default: false,
      },
    }
  },

  addCommands() {
    return {
      insertTable:
        ({ rows = 3, cols = 3, withHeaderRow = true } = {}) =>
        ({ dispatch, editor, commands }) => {
          if (!dispatch) {
            return false
          }
          const node = createTable(editor.schema, rows, cols, withHeaderRow)

          return commands.insertContentAndSelect(node.toJSON())
        },
      addColumnBefore:
        (col: number) =>
        ({ state, dispatch, tr }) => {
          if (!isInTable(state.selection)) return false
          if (!dispatch) return false
          const { table, tableStart } = selectedRect(state.selection)

          // TODO jordan have a check here to see if a column can be added.
          return addColumn(tr, {
            table,
            col,
            tableStart,
            newColSize: this.options.newColSize,
            colMinPercent: this.options.colMinPercent,
          })
        },
      addColumnAfter:
        (col: number) =>
        ({ state, dispatch, tr }) => {
          if (!isInTable(state.selection)) return false
          if (!dispatch) return false
          const { table, tableStart } = selectedRect(state.selection)

          // TODO jordan have a check here to see if a column can be added.
          return addColumn(tr, {
            table,
            col: col + 1,
            tableStart,
            newColSize: this.options.newColSize,
            colMinPercent: this.options.colMinPercent,
          })
        },
      equalizeColumns:
        () =>
        ({ state, dispatch, tr }) => {
          if (!isInTable(state.selection)) return false
          if (!dispatch) return false
          const { map, tableStart } = selectedRect(state.selection)
          tr.setNodeMarkup(tableStart - 1, undefined, {
            colWidths: createColumnWidths(map.width),
          })
          return true
        },
      deleteColumn:
        () =>
        ({ state, dispatch, tr }) => {
          if (!dispatch) return false
          return deleteColumnTr(state.selection, tr, dispatch)
        },
      addRowBefore:
        () =>
        ({ state, dispatch }) => {
          return addRowBefore(state, dispatch)
        },
      addRowAfter:
        () =>
        ({ state, dispatch }) => {
          return addRowAfter(state, dispatch)
        },
      deleteRow:
        () =>
        ({ state, dispatch, tr }) => {
          return deleteRowTr(state.selection, tr, dispatch)
        },
      deleteTable:
        () =>
        ({ state, tr, dispatch }) => {
          if (!dispatch) return false
          return deleteTableTr(state.selection, tr, dispatch)
        },
      deleteTableWhenEmpty:
        () =>
        ({ state, tr, dispatch }) => {
          if (!dispatch) return false
          return deleteTableWhenEmpty(state.selection, tr, dispatch)
        },
      deleteColWhenEmpty:
        () =>
        ({ state, tr, dispatch }) => {
          if (!dispatch) return false
          return deleteColWhenEmpty(state.selection, tr, dispatch)
        },
      deleteRowWhenEmpty:
        () =>
        ({ state, tr, dispatch }) => {
          if (!dispatch) return false
          return deleteRowWhenEmpty(state.selection, tr, dispatch)
        },
      mergeCells:
        () =>
        ({ state, dispatch }) => {
          return mergeCells(state, dispatch)
        },
      splitCell:
        () =>
        ({ state, dispatch }) => {
          return splitCell(state, dispatch)
        },
      toggleHeaderColumn:
        () =>
        ({ state, dispatch }) => {
          return toggleHeaderColumn(state, dispatch)
        },
      toggleHeaderRow:
        () =>
        ({ state, dispatch }) => {
          return toggleHeaderRow(state, dispatch)
        },
      toggleHeaderCell:
        () =>
        ({ state, dispatch }) => {
          return toggleHeaderCell(state, dispatch)
        },
      mergeOrSplit:
        () =>
        ({ state, dispatch }) => {
          if (mergeCells(state, dispatch)) {
            return true
          }

          return splitCell(state, dispatch)
        },
      setCellAttribute:
        (name, value) =>
        ({ state, dispatch }) => {
          return setCellAttr(name, value)(state, dispatch)
        },
      goToNextCell:
        () =>
        ({ state, dispatch }) => {
          return goToNextCell(1)(state, dispatch)
        },
      goToPreviousCell:
        () =>
        ({ state, dispatch }) => {
          return goToNextCell(-1)(state, dispatch)
        },
      fixTables:
        () =>
        ({ state, dispatch }) => {
          if (dispatch) {
            fixTables(state)
          }

          return true
        },
      setCellSelection:
        (position) =>
        ({ tr, dispatch }) => {
          if (dispatch) {
            const selection = CellSelection.create(
              tr.doc,
              position.anchorCell,
              position.headCell
            )

            // @ts-ignore
            tr.setSelection(selection)
          }

          return true
        },
    }
  },

  addKeyboardShortcuts() {
    const handleDelete = () =>
      this.editor.commands.first(({ commands }) => [
        () => commands.deleteTableWhenEmpty(),
        () => commands.deleteRowWhenEmpty(),
        () => commands.deleteColWhenEmpty(),
      ])
    return {
      Tab: () => {
        if (this.editor.commands.goToNextCell()) {
          return true
        }

        if (!this.editor.can().addRowAfter()) {
          return false
        }

        return this.editor.chain().addRowAfter().goToNextCell().run()
      },
      'Shift-Tab': () => this.editor.commands.goToPreviousCell(),

      Backspace: handleDelete,
      'Mod-Backspace': handleDelete,
      Delete: handleDelete,
      'Mod-Delete': handleDelete,
    }
  },

  addProseMirrorPlugins() {
    // TODO cleanup this option
    const isResizable = this.options.resizable /*&& this.editor.isEditable*/
    return [
      columnResizing({
        handleWidth: this.options.handleWidth,
        colMinPercent: this.options.colMinPercent,
        // TODO(jordan) fix typing
        // @ts-ignore (incorrect type)
        lastColumnResizable: this.options.lastColumnResizable,
      }),
      tableEditing({
        allowTableNodeSelection: this.options.allowTableNodeSelection,
      }),
    ]
  },

  extendNodeSchema(extension) {
    const context = {
      name: extension.name,
      options: extension.options,
      storage: extension.storage,
    }

    // store the options.colMinPercent and options.newColSize on `editor.schema.nodes[Table.name].spec
    // to statically look up these two values where we insert columns, such as the TableFormattingMenu
    return {
      // use optional (?) since extension.options will not be present in the footnote or comment editor
      colMinPercent: extension.options?.colMinPercent,
      newColSize: extension.options?.newColSize,
      tableRole: callOrReturn(
        getExtensionField(extension, 'tableRole', context)
      ),
    }
  },
})
