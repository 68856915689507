export const DefaultCellContent = [
  {
    type: 'heading',
    attrs: {
      level: 3,
    },
    content: [
      {
        type: 'text',
        text: `Thing`,
      },
    ],
  },
  {
    type: 'paragraph',
    content: [
      {
        type: 'text',
        text: `Description`,
      },
    ],
  },
]

export const EmptyCellContent = [
  {
    type: 'heading',
    attrs: {
      level: 3,
    },
  },
]

export const CELL_PADDING = '1em'
