import { Box, Flex, IconButton } from '@chakra-ui/react'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'
import DOMPurify from 'isomorphic-dompurify'
import { MouseEvent, useEffect, useRef, useState } from 'react'

import { useTooltipPortalRef } from 'modules/tooltips/hooks'

import { CommentInstanceType } from './Comment'

export const CommentTargetContent = ({
  targetHtml,
  onTargetClick,
  isSourceContentAvailable,
  instanceType,
}: {
  targetHtml: string
  onTargetClick?: (e: MouseEvent) => void
  isSourceContentAvailable?: boolean
  instanceType?: CommentInstanceType
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false)
  const [showIcon, setShowIcon] = useState<boolean>(false)
  const el = useRef<HTMLDivElement>(null)
  const tooltipPortalRef = useTooltipPortalRef()
  const noOfLines = isExpanded ? undefined : 3
  const toggleIcon = isExpanded
    ? (regular('chevron-circle-up') as IconProp)
    : (regular('chevron-circle-down') as IconProp)
  const toggleIconLabel = isExpanded ? 'Hide' : 'Show more'

  useEffect(() => {
    if (!el.current) return

    const noNeedToExpandBox =
      !isExpanded && el.current.scrollHeight <= el.current?.clientHeight
    setShowIcon(!noNeedToExpandBox)
  }, [isExpanded])

  const hoverBgColor =
    instanceType === 'feed'
      ? isSourceContentAvailable
        ? 'yellow.100'
        : 'gray.100'
      : undefined
  const cursor =
    isSourceContentAvailable && instanceType === 'feed' ? 'pointer' : 'auto'

  return (
    <Flex
      className="block-target-html"
      pl={3}
      mb={2}
      borderLeftWidth="4px"
      borderLeftStyle="solid"
      borderLeftColor="yellow.200"
      direction="row"
      alignItems="flex-end"
      cursor={cursor}
      onClick={onTargetClick}
    >
      <GammaTooltip
        label="Source content no longer exists"
        isDisabled={!(instanceType === 'feed' && !isSourceContentAvailable)}
        portalProps={{ containerRef: tooltipPortalRef }}
      >
        <Box
          flex={1}
          ml={-3}
          pl={3}
          w="100%"
          fontSize="sm"
          noOfLines={noOfLines}
          dangerouslySetInnerHTML={{
            __html: targetHtml ? DOMPurify.sanitize(targetHtml) : '',
          }}
          ref={el}
          _hover={{
            bgColor: hoverBgColor,
          }}
        />
      </GammaTooltip>
      {showIcon && (
        <GammaTooltip
          label={toggleIconLabel}
          portalProps={{ containerRef: tooltipPortalRef }}
        >
          <IconButton
            variant="ghost"
            size="xs"
            aria-label={toggleIconLabel}
            icon={<FontAwesomeIcon icon={toggleIcon} />}
            onClick={() => {
              setIsExpanded((prev) => !prev)
            }}
            data-target-name="comment-target-expand-button"
            isRound={true}
          />
        </GammaTooltip>
      )}
    </Flex>
  )
}
