const GAMMA_CDN_HOSTNAMES = [
  'https://cdn.gamma.app',
  'https://cdn-staging.gamma.app',
]

export const isGammaCDNUrl = (url: string) => {
  return GAMMA_CDN_HOSTNAMES.some((hostname) => url.startsWith(hostname + '/'))
}

type CloudflareResizeParams = {
  width: number
  height: number
  quality: number
  fit: 'scale-down' | 'contain' | 'cover' | 'crop' | 'pad'
}

const DEFAULT_RESIZE_PARAMS = {
  quality: 80,
  fit: 'scale-down',
}

// Replace all instances of Gamma CDN urls in the input (e.g. a background-image prop)
// with resized versions via Cloudflare
export const resizeGammaCDNUrls = (
  input: string,
  params: Partial<CloudflareResizeParams>
) => {
  if (!params.width && !params.height) return input
  const urlParams = Object.entries({ ...DEFAULT_RESIZE_PARAMS, ...params })
    .map(([key, value]) => `${key}=${value}`)
    .join(',')
  GAMMA_CDN_HOSTNAMES.forEach((hostname) => {
    input = input.replace(
      hostname,
      // https://developers.cloudflare.com/images/image-resizing/url-format/
      `${hostname}/cdn-cgi/image/${urlParams}`
    )
  })
  return input
}

/**
 * See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/Data_URIs
 * See: https://stackoverflow.com/questions/35940290/how-to-convert-base64-string-to-javascript-file-object-like-as-from-file-input-f
 */
export function dataURLtoFile(dataurl, filename = 'image') {
  const arr = dataurl.split(',')
  const mime = arr[0].match(/:(.*?);/)[1]
  const ext = mime.match(/image\/(.*)/)[1]

  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }

  return new File([u8arr], `${filename}.${ext}`, { type: mime })
}

export const downloadImage = (src?: string) =>
  new Promise((res, rej) => {
    if (!src) {
      res(null)
    } else {
      const img = new Image()
      img.src = src
      img.onload = res
      img.onerror = rej
    }
  })

export function isImageMimeType(type: string): boolean {
  return /^image/i.test(type)
}

/**
 * These two functions isHEICFileType and isHEICFileExtension use regex to determine
 * if something is HEIC / HEIF.
 *
 * NOTE: the template on transloadit will also need to be updated to filter for these
 * same values
 */
export function isHEICFileType(mimeType: string): boolean {
  return /heic|heif/i.test(mimeType)
}

export function isHEICFileExtension(filePath: string): boolean {
  return /\.heic|\.heif$/i.test(filePath)
}

export function svgToFile(svg: string): File {
  const type = 'image/svg+xml;charset=utf-8'
  const blob = new Blob(['<?xml version="1.0" standalone="no"?>\r\n', svg], {
    type,
  })
  const file = new File([blob], 'pasted.svg', {
    type,
  })
  return file
}
