import { EventEmitter } from 'utils/EventEmitter'

type ImageEvents = {
  startCrop: { id: string }
  endClip: {
    id: string
    confirm: boolean
  }
}

export const eventEmitter = new EventEmitter<ImageEvents>()
