import {
  ButtonGroup,
  Collapse,
  FlexProps,
  IconButton,
  Image,
  Stack,
  Text,
} from '@chakra-ui/react'
import { Editor } from '@tiptap/core'

import { preventDefaultToAvoidBlur } from 'utils/handlers'

import { CardLayout } from '../types'
import LayoutBehindIcon from './icons/layout-behind.svg'
import LayoutLeftIcon from './icons/layout-left.svg'
import LayoutRightIcon from './icons/layout-right.svg'
import LayoutTopIcon from './icons/layout-top.svg'

type ChooseLayoutPlaceholderProps = {
  isVisible: boolean
  setLayout: (l: CardLayout) => void
  editor: Editor
} & FlexProps

export const ChooseLayoutPlaceholder: React.FC<ChooseLayoutPlaceholderProps> =
  ({ isVisible, setLayout, editor }) => {
    return (
      <Collapse
        in={isVisible}
        onAnimationComplete={() => {
          editor.commands.refreshBubbleMenu?.()
        }}
      >
        <Stack
          overflow="hidden"
          mx={16}
          mb={12}
          contentEditable={false}
          onMouseDown={preventDefaultToAvoidBlur}
          cursor="default"
        >
          <Text
            color="var(--body-color-muted)"
            fontSize="sm"
            fontFamily="Inter"
            fontWeight="normal"
          >
            Or choose a layout
          </Text>
          <ButtonGroup spacing={1}>
            <IconButton
              aria-label='Add "Image" block'
              h="auto"
              p={1}
              variant="ghost"
              onClick={() => setLayout('top')}
              icon={<Image src={LayoutTopIcon.src} />}
            />
            <IconButton
              aria-label='Add "Image" block'
              h="auto"
              p={1}
              variant="ghost"
              onClick={() => setLayout('left')}
              icon={<Image src={LayoutLeftIcon.src} />}
            />
            <IconButton
              aria-label='Add "Image" block'
              h="auto"
              p={1}
              variant="ghost"
              onClick={() => setLayout('right')}
              icon={<Image src={LayoutRightIcon.src} />}
            />
            <IconButton
              aria-label='Add "Image" block'
              h="auto"
              p={1}
              variant="ghost"
              onClick={() => setLayout('behind')}
              icon={<Image src={LayoutBehindIcon.src} />}
            />
          </ButtonGroup>
        </Stack>
      </Collapse>
    )
  }
