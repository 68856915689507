import { Extension } from '@tiptap/core'

import { SalSelectionPlugin } from './SalSelectionPlugin'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    salExtension: {
      setSalOpen: (open: boolean) => ReturnType
    }
  }
}

export const SalExtension = Extension.create({
  name: 'salExtension',

  addStorage() {
    return {
      salPanelOpen: false,
    }
  },

  addProseMirrorPlugins() {
    return [SalSelectionPlugin(this.editor, this.storage)]
  },

  addCommands() {
    return {
      setSalOpen:
        (open: boolean) =>
        ({ state, dispatch }) => {
          this.storage.salPanelOpen = open
          return true
        },
    }
  },
})
