import { Box, Button, Link, Text } from '@chakra-ui/react'
import { cx } from '@chakra-ui/utils'
import { NodeViewProps } from '@tiptap/core'

import { useAppSelector } from 'modules/redux'
import { CLICKABLE_BOX_CLASS } from 'modules/theming/styles/box'
import {
  HEADING_CLASS,
  HEADING_NO_GRADIENT_CLASS,
} from 'modules/theming/styles/heading'

import { NodeViewContent, NodeViewWrapper } from '../../react'
import { selectContentEditable } from '../../reducer'
import { isFocusedInside } from '../selection/utils'

export const ButtonView = (nodeViewProps: NodeViewProps) => {
  const { node, decorations } = nodeViewProps
  const { href, variant } = node.attrs
  const isEditable = useAppSelector(selectContentEditable)
  const isFocused = isFocusedInside(decorations)
  const isEditing = isEditable && isFocused

  const buttonProps =
    variant === 'solid'
      ? {
          backgroundColor: 'var(--link-color)',
          backgroundImage: 'var(--accent-gradient)',
          border: '0px',
        }
      : {
          border: '0.125em solid var(--link-color)',
          backgroundColor: 'var(--card-color)',
        }

  return (
    <NodeViewWrapper>
      <Button
        as={isEditing ? Box : Link}
        variant="unstyled"
        display="inline-flex"
        cursor={isEditable ? 'text' : 'pointer'}
        userSelect={isEditable ? 'auto' : 'none'}
        href={href}
        style={buttonProps}
        // Reset default link styling
        _hover={{ textDecoration: 'none' }}
        // Force the sizes to scale with present mode
        minW="min(100%, 12em)"
        maxW="100%"
        width={['100%', 'auto']}
        fontSize="1em"
        py="0.5em"
        minH={'2.75em'}
        pl="1em"
        pr="1em"
        className={cx(
          'button',
          CLICKABLE_BOX_CLASS,
          variant === 'solid' && HEADING_NO_GRADIENT_CLASS
        )}
        // Prevents a double transition on font size that's too slow
        transitionProperty="none"
      >
        <Text
          noOfLines={isEditing ? undefined : 1}
          wordBreak="break-all"
          className={HEADING_CLASS}
          style={{
            color:
              variant === 'solid'
                ? 'var(--body-color-inverted)'
                : 'var(--link-color)',
          }}
        >
          <NodeViewContent />
        </Text>
      </Button>
    </NodeViewWrapper>
  )
}
