import { useCallback, useRef, useState } from 'react'

export const useSetSize = () => {
  const containerWrapperRef = useRef<HTMLDivElement>(null)
  const imageRef = useRef<HTMLImageElement>(null)

  const [currentWidth, setCurrentWidth] = useState(0)
  const [currentHeight, setCurrentHeight] = useState(0)
  const [intrinsicAspectRatio, setIntrisicAspectRatio] = useState<
    number | null
  >(null)

  const setSize = useCallback(() => {
    const containerRect = containerWrapperRef.current?.getBoundingClientRect()
    const imageRect = imageRef.current?.getBoundingClientRect()
    if (containerRect && imageRect) {
      const ar = imageRect.width / imageRect.height
      const width = containerRect.width
      const height = containerRect.width / ar
      if (width === 0 || isNaN(width) || height === 0 || isNaN(height)) {
        return
      }

      setCurrentWidth(width)
      setCurrentHeight(height)
      setIntrisicAspectRatio(ar)
    }
  }, [])

  return {
    width: currentWidth,
    height: currentHeight,
    aspectRatio: intrinsicAspectRatio,
    setSize,
    containerWrapperRef,
    imageRef,
  }
}
