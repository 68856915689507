import { Flex } from '@chakra-ui/react'
import { EditorOptions, JSONContent, NodeViewProps } from '@tiptap/core'

import { checkAccessLinkForLoggedOutUser } from 'modules/auth/accesslink_signup'
import { useAppSelector } from 'modules/redux'
import { selectDoc } from 'modules/tiptap_editor/reducer'
import { GraphqlUser, useUserContext } from 'modules/user'

import { DraftComment } from '../../../DraftCommentsExtension/types'
import { CommentSignUp } from '../../Comments/CommentSignUp'
import { CreateTopLevelComment } from '../../CreateTopLevelComment'
import { useDomNodeOffsetFromTop } from '../BlockCommentsButtons/hooks'
import { BaseCommentPopup } from './BaseCommentPopup'

type AddCommentPopupProps = {
  onCancel: () => void
  editor: NodeViewProps['editor']
  getPos: NodeViewProps['getPos']
  onCommentSave: (commentJSON: JSONContent) => void
  draftComment: DraftComment | null
  userCanComment: boolean
  blockAllowsCommenting: boolean
  onCommentDraftUpdate: EditorOptions['onUpdate']
  commentIdToHighlight: string | null
  isMobile: boolean
}
export const AddCommentPopup: React.FC<AddCommentPopupProps> = ({
  onCancel,
  editor,
  commentIdToHighlight,
  userCanComment,
  blockAllowsCommenting,
  getPos,
  draftComment,
  onCommentDraftUpdate,
  onCommentSave,
  isMobile,
}) => {
  const { user } = useUserContext()
  const doc = useAppSelector(selectDoc)
  const accessLink = checkAccessLinkForLoggedOutUser(doc, user)

  const offsetFromTop = useDomNodeOffsetFromTop({
    editor,
    getPos,
  })

  // const docId = doc?.id
  const showSignup = accessLink && !user && blockAllowsCommenting

  // we dont know the position, so wait until useLayoutEffect before rendering
  if (offsetFromTop === null) {
    return null
  }

  const inner = (
    <Flex
      overflowY="auto"
      direction="column"
      className="comments-list"
      p={isMobile || showSignup ? 0 : 4}
    >
      {showSignup && doc && (
        <CommentSignUp
          accessLink={accessLink}
          doc={doc}
          className="comments-sign-up"
          showDivider={false}
        />
      )}

      {userCanComment && (
        <CreateTopLevelComment
          draftComment={draftComment}
          user={user as GraphqlUser}
          showUser={isMobile}
          showBorder={isMobile}
          onCommentDraftUpdate={onCommentDraftUpdate}
          onCommentSave={onCommentSave}
          commentIdToHighlight={commentIdToHighlight}
          alwaysShowButtons={isMobile}
        />
      )}
    </Flex>
  )
  if (isMobile) {
    return inner
  }
  return (
    <BaseCommentPopup offsetFromTop={offsetFromTop}>{inner}</BaseCommentPopup>
  )
}
