import { brightenColor, colorWithOpacity, isColorDark } from 'utils/color'
import { isMobileDevice } from 'utils/deviceDetection'

import { resetHeadingStylesheet } from '../styles/heading'
import { ThemeBase } from '../types'

const BLOCK_BORDER_RADIUS = 'var(--chakra-radii-md)'

export const DefaultThemeBase: ThemeBase = {
  key: 'default',
  name: 'Default',
  description: 'Standard cards with rounded corners and subtle shadows',
  getCSSVars(cardColor) {
    const isDark = isColorDark(cardColor)
    return {
      '--line-thickness': '0.2em',
      // Use a shadow for the border so it always goes outside the card and isn't covered by accent items
      '--card-border-color': isDark
        ? 'var(--chakra-colors-gray-700)'
        : 'var(--chakra-colors-gray-200)',
      '--card-border': `1px solid var(--card-border-color)`,
      '--card-border-shadow': `0 0 0 1px var(--card-border-color)`,
      '--clickable-background-color': colorWithOpacity(
        isDark ? brightenColor(cardColor, 20) : brightenColor(cardColor, -5),
        0.6
      ),
      '--box-border-radius': 'var(--chakra-radii-md)',
    }
  },
  boxSx: {
    border: '1px solid var(--box-border-color)',
    borderRadius: 'var(--box-border-radius)',
  },
  clickableSx: {
    boxShadow: 'md',
    backgroundColor: 'var(--clickable-background-color)',
    '@media print': {
      boxShadow: 'none',
    },
  },
  cardSx: {
    boxShadow: 'var(--chakra-shadows-md), var(--card-border-shadow)',
    borderRadius: isMobileDevice()
      ? 'var(--chakra-radii-md)'
      : 'var(--chakra-radii-xl)',
    backgroundColor: 'var(--card-color)',
    '@media print': {
      boxShadow: 'none',
      border: 'var(--card-border)',
    },
  },
  imageSx: {
    borderRadius: BLOCK_BORDER_RADIUS,
  },
  smartLayoutBoxSx: {
    borderRadius: BLOCK_BORDER_RADIUS,
    backgroundColor: 'var(--accent-color-background)',
    backgroundImage: 'var(--accent-gradient-background)',
    border: '1px solid var(--accent-color-border)',
    // Reset headings to match body color to avoid visual noise
    ...resetHeadingStylesheet('var(--body-color)'),
  },
  smartLayoutContentSx: {
    // Reset headings to match body color to avoid visual noise
    ...resetHeadingStylesheet('var(--body-color)'),
  },
  smartLayoutLineSx: {
    backgroundColor: 'var(--accent-color-border)',
  },
  tableSx: {
    border: '1px solid var(--box-border-color)',
    borderRadius: 'var(--box-border-radius)',
    'tbody tr': {
      _even: {
        backgroundColor: 'blackAlpha.100',
      },
      _odd: {
        backgroundColor: 'whiteAlpha.100',
      },
    },

    td: {
      borderLeft: '1px solid var(--box-border-color)',
      _first: {
        borderLeftWidth: '0',
      },
    },
  },
}
