import { JSONContent } from '@tiptap/core'

import { CommandInfo } from 'modules/tiptap_editor/commands'

import BulletsLg from './thumbnails/BulletsLg.svg'
import BulletsSm from './thumbnails/BulletsSm.svg'
import ImagesWithTextIcons from './thumbnails/ImagesWithTextIcons.svg'
import ImagesWithTextPhotos from './thumbnails/ImagesWithTextPhotos.svg'
import ImagesWithTextTeam from './thumbnails/ImagesWithTextTeam.svg'
import TextBoxes3 from './thumbnails/TextBoxes3.svg'
import TextBoxes4 from './thumbnails/TextBoxes4.svg'
import TimelineHorizontalNumbered from './thumbnails/TimelineHorizontalNumbered.svg'
import TimelineHorizontalTwoSided from './thumbnails/TimelineHorizontalTwoSided.svg'
import TimelineVerticalNumbered from './thumbnails/TimelineVerticalNumbered.svg'
import TimelineVerticalTwoSided from './thumbnails/TimelineVerticalTwoSided.svg'
import { SmartLayoutAttrs } from './types'

type SmartLayoutTemplate = {
  name: string
  key: string
  content: JSONContent & { attrs: SmartLayoutAttrs }
  keywords?: string[]
  previewImage?: CommandInfo['previewImage']
}

export const SmartLayoutTemplates: SmartLayoutTemplate[] = [
  {
    name: 'Vertical timeline (two sided)',
    key: 'timelineVerticalTwoSided',
    previewImage: TimelineVerticalTwoSided,
    content: {
      type: 'smartLayout',
      attrs: {
        variantKey: 'timeline',
        options: {
          cellSize: 5.25,
          numbered: false,
          twoSided: true,
          hasLine: true,
        },
        fullWidthBlock: false,
      },
      content: [
        {
          type: 'smartLayoutCell',
          attrs: {
            options: {},
            width: null,
            label: null,
          },
          content: [
            {
              type: 'heading',
              attrs: {
                horizontalAlign: null,
                level: 3,
              },
              content: [
                {
                  type: 'text',
                  text: 'Vision',
                },
              ],
            },
            {
              type: 'paragraph',
              attrs: {
                horizontalAlign: null,
                fontSize: null,
              },
              content: [
                {
                  type: 'text',
                  text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor',
                },
              ],
            },
          ],
        },
        {
          type: 'smartLayoutCell',
          attrs: {
            options: {},
            width: null,
            label: null,
          },
          content: [
            {
              type: 'heading',
              attrs: {
                horizontalAlign: null,
                level: 3,
              },
              content: [
                {
                  type: 'text',
                  text: 'Mission',
                },
              ],
            },
            {
              type: 'paragraph',
              attrs: {
                horizontalAlign: null,
                fontSize: null,
              },
              content: [
                {
                  type: 'text',
                  text: 'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim ',
                },
              ],
            },
          ],
        },
        {
          type: 'smartLayoutCell',
          attrs: {
            options: {},
            width: null,
            label: null,
          },
          content: [
            {
              type: 'heading',
              attrs: {
                horizontalAlign: null,
                level: 3,
              },
              content: [
                {
                  type: 'text',
                  text: 'Goal',
                },
              ],
            },
            {
              type: 'paragraph',
              attrs: {
                horizontalAlign: null,
                fontSize: null,
              },
              content: [
                {
                  type: 'text',
                  text: 'Sed ut perspiciatis unde omnis iste natus error sit volupta tem accusa ntium eius modi tempora',
                },
              ],
            },
          ],
        },
        {
          type: 'smartLayoutCell',
          attrs: {
            options: {},
            width: null,
            label: null,
          },
          content: [
            {
              type: 'heading',
              attrs: {
                horizontalAlign: null,
                level: 3,
              },
              content: [
                {
                  type: 'text',
                  text: 'Strategy',
                },
              ],
            },
            {
              type: 'paragraph',
              attrs: {
                horizontalAlign: null,
                fontSize: null,
              },
              content: [
                {
                  type: 'text',
                  text: 'Foste natus error sit volupta tem accusa ntium eius modi tempora',
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    name: 'Vertical timeline (numbered)',
    key: 'timelineVerticalNumbered',
    keywords: ['agenda', 'steps'],
    previewImage: TimelineVerticalNumbered,
    content: {
      type: 'smartLayout',
      attrs: {
        variantKey: 'timeline',
        options: {
          cellSize: 5.25,
          numbered: true,
          hasLine: false,
          twoSided: false,
          orientation: 'vertical',
        },
        fullWidthBlock: false,
      },
      content: [
        {
          type: 'smartLayoutCell',
          attrs: {
            options: {},
            width: null,
            label: null,
          },
          content: [
            {
              type: 'heading',
              attrs: {
                horizontalAlign: null,
                level: 3,
              },
              content: [
                {
                  type: 'text',
                  text: 'Vision',
                },
              ],
            },
            {
              type: 'paragraph',
              attrs: {
                horizontalAlign: null,
                fontSize: null,
              },
              content: [
                {
                  type: 'text',
                  text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor',
                },
              ],
            },
          ],
        },
        {
          type: 'smartLayoutCell',
          attrs: {
            options: {},
            width: null,
            label: null,
          },
          content: [
            {
              type: 'heading',
              attrs: {
                horizontalAlign: null,
                level: 3,
              },
              content: [
                {
                  type: 'text',
                  text: 'Mission',
                },
              ],
            },
            {
              type: 'paragraph',
              attrs: {
                horizontalAlign: null,
                fontSize: null,
              },
              content: [
                {
                  type: 'text',
                  text: 'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim ',
                },
              ],
            },
          ],
        },
        {
          type: 'smartLayoutCell',
          attrs: {
            options: {},
            width: null,
            label: null,
          },
          content: [
            {
              type: 'heading',
              attrs: {
                horizontalAlign: null,
                level: 3,
              },
              content: [
                {
                  type: 'text',
                  text: 'Goal',
                },
              ],
            },
            {
              type: 'paragraph',
              attrs: {
                horizontalAlign: null,
                fontSize: null,
              },
              content: [
                {
                  type: 'text',
                  text: 'Sed ut perspiciatis unde omnis iste natus error sit volupta tem accusa ntium eius modi tempora',
                },
              ],
            },
          ],
        },
        {
          type: 'smartLayoutCell',
          attrs: {
            options: {},
            width: null,
            label: null,
          },
          content: [
            {
              type: 'heading',
              attrs: {
                horizontalAlign: null,
                level: 3,
              },
              content: [
                {
                  type: 'text',
                  text: 'Strategy',
                },
              ],
            },
            {
              type: 'paragraph',
              attrs: {
                horizontalAlign: null,
                fontSize: null,
              },
              content: [
                {
                  type: 'text',
                  text: 'Foste natus error sit volupta tem accusa ntium eius modi tempora',
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    name: 'Horizontal timeline (two sided)',
    key: 'timelineHorizontalTwoSided',
    previewImage: TimelineHorizontalTwoSided,
    content: {
      type: 'smartLayout',
      attrs: {
        variantKey: 'timeline',
        options: {
          cellSize: 5.25,
          numbered: false,
          hasLine: true,
          twoSided: true,
          orientation: 'horizontal',
        },
        fullWidthBlock: false,
      },
      content: [
        {
          type: 'smartLayoutCell',
          attrs: {
            options: {},
            width: null,
            label: null,
          },
          content: [
            {
              type: 'heading',
              attrs: {
                horizontalAlign: null,
                level: 3,
              },
              content: [
                {
                  type: 'text',
                  text: 'Vision',
                },
              ],
            },
            {
              type: 'paragraph',
              attrs: {
                horizontalAlign: null,
                fontSize: null,
              },
              content: [
                {
                  type: 'text',
                  text: 'Lorem ipsum dolor sit amet, consectetur do',
                },
              ],
            },
          ],
        },
        {
          type: 'smartLayoutCell',
          attrs: {
            options: {},
            width: null,
            label: null,
          },
          content: [
            {
              type: 'heading',
              attrs: {
                horizontalAlign: null,
                level: 3,
              },
              content: [
                {
                  type: 'text',
                  text: 'Mission',
                },
              ],
            },
            {
              type: 'paragraph',
              attrs: {
                horizontalAlign: null,
                fontSize: null,
              },
              content: [
                {
                  type: 'text',
                  text: 'Excepteur sint occaecat cupidatat non proident',
                },
              ],
            },
          ],
        },
        {
          type: 'smartLayoutCell',
          attrs: {
            options: {},
            width: null,
            label: null,
          },
          content: [
            {
              type: 'heading',
              attrs: {
                horizontalAlign: null,
                level: 3,
              },
              content: [
                {
                  type: 'text',
                  text: 'Goal',
                },
              ],
            },
            {
              type: 'paragraph',
              attrs: {
                horizontalAlign: null,
                fontSize: null,
              },
              content: [
                {
                  type: 'text',
                  text: 'Sed ut perspiciatis unde omnis iste natus error sit ',
                },
              ],
            },
          ],
        },
        {
          type: 'smartLayoutCell',
          attrs: {
            options: {},
            width: null,
            image: null,
            label: null,
          },
          content: [
            {
              type: 'heading',
              attrs: {
                horizontalAlign: null,
                level: 3,
              },
              content: [
                {
                  type: 'text',
                  text: 'Strategy',
                },
              ],
            },
            {
              type: 'paragraph',
              attrs: {
                horizontalAlign: null,
                fontSize: null,
              },
              content: [
                {
                  type: 'text',
                  text: 'Foste natus error sit volupta tem accusa ntium ',
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    name: 'Horizontal timeline (numbered)',
    key: 'timelineHorizontalNumbered',
    previewImage: TimelineHorizontalNumbered,
    content: {
      type: 'smartLayout',
      attrs: {
        variantKey: 'timeline',
        options: {
          cellSize: 5.25,
          numbered: true,
          hasLine: false,
          twoSided: false,
          orientation: 'horizontal',
        },
        fullWidthBlock: false,
      },
      content: [
        {
          type: 'smartLayoutCell',
          attrs: {
            options: {},
            width: null,
            label: null,
          },
          content: [
            {
              type: 'heading',
              attrs: {
                horizontalAlign: null,
                level: 3,
              },
              content: [
                {
                  type: 'text',
                  text: 'Vision',
                },
              ],
            },
            {
              type: 'paragraph',
              attrs: {
                horizontalAlign: null,
                fontSize: null,
              },
              content: [
                {
                  type: 'text',
                  text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor',
                },
              ],
            },
          ],
        },
        {
          type: 'smartLayoutCell',
          attrs: {
            options: {},
            width: null,
            label: null,
          },
          content: [
            {
              type: 'heading',
              attrs: {
                horizontalAlign: null,
                level: 3,
              },
              content: [
                {
                  type: 'text',
                  text: 'Mission',
                },
              ],
            },
            {
              type: 'paragraph',
              attrs: {
                horizontalAlign: null,
                fontSize: null,
              },
              content: [
                {
                  type: 'text',
                  text: 'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim ',
                },
              ],
            },
          ],
        },
        {
          type: 'smartLayoutCell',
          attrs: {
            options: {},
            width: null,

            label: null,
          },
          content: [
            {
              type: 'heading',
              attrs: {
                horizontalAlign: null,
                level: 3,
              },
              content: [
                {
                  type: 'text',
                  text: 'Goal',
                },
              ],
            },
            {
              type: 'paragraph',
              attrs: {
                horizontalAlign: null,
                fontSize: null,
              },
              content: [
                {
                  type: 'text',
                  text: 'Sed ut perspiciatis unde omnis iste natus error sit volupta tem accusa ntium eius modi tempora',
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    name: 'Large bullets',
    key: 'bulletsLg',
    keywords: ['agenda', 'steps'],
    previewImage: BulletsLg,
    content: {
      type: 'smartLayout',
      attrs: {
        variantKey: 'bullets',
        options: {
          cellSize: 14.5,
        },
        fullWidthBlock: false,
      },
      content: [
        {
          type: 'smartLayoutCell',
          attrs: {
            options: {},
            width: null,
            label: null,
          },
          content: [
            {
              type: 'heading',
              attrs: {
                horizontalAlign: null,
                level: 3,
              },
              content: [
                {
                  type: 'text',
                  text: 'Vision',
                },
              ],
            },
            {
              type: 'paragraph',
              attrs: {
                horizontalAlign: null,
                fontSize: null,
              },
              content: [
                {
                  type: 'text',
                  text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor',
                },
              ],
            },
          ],
        },
        {
          type: 'smartLayoutCell',
          attrs: {
            options: {},
            width: null,

            label: null,
          },
          content: [
            {
              type: 'heading',
              attrs: {
                horizontalAlign: null,
                level: 3,
              },
              content: [
                {
                  type: 'text',
                  text: 'Mission',
                },
              ],
            },
            {
              type: 'paragraph',
              attrs: {
                horizontalAlign: null,
                fontSize: null,
              },
              content: [
                {
                  type: 'text',
                  text: 'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim ',
                },
              ],
            },
          ],
        },
        {
          type: 'smartLayoutCell',
          attrs: {
            options: {},
            width: null,
            label: null,
          },
          content: [
            {
              type: 'heading',
              attrs: {
                horizontalAlign: null,
                level: 3,
              },
              content: [
                {
                  type: 'text',
                  text: 'Goal',
                },
              ],
            },
            {
              type: 'paragraph',
              attrs: {
                horizontalAlign: null,
                fontSize: null,
              },
              content: [
                {
                  type: 'text',
                  text: 'Sed ut perspiciatis unde omnis iste natus error sit volupta tem accusa ntium eius modi tempora',
                },
              ],
            },
          ],
        },
        {
          type: 'smartLayoutCell',
          attrs: {
            options: {},
            width: null,
            label: null,
          },
          content: [
            {
              type: 'heading',
              attrs: {
                horizontalAlign: null,
                level: 3,
              },
              content: [
                {
                  type: 'text',
                  text: 'Strategy',
                },
              ],
            },
            {
              type: 'paragraph',
              attrs: {
                horizontalAlign: null,
                fontSize: null,
              },
              content: [
                {
                  type: 'text',
                  text: 'Foste natus error sit volupta tem accusa ntium eius modi tempora',
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    name: 'Small bullets',
    key: 'bulletsSm',
    previewImage: BulletsSm,
    keywords: ['agenda', 'steps'],
    content: {
      type: 'smartLayout',
      attrs: {
        variantKey: 'bullets',
        options: {
          cellSize: 8.5,
        },
        fullWidthBlock: false,
      },
      content: [
        {
          type: 'smartLayoutCell',
          attrs: {
            options: {},
            width: null,
            image: null,
            label: null,
          },
          content: [
            {
              type: 'heading',
              attrs: {
                horizontalAlign: null,
                level: 3,
              },
              content: [
                {
                  type: 'text',
                  text: 'Vision',
                },
              ],
            },
            {
              type: 'paragraph',
              attrs: {
                horizontalAlign: null,
                fontSize: null,
              },
              content: [
                {
                  type: 'text',
                  text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor',
                },
              ],
            },
          ],
        },
        {
          type: 'smartLayoutCell',
          attrs: {
            options: {},
            width: null,
            image: null,
            label: null,
          },
          content: [
            {
              type: 'heading',
              attrs: {
                horizontalAlign: null,
                level: 3,
              },
              content: [
                {
                  type: 'text',
                  text: 'Mission',
                },
              ],
            },
            {
              type: 'paragraph',
              attrs: {
                horizontalAlign: null,
                fontSize: null,
              },
              content: [
                {
                  type: 'text',
                  text: 'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim ',
                },
              ],
            },
          ],
        },
        {
          type: 'smartLayoutCell',
          attrs: {
            options: {},
            width: null,
            image: null,
            label: null,
          },
          content: [
            {
              type: 'heading',
              attrs: {
                horizontalAlign: null,
                level: 3,
              },
              content: [
                {
                  type: 'text',
                  text: 'Goal',
                },
              ],
            },
            {
              type: 'paragraph',
              attrs: {
                horizontalAlign: null,
                fontSize: null,
              },
              content: [
                {
                  type: 'text',
                  text: 'Sed ut perspiciatis unde omnis iste natus error sit volupta tem accusa ntium eius modi tempora',
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    name: '3 boxes',
    key: 'textBoxes3',
    previewImage: TextBoxes3,
    content: {
      type: 'smartLayout',
      attrs: {
        variantKey: 'textBoxes',
        options: {
          cellSize: 12.5,
        },
        fullWidthBlock: false,
      },
      content: [
        {
          type: 'smartLayoutCell',
          attrs: {
            options: {},
            width: null,
            label: null,
          },
          content: [
            {
              type: 'paragraph',
              attrs: {
                horizontalAlign: null,
                fontSize: null,
              },
            },
            {
              type: 'title',
              attrs: {
                horizontalAlign: 'center',
                level: 1,
              },
              content: [
                {
                  type: 'text',
                  text: '2,491',
                },
              ],
            },
            {
              type: 'paragraph',
              attrs: {
                horizontalAlign: 'center',
                fontSize: null,
              },
              content: [
                {
                  type: 'text',
                  text: 'Lorem ipsum dolor sit amet, consectetur adipi',
                },
              ],
            },
          ],
        },
        {
          type: 'smartLayoutCell',
          attrs: {
            options: {},
            width: null,
            label: null,
          },
          content: [
            {
              type: 'paragraph',
              attrs: {
                horizontalAlign: null,
                fontSize: null,
              },
            },
            {
              type: 'title',
              attrs: {
                horizontalAlign: 'center',
                level: 1,
              },
              content: [
                {
                  type: 'text',
                  text: '6,000',
                },
              ],
            },
            {
              type: 'paragraph',
              attrs: {
                horizontalAlign: 'center',
                fontSize: null,
              },
              content: [
                {
                  type: 'text',
                  text: 'Excepteur sint occaecat cupidatat non proident',
                },
              ],
            },
          ],
        },
        {
          type: 'smartLayoutCell',
          attrs: {
            options: {},
            width: null,
            label: null,
          },
          content: [
            {
              type: 'paragraph',
              attrs: {
                horizontalAlign: null,
                fontSize: null,
              },
            },
            {
              type: 'title',
              attrs: {
                horizontalAlign: 'center',
                level: 1,
              },
              content: [
                {
                  type: 'text',
                  text: '96%',
                },
              ],
            },
            {
              type: 'paragraph',
              attrs: {
                horizontalAlign: 'center',
                fontSize: null,
              },
              content: [
                {
                  type: 'text',
                  text: 'Sed ut perspiciatis unde omnis iste natus error ',
                },
              ],
            },
            {
              type: 'paragraph',
              attrs: {
                horizontalAlign: 'center',
                fontSize: null,
              },
            },
          ],
        },
      ],
    },
  },
  {
    name: '4 boxes',
    key: 'textBoxes4',
    previewImage: TextBoxes4,
    keywords: ['callouts', 'numbers', 'matrix', 'quadrant'],
    content: {
      type: 'smartLayout',
      attrs: {
        variantKey: 'textBoxes',
        options: {
          cellSize: 16,
        },
        fullWidthBlock: false,
      },
      content: [
        {
          type: 'smartLayoutCell',
          attrs: {
            options: {},
            width: null,

            label: null,
          },
          content: [
            {
              type: 'heading',
              attrs: {
                horizontalAlign: null,
                level: 3,
              },
              content: [
                {
                  type: 'text',
                  text: 'Vision',
                },
              ],
            },
            {
              type: 'paragraph',
              attrs: {
                horizontalAlign: null,
                fontSize: null,
              },
              content: [
                {
                  type: 'text',
                  text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor',
                },
              ],
            },
          ],
        },
        {
          type: 'smartLayoutCell',
          attrs: {
            options: {},
            width: null,

            label: null,
          },
          content: [
            {
              type: 'heading',
              attrs: {
                horizontalAlign: null,
                level: 3,
              },
              content: [
                {
                  type: 'text',
                  text: 'Mission',
                },
              ],
            },
            {
              type: 'paragraph',
              attrs: {
                horizontalAlign: null,
                fontSize: null,
              },
              content: [
                {
                  type: 'text',
                  text: 'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim ',
                },
              ],
            },
          ],
        },
        {
          type: 'smartLayoutCell',
          attrs: {
            options: {},
            width: null,

            label: null,
          },
          content: [
            {
              type: 'heading',
              attrs: {
                horizontalAlign: null,
                level: 3,
              },
              content: [
                {
                  type: 'text',
                  text: 'Goal',
                },
              ],
            },
            {
              type: 'paragraph',
              attrs: {
                horizontalAlign: null,
                fontSize: null,
              },
              content: [
                {
                  type: 'text',
                  text: 'Sed ut perspiciatis unde omnis iste natus error sit volupta tem accusa ntium eius modi tempora',
                },
              ],
            },
          ],
        },
        {
          type: 'smartLayoutCell',
          attrs: {
            options: {},
            width: null,

            label: null,
          },
          content: [
            {
              type: 'heading',
              attrs: {
                horizontalAlign: null,
                level: 3,
              },
              content: [
                {
                  type: 'text',
                  text: 'Strategy',
                },
              ],
            },
            {
              type: 'paragraph',
              attrs: {
                horizontalAlign: null,
                fontSize: null,
              },
              content: [
                {
                  type: 'text',
                  text: 'Foste natus error sit volupta tem accusa ntium eius modi tempora',
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    name: 'Team photos',
    key: 'imagesWithTextTeam',
    previewImage: ImagesWithTextTeam,
    keywords: ['team', 'images', 'headshot', 'bio', 'roles'],
    content: {
      type: 'smartLayout',
      attrs: {
        variantKey: 'imagesText',
        options: {
          cellSize: 5.75,
        },
        fullWidthBlock: false,
      },
      content: [
        {
          type: 'smartLayoutCell',
          attrs: {
            options: {},
            width: null,
            image: {
              source: 'image.custom',
              uploadStatus: 0,
              showPlaceholder: false,
              src: 'https://cdn.gamma.app/zc87vhr30n8uf3n/c1414815be03462f8862cb80f89f720d/optimized/tesla.jpg',
              meta: {
                width: 820,
                height: 709,
                date_file_modified: '2023/02/07 22:20:08 GMT',
                aspect_ratio: 1.157,
                has_clipping_path: false,
                frame_count: 1,
                colorspace: 'sRGB',
                has_transparency: false,
                average_color: '#828282',
                orientation: 'Horizontal (normal)',
              },
              name: 'tesla.jpg',
            },
            label: null,
          },
          content: [
            {
              type: 'heading',
              attrs: {
                horizontalAlign: null,
                level: 3,
              },
              content: [
                {
                  type: 'text',
                  text: 'Nikola ',
                },
                {
                  type: 'hardBreak',
                },
                {
                  type: 'text',
                  text: 'Tesla',
                },
              ],
            },
            {
              type: 'paragraph',
              attrs: {
                horizontalAlign: null,
                fontSize: 'default',
              },
              content: [
                {
                  type: 'text',
                  marks: [
                    {
                      type: 'bold',
                    },
                  ],
                  text: 'Inventor',
                },
                {
                  type: 'hardBreak',
                },
                {
                  type: 'text',
                  text: '@ Tesla Inc. ',
                },
              ],
            },
          ],
        },
        {
          type: 'smartLayoutCell',
          attrs: {
            options: {},
            width: null,
            image: {
              source: 'image.custom',
              uploadStatus: 0,
              showPlaceholder: false,
              src: 'https://cdn.gamma.app/zc87vhr30n8uf3n/53ab692111294834a286002e3ecfdf2b/optimized/marie-curie.jpg',
              meta: {
                width: 1200,
                height: 1631,
                date_file_modified: '2023/02/07 22:20:11 GMT',
                aspect_ratio: 0.736,
                has_clipping_path: false,
                frame_count: 1,
                colorspace: 'sRGB',
                has_transparency: false,
                orientation: 'Horizontal (normal)',
                average_color: '#3b3b3b',
              },
              name: 'marie-curie.jpg',
            },
            label: null,
          },
          content: [
            {
              type: 'heading',
              attrs: {
                horizontalAlign: null,
                level: 3,
              },
              content: [
                {
                  type: 'text',
                  text: 'Marie ',
                },
                {
                  type: 'hardBreak',
                },
                {
                  type: 'text',
                  text: 'Curie',
                },
              ],
            },
            {
              type: 'paragraph',
              attrs: {
                horizontalAlign: null,
                fontSize: 'default',
              },
              content: [
                {
                  type: 'text',
                  marks: [
                    {
                      type: 'bold',
                    },
                  ],
                  text: 'Physicist and Chemist',
                },
                {
                  type: 'hardBreak',
                },
                {
                  type: 'text',
                  text: '@ Radioactivity',
                },
              ],
            },
          ],
        },
        {
          type: 'smartLayoutCell',
          attrs: {
            options: {},
            width: null,
            image: {
              source: 'image.custom',
              uploadStatus: 0,
              showPlaceholder: false,
              src: 'https://cdn.gamma.app/zc87vhr30n8uf3n/e5d0250f2e054ee3a4c63a8e11f39c36/optimized/thomas-edison.webp',
              meta: {
                width: 360,
                height: 450,
                date_file_modified: '2023/02/07 22:20:22 GMT',
                aspect_ratio: 0.8,
                has_clipping_path: false,
                frame_count: 1,
                colorspace: 'sRGB',
                has_transparency: false,
                average_color: '#606060',
                orientation: 'Horizontal (normal)',
              },
              name: 'thomas edison.webp',
            },
            label: null,
          },
          content: [
            {
              type: 'heading',
              attrs: {
                horizontalAlign: null,
                level: 3,
              },
              content: [
                {
                  type: 'text',
                  text: 'Thomas Edison',
                },
              ],
            },
            {
              type: 'paragraph',
              attrs: {
                horizontalAlign: null,
                fontSize: 'default',
              },
              content: [
                {
                  type: 'text',
                  marks: [
                    {
                      type: 'bold',
                    },
                  ],
                  text: 'Inventor ',
                },
                {
                  type: 'hardBreak',
                },
                {
                  type: 'text',
                  text: '@ Light bulb ',
                },
              ],
            },
          ],
        },
        {
          type: 'smartLayoutCell',
          attrs: {
            options: {},
            width: null,
            image: {
              source: 'image.custom',
              uploadStatus: 0,
              showPlaceholder: false,
              src: 'https://cdn.gamma.app/zc87vhr30n8uf3n/86aa2820df484599b26ab9bbb79737d3/optimized/einstein.jpg',
              meta: {
                width: 500,
                height: 500,
                date_file_modified: '2023/02/07 22:20:26 GMT',
                aspect_ratio: 1,
                has_clipping_path: false,
                frame_count: 1,
                colorspace: 'sRGB',
                has_transparency: false,
                orientation: 'Horizontal (normal)',
                average_color: '#575757',
              },
              name: 'einstein.jpg',
            },
            label: null,
          },
          content: [
            {
              type: 'heading',
              attrs: {
                horizontalAlign: null,
                level: 3,
              },
              content: [
                {
                  type: 'text',
                  text: 'Albert Einstein',
                },
              ],
            },
            {
              type: 'paragraph',
              attrs: {
                horizontalAlign: null,
                fontSize: 'default',
              },
              content: [
                {
                  type: 'text',
                  marks: [
                    {
                      type: 'bold',
                    },
                  ],
                  text: 'Theoretical Physicist',
                },
                {
                  type: 'hardBreak',
                },
                {
                  type: 'text',
                  text: '@ EMC2 ',
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    name: 'Icons with text',
    key: 'imagesWithTextIcons',
    previewImage: ImagesWithTextIcons,
    content: {
      type: 'smartLayout',
      attrs: {
        variantKey: 'imagesText',
        options: {
          cellSize: 5,
        },
        fullWidthBlock: false,
      },
      content: [
        {
          type: 'smartLayoutCell',
          attrs: {
            options: {},
            width: null,
            image: {
              source: 'image.icon',
              src: 'https://cdn.gamma.app/zc87vhr30n8uf3n/e169d76934d642e2999d195fb50cfd88/optimized/mountain.svg',
              tempUrl:
                'https://gamma.app/api/icons/mountain.svg?provider=fa&style=light',
              meta: {
                height: 512,
                width: 512,
                svgViewBoxWidth: 512,
                svgViewBoxHeight: 512,
                date_file_modified: '2023/02/07 21:46:24 GMT',
                aspect_ratio: 1,
                has_clipping_path: false,
                frame_count: 1,
                average_color: '#cbcbcb',
              },
              query: 'mountain',
              providerMeta: {
                name: 'fa',
                id: 'mountain',
              },
              uploadStatus: 0,
              showPlaceholder: false,
              name: 'mountain.svg',
            },
            label: null,
          },
          content: [
            {
              type: 'heading',
              attrs: {
                horizontalAlign: null,
                level: 3,
              },
              content: [
                {
                  type: 'text',
                  text: 'Vision',
                },
              ],
            },
            {
              type: 'paragraph',
              attrs: {
                horizontalAlign: null,
                fontSize: null,
              },
              content: [
                {
                  type: 'text',
                  text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor',
                },
              ],
            },
          ],
        },
        {
          type: 'smartLayoutCell',
          attrs: {
            options: {},
            width: null,
            image: {
              source: 'image.icon',
              src: 'https://cdn.gamma.app/zc87vhr30n8uf3n/1f156e4486744695b40e109958afc3fb/optimized/user-astronaut.svg',
              tempUrl:
                'https://gamma.app/api/icons/user-astronaut.svg?provider=fa&style=light',
              meta: {
                height: 512,
                width: 448,
                svgViewBoxWidth: 448,
                svgViewBoxHeight: 512,
                date_file_modified: '2023/02/07 21:45:45 GMT',
                aspect_ratio: 0.875,
                has_clipping_path: false,
                frame_count: 1,
                average_color: '#9c9c9c',
              },
              query: 'astronaut',
              providerMeta: {
                name: 'fa',
                id: 'user-astronaut',
              },
              uploadStatus: 0,
              showPlaceholder: false,
              name: 'user-astronaut.svg',
            },
            label: null,
          },
          content: [
            {
              type: 'heading',
              attrs: {
                horizontalAlign: null,
                level: 3,
              },
              content: [
                {
                  type: 'text',
                  text: 'Mission',
                },
              ],
            },
            {
              type: 'paragraph',
              attrs: {
                horizontalAlign: null,
                fontSize: null,
              },
              content: [
                {
                  type: 'text',
                  text: 'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim ',
                },
              ],
            },
          ],
        },
        {
          type: 'smartLayoutCell',
          attrs: {
            options: {},
            width: null,
            image: {
              source: 'image.icon',
              uploadStatus: 0,
              showPlaceholder: false,
              tempUrl:
                'https://gamma.app/api/icons/bullseye-arrow.svg?provider=fa&style=light',
              src: 'https://cdn.gamma.app/zc87vhr30n8uf3n/69f7d571bf7d4af1b2aab06149d5ce4d/optimized/bullseye-arrow.svg',
              meta: {
                height: 512,
                width: 512,
                svgViewBoxWidth: 512,
                svgViewBoxHeight: 512,
                date_file_modified: '2023/02/07 21:47:16 GMT',
                aspect_ratio: 1,
                has_clipping_path: false,
                frame_count: 1,
                average_color: '#acacac',
              },
              query: 'goal',
              providerMeta: {
                name: 'fa',
                id: 'bullseye-arrow',
              },
              name: 'bullseye-arrow.svg',
            },
            label: null,
          },
          content: [
            {
              type: 'heading',
              attrs: {
                horizontalAlign: null,
                level: 3,
              },
              content: [
                {
                  type: 'text',
                  text: 'Goal',
                },
              ],
            },
            {
              type: 'paragraph',
              attrs: {
                horizontalAlign: null,
                fontSize: null,
              },
              content: [
                {
                  type: 'text',
                  text: 'Sed ut perspiciatis unde omnis iste natus error sit volupta tem accusa ntium eius modi tempora',
                },
              ],
            },
          ],
        },
        {
          type: 'smartLayoutCell',
          attrs: {
            options: {},
            width: null,
            image: {
              source: 'image.icon',
              src: 'https://cdn.gamma.app/zc87vhr30n8uf3n/ce830d7ed3c543d5b88ecdf6ec6c26ad/optimized/chess-king-piece.svg',
              tempUrl:
                'https://gamma.app/api/icons/chess-king-piece.svg?provider=fa&style=light',
              meta: {
                height: 512,
                width: 256,
                svgViewBoxWidth: 256,
                svgViewBoxHeight: 512,
                date_file_modified: '2023/02/07 22:21:25 GMT',
                aspect_ratio: 0.5,
                has_clipping_path: false,
                frame_count: 1,
                average_color: '#a1a1a1',
              },
              providerMeta: {
                name: 'fa',
                id: 'chess-king-piece',
              },
              query: 'strategy',
              uploadStatus: 0,
              showPlaceholder: false,
              name: 'chess-king-piece.svg',
            },
            label: null,
          },
          content: [
            {
              type: 'heading',
              attrs: {
                horizontalAlign: null,
                level: 3,
              },
              content: [
                {
                  type: 'text',
                  text: 'Strategy',
                },
              ],
            },
            {
              type: 'paragraph',
              attrs: {
                horizontalAlign: null,
                fontSize: null,
              },
              content: [
                {
                  type: 'text',
                  text: 'Foste natus error sit volupta tem accusa ntium eius modi tempora',
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    name: 'Photos with descriptions',
    key: 'imagesWithTextPhotos',
    previewImage: ImagesWithTextPhotos,
    content: {
      type: 'smartLayout',
      attrs: {
        variantKey: 'imagesText',
        options: {
          cellSize: 12,
        },
        fullWidthBlock: false,
      },
      content: [
        {
          type: 'smartLayoutCell',
          attrs: {
            options: {},
            width: null,
            image: {
              source: 'image.unsplash',
              src: 'https://images.unsplash.com/photo-1457459686225-c7db79d4e59f?ixid=MnwxOTgwNzN8MHwxfHNlYXJjaHwxOXx8bW91bnRhaW58ZW58MXx8fHwxNjc1ODA2MDQw&ixlib=rb-4.0.3&w=2592&q=25',
              tempUrl:
                'https://images.unsplash.com/photo-1457459686225-c7db79d4e59f?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxOTgwNzN8MHwxfHNlYXJjaHwxOXx8bW91bnRhaW58ZW58MXx8fHwxNjc1ODA2MDQw&ixlib=rb-4.0.3&q=80&w=200',
              meta: {
                height: 3872,
                width: 2592,
              },
              query: 'mountain',
            },
            label: null,
          },
          content: [
            {
              type: 'heading',
              attrs: {
                horizontalAlign: null,
                level: 3,
              },
              content: [
                {
                  type: 'text',
                  text: 'Vision',
                },
              ],
            },
            {
              type: 'paragraph',
              attrs: {
                horizontalAlign: null,
                fontSize: null,
              },
              content: [
                {
                  type: 'text',
                  text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor',
                },
              ],
            },
          ],
        },
        {
          type: 'smartLayoutCell',
          attrs: {
            options: {},
            width: null,
            image: {
              source: 'image.unsplash',
              src: 'https://images.unsplash.com/photo-1536697246787-1f7ae568d89a?ixid=MnwxOTgwNzN8MHwxfHNlYXJjaHwyfHxhc3Ryb25hdXR8ZW58MXx8fHwxNjc1ODA1OTkz&ixlib=rb-4.0.3&w=2731&q=25',
              tempUrl:
                'https://images.unsplash.com/photo-1536697246787-1f7ae568d89a?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxOTgwNzN8MHwxfHNlYXJjaHwyfHxhc3Ryb25hdXR8ZW58MXx8fHwxNjc1ODA1OTkz&ixlib=rb-4.0.3&q=80&w=200',
              meta: {
                height: 4096,
                width: 2731,
              },
              query: 'astronaut',
            },
            label: null,
          },
          content: [
            {
              type: 'heading',
              attrs: {
                horizontalAlign: null,
                level: 3,
              },
              content: [
                {
                  type: 'text',
                  text: 'Mission',
                },
              ],
            },
            {
              type: 'paragraph',
              attrs: {
                horizontalAlign: null,
                fontSize: null,
              },
              content: [
                {
                  type: 'text',
                  text: 'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim',
                },
              ],
            },
          ],
        },
        {
          type: 'smartLayoutCell',
          attrs: {
            options: {},
            width: null,
            image: {
              source: 'image.unsplash',
              src: 'https://images.unsplash.com/photo-1598091874986-7da3b270fead?ixid=MnwxOTgwNzN8MHwxfHNlYXJjaHwyNHx8Z29hbHxlbnwxfHx8fDE2NzU4MDU4NjQ&ixlib=rb-4.0.3&w=2832&q=25',
              tempUrl:
                'https://images.unsplash.com/photo-1598091874986-7da3b270fead?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxOTgwNzN8MHwxfHNlYXJjaHwyNHx8Z29hbHxlbnwxfHx8fDE2NzU4MDU4NjQ&ixlib=rb-4.0.3&q=80&w=200',
              meta: {
                height: 2832,
                width: 2832,
              },
              query: 'goal',
            },
            label: null,
          },
          content: [
            {
              type: 'heading',
              attrs: {
                horizontalAlign: null,
                level: 3,
              },
              content: [
                {
                  type: 'text',
                  text: 'Goal',
                },
              ],
            },
            {
              type: 'paragraph',
              attrs: {
                horizontalAlign: null,
                fontSize: null,
              },
              content: [
                {
                  type: 'text',
                  text: 'Sed ut perspiciatis unde omnis iste natus error sit volupta tem accusa ntium eius modi tempora',
                },
              ],
            },
          ],
        },
      ],
    },
  },
]
