import { Box, Flex } from '@chakra-ui/react'
import { cx } from '@chakra-ui/utils'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import { IconProvider } from 'modules/media/providers/Icon'
import { HEADING_CLASS } from 'modules/theming/styles/heading'
import { getThemeBase } from 'modules/theming/themeBases'
import { BLOCK_MARGIN } from 'modules/tiptap_editor/styles/constants'

import { getAlignStyles } from '../../HorizontalAlign'
import { AccessoryImage } from '../../media/Image/AccessoryImage'
import { CELL_PADDING, EmptyCellContent } from '../constants'
import { CellSizeOption } from '../options/BlockSizeOption'
import {
  SmartLayoutCellComponent,
  SmartLayoutVariant,
  SmartLayoutWrapperComponent,
} from '../types'

export const ImagesWithTextWrapper: SmartLayoutWrapperComponent = ({
  children,
}) => {
  return (
    <Flex wrap="wrap" direction="row" justify="center" data-selection-ring>
      {children}
    </Flex>
  )
}

export const ImageWithTextCell: SmartLayoutCellComponent = ({
  children,
  image,
  layoutOptions,
  theme,
  ...props
}) => {
  const isHorizontalLayout = layoutOptions.imagePosition == 'left'
  const imageWidth = `${layoutOptions.cellSize}em`
  const base = getThemeBase(theme)

  const isIcon = image?.source == IconProvider.key
  const isTransparent = image?.meta?.has_transparency

  return (
    <Flex
      borderRadius="var(--box-border-radius)"
      gap={isHorizontalLayout ? BLOCK_MARGIN : 0}
      p={CELL_PADDING}
      direction={isHorizontalLayout ? 'row' : 'column'}
      align="center"
      justify="center"
      // Always fill the space, distributing horizontally
      flex={'1 1 100px'}
      data-selection-ring
      data-content-reference
    >
      <Box
        className={cx(isIcon && HEADING_CLASS, 'image')}
        style={{
          width: imageWidth,
        }}
        overflow="hidden"
        sx={
          isTransparent || isIcon
            ? undefined
            : base.smartLayoutImageSx || base.imageSx
        }
      >
        <AccessoryImage image={image} {...props} />
      </Box>
      <Box
        minW={isHorizontalLayout ? '8em' : undefined}
        flex="1"
        sx={getAlignStyles(isHorizontalLayout ? 'left' : 'center')}
        w="100%"
      >
        {children}
      </Box>
    </Flex>
  )
}

export const ImagesWithText: SmartLayoutVariant = {
  key: 'imagesText',
  name: 'Images with text',
  keywords: ['team', 'caption'],
  icon: regular('address-card'),
  options: [CellSizeOption],
  Wrapper: ImagesWithTextWrapper,
  Cell: ImageWithTextCell,
  defaultContent: EmptyCellContent,
  addDirection(options) {
    return options.imagePosition === 'left' ? 'bottom' : 'right'
  },
}
