import { Extension } from '@tiptap/core'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    // updateAttributes namespace is already taken by tiptap core
    updateAttributesAtPos: {
      updateAttributesAtPos: (
        pos: number,
        attrs: Record<string, any>
      ) => ReturnType
    }
  }
}

export const UpdateAttributes = Extension.create({
  name: 'updateAttributesAtPos',

  addCommands() {
    return {
      updateAttributesAtPos:
        (pos, attrs) =>
        ({ tr }) => {
          Object.entries(attrs).forEach(([key, val]) => {
            tr.setNodeAttribute(pos, key, val)
          })
          return true
        },
    }
  },
})
