// Hipster ipsum headings and paragaphs

export const HipsumTitles = [
  'Keffiyeh Kickstarter',
  'Thicc Messenger Bag',
  'Sus Microdosing Semiotics',
  'Authentic Stumptown Skateboards',
  'Blue Bottle Fanny Pack',
  'Humblebrag Mumblecore',
  'Farm-to-Table Four Loko',
  'Venmo Everyday Carry',
  'Banh Mi Distillery',
  'Palo Santo Direct Trade',
  'They Sold Out, Next Level',
  'Polaroid Mustache',
  'Cliche Art Party',
  'Skateboard Vexillologist',
  'Enamel Pin Normcore',
  'Subway Bruh',
  'Put a Goth Bird on It',
  'Lumbersexual Bicycle Rights',
  'Ethical Trust Fund',
  'Shoreditch Post-ironic Mixtape',
  'Official DSA Mixtape',
  'Sartorial Roof Party',
  'Live-Edge Beard Man',
  "We're in a Vibecession",
]

export const HipsumHeadings = [
  "I'm baby fashion dreamcatcher, cardigan poutine",
  'Axe helvetica: street art live-edge, & bespoke',
  'Tumblr pop-up w/ unicorn four loko ascot & taiyaki',
  "Organic 90's tousled readymade narwhal craft beer and cloud bread",
  'Sartorial, DIY biodiesel bitters',
  'Microdosing small batch raw denim jean shorts',
  'Heirloom Vice Magazine scenester crucifix',
  'Fixie ennui. Fixie typewriter. Fixie lo-fi Tumblr',
  'Ethical activated lumbersexual charcoal',
  'DIY fashion axe craft & venmo humblebrag',
  'Slow-carb letterpress wayfarers & ascot meditation taiyaki',
  'Everyday carry distillery swag, Edison bulb and VHS cray biodiesel',
  'Hashtag DSA thundercats in meggings, chillwave offal ascot pabst',
  'Chartreuse schlitz in helvetica cred, next level williamsburg beard bitters',
  'Pinterest kitsch, flexitarian. Letterpress squid affogato snackwave',
  "Listicle kitsch. Iceland gatekeep heirloom, godard locavore 90's",
  'Woke keffiyeh gochujang, chartreuse sriracha, intelligentsia whatever cliche',
  'Hot chicken skateboard; hell of a single-origin coffee, chillwave bruh',
  'Pop-up vexillologist adaptogen farm-to-table DSA ethical DIY glossier.',
  'Hexagon vape wolf drinking readymade, cold-pressed vinegar',
  'Before they sold out, lyft, Stumptown air plant health goth',
  'Church-key mumblecore intelligentsia; squid locavore poke Chia',
]

export const HipsumParagraphs = [
  "I'm baby pitchfork whatever fashion axe JOMO pour-over prism pork belly tote bag sriracha. Hammock cray church-key asymmetrical, unicorn chicharrones raw denim distillery heirloom 3 wolf moon retro. Craft beer palo santo distillery literally, vaporware hexagon migas artisan.",
  'VHS bushwick snackwave gentrify skateboard ramps selvage chicharrones cold-pressed before they sold out beard kinfolk palo santo jean shorts banh mi. Cray portland XOXO knausgaard. Master cleanse big mood hammock, fixie put a bird on it pork belly hella biodiesel mustache heirloom VHS locavore tumeric. Raclette wolf DIY pug humblebrag.',
  'Fixie umami health goth green juice mustache meditation. Coloring book DSA beard, Normcore polaroid sustainable tote bag small batch pitchfork organic. Freegan 8-bit paleo Pok Pok banh mi biodiesel kogi. Synth food truck blue bottle vexillologist lyft. Subway tile readymade paleo Gochujang quinoa. Mustache umami roof party lumbersexual wayfarers XOXO kickstarter meh poutine Normcore whatever try-hard.',
  'Bitters Stumptown keytar microdosing, flexitarian photo booth health goth sriracha cred leggings mlkshk. Direct trade enamel pin readymade gentrify adaptogen artisan ugh irony put a bird on it pug. Portland cray small batch chicharrones banjo pour-over kitsch. Fanny pack succulents hoodie, occupy photo booth fam snackwave plaid kale chips gluten-free craft beer pop-up 3 wolf moon biodiesel. Cornhole messenger bag four dollar toast hell of, Pok Pok green juice Gochujang unicorn portland drinking vinegar fam vibecession.',
  'Yes plz fanny pack poke bushwick unicorn lyft man bun. Stumptown tumblr affogato blue bottle meditation offal pour-over microdosing cornhole truffaut vibecession try-hard PBR&B cold-pressed thundercats. Bitters yr edison bulb snackwave salvia taxidermy plaid direct trade. Copper mug narwhal affogato brunch salvia godard ethical chicharrones. Chicharrones roof party post-ironic, bitters butcher tbh ugh intelligentsia irony flannel pabst viral.',
  'Master cleanse tote bag post-ironic, paleo fam kinfolk yr retro wolf whatever fashion axe humblebrag neutra blog. Vinyl cold-pressed post-ironic JOMO. Pop-up chillwave tumblr organic sus lo-fi adaptogen Williamsburg paleo fanny pack poke man bun raw denim deep v PBR&B. Food truck tousled bicycle rights tonx. ',
  'Ethical post-ironic big mood mumblecore neutra gastropub truffaut, taiyaki everyday carry godard. Selfies Normcore skateboard, microdosing pork belly intelligentsia vape pinterest praxis readymade helvetica deep v portland roof party tousled.',
  'Ennui knausgaard narwhal iPhone listicle DSA bruh humblebrag af. Waistcoat bespoke beard messenger bag, hot chicken shaman vinyl pork belly bushwick glossier. Wayfarers man braid subway tile bodega boys, tacos vegan plaid neutra marfa craft beer tonx bitters gluten-free cornhole sus. ',
  'Taiyaki live-edge Brooklyn craft beer, chillwave sriracha air plant master cleanse fashion axe lumbersexual brunch farm-to-table Gochujang. Semiotics sustainable bruh aesthetic. Intelligentsia humblebrag la croix, praxis ramps taxidermy 3 wolf moon tacos skateboard woke Williamsburg. Adaptogen flexitarian butcher shabby chic.',
  'Adaptogen man braid skateboard mumblecore swag meditation 3 wolf moon portland migas cred single-origin coffee lo-fi biodiesel praxis. Pitchfork dreamcatcher skateboard asymmetrical. Adaptogen gastropub viral cray, hot chicken occupy kale chips poke typewriter master cleanse skateboard JOMO tbh. Ennui sustainable celiac shoreditch sriracha schlitz waistcoat mustache wayfarers thundercats gatekeep. Waistcoat literally adaptogen knausgaard.',
  'Hot chicken raclette shoreditch plaid chillwave everyday carry post-ironic meggings truffaut banjo. Lyft slow-carb cliche, pinterest street art roof party single-origin coffee poutine locavore shabby chic chicharrones. Banjo DIY organic typewriter. Banh mi roof party waistcoat cloud bread sustainable, truffaut swag fit thundercats. Chartreuse tacos trust fund humblebrag, truffaut next level skateboard pickled meh cred tattooed yr beard iPhone chambray. Health goth pinterest cardigan forage wayfarers, waistcoat drinking vinegar bitters chicharrones DIY vaporware. Pabst salvia authentic echo park.',
  "Iceland palo santo literally DIY unicorn cold-pressed banjo tote bag. Roof party hammock venmo, hoodie craft beer banh mi artisan ennui pinterest. Tilde chartreuse fam, banh mi venmo wolf craft beer pug adaptogen mlkshk iPhone green juice. Meh pabst hoodie snackwave irony same. Readymade unicorn tacos man braid raw denim chartreuse pickled health goth praxis farm-to-table cornhole beard master cleanse DSA waistcoat. Knausgaard squid post-ironic, green juice twee tattooed crucifix everyday carry four dollar toast yes plz you probably haven't heard of them 3 wolf moon irony austin.",
  'Vinyl literally hell of, JOMO distillery paleo succulents poke echo park yes plz pop-up lumbersexual XOXO. Cray af organic hot chicken lomo pitchfork kombucha bruh. Cliche lumbersexual woke migas kitsch blue bottle iceland. Irony palo santo man bun, plaid kale chips affogato jianbing praxis kogi cardigan everyday carry PBR&B.',
  'Flexitarian tilde food truck cardigan sus banh mi. Lumbersexual skateboard kale chips aesthetic, tonx selfies butcher shabby chic slow-carb franzen. Neutra bespoke umami, hella Williamsburg bicycle rights humblebrag vegan kinfolk Normcore truffaut before they sold out letterpress. Shoreditch before they sold out bodega boys keytar, PBR&B DIY neutra brunch hot chicken kombucha.',
  'Mumblecore Stumptown readymade health goth meh crucifix prism schlitz fixie praxis. Ethical prism knausgaard vexillologist pork belly, ramps brunch viral. Glossier echo park pitchfork, swag Pok Pok biodiesel hexagon tbh try-hard butcher. Brunch coloring book venmo, gentrify sus semiotics direct trade mustache aesthetic typewriter vexillologist copper mug sartorial asymmetrical succulents. Cliche keffiyeh health goth yuccie same gatekeep waistcoat yr squid iceland. Bodega boys activated charcoal flexitarian gentrify church-key. Street art paleo XOXO bitters activated charcoal letterpress.',
  'Plaid af pork belly salvia taiyaki blog Stumptown, coloring book fixie tumblr Williamsburg prism fanny pack. Food truck kinfolk literally microdosing subway tile chambray. Prism paleo sustainable aesthetic tumblr mumblecore. Iceland VHS Brooklyn, JOMO shabby chic live-edge chia flexitarian chartreuse heirloom. ',
  'Mukbang selvage health goth, semiotics coloring book hashtag cornhole cliche craft beer enamel pin. Disrupt snackwave leggings cornhole distillery tumblr butcher bespoke. Bushwick cred four dollar toast single-origin coffee swag mumblecore post-ironic yuccie fingerstache.',
  'Kale chips jianbing pabst mustache tacos. Photo booth fingerstache lo-fi selvage. Squid narwhal enamel pin drinking vinegar readymade. Plaid blue bottle DIY, brunch cloud bread lumbersexual gastropub kale chips austin deep v chillwave trust fund. Truffaut neutra XOXO YOLO hell of DIY snackwave.',
  'Vape craft beer mukbang JOMO vibecession neutra cornhole XOXO migas crucifix marfa tonx. Banh mi aesthetic paleo wayfarers umami, ennui kickstarter keytar listicle. Disrupt crucifix typewriter, activated charcoal VHS Pok Pok green juice copper mug lo-fi hoodie everyday carry man bun cred cold-pressed letterpress. Vexillologist yuccie enamel pin, woke raclette celiac taiyaki letterpress chicharrones readymade kombucha poke hot chicken butcher. Artisan kinfolk af selvage affogato big mood everyday carry gentrify shaman etsy vibecession adaptogen gluten-free. Everyday carry big mood austin knausgaard forage lyft bushwick Normcore.',
  "Mixtape semiotics kickstarter prism la croix direct trade, tacos poke tumblr live-edge selvage air plant copper mug banh mi. Polaroid yes plz thundercats tousled butcher Stumptown glossier PBR&B. Health goth Brooklyn distillery palo santo, 90's freegan migas bushwick cliche slow-carb Williamsburg vice vaporware hammock farm-to-table. Try-hard tofu tattooed, trust fund big mood umami intelligentsia tilde bespoke woke bushwick tbh butcher. DSA shabby chic paleo chillwave everyday carry cold-pressed selvage yr jean shorts glossier pickled. Seitan pitchfork sartorial flexitarian.",
]

export const HipsumBullets = [
  "I'm baby pitchfork whatever fashion axe JOMO",
  'VHS bushwick snackwave gentrify skateboard ramps selvage chicharrones',
  'Cold-pressed before they sold out beard kinfolk palo santo jean shorts banh mi',
  'Cray portland XOXO knausgaard',
  'Master cleanse big mood hammock, fixie put a bird on it pork belly',
  'Hella biodiesel mustache heirloom VHS locavore tumeric.',
  'Raclette wolf DIY pug humblebrag.',
  'Fixie umami health goth green juice mustache meditation',
  'Coloring book DSA beard, Normcore polaroid sustainable tote',
  'Bag small batch pitchfork organic',
  'Freegan 8-bit paleo Pok Pok banh mi biodiesel kogi.',
  'Synth food truck blue bottle vexillologist',
  'Lyft. Subway tile readymade paleo Gochujang quinoa.',
  'Mustache umami roof party lumbersexual wayfarers XOXO kickstarter meh',
  'Poutine Normcore whatever try-hard.',
  'Bitters Stumptown keytar microdosing, flexitarian photo booth health goth sriracha',
  'Cred leggings mlkshk. Direct trade enamel pin readymade gentrify adaptogen artisan',
  'Ugh irony put a bird on it pug. Portland cray small batch chicharrones banjo pour-over kitsch.',
  'Fanny pack succulents hoodie, occupy photo booth fam snackwave plaid kale',
  'Chips gluten-free craft beer',
  '3 wolf moon biodiesel',
  'Cornhole messenger bag four dollar toast hell of',
  'Pok Pok green juice Gochujang unicorn portland drinking vinegar fam vibecession.',
  'Yes plz fanny pack poke man bun',
  'Master cleanse tote bag post-ironic, paleo fam kinfolk yr retro wolf whatever',
  'Fashion axe',
  'Cold-pressed vinyl',
  'Post-ironic JOMO',
  'Chillwave popup',
  'Sus organics',
  'Bushwick cred four dollar toast single-origin',
  'Coffee swag mumblecore post-ironic yuccie fingerstache.',
  'Kale chips jianbing pabst mustache tacos',
  'Photo booth fingerstache lo-fi selvage. Squid narwhal enamel pin drinking vinegar readymade. Plaid blue bottle DIY, brunch cloud',
  'Bread lumbersexual gastropub kale chips austin deep v chillwave',
  'Vape craft beer mukbang JOMO vibecession neutra cornhole XOXO migas',
  'crucifix marfa tonx. Banh mi aesthetic paleo wayfarers umami',
  'Ennui kickstarter keytar listicle. Disrupt crucifix typewriter',
  'Activated charcoal VHS',
  'Bun cred cold-pressed letterpress. Vexillologist yuccie',
  'Woke raclette celiac taiyaki letterpress chicharrones readymade',
  'Kombucha poke hot chicken butcher',
  'Selvage affogato',
  'Big mood everyday',
  'La croix direct trade',
  'Air plant copper mug',
]
