import { Node } from 'prosemirror-model'
import { EditorState } from 'prosemirror-state'
import { Decoration, DecorationSet } from 'prosemirror-view'

import { SmartLayout } from './SmartLayout'
import {
  SmartLayoutAttrs,
  SmartLayoutCellDecorationSpec,
  SmartLayoutDecorationSpec,
} from './types'

type SmartLayoutCellDecoration = Decoration & {
  spec: SmartLayoutCellDecorationSpec
}

type SmartLayoutDecoration = Decoration & { spec: SmartLayoutDecorationSpec }

export const decorateLayouts = (state: EditorState) => {
  const { doc } = state
  const decorations: Decoration[] = []
  const decorate = (node: Node, pos: number) => {
    if (node.type.name !== SmartLayout.name) {
      return
    }

    // Decorate the layout itself so the Wrapper component can rerender when the number of children changes
    const layoutDeco: SmartLayoutDecorationSpec = {
      isSmartLayoutDecoration: true,
      numCells: node.childCount,
    }
    decorations.push(Decoration.node(pos, pos + node.nodeSize, {}, layoutDeco))

    // Decorate each cell so their node views have access to the parent's attributes/child count, and rerender when they change
    node.forEach((child, offset, index) => {
      const childPos = pos + 1 + offset
      const cellDeco: SmartLayoutCellDecorationSpec = {
        isSmartLayoutCellDecoration: true,
        index,
        numCells: node.childCount,
        smartLayoutAttrs: node.attrs as SmartLayoutAttrs,
      }
      decorations.push(
        Decoration.node(childPos, childPos + child.nodeSize, {}, cellDeco)
      )
    })

    return false // No need to descend further
  }

  doc.descendants(decorate)
  return DecorationSet.create(doc, decorations)
}

export const findSmartLayoutDecoration = (
  decorations: Decoration[]
): SmartLayoutDecorationSpec => {
  const deco = decorations.find(
    (d): d is SmartLayoutDecoration => d.spec.isSmartLayoutDecoration
  )
  if (!deco) {
    console.error(
      '[SmartLayout] Decoration not found. This should never happen!',
      decorations
    )
    return {
      isSmartLayoutDecoration: true,
      numCells: 0,
    }
  }
  return deco.spec
}

export const findSmartLayoutCellDecoration = (
  decorations: Decoration[]
): SmartLayoutCellDecorationSpec => {
  const deco = decorations.find(
    (d): d is SmartLayoutCellDecoration => d.spec.isSmartLayoutCellDecoration
  )
  if (!deco) {
    console.error(
      '[SmartLayoutCell] Decoration not found. This should never happen!',
      decorations
    )
    return {
      isSmartLayoutCellDecoration: true,
      numCells: 0,
      index: 0,
      smartLayoutAttrs: {
        options: {},
        fullWidthBlock: false,
      },
    }
  }
  return deco.spec
}
