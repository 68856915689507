import { Box, Flex } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import { getThemeBase } from 'modules/theming/themeBases'

import { BulletMarker } from '../components/BulletMarker'
import { CELL_PADDING, EmptyCellContent } from '../constants'
import { CellSizeOption } from '../options/BlockSizeOption'
import {
  SmartLayoutCellComponent,
  SmartLayoutVariant,
  SmartLayoutWrapperComponent,
} from '../types'

export const BulletsWrapper: SmartLayoutWrapperComponent = ({ children }) => {
  return (
    <Flex gap="1em" direction="row" wrap="wrap" data-selection-ring>
      {children}
    </Flex>
  )
}

export const BulletsCell: SmartLayoutCellComponent = (props) => {
  const { children, ...markerProps } = props
  const { theme, layoutOptions } = markerProps
  const base = getThemeBase(theme)
  const width = `${layoutOptions.cellSize}em`

  return (
    <Flex
      direction="row"
      align="flex-start"
      gap={CELL_PADDING}
      // Always fill the space, distributing evenly
      flex={'1 1 auto'}
      w={width}
      data-selection-ring
      data-content-reference
    >
      <BulletMarker {...markerProps} alignText></BulletMarker>
      <Box flex="1" sx={base.smartLayoutContentSx}>
        {children}
      </Box>
    </Flex>
  )
}

export const Bullets: SmartLayoutVariant = {
  key: 'bullets',
  name: 'Bullets',
  icon: regular('square-list'),
  options: [CellSizeOption],
  Wrapper: BulletsWrapper,
  Cell: BulletsCell,
  defaultContent: EmptyCellContent,
  htmlTag: 'bullets',
}
