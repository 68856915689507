import { EditorState, PluginKey, Transaction } from 'prosemirror-state'
import * as Y from 'yjs'

import {
  absoluteToRelativePos,
  relativeToAbsolutePos,
} from 'modules/tiptap_editor/utils/relativePosition'

export const CardLayoutResizingPluginKey =
  new PluginKey<CardLayoutResizingState>('cardLayoutResizing')

export type LayoutItemPosAndSide = {
  pos: number
  side: 'left' | 'right'
}

export type DraggingState = {
  startX: number
  startWidth: number
  colWidths: number[]
  colIndex: number
  totalWidth: number
}
export type CardLayoutResizingSetHandleEvent = {
  setHandle: LayoutItemPosAndSide | null
}
export type CardLayoutResizingSetDraggingEvent = {
  setDragging: DraggingState | null
}
export type CardLayoutResizingResetEvent = {
  reset: true
}

type CardLayoutResizingEvent =
  | CardLayoutResizingSetDraggingEvent
  | CardLayoutResizingSetHandleEvent
  | CardLayoutResizingResetEvent

export class CardLayoutResizingState {
  public activeHandle: Y.RelativePosition | null = null

  public side: LayoutItemPosAndSide['side'] | null

  public dragging: DraggingState | null = null

  constructor() {}

  getActiveHandleAbs(state: EditorState): number | null {
    if (!this.activeHandle) {
      return null
    }
    return relativeToAbsolutePos(state, this.activeHandle)
  }

  reset(): this {
    this.activeHandle = null
    this.side = null
    this.dragging = null
    return this
  }

  setHandle(state: EditorState, event: CardLayoutResizingSetHandleEvent): this {
    if (event.setHandle === null) {
      this.activeHandle = null
      this.side = null
      return this
    }
    this.activeHandle = absoluteToRelativePos(state, event.setHandle.pos)
    this.side = event.setHandle.side

    return this
  }

  setDragging(event: CardLayoutResizingSetDraggingEvent): this {
    this.dragging = event.setDragging
    return this
  }

  apply(tr: Transaction, state: EditorState): this {
    const action = tr.getMeta(
      CardLayoutResizingPluginKey
    ) as CardLayoutResizingEvent | null

    if (action && 'setHandle' in action) {
      return this.setHandle(state, action)
    }
    if (action && 'setDragging' in action) {
      return this.setDragging(action)
    }
    if (action && 'reset' in action) {
      return this.reset()
    }

    // for other changes we count on Y.RelativePosition to hold it's position
    // correctly
    return this
  }
}
