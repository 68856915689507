import { Box, Center, Flex, FlexProps, Grid } from '@chakra-ui/react'
import { CSSObject } from '@emotion/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import { getThemeBase } from 'modules/theming/themeBases'
import { useGammaBreakpointValue } from 'utils/breakpoints/useGammaBreakpointValue'

import { getAlignStyles } from '../../HorizontalAlign'
import { BulletMarker } from '../components/BulletMarker'
import { EmptyCellContent } from '../constants'
import { HasLineOption } from '../options/HasLineOption'
import { LinePosition } from '../options/LinePositionOption'
import { NumberedOption } from '../options/NumberedOption'
import { OrientationOption } from '../options/OrientationOption'
import { TwoSidedOption } from '../options/TwoSidedOption'
import {
  SmartLayoutCellComponent,
  SmartLayoutCellProps,
  SmartLayoutVariant,
  SmartLayoutWrapperComponent,
} from '../types'

const TwoSidedBreakpoints = { base: false, md: true }
const HorizontalBreakpoints = { base: false, md: true }

const useTimelineShape = (options) => {
  const roomForTwoSided = useGammaBreakpointValue(TwoSidedBreakpoints) || false
  const roomForHorizontal =
    useGammaBreakpointValue(HorizontalBreakpoints) || false
  const isHorizontal = options.orientation === 'horizontal' && roomForHorizontal
  const isTwoSided = options.twoSided && (isHorizontal || roomForTwoSided)
  return { isHorizontal, isTwoSided }
}

const getMarkerSizeEm = (isNumbered: boolean) => {
  return isNumbered ? 1.5 : 0.5
}

const LINE_LENGTH_EM = 5 // Length of the dashed line leading to cells
const CONTENT_PADDING_EM = 2 // Padding between cells and the line
const MIN_CELL_SIZE_EM = 8

export const TimelineWrapper: SmartLayoutWrapperComponent = ({
  children,
  options,
  theme,
}) => {
  const { isHorizontal, isTwoSided } = useTimelineShape(options)
  const markerSize = getMarkerSizeEm(options.numbered)
  const gridProps = isHorizontal
    ? {
        // Use two rows, each auto scaling to fit their content
        // Leave an empty row between for the line itself
        templateRows: 'auto 0px auto',
        // Columns will have consistent widths
        autoColumns: '1fr',
        justifyContent: 'center',
        columnGap: '1em',
        mt: isTwoSided ? undefined : `${markerSize}em`, // Add padding for the markers on the line
      }
    : {
        templateColumns: isTwoSided ? '1fr 0px 1fr' : '0px 0px 1fr',
        autoRows: 'auto',
        justifyContent: 'center',
        rowGap: '1em',
        ml: isTwoSided ? undefined : `${markerSize}em`, // Add padding for the markers on the line
      }
  const base = getThemeBase(theme)

  const centerLine = isHorizontal ? (
    <Box gridRow={2} data-spotlight-dim>
      <Box
        position="absolute"
        height="var(--line-thickness)"
        width="100%"
        left="0"
        right="0"
        sx={base.smartLayoutLineSx}
      />
    </Box>
  ) : (
    <Center gridColumn={2} data-spotlight-dim>
      <Box
        position="absolute"
        width="var(--line-thickness)"
        top="0"
        bottom="0"
        sx={base.smartLayoutLineSx}
      />
    </Center>
  )

  // How far after the first cell should the second cell start
  const staggerSize = `${MIN_CELL_SIZE_EM / 2}em`
  const staggerProps = isHorizontal
    ? { gridColumn: 'span 1', gridRow: 1, minW: '0.5fr' }
    : { gridColumn: 1, gridRow: 'span 1', minH: staggerSize }

  return (
    <Grid {...gridProps} data-selection-ring>
      {/* Empty first element ensures the two sides are staggered https://stackoverflow.com/a/61781360 */}
      <Box {...staggerProps} contentEditable={false} />
      {/* The line. Uses position: absolute to span the full size  */}
      {centerLine}
      {children}
    </Grid>
  )
}

// Grid layout inspired by https://codepen.io/joemaffei/pen/WNQKyPo
export const TimelineCell: SmartLayoutCellComponent = (cellProps) => {
  const { children, layoutOptions, index, theme } = cellProps
  const base = getThemeBase(theme)
  const num = index + 1
  const { isHorizontal, isTwoSided } = useTimelineShape(layoutOptions)
  const isEven = isTwoSided && num % 2 === 0
  const linePosition: LinePosition = layoutOptions.hasLine ? 'inside' : 'none'
  const markerSize = getMarkerSizeEm(layoutOptions.numbered)

  // The distance between the center line and the content
  const contentDistance = `${
    (linePosition === 'inside' ? LINE_LENGTH_EM : 0) +
    (isHorizontal
      ? linePosition === 'none'
        ? markerSize
        : 0
      : CONTENT_PADDING_EM) // Horizontal cells already have padding from the BLOCK_MARGIN inside the cell
  }em`
  const gridItemProps: FlexProps = isHorizontal
    ? {
        // Take up two columns, alternating between the top and bottom rows
        gridColumn: 'span 2',
        gridRow: isEven ? 1 : 3,
        // Top row content should stick to the bottom, bottom row should stick to top. Center both horizontally
        align: isEven ? 'flex-end' : 'flex-start',
        justify: 'center',
        // Scaling direction in spotlight
        transformOrigin: isEven ? 'center bottom' : 'center top',
        // Distance from line
        pt: !isEven ? contentDistance : undefined,
        pb: isEven ? contentDistance : undefined,
        // With "before" position, text is aligned to the left
        px: `${CONTENT_PADDING_EM}em`,
      }
    : {
        gridRow: 'span 2',
        gridColumn: isEven ? 1 : 3,
        align: 'flex-start',
        transformOrigin: isEven ? 'right center' : 'left center',
        pl: !isEven ? contentDistance : undefined,
        pr: isEven ? contentDistance : undefined,
      }

  const contentProps: CSSObject = isHorizontal
    ? {
        minW: `min(100%, ${MIN_CELL_SIZE_EM}em)`, // Keep a min width, unless it would go wider than the container
        ...getAlignStyles('center'),
        ...base.smartLayoutContentSx,
      }
    : {
        minH: `${MIN_CELL_SIZE_EM}em`,
        ...getAlignStyles(isEven ? 'right' : 'left'),
        ...base.smartLayoutContentSx,
      }

  return (
    <Flex
      minW="0"
      position="relative"
      transitionProperty="border, padding, right, margin"
      data-selection-ring
      data-content-reference
      {...gridItemProps}
      data-test-timeline-item={index}
    >
      <TimelineCellMarker {...cellProps} />
      <Box sx={contentProps} flex="1" maxW="100%">
        {children}
      </Box>
    </Flex>
  )
}

const TimelineCellMarker = (props: SmartLayoutCellProps) => {
  const { index, layoutOptions, theme } = props
  const { isHorizontal, isTwoSided } = useTimelineShape(layoutOptions)
  const isFlipped = isTwoSided && index % 2 === 1
  const linePosition: LinePosition = layoutOptions.hasLine ? 'inside' : 'none'
  const base = getThemeBase(theme)
  const positionProps: FlexProps = isHorizontal
    ? isFlipped
      ? { bottom: 0, transform: 'translateY(50%)' }
      : { top: 0, transform: 'translateY(-50%)' }
    : isFlipped
    ? { right: 0, transform: 'translateX(50%)' }
    : { left: 0, transform: 'translateX(-50%)' }

  // The line on the marker
  const lineLength =
    linePosition === 'none'
      ? 0
      : linePosition === 'inside'
      ? `${LINE_LENGTH_EM}em`
      : '100%'
  const lineProps: FlexProps = isHorizontal
    ? {
        height: lineLength,
        width: 'var(--line-thickness)',
        left: '50%',
        top: isFlipped ? undefined : '50%',
        bottom: isFlipped ? '50%' : undefined,
        transform: 'translateX(-50%)',
      }
    : {
        width: lineLength,
        height: 'var(--line-thickness)',
        top: '50%',
        transform: 'translateY(-50%)',
        left: isFlipped ? undefined : '100%',
        right: isFlipped ? '100%' : undefined,
      }

  return (
    <BulletMarker
      {...props}
      alignText={!isHorizontal}
      positionProps={{
        ...positionProps,
        position: 'absolute',
      }}
    >
      <Box
        position="absolute"
        zIndex="-1"
        {...lineProps}
        sx={base.smartLayoutLineSx}
      />
    </BulletMarker>
  )
}

export const Timeline: SmartLayoutVariant = {
  key: 'timeline',
  name: 'Timeline',
  icon: regular('timeline-arrow'),
  options: [OrientationOption, TwoSidedOption, NumberedOption, HasLineOption],
  Wrapper: TimelineWrapper,
  Cell: TimelineCell,
  defaultContent: EmptyCellContent,
  addDirection(options) {
    return options.orientation === 'horizontal' ? 'right' : 'bottom'
  },
  isFullWidth(options) {
    return options.orientation === 'horizontal'
  },
  htmlTag: 'timeline',
}
