import { cx } from '@chakra-ui/utils'
import { Plugin } from 'prosemirror-state'
import { Decoration, DecorationSet } from 'prosemirror-view'

import { isCardLayoutItemNode, isCardNode } from '../Card/utils'
import { isBlockFullWidth } from './utils'

type BlockWidthDecorationSpec = {
  isBlockWidthDecoration: true
  isFullWidth: boolean
}

type BlockWidthDecoration = Decoration & {
  spec: BlockWidthDecorationSpec
}

export const BlockWidthPlugin = () =>
  new Plugin({
    props: {
      decorations({ doc }) {
        const decorations: Decoration[] = []

        doc.descendants((node, pos, parent) => {
          if (!parent || !isCardNode(node)) {
            return
          }

          // Only allow full width blocks if the card layout supports it
          const hasLayout =
            node.firstChild && isCardLayoutItemNode(node.firstChild)
          const layoutAllowsFullWidth = hasLayout && node.childCount === 1
          if (hasLayout && !layoutAllowsFullWidth) {
            return
          }

          // Loop over the card's immediate children
          let hasFullWidthChildren = false

          const contentParent = hasLayout ? node.firstChild : node
          const contentOffset = hasLayout ? 2 : 1
          contentParent.forEach((child, offset) => {
            const isFullWidth = isBlockFullWidth(child)
            if (isFullWidth) {
              hasFullWidthChildren = true
            }
            const childPos = pos + offset + contentOffset
            const decoration = Decoration.node(
              childPos,
              childPos + child.nodeSize,
              {
                class: cx(isFullWidth && 'is-full-width'),
              },
              {
                isBlockWidthDecoration: true,
                isFullWidth,
              }
            )
            decorations.push(decoration)
          })
          if (!hasFullWidthChildren) {
            return
          }

          // If a card has full width children, it should go full width too
          const decoration = Decoration.node(
            pos,
            pos + node.nodeSize,
            {
              class: 'is-full-width',
            },
            {
              isBlockWidthDecoration: true,
              isFullWidth: true,
            }
          )
          decorations.push(decoration)
        })
        return DecorationSet.create(doc, decorations)
      },
    },
  })

export const findBlockWidthDecoration = (
  decorations: Decoration[]
): Partial<BlockWidthDecorationSpec> => {
  return (
    decorations.find(
      (d): d is BlockWidthDecoration => d.spec.isBlockWidthDecoration
    )?.spec || {}
  )
}
