import { Box } from '@chakra-ui/react'
import { cx } from '@chakra-ui/utils'
import { NodeViewProps } from '@tiptap/core'
import { Node } from 'prosemirror-model'

import { Browser } from 'modules/browser'
import { useAppSelector } from 'modules/redux'
import { stopPropagation } from 'utils/handlers'

import { selectEditable } from '../../../reducer'
import { isFocusedAndEditable } from '../../selection/FocusedNodes'
import { VideoEmbedAttrs } from '../types'
import { getVideoProvider } from './utils'

const DEFAULT_ASPECT = 16 / 9

type VideoPlayerProps = {
  isZoomed?: boolean
  node: Node
  decorations: NodeViewProps['decorations']
}
export const VideoPlayer = ({
  isZoomed,
  ...nodeViewProps
}: VideoPlayerProps) => {
  const { node, decorations } = nodeViewProps
  const { embed, source, sourceUrl, displayStyle } =
    node.attrs as VideoEmbedAttrs
  const isFocused = isFocusedAndEditable(decorations)
  const isEditable = useAppSelector(selectEditable)

  // Some players have a fixed height (e.g. Spotify)
  const useFixedHeight = embed?.height && !embed?.width
  // But most expect a fixed aspect ratio (e.g. YouTube)
  const aspectRatio =
    embed?.height && embed?.width
      ? embed.width / embed.height
      : embed?.aspectRatio || DEFAULT_ASPECT
  const sizerProps = isZoomed
    ? {
        // Fill the viewport while maintaining aspect ratio
        width: `min(var(--media-maxW), calc(var(--media-maxH) * ${aspectRatio}))`,
        height: useFixedHeight
          ? embed.height
          : `min(var(--media-maxH), calc(var(--media-maxW) / ${aspectRatio}))`,
      }
    : useFixedHeight
    ? { height: embed.height }
    : // Set the height of the container by filling the width using a fixed aspect ratio. Padding is based on width, so padding-bottom set to a percent creates a fixed aspect ratio height.
      { height: 0, pb: `${100 / aspectRatio}%` }

  // Prefer the embed.url, but fallback to support legacy code
  const urlToUse = embed?.url || node.attrs.embedUrl || sourceUrl
  const provider = getVideoProvider(source)
  const embedUrl =
    provider && provider.rewriteEmbedUrl && urlToUse
      ? provider.rewriteEmbedUrl(urlToUse)
      : urlToUse

  return (
    <Box
      position="relative"
      data-drag-handle
      {...sizerProps}
      // Stop clicks in the player from closing the overla
      onClick={isZoomed ? stopPropagation : undefined}
    >
      <Box
        position="absolute"
        inset={0}
        zIndex={1} // Capture clicks when the video is not selected and you're editing
        pointerEvents={
          isEditable && !isFocused && displayStyle === 'inline' && !isZoomed
            ? 'auto'
            : 'none'
        }
        cursor="default"
      />
      {/* Render the video player to fill the parent box */}
      <Browser
        initialUrl={embedUrl}
        backgroundColor="none"
        borderRadius="var(--box-border-radius)"
        overflow="hidden"
        data-selection-ring="inside"
        data-selection-background
        className={cx('video-player')}
        iframeOptions={{
          preventScroll: true,
          ...provider.iframeOptions,
        }}
        position="absolute"
        inset={0}
      />
    </Box>
  )
}
