import { mergeAttributes, Node } from '@tiptap/core'

import { ReactNodeViewRenderer } from '../../react'
import { attrsOrDecorationsChanged } from '../updateFns'
import { CalloutBoxView } from './CalloutBoxView'

export const CalloutBox = Node.create({
  name: 'calloutBox',
  group: 'cardBlock layoutBlock footnoteBlock',
  content: '(block | calloutBlock)+',
  selectable: true,
  isolating: true,
  containerHandle: true,

  addAttributes() {
    return {
      variant: {
        default: 'note',
      },
      icon: {
        default: true,
      },
    }
  },

  addNodeView() {
    return ReactNodeViewRenderer(CalloutBoxView, {
      update: attrsOrDecorationsChanged,
    })
  },

  parseHTML() {
    return [
      {
        tag: 'aside',
      },
    ]
  },
  renderHTML({ HTMLAttributes }) {
    return [
      'aside',
      mergeAttributes(HTMLAttributes, { class: 'calloutBox' }),
      0,
    ]
  },
})
