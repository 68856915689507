import { createSlice } from '@reduxjs/toolkit'
import { JSONContent } from '@tiptap/core'

import { RootState } from 'modules/redux'

export type SuggestionVariant = {
  type: string // The prompt we used
  card: JSONContent // On edit, we could just update this value
  imageUrls: string[] // What we're querying unsplash with
}

export type CardSuggestionMap = Record<
  string, // CardId
  {
    selected: string // Which variant is selected
    variants: SuggestionVariant[] // All variants
  }
>

export type CardSuggestionState = {
  cardSuggestionMap: CardSuggestionMap
}

const initialState: CardSuggestionState = {
  cardSuggestionMap: {},
}

const CardSuggestionEditorSlice = createSlice({
  name: 'CardSuggestionEditor',
  initialState,
  reducers: {
    reset: () => initialState,
    addCardVariant(
      state,
      action: { payload: { cardId: string; variant: SuggestionVariant } }
    ) {
      const { cardId, variant } = action.payload
      if (state.cardSuggestionMap[cardId]) {
        state.cardSuggestionMap[cardId].variants.push(variant)
      }
    },
    setSuggestionCardMap(
      state,
      action: { payload: { partialSuggestionCardMap: CardSuggestionMap } }
    ) {
      const { partialSuggestionCardMap } = action.payload
      state.cardSuggestionMap = {
        ...state.cardSuggestionMap,
        ...partialSuggestionCardMap,
      }
    },
  },
})

export const { reset, setSuggestionCardMap, addCardVariant } =
  CardSuggestionEditorSlice.actions

type CardSuggestionEditorSliceState = Pick<RootState, 'CardSuggestionEditor'>

// Selectors

export const selectSuggestionCardMap = (
  state: CardSuggestionEditorSliceState
) => state.CardSuggestionEditor.cardSuggestionMap

export const selectSuggestionCardMapCard =
  (cardId: string) => (state: CardSuggestionEditorSliceState) =>
    state.CardSuggestionEditor.cardSuggestionMap[cardId]

export const selectSuggestionCardSelectedVariant =
  (cardId: string) => (state: CardSuggestionEditorSliceState) => {
    const data = selectSuggestionCardMapCard(cardId)(state)
    if (!data) return null
    const { selected, variants } = data
    return variants.find((variant) => variant.type === selected)
  }

// Reducer
export const reducer = CardSuggestionEditorSlice.reducer
