import { IconProp } from '@fortawesome/fontawesome-svg-core'

import { CommandInfo } from 'modules/tiptap_editor/commands/types'
import { setDraggingContent } from 'modules/tiptap_editor/commands/utils'
import { openMediaDrawer } from 'modules/tiptap_editor/components/drawers/MediaDrawer/utils'

import { MediaSourcesMap } from './MediaSources'

export const MediaCommands: CommandInfo[] = Object.entries(MediaSourcesMap)
  .filter(([_, source]) => source.nodeName && !source.disabled)
  .map(([key, source]) => {
    return {
      key: key,
      name: source.label,
      nodeName: source.nodeName || 'mediaPlaceholder',
      execute: (editor) => editor.commands.insertMediaBlock(source.key),
      dragStartFn: (editor) => {
        setDraggingContent(editor, {
          type: 'mediaPlaceholder',
          attrs: {
            source: source.key,
          },
        })
      },
      dragEndFn: () => {
        openMediaDrawer()
      },
      description: source.description,
      icon: source.icon as IconProp,
      image: source.image,
      testId: source.key,
      keywords: source.keywords,
      featureFlag: source.featureFlag,
      priority: source.priority,
    }
  })
