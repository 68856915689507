import { createContextualCan, useAbility as useCaslAbility } from '@casl/react'
import {
  AbilityFactory,
  Actions,
  AppAbility,
  Subjects,
} from '@gammatech/authorization'
import { createContext } from 'react'

import { ExistingWorkspace } from 'modules/api'

import { useUserContext } from './UserContext'

export const abilityFactory = new AbilityFactory()

export const AbilityContext = createContext<AppAbility>(
  abilityFactory.createForUser()
)

export const Can = createContextualCan(AbilityContext.Consumer)

export function useAbility() {
  const ability = useCaslAbility<AppAbility>(AbilityContext)
  return ability
}

export function useCan(can: Actions, on?: Subjects) {
  const ability = useCaslAbility<AppAbility>(AbilityContext)
  if (!on) return false
  return ability.can(can, on)
}

export function useCanManageWorkspace() {
  const { currentWorkspace } = useUserContext()

  return useCan('manage', {
    ...(currentWorkspace as ExistingWorkspace),
    __typename: 'Organization',
  })
}
