import { round } from 'lodash'
import { Node, ResolvedPos } from 'prosemirror-model'
import { EditorView } from 'prosemirror-view'

import { rebalanceColWidths } from 'modules/tiptap_editor/extensions/tables/prosemirror-table/columnUtils'
import { findParentNodes } from 'modules/tiptap_editor/utils'
import { dispatchContainerResizeEvent } from 'utils/hooks/useContainerResizing'

import { CardAttributes, CardLayout } from '../../types'
import { isCardLayoutItemNode, isCardNode } from '../../utils'
import { findLayoutPreset } from '../cardLayoutUtils'
import { DraggingState, LayoutItemPosAndSide } from './CardLayoutResizingState'
import {
  getCardLayoutResizingState,
  resetResizingState,
  setDragging,
  updateHandle,
} from './commands'

const HANDLE_WIDTH = 5
export const COL_MIN_PERCENT = 25

export const handleMouseMove = (view: EditorView, event: MouseEvent) => {
  // dont handle mouse move stuff when the doc is not editable
  if (!view.editable || !event.target) {
    return
  }
  if ((event.target as HTMLElement).closest('.column-resize-handle')) {
    return
  }
  const pluginState = getCardLayoutResizingState(view)

  // no op if already dragging
  if (pluginState.dragging) {
    return
  }

  const itemPosAndSide: LayoutItemPosAndSide | null = findHoveringLayoutItem(
    view,
    event
  )

  const activeHandle = pluginState.getActiveHandleAbs(view.state)
  const side = pluginState.side
  if (
    (activeHandle === null && itemPosAndSide === null) ||
    (itemPosAndSide &&
      itemPosAndSide.side === side &&
      itemPosAndSide.pos === activeHandle)
  ) {
    // layoutItem is already the active resize handle, noop
    return
  }

  updateHandle(view, itemPosAndSide)
}

function findHoveringLayoutItem(
  view: EditorView,
  event: MouseEvent
): LayoutItemPosAndSide | null {
  const found = view.posAtCoords({ left: event.clientX, top: event.clientY })
  if (!found || found.inside === -1) {
    return null
  }

  const dom = view.domAtPos(found.inside)
  if (!dom) {
    return null
  }
  const foundDomNode = dom.node.childNodes[dom.offset] as HTMLElement
  if (!foundDomNode) {
    return null
  }

  const $layoutItem = view.state.doc.resolve(found.inside)
  if (!$layoutItem.nodeAfter || !isCardLayoutItemNode($layoutItem.nodeAfter)) {
    return null
  }
  const parentCardNode = findParentNodes($layoutItem, isCardNode)[0]
  if (!parentCardNode) {
    return null
  }
  const preset = findLayoutPreset(parentCardNode.node.attrs.layout)
  if (!preset.allowResizing) {
    return null
  }

  const { left, right } = foundDomNode.getBoundingClientRect()

  if (event.clientX - left <= HANDLE_WIDTH) {
    // is on left side
    const moreLeft = view.posAtCoords({
      left: event.clientX - 2 * HANDLE_WIDTH,
      top: event.clientY,
    })
    if (!moreLeft) {
      return null
    }
    const moreLeftNode = view.state.doc.nodeAt(moreLeft.inside)

    // if we are on the left edge of the left most item
    if (!moreLeftNode || !isCardLayoutItemNode(moreLeftNode)) {
      return null
    }

    return moreLeftNode.type.name === 'cardLayoutItem'
      ? { pos: moreLeft!.inside, side: 'right' }
      : { pos: $layoutItem.pos, side: 'left' }
  } else if (right - event.clientX <= HANDLE_WIDTH) {
    // is on right side
    const moreRight = view.posAtCoords({
      left: event.clientX + 2 * HANDLE_WIDTH,
      top: event.clientY,
    })
    if (!moreRight) {
      return null
    }
    const moreRightNode = view.state.doc.nodeAt(moreRight.inside)

    // if we are on the left edge of the left most item
    if (!moreRightNode || !isCardLayoutItemNode(moreRightNode)) {
      return null
    }
    return moreRightNode.type.name === 'cardLayoutItem'
      ? { pos: moreRight!.inside, side: 'left' }
      : { pos: $layoutItem.pos, side: 'right' }
  }

  return null
}

const currentColWidth = (view: EditorView, pos: number) => {
  const dom = view.domAtPos(pos)
  const childNode = dom.node.childNodes[dom.offset] as HTMLElement
  return childNode.offsetWidth
}

/**
 * Gets layout dom element from any resolved position at layout or descendent of layout
 */
const getCardElement = (view: EditorView, $pos: ResolvedPos): HTMLElement => {
  const { node, offset } = view.domAtPos($pos.start())
  let el: HTMLElement = node.childNodes[offset] as HTMLElement
  do {
    if (el && el.classList && el.classList.contains('node-card')) {
      return el
    }
  } while ((el = el.parentNode! as HTMLElement))

  return el
}

export function handleMouseDown(view: EditorView, event) {
  // dont handle mouse move stuff when the doc is not editable
  if (!view.editable) {
    return
  }
  const pluginState = getCardLayoutResizingState(view)!
  const activeHandle = pluginState.getActiveHandleAbs(view.state)
  if (activeHandle === null || pluginState.dragging) {
    return false
  }

  const $layoutItem = view.state.doc.resolve(activeHandle)
  // parent card
  const card = $layoutItem.node()
  const cardDomNode = getCardElement(view, $layoutItem)
  const layoutControlNode = cardDomNode.querySelector(
    '[data-node-view-content-inner="card"]'
  ) as HTMLElement | null
  if (!layoutControlNode) {
    return
  }

  const side = pluginState.side
  const totalWidth = layoutControlNode.offsetWidth
  const width = currentColWidth(view, activeHandle)
  const colWidths = getGridTemplateWidths(card, cardDomNode, card.attrs.layout)
  const colIndex = 0
  if (!colWidths) {
    return
  }

  setDragging(view, {
    startX: event.clientX,
    startWidth: width,
    colWidths,
    totalWidth,
    colIndex,
  })
  let resizingColWidths: number[] | null = null

  function finish() {
    window.removeEventListener('mouseup', finish)
    window.removeEventListener('mousemove', move)
    const pluginState = getCardLayoutResizingState(view)!

    if (!pluginState.dragging) {
      // never started dragging
      return
    }

    if (resizingColWidths === null) {
      // started click / drag but did not actually move
      setDragging(view, null)
      return
    }

    if (pluginState.dragging) {
      try {
        const activeHandle = pluginState.getActiveHandleAbs(view.state)
        dispatchUpdatedColWidths(view, activeHandle!, resizingColWidths)
        setDragging(view, null)
      } catch (e) {
        // reset resize plugin state if the layout gets deleted.
        resetResizingState(view)
      }
    }

    resizingColWidths = null
  }

  function move(event: MouseEvent) {
    if (!event.which) {
      return finish()
    }
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const pluginState = getCardLayoutResizingState(view)!
    const activeHandle = pluginState.getActiveHandleAbs(view.state)
    if (!pluginState.dragging || activeHandle === null) {
      return
    }
    const { colWidths } = pluginState.dragging
    const percentChange = draggedPercentChange(
      pluginState.dragging,
      event,
      0.1 // precision
    )

    // snap
    const currWidth = colWidths[colIndex]
    const snapTo = side === 'left' ? [25, 37.5, 50] : [50, 62.5, 75]
    const snap: number =
      currWidth + percentChange < snapTo[0]
        ? snapTo[0]
        : currWidth + percentChange > snapTo[snapTo.length - 1]
        ? snapTo[snapTo.length - 1]
        : snapTo.find(
            (a) => Math.abs(a - currWidth - percentChange) <= 12.5 / 2
          )!

    resizingColWidths = rebalanceColWidths(
      colWidths,
      colIndex,
      snap - currWidth,
      COL_MIN_PERCENT
    )

    displayColumnWidth(cardDomNode, resizingColWidths)
    // dispatch resize here to trigger the ImageView useResizeable to update
    // the bubble menu / image resize handles
    dispatchContainerResizeEvent(cardDomNode)
  }

  window.addEventListener('mouseup', finish)
  window.addEventListener('mousemove', move)
  event.preventDefault()
  return true
}

function dispatchUpdatedColWidths(
  view: EditorView,
  layoutItemPos: number,
  colWidths: number[]
) {
  const $layoutItem = view.state.doc.resolve(layoutItemPos)!
  const cardPos = $layoutItem.before()
  const colWidthsString = colWidths.map((a) => `${a}%`).join(' ')
  const tr = view.state.tr.setNodeAttribute(
    cardPos,
    'layoutTemplateColumns',
    colWidthsString
  )
  view.dispatch(tr)

  removeDisplayColumnWidth(view, layoutItemPos)
}

function draggedPercentChange(
  dragging: DraggingState,
  event,
  roundTo = 1
): number {
  const { totalWidth } = dragging
  const offset = event.clientX - dragging.startX
  // round by 5

  const val = (100 * offset) / totalWidth
  return round(val / roundTo, 0) * roundTo
}

function displayColumnWidth(cardEl: HTMLElement, colWidths: number[]) {
  const controls = cardEl.querySelector(
    '[data-node-view-content-inner="card"]'
  )! as HTMLElement
  if (!controls) {
    return
  }
  controls.style.gridTemplateColumns = colWidths.map((w) => `${w}%`).join(' ')
}

function removeDisplayColumnWidth(view: EditorView, layoutItemPos: number) {
  const $layoutItem = view.state.doc.resolve(layoutItemPos)
  const cardEl = getCardElement(view, $layoutItem)
  const controls = cardEl.querySelector(
    '[data-node-view-content-inner="card"]'
  )! as HTMLElement
  if (!controls) {
    return
  }
  controls.style.gridTemplateColumns = ''
}

export function handleMouseLeave(view: EditorView) {
  const pluginState = getCardLayoutResizingState(view)
  const activeHandle = pluginState.getActiveHandleAbs(view.state)

  if (activeHandle !== null && !pluginState.dragging) {
    updateHandle(view, null)
  }
}

const getGridTemplateWidths = (
  card: Node,
  cardEl: HTMLElement,
  cardLayout: CardLayout
): number[] | null => {
  const layout = findLayoutPreset(cardLayout)
  const controls = cardEl.querySelector(
    '[data-node-view-content-inner="card"]'
  ) as HTMLElement | null

  const cardAttrs = card.attrs as CardAttributes
  const gridTemplateColumns =
    controls?.style.gridTemplateColumns ||
    cardAttrs.layoutTemplateColumns ||
    (layout.grid.gridTemplateColumns as string)

  if (!gridTemplateColumns) {
    return null
  }

  const colWidthsRaw = gridTemplateColumns.split(' ')
  if (!colWidthsRaw.length) {
    return null
  }

  const hasFr = colWidthsRaw[0].includes('fr')

  let colWidths: number[] = []

  if (hasFr) {
    const colWidthsParsed = colWidthsRaw.map((col) => +col.replace('fr', ''))
    const total = colWidthsParsed.reduce((acc, cur) => acc + cur, 0)
    colWidths = colWidthsParsed.map((col) => (col / total) * 100)
  } else {
    colWidths = colWidthsRaw.map((col) => +col.replace('%', ''))
  }
  return colWidths
}
