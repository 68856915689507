import { Editor } from '@tiptap/core'
import { useCallback } from 'react'

import { useAppDispatch } from 'modules/redux'
import {
  endCropping,
  eventEmitter,
  setClipType,
  startCropping,
} from 'modules/tiptap_editor/extensions/media/Image'

import { ResizeAttrs } from '../types'

export const useCroppingControls = (editor: Editor) => {
  const dispatch = useAppDispatch()

  const setCropClipType = useCallback(
    ({ clipType }: { clipType: ResizeAttrs['clipType'] }) => {
      if (!clipType) {
        return
      }
      dispatch(setClipType({ clipType }))
    },
    [dispatch]
  )

  const reset = useCallback(
    ({ id }: { id: string }) => {
      eventEmitter.emit('endClip', { id, confirm: false })
      dispatch(endCropping())
      editor.commands.resetImageClip()
    },
    [dispatch, editor]
  )

  const cancel = useCallback(
    ({ id }: { id: string }) => {
      eventEmitter.emit('endClip', { id, confirm: false })
      dispatch(endCropping())
    },
    [dispatch]
  )

  const confirm = useCallback(
    ({ id }: { id: string }) => {
      eventEmitter.emit('endClip', { id, confirm: true })
      dispatch(endCropping())
    },
    [dispatch]
  )

  const start = useCallback(
    ({
      id,
      clipType,
    }: {
      id: string
      clipType?: ResizeAttrs['clipType'] | null
    }) => {
      dispatch(
        startCropping({
          id,
          clipType,
        })
      )
      eventEmitter.emit('startCrop', { id })
    },
    [dispatch]
  )

  return {
    cancelCrop: cancel,
    startCrop: start,
    confirmCrop: confirm,
    resetCrop: reset,
    setCropClipType,
  }
}
