import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import { ToolbarButton } from 'modules/tiptap_editor/components/menus/buttons/ToolbarButton'

import { SmartLayoutOption, SmartLayoutOptionControlProps } from '../types'

const HasLineOptionControl = ({
  value,
  updateValue,
}: SmartLayoutOptionControlProps<boolean>) => {
  return (
    <ToolbarButton
      label="Show line?"
      onClick={() => updateValue(!value)}
      isActive={value}
      icon={regular('hyphen')}
    />
  )
}

export const HasLineOption: SmartLayoutOption<boolean> = {
  key: 'hasLine',
  defaultValue: true,
  Control: HasLineOptionControl,
}
