import { Workbox } from 'workbox-window'

import { config } from 'config'
import { featureFlags } from 'modules/featureFlags'
import { isStorageSupported } from 'utils/storage/utils'

interface Window {
  // See next-pwa register.js for where this is defined
  workbox?: Workbox
}

declare let window: Window

export const initWorkbox = () => {
  const activateIfEnabled = () => {
    if (featureFlags.get('offline') && isStorageSupported) {
      if (config.APPLICATION_ENVIRONMENT !== 'dev') {
        window.workbox?.register()
        console.log('[initWorkbox] Service worker registered')
      }
      return true
    }
    return false
  }
  if (!activateIfEnabled()) {
    featureFlags.initializePromise.then(activateIfEnabled)
  }
}
