import { Flex, ModalCloseButton } from '@chakra-ui/react'
import { DOC_DISPLAY_NAME_PLURAL, SectionTitle } from '@gamma-app/ui'
import isHotkey from 'is-hotkey'
import clamp from 'lodash/clamp'
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

import {
  DocSortField,
  SortDirection,
  useGetDocsForGlobalSearchQuery,
  useHealthCheck,
  useSearchDocsAndChannelsQuery,
} from 'modules/api'
import { keyboardHandler } from 'modules/keyboard'
import { OfflineInfoBox } from 'modules/offline'
import { useAppSelector } from 'modules/redux'
import { useUserContext } from 'modules/user'
import { isMobileDevice } from 'utils/deviceDetection'
import { useLocalStorage } from 'utils/hooks/useLocalStorage'
import { USER_SETTINGS_CONSTANTS } from 'utils/userSettingsConstants'

import { selectInitialQuery, selectIsGlobalSearchOpen } from '../../reducer'
import { PseudoSearchDocResultFragment, SearchResult } from '../../types'
import { SearchInput } from './SearchInput'
import { SearchModalTip } from './SearchModalTip'
import { SearchResultsWrapper } from './SearchResultsWrapper'

const MAX_INITIAL_DOCS = 5
const isModK = isHotkey('mod+K')
export type GoToItemParams = {
  target: '_blank' | '_self'
}

const useSetRecentlyViewedDocsAsResults = ({
  query,
  isOpen,
  setResults,
  currentWorkspaceId,
}: {
  query: string
  isOpen: boolean
  setResults: Dispatch<SetStateAction<SearchResult[]>>
  currentWorkspaceId?: string
}) => {
  const { data: recentlyViewedDocsData } = useGetDocsForGlobalSearchQuery({
    variables: {
      first: MAX_INITIAL_DOCS,
      workspaceId: currentWorkspaceId,
      archived: false,
      sortBy: {
        field: DocSortField.LastViewed,
        direction: SortDirection.Desc,
      },
    },
    fetchPolicy: 'cache-first',
    skip: !isOpen,
  })

  useEffect(() => {
    if (query.length > 0 || !isOpen) return
    const recentlyViewedDocs =
      recentlyViewedDocsData?.docs.edges.map((e) => e.node) || []

    const resultShapedDocs = recentlyViewedDocs
      .slice(0, MAX_INITIAL_DOCS)
      .map((doc) => {
        const {
          id,
          title,
          archived,
          titleCard,
          createdBy,
          editors,
          createdTime,
          editedTime,
          updatedTime,
        } = doc
        return {
          id,
          title,
          archived,
          previewUrl: titleCard?.previewUrl,
          createdBy,
          editors,
          text: '',
          createdTime,
          editedTime,
          updatedTime,
          __typename: 'PseudoDocResult',
        } as PseudoSearchDocResultFragment
      })

    setResults(resultShapedDocs)
  }, [isOpen, query.length, recentlyViewedDocsData?.docs.edges, setResults])
}

export const SearchWrapper = () => {
  const { user, currentWorkspace } = useUserContext()
  const { isConnected } = useHealthCheck()

  const isOpen = useAppSelector(selectIsGlobalSearchOpen)
  const [results, setResults] = useState<SearchResult[]>([])
  const [focusedIndex, setFocusedIndex] = useState<number>(0)
  const [hasDismissedSearchTip, setHasDismissedSearchTip] =
    useLocalStorage<boolean>(
      USER_SETTINGS_CONSTANTS.hasDismissedSearchTip,
      false
    )
  const focusedItemEl = useRef<HTMLDivElement | null>(null)

  const [focusScroll, setFocusScroll] = useState(false)
  const initialQuery = useAppSelector(selectInitialQuery)
  const [query, setQuery] = useState(() => initialQuery || '')

  const { data, loading } = useSearchDocsAndChannelsQuery({
    variables: { workspaceId: currentWorkspace?.id as string, query },
    skip: !user || !query || !isConnected,
  })

  // Fetch recently viewed docs and set them as results when there's no query
  useSetRecentlyViewedDocsAsResults({
    query,
    isOpen,
    setResults,
    currentWorkspaceId: currentWorkspace?.id,
  })

  useEffect(() => {
    if (loading || !data) return
    const incoming = data.search.filter((item) => {
      return (
        item !== undefined &&
        (item.__typename === 'DocResult' || item.__typename === 'Channel')
      )
    }) as SearchResult[]
    setResults(incoming)
  }, [loading, data, query])

  useEffect(() => {
    if (initialQuery.length == 0) return
    setQuery(initialQuery)
  }, [initialQuery])

  const goToItem = useCallback(
    (config: GoToItemParams = { target: '_self' }) => {
      const { target } = config || {}
      const href = (
        focusedItemEl?.current?.querySelector('[href]') as HTMLAnchorElement
      ).href
      if (!href) {
        console.error('[SearchModal] No href found')
      }
      window.open(href, target)
    },
    [focusedItemEl]
  )

  // Keep a reference to the items we need inside handleKeyDown
  // so that we dont have to unbind/rebind when they change
  const keyboardHandlerRef = useRef({
    focusedIndex,
    results,
    goToItem,
  })
  keyboardHandlerRef.current = {
    focusedIndex,
    results,
    goToItem,
  }

  useEffect(() => {
    if (!isOpen) return
    const handleKeydown = (e: KeyboardEvent) => {
      const { key } = e
      if (isModK(e)) {
        return true
      }

      const handlerRef = keyboardHandlerRef.current

      switch (key) {
        case 'Escape':
          e.preventDefault()
          e.stopPropagation()
          return true

        case 'Tab':
          e.preventDefault()
          return true

        case 'Enter':
          e.preventDefault()
          if (handlerRef.results.length > 0) {
            handlerRef.goToItem({
              target: e.metaKey || e.ctrlKey ? '_blank' : '_self',
            })
          }
          return true

        case 'ArrowUp':
          e.preventDefault()
          if (handlerRef.focusedIndex <= 0) {
            setFocusedIndex(handlerRef.results.length - 1)
          } else {
            setFocusedIndex(
              clamp(
                handlerRef.focusedIndex - 1,
                0,
                handlerRef.results.length - 1
              )
            )
          }
          setFocusScroll(true)
          return true

        case 'ArrowDown':
          e.preventDefault()
          if (handlerRef.focusedIndex >= handlerRef.results.length - 1) {
            setFocusedIndex(0)
          } else {
            setFocusedIndex(
              clamp(
                handlerRef.focusedIndex + 1,
                0,
                handlerRef.results.length - 1
              )
            )
          }
          setFocusScroll(true)
          return true

        default:
          return false
      }
    }

    return keyboardHandler.on('keydown', 'GLOBAL_SEARCH', handleKeydown)
  }, [isOpen])

  const onChange = useCallback((e) => {
    const { value } = e.target
    if (value) {
      setQuery(value)
    } else {
      setQuery('')
      setResults([])
      setFocusedIndex(0)
    }
  }, [])

  const onMouseEnter = useCallback((indexToFocus) => {
    setFocusScroll(false)
    setFocusedIndex(indexToFocus)
  }, [])

  const onDismissSearchTip = () => {
    setHasDismissedSearchTip(true)
  }

  const showTip = !hasDismissedSearchTip && results.length > 0

  useEffect(() => {
    if (focusedItemEl.current && focusScroll)
      focusedItemEl?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      })
  }, [focusedIndex, focusScroll])

  const header =
    query.length > 0
      ? 'Search results'
      : `Recently viewed ${DOC_DISPLAY_NAME_PLURAL}`
  // IMPORTANT: If there is no user, return null
  if (!user) {
    return null
  }

  return (
    <Flex direction="column">
      <SearchInput
        onChange={onChange}
        query={query}
        isDisabled={!isConnected}
        isLoading={loading}
      />
      <OfflineInfoBox
        mx={4}
        mt={2}
        mb={4}
        isConnected={isConnected}
        label="Search is only available when you're online."
      />
      {isConnected && <SectionTitle p={4}>{header}</SectionTitle>}
      {isConnected && results && results.length > 0 && (
        <SearchResultsWrapper
          focusedItemEl={focusedItemEl}
          results={results}
          focusedIndex={focusedIndex}
          onMouseEnter={onMouseEnter}
        />
      )}
      {!isMobileDevice() && (
        <SearchModalTip onClose={onDismissSearchTip} showTip={showTip} />
      )}
      {isMobileDevice() && <ModalCloseButton />}
    </Flex>
  )
}
