import { CenterProps } from '@chakra-ui/react'
import { TooltipProps } from '@gamma-app/ui'
import { NodeViewProps } from '@tiptap/core'
import React, { useCallback } from 'react'

import { useAppSelector } from 'modules/redux'
import { selectEditable } from 'modules/tiptap_editor/reducer'

import { hasBlockHoverDeco } from '../../block/BlockHoverPlugin'
import { isSelectingNode } from '../../selection/FocusedNodes'
import { isFocusedInside } from '../../selection/utils'
import { handleDragStart } from '../utils'
import { BaseDragHandle, BaseDragHandleProps } from './BaseDragHandle'

type HandlePlacement = 'outside' | 'inside' | 'top' | 'left'

type ContainerDragHandleProps = {
  handlePlacement?: HandlePlacement
  isVisible?: boolean
  label?: TooltipProps['label']
} & CenterProps &
  NodeViewProps

const PLACEMENT_STYLES: {
  [key in HandlePlacement]: Partial<BaseDragHandleProps>
} = {
  top: {
    top: '0',
    left: '50%',
    height: '16px',
    width: '26px',
    pt: '1px',
    icon: '⋯',
    zIndex: '3',
    transform: 'translateY(-50%) translateX(-50%)',
    fontSize: '15px',
  },
  left: {
    top: '50%',
    left: '0',
    height: '26px',
    width: '16px',
    zIndex: '3',
    transform: 'translateY(-50%) translateX(-50%)',
    fontSize: '15px',
  },
  outside: {
    top: 0,
    left: -6,
  },
  inside: {
    top: 2,
    left: 3,
  },
}

export const ContainerDragHandle = ({
  handlePlacement = 'outside',
  editor,
  getPos,
  decorations,
  isVisible,
  onClick,
  node: _node,
  selected: _selected,
  extension: _extension,
  updateAttributes: _updateAttributs,
  deleteNode: _deleteNode,
  ...rest
}: ContainerDragHandleProps) => {
  const isEditable = useAppSelector(selectEditable)

  const selectNode = useCallback(
    (ev: React.MouseEvent) => {
      editor.commands.selectNodeAtPos(getPos())
      ev.stopPropagation()
    },
    [editor, getPos]
  )
  const onDragStart = useCallback(
    (ev: React.DragEvent) => {
      const previewEl: HTMLElement | undefined =
        (ev.target as HTMLElement)
          .closest('[data-node-view-wrapper]')
          ?.querySelector('[data-selection-ring]') || undefined

      handleDragStart(editor, ev.nativeEvent, getPos(), previewEl)
      // we need the stopPropagation becuase ReactNodeViewRenderer will cancel any
      // on drag start that is not on a node with [data-drag-handle]
      ev.stopPropagation()
    },
    [editor, getPos]
  )

  if (!isEditable) {
    return null
  }

  const isHovering = hasBlockHoverDeco(decorations)
  const isFocused = isFocusedInside(decorations)
  const isSelected = isSelectingNode(decorations)
  // null coalescing operator -> if not defined use isHovering or isFocused
  isVisible ??= isHovering || isFocused

  return (
    <BaseDragHandle
      isSelected={isSelected}
      isVisible={isVisible}
      onClick={onClick || selectNode}
      onDragStart={onDragStart}
      {...PLACEMENT_STYLES[handlePlacement]}
      {...rest}
    />
  )
}
