import { Editor } from '@tiptap/core'
import { Mark } from 'prosemirror-model'

import { fetchAndUpdateEmbedAttrsForUrlAndUploadThumbnail } from 'modules/media/components/URLFetcher'
import { findChildrenByMark } from 'modules/tiptap_editor/prosemirror-utils'

import { SUPPORTED_PROTOCOLS } from './Link'

export const isValidAutoLink = (url: string) => {
  const isFuzzy = !url.includes('://')
  return !isFuzzy
}

export const fetchUrlThenUpdateMarks = (url: string, editor: Editor) => {
  if (isOtherSupportedProtocol(url)) {
    // Don't fetch embed attributes for non-http urls
    return
  }
  fetchAndUpdateEmbedAttrsForUrlAndUploadThumbnail(url, (attrs) => {
    const { doc, schema, tr } = editor.state
    // Find marks
    findChildrenByMark(doc, editor.schema.marks.link).forEach(
      ({ node, pos }) => {
        const linkMark = node.marks.find(
          (mark) => mark.type.name === 'link' && mark.attrs.href == url
        )
        if (!linkMark) return
        tr.removeMark(pos, pos + node.nodeSize, linkMark).addMark(
          pos,
          pos + node.nodeSize,
          schema.marks.link.create({ ...linkMark.attrs, ...attrs })
        )
      }
    )
    editor.view.dispatch(tr)
  }).catch((error) => {
    console.error('[Link.fetchUrlThenUpdateMarks]', error)
  })
}

export const linkHasMetadata = (mark: Mark) =>
  mark.attrs.source || mark.attrs.meta || mark.attrs.embed

export const isOtherSupportedProtocol = (inputHref: string | null) => {
  if (!inputHref) {
    return false
  }
  const href = inputHref && inputHref.toLowerCase()
  for (const protocol of SUPPORTED_PROTOCOLS) {
    if (href.startsWith(protocol + ':')) {
      return true
    }
  }
  return false
}
