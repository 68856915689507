import { Box, HStack, Text } from '@chakra-ui/react'
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'

type PermissionsSubtitleWithIconProps = {
  subtitle: string
  tooltipLabel: string
  ariaLabel: string
  icon: FontAwesomeIconProps['icon']
}
export const PermissionsSubtitleWithIcon = ({
  subtitle,
  tooltipLabel,
  ariaLabel,
  icon,
}: PermissionsSubtitleWithIconProps) => {
  return (
    <HStack spacing={1} align="center">
      <Text fontSize="xs" color="gray.500">
        {subtitle}
      </Text>
      <GammaTooltip label={tooltipLabel} placement="top" aria-label={ariaLabel}>
        <Box color="gray.500" lineHeight="1">
          <FontAwesomeIcon size="xs" icon={icon} />
        </Box>
      </GammaTooltip>
    </HStack>
  )
}
