import {
  Editable,
  EditableInput,
  EditablePreview,
  EditableProps,
} from '@chakra-ui/react'
import { useCallback } from 'react'

type EditableLabelProps = EditableProps & {
  updateAttributes: (attributes: Record<string, any>) => void
}

export const EditableLabel = ({
  updateAttributes,
  value,
  placeholder,
  ...props
}: EditableLabelProps) => {
  const updateValue = useCallback(
    (newValue: string) => {
      updateAttributes({ label: newValue === '' ? null : newValue })
    },
    [updateAttributes]
  )

  return (
    <Editable
      defaultValue={value || undefined}
      placeholder={placeholder}
      onSubmit={updateValue}
      {...props}
    >
      <EditablePreview width="100%" py={0} />
      <EditableInput width="100%" my={-1} py={1} />
    </Editable>
  )
}
