import { Checkbox, Text } from '@chakra-ui/react'
import { NodeViewProps } from '@tiptap/react'

import { useAppSelector } from 'modules/redux'
import { selectDoc } from 'modules/tiptap_editor/reducer'
import { useCan } from 'modules/user'

import { NodeViewContent } from '../../react'
import { AnnotatableNodeViewWrapper } from '../Annotatable/components/AnnotatableNodeViewWrapper'

export const TodoView = (nodeViewProps: NodeViewProps) => {
  const { node, updateAttributes } = nodeViewProps
  const doc = useAppSelector(selectDoc)
  const userCanEditDoc = useCan('edit', doc)
  const { checked, fontSize } = node.attrs

  return (
    <AnnotatableNodeViewWrapper {...nodeViewProps}>
      <Text
        as="li"
        color={checked ? 'var(--body-color-muted)' : undefined}
        textDecoration={checked ? 'line-through' : undefined}
        transition="color 250ms ease-out"
        position="relative"
        className="todo"
        data-font-size={fontSize}
      >
        <Checkbox
          contentEditable={false}
          suppressContentEditableWarning={true}
          isChecked={checked}
          onChange={(event) => {
            if (!userCanEditDoc) return
            updateAttributes({ checked: event.target.checked })
          }}
          position="absolute"
        />
        <NodeViewContent as="span" className="todo-content" />
      </Text>
    </AnnotatableNodeViewWrapper>
  )
}
