import { NodeViewProps } from '@tiptap/core'

import { useAppSelector } from 'modules/redux'
import {
  selectCardCollapsed,
  selectEditable,
  selectIsStatic,
} from 'modules/tiptap_editor/reducer'
import { isMobileDevice } from 'utils/deviceDetection'

import { EditorModeEnum } from '../../../types'
import { isExpandableSelectedNode } from '../../ExpandableNodes/ExpandableNodes'
import { isFocusedInside } from '../../selection/utils'
import { findCardPluginDecoration } from '../CardPlugin'
import { CardAttributes } from '../types'
import { useCardColorMode } from './useCardColorMode'
import { usePresentVariant } from './usePresentVariant'

export const useCardState = (
  nodeViewProps: Pick<NodeViewProps, 'node' | 'decorations'>
) => {
  const { node, decorations } = nodeViewProps
  const attrs = node.attrs as CardAttributes
  const { id: cardId, previewContent } = attrs

  // Presenting states
  const [presentVariant, prevPresentVariant] = usePresentVariant(cardId)
  // Derive the mode from the presentVariant so that we recompute things at the same time
  const mode =
    presentVariant === 'doc'
      ? EditorModeEnum.DOC_VIEW
      : EditorModeEnum.SLIDE_VIEW
  const isPresentMode = mode === EditorModeEnum.SLIDE_VIEW
  const isPresenting =
    presentVariant !== 'doc' && presentVariant !== 'presentCollapsed'
  const isPresentingCurrent = presentVariant === 'presentCurrent'
  // Pass the mode derived from the presentVariant to observe editable changes
  const isEditable = useAppSelector((state) => selectEditable(state, mode))
  const isPresentingParent = presentVariant === 'presentParent'

  // Focus ring
  const isFocused = isFocusedInside(decorations)

  // Card nesting
  const isCollapsed = useAppSelector(selectCardCollapsed(cardId))

  // Expandable selection
  const isExpandableSelected = isExpandableSelectedNode(decorations)

  const {
    isNested,
    nestedDepth,
    isFirstCard,
    inheritContainerEffect,
    inheritContainerIsDark,
    inheritContainerWidth,
  } = findCardPluginDecoration(decorations)
  const inheritContainer = {
    effect: inheritContainerEffect,
    isDark: inheritContainerIsDark,
    width: inheritContainerWidth,
  }

  // Use Redux here
  const isStatic = useAppSelector(selectIsStatic)

  const { theme, isDark, isDarkOverride } = useCardColorMode(decorations)

  // cardWidth is determined by whether a block inside the card isFullWidth
  // const cardWidthDeco = findBlockWidthDecoration(decorations)
  // const hasFullWidthChildren = cardWidthDeco?.isFullWidth || false

  const isFullBleed =
    !isPresentMode && !isMobileDevice() && attrs.cardSize === 'full'

  return {
    inheritContainer,
    isCollapsed,
    isEditable,
    isFirstCard,
    isFocused,
    isExpandableSelected,
    isNested,
    isPresenting,
    isPresentingCurrent,
    isPresentingParent,
    isPresentMode,
    isStatic,
    isDark,
    isDarkOverride,
    mode,
    nestedDepth,
    presentVariant,
    prevPresentVariant,
    previewContent,
    theme,
    attrs,
    cardId,
    isFullBleed,
  }
}
