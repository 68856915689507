import { Extension, findParentNode } from '@tiptap/core'
import { TextSelection } from 'prosemirror-state'

import { fetchTextCompletion, OpenAIParams } from 'modules/ai/openai'
import { featureFlags } from 'modules/featureFlags'
import {
  absoluteToRelativePos,
  relativeToAbsolutePos,
} from 'modules/tiptap_editor/utils/relativePosition'
import { createSelectionNearLastTo } from 'modules/tiptap_editor/utils/selection/findSelectionNearOrGapCursor'

import { isCardNode } from '../Card/utils'
import { rangeToMarkdown } from '../Clipboard/markdown'
import {
  AutocompleteInsertEvent,
  AutocompleteLoadEvent,
  AutocompletePlugin,
  AutocompleteResetEvent,
} from './AutocompletePlugin'
import { generateOutlineMarkdown } from './Outline'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    autocomplete: {
      autoCompleteQuick: () => ReturnType
      autoFillCard: () => ReturnType
      autoComplete: (
        params?: OpenAIParams,
        lookbackLength?: number
      ) => ReturnType
      autoOutline: () => ReturnType
    }
  }
}

export const INSERT_FADE_TIME = 1500 // ms
const DEFAULT_LOOKBACK_LENGTH = 500

export const Autocomplete = Extension.create({
  name: 'autocomplete',
  addProseMirrorPlugins() {
    return [AutocompletePlugin(this.editor)]
  },
  addCommands() {
    return {
      autoOutline:
        () =>
        ({ editor, state, tr: initialTr }) => {
          const { selection, doc } = state
          const { $from, from } = selection
          const insertPosRel = absoluteToRelativePos(state, $from.after())

          // Use the existing text in the card to generate the outline. Designed to run on the title card with extra description text.
          const parentCard = findParentNode(isCardNode)(selection)
          if (!parentCard || !insertPosRel) return false
          const prevText = rangeToMarkdown(doc, parentCard.pos, from)

          initialTr.setMeta('autocompleteEvent', <AutocompleteLoadEvent>{
            type: 'load',
          })

          generateOutlineMarkdown(prevText)
            .then((md) => {
              const insertPos = relativeToAbsolutePos(state, insertPosRel)
              if (!md || !insertPos) {
                throw new Error('No outline or insertPos')
              }
              // This range will be updated to match the actual area we selected
              const insertRange = { from: insertPos, to: insertPos }
              editor
                .chain()
                .insertMarkdownAt(insertRange, md)
                .command(({ tr }) => {
                  const sel = createSelectionNearLastTo(tr)
                  if (!sel) return true
                  insertRange.to = sel.to
                  tr.setSelection(sel).scrollIntoView()
                  return true
                })
                .setMeta('autocompleteEvent', <AutocompleteInsertEvent>{
                  range: insertRange,
                  type: 'insert',
                })
                .run()

              setTimeout(() => {
                editor.commands.setMeta('autocompleteEvent', <
                  AutocompleteResetEvent
                >{
                  type: 'reset',
                })
              }, INSERT_FADE_TIME)
            })
            .catch((reason) => {
              console.warn('Error generating outline', reason)
              editor.commands.setMeta('autocompleteEvent', <
                AutocompleteResetEvent
              >{
                type: 'reset',
              })
            })
          return true
        },

      autoFillCard:
        () =>
        ({ commands }) => {
          // Slow, expensive version of autocomplete that generates lots of content
          commands.autoComplete(
            {
              model: 'text-davinci-003',
              maxTokens: 300,
              temperature: 0.75,
              stop: '\n\n# ', // Stop it from generating a new card
            },
            1000
          )
          return true
        },

      // Autocomplete a small amount of text at a time
      autoCompleteQuick:
        () =>
        ({ commands }) => {
          commands.autoComplete(
            {
              model: 'text-davinci-003',
              maxTokens: 50,
              temperature: 0.25,
            },
            600
          )
          return true
        },

      autoComplete:
        (params = {}, lookbackLength = DEFAULT_LOOKBACK_LENGTH) =>
        ({ editor, state, tr: initialTr }) => {
          const { selection, doc } = state
          const { from } = selection
          const insertPosRel = absoluteToRelativePos(state, from)
          const canAutocomplete =
            featureFlags.get('aiAutocomplete') &&
            selection.empty &&
            selection instanceof TextSelection &&
            insertPosRel

          if (!canAutocomplete) {
            return false
          }

          const prevText = rangeToMarkdown(
            doc,
            Math.max(selection.from - lookbackLength, 0),
            selection.from
          )

          // Todo: should we tell it more about what it's completing to make it more accurate?
          const prompt = prevText
          initialTr.setMeta('autocompleteEvent', <AutocompleteLoadEvent>{
            type: 'load',
          })

          fetchTextCompletion(prompt, 'raw', params)
            .then((newCompletion) => {
              const insertPos = relativeToAbsolutePos(state, insertPosRel)
              if (newCompletion === null || !insertPos) {
                throw new Error('No completion or insertPos')
              }
              // Trim leading and trailing spaces
              newCompletion = newCompletion.trim()

              // This range will be updated to match the actual area we selected
              const insertRange = { from: insertPos, to: insertPos }

              const chain = editor
                .chain()
                .insertMarkdownAt(insertRange, newCompletion, false)
                .command(({ tr }) => {
                  const sel = createSelectionNearLastTo(tr)
                  if (!sel) return true
                  insertRange.to = sel.to
                  tr.setSelection(sel).scrollIntoView()
                  return true
                })
                .setMeta('autocompleteEvent', <AutocompleteInsertEvent>{
                  range: insertRange,
                  type: 'insert',
                })

              // Keep a space right before the insert
              if (!(prevText.endsWith(' ') || prevText.endsWith('\n'))) {
                chain.insertContentAt(insertPos, ' ', {
                  updateSelection: false,
                })
              }

              chain.run()

              setTimeout(() => {
                editor.commands.setMeta('autocompleteEvent', <
                  AutocompleteResetEvent
                >{
                  type: 'reset',
                })
              }, INSERT_FADE_TIME)
            })
            .catch((reason) => {
              console.warn('Error fetching autocomplete', reason)
              editor.commands.setMeta('autocompleteEvent', <
                AutocompleteResetEvent
              >{
                type: 'reset',
              })
            })

          return true
        },
    }
  },
  addKeyboardShortcuts() {
    return {
      'Ctrl-Enter': ({ editor }) => editor.commands.autoCompleteQuick(),
      'Ctrl-Space': ({ editor }) => editor.commands.autoCompleteQuick(),
    }
  },
})
