import { Stack, Heading, Text } from '@chakra-ui/react'

export const StepContent = ({
  title,
  body,
}: {
  title?: string
  body: string | JSX.Element
}) => {
  return (
    <Stack spacing={2}>
      {title && (
        <Heading size="md" mb={2}>
          {title}
        </Heading>
      )}
      {typeof body === 'string' ? <Text size="sm">{body}</Text> : body}
    </Stack>
  )
}

StepContent.displayName = 'StepContent'
