import { ApolloError } from '@apollo/client'

import {
  ChatCompletionDocument,
  ChatCompletionMutationResult,
  FormattedGraphQLError,
  getApolloClient,
  TransformTextDocument,
} from 'modules/api'
import { getExistingQueryParams } from 'utils/url'

export const USE_SAMPLE_DATA_TO_DEBUG =
  getExistingQueryParams()['debug'] === 'true'

export type OpenAIParams = Partial<{
  temperature: number
  maxTokens: number
  model: string
  stop: string | string[]
}>

export type ChatCompletionMessage = {
  role: 'system' | 'user' | 'assistant'
  content: string
}

export type ChatCompletionChoice = {
  index: number
  message: {
    role: ChatCompletionMessage['role']
    content: string
  }
  finish_reason: string
}

export const isBadRequestError = (error?: ApolloError) => {
  if (
    error?.graphQLErrors &&
    error?.graphQLErrors?.length > 0 &&
    (error.graphQLErrors as unknown as FormattedGraphQLError[]).some((err) => {
      return err.code === 'BAD_USER_INPUT'
    })
  ) {
    return true
  }
  return false
}

const retryWithBackoff = async ({
  fn,
  retries = 3,
  backoff = 1000,
}: {
  fn: () => Promise<any>
  retries?: number
  backoff?: number
}) => {
  try {
    return await fn()
  } catch (error) {
    // Dont retry bad request errors
    const isBadRequest = isBadRequestError(error)
    if (retries > 0 && !isBadRequest) {
      await new Promise((resolve) => setTimeout(resolve, backoff))
      return retryWithBackoff({
        fn,
        retries: retries - 1,
        backoff: backoff * 2,
      })
    }
    throw error
  }
}

export const fetchChatCompletion = async (
  messages: ChatCompletionMessage[],
  params?: OpenAIParams
): Promise<ChatCompletionMutationResult['data']> => {
  console.log('[AI][fetchChatCompletion]', messages)
  const execute = () =>
    getApolloClient()
      .mutate({
        mutation: ChatCompletionDocument,
        variables: {
          input: {
            messages,
            params,
          },
        },
      })
      .then(({ data }: ChatCompletionMutationResult) => {
        const response = data?.chatCompletion
        if (!response || response.length === 0) {
          throw Error(`No response received`)
        }
        console.debug('[AI] Got chat completion', response)
        return data
      })
  return retryWithBackoff({ fn: execute })
}

export const fetchTextCompletion = async (
  prompt: string,
  operation: string = 'raw',
  params?: OpenAIParams
): Promise<string> => {
  console.debug('[AI][fetchTextCompletion] Fetching text completion', {
    prompt,
  })
  const execute = () =>
    getApolloClient()
      .mutate({
        mutation: TransformTextDocument,
        variables: {
          input: [
            {
              text: prompt,
              operation,
              params,
            },
          ],
        },
      })
      .then(({ data }) => {
        const response = data.transformText
        if (!response || response.length === 0) {
          throw Error(`No response received`)
        }
        console.debug('[AI] Got text completion', response)
        return response[0].text[0]
      })

  return retryWithBackoff({ fn: execute })
}

export const trimSpaces = (text: string): string => {
  return text.replace(/^[\s]+|[\s]+$/g, '')
}
