import { isTextSelection, NodeWithPos } from '@tiptap/core'
import { GapCursor } from 'prosemirror-gapcursor'
import { ResolvedPos } from 'prosemirror-model'
import { EditorState, Selection, Transaction } from 'prosemirror-state'

import { findNodeAndParents } from 'modules/tiptap_editor/utils'
import { findSelectionNearOrGapCursor } from 'modules/tiptap_editor/utils/selection/findSelectionNearOrGapCursor'

import { MergeCardsAnnotationEvent } from '../Annotatable/AnnotationExtension/types'
import { getCardLayoutItems } from './CardLayout/cardLayoutUtils'
import { isCardLayoutItemNode, isCardNode } from './utils'

export const mergeCardForward = (
  cardPos: number,
  tr: Transaction,
  state: EditorState
): boolean => {
  const $card = tr.doc.resolve(cardPos)
  const nextCardPos = cardPos + $card.nodeAfter!.nodeSize
  const nextCardNode = tr.doc.nodeAt(nextCardPos)
  if (!nextCardNode || !isCardNode(nextCardNode)) {
    return false
  }

  // find first parent "content wrapper", either a body layout item or card
  const contentWrapper = findContentWrapperFromCard(tr, nextCardPos)
  if (!contentWrapper) {
    return false
  }

  tr.delete(nextCardPos, nextCardPos + nextCardNode.nodeSize)
  // TODO test mapping when deleting content before
  const insertPos = findCardContentInsertPos(tr, cardPos, true)
  // insert content
  tr.insert(insertPos, contentWrapper.node.content)

  const event: MergeCardsAnnotationEvent = {
    type: 'merge-cards',
    contentPos: contentWrapper.pos,
    insertPos,
  }
  tr.setMeta('annotationEvent', event)

  if (state.selection instanceof GapCursor) {
    const sel = findSelectionNearOrGapCursor(tr.doc.resolve(insertPos))
    if (sel) {
      tr.setSelection(sel)
    }
  }

  return true
}

/**
 * This function unlike mergeCardForward does not rely on selection to figure out
 * where to merge or where to select at the end
 * It can be used for both mergeCardsOnDelete and mergeCardsAtPos
 */
export const mergeCardBackward = (
  cardPos: number,
  tr: Transaction
): boolean => {
  const $card = tr.doc.resolve(cardPos)
  if (!$card.nodeBefore) {
    return false
  }

  const prevCardPos = cardPos - $card.nodeBefore.nodeSize
  const prevCardNode = tr.doc.nodeAt(prevCardPos)
  if (!prevCardNode || !isCardNode(prevCardNode)) {
    return false
  }

  // find first parent "content wrapper", either a body layout item or card
  const contentWrapper = findContentWrapperFromCard(tr, cardPos)
  if (!contentWrapper) {
    return false
  }

  tr.delete(cardPos, cardPos + $card.nodeAfter!.nodeSize)
  // TODO test mapping when deleting content before
  const insertPos = findCardContentInsertPos(tr, prevCardPos, true)
  // insert content
  tr.insert(insertPos, contentWrapper.node.content)
  const sel = findSelectionNearOrGapCursor(tr.doc.resolve(insertPos))
  if (sel) {
    tr.setSelection(sel)
  }

  const event: MergeCardsAnnotationEvent = {
    type: 'merge-cards',
    contentPos: contentWrapper.pos,
    insertPos,
  }
  tr.setMeta('annotationEvent', event)

  return true
}

export const findContentWrapperFromCard = (
  tr: Transaction,
  cardPos: number
): NodeWithPos | null => {
  const items = getCardLayoutItems(tr, cardPos)
  if (Object.entries(items).length === 0) {
    // not a card layout card
    return {
      node: tr.doc.nodeAt(cardPos)!,
      pos: cardPos,
    }
  }

  return items.body || null
}

const findContentWrapperFromInside = (
  $from: ResolvedPos
): NodeWithPos | null => {
  const found = findNodeAndParents(
    $from,
    (node) =>
      (node.type.name === 'cardLayoutItem' && node.attrs.itemId === 'body') ||
      node.type.name === 'card'
  )

  if (found.length === 0) {
    return null
  }
  return found[0]!
}

const findCardContentInsertPos = (
  tr: Transaction,
  cardPos: number,
  end = false
): number => {
  const items = getCardLayoutItems(tr, cardPos)
  const $card = tr.doc.resolve(cardPos)
  if (Object.entries(items).length === 0) {
    // not a card layout card

    return end
      ? $card.doc.resolve($card.start($card.depth + 1)).end()
      : cardPos + 1
  }

  if (!items.body) {
    throw new Error(`Could not find card body at cardPos=${cardPos}`)
  }

  const $body = tr.doc.resolve(items.body.pos)
  return end
    ? $body.doc.resolve($body.start($body.depth + 1)).end()
    : $body.pos + 1
}

export const isAtContentWrapperEdge = (
  selection: Selection,
  forward: boolean
): boolean => {
  if (!selection.empty) {
    return false
  }

  // only text selection and gapcursors are valid
  const isGapCursor = selection instanceof GapCursor
  if (!isTextSelection(selection) && !isGapCursor) {
    return false
  }

  const { from, $from } = selection

  if (!forward && $from.parentOffset !== 0) {
    // must be at beginning of parent if textContent
    return false
  }

  if (forward && $from.parentOffset !== $from.parent.content.size) {
    // must be at end of parent if textContent
    return false
  }

  // valid paths
  // card -> gapcursor = +1
  // card -> paragraph -> text selection = +2
  // card -> heading -> text selection = +2
  // dont care about cardAccentLayoutItem becuase it can't have selection inside
  const parentContentWrapper = findContentWrapperFromInside($from)
  if (!parentContentWrapper) {
    // should not happen
    return false
  }

  if (isGapCursor) {
    // if gapcuror can be 1 away from start or end
    return !forward
      ? from === parentContentWrapper.pos + 1
      : from ===
          parentContentWrapper.pos + parentContentWrapper.node.nodeSize - 1
  }

  // what nodes where a text selection at beginning or end
  // is considered at the edge of a card.  for example a bullet would not be
  const ACCEPTABLE_EDGE_NODES = ['paragraph', 'heading', 'title']

  let isAtEdge = true
  // traverse up through parent nodes until hitting a `card` node or null using $pos.depth
  for (let i = $from.depth; i > 0; i--) {
    const node = $from.node(i)
    if (node.type.name === 'card' || isCardLayoutItemNode(node)) {
      break
    }
    if (!ACCEPTABLE_EDGE_NODES.includes(node.type.name)) {
      isAtEdge = false
      break
    }
  }
  // only allow text selection to be inside, paragraph, heading
  if (!isAtEdge) {
    return false
  }

  return !forward
    ? from === parentContentWrapper.pos + 2
    : from === parentContentWrapper.pos + parentContentWrapper.node.nodeSize - 2
}
