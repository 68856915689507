import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'
import { QueryClient } from '@tanstack/react-query'
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'
import { useMemo, FC, PropsWithChildren } from 'react'

import { localStore } from 'utils/storage'
import { isStorageSupported } from 'utils/storage/utils'

export const ReactQueryProvider: FC<
  PropsWithChildren<{ client: QueryClient }>
> = ({ children, client }) => {
  const persister = useMemo(
    () =>
      createSyncStoragePersister({
        storage:
          typeof window !== 'undefined' && isStorageSupported
            ? localStore
            : undefined,
      }),
    []
  )
  return (
    <PersistQueryClientProvider client={client} persistOptions={{ persister }}>
      {children}
    </PersistQueryClientProvider>
  )
}

type ErrorFilterFn = (error: any) => boolean

const errorFilters: ErrorFilterFn[] = []

// React-Query always console.errors on "Error" responses
// Set our own logger so that we can avoid console.error'ing when
// we want to, such as a 401/403 from /user.
// This prevents these errors from ending up in Sentry
// See https://stackoverflow.com/questions/65570462/how-do-i-mute-react-query-console-errors-when-a-promise-rejects
const customLogger = {
  log: console.log,
  warn: console.warn,
  error: (error: any) => {
    // Avoid console erroring if any of the error filters return true
    if (errorFilters.some((fn) => fn(error))) return
    console.error(error)
  },
}

export const setReactQueryErrorFilter = (fn: ErrorFilterFn) => {
  errorFilters.push(fn)
}

export const getReactQueryClient = () =>
  new QueryClient({ logger: customLogger })
