export type ExampleMemoJSON = {
  docId: string
  thumbnailUrl: string
  title: string
  disabled?: boolean
  isNew?: boolean
}

/**
 * Must enumerate all feature flags here
 */
export type FeatureFlags = {
  codeBlock: boolean
  // Enables hard-coded custom themes
  // Can be ALL, NONE, a single theme key, or a comma separate list
  // E.g. "virtualhaus" or "virtualhaus_1, virtualhaus_alt,"
  customThemes: 'ALL' | 'NONE' | string
  debugComments: boolean
  debugCardViewed: boolean
  debugLogging: boolean
  drawingBlock: boolean
  exportToPDF: boolean
  newPDFExport: boolean
  uploadPDF: boolean
  highlight: {
    enabled: boolean
    enableStrictPrivacy: boolean
  }
  exampleMemos: ExampleMemoJSON[]
  realUserMetrics: boolean
  rumSampleRate: number
  rumKeystrokeSampleRate: number
  analytics: boolean
  cardViewedThreshold: number
  // Enables creating new custom themes
  themeEditor: boolean
  channelActivity: boolean
  customFonts: boolean
  docImport: boolean
  examplesV2: boolean
  presentModeFlat: boolean
  blockReactions: boolean
  inspirationDecks: boolean
  imageGallery: boolean
  intercom: boolean
  dataSyncErrorThreshold: number
  dataSyncClockDriftTolerance: number
  deleteAccount: boolean
  screenshotsEnabled: boolean
  textColor: boolean
  fontSize: boolean
  math: boolean
  calloutBox: boolean
  smartLayout: boolean
  gradientBackground: boolean
  offline: boolean
  cardWidthOptions: boolean
  layoutResizing: boolean
  tableOfContentsBlock: boolean
  presentZoom: boolean
  linkMetadata: boolean
  toggle: boolean
  loremIpsum: boolean
  presentFullWidth: boolean
  presentScaleFont: boolean
  renderCollapsedCards: boolean
  themes2: boolean
  cardLayoutsEnabled: boolean
  card1InCard2: boolean
  presentFullScreen: boolean
  cardLayoutResizing: boolean
  docFullWidth: boolean
  borderless: boolean
  aiAutocomplete: boolean
  aiRewrite: boolean
  aiCardDesigner: boolean
  aiWizard: boolean
  aiChat: boolean
  perfOnEditorRendered: boolean
  imageView2: boolean
  publishing: boolean
}

export const FEATURE_FLAG_DEFAULTS: Readonly<FeatureFlags> = {
  codeBlock: true,
  customThemes: 'NONE',
  debugCardViewed: false,
  debugComments: false,
  debugLogging: false,
  deleteAccount: false,
  drawingBlock: true,
  uploadPDF: false,
  exportToPDF: false,
  newPDFExport: false,
  highlight: {
    enabled: false,
    enableStrictPrivacy: true,
  },
  exampleMemos: [],
  realUserMetrics: false,
  // default sample rate is 100%
  rumSampleRate: 1,
  // default keystroke sample rate is 10%
  rumKeystrokeSampleRate: 10,
  analytics: false,
  cardViewedThreshold: 5000,
  themeEditor: true,
  channelActivity: false,
  customFonts: false,
  docImport: false,
  examplesV2: false,
  presentModeFlat: true,
  blockReactions: false,
  inspirationDecks: false,
  imageGallery: true,
  intercom: true,
  dataSyncErrorThreshold: -1,
  dataSyncClockDriftTolerance: 5,
  screenshotsEnabled: false,
  textColor: true,
  fontSize: false,
  math: false,
  calloutBox: false,
  smartLayout: false,
  gradientBackground: false,
  offline: false,
  cardWidthOptions: false,
  layoutResizing: false,
  tableOfContentsBlock: false,
  presentZoom: false,
  linkMetadata: false,
  toggle: false,
  loremIpsum: false,
  presentFullWidth: false,
  presentScaleFont: false,
  renderCollapsedCards: true,
  themes2: false,
  cardLayoutsEnabled: false,
  card1InCard2: false,
  presentFullScreen: false,
  cardLayoutResizing: false,
  docFullWidth: false,
  borderless: false,
  aiAutocomplete: false,
  aiRewrite: false,
  aiCardDesigner: false,
  aiWizard: false,
  aiChat: false,
  perfOnEditorRendered: false,
  imageView2: false,
  publishing: false,
}
