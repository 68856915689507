/**
 * This file provides certain core info to subcomponents via context
 */
import * as Sentry from '@sentry/browser'
import jsCookie from 'js-cookie'
import { customAlphabet } from 'nanoid'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import tinycolor from 'tinycolor2'
import * as workerTimers from 'worker-timers'

import { config } from 'config'
import {
  getWsLink,
  ExistingWorkspace,
  ReactQueryPromiseRejectionEvent,
  setReactQueryErrorFilter,
  useGetUser,
  User,
  usersApi,
} from 'modules/api'
import { updateIntercomUser } from 'modules/intercom'
import { EventEmitter } from 'utils/EventEmitter'
import { generateColor, generateName } from 'utils/generators'
import { useWakeUpDetector } from 'utils/hooks'
import { useLocalStorage } from 'utils/hooks/useLocalStorage'

import { AbilityContext, abilityFactory } from './AuthContext'
import { GraphqlUser, UserContext, UserContextType } from './UserContext'

export * from './AuthContext'
export * from './UserContext'

const RETRY_COUNT = 2
const VISITOR_ID_COOKIE = 'gamma_visitor_id'
const VISITOR_COOKIE_EXPIRATION_DAYS = 10 * 365 // 10 years

const nanoid = customAlphabet('0123456789abcdefghijklmnopqrstuvwxyz', 15)

const refetchUser = () =>
  usersApi.getUser().then(
    () => {
      console.debug('[UserContextProvider][refreshInterval] User re-fetched.')
    },
    () => {} // No Gamma User
  )

type UserContextProps = {
  children: React.ReactNode
}

/**
 * Ensure there is a user id set in the cookie,
 * creating a new one if necessary, and return it
 */
const ensureCookieUserId = (): string => {
  const id = jsCookie.get(VISITOR_ID_COOKIE) || undefined
  if (id) return id
  const newId = nanoid()
  jsCookie.set(VISITOR_ID_COOKIE, newId, {
    domain: config.VISITOR_ID_COOKIE_DOMAIN,
    expires: VISITOR_COOKIE_EXPIRATION_DAYS,
    // this cookie needs to be sameSite=none in order for all domains to see it
    // (including custom versions or custom domains)
    sameSite: 'none',
    // it has to be secure for sameSite=none to stick in Chrome
    secure: true,
  })
  return newId
}

const getAnonymousUser = (id: string) => {
  return {
    id,
    displayName: id ? generateName(id) : '',
  }
}

/**
 * Basic event emitter for handling when user is signed in
 */

type UserSignedInEvent = {
  signedIn: boolean
}

export const eventEmitter = new EventEmitter<UserSignedInEvent>()

/**
 * Hook that listens for signedIn events and invokes passed-in callback
 */

export const useUserSignedIn = (callback: () => void) => {
  useEffect(() => {
    return eventEmitter.on('signedIn', callback)
  }, [callback])
}

const getColorObject = (id?: string) => {
  const colorValue = id ? generateColor(id) : '#cccccc'
  return {
    value: colorValue,
    isDark: tinycolor(colorValue).isDark(),
  }
}

// A 401/403 error means we know this user is not signed in
const isExpected4xxResponse = (r: Response) => [401, 403].includes(r.status)

export const UserContextProvider = ({
  children,
}: UserContextProps): JSX.Element => {
  useEffect(() => {
    // This is an annoying way to prevent ReactQuery from console.erroring 4xx responses
    setReactQueryErrorFilter((e) => {
      if (config.GAMMA_PUPPETEER_SERVICE) {
        return true
      }
      if (
        e instanceof Response &&
        isExpected4xxResponse(e) &&
        e.url.includes('/user')
      ) {
        return true
      }
      return false
    })
  }, [])

  const {
    data: userData,
    isFetched,
    refetch,
  } = useGetUser({
    retry: (count: number, resp: Response) => {
      // Puppeteer mock responses dont have a status code for some reason
      // See https://linear.app/gamma-app/issue/G-2785/figure-out-how-to-properly-mock-user-with-puppeteer
      if (!resp.status || isExpected4xxResponse(resp)) {
        return false
      }

      // Retry other error codes
      if (count > RETRY_COUNT) {
        throw new ReactQueryPromiseRejectionEvent('', {
          message: 'useGetUser (/user) - Retry attempts exceeded',
        })
      }
      return true
    },
  })

  // isFetched lets us know when the query has been executed (success or failure)
  const isUserLoading = !isFetched
  const user = useMemo<GraphqlUser | undefined>(() => {
    if (userData) {
      return {
        ...userData,
        __typename: 'User',
      }
    }
    return userData
  }, [userData])

  const isConfirmedAnonymous = !isUserLoading && Boolean(!user)
  const hasAnonymousCheckRun = useRef<boolean>(false)
  useEffect(() => {
    // Only run once, when user goes from confirmed (ie, not loading) anonymous `true` to `false` (signed in)
    if (!hasAnonymousCheckRun.current) {
      hasAnonymousCheckRun.current = true
      return
    }
    if (isConfirmedAnonymous) {
      return
    }
    eventEmitter.emit('signedIn', true)
  }, [isConfirmedAnonymous])

  useUserSignedIn(
    useCallback(() => {
      // This is a hack to reconnect the subscription when a user is signed in
      // https://github.com/apollographql/subscriptions-transport-ws/issues/378
      // note that subscriptions-transport-ws is no longer maintained
      // but we can't upgrade until we upgrade apollo server
      if (!getWsLink) return
      // @ts-ignore: Property 'subscriptionClient' is private and only accessible within class 'WebSocketLink'.ts(2341)
      getWsLink().subscriptionClient.close(false, false)
    }, [])
  )

  const [currentWorkspaceId, setCurrentWorkspaceId] = useLocalStorage<
    string | undefined
  >('currentWorkspaceId', undefined)
  const currentWorkspace = useMemo<ExistingWorkspace | undefined>(() => {
    if (!user) return undefined

    const workspaces = user.organizations
    if (!workspaces || workspaces.length === 0) return undefined

    const workspace = workspaces.find((w) => w.id === currentWorkspaceId)
    if (!workspace) {
      // set the currentWorkspaceId to the first workspace if it isn't set or is invalid
      const firstWorkspace = workspaces?.[0]
      if (firstWorkspace) setCurrentWorkspaceId(firstWorkspace.id)
    }

    return workspace as ExistingWorkspace
  }, [user, currentWorkspaceId, setCurrentWorkspaceId])

  const [contextState, setContextState] = useState<UserContextType>(() => {
    // Set the anonymous user object based on userId
    const id = ensureCookieUserId()
    const anonymousUser = getAnonymousUser(id)
    return {
      anonymousUser,
      isUserLoading,
      isGammaOrgUser: false,
      color: getColorObject(id),
    }
  })
  const [userAbility, setUserAbility] = useState(
    abilityFactory.createForUser(user as User, config.SHARE_TOKEN)
  )

  useEffect(() => {
    if (!config.IS_CLIENT_SIDE || !isFetched || !user) return
    updateIntercomUser({ user, currentWorkspace })
  }, [isFetched, user, currentWorkspace])

  // Setup intervals to refresh the session cookie
  useEffect(() => {
    // Refresh the token every so often
    // Should align with server/src/identity/jwt.strategy.ts:JwtStrategy.validate
    const REFRESH_TOKEN_INTERVAL = 1000 * 60 * 15
    const refreshTokenInterval = workerTimers.setInterval(
      refetchUser,
      REFRESH_TOKEN_INTERVAL
    )
    return () => workerTimers.clearInterval(refreshTokenInterval)
  }, [])

  // Also refresh the session cookie when we wake up
  useWakeUpDetector(refetchUser)

  useEffect(() => {
    console.debug('[UserContextProvider] user or isUserLoading changed.', {
      user,
      isUserLoading,
    })
    if (isUserLoading) return

    setContextState((prev) => {
      // Clear the currently set user
      Sentry.configureScope((scope) => scope.setUser(null))

      if (!user) {
        Sentry.setUser({
          anonymousUserId: prev?.anonymousUser?.id,
          anonymousUserDisplayName: prev?.anonymousUser?.displayName,
          isAnonymousUser: true,
        })

        return {
          ...prev,
          isUserLoading,
          refetch,
          setCurrentWorkspaceId,
        }
      }
      // Finished loading user and confirmed has account
      Sentry.setUser({
        id: user?.id,
        orgId: currentWorkspace?.id,
      })
      return {
        ...prev,
        user,
        currentWorkspace,
        setCurrentWorkspaceId,
        isUserLoading,
        isGammaOrgUser: user.email.endsWith('@gamma.app'),
        color: getColorObject(user.id),
        refetch,
      }
    })
  }, [user, isUserLoading, refetch, currentWorkspace, setCurrentWorkspaceId])

  useEffect(() => {
    if (!user) return
    setUserAbility(
      abilityFactory.createForUser(user as User, config.SHARE_TOKEN)
    )
  }, [user])

  return (
    <UserContext.Provider value={contextState}>
      <AbilityContext.Provider value={userAbility}>
        {children}
      </AbilityContext.Provider>
    </UserContext.Provider>
  )
}
