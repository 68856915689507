import { Alert, AlertDescription, AlertIcon, VStack } from '@chakra-ui/react'

import { useFeatureFlag } from 'modules/featureFlags'
import { MediaProviderPanelProps } from 'modules/media'
import { useAppSelector } from 'modules/redux'
import { isThemeDark } from 'modules/theming'
import { AccentImageGrid } from 'modules/theming/components/AccentImageGrid'
import { Theme } from 'modules/theming/types'
import { selectTheme } from 'modules/tiptap_editor/reducer'

export const AccentImagePanel = ({
  currentAttributes,
  updateAttributes,
}: MediaProviderPanelProps) => {
  const theme: Theme = useAppSelector(selectTheme)
  const { accentBackgrounds } = theme.config
  const themes2Enabled = useFeatureFlag('themes2')
  const showNoAccentImageAlert =
    !themes2Enabled && (!accentBackgrounds || accentBackgrounds.length === 0)

  return (
    <VStack align="stretch">
      {accentBackgrounds && (
        <AccentImageGrid
          currentAttributes={currentAttributes}
          accentImages={accentBackgrounds}
          isDark={isThemeDark(theme)}
          handleItemClick={updateAttributes}
        />
      )}
      {themes2Enabled && (
        <Alert status="info">
          <AlertIcon />
          <AlertDescription>
            Accent images can be managed in the theme editor.
          </AlertDescription>
        </Alert>
      )}
      {showNoAccentImageAlert && (
        <Alert status="info">
          <AlertIcon />
          <AlertDescription>
            This theme does not have accent images.
          </AlertDescription>
        </Alert>
      )}
    </VStack>
  )
}
