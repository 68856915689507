import { sortBy } from 'lodash'

export type FontSizeOption = {
  label: string
  size: number
  mobileSize?: number
  flagged?: boolean
  type: 'body' | 'heading' | 'title'
  // If it's a heading or title, the level
  level?: number
  inputRegex?: RegExp
}

export const FontSizeOptions: Record<string, FontSizeOption> = {
  sm: {
    label: 'Small',
    size: 0.8,
    flagged: true,
    type: 'body',
  },
  default: {
    label: 'Normal',
    size: 1,
    type: 'body',
  },
  lg: {
    label: 'Large',
    size: 1.25,
    flagged: true,
    type: 'body',
  },
  h4: {
    label: 'Heading 4',
    size: 1.25,
    mobileSize: 1.1,
    level: 4,
    type: 'heading',
    inputRegex: /^####\s$/,
  },
  h3: {
    label: 'Heading 3',
    size: 1.5,
    mobileSize: 1.25,
    level: 3,
    type: 'heading',
    inputRegex: /^###\s$/,
  },
  h2: {
    label: 'Heading 2',
    size: 2,
    mobileSize: 1.5,
    level: 2,
    type: 'heading',

    inputRegex: /^##\s$/,
  },
  h1: {
    label: 'Heading 1',
    size: 2.5,
    mobileSize: 2,
    level: 1,
    type: 'heading',

    inputRegex: /^#\s$/,
  },
  title: {
    label: 'Title',
    size: 3,
    mobileSize: 2.25,
    level: 1,
    type: 'title',
  },
}

export const HeadingSizeOptions = sortBy(
  Object.entries(FontSizeOptions).filter(([_, { type }]) => type === 'heading'),
  ([_, { level }]) => level
)
export const TitleSizeOptions = sortBy(
  Object.entries(FontSizeOptions).filter(([_, { type }]) => type === 'title'),
  ([_, { level }]) => level
)
export const BodySizeOptions = sortBy(
  Object.entries(FontSizeOptions).filter(([_, { type }]) => type === 'body'),
  ([_, { size }]) => size
)
