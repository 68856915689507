import { ButtonGroup } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import {
  EMPTY_GRID_CELL,
  MAX_COLUMNS,
} from 'modules/tiptap_editor/extensions/Layout/constants'

import { DeleteNodeButton } from '../buttons/DeleteNodeButton'
import { DuplicateNodeButton } from '../buttons/DuplicateNodeButton'
import { SelectParentButton } from '../buttons/SelectParentButton'
import { ToolbarButton } from '../buttons/ToolbarButton'
import { FormattingMenuDivider } from './FormattingMenuDivider'
import { NodeFormattingMenuProps } from './types'

export const LayoutCellFormattingMenu = ({
  editor,
  selection,
}: NodeFormattingMenuProps) => {
  const parentLayout = selection.$from.parent
  const canAddCells = parentLayout.childCount < MAX_COLUMNS
  const addColumnBefore = () => {
    editor.chain().insertContentAt(selection.from, EMPTY_GRID_CELL).run()
  }
  const addColumnAfter = () => {
    editor
      .chain()
      .insertContentAt(
        selection.from + selection.node.nodeSize,
        EMPTY_GRID_CELL
      )
      .run()
  }

  const handleDelete = () => {
    editor
      .chain()
      .selectInsideNodeAtPos(selection.from)
      .handleLayoutDelete(true, false)
      .run()
  }

  return (
    <ButtonGroup spacing={1} size="sm" alignItems="center">
      <SelectParentButton
        editor={editor}
        label="Edit layout..."
        icon={regular('columns')}
      />
      {canAddCells && (
        <>
          <FormattingMenuDivider />
          <ToolbarButton
            label="Add column before"
            icon={regular('diagram-cells')}
            iconRotation={270}
            onClick={addColumnBefore}
            testId="add-col-before-button"
          />
          <ToolbarButton
            label="Add column after"
            icon={regular('diagram-cells')}
            iconRotation={90}
            onClick={addColumnAfter}
            testId="add-col-after-button"
          />
          <DuplicateNodeButton editor={editor} selection={selection} />
        </>
      )}
      <FormattingMenuDivider />
      <DeleteNodeButton editor={editor} handleDelete={handleDelete} />
    </ButtonGroup>
  )
}
