import {
  Box,
  CloseButton,
  ToastId,
  ToastProps,
  useToast,
} from '@chakra-ui/react'
import { useEffect, useRef } from 'react'

import { Doc } from 'modules/api'
import { PANEL_MIN_WIDTH } from 'modules/panels/constants'
import { useUserContext } from 'modules/user'

import { AccessLinkSignUp, AccessLinkSignUpProps } from './AccessLinkSignUp'

import { checkAccessLinkForLoggedOutUser, DocWithIdAndAccessLinks } from '.'

type ToastSignUpProps = {
  onClose: ToastProps['onClose']
  doc: Doc
} & Pick<AccessLinkSignUpProps, 'doc' | 'accessLink' | 'refetchFn'>

const ToastSignUp = ({ onClose, doc, ...rest }: ToastSignUpProps) => {
  return (
    <Box
      bg="white"
      border="0.5px solid var(--chakra-colors-gray-100)"
      borderRadius="md"
      shadow="md"
      maxWidth={`calc(${PANEL_MIN_WIDTH}px - 1rem)`}
      display="column"
      p={4}
      position="relative"
    >
      <CloseButton
        size="sm"
        onClick={onClose}
        position="absolute"
        top={2}
        right={2}
      />
      <AccessLinkSignUp {...rest} doc={doc} spacing={4} />
    </Box>
  )
}

export const useToastSignUp = (
  doc: DocWithIdAndAccessLinks | undefined,
  enabled: boolean
) => {
  const { isUserLoading, user, refetch } = useUserContext()
  const accessLink = checkAccessLinkForLoggedOutUser(doc, user)
  const toast = useToast()
  const toastIdRef = useRef<ToastId>()

  useEffect(() => {
    const showToastSignUp =
      enabled &&
      !user &&
      !isUserLoading &&
      doc &&
      accessLink &&
      accessLink.permission !== 'view'

    if (toastIdRef.current) {
      toast.close(toastIdRef.current)
    }
    if (!showToastSignUp) return

    toastIdRef.current = toast({
      position: 'bottom-left',
      render: ({ onClose }) =>
        ToastSignUp({ refetchFn: refetch, doc, accessLink, onClose }),
      isClosable: true,
      duration: null,
    })
  }, [refetch, user, isUserLoading, accessLink, toast, doc, enabled])
}
