import { Box, BoxProps, HStack, Link, Spinner, Text } from '@chakra-ui/react'
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome'
import { forwardRef } from 'react'

import { config } from 'config'

const mentionWrapperStyles = {
  borderRadius: 'md',
  padding: '0.05em 0.1em',
  display: 'inline',
  color: 'gray.800',
  cursor: 'default',
}
type MentionTagProps = BoxProps & {
  mentionLabel: string
  mentionIcon?: FontAwesomeIconProps['icon']
  mentionTargetUrl?: string
  hasError?: boolean
}
export const MentionTag = forwardRef<any, MentionTagProps>(
  (
    { mentionLabel, mentionIcon, mentionTargetUrl, hasError = false, ...rest },
    ref
  ) => {
    return (
      <Box
        ref={ref}
        position="relative"
        as="span"
        data-selection-ring
        className="mention"
        {...mentionWrapperStyles}
        color={hasError ? 'gray.600' : mentionWrapperStyles.color}
        {...rest}
      >
        <HStack display="inline-flex" spacing={0}>
          {mentionIcon && (
            <Box display="inline-block" textAlign="center" minWidth={4}>
              <FontAwesomeIcon
                icon={mentionIcon}
                fixedWidth
                size="sm"
                style={{ marginLeft: '0.1em', marginRight: '0.3em' }}
              />
            </Box>
          )}
          <Text
            noOfLines={1}
            whiteSpace="normal"
            wordBreak="break-all"
            flex={1}
            lineHeight="normal"
          >
            {mentionLabel}
          </Text>
        </HStack>
        {config.GAMMA_PUPPETEER_SERVICE && mentionTargetUrl && (
          <Link
            href={mentionTargetUrl}
            h="100%"
            w="100%"
            position="absolute"
            zIndex="-10"
            data-pdf-link
            top="0"
            left="0"
          />
        )}
      </Box>
    )
  }
)

MentionTag.displayName = 'MentionTag'

export const MentionTagLoading = () => {
  return (
    <Box as="span" className="mention" {...mentionWrapperStyles}>
      <Spinner size="xs" opacity="0.8" margin="0 0.1em" />
    </Box>
  )
}
