import { ArrowBackIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Divider,
  HStack,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  Text,
  VStack,
} from '@chakra-ui/react'
import { SectionTitle } from '@gamma-app/ui'
import { useMemo } from 'react'

import { Doc, useGetDocCardsAnalyticsQuery } from 'modules/api'
import { useAppStore } from 'modules/redux'
import { selectCardIdMap } from 'modules/tiptap_editor/reducer'

import { DisplayUser } from '../types'
import { createCardLookupTable } from '../utils'
import { AnalyticsError } from './AnalyticsError'
import {
  HorizontalBarCardTreeDescription,
  HorizontalBarCardTreeSkeleton,
  ViewTimePercentTooltip,
} from './CardEngagement'
import { HorizontalBarCardTree } from './HorizontalBarCardTree'
import { UserAvatarBlock } from './ViewersTable'

export const UserCardDrilldown = ({
  user,
  onBackClick,
  docId,
  onAnalyticsPanelClose,
}: {
  user: DisplayUser
  onBackClick: () => void
  docId: Doc['id']
  onAnalyticsPanelClose: () => void
}) => {
  const reduxStore = useAppStore()
  const state = reduxStore.getState()
  const cardIdMap = selectCardIdMap(state)
  const {
    data: docCardsData,
    error: docCardsError,
    loading: docCardsLoading,
    refetch,
  } = useGetDocCardsAnalyticsQuery({
    variables: { docId, userId: user.id },
    skip: !docId || !user.id,
    // re-get analytics every 15 minutes
    pollInterval: 1000 * 60 * 15,
    // This ensures that the value of isAnalyticsLoading updates on refetch
    notifyOnNetworkStatusChange: true,
  })

  const cardsViewed = docCardsData?.docCardsStats?.everyone?.length ?? 0
  const totalCards = docCardsData?.docCardsStats?.cardCount
  const cardStats = useMemo(
    () => docCardsData?.docCardsStats?.everyone || [],
    [docCardsData?.docCardsStats]
  )
  const cardLookupTable = useMemo(
    () => createCardLookupTable(cardStats, 'viewTimePercent'),
    [cardStats]
  )

  return (
    <>
      <ModalHeader>
        <Button
          aria-label="Back"
          leftIcon={<ArrowBackIcon />}
          variant="ghost"
          onClick={onBackClick}
        >
          Back
        </Button>
      </ModalHeader>
      <ModalCloseButton />
      <Divider />
      <ModalBody>
        <Box>
          <CardDrilldownUserInfo user={user} showLastOpened />
          {totalCards && (
            <HorizontalBarCardTreeDescription
              description={`Viewed ${cardsViewed}/${totalCards} cards
          across all sessions.`}
            />
          )}
          {docCardsLoading && !docCardsError && (
            <HorizontalBarCardTreeSkeleton includeDescription />
          )}
          {docCardsError && (
            <AnalyticsError error={docCardsError} refetch={refetch} />
          )}
          {!docCardsError && !docCardsLoading && (
            <HorizontalBarCardTree
              cards={cardIdMap.tree}
              scaleToMax
              includeChildren
              leftLabel="Less time spent"
              rightLabel="More time spent"
              cardLookupTable={cardLookupTable}
              tooltipLabel={<ViewTimePercentTooltip />}
              onAnalyticsPanelClose={onAnalyticsPanelClose}
            />
          )}
        </Box>
      </ModalBody>
    </>
  )
}

export const CardDrilldownUserInfo = ({
  user,
  showLastOpened,
}: {
  user: DisplayUser
  showLastOpened?: boolean
}) => {
  return (
    <HStack align="center" my={6}>
      <UserAvatarBlock
        name={user.name}
        profileImg={user.profileImg}
        email={user.email}
        isCreator={user.isCreator}
        isContributor={user.isContributor}
        width={showLastOpened ? '50%' : undefined}
      />
      {showLastOpened && (
        <VStack align="flex-start" spacing={0}>
          <SectionTitle>Last opened</SectionTitle>
          <Text>{user.lastOpened}</Text>
        </VStack>
      )}
    </HStack>
  )
}
