import { EditorState, PluginKey, Transaction } from 'prosemirror-state'
import * as Y from 'yjs'

import {
  absoluteToRelativePos,
  relativeToAbsolutePos,
} from 'modules/tiptap_editor/utils/relativePosition'

export const LayoutResizingPluginKey = new PluginKey<LayoutResizingState>(
  'layoutResizing'
)

export type DraggingState = {
  startX: number
  startWidth: number
  colWidths: number[]
  colIndex: number
  tableWidth: number
}
export type LayoutResizingSetHandleEvent = {
  setHandle: number | null
}
export type LayoutResizingSetDraggingEvent = {
  setDragging: DraggingState | null
}
export type LayoutResizingResetEvent = {
  reset: true
}

type LayoutResizingEvent =
  | LayoutResizingSetDraggingEvent
  | LayoutResizingSetHandleEvent
  | LayoutResizingResetEvent

export class LayoutResizingState {
  constructor(
    public activeHandle: Y.RelativePosition | null = null,
    public dragging: DraggingState | null = null
  ) {}

  getActiveHandleAbs(state: EditorState): number | null {
    if (!this.activeHandle) {
      return null
    }
    return relativeToAbsolutePos(state, this.activeHandle)
  }

  reset(): this {
    this.activeHandle = null
    this.dragging = null
    return this
  }

  setHandle(state: EditorState, event: LayoutResizingSetHandleEvent): this {
    if (event.setHandle === null) {
      this.activeHandle = null
      return this
    }
    this.activeHandle = absoluteToRelativePos(state, event.setHandle)
    return this
  }

  setDragging(event: LayoutResizingSetDraggingEvent): this {
    this.dragging = event.setDragging
    return this
  }

  apply(tr: Transaction, state: EditorState): this {
    const action = tr.getMeta(
      LayoutResizingPluginKey
    ) as LayoutResizingEvent | null

    if (action && 'setHandle' in action) {
      return this.setHandle(state, action)
    }
    if (action && 'setDragging' in action) {
      return this.setDragging(action)
    }
    if (action && 'reset' in action) {
      return this.reset()
    }

    // for other changes we count on Y.RelativePosition to hold it's position
    // correctly
    return this
  }
}
