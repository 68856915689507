import { CheckIcon } from '@chakra-ui/icons'
import { Button, ButtonGroup, HStack } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Attrs } from 'prosemirror-model'
import { useCallback } from 'react'

import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownList,
} from 'modules/tiptap_editor/components/menus/ToolbarDropdown'

import { SmartLayoutAttrs } from '../../../extensions/SmartLayout/types'
import {
  getSmartLayoutVariant,
  SmartLayoutVariants,
} from '../../../extensions/SmartLayout/variants'
import { DeleteNodeButton } from '../buttons/DeleteNodeButton'
import { FormattingMenuDivider } from './FormattingMenuDivider'
import { NodeFormattingMenuProps } from './types'

export const SmartLayoutFormattingMenu = ({
  editor,
  selection,
}: NodeFormattingMenuProps) => {
  const { node, from: pos } = selection
  const { options, variantKey } = node.attrs as SmartLayoutAttrs
  const variant = getSmartLayoutVariant(variantKey)
  const updateAttributes = useCallback(
    (attrs: Attrs) => {
      editor
        .chain()
        .updateAttributes('smartLayout', attrs)
        .selectNodeAtPos(pos) // The dropdown steals focus, this restores it
        .focus()
        .run()
    },
    [editor, pos]
  )

  const updateVariant = useCallback(
    (key: string) => {
      updateAttributes({ variantKey: key })
    },
    [updateAttributes]
  )
  return (
    <HStack spacing={0}>
      <Dropdown isLazy>
        <DropdownButton
          as={Button}
          variant="toolbar"
          rightIcon={<FontAwesomeIcon icon={regular('chevron-down')} />}
          leftIcon={<FontAwesomeIcon icon={variant.icon} fixedWidth />}
          mr={2}
          size="sm"
          data-testid="smart-layout-variant-dropdown"
        >
          {variant.name}
        </DropdownButton>
        <DropdownList
          maxH="min(25em, 45vh)"
          overflowY="auto"
          data-in-editor-focus
          fontSize="md"
        >
          {SmartLayoutVariants.map(({ key, name, icon, disabled }) => {
            if (disabled) return null
            const isChecked = variantKey === key
            return (
              <DropdownItem
                key={key}
                data-test-variant-option={key}
                icon={
                  isChecked ? (
                    <CheckIcon />
                  ) : (
                    <FontAwesomeIcon icon={icon} fixedWidth />
                  )
                }
                onClick={() => updateVariant(key)}
              >
                {name}
              </DropdownItem>
            )
          })}
        </DropdownList>
      </Dropdown>
      <ButtonGroup size="sm">
        {variant.options.map(({ key, Control, defaultValue }) => {
          const value = options[key] ?? defaultValue
          const updateValue = (newValue: any) => {
            updateAttributes({
              options: { ...options, [key]: newValue },
            })
          }
          return <Control key={key} value={value} updateValue={updateValue} />
        })}
        {/* <FullWidthButton editor={editor} selection={selection} /> */}
      </ButtonGroup>
      <FormattingMenuDivider />
      <DeleteNodeButton editor={editor} />
    </HStack>
  )
}
