import * as Sentry from '@sentry/nextjs'
import { Extension } from '@tiptap/core'
import { Transaction, Plugin, PluginKey } from 'prosemirror-state'
import { AttrStep, ReplaceStep, ReplaceAroundStep } from 'prosemirror-transform'

import { analytics, AppMonitoringEvents } from 'modules/segment'

import { ExtensionPriorityMap } from '../../constants'
import { CardIdsPluginState } from './CardIdsPluginState'

export const CardIdsPluginKey = new PluginKey<CardIdsPluginState>('cardIds')

export interface CardIdsExtensionOptions {
  enabled: boolean
}

export const CardIdsExtension = Extension.create<CardIdsExtensionOptions>({
  name: 'cardIds',
  priority: ExtensionPriorityMap.CardIds,

  addOptions() {
    return {
      enabled: true,
    }
  },

  addProseMirrorPlugins() {
    if (!this.options.enabled) {
      return []
    }

    return [
      new Plugin({
        key: CardIdsPluginKey,

        state: {
          init() {
            return new CardIdsPluginState()
          },

          apply(transaction, pluginState, oldEditorState, newEditorState) {
            return pluginState.apply(transaction, newEditorState)
          },
        },

        // Use appendTransaction because thats what the UniqueAttribute
        // extension does. This ensures our duplicate cardId check
        // happens after UniqueAttribute has its chance to run.
        appendTransaction(transactions, oldState, newState) {
          const state = CardIdsPluginKey.getState(newState)
          if (!state?.value?.cardIds) return null
          const docChanged = transactions.some((tr) => tr.docChanged)

          /**
           * If the most recent transaction includes new duplicate cardIds,
           * send an event to Sentry with additional metadata for debugging.
           */
          if (docChanged && state.duplicateCardIds.length) {
            // TODO - Investigate "repairing" cards with duplicate IDs as there are still
            //        some edge cases where you can get into this state (notably via y-sync remote changes)
            // https://linear.app/gamma-app/issue/G-1431/investigate-repairing-docs-when-duplicate-card-ids-are-detected
            const errorData = {
              // I (James) couldnt figure out how to send deep objects to Sentry
              // without stringifying them first. This is a bit of a hack.
              // Example event: https://sentry.io/organizations/gamma-tech/issues/3760758460/?environment=development&project=5776661&query=is%3Aunresolved&referrer=issue-stream&statsPeriod=1h#extra
              duplicateIds: JSON.stringify(state.duplicateCardIds),
              transactions: JSON.stringify(serializeTransactions(transactions)),
            }
            analytics?.track(AppMonitoringEvents.DUPLICATE_CARD_IDS, {
              ...errorData,
            })
            Sentry.captureException(
              '[generateCardIdMap] Duplicate cardIds detected. This is unexpected and will cause spotlight issues. Additional metadata:',
              {
                extra: errorData,
              }
            )
          }

          return null
        },
      }),
    ]
  },
})

export const serializeTransactions = (transactions: readonly Transaction[]) => {
  return transactions.map((tr) => {
    const stepsData = tr.steps.map((step) => {
      if (step instanceof ReplaceStep || step instanceof ReplaceAroundStep) {
        const sliceContent = step.slice.toJSON()?.content?.[0] || {}
        const sliceContentSimple = {
          type: sliceContent.type,
          attrs: sliceContent.attrs,
          contentLength: sliceContent.content?.length,
        }
        return {
          name: step.constructor.name,
          from: step.from,
          to: step.to,
          sliceContentSimple,
        }
      } else if (step instanceof AttrStep) {
        return {
          name: step.constructor.name,
          pos: step.pos,
          attr: step.attr,
        }
      }
      return {
        name: step.constructor.name,
      }
    })

    const metaData = Object.fromEntries(
      // @ts-ignore - tr.meta is private
      Object.entries(tr.meta).map(
        ([key, value]: [string, Record<string, any>]) => {
          if (key === 'annotationEvent') {
            return [
              key,
              {
                type: value.type,
                droppedBlockPos: value.droppedBlockPos,
                dragging: {
                  inBlockLength: value.dragging?.inBlock?.length,
                  inCardLength: value.dragging?.inCard?.length,
                  origNodePos: value.dragging?.origNodePos,
                },
              },
            ]
          } else if (key === 'uiEvent') {
            return [key, value]
          }

          return [key, typeof value === 'object' ? 'object' : value]
        }
      )
    )
    return {
      stepsData,
      docChanged: tr.docChanged,
      selection: {
        from: tr.selection.from,
        to: tr.selection.to,
      },
      selectionSet: tr.selectionSet,
      metaData,
    }
  })
}
