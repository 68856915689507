import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from 'modules/redux'

import { ManageCardMenuOptions } from './ManageCardMenu'

export type PopoverState = {
  id: string | null
  isOpen: boolean
  ref: any | null
  lastRef: any | null
  options: (ManageCardMenuOptions & { onClose: () => void }) | null
}

const initialState: PopoverState = {
  id: null,
  isOpen: false,
  ref: null,
  lastRef: null,
  options: null,
}

const ManageCardMenuSlice = createSlice({
  name: 'ManageCardMenu',
  initialState,
  reducers: {
    clearLastRef(state) {
      state.lastRef = null
    },
    closeMenu(state, action: PayloadAction<{ saveRef: boolean }>) {
      state.id = null
      state.isOpen = false
      // save ref to check for closing self
      state.lastRef = action.payload.saveRef ? state.ref : null
      state.ref = null
      const { onClose } = state.options || {}
      state.options = null
      if (onClose) {
        onClose()
      }
    },
    updateCardOptions(
      state: PopoverState,
      action: PayloadAction<Partial<PopoverState['options']>>
    ) {
      if (!state.options) {
        return
      }
      state.options = {
        ...state.options,
        ...action.payload,
      }
    },
    showMenu(
      state: PopoverState,
      action: PayloadAction<
        {
          ref: HTMLElement
          id?: string | null
        } & PopoverState['options']
      >
    ) {
      state.isOpen = true
      const { ref, id, ...options } = action.payload
      state.id = id ? id : null
      state.ref = ref
      state.lastRef = null
      state.options = options
    },
  },
})

export const {
  showMenu: showManageCardMenu,
  closeMenu: closeManageCardMenu,
  updateCardOptions: updateManageCardMenuOptions,
  clearLastRef: clearLastManageCardMenuRef,
} = ManageCardMenuSlice.actions

type SliceState = Pick<RootState, 'ManageCardMenu'>

// Selectors
export const selectManageCardMenuState = (state: SliceState): PopoverState => {
  return state.ManageCardMenu
}

export const selectIsManageCardMenuOpen =
  (idToCheck: string) =>
  (state: SliceState): boolean => {
    const { id, isOpen } = state.ManageCardMenu
    return isOpen && id === idToCheck
  }

// Reducer
export const ManageCardMenuReducer = ManageCardMenuSlice.reducer
