import { cx } from '@chakra-ui/utils'
import {
  callOrReturn,
  Editor,
  Extension,
  getExtensionField,
  NodeViewProps,
} from '@tiptap/core'
import { Node } from 'prosemirror-model'
import { Plugin, PluginKey, Selection } from 'prosemirror-state'
import { Decoration, DecorationSet } from 'prosemirror-view'

import { getStore } from 'modules/redux'
import { getScrollManager, isInViewport } from 'modules/scroll'
import { analytics, SegmentEvents } from 'modules/segment'

import {
  selectMode,
  setFollowingAttached,
  setMediaNodeExpanded,
} from '../../reducer'
import { EditorModeEnum } from '../../types'
import { getDomNodeFromPos, getTopOrBottomCenterNode } from '../../utils'
import { PresentationSelection } from '../../utils/selection/PresentationSelection'
import { isCardCollapsed } from '../Card/CardCollapse'
import { findCardById, getFullHeightCardNode } from '../Card/utils'
import { findNextNode } from '../spotlight/Spotlight'
import {
  SpotlightPluginKey,
  SpotlightPluginState,
} from '../spotlight/spotlightPlugin'
import { isToggleOpen, setToggleOpen } from '../Toggle/utils'

type ExpandableNodesPluginState = {
  lastApplied: number
  shouldAddResetClass: boolean
}
const ExpandableNodesPluginKey = new PluginKey<ExpandableNodesPluginState>(
  'expandableNodes'
)

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    interactiveNodes: {
      goToNextExpandableNode: (reverse?: boolean) => ReturnType
      expandOrCollapseExpandableNode: (pos: number | null) => ReturnType
      reHighlightExpandableNode: () => ReturnType
    }
  }
}

export const isExpandableSelectedNode = (
  decorations: NodeViewProps['decorations']
) => decorations.some((decoration) => decoration.spec.isExpandableNode)

export const isSelectionExpandable = (selection: Selection) => {
  return (
    selection instanceof PresentationSelection &&
    selection.side === 0 &&
    isNodeExpandable(selection.node)
  )
}

export const isNodeExpandable = (node: Node) => {
  return node.type.spec.expandable === true
}

const TIME_TO_STALE = 3000

const isSelectionInView = (editor: Editor, sel: Selection | null) => {
  if (!sel?.from) return false
  const nearestDomNode = getDomNodeFromPos(editor, sel.from)
  const inView = isInViewport(nearestDomNode)
  return inView?.inView === true
}

const isCardInView = (editor: Editor, pos: number, side: 'top' | 'bottom') => {
  const nodeDom = getFullHeightCardNode(
    editor.view.nodeDOM(pos) as HTMLElement | null
  )
  const inViewData = isInViewport(nodeDom)
  if (!inViewData) return false
  const { bottomInView, topInView } = inViewData

  return side === 'top' ? topInView : bottomInView
}

const getStartingPos = (
  editor: Editor,
  lowerBound: number,
  upperBound: number,
  reverse = false
) => {
  const selection = editor.state.selection
  const posToUse =
    selection instanceof PresentationSelection && selection.side === 1
      ? selection.to
      : selection.from

  if (posToUse <= lowerBound) {
    const topOfCardInView = isCardInView(editor, lowerBound, 'top')
    if (topOfCardInView) {
      return null
    }
  } else if (posToUse >= upperBound) {
    const bottomOfCardInView = isCardInView(editor, lowerBound, 'bottom')
    if (bottomOfCardInView) {
      return null
    }
  } else {
    if (isSelectionInView(editor, selection)) {
      // The current selection suffices to choose the next node from
      return null
    }
    const $pos = editor.state.doc.resolve(posToUse)
    if (isSelectionInView(editor, Selection.findFrom($pos, -1, false))) {
      // The selection looking backwards is in view
      return null
    }

    if (isSelectionInView(editor, Selection.findFrom($pos, 1, false))) {
      // The selection looking forwards is in view
      return null
    }
  }
  // We must have scrolled away, so start from a new position
  const centerEdgeNode = getTopOrBottomCenterNode({
    editor,
    side: reverse ? 'bottom' : 'top',
    margin: 200,
  })
  if (centerEdgeNode.pos) {
    const nearestCenter = Selection.findFrom(
      editor.state.doc.resolve(centerEdgeNode.pos),
      reverse ? 1 : -1, // Move away from the direction we actually want to go
      false
    )
    return nearestCenter?.from || null
  }
  return null
}

/**
 * In present mode, decorate the selected node with a class
 * if it can be expanded.
 */
export const ExpandableNodes = Extension.create({
  name: 'expandableNodes',

  extendNodeSchema(extension) {
    return {
      expandable:
        callOrReturn(getExtensionField(extension, 'expandable', extension)) ??
        false,
    }
  },

  addCommands() {
    return {
      expandOrCollapseExpandableNode:
        (pos) =>
        ({ state, chain }) => {
          const posToUse = pos ?? state.selection.from
          const node = state.doc.nodeAt(posToUse)

          if (!node) return false
          if (!isNodeExpandable(node)) return false

          const store = getStore()
          switch (node.type.name) {
            case 'card':
              store.dispatch(setFollowingAttached({ attached: false }))
              analytics?.track(SegmentEvents.CARD_EXPANDED, {
                is_present_mode: true,
                method: 'enter_key',
              })
              if (isCardCollapsed(node.attrs.id)) {
                return chain()
                  .descendIntoCurrentCard(posToUse)
                  .reHighlightExpandableNode()
                  .run()
              }
              return chain()
                .spotlightCollapseCard(posToUse)
                .reHighlightExpandableNode()
                .run()

            case 'toggle': {
              setToggleOpen(node.attrs.id, !isToggleOpen(node.attrs.id))

              const toggleChain = chain()
              if (pos) {
                toggleChain.spotlightNextBlock()
              }
              toggleChain.reHighlightExpandableNode().run()
              return true
            }

            case 'image':
            case 'video':
            case 'embed':
              store.dispatch(setMediaNodeExpanded({ nodeId: node.attrs.id }))
              return true

            case 'gallery': {
              const firstElement = node.firstChild
              if (firstElement) {
                store.dispatch(
                  setMediaNodeExpanded({ nodeId: firstElement.attrs.id })
                )
                return true
              }
              return false
            }

            default:
              return false
          }
        },
      goToNextExpandableNode:
        (reverse?: boolean) =>
        ({ editor, tr, state }) => {
          const lastSpotlight = SpotlightPluginKey.getState(
            editor.state
          ) as SpotlightPluginState
          const parentCard = findCardById(editor, lastSpotlight.cardId)
          if (!parentCard) return false

          const parentCardId = parentCard.node.attrs.id
          const parentCardFromPos = parentCard.pos
          const parentCardToPos = parentCard.pos + parentCard.node.nodeSize

          const selection = editor.state.selection
          const selectionIsExpandable = isSelectionExpandable(selection)
          const isPresentationSelection =
            selection instanceof PresentationSelection

          // If the current selection (interactive or not), is not visible
          // in the viewport, then pick a pos to start from
          const overridePos = getStartingPos(
            editor,
            parentCardFromPos,
            parentCardToPos,
            reverse
          )

          const lastApplied =
            ExpandableNodesPluginKey.getState(state)?.lastApplied || +new Date()
          const shouldRehighlight =
            selectionIsExpandable &&
            !overridePos &&
            +new Date() - lastApplied > TIME_TO_STALE

          if (shouldRehighlight) {
            return editor.commands.reHighlightExpandableNode()
          }
          tr.setMeta(ExpandableNodesPluginKey, {}) // Ensure lastApplied is updated

          const maybeMinusOne =
            isPresentationSelection &&
            ((selection.side === -1 && reverse) ||
              (selection.side === 1 && !reverse))
              ? -1
              : 0
          const fromToUse = overridePos
            ? overridePos
            : selectionIsExpandable || !isPresentationSelection
            ? selection.from
            : selection.side === 1
            ? selection.to + maybeMinusOne
            : selection.from + maybeMinusOne

          const posToUse = Math.min(
            Math.max(fromToUse, parentCardFromPos),
            parentCardToPos - 1
          )

          const nextExpandable = findNextNode(
            editor,
            parentCardId,
            posToUse,
            isNodeExpandable,
            reverse
          )

          console.debug(
            '[ExpandableNodes] goToNextExpandableNode',
            { posToUse, overridePos },
            nextExpandable
          )

          if (
            !nextExpandable.pos ||
            !nextExpandable.domNode ||
            nextExpandable.cardId !== parentCardId
          ) {
            // Theres no interactive node to select.
            // Select the beginning or end of the current presenting card.
            tr.setSelection(
              PresentationSelection.create(
                editor.state.doc,
                parentCardFromPos,
                reverse ? -1 : 1
              )
            )
            return false
          }

          const inViewData = isInViewport(nextExpandable.domNode, 50)
          if (inViewData?.inView) {
            tr.setSelection(
              PresentationSelection.create(editor.state.doc, nextExpandable.pos)
            )
            getScrollManager('editor').scrollElementIntoView({
              element: nextExpandable.domNode,
              attempts: 0,
              offsetFromTop: null,
            })
            return true
          }
          if (selectionIsExpandable) {
            // Select the beginning or end of the existing interactive node
            tr.setSelection(
              PresentationSelection.create(
                editor.state.doc,
                selection.from,
                reverse ? -1 : 1
              )
            )
          }
          return false
        },
      reHighlightExpandableNode:
        () =>
        ({ tr, editor }) => {
          tr.setMeta(ExpandableNodesPluginKey, {
            shouldAddResetClass: true,
          })
          requestAnimationFrame(() => {
            editor.commands.command(({ tr: rafTr }) => {
              rafTr.setMeta(ExpandableNodesPluginKey, {
                shouldAddResetClass: false,
              })
              return true
            })
          })
          return true
        },
    }
  },

  addProseMirrorPlugins() {
    return [
      new Plugin<ExpandableNodesPluginState>({
        key: ExpandableNodesPluginKey,

        state: {
          init() {
            return {
              lastApplied: 0,
              shouldAddResetClass: false,
            }
          },

          apply(transaction, pluginState) {
            const meta = transaction.getMeta(ExpandableNodesPluginKey)
            if (!meta) return pluginState
            return {
              ...pluginState,
              lastApplied: +new Date(),
              ...meta,
            }
          },
        },
        props: {
          decorations(state) {
            // Don't highlight expandable nodes when spotlighting
            const spotlightState = SpotlightPluginKey.getState(state)
            if (spotlightState?.pos) {
              return DecorationSet.empty
            }
            const pluginState = ExpandableNodesPluginKey.getState(state)
            const mode = selectMode(getStore().getState())
            if (mode !== EditorModeEnum.SLIDE_VIEW) return DecorationSet.empty

            const selection = state.selection
            if (
              !isSelectionExpandable(selection) ||
              !(selection instanceof PresentationSelection) // This check is redundant (is just here to please typescript)
            ) {
              return DecorationSet.empty
            }

            const node = selection.node
            return DecorationSet.create(state.doc, [
              Decoration.node(
                selection.from,
                selection.from + node.nodeSize,
                {
                  class: cx(
                    'expandable-node-selected',
                    pluginState?.shouldAddResetClass && 'expandable-node-reset'
                  ),
                },
                {
                  isExpandableNode: true,
                }
              ),
            ])
          },
        },
      }),
    ]
  },
})
