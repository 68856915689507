import { config } from 'config'

import { ContainerWidth } from '../../styles/containerStyles'

export const CARD_DEPTH = 2 // Doc > Document > Card, so card is 2 levels below the root
export const CARD_NODE_NAME = 'card'

// Passing '*' will return any card
export const getCardSelector = (cardIdOrStar: string) => {
  return cardIdOrStar === '*'
    ? '[data-card-id]'
    : `[data-card-id="${cardIdOrStar}"]`
}
export const getCardBodySelector = (cardId: string) =>
  `[data-card-body="${cardId}"]`

// Classes
// -------
export const CARD_WRAPPER_CLASS = 'card-wrapper'
export const CARD_PRESENTING_CLASS = 'card-presenting'
export const CARD_BODY_CLASS = 'card-body'
export const CARD_BACKGROUND_CLASS = 'card-background'
export const CARD_CONTAINER_CLASS = 'card-container'
export const CARD_CONTENT_CLASS = 'card-content'

// Sizes
// -----
export const TOOLBAR_HEIGHT = 42 // In px. Duplicated from sections/docs/editor/components/Toolbar/index.tsx because its outside our module boundary
export const NESTED_CARD_HOVER_SCALE = 1.02
// Units below are in rems, but will be multiplied by the zoom factor based on doc/present mode and screen width
export const CARD_WIDTH = 40.25
export const CARD_GRID_GUTTER = 2.5 // Should evenly divide the card width into 12 columns. http://gridcalculator.dk/#/644/12/40/0
export const NESTED_CARD_OUTDENT = 0.5
export const CARD_BACKGROUND_PADDING_RESPONSIVE = { base: 5, lg: 8, '3xl': 10 }

export const CARD_WIDTHS: Record<ContainerWidth, number> = {
  sm: 32,
  md: CARD_WIDTH,
  lg: 56,
  full: 96,
}

export const ZOOM_LEVEL_DEFAULT = 1
export const ZOOM_LEVEL_MIN = 0.5
export const ZOOM_LEVEL_MAX = 2

// Animation Timing
// ----------------
const DEBUG_FACTOR =
  typeof config.EDITOR_ANIMATION_DEBUG_FACTOR === 'number'
    ? config.EDITOR_ANIMATION_DEBUG_FACTOR
    : 1

// Used for expanding a nested card in doc mode + toggling doc <> present mode
export const EXPAND_CARD_TRANSITION_TIME = 225 * DEBUG_FACTOR
export const EXPAND_CARD_FRAMER_TRANSITION = {
  duration: EXPAND_CARD_TRANSITION_TIME / 1000,
  type: 'spring',
  bounce: 0,
}

// Used for next/previous in present mode
export const BETWEEN_CARD_TRANSITION_TIME = 200 * DEBUG_FACTOR
export const BETWEEN_CARDS_FRAMER_TRANSITION = {
  default: {
    duration: BETWEEN_CARD_TRANSITION_TIME / 1000,
  },
  x: {
    type: 'spring',
    duration: BETWEEN_CARD_TRANSITION_TIME / 1000,
    bounce: 0,
  },
}
