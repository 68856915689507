import { ButtonGroup, Flex, IconButton, Input } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'
import { NodeViewProps } from '@tiptap/react'

import { getMediaSourceUrl } from '../utils'

type NavMenuProps = {
  frameRef: React.RefObject<HTMLIFrameElement>
  isDark?: boolean
}

export const NavMenu = ({
  frameRef,
  node,
  isDark,
}: NavMenuProps & Pick<NodeViewProps, 'node'>) => {
  const reload = () => {
    // To force a reload, we have to briefly change the URL to something else and switch back
    if (!frameRef.current) return
    const oldSrc = frameRef.current.src
    frameRef.current.src = 'about:blank'
    setTimeout(() => {
      if (!frameRef.current) return
      frameRef.current.src = oldSrc
    }, 50)
  }

  const displayUrl = getMediaSourceUrl(node.attrs)

  return (
    <Flex
      direction="row"
      background={isDark ? 'gray.800' : 'gray.50'}
      align="center"
      borderBottom="1px solid"
      borderColor={isDark ? 'gray.600' : 'gray.200'}
      w="100%"
      p={1}
      zIndex={2} // Go over ZoomClickCapture
      position="relative"
    >
      {/* We use an input here to support copying the URL and truncating it, but it isn't editable because the value is forced to the URL attribute. */}
      <Input
        size="xs"
        value={displayUrl}
        border="0"
        noOfLines={1}
        color={isDark ? 'gray.200' : 'gray.600'}
        pointerEvents="none"
        readOnly
      />
      <ButtonGroup
        size="xs"
        spacing={1}
        variant="ghost"
        colorScheme="gray"
        ml={2}
      >
        <GammaTooltip label="Reload" placement="top">
          <IconButton
            isRound
            aria-label="Reload"
            icon={<FontAwesomeIcon icon={regular('redo')} />}
            onClick={reload}
          />
        </GammaTooltip>
        <GammaTooltip label="Open in new tab" placement="top">
          <IconButton
            isRound
            aria-label="Open in new tab"
            icon={<FontAwesomeIcon icon={regular('external-link')} />}
            onClick={() => {
              window.open(displayUrl, '_blank')
            }}
          />
        </GammaTooltip>
      </ButtonGroup>
    </Flex>
  )
}
