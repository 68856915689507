import { lightenColor } from 'utils/color'

import { ThemeBase } from '../types'
import { DefaultThemeBase } from './default'

export const IcebreakerThemeBase: ThemeBase = {
  ...DefaultThemeBase,
  key: 'icebreaker',
  name: 'Icebreaker',
  description: 'Clean edges with professional monochrome colors',
  getCSSVars(cardColor, accentColor) {
    return {
      ...DefaultThemeBase.getCSSVars(cardColor, accentColor),
      '--box-background-color': lightenColor(cardColor, -5),
      '--interactive-border': `1px solid var(--accent-color-border)`,
      '--box-border-radius': '0.5em',
    }
  },
  boxSx: {
    border: '0px',
    borderRadius: 'var(--box-border-radius)',
  },
  cardSx: {
    borderRadius: 'var(--box-border-radius)',
    backgroundColor: 'var(--card-color)',
  },
  clickableSx: {
    border: 'var(--interactive-border)',
    backgroundColor: 'var(--box-background-color)',
  },
  smartLayoutBoxSx: {
    borderRadius: 'var(--box-border-radius)',
    backgroundColor: 'var(--box-background-color)',
  },
  smartLayoutLineSx: {
    backgroundColor: 'var(--box-background-color)',
  },
  smartLayoutContentSx: {},
  tableSx: {
    border: '0px',
    borderRadius: 'var(--box-border-radius)',
    'tbody tr': {
      _odd: {
        backgroundColor: 'var(--box-background-color)',
      },
    },
  },
}
