import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro'

import { CommandInfo } from 'modules/tiptap_editor/commands'

export const AICommands: CommandInfo[] = [
  {
    key: 'autoComplete',
    name: 'Auto complete',
    shortcut: 'Ctrl+Enter',
    nodeName: 'paragraph',
    icon: duotone('thought-bubble'),
    keywords: ['continue', 'autocomplete', 'typeahead'],
    execute: (editor) => {
      editor.commands.autoCompleteQuick()
    },
    featureFlag: 'aiAutocomplete',
  },
  {
    key: 'autoOutline',
    name: 'Auto outline',
    nodeName: 'bullet',
    icon: duotone('list-ul'),
    execute: (editor) => {
      editor.commands.autoOutline()
    },
    featureFlag: 'aiAutocomplete',
  },
  {
    key: 'autoFillCard',
    name: 'Auto fill card',
    nodeName: 'paragraph',
    icon: duotone('address-card'),
    execute: (editor) => {
      editor.commands.autoFillCard()
    },
    featureFlag: 'aiAutocomplete',
  },
]
