import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import { cloneDeep } from 'lodash'

import { ColorPickerInput } from 'modules/tiptap_editor/components/panels/ColorPanel'

import { LinearGradient, LinearGradientPicker } from './LinearGradientPicker'
type ColorOrGradientPickerProps = {
  color?: string
  defaultColor?: string
  gradient?: LinearGradient
  updateColor: (color: string | null) => void
  updateGradient: (gradient: LinearGradient) => void
}

export const ColorOrGradientPicker = ({
  color,
  defaultColor,
  updateColor,
  gradient,
  updateGradient,
}: ColorOrGradientPickerProps) => {
  return (
    <Tabs
      variant="soft-rounded"
      size="sm"
      isFitted
      isLazy
      index={isGradientEnabled(gradient) ? 1 : 0}
      onChange={(index) => {
        if (index === 0 && gradient) {
          const newGradient = cloneDeep(gradient)
          newGradient.disabled = true
          updateGradient(newGradient)
        } else {
          const newGradient = cloneDeep(gradient) || {
            colors: [color || null, null],
          }
          newGradient.disabled = false
          updateGradient(newGradient)
        }
      }}
    >
      <TabList>
        <Tab>Color</Tab>
        <Tab>Gradient</Tab>
      </TabList>
      <TabPanels>
        <TabPanel px={0}>
          <ColorPickerInput
            value={color || null}
            defaultValue={defaultColor}
            updateValue={(newColor) => {
              updateColor(newColor)
              if (gradient) {
                const newGradient = cloneDeep(gradient)
                newGradient.colors[0] = newColor
                updateGradient(newGradient)
              }
            }}
          />
        </TabPanel>
        <TabPanel px={0}>
          <LinearGradientPicker
            value={gradient || null}
            updateValue={(newGradient) => {
              updateGradient(newGradient)
              updateColor(newGradient.colors[0] || null)
            }}
          />
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}

export const isGradientEnabled = (gradient?: LinearGradient | null) => {
  return gradient && !gradient.disabled
}
