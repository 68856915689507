import { Box, Button, Flex, SimpleGrid, Text } from '@chakra-ui/react'
import { useTour } from '@reactour/tour'
import { useCallback, useEffect, useState } from 'react'

import { SegmentEvents, useAnalytics } from 'modules/segment'

import { TourNames } from '../constants'
import { enablePresentButton } from '../tours/doc_editor/DocEditorTourSteps'

export const Navigation = (props) => {
  const { meta, setMeta } = useTour()
  const analytics = useAnalytics()
  const { setCurrentStep, steps, currentStep, setIsOpen, setSteps } = props
  const [stepsSeen, setStepsSeen] = useState<{}>({})
  const first = currentStep === 0
  const last = currentStep === steps.length - 1
  const onNextClick = useCallback(() => {
    if (last) {
      if (meta === TourNames.DOC_EDITOR) {
        const stepsCompleted =
          Math.max(...Object.keys(stepsSeen).map((k) => +k)) + 1
        analytics?.track(SegmentEvents.DOC_EDITOR_TOUR_COMPLETED, {
          //  We add 1 to account for the fact that the first step is 0
          // this excludes the last step!
          stepsCompleted,
          completed: Boolean(stepsCompleted === steps.length - 1),
        })

        // IMPORTANT: re-enable the present button
        enablePresentButton()
      }
      setSteps && setSteps([])
      setMeta && setMeta('')
      setIsOpen(false)
    } else {
      setCurrentStep((s) => (s === steps?.length - 1 ? 0 : s + 1))
    }
  }, [
    analytics,
    last,
    meta,
    setCurrentStep,
    setIsOpen,
    setMeta,
    setSteps,
    steps.length,
    stepsSeen,
  ])

  useEffect(() => {
    if (last) {
      // don't set for last step, because everyone who tries to leave the tour via the
      // close button will see the last step, and it will make the analytics confusing
      return
    }
    setStepsSeen((s) => ({ ...s, [currentStep]: true }))
  }, [currentStep, last])

  const onPrevClick = useCallback(() => {
    if (first) {
      setCurrentStep((s) => steps.length - 1)
    } else {
      setCurrentStep((s) => s - 1)
    }
  }, [first, setCurrentStep, steps.length])
  return (
    <Flex w="100%" mt={6} justify="Center">
      {first ? (
        <Button variant="solid" onClick={onNextClick} size="md">
          Let's go!
        </Button>
      ) : (
        <SimpleGrid
          columns={3}
          w="100%"
          alignItems="center"
          justify-content="space-between"
        >
          <Button
            size="sm"
            variant="ghost"
            colorScheme="blackAlpha"
            onClick={onPrevClick}
            w="fit-content"
            justifySelf="flex-start"
          >
            Back
          </Button>
          <Box textAlign="center">
            <Text fontSize="sm" color="gray.300" fontWeight="bold">
              {currentStep + 1} of {steps.length}
            </Text>
          </Box>
          <Button
            size="sm"
            variant="solid"
            onClick={onNextClick}
            width="fit-content"
            justifySelf="flex-end"
          >
            {last ? 'Close' : 'Next'}
          </Button>
        </SimpleGrid>
      )}
    </Flex>
  )
}
