import { marked } from 'marked'

import { fetchTextCompletion } from 'modules/ai/openai'

export const generateOutlineMarkdown = async (
  prevText: string
): Promise<string> => {
  const prompt = generateOutlinePrompt(prevText)
  const result = await fetchTextCompletion(prompt, 'raw', {
    maxTokens: 512,
    temperature: 0.9,
    model: 'text-davinci-003',
  })
  return marked.parse(result)
}

const generateOutlinePrompt = (prevText: string) => {
  return `Write an outline for a slide deck or document about the following topic. Your outline should take the form of bullets, where each bullet point represents the name of a slide or section. Generate at least 20 bullets for each topic. Your outline should have multiple levels of indented bullets.

# Launch Briefing
This memo can be used to brief your go-to-market team on new products, services, and programs you're launching. You can share it to read ahead of a training, walk through it live, and then keep it up to date as a living resource for your team to check back on.

- What we're launching
- Why it matters
  - Current offering
  - Customer feedback
  - Competitor comparison
  - User testimonials
- How it works
- Who's getting it
  - Pricing
  - Rollout timeline
  - How to get access

# Q1 Results
This presentation covers the company's results for the first quarter. It includes topics such as revenue, costs, profit and loss, and key metrics.

- Introduction
- Revenue
  - Product A
  - Product B
  - Product C
- Costs
  - Cost of Goods Sold
  - Marketing
  - Sales
  - R&D
  - G&A
- Profit and Loss
- Key Metrics
  - MAUs
  - New Customers
  - Churn

${prevText}
`
}
