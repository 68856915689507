import TiptapCodeBlock from '@tiptap/extension-code-block'
import { NodeViewProps } from '@tiptap/react'

import { NodeViewContent, ReactNodeViewRenderer } from '../../react'
import { AnnotatableNodeViewWrapper } from '../Annotatable'
import { attrsOrDecorationsChanged } from '../updateFns'

export const CodeBlockView = (nodeViewProps: NodeViewProps) => {
  return (
    <AnnotatableNodeViewWrapper {...nodeViewProps}>
      <pre>
        <NodeViewContent as="code" />
      </pre>
    </AnnotatableNodeViewWrapper>
  )
}

export const CodeBlock = TiptapCodeBlock.extend({
  selectable: false,
  // This fixes the weird bug where you can get stuck inside a code block
  // that's at the top or bottom of a card
  isolating: true,
  addNodeView() {
    return ReactNodeViewRenderer(CodeBlockView, {
      update: attrsOrDecorationsChanged,
    })
  },
})
