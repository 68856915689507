import { Editor, NodeViewProps } from '@tiptap/core'
import React from 'react'

import { Editor as ExtendedEditor } from './Editor'

function isClassComponent(Component: any) {
  return !!(
    typeof Component === 'function' &&
    Component.prototype &&
    Component.prototype.isReactComponent
  )
}

function isForwardRefComponent(Component: any) {
  return !!(
    typeof Component === 'object' &&
    Component.$$typeof?.toString() === 'Symbol(react.forward_ref)'
  )
}

export interface ReactRendererOptions {
  editor: Editor
  props?: Record<string, any>
  as?: string
  className?: string
  updateWrapperEl?: (el: HTMLElement, props: NodeViewProps) => void
}

type ComponentType<R, P> =
  | React.ComponentClass<P>
  | React.FunctionComponent<P>
  | React.ForwardRefExoticComponent<
      React.PropsWithoutRef<P> & React.RefAttributes<R>
    >

export class ReactRenderer<R = unknown, P = unknown> {
  id: string

  editor: ExtendedEditor

  component: any

  element: Element

  props: Record<string, any>

  reactElement: React.ReactNode

  ref: R | null = null

  updateWrapperEl?: any

  constructor(
    component: ComponentType<R, P>,
    {
      editor,
      props = {},
      as = 'div',
      className = '',
      // ----- FORKED CHANGES -----
      updateWrapperEl,
    }: // ----- END FORKED CHANGES -----
    ReactRendererOptions
  ) {
    this.id = Math.floor(Math.random() * 0xffffffff).toString()
    this.component = component
    this.editor = editor as ExtendedEditor
    this.props = props
    // ----- FORKED CHANGES -----
    this.updateWrapperEl = updateWrapperEl
    // ----- END FORKED CHANGES -----
    this.element = document.createElement(as)
    this.element.classList.add('react-renderer')

    if (className) {
      this.element.classList.add(...className.split(' '))
    }

    this.render()
  }

  render(): void {
    const Component = this.component
    const props = this.props

    if (isClassComponent(Component) || isForwardRefComponent(Component)) {
      props.ref = (ref: R) => {
        this.ref = ref
      }
    }

    this.reactElement = <Component {...props} />

    // ----- FORKED CHANGES -----
    if (this.updateWrapperEl) {
      this.updateWrapperEl(this.element, props)
    }
    // ----- END FORKED CHANGES -----

    this.editor?.contentComponent?.setRenderer(this.id, this)
  }

  updateProps(props: Record<string, any> = {}): void {
    this.props = {
      ...this.props,
      ...props,
    }

    this.render()
  }

  destroy(): void {
    this.editor?.contentComponent?.removeRenderer(this.id)
  }
}
