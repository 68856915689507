import {
  Alert,
  AlertIcon,
  Button,
  Heading,
  HStack,
  Input,
  VStack,
} from '@chakra-ui/react'
import { NodeViewProps } from '@tiptap/core'
import { Attrs } from 'prosemirror-model'
import { useEffect, useState } from 'react'

import { getStore } from 'modules/redux'
import { WebEmbedAttrs } from 'modules/tiptap_editor/extensions/media/types'
import { selectDocOrgId } from 'modules/tiptap_editor/reducer'
import { dataURLtoFile } from 'utils/image'
import { isValidUrl, startsWithHttp } from 'utils/link'

import { fetchIframelyJSON, resetLinkMetadata } from '../apis/iframely'
import { uploadFileFromUrl } from '../apis/transloadit'
import {
  CustomVideoProvider,
  MediaProviders,
  WebpageProvider,
} from '../providers'
import { ImageUploadResult } from '../types/ImageUpload'
import { LinkMetadata } from '../types/LinkMetadata'

const parseEmbedCodeSrc = (html: string): string | false => {
  try {
    // Inspired by https://stackoverflow.com/a/34064434
    const parsed = new DOMParser().parseFromString(html, 'text/html')
    return (
      parsed.querySelector('iframe')?.src || // Normal format
      parsed.querySelector('blockquote')?.cite || // TikTok, Twitter format
      false
    )
  } catch (err) {
    return false
  }
}

export const fetchEmbedAttrsForUrl = async (
  url: string
): Promise<Partial<WebEmbedAttrs> & Pick<WebEmbedAttrs, 'source'>> => {
  let linkMeta: LinkMetadata | undefined
  try {
    linkMeta = await fetchIframelyJSON(url)
  } catch (error) {
    if (error.status === 403 || error.status === 404 || error.status === 415) {
      // Check to see if this is a special source where private links are still embeddable (eg Google Sheets)
      for (const { key, urlRegex, canEmbedPrivate, label } of MediaProviders) {
        if (!urlRegex || !url.match(urlRegex)) continue
        if (canEmbedPrivate) {
          return {
            ...resetLinkMetadata, // Clear out old values from any previous URL
            source: key,
            proxy: false,
            url: url,
            meta: {
              title: `Private ${label} file`,
            },
            sourceUrl: url,
            displayStyle: 'inline',
          }
        }
      }
      // Couldn't find any handler for the private link
      throw new Error(`Code: ${error.status}. Message: ${error.error}`)
    } else {
      throw new Error(`Code: ${error.status}. Message: ${error.error}`)
    }
  }
  if (!linkMeta) throw new Error('No link metadata found')

  let source: string | undefined
  for (const { key, urlRegex } of MediaProviders) {
    if (urlRegex && linkMeta.sourceUrl?.match(urlRegex)) {
      source = key
      break
    }
  }

  if (source) {
    // If it's from a known source, use that
    return {
      ...resetLinkMetadata, // Clear out old values from any previous URL
      ...linkMeta,
      source,
      proxy: false,
      url: linkMeta.embed?.url || linkMeta.sourceUrl,
    }
  } else if (
    linkMeta.meta?.medium == 'audio' ||
    linkMeta.meta?.medium === 'video'
  ) {
    return {
      ...resetLinkMetadata, // Clear out old values from any previous URL
      ...linkMeta,
      source: CustomVideoProvider.key,
    }
  } else if (linkMeta.embed?.url) {
    // If we recognize a known embed, don't use proxy
    return {
      ...resetLinkMetadata, // Clear out old values from any previous URL
      ...linkMeta,
      source: WebpageProvider.key,
      proxy: false,
      url: linkMeta.embed.url,
    }
  } else {
    // If we don't recognize an embed, try proxying the URL they put
    return {
      ...resetLinkMetadata, // Clear out old values from any previous URL
      ...linkMeta,
      source: WebpageProvider.key,
      proxy: true,
      url: url,
    }
  }
}

export const fetchAndUpdateEmbedAttrsForUrlAndUploadThumbnail = async (
  url: string,
  updateAttributes: (attrs: Attrs) => void
) => {
  return await fetchEmbedAttrsForUrl(url)
    .then((attrs) => {
      updateAttributes(attrs)
      return attrs
    })
    .then((attrs) => {
      // Pass updater to uploadIframelyThumbnail, so that it can call it when
      // the upload is complete
      return uploadIframelyThumbnail(attrs, updateAttributes)
    })
}
type URLFetcherProps = {
  currentUrl?: string
  updateAttributes?: NodeViewProps['updateAttributes']
  handleSubmit?: (url: string) => void // Overrides defaultHandleSubmit if provided
  handleDataSubmit?: (dataBlob: string) => void // Overrides defaultHandleSubmit if provided
  placeholder: string
  noHeader?: boolean
}

export const URLFetcher = ({
  currentUrl = '',
  updateAttributes,
  handleSubmit,
  handleDataSubmit,
  placeholder,
  noHeader,
}: URLFetcherProps) => {
  const [inputValue, setInputValue] = useState(currentUrl || '')
  const [errorMessage, setErrorMessage] = useState<string>()
  const isDirty = inputValue !== currentUrl

  // If the value gets updated by a collaborator, or through a mutation like adding protocol, update it in here
  useEffect(() => {
    if (currentUrl) {
      setInputValue(currentUrl)
    }
  }, [currentUrl])

  // Clear errors once they start typing
  useEffect(() => {
    setErrorMessage(undefined)
  }, [inputValue])

  const onSubmit = () => {
    let value = inputValue.trim()

    // special case data blobs as URLs
    if (value.indexOf('data:') === 0) {
      // validate data
      if (handleDataSubmit) {
        try {
          dataURLtoFile(value)
        } catch (e) {
          setErrorMessage("Sorry, we couldn't recognize this URL.")
          return
        }
        return handleDataSubmit(value)
      }
      // validation error
      setErrorMessage("Sorry, we couldn't recognize this URL.")
      return
    }

    const src = parseEmbedCodeSrc(value)
    if (src) {
      value = src
    }

    if (!startsWithHttp(value)) {
      value = 'https://' + value
      if (!isValidUrl(value)) {
        setErrorMessage("Sorry, we couldn't recognize this URL.")
        return
      }
    }

    if (handleSubmit) return handleSubmit(value)
    else if (updateAttributes)
      return fetchAndUpdateEmbedAttrsForUrlAndUploadThumbnail(
        value,
        updateAttributes
      ).catch((error) => {
        console.error('Error fetching URL', error)
        setErrorMessage(
          "Sorry, we couldn't reach this URL. If this is a private URL that requires a login, try using the app's share function to generate an embed code."
        )
      })
    else console.error('No submit handler provided')
  }

  return (
    <VStack align="flex-start" spacing={4} w="100%">
      {!noHeader && <Heading size="md">URL or embed code</Heading>}
      <HStack align="flex-start" spacing={2} w="100%">
        <Input
          placeholder={placeholder}
          onKeyDown={(event) => {
            if (event.key !== 'Enter') return
            onSubmit()
          }}
          value={inputValue}
          onChange={(event) => setInputValue(event.target.value)}
          flex={1}
          data-testid="url-input"
        />
        <Button onClick={onSubmit} isDisabled={!isDirty}>
          Go
        </Button>
      </HStack>
      {errorMessage && (
        <Alert status="error">
          <AlertIcon />
          {errorMessage}
        </Alert>
      )}
    </VStack>
  )
}

export const uploadIframelyThumbnail = async (
  attrs: LinkMetadata,
  updateAttributes: (attrs) => void
) => {
  const state = getStore().getState()
  const docOrgId = selectDocOrgId(state)

  if (!docOrgId) {
    console.error('[uploadIframelyThumbnail] No orgId specified, bailing')
    return
  }
  const thumbnailToSave = { ...attrs.thumbnail }
  const thumbnailUrl = thumbnailToSave.src
  if (thumbnailUrl && thumbnailToSave !== undefined) {
    try {
      await uploadFileFromUrl(
        thumbnailUrl,
        docOrgId,
        {
          onUploadComplete: (image: ImageUploadResult) => {
            if (thumbnailToSave) {
              thumbnailToSave.src = image.src
              thumbnailToSave.width = image.meta.width
              thumbnailToSave.height = image.meta.height
              updateAttributes({ ...attrs, thumbnail: thumbnailToSave })
            }
            return Promise.resolve()
          },
          onOriginalFileUpload: (image: ImageUploadResult) => {
            if (thumbnailToSave) {
              thumbnailToSave.src = image.src
              thumbnailToSave.width = image.meta.width
              thumbnailToSave.height = image.meta.height
              updateAttributes({ ...attrs, thumbnail: thumbnailToSave })
            }
            return Promise.resolve()
          },
          onUploadFailed: (error?: string) => {
            // There was an error uploading. Let's fall back on the thumbnail from iframely...
            console.error('[iframely] Thumbnail upload error: ', error)
            return Promise.reject()
          },
        },
        'iframelyThumbnail'
      )
    } catch (error) {
      console.error('[iframely] Thumbnail upload error: ', error)
    }
  }
}
