import {
  Box,
  Button,
  Flex,
  HStack,
  Skeleton,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react'
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useCallback, useMemo, useState } from 'react'

import { Doc, useGetDocCardsAnalyticsQuery } from 'modules/api'
import { useFeatureFlag } from 'modules/featureFlags'
import { useAppStore } from 'modules/redux'
import { selectCardIdMap } from 'modules/tiptap_editor/reducer'

import { GRAY_TEXT_COLOR } from '../constants'
import { AnalyticsViewerType, CardEngagementMode } from '../types'
import { formatCardsViewedTooltipText, createCardLookupTable } from '../utils'
import { AnalyticsError } from './AnalyticsError'
import { HorizontalBarCardTree } from './HorizontalBarCardTree'

export const ViewTimePercentTooltip = () => {
  return (
    <Stack spacing={2}>
      <Text>
        We keep a running counter for every second a card is "in view."
      </Text>
      <Text>
        A card is considered "in view" if the card takes up most or all of the
        browser window in doc or present mode. Nested cards must be expanded to
        count.
      </Text>
      <Text>
        We stop counting time on cards immediately if a user closes the tab, or
        60s after they switch tabs.
      </Text>
    </Stack>
  )
}

const getLabels = (mode: CardEngagementMode, viewerCount: number) => {
  if (mode === 'viewTimePercent') {
    return {
      chartDescription: `The average relative distribution of time spent across all cards, based on ${viewerCount} unique ${
        viewerCount === 1 ? 'viewer' : 'viewers'
      }.`,
      leftLabel: 'Less time spent',
      rightLabel: 'More time spent',
      createTooltipLabel: ViewTimePercentTooltip,
    }
  }
  return {
    chartDescription: `The percentage of viewers that saw a given card, based on ${viewerCount} unique ${
      viewerCount === 1 ? 'viewer' : 'viewers'
    }.`,
    leftLabel: '0% viewed this card',
    rightLabel: '100% viewed this card',
    createTooltipLabel: formatCardsViewedTooltipText,
    getScreenshotLabel: (percent: number) => `${percent}% viewed`,
  }
}

type CardEngagementViewProps = {
  docId: Doc['id']
  viewerFilter: AnalyticsViewerType
  currentViewerFilterCount: number
  onAnalyticsPanelClose: () => void
}

export const CardEngagementView = ({
  docId,
  viewerFilter,
  currentViewerFilterCount,
  onAnalyticsPanelClose,
}: CardEngagementViewProps) => {
  const reduxStore = useAppStore()
  const state = reduxStore.getState()
  const cardIdMap = selectCardIdMap(state)
  const {
    data: docCardsData,
    error: docCardsError,
    loading: docCardsLoading,
    refetch,
  } = useGetDocCardsAnalyticsQuery({ variables: { docId } })

  const [mode, setMode] = useState<CardEngagementMode>('viewTimePercent')
  const {
    chartDescription,
    rightLabel,
    leftLabel,
    createTooltipLabel,
    getScreenshotLabel,
  } = getLabels(mode, currentViewerFilterCount)
  const viewerGroup = viewerFilter === 'viewersOnly' ? 'viewers' : 'everyone'
  const CARD_VIEWED_THRESHOLD_IN_MS = useFeatureFlag('cardViewedThreshold')
  const tooltipLabel = createTooltipLabel(CARD_VIEWED_THRESHOLD_IN_MS)
  const cardStats = useMemo(
    () => docCardsData?.docCardsStats?.[viewerGroup] || [],
    [docCardsData?.docCardsStats, viewerGroup]
  )
  const cardLookupTable = useMemo(
    () => createCardLookupTable(cardStats, mode),
    [cardStats, mode]
  )
  const setModeToViewTimePercent = useCallback(
    () => setMode('viewTimePercent'),
    []
  )
  const setModeToViewersPercent = useCallback(
    () => setMode('viewersPercent'),
    []
  )

  return (
    <Box>
      <HStack my={4}>
        <Button
          leftIcon={
            <FontAwesomeIcon icon={regular('clock')} aria-label="Time spend" />
          }
          variant="ghost"
          fontWeight="600"
          bg={mode === 'viewTimePercent' ? 'trueblue.50' : 'transparent'}
          color={mode === 'viewTimePercent' ? 'trueblue.500' : GRAY_TEXT_COLOR}
          onClick={setModeToViewTimePercent}
        >
          Time spent
        </Button>
        <Button
          leftIcon={
            <FontAwesomeIcon
              icon={solid('percent')}
              aria-label="Percent viewed"
            />
          }
          variant="ghost"
          fontWeight="600"
          bg={mode === 'viewersPercent' ? 'trueblue.50' : 'transparent'}
          color={mode === 'viewersPercent' ? 'trueblue.500' : GRAY_TEXT_COLOR}
          onClick={setModeToViewersPercent}
        >
          Viewed
        </Button>
      </HStack>
      <HorizontalBarCardTreeDescription description={chartDescription} />
      {docCardsError && (
        <AnalyticsError error={docCardsError} refetch={refetch} />
      )}
      {docCardsLoading && !docCardsError && <HorizontalBarCardTreeSkeleton />}
      {!docCardsLoading && !docCardsError && (
        <HorizontalBarCardTree
          cards={cardIdMap.tree}
          cardLookupTable={cardLookupTable}
          scaleToMax={mode === 'viewTimePercent' ? true : false}
          isColorized={mode === 'viewersPercent' ? true : false}
          includeChildren={mode === 'viewTimePercent' ? true : false}
          rightLabel={rightLabel}
          leftLabel={leftLabel}
          tooltipLabel={tooltipLabel}
          getScreenshotLabel={
            mode === 'viewersPercent' ? getScreenshotLabel : undefined
          }
          onAnalyticsPanelClose={onAnalyticsPanelClose}
        />
      )}
    </Box>
  )
}

export const HorizontalBarCardTreeSkeleton = ({
  includeDescription = false,
}: {
  includeDescription?: boolean
}) => (
  <Flex direction="column" mt={6} mb={4}>
    <VStack align="stretch">
      {includeDescription && <Skeleton h={5} mb={3} w="70%" />}
      {[...Array(5)].map((_, i) => (
        <Skeleton h={8} key={i} />
      ))}
    </VStack>
  </Flex>
)

export const HorizontalBarCardTreeDescription = ({ description }) => (
  <Text color={GRAY_TEXT_COLOR}>{description}</Text>
)
