export const CardTemplateEmptyNodes = {
  blankCard: {
    type: 'card',
    content: [
      {
        type: 'heading',
        attrs: {
          level: 1,
        },
      },
    ],
  },
  titleCard: {
    type: 'card',
    attrs: {
      background: {
        type: 'none',
      },
      container: {},
      cardSize: 'default',
      layout: 'blank',
    },
    content: [
      {
        type: 'title',
        attrs: {
          horizontalAlign: 'left',
          level: 1,
        },
        content: [
          {
            type: 'text',
            text: 'Title',
          },
        ],
      },
      {
        type: 'heading',
        attrs: {
          horizontalAlign: 'left',
          level: 3,
        },
        content: [
          {
            type: 'text',
            text: 'Short description or subheading',
          },
        ],
      },
    ],
  },
  titleWithText: {
    type: 'card',
    attrs: {
      previewContent: null,
      background: {
        type: 'none',
      },
      container: {},
      cardSize: 'default',
      layout: 'blank',
    },
    content: [
      {
        type: 'heading',
        attrs: {
          horizontalAlign: null,
          level: 1,
        },
        content: [
          {
            type: 'text',
            text: 'Card Title',
          },
        ],
      },
      {
        type: 'paragraph',
        attrs: {
          horizontalAlign: null,
          fontSize: null,
        },
        content: [
          {
            type: 'text',
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
          },
        ],
      },
      {
        type: 'paragraph',
        attrs: {
          horizontalAlign: null,
          fontSize: null,
        },
        content: [
          {
            type: 'text',
            text: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
          },
        ],
      },
    ],
  },
  titleWithBullets: {
    type: 'card',
    attrs: {
      previewContent: null,
      background: {
        type: 'none',
      },
      container: {},
      cardSize: 'default',
      layout: 'blank',
    },
    content: [
      {
        type: 'heading',
        attrs: {
          horizontalAlign: null,
          level: 1,
        },
        content: [
          {
            type: 'text',
            text: 'Card Title',
          },
        ],
      },
      {
        type: 'bullet',
        attrs: {
          indent: 0,
          fontSize: null,
        },
        content: [
          {
            type: 'text',
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
          },
        ],
      },
      {
        type: 'bullet',
        attrs: {
          indent: 0,
          fontSize: null,
        },
        content: [
          {
            type: 'text',
            text: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
          },
        ],
      },
      {
        type: 'bullet',
        attrs: {
          indent: 0,
          fontSize: null,
        },
        content: [
          {
            type: 'text',
            text: 'Ut enim ad minim veniam, quis nostrud exercitation',
          },
        ],
      },
      {
        type: 'bullet',
        attrs: {
          indent: 0,
          fontSize: null,
        },
        content: [
          {
            type: 'text',
            text: 'Duis aute irure dolor in reprehenderit in voluptate',
          },
        ],
      },
      {
        type: 'bullet',
        attrs: {
          indent: 0,
          fontSize: null,
        },
        content: [
          {
            type: 'text',
            text: 'Dolore eu fugiat nulla pariatur',
          },
        ],
      },
    ],
  },
  titleWith2ColumnContent: {
    type: 'card',
    attrs: {
      previewContent: null,
      background: {
        type: 'none',
      },
      container: {},
      cardSize: 'default',
      layout: 'blank',
    },
    content: [
      {
        type: 'heading',
        attrs: {
          horizontalAlign: null,
          level: 1,
        },
        content: [
          {
            type: 'text',
            text: 'Card Title',
          },
        ],
      },
      {
        type: 'gridLayout',
        attrs: {
          colWidths: [50, 50],
          fullWidthBlock: false,
        },
        content: [
          {
            type: 'gridCell',
            content: [
              {
                type: 'paragraph',
                attrs: {
                  horizontalAlign: null,
                  fontSize: null,
                },
                content: [
                  {
                    type: 'text',
                    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ',
                  },
                ],
              },
            ],
          },
          {
            type: 'gridCell',
            content: [
              {
                type: 'paragraph',
                attrs: {
                  horizontalAlign: null,
                  fontSize: null,
                },
                content: [
                  {
                    type: 'text',
                    text: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  titleWith2ColumnImageLeft: {
    type: 'card',
    attrs: {
      previewContent: null,
      background: {
        type: 'none',
      },
      container: {},
      cardSize: 'default',
      layout: 'blank',
    },
    content: [
      {
        type: 'heading',
        attrs: {
          horizontalAlign: null,
          level: 1,
        },
        content: [
          {
            type: 'text',
            text: 'Card Title',
          },
        ],
      },
      {
        type: 'gridLayout',
        attrs: {
          colWidths: [50, 50],
          fullWidthBlock: false,
        },
        content: [
          {
            type: 'gridCell',
            content: [
              {
                type: 'mediaPlaceholder',
                attrs: {
                  horizontalAlign: null,
                  source: 'image.custom',
                  fullWidthBlock: false,
                },
              },
            ],
          },
          {
            type: 'gridCell',
            content: [
              {
                type: 'paragraph',
                attrs: {
                  horizontalAlign: null,
                  fontSize: null,
                },
                content: [
                  {
                    type: 'text',
                    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna. aliqua. Ut enim ad minim veniam quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ',
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  titleWith2ColumnImageRight: {
    type: 'card',
    attrs: {
      previewContent: null,
      background: {
        type: 'none',
      },
      container: {},
      cardSize: 'default',
      layout: 'blank',
    },
    content: [
      {
        type: 'heading',
        attrs: {
          horizontalAlign: null,
          level: 1,
        },
        content: [
          {
            type: 'text',
            text: 'Card Title',
          },
        ],
      },
      {
        type: 'gridLayout',
        attrs: {
          colWidths: [50, 50],
          fullWidthBlock: false,
        },
        content: [
          {
            type: 'gridCell',
            content: [
              {
                type: 'paragraph',
                attrs: {
                  horizontalAlign: null,
                  fontSize: null,
                },
                content: [
                  {
                    type: 'text',
                    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna. aliqua. Ut enim ad minim veniam quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ',
                  },
                ],
              },
            ],
          },
          {
            type: 'gridCell',
            content: [
              {
                type: 'mediaPlaceholder',
                attrs: {
                  horizontalAlign: null,
                  source: 'image.custom',
                  fullWidthBlock: false,
                },
              },
            ],
          },
        ],
      },
    ],
  },
  titleWith3ColumnContent: {
    type: 'card',
    attrs: {
      previewContent: null,
      background: {
        type: 'none',
      },
      container: {},
      cardSize: 'default',
      layout: 'blank',
    },
    content: [
      {
        type: 'heading',
        attrs: {
          horizontalAlign: null,
          level: 1,
        },
        content: [
          {
            type: 'text',
            text: 'Card Title',
          },
        ],
      },
      {
        type: 'gridLayout',
        attrs: {
          colWidths: [33.33, 33.34, 33.33],
          fullWidthBlock: false,
        },
        content: [
          {
            type: 'gridCell',
            content: [
              {
                type: 'paragraph',
                attrs: {
                  horizontalAlign: null,
                  fontSize: null,
                },
                content: [
                  {
                    type: 'text',
                    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
                  },
                ],
              },
            ],
          },
          {
            type: 'gridCell',
            content: [
              {
                type: 'paragraph',
                attrs: {
                  horizontalAlign: null,
                  fontSize: null,
                },
                content: [
                  {
                    type: 'text',
                    text: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident.',
                  },
                ],
              },
            ],
          },
          {
            type: 'gridCell',
            content: [
              {
                type: 'paragraph',
                attrs: {
                  horizontalAlign: null,
                  fontSize: null,
                },
                content: [
                  {
                    type: 'text',
                    text: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.',
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  titleWith3NestedCards: {
    type: 'card',
    attrs: {
      previewContent: null,
      background: {
        type: 'none',
      },
      container: {},
      cardSize: 'default',
      layout: 'blank',
    },
    content: [
      {
        type: 'heading',
        attrs: {
          horizontalAlign: null,
          level: 1,
        },
        content: [
          {
            type: 'text',
            text: 'Card Title',
          },
        ],
      },
      {
        type: 'paragraph',
        attrs: {
          horizontalAlign: null,
          fontSize: null,
        },
        content: [
          {
            type: 'text',
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
          },
        ],
      },
      {
        type: 'card',
        attrs: {
          previewContent: null,
          background: {
            type: 'none',
          },
          container: {},
          cardSize: 'default',
          layout: 'blank',
        },
        content: [
          {
            type: 'heading',
            attrs: {
              horizontalAlign: null,
              level: 1,
            },
            content: [
              {
                type: 'text',
                text: 'Section 1',
              },
            ],
          },
          {
            type: 'gridLayout',
            attrs: {
              colWidths: [50, 50],
              fullWidthBlock: false,
            },
            content: [
              {
                type: 'gridCell',
                content: [
                  {
                    type: 'paragraph',
                    attrs: {
                      horizontalAlign: null,
                      fontSize: null,
                    },
                    content: [
                      {
                        type: 'text',
                        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
                      },
                    ],
                  },
                ],
              },
              {
                type: 'gridCell',
                content: [
                  {
                    type: 'mediaPlaceholder',
                    attrs: {
                      horizontalAlign: null,
                      source: 'image.custom',
                      fullWidthBlock: false,
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        type: 'card',
        attrs: {
          previewContent: null,
          background: {
            type: 'none',
          },
          container: {},
          cardSize: 'default',
          layout: 'blank',
        },
        content: [
          {
            type: 'heading',
            attrs: {
              horizontalAlign: null,
              level: 1,
            },
            content: [
              {
                type: 'text',
                text: 'Section 2',
              },
            ],
          },
          {
            type: 'gridLayout',
            attrs: {
              colWidths: [50, 50],
              fullWidthBlock: false,
            },
            content: [
              {
                type: 'gridCell',
                content: [
                  {
                    type: 'paragraph',
                    attrs: {
                      horizontalAlign: null,
                      fontSize: null,
                    },
                    content: [
                      {
                        type: 'text',
                        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
                      },
                    ],
                  },
                ],
              },
              {
                type: 'gridCell',
                content: [
                  {
                    type: 'mediaPlaceholder',
                    attrs: {
                      horizontalAlign: null,
                      source: 'image.custom',
                      fullWidthBlock: false,
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        type: 'card',
        attrs: {
          previewContent: null,
          background: {
            type: 'none',
          },
          container: {},
          cardSize: 'default',
          layout: 'blank',
        },
        content: [
          {
            type: 'heading',
            attrs: {
              horizontalAlign: null,
              level: 1,
            },
            content: [
              {
                type: 'text',
                text: 'Section 3',
              },
            ],
          },
          {
            type: 'gridLayout',
            attrs: {
              colWidths: [50, 50],
              fullWidthBlock: false,
            },
            content: [
              {
                type: 'gridCell',
                content: [
                  {
                    type: 'paragraph',
                    attrs: {
                      horizontalAlign: null,
                      fontSize: null,
                    },
                    content: [
                      {
                        type: 'text',
                        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
                      },
                    ],
                  },
                ],
              },
              {
                type: 'gridCell',
                content: [
                  {
                    type: 'mediaPlaceholder',
                    attrs: {
                      horizontalAlign: null,
                      source: 'image.custom',
                      fullWidthBlock: false,
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  titleWith3Toggles: {
    type: 'card',
    attrs: {
      previewContent: null,
      background: {
        type: 'none',
      },
      container: {},
      cardSize: 'default',
      layout: 'blank',
    },
    content: [
      {
        type: 'heading',
        attrs: {
          horizontalAlign: null,
          level: 1,
        },
        content: [
          {
            type: 'text',
            text: 'Card Title',
          },
        ],
      },
      {
        type: 'toggle',

        content: [
          {
            type: 'toggleSummary',
            attrs: {
              fontSize: 'lg',
            },
            content: [
              {
                type: 'text',
                text: 'Key point toggle 1',
              },
            ],
          },
          {
            type: 'paragraph',
            attrs: {
              horizontalAlign: null,
              fontSize: null,
            },
            content: [
              {
                type: 'text',
                text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
              },
            ],
          },
        ],
      },
      {
        type: 'toggle',

        content: [
          {
            type: 'toggleSummary',
            attrs: {
              fontSize: 'lg',
            },
            content: [
              {
                type: 'text',
                text: 'Key point toggle 2',
              },
            ],
          },
          {
            type: 'paragraph',
            attrs: {
              horizontalAlign: null,
              fontSize: null,
            },
            content: [
              {
                type: 'text',
                text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
              },
            ],
          },
        ],
      },
      {
        type: 'toggle',
        content: [
          {
            type: 'toggleSummary',
            attrs: {
              fontSize: 'lg',
            },
            content: [
              {
                type: 'text',
                text: 'Key point toggle 3',
              },
            ],
          },
          {
            type: 'paragraph',
            attrs: {
              horizontalAlign: null,
              fontSize: null,
            },
            content: [
              {
                type: 'text',
                text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
              },
            ],
          },
        ],
      },
    ],
  },
  titleWith2ImageColumns: {
    type: 'card',
    attrs: {
      previewContent: null,
      background: {
        type: 'none',
      },
      container: {},
      cardSize: 'default',
      layout: 'blank',
    },
    content: [
      {
        type: 'heading',
        attrs: {
          horizontalAlign: null,
          level: 1,
        },
        content: [
          {
            type: 'text',
            text: 'Card Title',
          },
        ],
      },
      {
        type: 'gridLayout',
        attrs: {
          colWidths: [50, 50],
          fullWidthBlock: false,
        },
        content: [
          {
            type: 'gridCell',
            content: [
              {
                type: 'mediaPlaceholder',
                attrs: {
                  horizontalAlign: null,
                  source: 'image.custom',
                  fullWidthBlock: false,
                },
              },
              {
                type: 'heading',
                attrs: {
                  horizontalAlign: null,
                  level: 3,
                },
                content: [
                  {
                    type: 'text',
                    text: 'Item 1',
                  },
                ],
              },
              {
                type: 'paragraph',
                attrs: {
                  horizontalAlign: null,
                  fontSize: null,
                },
                content: [
                  {
                    type: 'text',
                    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna. aliqua. Ut enim ad minim veniam quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ',
                  },
                ],
              },
            ],
          },
          {
            type: 'gridCell',
            content: [
              {
                type: 'mediaPlaceholder',
                attrs: {
                  horizontalAlign: null,
                  source: 'image.custom',
                  fullWidthBlock: false,
                },
              },
              {
                type: 'heading',
                attrs: {
                  horizontalAlign: null,
                  level: 3,
                },
                content: [
                  {
                    type: 'text',
                    text: 'Item 2',
                  },
                ],
              },
              {
                type: 'paragraph',
                attrs: {
                  horizontalAlign: null,
                  fontSize: null,
                },
                content: [
                  {
                    type: 'text',
                    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna. aliqua. Ut enim ad minim veniam quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ',
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  titleWith3ImageColumns: {
    type: 'card',
    attrs: {
      previewContent: null,
      background: {
        type: 'none',
      },
      container: {},
      cardSize: 'default',
      layout: 'blank',
    },
    content: [
      {
        type: 'heading',
        attrs: {
          horizontalAlign: null,
          level: 1,
        },
        content: [
          {
            type: 'text',
            text: 'Card Title',
          },
        ],
      },
      {
        type: 'gridLayout',
        attrs: {
          colWidths: [33.33, 33.33, 33.33],
          fullWidthBlock: false,
        },
        content: [
          {
            type: 'gridCell',
            content: [
              {
                type: 'mediaPlaceholder',
                attrs: {
                  horizontalAlign: null,
                  source: 'image.custom',
                  fullWidthBlock: false,
                },
              },
              {
                type: 'heading',
                attrs: {
                  horizontalAlign: null,
                  level: 3,
                },
                content: [
                  {
                    type: 'text',
                    text: 'Item 1',
                  },
                ],
              },
              {
                type: 'paragraph',
                attrs: {
                  horizontalAlign: null,
                  fontSize: null,
                },
                content: [
                  {
                    type: 'text',
                    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.',
                  },
                ],
              },
            ],
          },
          {
            type: 'gridCell',
            content: [
              {
                type: 'mediaPlaceholder',
                attrs: {
                  horizontalAlign: null,
                  source: 'image.custom',
                  fullWidthBlock: false,
                },
              },
              {
                type: 'heading',
                attrs: {
                  horizontalAlign: null,
                  level: 3,
                },
                content: [
                  {
                    type: 'text',
                    text: 'Item 2',
                  },
                ],
              },
              {
                type: 'paragraph',
                attrs: {
                  horizontalAlign: null,
                  fontSize: null,
                },
                content: [
                  {
                    type: 'text',
                    text: 'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo.',
                  },
                ],
              },
            ],
          },
          {
            type: 'gridCell',
            content: [
              {
                type: 'mediaPlaceholder',
                attrs: {
                  horizontalAlign: null,
                  source: 'image.custom',
                  fullWidthBlock: false,
                },
              },
              {
                type: 'heading',
                attrs: {
                  horizontalAlign: null,
                  level: 3,
                },
                content: [
                  {
                    type: 'text',
                    text: 'Item 3',
                  },
                ],
              },
              {
                type: 'paragraph',
                attrs: {
                  horizontalAlign: null,
                  fontSize: null,
                },
                content: [
                  {
                    type: 'text',
                    text: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  titleWith4ImageColumns: {
    type: 'card',
    attrs: {
      previewContent: null,
      background: {
        type: 'none',
      },
      container: {},
      cardSize: 'default',
      layout: 'blank',
    },
    content: [
      {
        type: 'heading',
        attrs: {
          horizontalAlign: null,
          level: 1,
        },
        content: [
          {
            type: 'text',
            text: 'Card Title',
          },
        ],
      },
      {
        type: 'gridLayout',
        attrs: {
          colWidths: [25, 25, 25, 25],
          fullWidthBlock: false,
        },
        content: [
          {
            type: 'gridCell',
            content: [
              {
                type: 'mediaPlaceholder',
                attrs: {
                  horizontalAlign: null,
                  source: 'image.custom',
                  fullWidthBlock: false,
                },
              },
              {
                type: 'heading',
                attrs: {
                  horizontalAlign: null,
                  level: 4,
                },
                content: [
                  {
                    type: 'text',
                    text: 'Item 1',
                  },
                ],
              },
              {
                type: 'paragraph',
                attrs: {
                  horizontalAlign: null,
                  fontSize: null,
                },
                content: [
                  {
                    type: 'text',
                    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
                  },
                ],
              },
            ],
          },
          {
            type: 'gridCell',
            content: [
              {
                type: 'mediaPlaceholder',
                attrs: {
                  horizontalAlign: null,
                  source: 'image.custom',
                  fullWidthBlock: false,
                },
              },
              {
                type: 'heading',
                attrs: {
                  horizontalAlign: null,
                  level: 4,
                },
                content: [
                  {
                    type: 'text',
                    text: 'Item 2',
                  },
                ],
              },
              {
                type: 'paragraph',
                attrs: {
                  horizontalAlign: null,
                  fontSize: null,
                },
                content: [
                  {
                    type: 'text',
                    text: 'Ut enim ad minim veniam, quis nostrud exercitation',
                  },
                ],
              },
            ],
          },
          {
            type: 'gridCell',
            content: [
              {
                type: 'mediaPlaceholder',
                attrs: {
                  horizontalAlign: null,
                  source: 'image.custom',
                  fullWidthBlock: false,
                },
              },
              {
                type: 'heading',
                attrs: {
                  horizontalAlign: null,
                  level: 4,
                },
                content: [
                  {
                    type: 'text',
                    text: 'Item 3',
                  },
                ],
              },
              {
                type: 'paragraph',
                attrs: {
                  horizontalAlign: null,
                  fontSize: null,
                },
                content: [
                  {
                    type: 'text',
                    text: 'Duis aute irure dolor in reprehenderit in voluptate velit',
                  },
                ],
              },
            ],
          },
          {
            type: 'gridCell',
            content: [
              {
                type: 'mediaPlaceholder',
                attrs: {
                  horizontalAlign: null,
                  source: 'image.custom',
                  fullWidthBlock: false,
                },
              },
              {
                type: 'heading',
                attrs: {
                  horizontalAlign: null,
                  level: 4,
                },
                content: [
                  {
                    type: 'text',
                    text: 'Item 4',
                  },
                ],
              },
              {
                type: 'paragraph',
                attrs: {
                  horizontalAlign: null,
                  fontSize: null,
                },
                content: [
                  {
                    type: 'text',
                    text: 'Neque porro quisquam est, qui dolorem ipsum quia dolor sit',
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  titleWithContentAndButton: {
    type: 'card',
    attrs: {
      previewContent: null,
      background: {
        type: 'none',
      },
      container: {},
      cardSize: 'default',
      layout: 'blank',
    },
    content: [
      {
        type: 'heading',
        attrs: {
          horizontalAlign: 'center',
          level: 1,
        },
        content: [
          {
            type: 'text',
            text: 'Card Title',
          },
        ],
      },
      {
        type: 'paragraph',
        attrs: {
          horizontalAlign: null,
          fontSize: null,
        },
        content: [
          {
            type: 'text',
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
          },
        ],
      },
      {
        type: 'buttonGroup',
        attrs: {
          horizontalAlign: 'center',
        },
        content: [
          {
            type: 'button',
            attrs: {
              href: '',
              variant: 'solid',
            },
            content: [
              {
                type: 'text',
                text: 'Button 1',
              },
            ],
          },
        ],
      },
    ],
  },
  /// Card Layout templates
  accentLeft: {
    type: 'card',
    attrs: {
      previewContent: null,
      background: {
        type: 'none',
      },
      container: {},
      cardSize: 'default',
      layout: 'left',
    },
    content: [
      {
        type: 'heading',
        attrs: {
          horizontalAlign: null,
          level: 1,
        },
        content: [
          {
            type: 'text',
            text: 'Card Title',
          },
        ],
      },
      {
        type: 'paragraph',
        attrs: {
          horizontalAlign: null,
          fontSize: null,
        },
        content: [
          {
            type: 'text',
            text: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
          },
        ],
      },
    ],
  },
  accentRight: {
    type: 'card',
    attrs: {
      previewContent: null,
      background: {
        type: 'none',
      },
      container: {},
      cardSize: 'default',
      layout: 'right',
    },
    content: [
      {
        type: 'heading',
        attrs: {
          horizontalAlign: null,
          level: 1,
        },
        content: [
          {
            type: 'text',
            text: 'Card Title',
          },
        ],
      },
      {
        type: 'paragraph',
        attrs: {
          horizontalAlign: null,
          fontSize: null,
        },
        content: [
          {
            type: 'text',
            text: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
          },
        ],
      },
    ],
  },
  accentTop: {
    type: 'card',
    attrs: {
      previewContent: null,
      background: {
        type: 'none',
      },
      container: {},
      cardSize: 'default',
      layout: 'top',
    },
    content: [
      {
        type: 'heading',
        attrs: {
          horizontalAlign: null,
          level: 1,
        },
        content: [
          {
            type: 'text',
            text: 'Card Title',
          },
        ],
      },
      {
        type: 'paragraph',
        attrs: {
          horizontalAlign: null,
          fontSize: null,
        },
        content: [
          {
            type: 'text',
            text: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
          },
        ],
      },
    ],
  },
  titleWithTimeline: {
    type: 'card',
    attrs: {
      id: 'sb2yzx7mi78270g',
      previewContent: null,
      background: {
        type: 'none',
      },
      container: {},
      cardSize: 'default',
      layout: 'blank',
      layoutTemplateColumns: null,
    },
    content: [
      {
        type: 'cardLayoutItem',
        attrs: {
          itemId: 'body',
          background: {},
        },
        content: [
          {
            type: 'heading',
            attrs: {
              horizontalAlign: null,
              level: 1,
            },
            content: [
              {
                type: 'text',
                text: 'Card Title',
              },
            ],
          },
          {
            type: 'paragraph',
            attrs: {
              horizontalAlign: null,
              fontSize: null,
            },
            content: [
              {
                type: 'text',
                text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna. aliqua. Ut enim ad minim veniam quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
              },
            ],
          },
          {
            type: 'smartLayout',
            attrs: {
              variantKey: 'timeline',
              options: {},
              fullWidthBlock: false,
            },
            content: [
              {
                type: 'smartLayoutCell',
                attrs: {
                  options: {},
                  width: null,
                  image: null,
                  label: null,
                },
                content: [
                  {
                    type: 'paragraph',
                    attrs: {
                      horizontalAlign: null,
                      fontSize: null,
                    },
                    content: [
                      {
                        type: 'text',
                        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
                      },
                    ],
                  },
                ],
              },
              {
                type: 'smartLayoutCell',
                attrs: {
                  options: {},
                  width: null,
                  image: null,
                  label: null,
                },
                content: [
                  {
                    type: 'paragraph',
                    attrs: {
                      horizontalAlign: null,
                      fontSize: 'default',
                    },
                    content: [
                      {
                        type: 'text',
                        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
                      },
                    ],
                  },
                ],
              },
              {
                type: 'smartLayoutCell',
                attrs: {
                  options: {},
                  width: null,
                  image: null,
                  label: null,
                },
                content: [
                  {
                    type: 'paragraph',
                    attrs: {
                      horizontalAlign: null,
                      fontSize: 'default',
                    },
                    content: [
                      {
                        type: 'text',
                        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  titleWithLargeBulletList: {
    type: 'card',
    attrs: {
      previewContent: null,
      background: {
        type: 'none',
      },
      container: {},
      cardSize: 'default',
      layout: 'blank',
      layoutTemplateColumns: null,
    },
    content: [
      {
        type: 'cardLayoutItem',
        attrs: {
          itemId: 'body',
          background: {},
        },
        content: [
          {
            type: 'heading',
            attrs: {
              horizontalAlign: null,
              level: 1,
            },
            content: [
              {
                type: 'text',
                text: 'Card Title',
              },
            ],
          },
          {
            type: 'smartLayout',
            attrs: {
              variantKey: 'bullets',
              options: {
                cellSize: 25,
              },
              fullWidthBlock: false,
            },
            content: [
              {
                type: 'smartLayoutCell',
                attrs: {
                  options: {},
                  width: null,
                  image: null,
                  label: null,
                },
                content: [
                  {
                    type: 'heading',
                    attrs: {
                      horizontalAlign: null,
                      level: 3,
                    },
                    content: [
                      {
                        type: 'text',
                        text: 'Item 1',
                      },
                    ],
                  },
                ],
              },
              {
                type: 'smartLayoutCell',
                attrs: {
                  options: {},
                  width: null,
                  image: null,
                  label: null,
                },
                content: [
                  {
                    type: 'heading',
                    attrs: {
                      horizontalAlign: null,
                      level: 3,
                    },
                    content: [
                      {
                        type: 'text',
                        text: 'Item 2',
                      },
                    ],
                  },
                ],
              },
              {
                type: 'smartLayoutCell',
                attrs: {
                  options: {},
                  width: null,
                  image: null,
                  label: null,
                },
                content: [
                  {
                    type: 'heading',
                    attrs: {
                      horizontalAlign: null,
                      level: 3,
                    },
                    content: [
                      {
                        type: 'text',
                        text: 'Item 3',
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  titleWithTeamPhotos: {
    type: 'smartLayout',
    attrs: {
      variantKey: 'imagesText',
      options: {
        cellSize: 5.75,
      },
      fullWidthBlock: false,
    },
    content: [
      {
        type: 'smartLayoutCell',
        attrs: {
          options: {},
          width: null,
          image: {
            source: 'image.custom',
            uploadStatus: 0,
            showPlaceholder: false,
            src: 'https://cdn.gamma.app/zc87vhr30n8uf3n/c1414815be03462f8862cb80f89f720d/optimized/tesla.jpg',
            meta: {
              width: 820,
              height: 709,
              date_file_modified: '2023/02/07 22:20:08 GMT',
              aspect_ratio: 1.157,
              has_clipping_path: false,
              frame_count: 1,
              colorspace: 'sRGB',
              has_transparency: false,
              average_color: '#828282',
              orientation: 'Horizontal (normal)',
            },
            name: 'tesla.jpg',
          },
          label: null,
        },
        content: [
          {
            type: 'heading',
            attrs: {
              horizontalAlign: null,
              level: 3,
            },
            content: [
              {
                type: 'text',
                text: 'Nikola ',
              },
              {
                type: 'hardBreak',
              },
              {
                type: 'text',
                text: 'Tesla',
              },
            ],
          },
          {
            type: 'paragraph',
            attrs: {
              horizontalAlign: null,
              fontSize: 'default',
            },
            content: [
              {
                type: 'text',
                marks: [
                  {
                    type: 'bold',
                  },
                ],
                text: 'Inventor',
              },
              {
                type: 'hardBreak',
              },
              {
                type: 'text',
                text: '@ Tesla Inc. ',
              },
            ],
          },
        ],
      },
      {
        type: 'smartLayoutCell',
        attrs: {
          options: {},
          width: null,
          image: {
            source: 'image.custom',
            uploadStatus: 0,
            showPlaceholder: false,
            src: 'https://cdn.gamma.app/zc87vhr30n8uf3n/53ab692111294834a286002e3ecfdf2b/optimized/marie-curie.jpg',
            meta: {
              width: 1200,
              height: 1631,
              date_file_modified: '2023/02/07 22:20:11 GMT',
              aspect_ratio: 0.736,
              has_clipping_path: false,
              frame_count: 1,
              colorspace: 'sRGB',
              has_transparency: false,
              orientation: 'Horizontal (normal)',
              average_color: '#3b3b3b',
            },
            name: 'marie-curie.jpg',
          },
          label: null,
        },
        content: [
          {
            type: 'heading',
            attrs: {
              horizontalAlign: null,
              level: 3,
            },
            content: [
              {
                type: 'text',
                text: 'Marie ',
              },
              {
                type: 'hardBreak',
              },
              {
                type: 'text',
                text: 'Curie',
              },
            ],
          },
          {
            type: 'paragraph',
            attrs: {
              horizontalAlign: null,
              fontSize: 'default',
            },
            content: [
              {
                type: 'text',
                marks: [
                  {
                    type: 'bold',
                  },
                ],
                text: 'Physicist and Chemist',
              },
              {
                type: 'hardBreak',
              },
              {
                type: 'text',
                text: '@ Radioactivity',
              },
            ],
          },
        ],
      },
      {
        type: 'smartLayoutCell',
        attrs: {
          options: {},
          width: null,
          image: {
            source: 'image.custom',
            uploadStatus: 0,
            showPlaceholder: false,
            src: 'https://cdn.gamma.app/zc87vhr30n8uf3n/e5d0250f2e054ee3a4c63a8e11f39c36/optimized/thomas-edison.webp',
            meta: {
              width: 360,
              height: 450,
              date_file_modified: '2023/02/07 22:20:22 GMT',
              aspect_ratio: 0.8,
              has_clipping_path: false,
              frame_count: 1,
              colorspace: 'sRGB',
              has_transparency: false,
              average_color: '#606060',
              orientation: 'Horizontal (normal)',
            },
            name: 'thomas edison.webp',
          },
          label: null,
        },
        content: [
          {
            type: 'heading',
            attrs: {
              horizontalAlign: null,
              level: 3,
            },
            content: [
              {
                type: 'text',
                text: 'Thomas Edison',
              },
            ],
          },
          {
            type: 'paragraph',
            attrs: {
              horizontalAlign: null,
              fontSize: 'default',
            },
            content: [
              {
                type: 'text',
                marks: [
                  {
                    type: 'bold',
                  },
                ],
                text: 'Inventor ',
              },
              {
                type: 'hardBreak',
              },
              {
                type: 'text',
                text: '@ Light bulb ',
              },
            ],
          },
        ],
      },
      {
        type: 'smartLayoutCell',
        attrs: {
          options: {},
          width: null,
          image: {
            source: 'image.custom',
            uploadStatus: 0,
            showPlaceholder: false,
            src: 'https://cdn.gamma.app/zc87vhr30n8uf3n/86aa2820df484599b26ab9bbb79737d3/optimized/einstein.jpg',
            meta: {
              width: 500,
              height: 500,
              date_file_modified: '2023/02/07 22:20:26 GMT',
              aspect_ratio: 1,
              has_clipping_path: false,
              frame_count: 1,
              colorspace: 'sRGB',
              has_transparency: false,
              orientation: 'Horizontal (normal)',
              average_color: '#575757',
            },
            name: 'einstein.jpg',
          },
          label: null,
        },
        content: [
          {
            type: 'heading',
            attrs: {
              horizontalAlign: null,
              level: 3,
            },
            content: [
              {
                type: 'text',
                text: 'Albert Einstein',
              },
            ],
          },
          {
            type: 'paragraph',
            attrs: {
              horizontalAlign: null,
              fontSize: 'default',
            },
            content: [
              {
                type: 'text',
                marks: [
                  {
                    type: 'bold',
                  },
                ],
                text: 'Theoretical Physicist',
              },
              {
                type: 'hardBreak',
              },
              {
                type: 'text',
                text: '@ EMC2 ',
              },
            ],
          },
        ],
      },
    ],
  },
  titleWith3Boxes: {
    type: 'card',
    attrs: {
      previewContent: null,
      background: {
        type: 'none',
      },
      container: {},
      cardSize: 'default',
      layout: 'blank',
      layoutTemplateColumns: null,
    },
    content: [
      {
        type: 'cardLayoutItem',
        attrs: {
          itemId: 'body',
          background: {},
        },
        content: [
          {
            type: 'heading',
            attrs: {
              horizontalAlign: null,
              level: 1,
            },
            content: [
              {
                type: 'text',
                text: 'Card Title',
              },
            ],
          },
          {
            type: 'smartLayout',
            attrs: {
              variantKey: 'textBoxes',
              options: {
                cellSize: 5,
              },
              fullWidthBlock: false,
            },
            content: [
              {
                type: 'smartLayoutCell',
                attrs: {
                  options: {},
                  width: null,
                  image: null,
                  label: null,
                },
                content: [
                  {
                    type: 'heading',
                    attrs: {
                      horizontalAlign: null,
                      level: 3,
                    },
                    content: [
                      {
                        type: 'text',
                        text: 'Item 1',
                      },
                    ],
                  },
                  {
                    type: 'paragraph',
                    attrs: {
                      horizontalAlign: null,
                      fontSize: null,
                    },
                    content: [
                      {
                        type: 'text',
                        text: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident.',
                      },
                    ],
                  },
                ],
              },
              {
                type: 'smartLayoutCell',
                attrs: {
                  options: {},
                  width: null,
                  image: null,
                  label: null,
                },
                content: [
                  {
                    type: 'heading',
                    attrs: {
                      horizontalAlign: null,
                      level: 3,
                    },
                    content: [
                      {
                        type: 'text',
                        text: 'Item 2',
                      },
                    ],
                  },
                  {
                    type: 'paragraph',
                    attrs: {
                      horizontalAlign: null,
                      fontSize: null,
                    },
                    content: [
                      {
                        type: 'text',
                        text: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident.',
                      },
                    ],
                  },
                ],
              },
              {
                type: 'smartLayoutCell',
                attrs: {
                  options: {},
                  width: null,
                  image: null,
                  label: null,
                },
                content: [
                  {
                    type: 'heading',
                    attrs: {
                      horizontalAlign: null,
                      level: 3,
                    },
                    content: [
                      {
                        type: 'text',
                        text: 'Item 3',
                      },
                    ],
                  },
                  {
                    type: 'paragraph',
                    attrs: {
                      horizontalAlign: null,
                      fontSize: null,
                    },
                    content: [
                      {
                        type: 'text',
                        text: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident.',
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
}
