import { configureStore } from '@reduxjs/toolkit'
import { isEqual, memoize } from 'lodash'
import {
  useDispatch,
  useSelector,
  useStore,
  TypedUseSelectorHook,
} from 'react-redux'

import { reducer as CardSuggestionEditorReducer } from 'modules/ai/components/CardSuggestionEditor/reducer'
import { CardsReducer, reset as ResetCards } from 'modules/cards/reducer'
import { reducer as IntercomReducer } from 'modules/intercom/reducer'
import { reducer as ModalStateReducer } from 'modules/modal_state/reducer'
import { PanelReducer } from 'modules/panels/reducer'
import { reducer as SearchModalReducer } from 'modules/search/reducer'
import { ManageCardMenuReducer } from 'modules/tiptap_editor/extensions/Card/ManageCardMenu/reducer'
import { reducer as ImageEditorReducer } from 'modules/tiptap_editor/extensions/media/Image/reducer'
import {
  reducer as TipTapReducer,
  reset as ResetTiptap,
} from 'modules/tiptap_editor/reducer'
import { reducer as DesignPartnerReducer } from 'sections/docs/editor/components/DesignPartner/reducer'
import {
  reducer as DocEditorReducer,
  reset as ResetDocEditor,
} from 'sections/docs/reducer'

export const moduleReducers = {
  CardSuggestionEditor: CardSuggestionEditorReducer,
  Cards: CardsReducer,
  DesignPartner: DesignPartnerReducer,
  ImageEditor: ImageEditorReducer,
  TipTap: TipTapReducer,
  Panels: PanelReducer,
  ManageCardMenu: ManageCardMenuReducer,
  SearchModal: SearchModalReducer,
  Intercom: IntercomReducer,
  ModalState: ModalStateReducer,
} as const

const rootReducer = {
  // Sections/Pages
  DocEditor: DocEditorReducer,

  // Modules
  ...moduleReducers,
}

// TODO - Is there a more elegant way to reset all the stores?
// This action is necessary to reset the state when running in an SSR (lambda) environment
export const globalResetAction = () => {
  const dispatch = getStore().dispatch
  dispatch(ResetCards())
  dispatch(ResetDocEditor())
  dispatch(ResetTiptap())
}

export const getStore = memoize(() => {
  // All stores must be registered here.
  // Code splitting ideas: https://redux.js.org/recipes/code-splitting
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      // dont enable serializeableCheck since we do not use any features requiring this,
      // such as time travelling debugging, persisting the store state or replaying of actions
      getDefaultMiddleware({ serializableCheck: false }),
  })
})

if (process.env.NODE_ENV !== 'production' && module.hot) {
  // Handle hot reloading by replacing the reducers:
  // See https://redux.js.org/usage/configuring-your-store#hot-reloading
  // @ts-ignore
  const replaceFn = () => getStore().replaceReducer(rootReducer)
  module.hot.accept('modules/tiptap_editor/reducer', replaceFn)
  module.hot.accept('modules/panels/reducer', replaceFn)
  module.hot.accept(
    'modules/tiptap_editor/extensions/Card/ManageCardMenu/reducer',
    replaceFn
  )
  module.hot.accept('sections/docs/reducer', replaceFn)
  console.log(
    '%c $$$$$$$$$$$$$$$$$$$$ Redux HMR replaceReducer $$$$$$$$$$$$$$$$$$$$',
    'background-color: #764abc; color: white;'
  )
}

type StoreType = ReturnType<typeof getStore>
export type RootState = ReturnType<StoreType['getState']>
export type AppDispatch = StoreType['dispatch']
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
export const useAppStore = () => useStore<RootState>()

/**
 * Utility to observe a selector outside of React context.
 * Inspired by https://github.com/reduxjs/redux/issues/303#issuecomment-125184409
 */
export function observeSelector<T>(
  select: (state: RootState) => T,
  onChange: (val: T) => void,
  store?: StoreType
) {
  const storeToUse = store || getStore()
  let currentState: T

  function handleChange() {
    const nextState = select(storeToUse.getState())
    if (!isEqual(nextState, currentState)) {
      currentState = nextState
      onChange(currentState)
    }
  }

  const unsubscribe = storeToUse.subscribe(handleChange)
  handleChange()
  return unsubscribe
}
