import { cx } from '@chakra-ui/utils'
import { NodeViewProps } from '@tiptap/react'

import { HEADING_CLASS } from 'modules/theming/styles/heading'

import { NodeViewContent } from '../../react'
import { AnnotatableNodeViewWrapper } from '../Annotatable'
import { getAlignStyles } from '../HorizontalAlign'
export const HeadingView = (nodeViewProps: NodeViewProps) => {
  const { node } = nodeViewProps
  const { level, horizontalAlign } = node.attrs
  return (
    <AnnotatableNodeViewWrapper
      {...nodeViewProps}
      style={{
        display: 'flex',
        flexDirection: 'column',
        ...getAlignStyles(horizontalAlign),
      }}
    >
      <NodeViewContent
        level={level}
        placeholder="Start typing…"
        className={cx('heading', HEADING_CLASS)}
        style={{
          minWidth: 'min(100%, 8em)', // Ensure room for placeholder
        }}
      ></NodeViewContent>
    </AnnotatableNodeViewWrapper>
  )
}
