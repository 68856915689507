import { Box, Flex } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import { getThemeBase } from 'modules/theming/themeBases'

import { CELL_PADDING, EmptyCellContent } from '../constants'
import { CellSizeOption } from '../options/BlockSizeOption'
import {
  SmartLayoutCellComponent,
  SmartLayoutVariant,
  SmartLayoutWrapperComponent,
} from '../types'

export const TextBoxesWrapper: SmartLayoutWrapperComponent = ({ children }) => {
  return (
    <Flex gap="1em" wrap="wrap" data-selection-ring>
      {children}
    </Flex>
  )
}

export const TextBoxCell: SmartLayoutCellComponent = ({
  theme,
  children,
  layoutOptions,
}) => {
  const base = getThemeBase(theme)
  const width = `${layoutOptions.cellSize}em`
  return (
    <Box
      // Always fill the space, distributing evenly
      flex={'1 1 auto'}
      w={width}
      px={CELL_PADDING}
      data-selection-ring
      data-content-reference
      sx={base.smartLayoutBoxSx || base.boxSx}
    >
      {children}
    </Box>
  )
}

export const TextBoxes: SmartLayoutVariant = {
  key: 'textBoxes',
  name: 'Text boxes',
  icon: regular('input-text'),
  options: [CellSizeOption],
  Wrapper: TextBoxesWrapper,
  Cell: TextBoxCell,
  defaultContent: EmptyCellContent,
  addDirection() {
    return 'right'
  },
  htmlTag: 'boxes',
}
