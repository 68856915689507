import { NodeViewProps } from '@tiptap/react'

import { CardAccentLayoutItemView } from './CardAccent/CardAccentLayoutItemView'
import { CardBodyLayoutItemView } from './CardBodyLayoutItemView'

export const CardLayoutItemView = (nodeViewProps: NodeViewProps) => {
  const { node } = nodeViewProps
  if (node.attrs.itemId === 'accent') {
    return <CardAccentLayoutItemView {...nodeViewProps} />
  } else {
    return <CardBodyLayoutItemView {...nodeViewProps} />
  }
}
