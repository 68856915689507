import { Box } from '@chakra-ui/react'
import { Editor, findChildren, JSONContent } from '@tiptap/core'
import { Node, ResolvedPos } from 'prosemirror-model'
import { Selection } from 'prosemirror-state'
import React, {
  memo,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { DropTargetMonitor, useDrag, useDrop, XYCoord } from 'react-dnd'
import { useDispatch, useSelector } from 'react-redux'

import { config } from 'config'
import { useGetCard } from 'modules/cards'
import { startSlideToSlide } from 'modules/performance/slideToSlidePerf'
import { isCardNode } from 'modules/tiptap_editor/extensions/Card/utils'
import { setFollowingAttached } from 'modules/tiptap_editor/reducer'
import { EditorModeEnum } from 'modules/tiptap_editor/types'
import { updateCardHash } from 'modules/tiptap_editor/utils/url'
import { useCan } from 'modules/user'
import {
  selectDoc,
  selectIsCollaborativeDocEditorType,
} from 'sections/docs/reducer'
import { isMobileDevice } from 'utils/deviceDetection'
import { parseUrlHash } from 'utils/url'

import { findFirstTreeTextNode } from './helpers'
import { useOverlap } from './hooks'
import { TreeItemInner } from './TreeItemInner'

export type TreeItemProps = {
  isRoot: boolean
  node: Node
  editorMode: EditorModeEnum
  editor: Editor
  docId: string
  content: JSONContent
  selection: Selection
  isSelectionOriginTOC: boolean
  hasChildren: boolean
  isFirst: boolean
  isLast: boolean
  onClose?: () => void
  scrollOffsetFromTop: number
}

type DragItem = {
  id: string
  node: Node
}

const getScreenshotURL = (previewUrl?: string) => {
  if (!previewUrl) return

  const url = new URL(previewUrl)
  if (config.SHARE_TOKEN) {
    url.searchParams.set('shareToken', config.SHARE_TOKEN)
  }

  return url.toString()
}

export const useResolvedCardNode = (
  editor: Editor,
  item: JSONContent
): ResolvedPos | null => {
  const matches = findChildren(
    editor.state.doc,
    (node) => isCardNode(node) && node.attrs.id === item.attrs?.id
  )
  if (!matches || !matches[0]) {
    return null
  }

  return editor.state.doc.resolve(matches[0].pos + 1)
}

const ExpandBox: React.FC<{
  position: 'top' | 'bottom'
  size: number
}> = memo(({ position, size }) => {
  const posProps =
    position === 'top' ? { top: `-${size}px` } : { bottom: `-${size}px` }
  return (
    <Box
      width="100%"
      bg="transparent"
      position="absolute"
      zIndex={2}
      height={`${size}px`}
      {...posProps}
    />
  )
})

ExpandBox.displayName = 'ExpandBox'

export const TreeItem: React.FC<PropsWithChildren<TreeItemProps>> = ({
  isRoot,
  node,
  editorMode,
  content,
  editor,
  docId,
  selection,
  isSelectionOriginTOC,
  hasChildren,
  isFirst,
  isLast,
  children,
  onClose,
  scrollOffsetFromTop,
}) => {
  const doc = useSelector(selectDoc)
  const dispatch = useDispatch()
  const canEditDoc = useCan('edit', doc)
  const isCollaborativeDocEditorType = useSelector(
    selectIsCollaborativeDocEditorType
  )
  const userCanEdit = isCollaborativeDocEditorType && canEditDoc
  // drag and drop element ref
  const ref = useRef<any>()
  const [isExpanded, setIsExpanded] = useState(hasChildren && isRoot)
  const [dropState, setDropState] = useState<
    'none' | 'above' | 'below' | 'inside'
  >('none')
  const id = node.attrs.id
  const cardData = useGetCard(id)

  const title = cardData ? cardData.title || findFirstTreeTextNode(content) : ''

  const resolved = useResolvedCardNode(editor, content)

  // figure out which tree item is selected
  const overlap = useOverlap({
    editorMode,
    resolved,
    selection,
    node,
  })
  const canClick = !!resolved

  // handle selecting + scrolling to card
  // opens all parent cards for nested cards
  const onClick = useCallback(() => {
    if (!canClick) {
      return
    }

    if (editorMode === EditorModeEnum.SLIDE_VIEW) {
      startSlideToSlide({ tocClick: true })
    }

    dispatch(setFollowingAttached({ attached: false }))

    const { cardId: currentCardId } = parseUrlHash()
    if (isMobileDevice() && onClose) {
      setTimeout(onClose, 300) // The timeout makes it feel less jarring
    }
    updateCardHash({
      cardId: id,
      // Clicking on the currently active item should not add to the history
      // stack, but we still call it to possibly scroll us back there.
      method: currentCardId === id ? 'replace' : 'push',
    })
  }, [dispatch, editorMode, id, canClick, onClose])

  const [{ isDragging: _isDragging }, drag, dragPreviewRef] = useDrag(
    {
      type: 'card',
      canDrag: () => !isMobileDevice() && userCanEdit,
      item: () => {
        return { id: node.attrs.id, node }
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    },
    [userCanEdit]
  )

  const [{ isOver, isOverCurrent, draggingItem }, drop] = useDrop(
    () => ({
      accept: 'card',
      canDrop: () => !isMobileDevice() && userCanEdit,
      hover(item: DragItem, monitor: DropTargetMonitor) {
        if (!ref.current) {
          return
        }
        const dragId = item.id
        const hoverId = id

        // Don't replace items with themselves
        if (dragId === hoverId) {
          return
        }

        // Determine rectangle on screen
        const hoverBoundingRect = ref.current?.getBoundingClientRect()

        // Get vertical middle
        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

        const MIDDLE_HEIGHT = isExpanded && hasChildren ? 0 : 20
        const middleRange = [
          hoverMiddleY - MIDDLE_HEIGHT / 2,
          hoverMiddleY + MIDDLE_HEIGHT / 2,
        ]

        // Determine mouse position
        const clientOffset = monitor.getClientOffset()

        // Get pixels to the top
        const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%

        if (hoverClientY > middleRange[0] && hoverClientY <= middleRange[1]) {
          setDropState('inside')
          return
        }

        if (hoverClientY > middleRange[1]) {
          // Dragging downwards
          setDropState('below')
          return
        }

        // Dragging upwards
        if (hoverClientY < middleRange[0]) {
          setDropState('above')
          return
        }
      },
      drop(item: DragItem, monitor) {
        const didDrop = monitor.didDrop()
        if (didDrop || dropState === 'none') {
          return
        }

        const dropped = findChildren(
          editor.state.doc,
          (n) => isCardNode(n) && n.attrs.id === id
        )
        const dragged = findChildren(
          editor.state.doc,
          (n) => isCardNode(n) && n.attrs.id === item.id
        )

        if (!dropped || !dragged) {
          return
        }

        editor.commands.rearrangeCards({
          from: dragged[0].pos,
          to: dropped[0].pos,
          position: dropState,
        })

        setDropState('none')
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        draggingItem: monitor.getItem(),
        isOverCurrent: monitor.isOver({ shallow: true }),
      }),
    }),
    [dropState, setDropState, hasChildren, isExpanded, userCanEdit]
  )
  // use effect to reset drop state to none when dragged item is not over this drop target
  useEffect(() => {
    if (!isOver && dropState !== 'none') {
      setDropState('none')
    }
  }, [isOver, dropState])
  drag(drop(ref))

  // Expand box is an absolutely positioned element that increases the
  // drop target to account for the lines above and below that form when
  // hovering over an element.
  let expandTop20: boolean = false
  let expandBottom20: boolean = false
  let expandTop4: boolean = false
  let expandBottom4: boolean = false
  if (draggingItem && isFirst && isRoot) {
    expandTop20 = true
  }
  if (
    (isOver && hasChildren && isExpanded) ||
    (draggingItem && isLast && isRoot)
  ) {
    expandBottom20 = true
  }
  if (isOverCurrent) {
    if (dropState === 'below') {
      expandBottom4 = true
    } else if (dropState === 'above') {
      expandTop4 = true
    }
  }

  const expandBox = useMemo(() => {
    const ret: ReactNode[] = []
    if (expandTop20) {
      ret.push(<ExpandBox position="top" key="expand-top-20" size={10} />)
    }
    if (expandTop4) {
      ret.push(<ExpandBox position="top" key="expand-top-4" size={4} />)
    }

    if (expandBottom20) {
      ret.push(<ExpandBox position="bottom" key="expand-bottom-20" size={10} />)
    }
    if (expandBottom4) {
      ret.push(<ExpandBox position="bottom" key="expand-bottom-4" size={4} />)
    }
    return ret
  }, [expandBottom20, expandBottom4, expandTop20, expandTop4])

  const isAbove = isOverCurrent && dropState === 'above'
  const isInside = isOverCurrent && dropState === 'inside'
  const isBelow = isOverCurrent && dropState === 'below'

  const classNames = `${isAbove ? 'toc-box__above' : ''} ${
    isBelow ? 'toc-box__below' : ''
  }`

  // The TOC content should ideally be in sync with `editor.state.doc`.
  // If the TOC content is behind `editor.state.doc`, then we may get a `null`
  // resolved value, so we need to account for that.
  // Use pos - 1, to get the position to SELECT the card node
  // not the actual position of the card node.
  const cardPos = resolved?.pos ? resolved.pos - 1 : null
  // store cardPos in a ref to create an identity stable function for cardPos, so TreeItemInner can be memoized
  const cardPosRef = useRef(cardPos)
  useEffect(() => {
    cardPosRef.current = cardPos
  }, [cardPos])
  const getPos = useCallback(() => {
    return cardPosRef.current
  }, [cardPosRef])

  return (
    <Box className={`toc-box ${classNames}`}>
      {isAbove && (
        <Box height="2px" mb="2px" bgColor="trueblue.100" ml={isRoot ? 2 : 4} />
      )}
      <TreeItemInner
        userCanEdit={userCanEdit}
        className="toc-box--inner"
        overlap={draggingItem ? 'none' : overlap}
        isRoot={isRoot}
        onSelectClick={onClick}
        hasChildren={hasChildren}
        title={title}
        ref={ref}
        previewRef={dragPreviewRef}
        expandBox={expandBox}
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
        showDropBorder={isInside}
        editorMode={editorMode}
        cardId={id}
        editor={editor}
        isSelectionOriginTOC={isSelectionOriginTOC}
        docId={docId}
        getPos={getPos}
        screenshotUrl={getScreenshotURL(cardData?.previewUrl)}
        scrollOffsetFromTop={scrollOffsetFromTop}
      >
        {isExpanded && children}
      </TreeItemInner>

      {isBelow && (
        <Box height="2px" mt="2px" bgColor="trueblue.100" ml={isRoot ? 2 : 4} />
      )}
    </Box>
  )
}
