import { findParentNodeClosestToPos } from '@tiptap/core'
import { ResolvedPos, Slice } from 'prosemirror-model'
import { EditorView } from 'prosemirror-view'

import {
  BackgroundOptions,
  BackgroundType,
} from 'modules/tiptap_editor/styles/backgroundStyles'
import { findNodeAndParents, rectAtPos } from 'modules/tiptap_editor/utils'
import { isImageMimeType } from 'utils/image'

import { sliceSingleNode } from '../../Clipboard/defaultHandlePaste'
import { getDataTransferFiles } from '../../media/Upload/uploadPlugin'
import { isAccentCardLayoutItem } from './cardLayoutUtils'

export type CardAccentDropTarget = {
  pos: number
  rect: DOMRect
} & (
  | {
      isImageUpload: true
      file: File
    }
  | {
      isImageUpload: false
      background: Partial<BackgroundOptions>
    }
)

export const findParentCardAccentLayoutItem = ($pos: ResolvedPos) =>
  findParentNodeClosestToPos($pos, isAccentCardLayoutItem)

export const checkCardAccentDropTarget = (
  view: EditorView,
  event: DragEvent,
  slice?: Slice
): CardAccentDropTarget | null => {
  const coordPos = view.posAtCoords({
    left: event.clientX,
    top: event.clientY,
  })
  if (!coordPos || coordPos.inside < 0) {
    return null
  }
  const { doc } = view.state
  const { inside } = coordPos
  const $pos = doc.resolve(inside)
  const cardAccent = findNodeAndParents($pos, isAccentCardLayoutItem)
  const node = slice && sliceSingleNode(slice)
  const rect = rectAtPos(inside, view)

  if (cardAccent.length === 0 || !rect) {
    return null
  }

  if (!node) {
    const files = getDataTransferFiles(event).filter((file) =>
      isImageMimeType(file.type)
    )
    if (files.length !== 1) {
      return null
    }

    return {
      pos: inside,
      rect,
      isImageUpload: true,
      file: files[0],
    }
  }

  if (node && node.type.name === 'image') {
    return {
      pos: inside,
      rect,
      isImageUpload: false,
      background: {
        type: BackgroundType.IMAGE,
        source: node.attrs.source,
        image: node.attrs,
      },
    }
  }
  return null
}
