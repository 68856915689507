import { Box, Portal } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { DocumentGridItem, DOC_DISPLAY_NAME, GammaTooltip } from '@gamma-app/ui'
import { NodeViewProps } from '@tiptap/core'
import { motion } from 'framer-motion'
import { useEffect } from 'react'

import placeholderBackground from 'gamma_components/placeholderBackground.svg'
import {
  DocUserUpdateFragmentDoc,
  SubscribeToDocMentionDocument,
  useFavoriteDocMutation,
  useGetDocMentionQuery,
} from 'modules/api'
import { useFeatureFlag } from 'modules/featureFlags'
import { NodeViewWrapper } from 'modules/tiptap_editor/react'
import { useUserContext } from 'modules/user'
import { LightPopoverMotionProps, useLightPopover } from 'utils/hooks'
import { generateDocUrl } from 'utils/url'

import { DOC_MENTION_NODE_NAME } from './constants'
import { MentionTag, MentionTagLoading } from './MentionTag'

const DOC_TITLE_FORBIDDEN = `Private ${DOC_DISPLAY_NAME}`
const MotionBox = motion(Box)

export const DocMentionNodeView = ({ node, editor }: NodeViewProps) => {
  const { id } = node.attrs
  const screenshotsEnabled = useFeatureFlag('screenshotsEnabled')
  const { user } = useUserContext()
  const [favoriteDoc] = useFavoriteDocMutation()

  const {
    data: docMentionData,
    error,
    loading,
    subscribeToMore,
  } = useGetDocMentionQuery({
    variables: {
      id,
    },
    skip: !id,
  })

  useEffect(() => {
    if (!process.browser || !subscribeToMore) return

    return subscribeToMore({
      document: SubscribeToDocMentionDocument,
      variables: { id },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data || !subscriptionData.data.doc) return prev
        // Shallow merge the result with the existing doc
        return {
          doc: {
            ...prev.doc,
            ...subscriptionData.data.doc,
            __typename: 'Doc',
          },
        }
      },
    })
  }, [id, subscribeToMore])

  const { title, createdTime, createdBy, editedTime, editors, docUser } =
    docMentionData?.doc || {}
  const docUrl = generateDocUrl({ docId: id, docTitle: title })
  useEffect(() => {
    // This is defined in the docMention extension's arbitrary `addStorage` POJO
    // We're keeping a map of docId to title such that we have that information handy
    // when we need to call `getHTML()` or `getText()` (faciliated by the renderHTML
    // and renderText methods)
    editor.storage[DOC_MENTION_NODE_NAME][id] = error
      ? DOC_TITLE_FORBIDDEN
      : title
  }, [title, editor.storage, id, error])

  const thumbnailImageUrl = {
    src: screenshotsEnabled
      ? docMentionData?.doc?.titleCard?.previewUrl
      : placeholderBackground.src,
    fallbackSrc: placeholderBackground.src,
  }
  const handleFavoriteDoc = (favorited: Date | null) => {
    return () => {
      if (!user) return

      const variables = {
        input: {
          docId: id,
          userId: user.id,
          favorited,
        },
      }
      favoriteDoc({
        variables,
        update: (cache, { data }) => {
          cache.writeFragment({
            id: `Doc:${id}`,
            fragment: DocUserUpdateFragmentDoc,
            data: {
              docUser: data?.updateDocUser,
            },
          })
        },
        optimisticResponse: {
          updateDocUser: {
            ...docUser!,
            favorited: variables.input.favorited,
            __typename: 'DocUser',
          },
        },
      })
    }
  }

  const {
    popperRef,
    referenceRef,
    isHovering,
    onMouseOver,
    onMouseOut,
    getPopperProps,
  } = useLightPopover()

  return (
    <NodeViewWrapper as="span">
      {loading ? (
        <MentionTagLoading />
      ) : docMentionData && docMentionData.doc ? (
        <MentionTag
          onMouseOver={onMouseOver}
          onMouseOut={onMouseOut}
          ref={referenceRef}
          mentionLabel={title || ''}
          mentionIcon={regular('rectangle-history')}
          mentionTargetUrl={docUrl}
        />
      ) : error ? (
        // If there's an error, we'll assume they don't have access to the doc.
        <GammaTooltip
          label={`You don't have access to the linked ${DOC_DISPLAY_NAME}. Try asking the owner of this ${DOC_DISPLAY_NAME} for access.`}
        >
          <MentionTag
            hasError
            mentionLabel={DOC_TITLE_FORBIDDEN}
            mentionIcon={regular('lock')}
          />
        </GammaTooltip>
      ) : null}
      <Portal>
        {isHovering && docMentionData && docMentionData.doc && (
          <MotionBox
            width="320px"
            data-target-name="doc-mention-popup"
            border="1px solid"
            borderColor="gray.200"
            borderRadius="lg"
            overflow="hidden"
            shadow="lg"
            maxW="80vw"
            zIndex="tooltip"
            ref={popperRef}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
            {...getPopperProps()}
            {...LightPopoverMotionProps}
          >
            <DocumentGridItem
              createdByYou={createdBy?.id === user?.id}
              title={title || ''}
              // TO DO: Implement docUser.lastViewed in getDoc and docSubscription
              // https://linear.app/gamma-app/issue/G-1497/implement-docuserlastviewed-in-getdoc-and-docsubscription
              timestamp={editedTime}
              timestampDescriptor={'Edited'}
              NextLink={null}
              href={generateDocUrl({ docId: id })}
              id={id}
              createdByName={createdBy?.displayName || ''}
              createdByEmail={createdBy?.email || ''}
              createdByProfileImageUrl={createdBy?.profileImageUrl || ''}
              createdTime={createdTime}
              thumbnailImageUrl={thumbnailImageUrl}
              editors={editors}
              isFavorited={!!docUser?.favorited}
              onFavorite={handleFavoriteDoc(new Date())}
              onUnfavorite={handleFavoriteDoc(null)}
              menuEnabled={false}
              openInNewWindow={true}
            />
          </MotionBox>
        )}
      </Portal>
    </NodeViewWrapper>
  )
}
