import { Mark } from '@tiptap/core'
import { mergeAttributes } from '@tiptap/react'

import { ExtensionPriorityMap } from '../constants'
import { ValidTextColors } from './textColorStyles'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    textColor: {
      setTextColorVariant: (attributes?: { variant: string }) => ReturnType
    }
  }
}

export const TextColor = Mark.create({
  name: 'textColor',
  excludes: 'highlight textColor',
  priority: ExtensionPriorityMap.TextColor,
  addAttributes() {
    return {
      variant: {
        default: 'blue',
      },
    }
  },
  addCommands() {
    return {
      setTextColorVariant:
        (attributes) =>
        ({ commands }) => {
          return commands.setMark(this.name, attributes)
        },
    }
  },
  parseHTML() {
    return [
      {
        tag: 'span[class=textColor]',
      },
      {
        tag: '*[color]',
        getAttrs(elt: HTMLElement) {
          const color = elt.getAttribute('color')
          if (!color || !ValidTextColors.includes(color)) {
            // Future todo: match arbitrary hex colors
            // When we do, double check that it doesn't match the theme body
            return false
          }
          return {
            variant: elt.getAttribute('color'),
          }
        },
        // This lets us match a tag like <h1 color="red">. It will run before the H1 rule due to the higher
        // priority, and consuming: false means the H1 rule can still match it.
        consuming: false,
        priority: 60,
      },
    ]
  },

  // Used in the editor, and regular copy and pastes
  renderHTML({ HTMLAttributes }) {
    return [
      'span',
      mergeAttributes(HTMLAttributes, {
        class: 'textColor',
      }),
    ]
  },

  renderHTMLforAI({ mark }) {
    return [
      'span',
      {
        color: mark.attrs.variant,
      },
    ]
  },
})
