import { Editor } from '@tiptap/core'

import {
  absoluteToRelativePos,
  relativeToAbsolutePos,
} from 'modules/tiptap_editor/utils/relativePosition'
import { commentNanoid } from 'utils/comments'

import { DraftCommentsPluginKey } from '../DraftCommentsExtension/DraftCommentsPlugin'
import { DraftCommentsState } from '../DraftCommentsExtension/DraftCommentsState'
import { DraftComment } from '../DraftCommentsExtension/types'
import { DraftCommentReplyMap } from './types'

export const createEmptyDraftComment = (
  getPos: () => number,
  editor: Editor,
  targetHtml?: string
): DraftComment => {
  return {
    targetId: commentNanoid.generate(),
    targetHtml,
    json: null,
    text: '',
    relativePos: absoluteToRelativePos(editor.state, getPos())!,
  }
}

export const updateDraftComment = (
  draft: DraftComment,
  getPos: () => number,
  targetHtml: string | undefined,
  editor: Editor
): DraftComment => {
  return {
    ...draft,
    targetHtml,
    relativePos: absoluteToRelativePos(editor.state, getPos())!,
  }
}

/**
 * Return any draft comments for the given absolute position.
 * This can be used when outside the context of a NodeView,
 * such as the comments panel for memo level comments.
 */
export const findDraftCommentByAbsPos = (
  editor: Editor,
  absPos: number
): DraftComment[] => {
  const pluginState = DraftCommentsPluginKey.getState(
    editor.state
  ) as DraftCommentsState

  return Object.values(pluginState.draftComments).filter(
    (draft: DraftComment) => {
      return relativeToAbsolutePos(editor.state, draft.relativePos) === absPos
    }
  )
}

export const omitEmptyDraftReplies = (
  replies: DraftCommentReplyMap
): DraftCommentReplyMap => {
  return Object.entries(replies).reduce<DraftCommentReplyMap>(
    (carry, [key, val]) => {
      if (val.text.trim() === '') {
        return carry
      }
      carry[key] = val
      return carry
    },
    {}
  )
}
