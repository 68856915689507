import { GapCursor } from 'prosemirror-gapcursor'
import { Node, ResolvedPos } from 'prosemirror-model'
import {
  NodeSelection,
  Selection,
  TextSelection,
  Transaction,
} from 'prosemirror-state'
import { ReplaceAroundStep, ReplaceStep } from 'prosemirror-transform'

const getNodeSelection = (
  doc: Node,
  node: Node | null,
  pos: number
): NodeSelection | null => {
  if (node && NodeSelection.isSelectable(node)) {
    return NodeSelection.create(doc, pos)
  }
  return null
}

const getTextSelection = (
  doc: Node,
  node: Node | null,
  pos: number
): TextSelection | null => {
  if (node && node.inlineContent) {
    return TextSelection.create(doc, pos)
  }

  return null
}

function findDirectionSelection<S extends Selection>(
  isForward: boolean,
  backward: S | null,
  forward: S | null
): S | null {
  if (isForward && forward) {
    return forward
  } else if (isForward && backward) {
    return backward
  } else if (!isForward && backward) {
    return backward
  } else if (!isForward && forward) {
    return forward
  }

  return null
}

/**
 *
 * @param $pos
 * @param dir
 * @returns
 */
function findSelectionBeside($pos: ResolvedPos, dir: number): Selection | null {
  const { doc, pos, parent, nodeAfter, nodeBefore } = $pos
  // inside a text node, just return text selection at that position and be done
  if (parent.inlineContent) {
    return TextSelection.create(doc, pos)
  }

  const forward = dir > 0

  // Check for a text selection at the same depth
  const foundTextSelection = findDirectionSelection(
    forward,
    // try getting text selection backward
    getTextSelection(doc, nodeBefore, pos - 1),
    // try getting text selection forward
    getTextSelection(doc, nodeAfter, pos + 1)
  )
  if (foundTextSelection) {
    return foundTextSelection
  }

  // attempt to put a gapcursor at this position before we resort to doing a NodeSelection
  // or selecting the parent (if parent is isolating)
  // @ts-ignore
  if (GapCursor.valid($pos)) {
    return new GapCursor($pos)
  }

  if (parent.type.spec.isolating) {
    // if it's isolating and we haven't been able to get a text selection or gapcursor
    // then try to do a node selection in that direction
    const foundNodeSelection = findDirectionSelection(
      forward,
      // try selecting node before
      getNodeSelection(doc, nodeBefore, pos - (nodeBefore?.nodeSize || 0)),
      // try selecting node after
      getNodeSelection(doc, nodeAfter, pos)
    )
    if (foundNodeSelection) {
      return foundNodeSelection
    }

    // if there are no children left of the parent and it's selectable, select it...
    if (parent.childCount === 0 && NodeSelection.isSelectable(parent)) {
      return NodeSelection.create(
        doc,
        // parent's pos
        $pos.before()
      )
    }
  }

  return null
}

export function findSelectionNearOrGapCursor(
  $pos: ResolvedPos,
  dir: number = 1
): Selection | null {
  const selection = findSelectionBeside($pos, dir)
  if (selection) {
    return selection
  }
  // if the parent is isolating, dont traverse up more.  If we couldn't find a place
  // to select text or gapcursor then dont return anything...
  // return null
  for (let depth = $pos.depth - 1; depth >= 0; depth--) {
    // this is parent of current node at depth=depth
    if ($pos.node(depth + 1).type.spec.isolating) {
      // try to traverse up in depth, but the current node is isolating, so return null
      return null
    }
    // based on direction use before or after
    const newPos = dir < 0 ? $pos.before(depth + 1) : $pos.after(depth + 1)
    const found = findSelectionBeside($pos.doc.resolve(newPos), dir)
    if (found) {
      return found
    }
  }

  // could not find anything
  return null
}

/**
 * Creates a selection near (with gapcursor) based on the last replace step's `to` or `from`
 */
export const createSelectionNearLastTo = (
  tr: Transaction,
  dir?: number
): Selection | null => {
  const last = tr.steps.length - 1
  const step = tr.steps[last]
  if (!(step instanceof ReplaceStep || step instanceof ReplaceAroundStep)) {
    return null
  }

  const map = tr.mapping.maps[last]
  let end: number | undefined
  map.forEach((_from, _to, _newFrom, newTo) => {
    if (end == null) end = newTo
  })

  if (end == null) {
    return null
  }

  return findSelectionNearOrGapCursor(tr.doc.resolve(end), dir)
}
