import { ContainerEffect } from 'modules/tiptap_editor/styles/containerStyles'

import { Theme, ThemeBase } from '../types'
import { DaydreamThemeBase } from './daydream'
import { DefaultThemeBase } from './default'
import { FinesseThemeBase } from './finesse'
import { GlassThemeBase } from './glass'
import { IcebreakerThemeBase } from './icebreaker'
import { Soft3DThemeBase } from './soft3d'

export const THEME_BASES: ThemeBase[] = [
  DefaultThemeBase,
  GlassThemeBase,
  Soft3DThemeBase,
  FinesseThemeBase,
  DaydreamThemeBase,
  IcebreakerThemeBase,
]

const ThemeBasesMap: Record<string, ThemeBase> = THEME_BASES.reduce(
  (acc, themeBase) => {
    acc[themeBase.key] = themeBase
    return acc
  },
  {}
)

export const getThemeBase = (theme: Theme) => {
  const themeBaseKey = theme.config.themeBase
  if (themeBaseKey && themeBaseKey in ThemeBasesMap) {
    return ThemeBasesMap[themeBaseKey]
  } else if (
    theme.config.container?.effect === ContainerEffect.FROSTED ||
    theme.config.container?.effect === ContainerEffect.FADED
  ) {
    // Legacy custom themes don't have a base defined, so
    // we default based on the card style
    return GlassThemeBase
  } else {
    return DefaultThemeBase
  }
}
