import { Box, HStack, Flex, FlexProps, StackProps } from '@chakra-ui/react'
import { forwardRef, KeyboardEvent, MutableRefObject, ReactNode } from 'react'

import { BlockCommentsCircle } from '../../BlockCommentsCircle'

export const COMMENTS_WRAPPER_CLASSNAME = 'comments-wrapper'

type BlockCommentsWrapperProps = {
  nodeName: string
  isPopupOpen: boolean
  onKeyDown: (event: KeyboardEvent) => void
  buttonRef: MutableRefObject<any>
  children: ReactNode
  isMobile: boolean
  hasCommentsOrReactions: boolean
  isEmpty: boolean
} & FlexProps

export const BlockCommentsWrapper = forwardRef<
  HTMLDivElement,
  BlockCommentsWrapperProps
>(function BlockCommentsWrapper(props, ref) {
  const {
    nodeName,
    isPopupOpen,
    onKeyDown,
    buttonRef,
    isMobile,
    hasCommentsOrReactions,
    children,
    isEmpty,
    ...flexProps
  } = props

  const styles: Partial<StackProps> = isMobile
    ? hasCommentsOrReactions
      ? { pt: 4 }
      : {}
    : {
        position: 'absolute',
        marginLeft: '100%',
        top: 0,
        height: '100%',
        align: 'flex-start',
      }
  return (
    <Box
      ref={ref}
      contentEditable={false}
      suppressContentEditableWarning={true}
      pointerEvents="none"
      data-comments-wrapper // NB: do not delete this, we need it to identify events coming from this component and for spotlight selectors
      data-is-popup-open={isPopupOpen}
      className={COMMENTS_WRAPPER_CLASSNAME}
      // ensure the comments wrapper is align start when the parent is flex and alignItems: center
      alignSelf="start"
      onKeyDown={onKeyDown}
      data-print-hidden
    >
      {/** Only render the circle when there are existing comments/reactions*/}
      {/** NOTE: we render the BlockCommentsCircle inside the above Box because if a position:absolute element
       * is rendered next to the content editable, then it messes up arrow left
       * selecting the previous block
       */}
      {!isEmpty && !isMobile && <BlockCommentsCircle nodeName={nodeName} />}
      <HStack
        contentEditable={false}
        suppressContentEditableWarning={true}
        {...styles}
        className={`controls-wrapper controls-wrapper__${nodeName}`}
      >
        <Flex
          direction="row"
          ref={buttonRef}
          contentEditable={false}
          suppressContentEditableWarning={true}
          transitionProperty="common"
          transitionDuration="fast"
          zIndex={21} // one higher than column-resize-handle
          data-controls-toggle-button
          ml={[0, '-17px']}
          {...flexProps}
        >
          {children}
        </Flex>
      </HStack>
    </Box>
  )
})
